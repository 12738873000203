import React, { Component } from 'react'
import { Form, Select, Input, message } from 'antd';
import 'moment/locale/zh-cn';
import AddConfig from '../../components/AddConfig'
import { CaretDownOutlined } from '@ant-design/icons';
import { createDevelopmentAcceptances, DevelopmentAcceptanceDetail, getDevelopmentInfo } from "../../../utils/scientific_research"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "结题验收申请表",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '项目基本信息',
                    list: [
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目编号', //字段标题
                            fieldName: 'pro_code', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '部门名称', //字段标题
                            fieldName: 'de_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目经费(元)', //字段标题
                            fieldName: 'pro_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目负责人', //字段标题
                            fieldName: 'pro_manager', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '合作单位', //字段标题
                            fieldName: 'coo_unit', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'phone', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_time', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_time', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                }, {
                    plateName: '项目成果形式及数量(需提供附件)',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [{
                            title: '',
                            dataIndex: 'key',
                            key: 'key',
                            width: '100px'
                        },
                        {
                            title: '项目成果形式',
                            dataIndex: 'shape',
                            key: 'shape',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (<Form.Item name={'shape' + record.key} key={index} initialValue={text}>
                                        <Select options={[
                                            { value: 1, label: '文献资料综述' },
                                            { value: 2, label: '调查报告' },
                                            { value: 3, label: '研究论文' },
                                            { value: 4, label: '授权专利' },
                                            { value: 5, label: '软件' },
                                            { value: 6, label: '设计' },
                                            { value: 7, label: '硬件研制' },
                                            { value: 8, label: '学术交流报告' },
                                            { value: 9, label: '其他' },
                                        ]}
                                            suffixIcon={<CaretDownOutlined />}
                                        >
                                        </Select>
                                    </Form.Item>)
                                } else {
                                    return (<Form.Item name={'shape' + record.key} key={index} initialValue={text}>
                                        <Select options={[
                                            { value: 1, label: '文献资料综述' },
                                            { value: 2, label: '调查报告' },
                                            { value: 3, label: '研究论文' },
                                            { value: 4, label: '授权专利' },
                                            { value: 5, label: '软件' },
                                            { value: 6, label: '设计' },
                                            { value: 7, label: '硬件研制' },
                                            { value: 8, label: '学术交流报告' },
                                            { value: 9, label: '其他' },
                                        ]}
                                            suffixIcon={<CaretDownOutlined />}
                                            disabled
                                        >
                                        </Select>
                                    </Form.Item>)
                                }
                            }

                        },
                        {
                            title: '数量',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (<Form.Item name={'quantity' + record.key} key={index} initialValue={text}>
                                        <Input />
                                    </Form.Item>)
                                } else {
                                    return (<Form.Item name={'quantity' + record.key} key={index} initialValue={text}>
                                        <Input disabled />
                                    </Form.Item>)
                                }
                            }

                        },
                        ]
                    },
                }, {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '预期目标', //字段标题
                            fieldName: 'target', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }, {
                    plateName: '项目经费使用情况',
                    list: [
                        {
                            FieldTitle: '计划经费(万元)', //字段标题
                            fieldName: 'plan_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '共使用经费(万元)', //字段标题
                            fieldName: 'use_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                },
            ]
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DevelopmentAcceptanceDetail(tableId)
        } else {
            let devel_id = sessionStorage.getItem('development_id')
            this.get_DevelopmentInfo(devel_id)
        }
    }

    get_DevelopmentInfo = (devel_id) => {
        let { Info, form } = this.state
        let data = {
            devel_id: devel_id
        }
        getDevelopmentInfo(data).then(
            (res) => {
                if (!res.code) {
                    Info.state = 1
                    form.devel_id = devel_id
                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.devel.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.devel.pro_code
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.devel.deName
                        } else if (item.FieldTitle === '项目经费(元)') {
                            item.value = res.data.devel.pro_money
                        } else if (item.FieldTitle === '项目负责人') {
                            item.value = res.data.devel.pro_manager
                        } else if (item.FieldTitle === '联系电话') {
                            item.value = res.data.devel.phone
                        } else if (item.FieldTitle === '合作单位') {
                            item.value = res.data.devel.coo_unit
                        } else if (item.FieldTitle === '开始时间') {
                            item.value = res.data.devel.start_time
                        } else if (item.FieldTitle === '结束时间') {
                            item.value = res.data.devel.end_time
                        }
                    })
                    this.setState(preState => ({
                        Info,
                        form
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DevelopmentAcceptanceDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentAcceptanceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '附件') {
                                    if (!res.data.Detail.enclosure.length) {
                                        em.isShow = true
                                    }
                                }
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.devel.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.devel.pro_code
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.devel.deName
                        } else if (item.FieldTitle === '项目经费(元)') {
                            item.value = res.data.Detail.devel.pro_money
                        } else if (item.FieldTitle === '项目负责人') {
                            item.value = res.data.Detail.devel.pro_manager
                        } else if (item.FieldTitle === '联系电话') {
                            item.value = res.data.Detail.devel.phone
                        } else if (item.FieldTitle === '合作单位') {
                            item.value = res.data.Detail.devel.coo_unit
                        } else if (item.FieldTitle === '开始时间') {
                            item.value = res.data.Detail.devel.start_time
                        } else if (item.FieldTitle === '结束时间') {
                            item.value = res.data.Detail.devel.end_time
                        }
                    })

                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    Info.FirstInfo[1].editable.dataSource = []
                    let result = JSON.parse(res.data.Detail.result)
                    result.forEach((item, index) => {
                        const newData = {
                            key: Info.FirstInfo[1].editable.dataSource.length + 1,
                            shape: item.shape,
                            quantity: item.quantity,
                        };
                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        target: res.data.Detail.target,
                        plan_money: res.data.Detail.plan_money,
                        use_money: res.data.Detail.use_money,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.target = data.target
        form.plan_money = data.plan_money
        form.use_money = data.use_money
        form.result = []

        if (Info.FirstInfo[1].editable.dataSource.length) {
            Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                index++
                if (item.shape) {
                    let value = {
                        shape: data['shape' + index] ? data['shape' + index] : item.shape,
                        quantity: data['quantity' + index] ? data['quantity' + index] : item.quantity,
                    }
                    form.result = [...form.result, value]
                } else {
                    let value = {
                        shape: data['shape' + index] ? data['shape' + index] : '',
                        quantity: data['quantity' + index] ? data['quantity' + index] : '',
                    }
                    form.result = [...form.result, value]
                }
            })
        }

        form.result = JSON.stringify(form.result)


        createDevelopmentAcceptances(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
					    setTimeout(() => {
					        window.history.back(-1)
					    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[3].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        const newData = {
            key: Info.FirstInfo[1].editable.dataSource.length + 1,
            shape: '',
            quantity: '',
        };
        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]

        this.setState(preState => ({
            Info
        })
        )
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} rowAddEvent={this.handleWorkAdd} auditEvent={this.handleButton} />
            </div>
        )
    }
}
