import React, { Component } from 'react'
import { Menu, Dropdown, message, Input, Modal } from 'antd';
import { withRouter, useLocation } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons';
import router from '../router/Index'
import userImg from "../accets/img/user.png"
import bottomImg from "../accets/img/bottom.png"
import returnImg from "../accets/img/return.png"
import { updateUserPassword } from "../utils/public"

class TopHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            inputModal: false,
            prdForm: {
                password: '',
                user_id: ''
            },
            itemList: [],
            pathname: '',
        }
    }

    static getDerivedStateFromProps(props, state) {
        let { itemList } = state
        let { location, path } = props
        if (path.pathname) {
            if (path.pathname === '/index') {
                itemList = []
            } else {
                router.forEach((item, index) => {
                    if (item.path === path.pathname) {
                        path.label = item.title
                    }
                })

                if (itemList.length) {
                    let isRepeat = false
                    itemList.forEach((item, index) => {
                        if (item.pathname === path.pathname) {
                            isRepeat = true
                            itemList.splice(index, 1, path)
                        }
                    })
                    if (!isRepeat) {
                        if (itemList.length < 5) {
                            itemList = [...itemList, path]
                        } else {
                            itemList.splice(0, 1)
                            itemList = [...itemList, path]
                        }
                    }
                } else {
                    itemList = [...itemList, path]
                }
            }

            return { itemList, pathname: location.pathname }
        } else {
            return null
        }
    }

    componentDidMount() {
        this.get_userInfo()
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let { prdForm } = this.state
        prdForm.user_id = userInfo.userId
        this.setState(preState => ({
            userName: userInfo.name,
        })
        )
    }

    menu = () => (
        <Menu
            items={[
                {
                    label: (
                        <span>退出</span>
                    ),
                },
                {
                    label: (
                        <span>修改密码</span>
                    ),
                },
            ]}
            onClick={(e) => {
                this.get_LogOut(e)
            }}
        />
    )

    get_LogOut = (value) => {
        if (value.key === 'tmp-0') {
            localStorage.removeItem('Token')
            this.props.history.push('/login')
        } else {
            this.handleModal(true)
        }
    }

    handleIndex = () => {
        this.setState(preState => ({
            itemList: [],
            FlowchartKey: Math.random(),
        }), () => {
            this.props.history.push('/')
        })

    }

    handleModal = (status) => {
        this.setState({
            inputModal: status
        })
    }

    InputChange = (value) => {
        let { prdForm } = this.state
        prdForm.password = value.target.value
        this.setState({
            prdForm
        })
    }

    updatePassword = () => {
        let { prdForm } = this.state
        if (!prdForm.password) {
            message.error('请输入密码!')
            return
        }
        updateUserPassword(prdForm).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        inputModal: false,
                    }, () => {
                        localStorage.removeItem('Token')
                        this.props.history.push('/login')
                    })
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleItem = (item) => {
        if (item.query && item.query.tableId) {
            this.props.history.push({
                pathname: item.pathname,
                query: {
                    'tableId': item.query.tableId,
                }
            })
        } else {
            this.props.history.push(item.pathname)
        }
    }

    deleteItem = (value) => {
        let { itemList } = this.state
        itemList.forEach((item, index) => {
            if (item.pathname === value) {
                if (itemList.length > 1) {
                    if (index > 0) {
                        if (itemList[index - 1].query && itemList[index - 1].query.tableId) {
                            this.props.history.push({
                                pathname: itemList[index - 1].pathname,
                                query: {
                                    'tableId': itemList[index - 1].query.tableId,
                                }
                            })
                        } else {
                            this.props.history.push(itemList[index - 1].pathname)
                        }
                    } else {
                        if (itemList[index + 1].query && itemList[index + 1].query.tableId) {
                            this.props.history.push({
                                pathname: itemList[index + 1].pathname,
                                query: {
                                    'tableId': itemList[index + 1].query.tableId,
                                }
                            })
                        } else {
                            this.props.history.push(itemList[index + 1].pathname)
                        }
                    }
                } else {
                    this.props.history.push('/index')
                }
                itemList.splice(index, 1)
                this.props.deleteEvent(value)
            }
        })

        this.setState({
            itemList
        })
    }

    render() {
        let { itemList, pathname } = this.state
        return (
            <div className='TopHeader'>
                <div>
                    {
                        !itemList.length ? <div className='desktop' onClick={() => this.handleIndex()} style={{ cursor: 'pointer' }}>
                            我的桌面
                        </div> : <div className='labelList'>
                            <div onClick={() => this.handleIndex()}>我的桌面</div>
                            <div>
                                {
                                    itemList.map((item, index) => {
                                        return (
                                            <div key={item.pathname} className={index === itemList.length - 1 && pathname === item.pathname ? 'final_menu current_menu' : index === itemList.length - 1 ? 'final_menu' : pathname === item.pathname ? 'current_menu' : ''} >
                                                <div onClick={() => {
                                                    this.handleItem(item)
                                                }} title={item.label}>{item.label}</div>
                                                <div onClick={() => {
                                                    this.deleteItem(item.pathname)
                                                }}><CloseOutlined /> </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        false ? <div className='labelReturn'>
                            <div>
                                <img src={returnImg} alt=""></img>
                            </div>
                            <div>
                                返回
                            </div>
                        </div> : ''
                    }
                </div>
                <div>
                    <Dropdown overlay={this.menu} >
                        <div className='home_plate_right_dropdown'>
                            <div>
                                <img src={userImg} alt="" style={{ width: '46px', height: '46px' }}></img>
                            </div>
                            <div>
                                {this.state.userName}
                            </div>
                            <div>
                                <img src={bottomImg} alt=""></img>
                            </div>
                        </div>
                    </Dropdown>

                    <Modal
                        title="输入密码"
                        centered
                        visible={this.state.inputModal}
                        onOk={() => this.updatePassword()}
                        onCancel={() => this.handleModal(false)}
                        okText="确认"
                        cancelText="取消"
                        wrapClassName='userModal'
                    >
                        <Input onChange={(e) => this.InputChange(e)} />
                    </Modal>

                </div>
            </div>
        )
    }
}

export default withRouter(TopHeader)
