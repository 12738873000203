import React, { Component } from 'react'
import { message } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createProgressSettlement, progressSettlementDetail, selectMachineryList, aboutSupConfirmSelect } from "../../../utils/project"
import { selectAvailableSeal } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import { revenueMain } from "../../../utils/market"
import Folder from "../../../accets/img/min_company.png"
import Folders from "../../../accets/img/min_folder.png"
import File from "../../../accets/img/min_file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/projectProgressMeasurement.docx',
                templateName: '四川天艺生态园林集团股份有限公司进度计量审批单(工程)',
                tempData: {
                    class: '',
                    apply_date: '',
                    project_name: '',
                    con_name: '',
                    supplier: '',
                    sup_name: '',
                    con_money: '',
                    now_month_output: '',
                    month_over_output: '',
                    pay_tax: '',
                    month_pay: '',
                    stage: '',
                    progress_month: '',
                    last_month_output: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "进度计量审批",
                TableInfo: [{
                    FieldTitle: '进度计量类别', //字段标题
                    fieldName: 'class', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择进度计量类别', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "材料" },
                        { value: 2, label: "劳务" },
                        { value: 3, label: "机械" },
                        { value: 7, label: "零星材料" },
                        { value: 8, label: "双包" },
                    ]
                }, {
                    FieldTitle: '是否内部', //字段标题
                    fieldName: 'is_inside', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否内部', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                }, {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                },
                {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    isShow: true
                },
                {
                    FieldTitle: '关联材料购买清单', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true
                },
                {
                    FieldTitle: '材料购买清单', //字段标题
                    fieldName: 'sup_confirm_id', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true,
                    search: true,
                },
                {
                    FieldTitle: '选择合同', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '合同名称', //字段标题
                    fieldName: 'con_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    search: true,
                },
                {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'con_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '合同总金额', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'proName', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'proNumber', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'proManager', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '供货单位', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '本月完成产值(元)', //字段标题
                    fieldName: 'month_over_output', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入本月完成产值(元)', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '至本月累计完成产值(元)', //字段标题
                    fieldName: 'now_month_output', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '进度款支付比例(%)', //字段标题
                    fieldName: 'pay_tax', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入进度款支付比例(%)', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '本月进度款支付金额(元)', //字段标题
                    fieldName: 'month_pay', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '进度月份', //字段标题
                    fieldName: 'progress_month', //字段名称
                    fieldType: '月份',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择进度月份', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '进度月份范围', //字段标题
                    fieldName: 'progress_ranges', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
                {
                    FieldTitle: '进度期数', //字段标题
                    fieldName: 'stage', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入进度期数', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '至上月累计完成产值(元)', //字段标题
                    fieldName: 'last_month_output', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },

                ],
                FirstInfo: []
            },
            treeData: [],
            pro_id: '',
            selectedKeys: [],
            expandedKeys: [],
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            seal_name: '',
            pro_name: '',
            sup_name: '',
            con_number: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: true,
            FlowchartKey: 1,
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_progressSettlementDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    selectedKeys = []
                    expandedKeys = ['1']
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    treeData.forEach((item, index) => {
                        if (index === 0) {
                            item.children.forEach((em, ex) => {
                                if (ex === 0) {
                                    pro_id = em.children[0].key
                                    selectedKeys.push(em.children[0].key)
                                    expandedKeys.push(em.key)
                                }
                            })
                        }
                    })

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_MachineryList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_progressSettlementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        progressSettlementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.calss = res.data.Detail.calss
                        form.project_id = res.data.Detail.project_id
                        form.supplier = res.data.Detail.supplier
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                        if (res.data.Detail.class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '关联材料购买清单') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '材料购买清单') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sup_confirm_id
                                }
                            })
                        }

                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.Detail.is_seal === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                        if (res.data.Detail.class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '材料购买清单') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sup_confirm_id
                                }
                            })
                        }

                        if (res.data.Detail.status === 4) {
                            Info.tempData.class = res.data.Detail.class === 1 ? '材料' : res.data.Detail.class === 2 ? '劳务' : res.data.Detail.class === 3 ? '机械' : res.data.Detail.class === 7 ? '零星材料' : '双包'
                            Info.tempData.apply_date = res.data.Detail.created_at.slice(0, 10)
                            Info.tempData.project_name = res.data.Detail.pro_name
                            Info.tempData.con_name = res.data.Detail.con_name
                            Info.tempData.supplier = res.data.Detail.class === 1 || res.data.Detail.class === 7 ? '供货单位' : res.data.Detail.class === 2 || res.data.Detail.class === 8 ? '劳务单位' : '机械单位'
                            Info.tempData.sup_name = res.data.Detail.supplier
                            Info.tempData.con_money = res.data.Detail.con_money
                            Info.tempData.month_over_output = res.data.Detail.month_over_output
                            Info.tempData.now_month_output = res.data.Detail.now_month_output
                            Info.tempData.pay_tax = res.data.Detail.pay_tax
                            Info.tempData.month_pay = res.data.Detail.month_pay
                            Info.tempData.progress_month = res.data.Detail.progress_month
                            Info.tempData.last_month_output = res.data.Detail.last_month_output

                            if (res.data.Detail.stage) {
                                Info.tempData.stage = res.data.Detail.stage
                                Info.template='/projectProgressMeasurementStage.docx'
                            }

                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    form.sup_confirm_id = res.data.Detail.sup_confirm_id
                    form.machinery_id = res.data.Detail.machinery_id

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.FieldTitle === '合同编号') {
                            item.value = res.data.Detail.con_number
                        } else if (item.FieldTitle === '合同总金额') {
                            item.value = res.data.Detail.con_money
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManagerName
                        } else if (item.FieldTitle === '供货单位') {
                            item.value = res.data.Detail.supplier
                        } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                            item.value = res.data.Detail.now_month_output
                            form.now_month_output = res.data.Detail.now_month_output
                            form.month_over_output = res.data.Detail.month_over_output
                        } else if (item.FieldTitle === '本月进度款支付金额(元)') {
                            item.value = res.data.Detail.month_pay
                            form.month_pay = res.data.Detail.month_pay
                            form.pay_tax = res.data.Detail.pay_tax
                        } else if (item.FieldTitle === '至上月累计完成产值(元)') {
                            item.value = res.data.Detail.last_month_output
                            form.last_month_output = res.data.Detail.last_month_output
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    let progress_ranges = JSON.parse(res.data.Detail.progress_ranges)

                    this.NewForm.current.formRef.current.setFieldsValue({
                        class: res.data.Detail.class,
                        is_inside: res.data.Detail.is_inside,
                        is_seal: res.data.Detail.is_seal,
                        month_over_output: res.data.Detail.month_over_output,
                        pay_tax: res.data.Detail.pay_tax,
                        progress_month: res.data.Detail.progress_month ? moment(res.data.Detail.progress_month, 'YYYY-MM') : '',
                        start: progress_ranges[0] ? moment(progress_ranges[0], 'YYYY-MM-DD') : '',
                        end: progress_ranges[1] ? moment(progress_ranges[1], 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        stage: res.data.Detail.stage,
                        enclosure: res.data.Detail.enclosure,
                    })


                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutSupConfirmSelect = () => {
        let { dataSource, pages, sup_name, pro_name, form } = this.state

        if (!form.class) {
            message.error('进度计量类别!')
            return
        }

        let data = {
            class: form.class,
            pro_name: pro_name,
            sup_name: sup_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutSupConfirmSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.confirms.length) {
                        res.data.confirms.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                className: item.className,
                                sup_name: item.sup_name,
                                projectName: item.projectName,
                                apply_date: item.apply_date,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_MachineryList = () => {
        let { dataSource, pages, con_number, sup_name, pro_id } = this.state
        let data = {
            project_id: pro_id,
            con_number: con_number,
            con_name: '',
            sup_name: sup_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectMachineryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.machinery.length) {
                        res.data.machinery.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                apply_date: item.apply_date,
                                proNumber: item.proNumber,
                                project_id: item.project_id,
                                proName: item.proName,
                                proManager: item.proManager,
                                con_number: item.con_number,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                sup_name: item.sup_name,
                                last_month_output: item.last_month_output
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.class = data.class
        form.is_inside = data.is_inside
        form.is_seal = data.is_seal
        form.month_over_output = data.month_over_output
        form.pay_tax = data.pay_tax
        form.progress_month = data.progress_month ? data.progress_month.format('YYYY-MM') : ''
        form.progress_ranges = data.start ? [data.start.format('YYYY-MM-DD'), data.end.format('YYYY-MM-DD')] : []
        form.progress_ranges = JSON.stringify(form.progress_ranges)
        form.info = data.info
        form.stage = data.stage

        createProgressSettlement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, treeData, radio } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                treeData = []
                radio = false
                this.get_seal()
            } else if (title === '关联材料购买清单') {
                columns = [
                    {
                        title: '认价类别',
                        dataIndex: 'className',
                        key: 'className',
                    },
                    {
                        title: '认价日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'projectName',
                        key: 'projectName',
                    },
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                ]
                treeData = []
                radio = true
                this.get_aboutSupConfirmSelect()
            } else if (title === '选择合同') {
                columns = [
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同编号',
                        dataIndex: 'con_number',
                        key: 'con_number',
                    },
                    {
                        title: '合同总金额(元)',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '申请日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '至上月累计完成产值(元)',
                        dataIndex: 'last_month_output',
                        key: 'last_month_output',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_number', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                radio = true
                this.get_revenueMain()
            }

            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                treeData,
                radio,
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
                treeData: [],
                pro_id: '',
                selectedKeys: [],
                expandedKeys: [],
            })
            )
        }
    }

    handleRadio = (value, title) => {
        let { Info, form } = this.state;
        if (title === '是否盖章') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = false
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = false
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = true
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = true
                    }
                })
            }
        } else {
            form.class = value
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '关联材料购买清单') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料购买清单') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '关联材料购买清单') {
                        item.isShow = true
                    } else if (item.FieldTitle === '材料购买清单') {
                        item.isShow = true
                    }
                })
            } else if (value === 3) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '关联材料购买清单') {
                        item.isShow = true
                    } else if (item.FieldTitle === '材料购买清单') {
                        item.isShow = true
                    }
                })
            } else if (value === 7 || value === 8) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '关联材料购买清单') {
                        item.isShow = true
                    } else if (item.FieldTitle === '材料购买清单') {
                        item.isShow = true
                    }
                })
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handleInput = (value, title) => {
        let { form, Info } = this.state
        if (title === '本月完成产值(元)') {
            form.month_over_output = value

            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '至本月累计完成产值(元)') {
                    item.value = Number(form.last_month_output) + Number(form.month_over_output)
                    form.now_month_output = Number(form.last_month_output) + Number(form.month_over_output)
                }
            })

        } else if ('进度款支付比例(%)') {
            form.pay_tax = value
        }

        if (form.month_over_output && form.pay_tax) {


            console.log(0)
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '本月进度款支付金额(元)') {
                    item.value = Number(form.month_over_output) * Number(form.pay_tax) / 100
                    form.month_pay = Number(form.month_over_output) * Number(form.pay_tax) / 100
                }
            })
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        } else if (buttonTitle === '关联材料购买清单') {
            form.sup_confirm_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '材料购买清单') {
                    item.value = value[0].key
                }
            })
        } else if (buttonTitle === '选择合同') {
            form.machinery_id = value[0].key
            form.project_id = value[0].project_id
            form.supplier = value[0].sup_name
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].proName
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].proNumber
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].proManager
                } else if (item.FieldTitle === '合同编号') {
                    item.value = value[0].con_number
                } else if (item.FieldTitle === '合同名称') {
                    item.value = value[0].con_name
                } else if (item.FieldTitle === '合同总金额') {
                    item.value = value[0].con_money
                } else if (item.FieldTitle === '供货单位') {
                    item.value = value[0].sup_name
                } else if (item.FieldTitle === '至上月累计完成产值(元)') {
                    item.value = value[0].last_month_output
                    form.last_month_output = value[0].last_month_output
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        } else if (buttonTitle === '关联材料购买清单') {
            this.setState({
                pro_name: value.pro_name,
                sup_name: value.sup_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_aboutSupConfirmSelect()
            })
        } else if (buttonTitle === '选择合同') {
            this.setState({
                con_number: value.con_number,
                sup_name: value.sup_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_MachineryList()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            seal_name: '',
            pro_name: '',
            sup_name: '',
            con_number: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '关联材料购买清单') {
                this.get_aboutSupConfirmSelect()
            } else if (buttonTitle === '选择合同') {
                this.get_MachineryList()
            }
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_MachineryList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handleSearch = (title) => {
        let { form } = this.state
        if (title === '材料购买清单') {
            if (!form.sup_confirm_id) {
                message.error('未关联材料购买清单!')
                return
            }
            this.props.history.push({
                pathname: '/purchase/supplier/audit/NewItem',
                query: {
                    'tableId': form.sup_confirm_id,
                }
            })
        } else {
            if (!form.machinery_id) {
                message.error('未关联合同!')
                return
            }
            this.props.history.push({
                pathname: '/project/contract/NewItem',
                query: {
                    'tableId': form.machinery_id,
                }
            })
        }
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey, treeData, selectedKeys, expandedKeys } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} InputEvent={this.handleInput} searchEvent={this.handleSearch} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} isTree={treeData.length ? true : false} treeData={treeData} selectedKeys={selectedKeys} expandedKeys={expandedKeys} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} onSelectEvent={this.onSelect} onExpandEvent={this.onExpand} />
            </div>
        )
    }
}
