import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Append from '../../components/addAppend'
import { GeneralInventoryList,EditGeneralInventory } from "../../../utils/purchase"

export default class Tree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '时 间',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
                {
                    title: '材料名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '长 度',
                    dataIndex: 'diameter',
                    key: 'diameter',
                },
                {
                    title: '宽 度',
                    dataIndex: 'height',
                    key: 'height',
                },
                {
                    title: '厚 度',
                    dataIndex: 'crown',
                    key: 'crown',
                },
                {
                    title: '源产地',
                    dataIndex: 'origin',
                    key: 'origin',
                },
                {
                    title: '单 位',
                    dataIndex: 'unit',
                    key: 'unit',
                },
                {
                    title: '单 价',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '备 注',
                    dataIndex: 'info',
                    key: 'info',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '名 称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                },
            ],
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_GeneralInventoryList()
    }

    get_GeneralInventoryList = () => {
        let { dataSource, pages, name } = this.state
        let data = {
            class: '石材',
            name: name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        GeneralInventoryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.general.length) {
                        res.data.general.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.id,
                                apply_date: item.apply_date,
                                name: item.name,
                                diameter: item.diameter,
                                height: item.height,
                                crown: item.crown,
                                unit: item.unit,
                                price: item.price,
                                origin: item.origin,
                                info: item.info,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, pages, TableInfo } = this.state
        return (
            <div>
                <Operate dontAdd={true} notPrint={true} educe={true} dontImport={true}  searchEvent={this.handleSearch} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow}/>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />

            </div>
        )
    }
}
