import React, { Component } from 'react'
import axios from 'axios'
import md5 from 'md5'
import { withRouter } from "react-router-dom";
import { Upload, Button, Checkbox, Tree, message, Modal, Progress } from 'antd';
import Icon, { EyeOutlined, DownloadOutlined, VerticalAlignTopOutlined, PaperClipOutlined } from '@ant-design/icons';
import { revenueMain } from "../../../utils/market"
import { BidWinningList, createBidWinningList } from "../../../utils/cost"
import { fileDownload, filePreview, BulkDownload } from "../../../utils/api"
import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pro_id: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            masterList: [],
            fileList: [],
            file: {},
            fileChunkList: [],
            isProgress: false,
            percent: 0,
            fileMd5:''
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_BidWinningList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BidWinningList = () => {
        let { pro_id } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            class: 'list'
        }
        BidWinningList(data).then(
            (res) => {
                if (!res.code) {
                    this.setState({
                        masterList: res.data.list
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleBidWinning = (attId) => {
        let { pro_id } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            class: 'list',
            file_id: attId
        }
        createBidWinningList(data).then(
            (res) => {
                if (!res.code) {
                    message.success('上传成功')
                    this.get_BidWinningList()
                } else {
                    message.error('上传失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_BidWinningList()
        })
    }

    onExpand = (value) => {
        console.log(value)
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    // 文件上传改变事件
    updateChange = (info) => {
        console.log(info)
        if (info.file.status === 'done') {
            if (info.file.response) {
                let att_id = info.file.response.data.att_id
                this.handleBidWinning(att_id)
            }
        } else if (info.file.status === 'error') {
            message.error('上传失败')
        }
    }

    updateChange = ({ file, fileList }) => {
        this.setState(preState => ({
            file: file
        }), () => {
            this.createFileChunk()
        })
    }

    createFileChunk = () => {
        let { file, fileChunkList, isProgress } = this.state
        let chunkSize = 1024 * 1024  // 切片每次1M
        let filecount = Math.ceil(file.size / chunkSize)
        let currentChunk = 0 // 当前上传的chunk
        let index = 0
        fileChunkList = []
        while (currentChunk < file.size) {
            const chunk = file.slice(currentChunk, currentChunk + chunkSize);
            fileChunkList.push({ file: chunk, index, filecount }); // 可以加入很多信息。比如MD5
            currentChunk += chunkSize;
            index += 1;
        }

        if (filecount > 5) {
            isProgress = true
        }

        fileChunkList.forEach((item, index) => {
            if (index === 0) {
                let reader = new FileReader()
                let fileDate = item.file
                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    this.setState(preState => ({
                        fileChunkList,
                        isProgress,
                        fileMd5: md5(e.target.result)
                    }), () => {
                        this.fileUpload(0)
                    })
                }
            }
        })
    }

    fileUpload = (uploadIndex) => {
        let { file, fileChunkList, fileList, storeList, fileMd5 } = this.state
        fileChunkList.forEach((item, index) => {//分片传输
            if (index === uploadIndex) {
                let reader = new FileReader()
                let fileDate = item.file
                let data = {
                    file: '',
                    name: file.name,
                    index: item.index,
                    filecount: item.filecount,
                    MD5: fileMd5,
                    is_download: 1
                }

                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    data.file = e.target.result
                    axios({
                        method: 'post',
                        url: '/api/fileUpload',
                        data: data,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem("Token")
                        }
                    }).then((res) => {
                        if (!res.data.code) {
                            if (res.data.data.result) {
                                let index = res.data.data.index
                                this.fileUpload(index)

                                this.setState(preState => ({
                                    percent: Math.ceil((100 / item.filecount) * index)
                                })
                                )
                            } else {
                                this.setState(preState => ({
                                    fileList,
                                    storeList,
                                    percent: 0,
                                    isProgress: false,
                                    fileMd5:''
                                }),()=>{
                                    this.handleBidWinning(res.data.data.att_id)
                                })
                            }
                        }
                    }).catch((err) => {
                        message.error("上传失败，请稍后重试！");
                        return;
                    })
                }
            }
        })
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.table_id
        }
        fileDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.click()
                } else {
                    message.error(res.data.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    checkChange = (value) => {
        this.setState(preState => ({
            fileList: value
        })
        )
    };

    batch_download = () => {
        let { fileList } = this.state
        if (!fileList.length) {
            message.error('请选择下载文件')
            return
        }
        let data = {
            file_ids: JSON.stringify(fileList)
        }
        BulkDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.href = res.data.path
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { treeData, expandedKeys, selectedKeys, masterList ,isProgress,percent} = this.state
        return (
            <div className='contract_List'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='ListOperate'>
                        <Upload
                            showUploadList={false}
                            beforeUpload={(file, fileList) => {
                                return false
                            }}
                            onChange={this.updateChange}
                        >
                            <Button style={{ marginLeft: 0 }}>
                                <VerticalAlignTopOutlined />
                                <div>上传</div>
                            </Button>
                        </Upload>
                        <Button style={{ width: '120px' }} onClick={() => {
                            this.batch_download()
                        }}>
                            <PaperClipOutlined />
                            <div>批量下载</div>
                        </Button>
                    </div>
                    <Checkbox.Group
                        style={{
                            width: '100%',
                        }}
                        onChange={this.checkChange}
                    >
                        <div className='uploadList'>
                            <div>
                                {
                                    masterList.length ? masterList.map((item, index) => {
                                        return (
                                            <div style={{ width: '47%' }} key={index}>
                                                <div></div>
                                                <div style={{ width: '92%' }}>
                                                    <div>
                                                        <Checkbox value={item.table_id} style={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: '#333333'
                                                        }}>{item.filename}</Checkbox>
                                                    </div>
                                                    <div>
                                                        <div style={{ marginLeft: '24px' }}>{item.name}于{item.created_at}上传</div>
                                                        <div>
                                                            <EyeOutlined style={{ color: "#339EF9", marginRight: '10px' }} onClick={() => {
                                                                this.get_filePreview(item)
                                                            }} />
                                                            <DownloadOutlined style={{ color: "#339EF9" }} onClick={() => {
                                                                this.get_download(item)
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </div>
                        </div>
                    </Checkbox.Group>
                </div>

                <Modal
                    title='上传进度'
                    centered
                    visible={isProgress}
                    onCancel={() => this.handleModal()}
                    footer={[]}
                >
                    <Progress percent={percent} />
                </Modal>
            </div >
        )
    }
}

export default withRouter(List)
