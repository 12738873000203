import React, { Component } from 'react'
import { message } from 'antd';
// import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { reviewApplyResult } from "../../../utils/api"
import { estimatesMarkProjectDetail, createEstimatesMarkProject } from "../../../utils/market"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "概算项目任务申请",
                TableInfo: [],
                FirstInfo: [
                    {
                        plateName: '项目基本信息',
                        list: [
                            {
                                FieldTitle: '项目名称', //字段标题
                                fieldName: 'pro_name', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入项目名称', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '概算周期', //字段标题
                                fieldName: 'cycle', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入概算周期', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '建设单位及注册资本', //字段标题
                                fieldName: 'project', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入建设单位及注册资本', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '项目地址及概况', //字段标题
                                fieldName: 'capital', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入项目地址及概况', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '备注', //字段标题
                                fieldName: 'info', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'enclosure', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                        ],
                    },
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            form: {},
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_estimatesMarkProjectDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_estimatesMarkProjectDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        estimatesMarkProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '附件') {
                                    em.isShow = true
                                }
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        cycle: res.data.Detail.cycle,
                        project: res.data.Detail.project,
                        capital: res.data.Detail.capital,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure, 
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.cycle = data.cycle
        form.pro_info = data.pro_info
        form.project = data.project
        form.capital = data.capital
        form.info = data.info

        createEstimatesMarkProject(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} auditEvent={this.handleButton} />
            </div>
        )
    }
}