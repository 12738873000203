import React, { Component } from 'react'
import { Tree, message, Modal, Table, Input, Button, Upload } from 'antd';
import axios from 'axios'
import Icon, { PieChartOutlined, DownloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Append from '../../components/addAppend'
import Search from '../../components/Search'
import UserTable from '../../components/Table'
import { revenueMain } from "../../../utils/market"
import { selectBudgetTable, projectCostStatistics, EditCostBudget, EditBudgetTable } from "../../../utils/cost"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '名 称',
                    dataIndex: 'name',
                    key: 'name',
                    width: '140px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '规格型号',
                    dataIndex: 'model',
                    key: 'model',
                    width: '140px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '计量单位',
                    dataIndex: 'unit',
                    key: 'unit',
                },
                {
                    title: '数 量',
                    dataIndex: 'number',
                    key: 'number',
                },
                {
                    title: '预算单价',
                    dataIndex: 'unit_price',
                    key: 'unit_price',
                },
                {
                    title: '综合单价',
                    dataIndex: 'award_price',
                    key: 'award_price',
                },
                {
                    title: '总 价',
                    dataIndex: 'money',
                    key: 'money',
                },
                {
                    title: '备 注',
                    dataIndex: 'info',
                    key: 'info',
                },

            ],
            TableInfo: [
                {
                    FieldTitle: '名 称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            pages: {
                limit: 20,
                page: 1,
                total: 0
            },
            summaryValue: {
                colspan: 9,
                name: '总价合计(元)',
                value: 0
            },
            pro_id: '',
            pro_name: '',
            classType: 1,
            name: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            uploadUrl: {
                url: '/api/ImportTable',
                data: {
                    project_id: '',
                    class: 1
                }
            },
            sum_columns: [
                {
                    title: '成本项目',
                    dataIndex: 'cost_items',
                    key: 'cost_items',
                    render: (text, record, index) => {
                        if (index === 0) {
                            return {
                                children: text,
                                props: { rowSpan: 4 }
                            }
                        } else if (index === 4) {
                            return {
                                children: text,
                                props: { rowSpan: 2 }
                            }
                        } else if (index === 1 || index === 2 || index === 3 || index === 5) {
                            return {
                                children: text,
                                props: { rowSpan: 0 }
                            }
                        } else if (index === 6 || index === 8) {
                            return {
                                children: text,
                                props: { colSpan: 5 }
                            }
                        } else {
                            return text
                        }
                    }
                },
                {
                    title: '项目类别',
                    dataIndex: 'class',
                    key: 'class',
                    render: (text, record, index) => {
                        if (index === 6 || index === 8) {
                            return {
                                children: text,
                                props: { colSpan: 0 }
                            }
                        } if (index === 7) {
                            if (this.state.summaryEdit) {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>增值税税率(%)</div>
                                        <Input style={{ width: '150px', marginLeft: '10px' }} defaultValue={text} onChange={(e) => this.taxChange(e)} />
                                    </div>
                                )
                            } else {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>增值税税率(%)  {text}</div>
                                    </div>
                                )
                            }
                        } else {
                            return text
                        }
                    }
                },
                {
                    title: '预算金额(元)',
                    dataIndex: 'budget',
                    key: 'budget',
                    render: (text, record, index) => {
                        if (index === 6 || index === 8) {
                            return {
                                children: text,
                                props: { colSpan: 0 }
                            }
                        } else if (index === 11) {
                            if (this.state.summaryEdit) {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input style={{ width: '150px', marginLeft: '10px' }} defaultValue={text} onChange={(e) => this.profitChange(e)} />
                                        <div>%</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>{text}%</div>
                                )
                            }
                        } else {
                            return text
                        }
                    }
                },
                {
                    title: '百分比(%)',
                    dataIndex: 'percentage',
                    key: 'percentage',
                    render: (text, record, index) => {
                        if (index === 6 || index === 8) {
                            return {
                                children: text,
                                props: { colSpan: 0 }
                            }
                        } else {
                            return text
                        }
                    }
                },
                {
                    title: '',
                    dataIndex: 'operation',
                    key: 'operation',
                    render: (text, record, index) => {
                        if (index < 6) {
                            return (
                                <div style={{ color: '#339EF9', fontSize: '14px', cursor: 'pointer' }} onClick={() => this.get_tabIndex(record.key)}>详情</div>
                            )
                        } else if (index === 6 || index === 8) {
                            return {
                                children: text,
                                props: { colSpan: 0 }
                            }
                        } else {
                            return <div></div>
                        }
                    }
                },
            ],
            sum_dataSource: [
                {
                    key: 1,
                    cost_items: '一、直接费用',
                    class: '1、材料',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 3,
                    cost_items: '一、直接费用',
                    class: '2、机械',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 2,
                    cost_items: '一、直接费用',
                    class: '3、劳务',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 4,
                    cost_items: '一、直接费用',
                    class: '4、措施费',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 5,
                    cost_items: '二、间接费',
                    class: '1、规费',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 6,
                    cost_items: '二、间接费',
                    class: '2、管理费',
                    budget: '/',
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 7,
                    cost_items: '预算成本合计',
                    class: '',
                    budget: '',
                    percentage: '',
                    operation: ''
                },
                {
                    key: 8,
                    cost_items: '三、预计税费',
                    class: 0,
                    budget: 0,
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 9,
                    cost_items: '预算总成本合计',
                    class: '',
                    budget: '',
                    percentage: '',
                    operation: ''
                },
                {
                    key: 10,
                    cost_items: '四、目标利润',
                    class: '/',
                    budget: 0,
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 11,
                    cost_items: '五、目标利润率',
                    class: '/',
                    budget: 0,
                    percentage: '/',
                    operation: ''
                },
                {
                    key: 12,
                    cost_items: '六、预估利润率',
                    class: '/',
                    budget: 0,
                    percentage: '/',
                    operation: ''
                },
            ],
            summary: {
                totalMoney: 771996229.6,
                set_money: 200000.00,
                temporary: 200000.00,
            },
            SummaryVisible: false,
            isEdit: false,
            summaryEdit: false,
            tableKey: 1,
            modal_Visible: false,
            Info: {
                state: '新增',
                FlowModelName: "成本预算明细表",
                TableInfo: [{
                    FieldTitle: '名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '规格型号', //字段标题
                    fieldName: 'model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '计量单位', //字段标题
                    fieldName: 'unit', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '数量', //字段标题
                    fieldName: 'number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '预算单价(元)', //字段标题
                    fieldName: 'unit_price', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合同综合单价', //字段标题
                    fieldName: 'award_price', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            },
            form: {},
            appendKey: 1,
            selectValue: [],
            templateList: [],
            templateShow: false
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, pro_name, selectedKeys, expandedKeys, uploadUrl } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (this.props.location.query) {
                        pro_id = this.props.location.query.tableId
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            item.children.forEach((em, ex) => {
                                em.children.forEach((m, x) => {
                                    if (m.key === pro_id) {
                                        uploadUrl.data.project_id = m.key
                                        pro_name = m.title
                                        selectedKeys.push(m.key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            })
                        })
                    } else {
                        if (!pro_id) {
                            selectedKeys = []
                            expandedKeys = ['1']
                            treeData.forEach((item, index) => {
                                if (index === 0) {
                                    item.children.forEach((em, ex) => {
                                        if (ex === 0) {
                                            uploadUrl.data.project_id = em.children[0].key
                                            pro_id = em.children[0].key
                                            pro_name = em.children[0].title
                                            selectedKeys.push(em.children[0].key)
                                            expandedKeys.push(em.key)
                                        }
                                    })
                                }
                            })
                        }
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        pro_name,
                        uploadUrl,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_BudgetTable()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BudgetTable = () => {
        let { pro_id, name, classType, dataSource, pages, summaryValue } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            class: classType,
            name: name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectBudgetTable(data).then(
            (res) => {
                if (!res.code) {
                    summaryValue.value = res.data.totalMoney
                    dataSource = []
                    if (res.data.content.length) {
                        res.data.content.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                id: item.id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                unit_price: item.unit_price,
                                award_price: item.award_price,
                                money: item.money,
                                info: item.info
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        summaryValue,
                        pages: res.data.pages,
                        templateList: res.data.template
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // 文件上传改变事件
    updateChange = (info) => {
        if (info.file.status === 'done') {
            if (!info.file.response.code) {
                message.success('导入成功')
                this.get_BudgetTable()
            } else if (info.file.response.code === 1004) {
                message.error('权限受限，请联系管理员!')
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    handleNew = () => {
        let { Info, form } = this.state
        form.id = ''
        Info = {
            state: '新增',
            FlowModelName: "成本预算明细表",
            TableInfo: [{
                FieldTitle: '名 称', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '规格型号', //字段标题
                fieldName: 'model', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '计量单位', //字段标题
                fieldName: 'unit', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '数 量', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '预算单价(元)', //字段标题
                fieldName: 'unit_price', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '合同综合单价', //字段标题
                fieldName: 'award_price', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: []
        }
        this.setState({
            Info,
            form,
            modal_Visible: true,
            appendKey: Math.random(),
        })
    }

    handleEdits = () => {
        let { selectValue, form } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                form.id = selectValue[0].id
                this.setState({
                    modal_Visible: true,
                    form
                }, () => {
                    this.NewForm.current.formRef.current.setFieldsValue({
                        name: selectValue[0].name,
                        model: selectValue[0].model,
                        unit: selectValue[0].unit,
                        number: selectValue[0].number,
                        unit_price: selectValue[0].unit_price,
                        award_price: selectValue[0].award_price,
                        // money: selectValue[0].money,
                        info: selectValue[0].info,
                    })
                })
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    appendCancel = () => {
        this.setState({
            modal_Visible: false
        })
    }

    handPresent = (data) => {
        const { form, pro_id, classType } = this.state;
        form.project_id = pro_id
        form.class = classType
        form.name = data.name
        form.model = data.model
        form.unit = data.unit
        form.number = data.number
        form.unit_price = data.unit_price
        form.award_price = data.award_price
        // form.money = data.money
        form.info = data.info

        EditBudgetTable(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        modal_Visible: false
                    }, () => {
                        this.get_BudgetTable()
                    })
                } else {
                    message.error(res.data[0] || '保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        // this.props.history.push({
        //     pathname: '/market/contract/NewItem',
        //     query: {
        //         'tableId': tableId,
        //     }
        // })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 20,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_BudgetTable()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name
        }, () => {
            this.get_BudgetTable()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_BudgetTable()
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    onSelect = (value) => {
        let { uploadUrl, pro_id, pro_name, treeData } = this.state
        uploadUrl.data.project_id = value[0]
        pro_id = value[0]
        treeData.forEach((item, index) => {
            item.children.forEach((em, ex) => {
                em.children.forEach((m, x) => {
                    if (m.key === pro_id) {
                        pro_name = m.title
                    }
                })
            })
        })
        this.setState(preState => ({
            selectedKeys: value,
            pro_id,
            pro_name,
            uploadUrl
        }), () => {
            this.get_BudgetTable()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    get_tabIndex = (index) => {
        let { uploadUrl } = this.state
        uploadUrl.data.class = index
        this.setState(preState => ({
            classType: index,
            uploadUrl,
            SummaryVisible: false,
            pages: {
                limit: 20,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_BudgetTable()
        })
    }

    handleSummary = () => {
        let { pro_id, sum_dataSource, summary, isEdit } = this.state
        let permission = JSON.parse(localStorage.getItem("permission"))
        permission.forEach((item, index) => {
            if (item === 'EditCostBudget') {
                isEdit = true
            }
        })
        let data = {
            project_id: pro_id,
        }
        projectCostStatistics(data).then(
            (res) => {
                if (!res.code) {
                    summary.set_money = res.data.set_money
                    summary.totalMoney = (Number(res.data.totalMoney) + Number(res.data.tax_money)).toFixed(2)
                    summary.temporary = res.data.temporary
                    sum_dataSource.forEach((item, index) => {
                        if (index < 6) {
                            item.budget = res.data.statistics[index + 1] ? res.data.statistics[index + 1].money : '/'
                            item.percentage = res.data.statistics[index + 1] ? res.data.statistics[index + 1].percentage : '/'
                        } else if (index === 6) {
                            item.cost_items = '预算成本合计：' + ' ' + res.data.totalMoney
                        } else if (index === 7) {
                            item.class = res.data.tax
                            item.budget = res.data.tax_money
                        } else if (index === 8) {
                            item.cost_items = '预算总成本合计：' + ' ' + summary.totalMoney
                        } else if (index === 9) {
                            item.budget = res.data.target_profit
                        } else if (index === 10) {
                            item.budget = res.data.profit_tax + '%'
                        } else if (index === 11) {
                            item.budget = res.data.estimated_profit ? res.data.estimated_profit : '0'
                        }
                    })
                    this.setState({
                        summary,
                        sum_dataSource,
                        SummaryVisible: true,
                        isEdit,
                        tableKey: Math.random(),
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        this.setState({
            SummaryVisible: false,
            isEdit: false,
            summaryEdit: false,
        })
    }

    handleEdit = () => {
        this.setState({
            summaryEdit: true,
            tableKey: Math.random(),
        })
    }

    temporaryChange = (e) => {
        let { summary } = this.state
        summary.temporary = e.target.value
        this.setState({
            summary
        })
    }

    taxChange = (e) => {
        let { sum_dataSource } = this.state
        sum_dataSource.forEach((item, index) => {
            if (index === 7) {
                item.class = e.target.value
            }
        })
        this.setState({
            sum_dataSource
        })
    }

    profitChange = (e) => {
        let { sum_dataSource } = this.state
        sum_dataSource.forEach((item, index) => {
            if (index === 11) {
                item.budget = e.target.value
            }
        })
        this.setState({
            sum_dataSource
        })
    }

    set_CostBudget = () => {
        let { pro_id, sum_dataSource, summary } = this.state
        let tax = ''
        let estimated_profit = ''
        sum_dataSource.forEach((item, index) => {
            if (index === 7) {
                tax = item.class
            } else if (index === 11) {
                estimated_profit = item.budget
            }
        })
        let data = {
            project_id: pro_id,
            temporary: summary.temporary,
            tax: tax,
            estimated_profit: estimated_profit
        }
        EditCostBudget(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        SummaryVisible: false,
                        isEdit: false,
                        summaryEdit: false,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleTemplate = () => {
        this.setState({
            templateShow: true,
        })
    }

    cancelTemplate = () => {
        this.setState({
            templateShow: false,
        })
    }

    handlePath = (path) => {
        let link = document.createElement("a")
        link.href = path
        link.click()
    }

    clearLabelDerivation = () => {
        const { pro_id, classType } = this.state;
        let data = {
            project_id: pro_id,
            class: classType
        }
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            let derive = false
            permission.forEach((item, index) => {
                if (item === 'exportProjectBudTable') {
                    derive = true
                }
            })
            if (derive) {
                axios.get('/api/exportProjectBudTable', {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("Token")
                    },
                    responseType: 'blob',
                    params: data,
                }).then((res) => {
                    const blob = new Blob([res.data], {
                        type: 'application/vnd.ms-excel;charset=utf-8'
                    })
                    const link = document.createElement('a');
                    link.download = '成本预算明细表.xlsx'
                    link.href = URL.createObjectURL(blob)
                    link.target = "_blank"
                    document.body.appendChild(link)
                    link.click();
                    //释放URL对象
                    URL.revokeObjectURL(link.href);
                    document.body.removeChild(link);
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                message.error('暂无导出权限，请联系管理员')
            }
        }
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, pages, summaryValue, treeData, expandedKeys, selectedKeys, uploadUrl, classType, sum_columns, sum_dataSource, SummaryVisible, summary, isEdit, summaryEdit, tableKey, pro_name, Info, modal_Visible, appendKey, templateList, templateShow, pro_id } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <div className='cost_budgeting'>
                            <div className='history ListOperate' style={{ width: 'auto', marginRight: '15px' }}>
                                <Button onClick={() => {
                                    this.handleSummary()
                                }} style={{ marginLeft: 0, width: '200px' }}>
                                    <PieChartOutlined />
                                    <div>成本预算汇总表</div>
                                </Button>
                            </div>
                            <Operate notPrint={true} educe={true} edit={true} template={true} searchEvent={this.handleSearch} newEvent={this.handleNew} editEvent={this.handleEdits} printEvent={this.handlePrint} exportEvent={this.handleExport} uploadEvent={this.updateChange} searchShow={searchShow} uploadUrl={uploadUrl} templateEvent={this.handleTemplate} />
                        </div>

                        <div className='supplier_right supplier_style'></div>
                    </div>

                    <div className='labor_search'>
                        <div className='cost_budgeting'>
                            <div className='ListOperate'>
                                <Button style={{ marginLeft: 0, display: classType === 1 || classType === 2 || classType === 8 ? ' ' : 'none' }} onClick={() => { this.clearLabelDerivation() }}>
                                    <DownloadOutlined />
                                    <div>清标导出</div>
                                </Button>
                                {/* <Button>
                                    <VerticalAlignTopOutlined />
                                    <div>清标导入</div>
                                </Button> */}
                                <Upload name='brightMoon'
                                    action='/api/importProjectBudTable' //上传文件地址
                                    headers={{
                                        "Authorization": "Bearer " + localStorage.getItem("Token")
                                    }}
                                    data={{
                                        project_id: pro_id,
                                        class: classType
                                    }}
                                    showUploadList={false}
                                    onChange={(e) => {
                                        this.updateChange(e)
                                    }}   //上传状态改变事件

                                >
                                    <Button style={{ display: classType === 1 || classType === 2 || classType === 8 ? ' ' : 'none' }}>
                                        <VerticalAlignTopOutlined />
                                        <div>清标导入</div>
                                    </Button>
                                </Upload>
                            </div>
                        </div>

                        <div className='supplier_right supplier_style'>
                            <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(1)
                            }}>材料</div>
                            <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(2)
                            }}>劳务</div>
                            <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(3)
                            }}>机械</div>
                            <div className={classType === 4 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(4)
                            }}>措施费</div>
                            <div className={classType === 5 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(5)
                            }}>规费</div>
                            <div className={classType === 6 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(6)
                            }}>管理费</div>
                            <div className={classType === 7 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(7)
                            }}>辅料</div>
                            <div className={classType === 8 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(8)
                            }}>双包</div>
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }


                    <UserTable summary={true} columns={columns} dataSource={dataSource} pages={pages} summaryValue={summaryValue} dataLimit={this.handleLimit} selectEvent={this.handSelect} />


                    <Modal
                        title=''
                        centered
                        visible={SummaryVisible}
                        onCancel={() => this.modalCancel()}
                        wrapClassName='summaryModal'
                        maskClosable={false}
                        footer={null}
                    >
                        <div>
                            <div>{pro_name}</div>

                            <div>
                                <div className='summaryTable'>
                                    <div>
                                        <div>合同金额(元)</div>
                                        <div>{summary.set_money}</div>
                                    </div>

                                    <div>
                                        <div>预算总成本(元)</div>
                                        <div>{summary.totalMoney}</div>
                                    </div>

                                    <div>
                                        <div>暂列金额(元)</div>
                                        <div>
                                            {
                                                summaryEdit ? <Input style={{ width: '200px', height: '40px', fontSize: '25px' }} defaultValue={summary.temporary} onChange={(e) => this.temporaryChange(e)} /> : <div>{summary.temporary}</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <Table key={tableKey} columns={sum_columns} dataSource={sum_dataSource} pagination={false} rowClassName={(record, index) => {
                                    let className = 'light-row';
                                    if (index === 6 || index === 8) className = 'dark-row';
                                    return className;
                                }} />
                            </div>

                            <div>
                                {
                                    summaryEdit && isEdit ? <div onClick={() => this.set_CostBudget()}>保存</div> : isEdit ? <div onClick={() => this.handleEdit()}>编辑</div> : ''
                                }
                            </div>
                        </div>
                    </Modal>

                    <Append key={appendKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.appendCancel} />


                    <Modal
                        title="下载模板"
                        centered
                        visible={templateShow}
                        onCancel={() => this.cancelTemplate()}
                        wrapClassName='userModal template'
                        maskClosable={false}
                        footer={[]}
                    >
                        {
                            templateList.map((item, index) => {
                                return (
                                    <div onClick={() => {
                                        this.handlePath(item.filePath)
                                    }}>
                                        {item.file_name}
                                    </div>
                                )
                            })
                        }
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withRouter(List)
