import React, { Component } from 'react'
import { Modal, Tree } from 'antd';
import Search from './Search'
import Table from './Table'

export default class InitialValue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectValue: [],
        }
    }

    handleCancel = () => {
        this.props.cancelEvent()
    }

    searchQuery = (value) => {
        this.props.searchEvent(value)
    }

    handeSelect = (value) => {
        this.setState(preState => ({
            selectValue: value
        }))
    }

    handleEvent = () => {
        this.props.selectEvent(this.state.selectValue)
    }

    handleLimit = (page, pageSize) => {
        this.props.limitEvent(page, pageSize)
    }

    onSelect = (value) => {
        this.props.onSelectEvent(value)
    }

    onExpand = (value) => {
        this.props.onExpandEvent(value)
    }

    render() {
        let { modal_Visible, dataSource, columns, TableInfo, pages, radio, isTree, treeData, expandedKeys, selectedKeys } = this.props;

        return (
            <Modal
                title="基础资料"
                centered
                visible={modal_Visible}
                onCancel={() => this.handleCancel()}
                wrapClassName='initialValue'
                maskClosable={false}
                footer={[]}
            >
                {
                    isTree ? <div className='TreeScroll'>
                        <Tree
                            showLine={{ showLeafIcon: false }}
                            showIcon={true}
                            expandedKeys={expandedKeys}
                            selectedKeys={selectedKeys}
                            defaultExpandParent={true}
                            treeData={treeData}
                            onSelect={(value) => {
                                this.onSelect(value)
                            }}
                            onExpand={(value) => {
                                this.onExpand(value)
                            }}
                        />
                    </div> : ''
                }

                <div className='filter'>
                    <Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} handleEvent={this.handleEvent} TableInfo={TableInfo} />
                </div>


                <div style={{ height: '520px',overflow:'auto' }}>
                    <Table columns={columns} dataSource={dataSource} radio={radio} selectEvent={this.handeSelect} pages={pages} dataLimit={this.handleLimit} />
                </div>


            </Modal>
        )
    }
}
