import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../../../components/AddConfig'
import InitialValue from '../../../../../components/InitialValue'
import { CenRestructureDetail, createCenRestructure, OilCardList, operatorList, CenPlantList } from "../../../../../../utils/purchase"
import { BlankingSelectProject } from "../../../../../../utils/project"
import { reviewApplyResult } from "../../../../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "调拨申请",
                TableInfo: [{
                    FieldTitle: '调拨人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '调拨日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择调拨日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '调拨类型', //字段标题
                    fieldName: 'class', //字段名称
                    fieldType: '多选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    checkboxList: [
                        {
                            label: '机械',
                            value: 1
                        },
                        {
                            label: '机操手',
                            value: 2
                        },
                        {
                            label: '油卡',
                            value: 3
                        }
                    ]
                }, {
                    FieldTitle: '选择机械', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                    isShow: true
                }, {
                    FieldTitle: '机械名称', //字段标题
                    fieldName: 'plant_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                }, 
                {
                    FieldTitle: '机械代码', //字段标题
                    fieldName: 'plantCode', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                }, 
                {
                    FieldTitle: '选择机操手', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                    isShow: true
                }, {
                    FieldTitle: '机操手名称', //字段标题
                    fieldName: 'oper_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                }, {
                    FieldTitle: '选择油卡', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                    isShow: true
                }, {
                    FieldTitle: '油卡编号', //字段标题
                    fieldName: 'oil_code', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                },
                {
                    FieldTitle: '调拨至项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: [],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            plant_name: '',
            oper_user: '',
            pro_name: '',
            com_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
        }

    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_CenRestructureDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '调拨人') {
                item.value = userInfo.name
            }
        })

        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_CenRestructureDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        CenRestructureDetail(data).then(
            (res) => {
                if (!res.code) {
                    let value = JSON.parse(res.data.Detail.class)
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '选择机械') {
                            item.isShow = true
                        } else if (item.FieldTitle === '机械名称') {
                            item.isShow = true
                        } else if (item.FieldTitle === '机械代码') {
                            item.isShow = true
                        } else if (item.FieldTitle === '选择机操手') {
                            item.isShow = true
                        } else if (item.FieldTitle === '机操手名称') {
                            item.isShow = true
                        } else if (item.FieldTitle === '选择油卡') {
                            item.isShow = true
                        } else if (item.FieldTitle === '油卡编号') {
                            item.isShow = true
                        }
                    })
                    value.forEach((item, index) => {
                        if (item === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择机械') {
                                    item.isShow = false
                                } 
                                else if (item.FieldTitle === '机械名称') {
                                    item.isShow = false
                                }else if (item.FieldTitle === '机械代码') {
                                    item.isShow = false
                                }
                            })
                        } else if (item === 2) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择机操手') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '机操手名称') {
                                    item.isShow = false
                                }
                            })
                        } else if (item === 3) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择油卡') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '油卡编号') {
                                    item.isShow = false
                                }
                            })
                        }
                    })

                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.plant_id = res.data.Detail.plant_id || ''
                        form.oper_user = res.data.Detail.oper_user || ''
                        form.oil_id = res.data.Detail.oil_id || ''
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择机械') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择机操手') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择油卡') {
                                item.isShow = true
                            } else if (item.FieldTitle === '调拨至项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '调拨人') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '机械名称') {
                            item.value = res.data.Detail.platName || ''
                        } else if (item.FieldTitle === '机械代码') {
                            item.value = res.data.Detail.platCode || ''
                        } else if (item.FieldTitle === '机操手名称') {
                            item.value = res.data.Detail.operUsername || ''
                        } else if (item.FieldTitle === '油卡编号') {
                            item.value = res.data.Detail.oilCode || ''
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.Detail.apply_day ? moment(res.data.Detail.apply_day, 'YYYY-MM-DD') : '',
                        class: JSON.parse(res.data.Detail.class),
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_CenPlantList = () => {
        let { dataSource, pages, plant_name } = this.state
        let data = {
            name: plant_name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        CenPlantList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.plants.length) {
                        res.data.plants.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                code: item.plantCode,
                                phone: item.phone,
                                name: item.plantName,
                                buy_day: item.buy_day,
                                review_day: item.review_day,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_operatorList = () => {
        let { dataSource, pages, oper_user } = this.state
        let data = {
            name: oper_user,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        operatorList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.operators.length) {
                        res.data.operators.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                name: item.operName,
                                phone: item.phone,
                                class: item.oper_class,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_OilCardList = () => {
        let { dataSource, pages, com_name, } = this.state
        let data = {
            com_name: com_name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        OilCardList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.operators.length) {
                        res.data.operators.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                com_name: item.com_name,
                                oil_code: item.oil_code,
                                oil_number: item.oil_number,
                                use_range: item.use_range,
                                use_status: item.use_status,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.class = JSON.stringify(data.class)
        form.info = data.info

        createCenRestructure(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择机械') {
                columns = [
                    {
                        title: '机械代码',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '机械名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '购买日期',
                        dataIndex: 'buy_day',
                        key: 'buy_day',
                    },
                    {
                        title: '车审日期',
                        dataIndex: 'review_day',
                        key: 'review_day',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '机械名称', //字段标题
                        fieldName: 'plant_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '机械代码', //字段标题
                        fieldName: 'plantCode', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]
                this.get_CenPlantList()
            } else if (title === '选择机操手') {
                columns = [
                    {
                        title: '机操手',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '操作类型',
                        dataIndex: 'class',
                        key: 'class',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '机 操 手', //字段标题
                        fieldName: 'oper_user', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]
                this.get_operatorList()
            } else if (title === '选择油卡') {
                columns = [
                    {
                        title: '公司名称',
                        dataIndex: 'com_name',
                        key: 'com_name',
                    },
                    {
                        title: '油卡编号',
                        dataIndex: 'oil_code',
                        key: 'oil_code',
                    },
                    {
                        title: '定油',
                        dataIndex: 'oil_number',
                        key: 'oil_number',
                    },
                    {
                        title: '可使用范围',
                        dataIndex: 'use_range',
                        key: 'use_range',
                    },
                    {
                        title: '使用状态',
                        dataIndex: 'use_status',
                        key: 'use_status',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '公司名称', //字段标题
                        fieldName: 'com_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]
                this.get_OilCardList()
            } else if (title === '调拨至项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }]
                this.get_BlankingSelectProject()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;

        if (buttonTitle === '选择机械') {
            form.plant_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '机械名称') {
                    item.value = value[0].name
                }
                if (item.FieldTitle === '机械代码') {
                    item.value = value[0].code
                }
            })
        } else if (buttonTitle === '选择机操手') {
            form.oper_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '机操手名称') {
                    item.value = value[0].name
                }
            })
        } else if (buttonTitle === '选择油卡') {
            form.oil_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '油卡编号') {
                    item.value = value[0].oil_code
                }
            })
        } else if (buttonTitle === '调拨至项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择机械') {
            this.setState({
                plant_name: value.plant_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_CenPlantList()
            })
        } else if (buttonTitle === '选择机操手') {
            this.setState({
                oper_user: value.oper_user,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_operatorList()
            })
        } else if (buttonTitle === '选择油卡') {
            this.setState({
                com_name: value.com_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_OilCardList()
            })
        } else if (buttonTitle === '调拨至项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            plant_name: '',
            oper_user: '',
            pro_name: '',
            com_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择机械') {
                this.get_CenPlantList()
            } else if (buttonTitle === '选择机操手') {
                this.get_operatorList()
            } else if (buttonTitle === '选择油卡') {
                this.get_OilCardList()
            } else if (buttonTitle === '调拨至项目') {
                this.get_BlankingSelectProject()
            }
        })
    }

    handleCheck = (value) => {
        let { Info } = this.state
        if (value.length) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择机械') {
                    item.isShow = true
                } else if (item.FieldTitle === '机械名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '机械代码') {
                    item.isShow = true
                } else if (item.FieldTitle === '选择机操手') {
                    item.isShow = true
                } else if (item.FieldTitle === '机操手名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '选择油卡') {
                    item.isShow = true
                } else if (item.FieldTitle === '油卡编号') {
                    item.isShow = true
                }
            })
            value.forEach((item, index) => {
                if (item === 1) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '选择机械') {
                            item.isShow = false
                        } else if (item.FieldTitle === '机械名称') {
                            item.isShow = false
                        } else if (item.FieldTitle === '机械代码') {
                            item.isShow = false
                        }
                    })
                } else if (item === 2) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '选择机操手') {
                            item.isShow = false
                        } else if (item.FieldTitle === '机操手名称') {
                            item.isShow = false
                        }
                    })
                } else if (item === 3) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '选择油卡') {
                            item.isShow = false
                        } else if (item.FieldTitle === '油卡编号') {
                            item.isShow = false
                        }
                    })
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择机械') {
                    item.isShow = true
                } else if (item.FieldTitle === '机械名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '机械代码') {
                    item.isShow = true
                } else if (item.FieldTitle === '选择机操手') {
                    item.isShow = true
                } else if (item.FieldTitle === '机操手名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '选择油卡') {
                    item.isShow = true
                } else if (item.FieldTitle === '油卡编号') {
                    item.isShow = true
                }
            })
        }

        this.setState(preState => ({
            Info
        })
        )
    }


    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} chexkBoxEvent={this.handleCheck} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} radio={true} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
