import React, { Component } from 'react'
import { Input, Button, message, Modal } from 'antd';
import { RotateLeftOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { fileResourceList, createResourceManager, addResourceFileUpload } from "../../../utils/scientific_research"
import { fileDownload, filePreview } from "../../../utils/api"
import foldeImg from "../../../accets/img/folde.png"
import fileImg from "../../../accets/img/files.png"
import wordImg from "../../../accets/img/word.png"
import xlsImg from "../../../accets/img/xls.png"
import pptImg from "../../../accets/img/ppt.png"
import pdfImg from "../../../accets/img/pdf.png"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableId: '',
            parentId: '',
            presentId: '',
            folder_name: '',
            enclosure: [],
            inputModal: false,
            dataSource: [],
            columns: [
                {
                    title: '文件名',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                                if (record.file_type === '文件夹') {
                                    this.get_NextLevel(record)
                                } else {
                                    this.get_filePreview(record)
                                }
                            }}>

                                {
                                    record.file_type === '文件夹' ? <img src={foldeImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'docx' ? <img src={wordImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'xls' ? <img src={xlsImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'ppt' ? <img src={pptImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'pdf' ? <img src={pdfImg} alt="" style={{ width: '30px', height: '30px' }}></img> : <img src={fileImg} alt="" style={{ width: '30px', height: '30px' }}></img>
                                }

                                <div style={{ marginLeft: '20px' }}>{text}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '上传时间',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '上传人',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '类型',
                    dataIndex: 'file_type',
                    key: 'file_type',
                },
                {
                    title: '大小',
                    dataIndex: 'file_size',
                    key: 'file_size',
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    render: (text, record, index) => {
                        if (record.file_type === '文件夹') {
                            return (
                                <div>/</div>
                            )
                        } else {
                            return (
                                <div className='download' onClick={() => {
                                    this.get_download(record)
                                }}>
                                    下载
                                </div>
                            )
                        }
                    }
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_fileResourceList()
    }

    get_fileResourceList = () => {
        let { dataSource, parentId } = this.state
        let data = {
            table_id: parentId
        }
        fileResourceList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.fileFolder.length) {
                        res.data.fileFolder.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                name: item.name,
                                parent_id: item.parent_id,
                                created_at: item.created_at,
                                file_size: '/',
                                file_type: '文件夹',
                                username: '/',
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }
                    if (res.data.files.length) {
                        console.log(0)
                        res.data.files.forEach((item, index) => {
                            let rowValue = {
                                key: res.data.fileFolder.length + index + 1,
                                table_id: item.table_id,
                                name: item.filename,
                                file_size: (item.file_size / 1024).toFixed(1) + ' kb',
                                file_type: item.fileType,
                                username: item.username,
                                created_at: item.created_at
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        presentId: res.data.table_id
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_NextLevel = (record) => {
        console.log(0)
        this.setState(preState => ({
            parentId: record.table_id,
        }), () => {
            this.get_fileResourceList()
        })
    }

    handleNew = () => {
        this.setState({
            inputModal: true,
        })
    }

    handleSearch = () => { }

    handlePrint = () => { }

    handleExport = () => { }

    handleLimit = (page, limit) => { }

    handleImport = (id) => {
        let { enclosure } = this.state
        enclosure = []
        enclosure = [...enclosure, id]
        this.setState(preState => ({
            enclosure
        }), () => {
            this.get_addResourceFileUpload()
        })
    }

    handleModal = () => {
        this.setState({
            inputModal: false,
        })
    }

    InputChange = (value) => {
        let { folder_name } = this.state
        folder_name = value.target.value
        this.setState({
            folder_name
        })
    }

    updateFolder = () => {
        let { folder_name, tableId, parentId } = this.state
        let data = {
            folder_name: folder_name,
            table_id: tableId,
            parent_id: parentId
        }
        createResourceManager(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        inputModal: false,
                    }, this.get_fileResourceList())
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_addResourceFileUpload = () => {
        let { parentId, enclosure } = this.state
        let data = {
            table_id: parentId,
            enclosure: JSON.stringify(enclosure)
        }
        addResourceFileUpload(data).then(
            (res) => {
                if (!res.code) {
                    message.success('导入成功')
                    this.get_fileResourceList()
                } else {
                    message.error('导入失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    Upper_level = () => {
        let { presentId } = this.state
        this.setState(preState => ({
            parentId: presentId
        }), () => {
            this.get_fileResourceList()
        })
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.table_id
        }
        fileDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.click()
                } else {
                    message.error(res.data.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting' style={{width:'auto'}}>
                        <Operate educe={true} dontAdd={true} dontLead={true} dontImport={true} notPrint={true} newFolder={true} seek={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} importEvent={this.handleImport} />

                        {
                            this.state.parentId ? <div className='ListOperate'>
                                <Button onClick={() => {
                                    this.Upper_level()
                                }}>
                                    <RotateLeftOutlined />
                                    <div>返回</div>
                                </Button>
                            </div> : ''
                        }
                    </div>
                </div>


                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Modal
                    title="文件夹名称"
                    centered
                    visible={this.state.inputModal}
                    onOk={() => this.updateFolder()}
                    onCancel={() => this.handleModal()}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    <Input onChange={(e) => this.InputChange(e)} />
                </Modal>
            </div>
        )
    }
}


export default withRouter(List)



