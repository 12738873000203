import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createDesignHandover, DesignHandoverDetail } from "../../../utils/design"
import { designPublicTaskList } from "../../../utils/market"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "设计文件移交及进度汇报",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
            {
                FieldTitle: '文件类型(多选)', //字段标题
                fieldName: 'file_type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择文件类型', //必填要求
                value: [],
                width: '50%',
                selectList: [
                    { value: 1, label: "PPT" },
                    { value: 2, label: "PDF" },
                    { value: 3, label: "CAD" },
                    { value: 4, label: "纸质" },

                ],
                multiple: true
            },
            {
                FieldTitle: '文件性质', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择文件性质', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "方案" },
                    { value: 2, label: "施工图" }
                ],
            },
            {
                FieldTitle: '移交内容描述', //字段标题
                fieldName: 'transfer_content', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            }, {
                title: '任务编号',
                dataIndex: 'task_code',
                key: 'task_code',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            }
        ],
        pro_name: '',
        form: {},
        pages: {
            limit: 10,
            page: 1,
            total: 0
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DesignHandoverDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
		if (localStorage.getItem('itemForm')) {
		    project = JSON.parse(localStorage.getItem('itemForm'))
		    form.project_id = project.table_id
			Info.TableInfo.forEach((item, index) => {
			    if (item.FieldTitle === '项目名称') {
			        item.value = project.pro_name
			    }
			})
		}
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_DesignHandoverDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DesignHandoverDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        form.table_id = tableId
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        file_type: JSON.parse(res.data.Detail.file_type),
                        class: res.data.Detail.class,
                        transfer_content: res.data.Detail.transfer_content,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_designPublicTaskList = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        designPublicTaskList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.marks.length) {
                        res.data.marks.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                task_code: item.task_code
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }              
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_designPublicTaskList()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_designPublicTaskList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        })
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.class = data.class
        form.transfer_content=data.transfer_content
        form.file_type = JSON.stringify(data.file_type)

        createDesignHandover(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_designPublicTaskList()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} />
            </div>
        )
    }
}
