import http from '../utils/http';

//获取用户信息
function userInfo(data) {
  return http("get", '/api/user_info', data);
}

//用户查询
function selectUserName(data) {
  return http("get", '/api/selectUserName', data);
}

//项目查询
function selectProjectName(data) {
  return http("get", '/api/selectProjectName', data);
}

//获取部门
function getCompanyDeName(data) {
  return http("get", '/api/getCompanyDeName', data);
}

//退出登录
function logOut(data) {
  return http("post", '/api/logout', data);
}

//附件上传
function fileUpload(data) {
  return http("post", '/api/fileUpload', data);
}

//附件删除
function deleteFile(data) {
  return http("post", '/api/deleteFile', data);
}

//流程审核
function reviewApplyResult(data) {
  return http("post", '/api/reviewApplyResult', data);
}

//添加编辑项目财务收款
function createdProjectCollection(data) {
  return http("post", '/api/createdProjectCollection', data);
}

//财务项目收款记录
function projectCollection(data) {
  return http("get", '/api/projectCollection', data);
}

//添加编辑项目财务付款
function addSetPayMoney(data) {
  return http("post", '/api/addSetPayMoney', data);
}

//财务项目付款记录
function macContractSupplier(data) {
  return http("get", '/api/macContractSupplier', data);
}

//财务查看报销审批
function FinanceReApproval(data) {
  return http("get", '/api/FinanceReApproval', data);
}

//文件下载
function fileDownload(data) {
  return http("post", '/api/fileDownload', data);
}

//文件预览
function filePreview(data) {
  return http("get", '/api/filePreview', data);
}

//文件批量下载
function BulkDownload(data) {
  return http("post", '/api/BulkDownload', data);
}

//项目成本归集
function projectCostAccumulation(data) {
  return http("get", '/api/projectCostAccumulation', data);
}

//项目动态总控表
function projectStatisticsInfo(data) {
  return http("get", '/api/projectStatisticsInfo', data);
}

//数据访问记录列表
function getAccessData(data) {
  return http("get", '/api/getAccessData', data);
}

//功能历史数据详情
function historyLogDetail(data) {
  return http("get", '/api/historyLogDetail', data);
}

//获取部门名称
function getFunctionDeName(data) {
  return http("get", '/api/getFunctionDeName', data);
}

//导出历史访问数据
function exportHistoryDataLogs(data) {
  return http("get", '/api/exportHistoryDataLogs', data);
}


//项目经理参与项目列表
function proManagerProjects(data) {
  return http("get", '/api/proManagerProjects', data);
}



export {
  userInfo,
  selectUserName,
  selectProjectName,
  logOut,
  fileUpload,
  deleteFile,
  getCompanyDeName,
  reviewApplyResult,
  createdProjectCollection,
  projectCollection,
  macContractSupplier,
  FinanceReApproval,
  addSetPayMoney,
  fileDownload,
  filePreview,
  BulkDownload,
  projectCostAccumulation,
  projectStatisticsInfo,
  getAccessData,
  historyLogDetail,
  getFunctionDeName,
  exportHistoryDataLogs,
  proManagerProjects
}

