import React, { Component } from 'react'
import { message, Button } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Append from '../../components/addAppend'
import { MaintenanceInspectionList, MaintenanceInspectionDetail, createMaintenanceInspection } from "../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_MaintenanceInspectionDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '项目地址',
                    dataIndex: 'pro_address',
                    key: 'pro_address',
                },
                {
                    title: '项目经理',
                    dataIndex: 'pro_manager',
                    key: 'pro_manager',
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                },
            ],
            pro_name: '',
            pro_manager: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            Info: {
                state: '新增',
                FlowModelName: "立体绿化项目信息维护",
                TableInfo: [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '项目地址', //字段标题
                    fieldName: 'pro_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
                ],
                FirstInfo: []
            },
            selectValue: '',
            form: {},
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_MaintenanceInspectionList()
    }

    get_MaintenanceInspectionList = () => {
        let { pro_name, pro_manager, dataSource, pages } = this.state
        let data = {
            pro_name: pro_name,
            pro_manager: pro_manager,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        MaintenanceInspectionList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.maintenance.length) {
                        res.data.maintenance.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_name: item.pro_name,
                                pro_address: item.pro_address,
                                pro_manager: item.pro_manager,
                                info: item.info,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleRecord = () => {
        this.props.history.push('/scientific_research/patrol/Record')
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_MaintenanceInspectionDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_MaintenanceInspectionDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        MaintenanceInspectionDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })
        }

        this.NewForm.current.formRef.current.setFieldsValue({
            pro_name: value.pro_name,
            pro_address: value.pro_address,
            pro_manager: value.pro_manager,
            info: value.info,
        })

        this.setState({
            Info
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            pro_manager: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_MaintenanceInspectionList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pro_manager: value.pro_manager,
        }, () => {
            this.get_MaintenanceInspectionList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_MaintenanceInspectionList()
        })
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.pro_name = data.pro_name
        form.pro_address = data.pro_address
        form.pro_manager = data.pro_manager
        form.info = data.info

        createMaintenanceInspection(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_MaintenanceInspectionList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                pro_name: '',
                pro_address: '',
                pro_manager: '',
                info: '',
            })
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>

                <div className='labor_search'>
                    <Operate notPrint={true} educe={true} dontImport={true} edit={false} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    <div className='history ListOperate' style={{ width: 'auto' }}>
                        <Button onClick={() => {
                            this.handleRecord()
                        }}>
                            <HistoryOutlined />
                            <div>巡查记录</div>
                        </Button>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                }

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} selectorEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(List)
