import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { Select, Modal } from 'antd';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../../components/Operate'
import Table from '../../../../components/Table'
import { SeedlingInventoryList, selectNurseryList } from "../../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '入库时间',
                    dataIndex: 'warehousing',
                    key: 'warehousing',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '入库类型',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: '植物编号',
                    dataIndex: 'plant_code',
                    key: 'plant_code',
                },
                {
                    title: '植物名称',
                    dataIndex: 'plant_name',
                    key: 'plant_name',
                },
                {
                    title: '型号规格',
                    dataIndex: 'model',
                    key: 'model',
                },
                {
                    title: '单位',
                    dataIndex: 'supply_unit',
                    key: 'supply_unit',
                },
                {
                    title: '入库数量',
                    dataIndex: 'see_number',
                    key: 'see_number',
                },
                {
                    title: '入库单价',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '入库总额',
                    dataIndex: 'all_price',
                    key: 'all_price',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            nurList: [],
            nurValue: '',
            templateList: [],
            templateShow: false
        }
    }

    componentDidMount() {
        this.get_selectNurseryList()
    }

    get_selectNurseryList = () => {
        let { nurList, nurValue } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    nurList = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                label: item.name,
                                value: item.id
                            }
                            nurList = [...nurList, value]

                            if (!nurValue) {
                                if (index === 0) {
                                    nurValue = item.id
                                }
                            }
                        })
                    }

                    this.setState(preState => ({
                        nurList,
                        nurValue
                    }), () => {
                        this.get_SeedlingInventoryList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SeedlingInventoryList = () => {
        let { dataSource, pages, nurValue } = this.state
        let data = {
            queryCriteria: 'personal',
            nur_id: nurValue,
            operate: 'in',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SeedlingInventoryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.seedling.length) {
                        res.data.seedling.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.sheet_id,
                                warehousing: item.warehousing,
                                type: item.type === 1 ? '外部购买' : '自主培育',
                                plant_name: item.plant_name,
                                plant_code: item.plant_code,
                                model: item.model,
                                supply_unit: item.unit,
                                see_number: item.see_number,
                                price: item.price,
                                all_price: item.all_price,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        templateList: res.data.template
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/beputinstorage/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/beputinstorage/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            con_name: '',
            con_code: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            con_name: value.con_name,
            con_code: value.con_code
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    nurChange = (value) => {
        this.setState({
            nurValue: value,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => { this.get_SeedlingInventoryList() })
    }

    handleTemplate = () => {
        this.setState({
            templateShow: true,
        })
    }

    cancelTemplate = () => {
        this.setState({
            templateShow: false,
        })
    }

    handlePath = (path) => {
        let link = document.createElement("a")
        link.href = path
        link.click()
    }

    render() {
        let { dataSource, columns, pages, nurList, nurValue, templateShow, templateList } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} seek={true} template={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} templateEvent={this.handleTemplate} />
                    </div>

                    <div className='screen_right'>
                        <div>
                            <div>苗圃名称 :</div>
                            <Select
                                options={nurList}
                                value={nurValue}
                                onChange={(e) => {
                                    this.nurChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>
                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />

                <Modal
                    title="下载模板"
                    centered
                    visible={templateShow}
                    onCancel={() => this.cancelTemplate()}
                    wrapClassName='userModal template'
                    maskClosable={false}
                    footer={[]}
                >
                    {
                        templateList.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    this.handlePath(item.filePath)
                                }}>
                                    {item.file_name}
                                </div>
                            )
                        })
                    }
                </Modal>
            </div>
        )
    }
}


export default withRouter(List)
