import Index from '../views/index/Index'
import Backlog from '../views/index/Backlog'
import Flow from '../views/index/Flow'
import Week from '../views/index/Weekly'
import Log from '../views/index/Log'
import Process from '../views/index/Process'

import ShenpibiaoList from '../views/market/Shenpibiao/List'
import ShenpibiaoNewItem from '../views/market/Shenpibiao/NewItem'
import BaozhengjinList from '../views/market/Baozhengjin/List'
import BaozhengjinNewItem from '../views/market/Baozhengjin/NewItem'
import TongzhishuList from '../views/market/Tongzhishu/List'
import ZhongbiaolixiangList from '../views/market/Zhongbiaolixiang/List'
import ZhongbiaolixiangNewItem from '../views/market/Zhongbiaolixiang/NewItem'
import HetongshenpiList from '../views/market/Hetongshenpi/List'
import HetongshenpiNewItem from '../views/market/Hetongshenpi/NewItem'
import marketDesignSchemeList from '../views/market/shejixiangmurenwushenqing/List'
import marketDesignSchemeNewItem from '../views/market/shejixiangmurenwushenqing/NewItem'
import marketApproximateSumList from '../views/market/gaisuanxiangmurenwushenqing/List'
import marketApproximateSumNewItem from '../views/market/gaisuanxiangmurenwushenqing/NewItem'

import lingxingyonggongList from '../views/cost/lingxingyonggong/List'
import renjiashenpiList from '../views/cost/renjiashenpi/List'
import zhongbiaoqingdanList from '../views/cost/zhongbiaoqingdan/List'
import chengbenyusuanList from '../views/cost/chengbenyusuan/List'
import rencaijizongkuList from '../views/cost/rencaijizongku/List'
import rencaijijiageList from '../views/cost/rencaijijiage/List'
import rencaijigongchengliangList from '../views/cost/rencaijigongchengliang/List'
import progressPaymentList from '../views/cost/gongchengjindukuanshenpi/List'
import progressPaymentNewItem from '../views/cost/gongchengjindukuanshenpi/NewItem'
import progressPaymentSummaryList from '../views/cost/gongchengjindukuanshenpi/SummaryList'


import rencaijixialiaodanList from '../views/project/rencaijixialiaodan/List'
import rencaijixialiaodanNewItem from '../views/project/rencaijixialiaodan/NewItem'
import projectLingxingyonggongList from '../views/project/lingxingyonggong/List'
import projectLingxingyonggongNewItem from '../views/project/lingxingyonggong/NewItem'
import shouhuoguanliList from '../views/project/shouhuoguanli/List'
import shouhuoguanliNewItem from '../views/project/shouhuoguanli/NewItem'
import jixietaibanList from '../views/project/jixietaiban/List'
import jixietaibanNewItem from '../views/project/jixietaiban/NewItem'
import lingxcailiaocaigouList from '../views/project/lingxcailiaocaigou/List'
import lingxcailiaocaigouNewItem from '../views/project/lingxcailiaocaigou/NewItem'
import rencaijichanzhishenbaoList from '../views/project/rencaijichanzhishenbao/List'
import rencaijichanzhishenbaoNewItem from '../views/project/rencaijichanzhishenbao/NewItem'
import ziliaohuizongList from '../views/project/ziliaohuizong/List'
import rencaijizongjihuabiaoList from '../views/project/rencaijizongjihuabiao/List'
import rencaijihetongshenpiList from '../views/project/rencaijihetongshenpi/List'
import rencaijihetongshenpiNewItem from '../views/project/rencaijihetongshenpi/NewItem'
import jindujiesuanshenpiList from '../views/project/jindujiesuanshenpi/List'
import jindujiesuanshenpiNewItem from '../views/project/jindujiesuanshenpi/NewItem'
import jiesuanshenpiList from '../views/project/jiesuanshenpi/List'
import jiesuanshenpiNewItem from '../views/project/jiesuanshenpi/NewItem'
import yezhukaipiaoList from '../views//project/yezhukaipiao/List'
import yezhukaipiaoNewItem from '../views/project/yezhukaipiao/NewItem'
import rencaijifukuanshenpiList from '../views/project/rencaijifukuanshenpi/List'
import rencaijifukuanshenpiNewItem from '../views/project/rencaijifukuanshenpi/NewItem'
import shigongrizhiList from '../views/project/shigongrizhi/List'
import shigongrizhiNewItem from '../views/project/shigongrizhi/NewItem'
import shigongrizhiSummary from '../views/project/shigongrizhi/Summary'
import zhanghushenqingList from '../views/project/hezuoxiangmuzhanghushenqing/List'
import zhanghushenqingNewItem from '../views/project/hezuoxiangmuzhanghushenqing/NewItem'
import zhanghufukuanList from '../views/project/hezuoxiangmufukuan/List'
import zhanghufukuanNewItem from '../views/project/hezuoxiangmufukuan/NewItem'
import responsibilityInformationList from '../views/project/xiangmuzerenxinxi/List'
import responsibilityInformationNewItem from '../views/project/xiangmuzerenxinxi/NewItem'
import responsibilityReviewList from '../views/project/xiangmuzerenpingshen/List'
import responsibilityReviewNewItem from '../views/project/xiangmuzerenpingshen/NewItem'
import handoverOrderList from '../views/project/ziliaoyijiaodan/List'
import handoverOrderNewItem from '../views/project/ziliaoyijiaodan/NewItem'
import noticeList from '../views/project/tongzhiwenjian/List'
import noticeNewItem from '../views/project/tongzhiwenjian/NewItem'
import receiveList from '../views/project/shouwenguanli/List'
import receiveItem from '../views/project/shouwenguanli/NewItem'
import letterList from '../views/project/hanjianshenpi/List'
import letterNewItem from '../views/project/hanjianshenpi/NewItem'
import designSchemeList from '../views/project/shejixiangmurenwushenqing/List'
import designSchemeNewItem from '../views/project/shejixiangmurenwushenqing/NewItem'
import dataArchivingList from '../views/project/gongchengziliaoyunguidang/List'
import dataUploadList from '../views/project/gongchengguochengziliaoshangchuan/List'
import dataUploadNewItem from '../views/project/gongchengguochengziliaoshangchuan/NewItem'
import attendanceSummaryList from '../views/project/chuqinqiandaohuizong/List'

import jicaiguanlizhiduList from '../views/purchase/jicaiguanlizhidu/List'
import purchaserencaijizongkuList from '../views/purchase/rencaijizongku/List'
import priceAdjustmentList from '../views/purchase/cailiaotiaojiashenpi/List'
import priceAdjustmentNewItem from '../views/purchase/cailiaotiaojiashenpi/NewItem'
import gongyingshangrukuList from '../views/purchase/gongyingshangjirenjiaguanli/gongyingshangruku/List'
import youzhigongyingshangrukuList from '../views/purchase/gongyingshangjirenjiaguanli/youzhigongyingshangruku/List'
import youzhigongyingshangrukuNewItem from '../views/purchase/gongyingshangjirenjiaguanli/youzhigongyingshangruku/NewItem'
import purchasegongyingshangrenjiashenpiList from '../views/purchase/gongyingshangjirenjiaguanli/gongyingshangrenjiashengpi/List'
import purchasegongyingshangrenjiashenpiNewItem from '../views/purchase/gongyingshangjirenjiaguanli/gongyingshangrenjiashengpi/NewItem'
import gongyingshangrenjiaxiangqingList from '../views/purchase/gongyingshangjirenjiaguanli/gongyingshangrenjiaxiangqing/List'
import gongyingshangrenjiaxiangqingContrast from '../views/purchase/gongyingshangjirenjiaguanli/gongyingshangrenjiaxiangqing/Contrast'
import toubiaojueceshenpibiaoList from '../views/purchase/gongchengguanli/toubiaojueceshenpibiao/List'
import xiangmuzhongbiaolixiangList from '../views/purchase/gongchengguanli/Zhongbiaolixiang/List'
import shouruhetongshenpiList from '../views/purchase/gongchengguanli/Hetongshenpi/List'
import purchaserencaijixialiaodanList from '../views/purchase/gongchengguanli/rencaijixialiaodan/List'
import purchaseLingxingyonggongList from '../views/purchase/gongchengguanli/lingxingyonggong/List'
import purchaseshouhuoguanliList from '../views/purchase/gongchengguanli/shouhuoguanli/List'
import jixietaibanjilubiaoList from '../views/purchase/gongchengguanli/jixietaiban/List'
import lingxcailiaoList from '../views/purchase/gongchengguanli/lingxcailiaocaigou/List'
import purchaseRencaijizongjihuabiaoList from '../views/purchase/gongchengguanli/rencaijizongjihuabiao/List'
import purchaseRencaijihetongshenpiList from '../views/purchase/gongchengguanli/rencaijihetongshenpi/List'
import shebeifenpeiguanliList from '../views/purchase/gongchengguanli/shebeifenpeiguanli/List'
import gongchengjixieshangxiajiList from '../views/purchase/gongchengguanli/gongchengjixieshangxiaji/List'
import gongchengjixieshangxiajiNewItem from '../views/purchase/gongchengguanli/gongchengjixieshangxiaji/NewItem'
import gongchengjixiexingdongguijiList from '../views/purchase/gongchengguanli/gongchengjixiexingdongguiji/List'
import gongchengjixiexingdongguijiNewItem from '../views/purchase/gongchengguanli/gongchengjixiexingdongguiji/NewItem'
import mechanicalInformationList from '../views/purchase/cangkuguanli/gudingzichanguanli/jixiezichanmingxi/jixiexinxi/List'
import machineOperatorList from '../views/purchase/cangkuguanli/gudingzichanguanli/jixiezichanmingxi/jichaoshouxinxi/List'
import fuelCardList from '../views/purchase/cangkuguanli/gudingzichanguanli/jixiezichanmingxi/youkaxinxi/List'
import allotList from '../views/purchase/cangkuguanli/gudingzichanguanli/jixiezichanmingxi/diaoboshenqing/List'
import allotNewItem from '../views/purchase/cangkuguanli/gudingzichanguanli/jixiezichanmingxi/diaoboshenqing/NewItem'

import yonghuguanliList from '../views/administrative/yonghuguanli/List'
import yonghuzuguanliList from '../views/administrative/yonghuzuguanli/List'
import yonghuzuguanliAuthority from '../views/administrative/yonghuzuguanli/Authority'
import ruzhibanliList from '../views/administrative/renliziyuan/ruzhibanli/List'
import ruzhibanliNewItem from '../views/administrative/renliziyuan/ruzhibanli/NewItem'
import yuangongdanganList from '../views/administrative/renliziyuan/yuangongdangan/List'
import yuangongdanganNewItem from '../views/administrative/renliziyuan/yuangongdangan/NewItem'
import adminZhuanzhengshenqingList from '../views/administrative/renliziyuan/zhuanzhengshenqing/List'
import adminLizhibanliList from '../views/administrative/renliziyuan/lizhibanli/List'
import turnoverHandoverList from '../views/administrative/renliziyuan/lizhjiaojie/List'
import turnoverHandoverNewItem from '../views/administrative/renliziyuan/lizhjiaojie/NewItem'
import adminYonggongshenqingList from '../views/administrative/renliziyuan/yonggongshenqing/List'
import adminLinshiyonggongshenqingList from '../views/administrative/renliziyuan/linshiyonggongshenqing/List'
import linshiyonggonggongzibiaoList from '../views/administrative/renliziyuan/linshiyonggonggongzibiao/List'
import linshiyonggonggongzibiaoNewItem from '../views/administrative/renliziyuan/linshiyonggonggongzibiao/NewItem'
import zujianxiangmutuanduiList from '../views/administrative/renliziyuan/zujianxiangmutuandui/List'
import zujianxiangmutuanduiAppointList from '../views/administrative/renliziyuan/zujianxiangmutuandui/AppointList'
import zujianxiangmutuanduiAppointNewItem from '../views/administrative/renliziyuan/zujianxiangmutuandui/AppointNewItem'
import zujianxiangmutuanduiAccrediAccreditNewItem from '../views/administrative/renliziyuan/zujianxiangmutuandui/AccreditNewItem'
import adminBukashenqingList from '../views/administrative/kaoqinguanli/bukashenqing/List'
import adminChuchaishenqingList from '../views/administrative/kaoqinguanli/chuchaishenqing/List'
import adminQingjiashenqingList from '../views/administrative/kaoqinguanli/qingjiashenqing/List'
import adminPaiqianshenqingList from '../views/administrative/kaoqinguanli/paiqianshenqing/List'
import kaoqinhuizongList from '../views/administrative/kaoqinguanli/kaoqinhuizong/List'
import kaohebiaozhunList from '../views/administrative/jixaokaohe/kaohebiaozhun/List'
import kaohebiaozhunNewItem from '../views/administrative/jixaokaohe/kaohebiaozhun/NewItem'
import kaohejiluList from '../views/administrative/jixaokaohe/kaohejilu/List'
import kaohejiluResultList from '../views/administrative/jixaokaohe/kaohejilu/ResultList'
import kaohejiluNewItem from '../views/administrative/jixaokaohe/kaohejilu/NewItem'
import adminGongzuozhoubaoList from '../views/administrative/gongzuozongjie/gongzuozhoubao/List'
import adminGongzuozhoubaoStatisticalList from '../views/administrative/gongzuozongjie/gongzuozhoubao/StatisticalList'
import adminNianduzongjieList from '../views/administrative/gongzuozongjie/nianduzongjie/List'
import adminYongzhangshenqingList from '../views/administrative/yinzhangguanli/yongzhangshenqing/List'
import yongzhangshenqingStatisticalList from '../views/administrative/yinzhangguanli/yongzhangshenqing/StatisticalList'
import adminYinzhangguanliList from '../views/administrative/yinzhangguanli/yinzhangguanli/List'
import adminYinzhangkezhishenqingList from '../views/administrative/yinzhangguanli/yinzhangkezhi/List'
import yinzhangzhuxiaoList from '../views/administrative/yinzhangguanli/yinzhangzhuxiao/List'
import yinzhangzhuxiaoNewItem from '../views/administrative/yinzhangguanli/yinzhangzhuxiao/NewItem'
import adminYongcheshenqingList from '../views/administrative/cheliangguanli/yongcheshenqing/List'
import adminCheliangpaiqianList from '../views/administrative/cheliangguanli/cheliangpaiqian/List'
import adminShebeigouzhiList from '../views/administrative/shebeigouzhi/shebeigouzhi/List'
import adminBangongyongpinshenqingList from '../views/administrative/shebeigouzhi/bangongyongpinshenqing/List'
import adminXingzhengshebeigouzhiList from '../views/administrative/shebeigouzhi/xingzhengshebeigouzhi/List'
import adminXingzhengshebeigouzhiNewItem from '../views/administrative/shebeigouzhi/xingzhengshebeigouzhi/NewItem'
import oaNoticeList from '../views/administrative/tongzhigonggao/List'
import oaNoticeLists from '../views/administrative/tongzhigonggao/Lists'
import oaNoticeNewItem from '../views/administrative/tongzhigonggao/NewItem'
import liuchengshejiList from '../views/administrative/liuchengsheji/List'

import publicbukashenqingList from '../views/public/bukashenqing/List'
import publicbukashenqingNewItem from '../views/public/bukashenqing/NewItem'
import publicChuchaishenqingList from '../views/public/chuchaishenqing/List'
import publicChuchaishenqingNewItem from '../views/public/chuchaishenqing/NewItem'
import publicqingjiashenqingList from '../views/public/qingjiashenqing/List'
import publicqingjiashenqingNewItem from '../views/public/qingjiashenqing/NewItem'
import publicgongzuozhoubaoList from '../views/public/gongzuozhoubao/List'
import publicgongzuozhoubaoNewItem from '../views/public/gongzuozhoubao/NewItem'
import temporaryPipeList from '../views/public/gongzuotongzhizanguan/List'
import temporaryPipeNewItem from '../views/public/gongzuotongzhizanguan/NewItem'
import xitongrizhiList from '../views/public/xitongrizhi/List'
import xitongrizhiRanking from '../views/public/xitongrizhi/Ranking'

import yuangongjiekuanshenpiList from '../views/public/fukuanshenpi/yuangongjiekuanshenpi/List'
import yuangongjiekuanshenpiNewItem from '../views/public/fukuanshenpi/yuangongjiekuanshenpi/NewItem'
import feiyongbaoxiaoshenpiList from '../views/public/fukuanshenpi/feiyongbaoxiaoshenpi/List'
import feiyongbaoxiaoshenpiNewItem from '../views/public/fukuanshenpi/feiyongbaoxiaoshenpi/NewItem'
import zonghekeyanfukuanList from '../views/public/fukuanshenpi/zonghekeyanfukuanshenpi/List'
import zonghekeyanfukuanNewItem from '../views/public/fukuanshenpi/zonghekeyanfukuanshenpi/NewItem'
import gongchengkuanzhifushenpiList from '../views/public/fukuanshenpi/gongchengkuanzhifushenpi/List'
import gongchengkuanzhifushenpiNewItem from '../views/public/fukuanshenpi/gongchengkuanzhifushenpi/NewItem'
import patentRenewalList from '../views/public/fukuanshenpi/zhuanlixufeifukuanshenqing/List'
import patentRenewalNewItem from '../views/public/fukuanshenpi/zhuanlixufeifukuanshenqing/NewItem'

import publicyonggongshenqingList from '../views/public/renliziyuan/yonggongshenqing/List'
import publicyonggongshenqingNewItem from '../views/public/renliziyuan/yonggongshenqing/NewItem'
import publiclinshiyonggongshenqingList from '../views/public/renliziyuan/linshiyonggongshenqing/List'
import publiclinshiyonggongshenqingNewItem from '../views/public/renliziyuan/linshiyonggongshenqing/NewItem'
import zhuanzhengshenqingList from '../views/public/renliziyuan/zhuanzhengshenqing/List'
import zhuanzhengshenqingNewItem from '../views/public/renliziyuan/zhuanzhengshenqing/NewItem'
import lizhibanliList from '../views/public/renliziyuan/lizhibanli/List'
import lizhibanliNewItem from '../views/public/renliziyuan/lizhibanli/NewItem'
import publicpaiqianshenqingList from '../views/public/renliziyuan/paiqianshenqing/List'
import publicpaiqianshenqingNewItem from '../views/public/renliziyuan/paiqianshenqing/NewItem'
import publicnianduzongjieList from '../views/public/renliziyuan/nianduzongjie/List'
import publicnianduzongjieNewItem from '../views/public/renliziyuan/nianduzongjie/NewItem'
import publicyongzhangshenqingList from '../views/public/xingzhengbangong/yongzhangshenqing/List'
import publicyongzhangshenqingNewItem from '../views/public/xingzhengbangong/yongzhangshenqing/NewItem'
import yongcheshenqingList from '../views/public/xingzhengbangong/yongcheshenqing/List'
import yongcheshenqingNewItem from '../views/public/xingzhengbangong/yongcheshenqing/NewItem'
import cheliangpaiqianList from '../views/public/xingzhengbangong/cheliangpaiqian/List'
import cheliangpaiqianNewItem from '../views/public/xingzhengbangong/cheliangpaiqian/NewItem'
import yewuzhaodaishenqingList from '../views/public/xingzhengbangong/yewuzhaodaishenqing/List'
import yewuzhaodaishenqingNewItem from '../views/public/xingzhengbangong/yewuzhaodaishenqing/NewItem'
import bangongyongpinshenqingList from '../views/public/xingzhengbangong/bangongyongpinshenqing/List'
import bangongyongpinshenqingNewItem from '../views/public/xingzhengbangong/bangongyongpinshenqing/NewItem'
import publicshebeigouzhishenqingList from '../views/public/xingzhengbangong/shebeigouzhishenqing/List'
import publicshebeigouzhishenqingNewItem from '../views/public/xingzhengbangong/shebeigouzhishenqing/NewItem'
import publicyinzhangkezhishenqingList from '../views/public/xingzhengbangong/yinzhangkezhishenqing/List'
import publicyinzhangkezhishenqingNewItem from '../views/public/xingzhengbangong/yinzhangkezhishenqing/NewItem'
import gongsihetongshenpiList from '../views/public/hetongshenpi/gongsihetongshenpi/List'
import gongsihetongshenpiNewItem from '../views/public/hetongshenpi/gongsihetongshenpi/NewItem'
import shigongzuzhishejiList from '../views/public/shigongzuzhisheji/List'
import shigongzuzhishejiNewItem from '../views/public/shigongzuzhisheji/NewItem'

import xiangmushoukuanList from '../views/finance/xiangmushoukuan/List'
import xiangmufukuanList from '../views/finance/xiangmufukuan/List'
import financeFeiyongbaoxiao from '../views/finance/feiyongbaoxiao/List'
import dongtaizongkongbiaoList from '../views/item/dongtaizongkongbiao/List'
import chengbenguijibiaoList from '../views/item/chengbenguijibiao/List'

import documentTransferList from '../views/design/shejiwenjianyijiaojijinduhuibao/List'
import documentTransferNewItem from '../views/design/shejiwenjianyijiaojijinduhuibao/NewItem'

import customerList from '../views/scientificResearch/kehuguanli/List'
import projectApplicationList from '../views/scientificResearch/keyanxiangmushenbao/List'
import projectApplicationNewItem from '../views/scientificResearch/keyanxiangmushenbao/NewItem'
import projectApplicationConApprovalList from '../views/scientificResearch/keyanxiangmushenbao/ConApprovalList'
import projectApplicationConApprovalNewItem from '../views/scientificResearch/keyanxiangmushenbao/ConApprovalNewItem'
import projectApplicationPayList from '../views/scientificResearch/keyanxiangmushenbao/PayList'
import projectApplicationPayNewItem from '../views/scientificResearch/keyanxiangmushenbao/PayNewItem'
import ResearchProjectList from '../views/scientificResearch/yanfaxiangmu/List'
import ResearchProjectNewItem from '../views/scientificResearch/yanfaxiangmu/NewItem'
import ResearchProjectConApprovalList from '../views/scientificResearch/yanfaxiangmu/ConApprovalList'
import ResearchProjectConApprovalNewItem from '../views/scientificResearch/yanfaxiangmu/ConApprovalNewItem'
import ResearchProjectPayList from '../views/scientificResearch/yanfaxiangmu/PayList'
import ResearchProjectPayNewItem from '../views/scientificResearch/yanfaxiangmu/PayNewItem'
import ResearchProjectInspectNewItem from '../views/scientificResearch/yanfaxiangmu/InspectNewItem'
import ResearchProjectConcludeNewItem from '../views/scientificResearch/yanfaxiangmu/ConcludeNewItem'
import ResearchProjectPostponeList from '../views/scientificResearch/yanfaxiangmu/PostponeList'
import ResearchProjectPostponeNewItem from '../views/scientificResearch/yanfaxiangmu/PostponeNewItem'
import AchievementList from '../views/scientificResearch/keyanzhishiguanli/chengguoguanli/List'
import HonorList from '../views/scientificResearch/keyanzhishiguanli/zizhirongyuzhengshu/List'
import DatumList from '../views/scientificResearch/ziliaoguanli/List'
import PatrolList from '../views/scientificResearch/xiangmuyanghuxuncha/List'
import PatrolRecord from '../views/scientificResearch/xiangmuyanghuxuncha/Record'
import ContractList from '../views/scientificResearch/keyanxiangguanhetong/List'
import ContractNewItem from '../views/scientificResearch/keyanxiangguanhetong/NewItem'
import NurseryList from '../views/scientificResearch/chengguozhuanhua/miaopumingchengduiyingbiao/List'
import PlantIntroductionList from '../views/scientificResearch/chengguozhuanhua/zhiwuyinzhongjihuabiao/List'
import PlantIntroductionNewItem from '../views/scientificResearch/chengguozhuanhua/zhiwuyinzhongjihuabiao/NewItem'
import BeputinstorageList from '../views/scientificResearch/chengguozhuanhua/churukuguanli/rukuguanli/List'
import BeputinstorageNewItem from '../views/scientificResearch/chengguozhuanhua/churukuguanli/rukuguanli/NewItem'
import DeliveryfromstorageList from '../views/scientificResearch/chengguozhuanhua/churukuguanli/chukuguanli/List'
import DeliveryfromstorageNewItem from '../views/scientificResearch/chengguozhuanhua/churukuguanli/chukuguanli/NewItem'
import inventoryStatisticsList from '../views/scientificResearch/chengguozhuanhua/churukuguanli/kucuntongji/List'
import inventoryStatisticsSummaryList from '../views/scientificResearch/chengguozhuanhua/churukuguanli/kucuntongji/SummaryList'
import ProjectinitiationList from '../views/scientificResearch/chengguozhuanhua/chengguozhuanhuaxiangmulixiang/List'
import ProjectinitiationNewItem from '../views/scientificResearch/chengguozhuanhua/chengguozhuanhuaxiangmulixiang/NewItem'
import ProcontractapprovalList from '../views/scientificResearch/chengguozhuanhua/xiangmuhetongshenpi/List'
import ProcontractapprovalNewItem from '../views/scientificResearch/chengguozhuanhua/xiangmuhetongshenpi/NewItem'
import ContractapprovalList from '../views/scientificResearch/chengguozhuanhua/hetongshenpi/List'
import ContractapprovalNewItem from '../views/scientificResearch/chengguozhuanhua/hetongshenpi/NewItem'
import ProgresssettlementList from '../views/scientificResearch/chengguozhuanhua/jindujiliangshenpi/List'
import ProgresssettlementNewItem from '../views/scientificResearch/chengguozhuanhua/jindujiliangshenpi/NewItem'
import NurserysettlementlList from '../views/scientificResearch/chengguozhuanhua/miaopujiesuanshenpi/List'
import NurserysettlementNewItem from '../views/scientificResearch/chengguozhuanhua/miaopujiesuanshenpi/NewItem'
import PaymentapprovalList from '../views/scientificResearch/chengguozhuanhua/fukuanshenpi/List'
import PaymentapprovalNewItem from '../views/scientificResearch/chengguozhuanhua/fukuanshenpi/NewItem'
import IdentityList from '../views/scientificResearch/chengguozhuanhua/renzhihejiashenpi/List'
import IdentityNewItem from '../views/scientificResearch/chengguozhuanhua/renzhihejiashenpi/NewItem'
import TemporaryWorkerList from '../views/scientificResearch/chengguozhuanhua/linshiyonggongshenqingbiao/List'
import TemporaryWorkerNewItem from '../views/scientificResearch/chengguozhuanhua/linshiyonggongshenqingbiao/NewItem'
import NotificationList from '../views/dispostion/qiyeweixintongzhipeizhi/List'
import NotificationNewItem from '../views/dispostion/qiyeweixintongzhipeizhi/NewItem'
import HistoryList from '../views/dispostion/lishijilu/List'
import HistoryNewItem from '../views/dispostion/lishijilu/NewItem'
import projectKanbanList from '../views/scientificResearch/xiangmukanban/List'
import projectKanbanNewItem from '../views/scientificResearch/xiangmukanban/NewItem'


const router = [
    {
        title: '首页',
        path: '/index',
        component: Index,
    },
    {
        title: '流程待办',
        path: '/backlog',
        component: Backlog,
    },
    {
        title: '流程待阅',
        path: '/flow',
        component: Flow,
    },
    {
        title: '周报待阅',
        path: '/week',
        component: Week,
    },
    {
        title: '施工日志',
        path: '/log',
        component: Log,
    },
    {
        title: '我的流程',
        path: '/process',
        component: Process,
    },
    {
        title: '市场开发-投标决策审批表',
        path: '/market/application/List',
        component: ShenpibiaoList,
    },
    {
        title: '投标决策审批表详情',
        path: '/market/application/NewItem',
        component: ShenpibiaoNewItem,
    },
    {
        title: '市场开发-投标保证金支付申请',
        path: '/market/margin/List',
        component: BaozhengjinList,
    },
    {
        title: '投标保证金支付申请详情',
        path: '/market/margin/NewItem',
        component: BaozhengjinNewItem,
    },
    {
        title: '市场开发-中标通知书',
        path: '/market/notice/List',
        component: TongzhishuList,
    },
    {
        title: '市场开发-项目中标立项',
        path: '/market/winning/List',
        component: ZhongbiaolixiangList,
    },
    {
        title: '项目中标立项详情',
        path: '/market/winning/NewItem',
        component: ZhongbiaolixiangNewItem,
    },
    {
        title: '市场开发-收入合同审批',
        path: '/market/contract/List',
        component: HetongshenpiList,
    },
    {
        title: '市场开发-设计项目申请',
        path: '/market/designScheme/List',
        component: marketDesignSchemeList,
    },
    {
        title: '市场部门-设计项目申请详情',
        path: '/market/designScheme/NewItem',
        component: marketDesignSchemeNewItem,
    },
    {
        title: '市场开发-概算项目任务申请',
        path: '/market/approximateSum/List',
        component: marketApproximateSumList,
    },
    {
        title: '市场部门-概算项目任务申请详情',
        path: '/market/approximateSum/NewItem',
        component: marketApproximateSumNewItem,
    },
    {
        title: '收入合同审批详情',
        path: '/market/contract/NewItem',
        component: HetongshenpiNewItem,
    },
    {
        title: '成本控制-零星用工',
        path: '/cost/labor/List',
        component: lingxingyonggongList,
    },
    {
        title: '成本控制-供应商认价审批',
        path: '/cost/supplier/List',
        component: renjiashenpiList,
    },
    {
        title: '成本控制-中标清单',
        path: '/cost/winning/List',
        component: zhongbiaoqingdanList,
    },
    {
        title: '成本控制-成本预算明细表',
        path: '/cost/costbudget/List',
        component: chengbenyusuanList,
    },
    {
        title: '成本控制-人材机总库',
        path: '/cost/stronger/List',
        component: rencaijizongkuList,
    },
    {
        title: '成本控制-人材机价格对比',
        path: '/cost/price/List',
        component: rencaijijiageList,
    },
    {
        title: '成本控制-人材机工程量对比',
        path: '/cost/quantities/List',
        component: rencaijigongchengliangList,
    },
    {
        title: '成本控制-工程进度款审批',
        path: '/cost/progressPayment/List',
        component: progressPaymentList,
    }, {
        title: '成本控制-工程进度款审批详情',
        path: '/cost/progressPayment/NewItem',
        component: progressPaymentNewItem,
    },{
        title: '成本控制-工程进度款数据总表',
        path: '/cost/progressPayment/SummaryList',
        component: progressPaymentSummaryList,
    },
    {
        title: '成本控制-人材机下料单',
        path: '/project/blanking/List',
        component: rencaijixialiaodanList,
    }, {
        title: '人材机下料单详情',
        path: '/project/blanking/NewItem',
        component: rencaijixialiaodanNewItem,
    },
    {
        title: '工程部-零星用工',
        path: '/project/labor/List',
        component: projectLingxingyonggongList,
    }, {
        title: '零星用工详情',
        path: '/project/labor/NewItem',
        component: projectLingxingyonggongNewItem,
    },
    {
        title: '工程部-收货管理',
        path: '/project/goods/List',
        component: shouhuoguanliList,
    }, {
        title: '收货管理详情',
        path: '/project/goods/NewItem',
        component: shouhuoguanliNewItem,
    },
    {
        title: '工程部-机械台班记录表',
        path: '/project/machinery/List',
        component: jixietaibanList,
    }, {
        title: '机械台班记录表详情',
        path: '/project/machinery/NewItem',
        component: jixietaibanNewItem,
    },
    {
        title: '工程部-项目辅助用材及其他申请',
        path: '/project/spMaterial/List',
        component: lingxcailiaocaigouList,
    }, {
        title: '工程部-项目辅助用材及其他申请详情',
        path: '/project/spMaterial/NewItem',
        component: lingxcailiaocaigouNewItem,
    },
    {
        title: '工程部-人材机产值申报',
        path: '/project/outputValue/List',
        component: rencaijichanzhishenbaoList,
    }, {
        title: '工程部-人材机产值申报详情',
        path: '/project/outputValue/NewItem',
        component: rencaijichanzhishenbaoNewItem,
    },
    {
        title: '工程部-资料汇总表',
        path: '/project/material/List',
        component: ziliaohuizongList,
    },
    {
        title: '工程部-人材机总计划表',
        path: '/project/schedule/List',
        component: rencaijizongjihuabiaoList,
    },
    {
        title: '工程部-人材机合同审批',
        path: '/project/contract/List',
        component: rencaijihetongshenpiList,
    }, {
        title: '人材机合同审批详情',
        path: '/project/contract/NewItem',
        component: rencaijihetongshenpiNewItem,
    },
    {
        title: '工程部-进度计量审批',
        path: '/project/settlement/List',
        component: jindujiesuanshenpiList,
    }, {
        title: '工程部-进度计量审批详情',
        path: '/project/settlement/NewItem',
        component: jindujiesuanshenpiNewItem,
    },
    {
        title: '工程部-结算审批',
        path: '/project/settlements/List',
        component: jiesuanshenpiList,
    }, {
        title: '工程部-结算审批详情',
        path: '/project/settlements/NewItem',
        component: jiesuanshenpiNewItem,
    },
    {
        title: '工程部-业主开票申请',
        path: '/project/invoice/List',
        component: yezhukaipiaoList,
    }, {
        title: '工程部-业主开票申请详情',
        path: '/project/invoice/NewItem',
        component: yezhukaipiaoNewItem,
    },
    {
        title: '工程部-人材机付款审批',
        path: '/project/payment/List',
        component: rencaijifukuanshenpiList,
    }, {
        title: '工程部-人材机付款审批详情',
        path: '/project/payment/NewItem',
        component: rencaijifukuanshenpiNewItem,
    },
    {
        title: '工程部-施工日志',
        path: '/project/build/List',
        component: shigongrizhiList,
    }, {
        title: '工程部-施工日志详情',
        path: '/project/build/NewItem',
        component: shigongrizhiNewItem,
    },
    {
        title: '工程部-施工日志汇总',
        path: '/project/build/Summary',
        component: shigongrizhiSummary,
    },
    {
        title: '工程部-合作项目账户申请',
        path: '/project/account/List',
        component: zhanghushenqingList,
    }, {
        title: '工程部-合作项目账户申请详情',
        path: '/project/account/NewItem',
        component: zhanghushenqingNewItem,
    },
    {
        title: '工程部-合作项目账户付款',
        path: '/project/projectPayment/List',
        component: zhanghufukuanList,
    },
    {
        title: '工程部-合作项目账户付款详情',
        path: '/project/projectPayment/NewItem',
        component: zhanghufukuanNewItem,
    },
    {
        title: '工程部-获取项目责任书信息',
        path: '/project/responsibilityInformation/List',
        component: responsibilityInformationList,
    },
    {
        title: '工程部-获取项目责任书信息详情',
        path: '/project/responsibilityInformation/NewItem',
        component: responsibilityInformationNewItem,
    },
    {
        title: '工程部-项目责任书评审',
        path: '/project/responsibilityReview/List',
        component: responsibilityReviewList,
    },
    {
        title: '工程部-项目责任书评审详情',
        path: '/project/responsibilityReview/NewItem',
        component: responsibilityReviewNewItem,
    },
    {
        title: '工程部-资料移交单',
        path: '/project/handoverOrder/List',
        component: handoverOrderList,
    },
    {
        title: '工程部-资料移交单详情',
        path: '/project/handoverOrder/NewItem',
        component: handoverOrderNewItem,
    },
    {
        title: '工程部-通知文件审批',
        path: '/project/notice/List',
        component: noticeList,
    },
    {
        title: '工程部-通知文件审批详情',
        path: '/project/notice/NewItem',
        component: noticeNewItem,
    },
    {
        title: '工程部-收文管理',
        path: '/project/receive/List',
        component: receiveList,
    },
    {
        title: '工程部-收文管理详情',
        path: '/project/receive/NewItem',
        component: receiveItem,
    },
    {
        title: '工程部-函件审批',
        path: '/project/letter/List',
        component: letterList,
    },
    {
        title: '工程部-函件审批详情',
        path: '/project/letter/NewItem',
        component: letterNewItem,
    },
    {
        title: '工程部-设计项目申请',
        path: '/project/designScheme/List',
        component: designSchemeList,
    },
    {
        title: '工程部-设计项目申请详情',
        path: '/project/designScheme/NewItem',
        component: designSchemeNewItem,
    },
    {
        title: '工程部-工程资料云归档',
        path: '/project/dataArchiving/List',
        component: dataArchivingList,
    },
    {
        title: '工程部-工程过程资料上传',
        path: '/project/dataUpload/List',
        component: dataUploadList,
    },
    {
        title: '工程部-工程过程资料上传详情',
        path: '/project/dataUpload/NewItem',
        component: dataUploadNewItem,
    },
    {
        title: '工程部-出勤签到',
        path: '/project/attendanceSummary/List',
        component: attendanceSummaryList,
    },
    {
        title: '集采中心-集采管理制度',
        path: '/purchase/system/List',
        component: jicaiguanlizhiduList,
    },
    {
        title: '集采中心-人材机总库',
        path: '/purchase/stronger/List',
        component: purchaserencaijizongkuList,
    },
    {
        title: '集采中心-材料调价审批',
        path: '/purchase/priceAdjustment/List',
        component: priceAdjustmentList,
    },
    {
        title: '集采中心-材料调价审批详情',
        path: '/purchase/priceAdjustment/NewItem',
        component: priceAdjustmentNewItem,
    },
    {
        title: '集采中心-供应商入库',
        path: '/purchase/supplier/management/List',
        component: gongyingshangrukuList,
    },
    {
        title: '集采中心-优质供应商入库',
        path: '/purchase/supplier/storage/List',
        component: youzhigongyingshangrukuList,
    },
    {
        title: '集采中心-优质供应商入库详情',
        path: '/purchase/supplier/storage/NewItem',
        component: youzhigongyingshangrukuNewItem,
    },
    {
        title: '集采中心-供应商认价审批',
        path: '/purchase/supplier/audit/List',
        component: purchasegongyingshangrenjiashenpiList,
    },
    {
        title: '供应商认价审批详情',
        path: '/purchase/supplier/audit/NewItem',
        component: purchasegongyingshangrenjiashenpiNewItem,
    },
    {
        title: '集采中心-供应商认价详情数据',
        path: '/purchase/supplier/information/List',
        component: gongyingshangrenjiaxiangqingList,
    },
    {
        title: '集采中心-认价对比筛选',
        path: '/purchase/supplier/information/Contrast',
        component: gongyingshangrenjiaxiangqingContrast,
    },
    {
        title: '集采中心-投标决策审批表',
        path: '/purchase/application/List',
        component: toubiaojueceshenpibiaoList,
    },
    {
        title: '集采中心-项目中标立项',
        path: '/purchase/winning/List',
        component: xiangmuzhongbiaolixiangList,
    },
    {
        title: '集采中心-收入合同审批',
        path: '/purchase/market_contract/List',
        component: shouruhetongshenpiList,
    },
    {
        title: '集采中心-人材机下料单',
        path: '/purchase/blanking/List',
        component: purchaserencaijixialiaodanList,
    },
    {
        title: '集采中心-零星用工',
        path: '/purchase/labor/List',
        component: purchaseLingxingyonggongList,
    },
    {
        title: '集采中心-收货管理',
        path: '/purchase/goods/List',
        component: purchaseshouhuoguanliList,
    },
    {
        title: '集采中心-机械台班记录表',
        path: '/purchase/machinery/List',
        component: jixietaibanjilubiaoList,
    },
    {
        title: '集采中心-项目辅助用材及其他申请',
        path: '/purchase/spMaterial/List',
        component: lingxcailiaoList,
    },
    {
        title: '集采中心-人材机总计划表',
        path: '/purchase/schedule/List',
        component: purchaseRencaijizongjihuabiaoList,
    },
    {
        title: '集采中心-人材机合同审批',
        path: '/purchase/contract/List',
        component: purchaseRencaijihetongshenpiList,
    },
    {
        title: '集采中心-GPS设备分配管理',
        path: '/purchase/equipment/List',
        component: shebeifenpeiguanliList,
    },
    {
        title: '集采中心-工程机械上下机登记',
        path: '/purchase/registration/List',
        component: gongchengjixieshangxiajiList,
    },
    {
        title: '集采中心-工程机械上下机登记详情',
        path: '/purchase/registration/NewItem',
        component: gongchengjixieshangxiajiNewItem,
    },
    {
        title: '集采中心-工程机械运动轨迹',
        path: '/purchase/trajectory/List',
        component: gongchengjixiexingdongguijiList,
    },
    {
        title: '集采中心-工程机械运动轨迹详情',
        path: '/purchase/trajectory/NewItem',
        component: gongchengjixiexingdongguijiNewItem,
    },
    {
        title: '集采中心-机械管理',
        path: '/purchase/warehouse/fixedAssets/machine/mechanicalInformation/List',
        component: mechanicalInformationList,
    },
    {
        title: '集采中心-机操手管理',
        path: '/purchase/warehouse/fixedAssets/machine/machineOperator/List',
        component: machineOperatorList,
    },
    {
        title: '集采中心-油卡管理',
        path: '/purchase/warehouse/fixedAssets/machine/fuelCard/List',
        component: fuelCardList,
    },
    {
        title: '集采中心-调拨申请',
        path: '/purchase/warehouse/fixedAssets/machine/allot/List',
        component: allotList,
    },
    {
        title: '集采中心-调拨申请详情',
        path: '/purchase/warehouse/fixedAssets/machine/allot/NewItem',
        component: allotNewItem,
    },
    {
        title: '行政管理-用户管理',
        path: '/administrative/user/List',
        component: yonghuguanliList,
    },
    {
        title: '行政管理-用户组管理',
        path: '/administrative/userGroup/List',
        component: yonghuzuguanliList,
    },
    {
        title: '行政管理-权限设置',
        path: '/administrative/userGroup/Authority',
        component: yonghuzuguanliAuthority,
    },
    {
        title: '行政管理-入职办理',
        path: '/administrative/human/induction/List',
        component: ruzhibanliList,
    },
    {
        title: '行政管理-入职办理详情',
        path: '/administrative/human/induction/NewItem',
        component: ruzhibanliNewItem,
    },
    {
        title: '行政管理-员工档案',
        path: '/administrative/human/archives/List',
        component: yuangongdanganList,
    },
    {
        title: '行政管理-员工档案详情',
        path: '/administrative/human/archives/NewItem',
        component: yuangongdanganNewItem,
    },
    {
        title: '行政管理-转正申请',
        path: '/administrative/human/positive/List',
        component: adminZhuanzhengshenqingList,
    },
    {
        title: '行政管理-离职办理',
        path: '/administrative/human/departure/List',
        component: adminLizhibanliList,
    },
    {
        title: '行政管理-离职交接',
        path: '/administrative/human/turnoverHandover/List',
        component: turnoverHandoverList,
    },
    {
        title: '行政管理-离职交接',
        path: '/administrative/human/turnoverHandover/NewItem',
        component: turnoverHandoverNewItem,
    },
    {
        title: '行政管理-用工申请',
        path: '/administrative/human/labor/List',
        component: adminYonggongshenqingList,
    },
    {
        title: '行政管理-临时用工申请',
        path: '/administrative/human/temporaryWork/List',
        component: adminLinshiyonggongshenqingList,
    },
    {
        title: '行政管理-临时用工工资表',
        path: '/administrative/human/payroll/List',
        component: linshiyonggonggongzibiaoList,
    },
    {
        title: '行政管理-临时用工工资表详情',
        path: '/administrative/human/payroll/NewItem',
        component: linshiyonggonggongzibiaoNewItem,
    },
    {
        title: '行政管理-组建项目团队',
        path: '/administrative/human/projectTeam/List',
        component: zujianxiangmutuanduiList,
    },
    {
        title: '行政管理-查看任命',
        path: '/administrative/human/projectTeam/AppointList',
        component: zujianxiangmutuanduiAppointList,
    },
    {
        title: '行政管理-任命项目经理',
        path: '/administrative/human/projectTeam/AppointNewItem',
        component: zujianxiangmutuanduiAppointNewItem,
    },
    {
        title: '行政管理-查看团队授权',
        path: '/administrative/human/projectTeam/AccreditNewItem',
        component: zujianxiangmutuanduiAccrediAccreditNewItem,
    },
    {
        title: '行政管理-补卡申请',
        path: '/administrative/attendance/reissueCard/List',
        component: adminBukashenqingList,
    },
    {
        title: '行政管理-出差申请',
        path: '/administrative/attendance/brilliant/List',
        component: adminChuchaishenqingList,
    },
    {
        title: '行政管理-请假申请',
        path: '/administrative/attendance/leave/List',
        component: adminQingjiashenqingList,
    },
    {
        title: '行政管理-派遣申请',
        path: '/administrative/attendance/send/List',
        component: adminPaiqianshenqingList,
    },
    {
        title: '行政管理-考勤汇总',
        path: '/administrative/attendance/summary/List',
        component: kaoqinhuizongList,
    },
    {
        title: '行政管理-考核标准',
        path: '/administrative/performance/standard/List',
        component: kaohebiaozhunList,
    },
    {
        title: '行政管理-考核标准详情',
        path: '/administrative/performance/standard/NewItem',
        component: kaohebiaozhunNewItem,
    },
    {
        title: '行政管理-考核记录',
        path: '/administrative/performance/record/List',
        component: kaohejiluList,
    },
    {
        title: '行政管理-考核结果',
        path: '/administrative/performance/record/ResultList',
        component: kaohejiluResultList,
    },
    {
        title: '行政管理-考核记录详情',
        path: '/administrative/performance/record/NewItem',
        component: kaohejiluNewItem,
    },
    {
        title: '行政管理-工作周报',
        path: '/administrative/workSummary/weekly/List',
        component: adminGongzuozhoubaoList,
    },
    {
        title: '行政管理-周报统计',
        path: '/administrative/workSummary/weekly/StatisticalList',
        component: adminGongzuozhoubaoStatisticalList,
    },
    {
        title: '行政管理-年度总结',
        path: '/administrative/workSummary/annual/List',
        component: adminNianduzongjieList,
    },
    {
        title: '行政管理-用章申请',
        path: '/administrative/seal/chapter/List',
        component: adminYongzhangshenqingList,
    },
    {
        title: '行政管理-用章统计',
        path: '/administrative/seal/chapter/StatisticalList',
        component: yongzhangshenqingStatisticalList,
    },
    {
        title: '行政管理-印章管理',
        path: '/administrative/seal/management/List',
        component: adminYinzhangguanliList,
    },
    {
        title: '行政管理-印章刻制申请',
        path: '/administrative/seal/engrave/List',
        component: adminYinzhangkezhishenqingList,
    },
    {
        title: '行政管理-印章注销',
        path: '/administrative/seal/cancellation/List',
        component: yinzhangzhuxiaoList,
    },
    {
        title: '行政管理-印章注销详情',
        path: '/administrative/seal/cancellation/NewItem',
        component: yinzhangzhuxiaoNewItem,
    },
    {
        title: '行政管理-用车申请',
        path: '/administrative/vehicle/car/List',
        component: adminYongcheshenqingList,
    },
    {
        title: '行政管理-车辆派遣',
        path: '/administrative/vehicle/dispatch/List',
        component: adminCheliangpaiqianList,
    },
    {
        title: '行政管理-设备购置申请',
        path: '/administrative/equipment/ordinary/List',
        component: adminShebeigouzhiList,
    },
    {
        title: '行政管理-办公用品申请',
        path: '/administrative/equipment/stationery/List',
        component: adminBangongyongpinshenqingList,
    },
    {
        title: '行政管理-行政设备购置申请',
        path: '/administrative/equipment/administrative/List',
        component: adminXingzhengshebeigouzhiList,
    },
    {
        title: '行政管理-行政设备购置申请详情',
        path: '/administrative/equipment/administrative/NewItem',
        component: adminXingzhengshebeigouzhiNewItem,
    },
    {
        title: '行政管理-发文管理',
        path: '/administrative/notice/List',
        component: oaNoticeList,
    },
    {
        title: '行政管理-发文管理',
        path: '/administrative/notice/Lists',
        component: oaNoticeLists,
    },
    {
        title: '行政管理-发文管理详情',
        path: '/administrative/notice/NewItem',
        component: oaNoticeNewItem,
    },
    {
        title: '行政管理-流程设计',
        cache: true,
        path: '/administrative/application/List',
        component: liuchengshejiList,
    },
    {
        title: '公共菜单-系统日志',
        path: '/public/system/List',
        component: xitongrizhiList,
    },
    {
        title: '公共菜单-功能点击量排行',
        path: '/public/system/Ranking',
        component: xitongrizhiRanking,
    },
    {
        title: '公共菜单-补卡申请',
        path: '/public/human/reissueCard/List',
        component: publicbukashenqingList,
    }, {
        title: '补卡申请详情',
        path: '/public/human/reissueCard/NewItem',
        component: publicbukashenqingNewItem,
    },
    {
        title: '公共菜单-出差申请',
        path: '/public/human/evection/List',
        component: publicChuchaishenqingList,
    }, {
        title: '出差申请详情',
        path: '/public/human/evection/NewItem',
        component: publicChuchaishenqingNewItem,
    },
    {
        title: '公共菜单-请假申请',
        path: '/public/human/leave/List',
        component: publicqingjiashenqingList,
    }, {
        title: '请假申请详情',
        path: '/public/human/leave/NewItem',
        component: publicqingjiashenqingNewItem,
    },
    {
        title: '公共菜单-工作周报',
        path: '/public/human/weekly/List',
        component: publicgongzuozhoubaoList,
    },
    {
        title: '工作周报详情',
        path: '/public/human/weekly/NewItem',
        component: publicgongzuozhoubaoNewItem,
    },
    {
        title: '公共菜单-工作通知暂管申请',
        path: '/public/temporaryPipe/List',
        component: temporaryPipeList,
    },
    {
        title: '公共菜单-工作通知暂管申请',
        path: '/public/temporaryPipe/NewItem',
        component: temporaryPipeNewItem,
    },
    {
        title: '公共菜单-员工借款审批',
        path: '/public/payment/borrowing/List',
        component: yuangongjiekuanshenpiList,
    }, {
        title: '公共菜单-员工借款审批详情',
        path: '/public/payment/borrowing/NewItem',
        component: yuangongjiekuanshenpiNewItem,
    },
    {
        title: '公共菜单-费用报销审批',
        path: '/public/payment/reimbursement/List',
        component: feiyongbaoxiaoshenpiList,
    }, {
        title: '公共菜单-费用报销审批详情',
        path: '/public/payment/reimbursement/NewItem',
        component: feiyongbaoxiaoshenpiNewItem,
    },
    {
        title: '公共菜单-综合科研付款审批',
        path: '/public/payment/comprehensive/List',
        component: zonghekeyanfukuanList,
    }, {
        title: '公共菜单-综合科研付款审批详情',
        path: '/public/payment/comprehensive/NewItem',
        component: zonghekeyanfukuanNewItem,
    },
    {
        title: '公共菜单-工程款支付审批',
        path: '/public/payment/engineering/List',
        component: gongchengkuanzhifushenpiList,
    }, {
        title: '公共菜单-工程款支付审批详情',
        path: '/public/payment/engineering/NewItem',
        component: gongchengkuanzhifushenpiNewItem,
    },
    {
        title: '公共菜单-专利续费付款审批',
        path: '/public/payment/patentRenewal/List',
        component: patentRenewalList,
    }, {
        title: '公共菜单-专利续费付款审批',
        path: '/public/payment/patentRenewal/NewItem',
        component: patentRenewalNewItem,
    },
    {
        title: '公共菜单-用工申请',
        path: '/public/human/labor/List',
        component: publicyonggongshenqingList,
    }, {
        title: '用工申请详情',
        path: '/public/human/labor/NewItem',
        component: publicyonggongshenqingNewItem,
    },
    {
        title: '公共菜单-临时用工申请',
        path: '/public/human/temporaryWork/List',
        component: publiclinshiyonggongshenqingList,
    }, {
        title: '临时用工申请详情',
        path: '/public/human/temporaryWork/NewItem',
        component: publiclinshiyonggongshenqingNewItem,
    },
    {
        title: '公共菜单-转正申请',
        path: '/public/human/positive/List',
        component: zhuanzhengshenqingList,
    }, {
        title: '转正申请详情',
        path: '/public/human/positive/NewItem',
        component: zhuanzhengshenqingNewItem,
    },
    {
        title: '公共菜单-离职办理',
        path: '/public/human/departure/List',
        component: lizhibanliList,
    }, {
        title: '离职办理详情',
        path: '/public/human/departure/NewItem',
        component: lizhibanliNewItem,
    },
    {
        title: '公共菜单-派遣申请',
        path: '/public/human/send/List',
        component: publicpaiqianshenqingList,
    }, {
        title: '派遣申请详情',
        path: '/public/human/send/NewItem',
        component: publicpaiqianshenqingNewItem,
    },
    {
        title: '公共菜单-年度总结',
        path: '/public/human/conclusion/List',
        component: publicnianduzongjieList,
    },
    {
        title: '年度总结详情',
        path: '/public/human/conclusion/NewItem',
        component: publicnianduzongjieNewItem,
    },
    {
        title: '公共菜单-用章申请',
        path: '/public/office/chapter/List',
        component: publicyongzhangshenqingList,
    },
    {
        title: '用章申请详情',
        path: '/public/office/chapter/NewItem',
        component: publicyongzhangshenqingNewItem,
    },
    {
        title: '公共菜单-用车申请',
        path: '/public/office/car/List',
        component: yongcheshenqingList,
    },
    {
        title: '用车申请详情',
        path: '/public/office/car/NewItem',
        component: yongcheshenqingNewItem,
    },
    {
        title: '公共菜单-车辆派遣',
        path: '/public/office/vehicle/List',
        component: cheliangpaiqianList,
    },
    {
        title: '车辆派遣详情',
        path: '/public/office/vehicle/NewItem',
        component: cheliangpaiqianNewItem,
    },
    {
        title: '公共菜单-业务招待申请',
        path: '/public/office/business/List',
        component: yewuzhaodaishenqingList,
    },
    {
        title: '公共菜单-业务招待申请详情',
        path: '/public/office/business/NewItem',
        component: yewuzhaodaishenqingNewItem,
    },
    {
        title: '公共菜单-办公用品申请',
        path: '/public/office/stationery/List',
        component: bangongyongpinshenqingList,
    },
    {
        title: '办公用品申请详情',
        path: '/public/office/stationery/NewItem',
        component: bangongyongpinshenqingNewItem,
    },
    {
        title: '公共菜单-设备购置申请',
        path: '/public/office/purchase/List',
        component: publicshebeigouzhishenqingList,
    },
    {
        title: '设备购置申请详情',
        path: '/public/office/purchase/NewItem',
        component: publicshebeigouzhishenqingNewItem,
    },
    {
        title: '公共菜单-印章刻制申请',
        path: '/public/office/seal/List',
        component: publicyinzhangkezhishenqingList,
    },
    {
        title: '印章刻制申请详情',
        path: '/public/office/seal/NewItem',
        component: publicyinzhangkezhishenqingNewItem,
    },
    {
        title: '公共菜单-公司合同审批',
        path: '/public/contract/List',
        component: gongsihetongshenpiList,
    }, {
        title: '公共菜单-公司合同审批详情',
        path: '/public/contract/NewItem',
        component: gongsihetongshenpiNewItem,
    },
    {
        title: '公共菜单-施工组织设计审批',
        path: '/public/construction/List',
        component: shigongzuzhishejiList,
    },{
        title: '公共菜单-施工组织设计审批详情',
        path: '/public/construction/NewItem',
        component: shigongzuzhishejiNewItem,
    },
    {
        title: '财务部-项目收款',
        path: '/finance/collectmoney/List',
        component: xiangmushoukuanList,
    }, {
        title: '财务部-项目付款',
        path: '/finance/pay/List',
        component: xiangmufukuanList,
    },
    {
        title: '财务部-费用报销',
        path: '/finance/reimbursement/List',
        component: financeFeiyongbaoxiao,
    },
    {
        title: '项目总看板-项目动态总控表',
        path: '/item/control/List',
        component: dongtaizongkongbiaoList,
    },
    {
        title: '项目总看板-项目成本归集表',
        path: '/finance/aggregation/List',
        component: chengbenguijibiaoList,
    },
    {
        title: '设计中心-设计文件移交及进度汇报',
        path: '/design/documentTransfer/List',
        component: documentTransferList,
    }, {
        title: '设计中心-设计文件移交及进度汇报详情',
        path: '/design/documentTransfer/NewItem',
        component: documentTransferNewItem,
    },
    {
        title: '科研院-客户管理',
        path: '/scientific_research/customer/List',
        component: customerList,
    },
    {
        title: '科研院-科技项目申报',
        path: '/scientific_research/project_application/List',
        component: projectApplicationList,
    },
    {
        title: '科研院-科技项目申报详情',
        path: '/scientific_research/project_application/NewItem',
        component: projectApplicationNewItem,
    },
    {
        title: '科研院-科技项目申报-合同审批',
        path: '/scientific_research/project_application/ConApprovalList',
        component: projectApplicationConApprovalList,
    },
    {
        title: '科研院-科技项目申报-合同审批详情',
        path: '/scientific_research/project_application/ConApprovalNewItem',
        component: projectApplicationConApprovalNewItem,
    },
    {
        title: '科研院-科技项目申报-费用支付',
        path: '/scientific_research/project_application/PayList',
        component: projectApplicationPayList,
    },
    {
        title: '科研院-科技项目申报-费用支付',
        path: '/scientific_research/project_application/PayNewItem',
        component: projectApplicationPayNewItem,
    },
    {
        title: '科研院-研发项目',
        path: '/scientific_research/project/List',
        component: ResearchProjectList,
    },
    {
        title: '科研院-研发项目详情',
        path: '/scientific_research/project/NewItem',
        component: ResearchProjectNewItem,
    },
    {
        title: '科研院-研发项目-合同审批',
        path: '/scientific_research/project/ConApprovalList',
        component: ResearchProjectConApprovalList,
    },
    {
        title: '科研院-研发项目-合同审批详情',
        path: '/scientific_research/project/ConApprovalNewItem',
        component: ResearchProjectConApprovalNewItem,
    },
    {
        title: '科研院-研发项目-费用支付',
        path: '/scientific_research/project/PayList',
        component: ResearchProjectPayList,
    },
    {
        title: '科研院-研发项目-费用支付',
        path: '/scientific_research/project/PayNewItem',
        component: ResearchProjectPayNewItem,
    },
    {
        title: '科研院-研发项目-中期检查报告',
        path: '/scientific_research/project/InspectNewItem',
        component: ResearchProjectInspectNewItem,
    },
    {
        title: '科研院-研发项目-结题验收申请表',
        path: '/scientific_research/project/ConcludeNewItem',
        component: ResearchProjectConcludeNewItem,
    },
    {
        title: '科研院-研发项目-延期结题',
        path: '/scientific_research/project/PostponeList',
        component: ResearchProjectPostponeList,
    },
    {
        title: '科研院-研发项目-延期结题申请',
        path: '/scientific_research/project/PostponeNewItem',
        component: ResearchProjectPostponeNewItem,
    },
    {
        title: '科研院-成果管理',
        path: '/scientific_research/knowledge/achievement/List',
        component: AchievementList,
    },
    {
        title: '科研院-资质荣誉证书',
        path: '/scientific_research/knowledge/honor/List',
        component: HonorList,
    },
    {
        title: '科研院-资料管理',
        path: '/scientific_research/datum/List',
        component: DatumList,
    },
    {
        title: '科研院-项目养护巡查',
        path: '/scientific_research/patrol/List',
        component: PatrolList,
    },
    {
        title: '科研院-养护巡查记录 ',
        path: '/scientific_research/patrol/Record',
        component: PatrolRecord,
    },
    {
        title: '科研院-科研相关合同',
        path: '/scientific_research/contract/List',
        component: ContractList,
    }, {
        title: '科研院-公司合同审批详情',
        path: '/scientific_research/contract/NewItem',
        component: ContractNewItem,
    },
    {
        title: '科研院-三五八成果转化-苗圃名称对应表',
        path: '/scientific_research/achievement_transformation/nursery/List',
        component: NurseryList,
    },
    {
        title: '科研院-三五八成果转化-植物引种记录表',
        path: '/scientific_research/achievement_transformation/plantIntroduction/List',
        component: PlantIntroductionList,
    },{
        title: '科研院-三五八成果转化-植物引种记录表详情',
        path: '/scientific_research/achievement_transformation/plantIntroduction/NewItem',
        component: PlantIntroductionNewItem,
    },
    {
        title: '科研院-三五八成果转化-入库管理',
        path: '/scientific_research/achievement_transformation/beputinstorage/List',
        component: BeputinstorageList,
    },{
        title: '科研院-三五八成果转化-入库管理详情',
        path: '/scientific_research/achievement_transformation/beputinstorage/NewItem',
        component: BeputinstorageNewItem,
    },
    {
        title: '科研院-三五八成果转化-出库管理',
        path: '/scientific_research/achievement_transformation/deliveryfromstorage/List',
        component: DeliveryfromstorageList,
    },{
        title: '科研院-三五八成果转化-出库管理详情',
        path: '/scientific_research/achievement_transformation/deliveryfromstorage/NewItem',
        component: DeliveryfromstorageNewItem,
    },
    {
        title: '科研院-三五八成果转化-库存统计',
        path: '/scientific_research/achievement_transformation/inventoryStatistics/List',
        component: inventoryStatisticsList,
    },
    {
        title: '科研院-三五八成果转化-库存总表',
        path: '/scientific_research/achievement_transformation/inventoryStatistics/SummaryList',
        component: inventoryStatisticsSummaryList,
    },    
    {
        title: '科研院-三五八成果转化-项目立项',
        path: '/scientific_research/achievement_transformation/projectInitiation/List',
        component: ProjectinitiationList,
    },{
        title: '科研院-三五八成果转化-项目立项详情',
        path: '/scientific_research/achievement_transformation/projectInitiation/NewItem',
        component: ProjectinitiationNewItem,
    },
    {
        title: '科研院-三五八成果转化-项目合同审批',
        path: '/scientific_research/achievement_transformation/proContractApproval/List',
        component:ProcontractapprovalList,
    },{
        title: '科研院-三五八成果转化-项目合同审批详情',
        path: '/scientific_research/achievement_transformation/proContractApproval/NewItem',
        component: ProcontractapprovalNewItem,
    },
    {
        title: '科研院-三五八成果转化-合同审批',
        path: '/scientific_research/achievement_transformation/contractApproval/List',
        component: ContractapprovalList,
    },{
        title: '科研院-三五八成果转化-合同审批详情',
        path: '/scientific_research/achievement_transformation/contractApproval/NewItem',
        component: ContractapprovalNewItem,
    },
    {
        title: '科研院-三五八成果转化-进度计量审批',
        path: '/scientific_research/achievement_transformation/progressSettlement/List',
        component: ProgresssettlementList,
    },{
        title: '科研院-三五八成果转化-进度计量审批详情',
        path: '/scientific_research/achievement_transformation/progressSettlement/NewItem',
        component: ProgresssettlementNewItem,
    },
    {
        title: '科研院-三五八成果转化-结算审批',
        path: '/scientific_research/achievement_transformation/nurserySettlement/List',
        component: NurserysettlementlList,
    },{
        title: '科研院-三五八成果转化-结算审批详情',
        path: '/scientific_research/achievement_transformation/nurserySettlement/NewItem',
        component: NurserysettlementNewItem,
    },
    {
        title: '科研院-三五八成果转化-付款审批',
        path: '/scientific_research/achievement_transformation/paymentApproval/List',
        component: PaymentapprovalList,
    },{
        title: '科研院-三五八成果转化-付款审批详情',
        path: '/scientific_research/achievement_transformation/paymentApproval/NewItem',
        component: PaymentapprovalNewItem,
    },
    {
        title: '科研院-三五八成果转化-认质核价审批',
        path: '/scientific_research/achievement_transformation/identity/List',
        component: IdentityList,
    },{
        title: '科研院-三五八成果转化-认质核价审批详情',
        path: '/scientific_research/achievement_transformation/identity/NewItem',
        component: IdentityNewItem,
    },
    {
        title: '科研院-三五八成果转化-358临时用工申请',
        path: '/scientific_research/achievement_transformation/temporaryWorker/List',
        component: TemporaryWorkerList,
    },{
        title: '科研院-三五八成果转化-358临时用工申请详情',
        path: '/scientific_research/achievement_transformation/temporaryWorker/NewItem',
        component: TemporaryWorkerNewItem,
    },
    {
        title: '系统配置-企业微信通知配置',
        path: '/disposition/notification/List',
        component:NotificationList,
    },{
        title: '系统配置-企业微信通知配置详情',
        path: '/disposition/notification/NewItem',
        component: NotificationNewItem,
    },
    {
        title: '系统配置-历史记录',
        path: '/disposition/history/List',
        component:HistoryList,
    },{
        title: '系统配置-历史记录详情',
        path: '/disposition/history/NewItem',
        component: HistoryNewItem,
    },
    {
        title: '科研院-三五八成果转化-358临时用工申请',
        path: '/item/projectKanban/List',
        component: projectKanbanList,
    },{
        title: '科研院-三五八成果转化-358临时用工申请详情',
        path: '/item/projectKanban/NewItem',
        component: projectKanbanNewItem,
    },

];

export default router

