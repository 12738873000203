import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Big from 'big.js';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { selectContractProject, createCostProgressPayment, CostProgressDetail } from "../../../utils/cost"
import { selectAvailableSeal, } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/progressPayment.docx',
                templateName: '四川天艺生态园林集团股份有限公司工程进度款',
                tempData: {
                    stage: '',
                    apply_date: '',
                    pro_name: '',
                    seal_name: '',
                    con_money: '',
                    mea_dec: '',
                    metering: '',
                    grand_meter: '',
                    pay_pro: '',
                    pay_money: '',
                    info: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "工程进度款审批",
                TableInfo: [{
                    FieldTitle: '发起人', //字段标题
                    fieldName: 'user_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择日期', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                },
                {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    isShow: true
                },
                {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '进度期数', //字段标题
                    fieldName: 'stage', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '合同金额(元)', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '付款方式', //字段标题
                    fieldName: 'term_payment', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '本期计量申报金额(元)', //字段标题
                    fieldName: 'mea_dec', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '本期计量审定金额(元)', //字段标题
                    fieldName: 'metering', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入本期计量审定金额(元)', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '累计计量审定金额(元)', //字段标题
                    fieldName: 'grand_meter', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '进度款支付比例(%)', //字段标题
                    fieldName: 'pay_pro', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入进度款支付比例(%)', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '本期应付金额(元)', //字段标题
                    fieldName: 'pay_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入本期应付金额(元)', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '累计应付金额(元)', //字段标题
                    fieldName: 'grand_pay_money', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '累计支付比例(%)', //字段标题
                    fieldName: 'grand_pro', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: []
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            seal_name: '',
            pro_name: '',
            up_grand_meter: '',
            up_grand_pay_money: '',
            con_money: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: true
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_CostProgressDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        Info.state = 1
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '发起人') {
                item.value = userInfo.name
                console.log(userInfo.name)
            }
        })
        this.setState(preState => ({
            Info,
        }))
    }

    get_CostProgressDetail = (tableId) => {
        let { Info, form, up_grand_meter, up_grand_pay_money, con_money } = this.state;
        let data = {
            table_id: tableId
        }
        CostProgressDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.Detail.is_seal === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                        if (res.data.Detail.status === 4) {
                            Info.tempData.stage = res.data.Detail.stage
                            Info.tempData.apply_date = res.data.Detail.apply_day
                            Info.tempData.pro_name = res.data.Detail.pro_name
                            Info.tempData.seal_name = res.data.Detail.sealNames || '/'
                            Info.tempData.con_money = res.data.Detail.set_money
                            Info.tempData.mea_dec = res.data.Detail.mea_dec
                            Info.tempData.metering = res.data.Detail.metering
                            Info.tempData.grand_meter = res.data.Detail.grand_meter
                            Info.tempData.pay_pro = res.data.Detail.pay_pro
                            Info.tempData.pay_money = res.data.Detail.pay_money
                            Info.tempData.info = res.data.Detail.info
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '发起人') {
                            item.value = res.data.Detail.applyUserName
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '合同金额(元)') {
                            item.value = res.data.Detail.set_money
                            con_money = res.data.Detail.set_money
                        } else if (item.FieldTitle === '付款方式') {
                            this.NewForm.current.formRef.current.setFieldsValue({
                                term_payment: res.data.Detail.term_payment,
                            })
                        } else if (item.FieldTitle === '累计计量审定金额(元)') {
                            item.value = res.data.Detail.grand_meter
                            up_grand_meter = res.data.Detail.grand_meter
                            form.grand_meter = res.data.Detail.grand_meter
                        } else if (item.FieldTitle === '累计应付金额(元)') {
                            item.value = res.data.Detail.grand_pay_money
                            up_grand_pay_money = res.data.Detail.grand_pay_money
                            form.grand_pay_money = res.data.Detail.grand_pay_money
                        } else if (item.FieldTitle === '累计支付比例(%)') {
                            item.value = res.data.Detail.grand_pro
                            form.grand_pro = res.data.Detail.grand_pro
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.Detail.apply_day ? moment(res.data.Detail.apply_day, 'YYYY-MM-DD') : '',
                        is_seal: res.data.Detail.is_seal,
                        stage: res.data.Detail.stage,
                        mea_dec: res.data.Detail.mea_dec,
                        metering: res.data.Detail.metering,
                        pay_pro: res.data.Detail.pay_pro,
                        pay_money: res.data.Detail.pay_money,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        con_money,
                        up_grand_meter,
                        up_grand_pay_money,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ContractProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectContractProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                con_money: item.set_money || 0,
                                term_payment: item.term_payment || '/',
                                grand_money: item.grand_money || 0,
                                grand_pay_money: item.grand_pay_money || 0
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.is_seal = data.is_seal
        form.stage = data.stage
        form.mea_dec = data.mea_dec
        form.metering = data.metering
        form.pay_pro = data.pay_pro
        form.pay_money = data.pay_money
        form.info = data.info

        createCostProgressPayment(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_seal()
                radio = false
            } else if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '合同金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                    {
                        title: '付款方式',
                        dataIndex: 'term_payment',
                        key: 'term_payment',
                        width: '250px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '240px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '累计计量审定金额',
                        dataIndex: 'grand_money',
                        key: 'grand_money',
                    },
                    {
                        title: '累计应付金额（元）',
                        dataIndex: 'grand_pay_money',
                        key: 'grand_pay_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_ContractProject()
                radio = true
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handleRadio = (value, title) => {
        let { Info } = this.state;
        if (title === '是否盖章') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = false
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = false
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = true
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = true
                    }
                })
            }
        }

        this.setState(preState => ({
            Info
        }))
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_aboutMachineryContract()
            })
        } else if (buttonTitle === '选择印章') {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            seal_name: '',
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '选择项目') {
                this.get_ContractProject()
            }
        })
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle, up_grand_meter, up_grand_pay_money, con_money } = this.state;
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '合同金额(元)') {
                    item.value = value[0].con_money
                    con_money = value[0].con_money
                } else if (item.FieldTitle === '付款方式') {
                    this.NewForm.current.formRef.current.setFieldsValue({
                        term_payment: value[0].term_payment,
                    })
                } else if (item.FieldTitle === '累计计量审定金额(元)') {
                    item.value = value[0].grand_money
                    up_grand_meter = value[0].grand_money
                } else if (item.FieldTitle === '累计应付金额(元)') {
                    item.value = value[0].grand_pay_money
                    up_grand_pay_money = value[0].grand_pay_money
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            con_money,
            up_grand_meter,
            up_grand_pay_money,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handleInput = (value, name) => {
        let { form, Info, con_money, up_grand_meter, up_grand_pay_money } = this.state
        if (name === '本期计量审定金额(元)') {
            form.metering = value

            if (up_grand_meter || up_grand_meter === 0) {
                form.grand_meter = Big(Number(form.metering)).plus(Number(up_grand_meter)).toFixed(2);
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '累计计量审定金额(元)') {
                        item.value = form.grand_meter
                    }
                })
            }
        } else if (name === '本期应付金额(元)') {
            form.pay_money = value

            if (up_grand_pay_money || up_grand_pay_money === 0) {
                form.grand_pay_money = Big(Number(form.pay_money)).plus(Number(up_grand_pay_money)).toFixed(2);
                let grand_pro = Math.round(Number(form.grand_pay_money) / Number(con_money) * 100)
                form.grand_pro = grand_pro
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '累计应付金额(元)') {
                        item.value = form.grand_pay_money
                    } else if (item.FieldTitle === '累计支付比例(%)') {
                        item.value = form.grand_pro
                    }
                })
            }
        }

        this.setState(preState => ({
            form,
            Info
        })
        )
    }
    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} radioEvent={this.handleRadio} auditEvent={this.handleButton} InputEvent={this.handleInput} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}