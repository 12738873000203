import React, { Component } from 'react'
import { message } from 'antd';
import Icon, { PieChartOutlined, DownloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import axios from 'axios'
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { getFunctionDeName, getAccessData } from "../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: '功能名称',
                    dataIndex: 'name',
                    key: 'name',
                    width: '250px'
                },
                {
                    title: '浏览次数',
                    dataIndex: 'browse_number',
                    key: 'browse_number',
                },
                {
                    title: '最近浏览时间',
                    dataIndex: 'browse_time',
                    key: 'browse_time',
                },
                {
                    title: '最近浏览ID',
                    dataIndex: 'browse_ID',
                    key: 'browse_ID',
                },
                {
                    title: '提交次数',
                    dataIndex: 'submit_number',
                    key: 'submit_number',
                },
                {
                    title: '最近提交时间',
                    dataIndex: 'submit_time',
                    key: 'submit_time',
                },
                {
                    title: '最近提交ID',
                    dataIndex: 'submit_ID',
                    key: 'submit_ID',
                },
                {
                    title: '',
                    dataIndex: 'handle',
                    key: 'handle',
                    width: '120px',
                    render: (text, record, index) => {
                        if (text) {
                            return (
                                <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => {
                                    this.get_details(record)
                                }}>查看详情</div>
                            )
                        }
                    }
                },
            ],
            FlowchartKey: 1,
            expandedRowKey: [],
            selectValue: []
        }

    }

    NewForm = React.createRef();

    componentDidMount() {
        let { expandedRowKey } = this.state
        if (!expandedRowKey.length) {
            this.get_FunctionDeName()
        }
    }

    get_FunctionDeName = () => {
        let { dataSource } = this.state
        getFunctionDeName().then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    for (let key in res.data) {
                        let name = res.data[key]
                        let value = {
                            key: key,
                            name: name,
                            children: []
                        }
                        dataSource = [...dataSource, value]
                    }
                    this.setState({
                        dataSource,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleExport = () => {
        let { selectValue } = this.state
        let table_name = []
        if (!selectValue.length) {
            message.error('请选择导出项')
            return
        }
        selectValue.forEach((item, index) => {
            if (item.table_name) {
                table_name = [...table_name, item.table_name]
            }
        })
        let data = {
            function_table: JSON.stringify(table_name),
        }
        axios.get('/api/exportHistoryDataLogs', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '访问历史记录.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    handleSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    get_details(record) {
        this.props.history.push({
            pathname: '/disposition/history/NewItem',
            query: {
                'type': record.type,
                'table_name': record.table_name,
            }
        })
    }

    handleExpand = (stutas, record) => {
        let { dataSource, expandedRowKey } = this.state
        if (stutas) {
            this.get_AccessData(record.name)
        } else {
            dataSource.forEach((item, index) => {
                if (item.name === record.name) {
                    item.children = []
                }
            })

            expandedRowKey = []
            dataSource.forEach((item, index) => {
                if (item.children.length) {
                    console.log(item.key)
                    expandedRowKey.push(item.key)
                }
            })

            this.setState({
                dataSource,
                expandedRowKey,
                FlowchartKey: Math.random(),
            })
        }
    }

    get_AccessData = (name) => {
        let { dataSource, expandedRowKey } = this.state
        let data = {
            type: name
        }
        getAccessData(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.length) {
                        dataSource.forEach((item, index) => {
                            if (item.name === name) {
                                item.children = []
                                res.data.forEach((tem, dex) => {
                                    let last_time = tem.last_time ? tem.last_time.slice(0, 10) : '/'
                                    let commit_last_time = tem.commit_last_time ? tem.commit_last_time.slice(0, 10) : '/'
                                    let value = {
                                        key: item.key + '-' + dex,
                                        type: name,
                                        name: tem.function_name,
                                        table_name: tem.function,
                                        browse_number: tem.see_number,
                                        browse_time: last_time,
                                        browse_ID: tem.last_user || '/',
                                        submit_number: tem.commit_number,
                                        submit_time: commit_last_time,
                                        submit_ID: tem.commit_last_user || '/',
                                        handle: true
                                    }
                                    item.children = [...item.children, value]
                                })
                            }
                        })
                    }

                    expandedRowKey = []
                    dataSource.forEach((item, index) => {
                        if (item.children.length) {
                            console.log(item.key)
                            expandedRowKey.push(item.key)
                        }
                    })

                    this.setState({
                        dataSource,
                        expandedRowKey,
                        FlowchartKey: Math.random(),
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    render() {
        let { columns, dataSource, expandedRowKey, FlowchartKey } = this.state
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} seek={true} notPrint={true} exportEvent={this.handleExport} />
                <div>
                    <Table key={FlowchartKey} columns={columns} dataSource={dataSource} pages={false} checkStrictly={true} expandedRowKey={expandedRowKey} expandEvent={this.handleExpand} selectEvent={this.handleSelect} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
