import React, { useEffect } from 'react';
import * as echarts from 'echarts';
 
const LineChart = ({ option, style }) => {
  useEffect(() => {
    // 初始化echarts实例
    const chart = echarts.init(document.getElementById('chart'));
    // 使用配置项和数据显示图表
    chart.setOption(option);
    // 窗口尺寸变更时，图表也重绘
    window.addEventListener('resize', chart.resize);
    // 组件卸载时，移除窗口监听器以避免内存泄露
    return () => window.removeEventListener('resize', chart.resize);
  }, []);
 
  return <div id="chart" style={{ width: '100%', height: '320px' }}></div>;
};
 
export default LineChart;