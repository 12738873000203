import React, { Component } from 'react'
import axios from 'axios'
import { Select, Button, Modal, message } from 'antd';
import { CaretDownOutlined, BarChartOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Table from '../../components/Table'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import calendarImg from "../../../accets/img/calendar.png"
import { ConstructionLogSummary } from "../../../utils/project"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            weekList: [],
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    width: '280px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '应提交次数',
                    dataIndex: 'submittedFrequency',
                    key: 'submittedFrequency',
                },
                {
                    title: '已提交次数',
                    dataIndex: 'subFrequency',
                    key: 'subFrequency',
                },
                {
                    title: '未提交次数',
                    dataIndex: 'notSubFrequency',
                    key: 'notSubFrequency',
                },
                {
                    title: '未提交日期',
                    dataIndex: 'detail',
                    key: 'detail',
                    render: (text, index) => {
                        return (
                            <div style={{
                                fontSize: '16px',
                                color: '#339EF9',
                                cursor: 'pointer'
                            }} onClick={() => { this.modalVisible(text) }}>
                                点击查看
                            </div>
                        )
                    }
                },
            ],
            yearList: [
                { value: 2022, label: "2022年" },
                { value: 2023, label: "2023年" },
                { value: 2024, label: "2024年" },
                { value: 2025, label: "2025年" },
                { value: 2026, label: "2026年" },
                { value: 2027, label: "2027年" },
                { value: 2028, label: "2028年" },
                { value: 2029, label: "2029年" },
                { value: 2030, label: "2030年" },
            ],
            yearValue: '',
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
            pro_name: '',
            weeklyModal: false,
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            projects:''
        }
    }

    componentDidMount() {
        this.get_ConstructionLogSummary()
    }

    get_ConstructionLogSummary = () => {
        let { dataSource, pages, yearValue, monthValue, pro_name } = this.state
        let data = {
            year: yearValue,
            month: monthValue,
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        ConstructionLogSummary(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                pro_id: item.pro_id,
                                proName: item.proName,
                                submittedFrequency: item.submittedFrequency,
                                subFrequency: item.subFrequency,
                                notSubFrequency: item.notSubFrequency,
                                detail: item.notSubmitDays,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleExport = () => {
        let { pro_name, yearValue, monthValue, projects } = this.state
        let data = {
            pro_name: pro_name,
            year: yearValue,
            month: monthValue,
            projects: projects
        }
        axios.get('/api/ConstructionLogExport', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '施工日志.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    modalVisible = (value) => {
        let { weekList } = this.state
        weekList = []
        if (value.length) {
            value.forEach((item, index) => {
                let label = {
                    year: item.slice(0, 4),
                    month: item.slice(5, 10)
                }
                weekList = [...weekList, label]
            })
            this.setState({
                weekList,
                weeklyModal: true
            })
        } else {
            message.error('暂无未提交日志')
        }
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => { this.get_ConstructionLogSummary() })
    }

    monthChange = (value) => {
        this.setState({
            monthValue: value
        }, () => { this.get_ConstructionLogSummary() })
    }

    modalCancel = () => {
        this.setState({
            weeklyModal: false
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => { this.get_ConstructionLogSummary() })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_ConstructionLogSummary()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
        }, () => {
            this.get_ConstructionLogSummary()
        })
    }

    handleSelect = (value) => {
        let { projects } = this.state
        projects = []
        value.forEach((item, index) => {
            projects = [...projects, item.pro_id]
        })
        projects = JSON.stringify(projects)
        this.setState({
            projects
        })
    }

    render() {
        let { dataSource, columns, weeklyModal, yearValue, yearList, monthList, monthValue, weekList, searchShow, TableInfo } = this.state
        return (
            <div>
                <div className='labor_search'>
                    {/* <div className='history ListOperate' style={{ width: 'auto', marginRight: '15px' }}>
                        <Button onClick={() => {
                            this.handleExport()
                        }}>
                            <BarChartOutlined />
                            <div>周报导出</div>
                        </Button>
                    </div> */}
                    <Operate notPrint={true} dontAdd={true} dontImport={true} searchEvent={this.handleSearch} exportEvent={this.handleExport} searchShow={searchShow} />

                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    this.yearChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择月份 :</div>
                            <Select
                                options={monthList}
                                value={monthValue}
                                onChange={(e) => {
                                    this.monthChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handleSelect} />

                <Modal
                    title='未提交日志日期'
                    centered
                    visible={weeklyModal}
                    onCancel={() => { this.modalCancel() }}
                    wrapClassName='weekly weeklys'
                    footer={[
                        <Button type="primary" onClick={() => { this.modalCancel() }}>确认</Button>
                    ]}
                >
                    <div className='weekly_time'>

                        {
                            weekList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={calendarImg} alt=""></img>
                                        <div>
                                            <div>{item.month}</div>
                                            <div>{item.year}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(List)
