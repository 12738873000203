import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { reviewApplyResult } from "../../../utils/api"
import { DesignEngProjectDetail, createDesignEngProject } from "../../../utils/project"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "设计项目任务申请",
                TableInfo: [
                    {
                        FieldTitle: '发起人', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '部门名称', //字段标题
                        fieldName: 'deName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入项目名称', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '提交成果形式(多选)', //字段标题
                        fieldName: 'result', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择提交成果形式(多选)', //必填要求
                        value: [],
                        width: '50%',
                        selectList: [
                            { value: 1, label: "效果图" },
                            { value: 2, label: "文本" },
                            { value: 3, label: "施工图" },
                            { value: 4, label: "竣工图" },

                        ],
                        multiple: true
                    },
                    {
                        FieldTitle: '计划完成时间', //字段标题
                        fieldName: 'complete', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择计划完成时间', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '配合内容', //字段标题
                        fieldName: 'content', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '任务要求', //字段标题
                        fieldName: 'demand', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
                FirstInfo: [],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DesignEngProjectDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const {
            Info
        } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '发起人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门名称') {
                item.value = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_DesignEngProjectDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DesignEngProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '发起人') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        result: JSON.parse(res.data.Detail.result),
                        complete: res.data.Detail.complete ? moment(res.data.Detail.complete, 'YYYY-MM-DD') : '',
                        content: res.data.Detail.content,
                        demand: res.data.Detail.demand,
                        info: res.data.Detail.info,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.result = JSON.stringify(data.result)
        form.complete = data.complete ? data.complete.format('YYYY-MM-DD') : ''
        form.content = data.content
        form.demand = data.demand
        form.info = data.info

        createDesignEngProject(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />
            </div>
        )
    }
}