import React, { Component } from 'react'
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'

export default class NewItem extends Component {
    state = {
        Info: {
            state: 1,
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
				edit: false
            },
            FlowModelName: "工程机械运动轨迹",
            TableInfo: [ {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },{
                FieldTitle: '操作员', //字段标题
                fieldName: 'pro_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },{
                FieldTitle: '操作类型', //字段标题
                fieldName: 'pro_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },{
                FieldTitle: '操作时间', //字段标题
                fieldName: 'apply_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: moment('2022-06-15', 'YYYY-MM-DD'),
                width: '50%'
            },{
                FieldTitle: '工作类型', //字段标题
                fieldName: 'pro_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },{
                FieldTitle: '里程表数据', //字段标题
                fieldName: 'pro_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '工作内容', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '整体环境+机械，机械内部里程表', //字段标题
                fieldName: 'bid_refund_time', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }
            ],
            FirstInfo: [],
        }
    }
    render() {
        return (
            <div className='configAdd'>
                <AddConfig Info={this.state.Info} />
            </div>
        )
    }
}
