import React, { Component } from 'react'
import { message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { noticeList, deleteNotice } from "../../../utils/oa"
import { fileDownload } from "../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '标题',
                    dataIndex: 'notice_name',
                    key: 'notice_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '关键字',
                    dataIndex: 'key_str',
                    key: 'key_str',
                },
                {
                    title: '发布时间',
                    dataIndex: 'public_day',
                    key: 'public_day',
                },
                {
                    title: '文件编号',
                    dataIndex: 'file_code',
                    key: 'file_code',
                },
                {
                    title: '发文类型',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: '发文子类型',
                    dataIndex: 'sub_type',
                    key: 'sub_type',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            selectValue: [],
            isDel: false
        }
    }

    componentDidMount() {
        let { isDel } = true
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            permission.forEach((item, index) => {
                if (item === 'deleteNotice') {
                    isDel = true
                }
            })
        }
        this.setState(preState => ({
            isDel
        }), () => {
            this.get_noticeList()
        })
    }

    get_noticeList = () => {
        let { dataSource, pages, process_state } = this.state
        let data = {
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        noticeList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.notices.length) {
                        res.data.notices.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                notice_name: item.notice_name,
                                key_str: item.key_str,
                                public_day: item.public_day,
                                file_code: item.file_code,
                                type: item.type === 1 ? '公示' : item.type === 2 ? '函件' : item.type === 3 ? '会议纪要' : item.type === 4 ? '决议' : item.type === 5 ? '任免决定' : item.type === 6 ? '通知' : item.type === 7 ? '新闻报道' : '其他',
                                sub_type: item.sub_type === 1 ? '党群工作类' : '企业经营类',
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handleNew = () => {
        let value = '/administrative/notice/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/administrative/notice/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.props.history.push({
                    pathname: '/administrative/notice/NewItem',
                    query: {
                        'tableId': selectValue[0].table_id,
                        'status': '编辑'
                    }
                })
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    handleDel = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法删除多项')
            } else {
                let data = {
                    table_id: selectValue[0].table_id,
                }
                deleteNotice(data).then(
                    (res) => {
                        if (!res.code) {
                            message.success('删除成功')
                            this.get_noticeList()
                        } else {
                            message.error(res.data.data[0] || '删除失败')
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        } else {
            message.error('请选择删除项')
        }
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.enclosure
        }
        fileDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.deName + file.username + '年度总结'
                    link.href = res.data.path
                    link.click()
                } else {
                    message.error(res.data.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_noticeList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_noticeList()
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} seek={true} dontImport={true} edit={true} del={this.state.isDel} delEvent={this.handleDel} editEvent={this.handleEdit} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
            </div>
        )
    }
}


export default withRouter(List)
