import React, { Component } from 'react'
import { Input, Form, message, DatePicker, ConfigProvider, } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createSporadicMaterial, SporadicMaterialDetail, BlankingSelectProject, addMaterialPrice } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "项目辅助用材及其他申请",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'pro_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '选择集采专员', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            },
            {
                FieldTitle: '集采专员', //字段标题
                fieldName: 'cc_user', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '采购部门', //字段标题
                fieldName: 'buy_de', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择采购部门', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    {
                        value: 1,
                        label: '工程部'
                    },
                    {
                        value: 2,
                        label: '集采中心'
                    }
                ]
            },
            {
                FieldTitle: '提货时间', //字段标题
                fieldName: 'take_time', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择提货时间', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '到货时间', //字段标题
                fieldName: 'arrival_time', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择到货时间', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [
                {
                    plateName: '清单明细',
                    list: [],
                    imptable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '',
                                dataIndex: 'key',
                                key: 'key',
                            },
                            {
                                title: '名称',
                                dataIndex: 'mac_name',
                                key: 'mac_name',
                            }, {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                            },
                            {
                                title: '单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '工程量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '单价(元)',
                                dataIndex: 'price',
                                key: 'price',
                                render: (text, record, index) => {
                                    if (this.state.isEdit && this.state.Info.state === 4) {
                                        return (
                                            <Form.Item name={'price' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record, 'price')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else if (text) {
                                        return (
                                            <Form.Item name={'price' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <div>/</div>
                                        )
                                    }
                                }
                            },
                            {
                                title: '合价(元)',
                                dataIndex: 'money',
                                key: 'money',
                                render: (text, record, index) => {
                                    if (text) {
                                        return (
                                            <Form.Item name={'money' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <div>/</div>
                                        )
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/SporadicMaterialImportTable',
                            name:'tableFile',
                            data: {
                                buy_de: ''
                            }
                        }
                    }
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        pro_name: '',
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
        FlowchartKey: 1,
        FlowchartKeys: 2,
        isEdit: false
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SporadicMaterialDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { form, Info } = this.state;
		let project = ''
		Info.state = 1
		if (localStorage.getItem('itemForm')) {
		    project = JSON.parse(localStorage.getItem('itemForm'))
		    form.project_id = project.project_id
		    form.pro_manager = project.pro_manager_id
			Info.TableInfo.forEach((item, index) => {
			    if (item.FieldTitle === '项目名称') {
			        item.value = project.pro_name
			    } else if (item.FieldTitle === '项目编号') {
			        item.value = project.pro_number
			    } else if (item.FieldTitle === '项目经理') {
			        item.value = project.pro_manager
			    }
			})
		}
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_SporadicMaterialDetail = (tableId) => {
        let { Info, form, isEdit } = this.state;
        let permission = JSON.parse(localStorage.getItem("permission"))
        let data = {
            table_id: tableId
        }
        SporadicMaterialDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.pro_id
                        form.cc_user = res.data.cc_user
                        form.pro_manager = res.data.proManager
                        Info.process.edit = res.data.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择集采专员') {
                                item.isShow = true
                            }

                            if (!res.data.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    permission.forEach((item, index) => {
                        if (item === 'addMaterialPrice') {
                            isEdit = true
                            console.log(0)
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.proNumber
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.proManager
                        } else if (item.FieldTitle === '集采专员') {
                            item.value = res.data.ccUserName
                        } else if (item.FieldTitle === '采购部门') {
                            item.value = res.data.buy_de
                        } else if (item.FieldTitle === '提货时间') {
                            item.value = res.data.take_time ? moment(res.data.take_time, 'YYYY-MM-DD') : ''
                        } else if (item.FieldTitle === '到货时间') {
                            item.value = res.data.arrival_time ? moment(res.data.arrival_time, 'YYYY-MM-DD') : ''
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    Info.FirstInfo[0].imptable.uploadUrl.data.buy_de = res.data.buy_de

                    res.data.details.forEach((item, index) => {
                        item.key = index + 1
                    })
                    Info.FirstInfo[0].imptable.dataSource = res.data.details

                    // this.NewForm.current.formRef.current.setFieldsValue({
                    //     buy_de: res.data.buy_de,
                    //     take_time: moment(res.data.take_time, 'YYYY-MM-DD'),
                    //     arrival_time: moment(res.data.arrival_time, 'YYYY-MM-DD'),
                    //     enclosure: res.data.enclosure
                    // })

                    this.setState(preState => ({
                        Info,
                        form,
                        isEdit,
                        FlowchartKeys: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                                project_manager: item.project_manager
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.buy_de = data.buy_de
        form.take_time = data.take_time ? data.take_time.format('YYYY-MM-DD') : ''
        form.arrival_time = data.arrival_time ? data.arrival_time.format('YYYY-MM-DD') : ''
        form.macContents = Info.FirstInfo[0].imptable.dataSource
        form.macContents = JSON.stringify(form.macContents)

        createSporadicMaterial(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择集采专员') {
                columns = [
                    {
                        title: '姓名',
                        dataIndex: 'username',
                        key: 'username',
                    },
                    {
                        title: '性别',
                        dataIndex: 'sex',
                        key: 'sex',
                        render: (text, index) => {
                            if (text === '1') {
                                return (
                                    <span> 男 </span>
                                )
                            } else {
                                return (<span> 女 </span>
                                )
                            }
                        }
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'deName',
                        key: 'deName',
                    },
                    {
                        title: '职位',
                        dataIndex: 'company_job',
                        key: 'company_job',
                    },
                    {
                        title: '手机',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '部门', //字段标题
                        fieldName: 'de_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_InitialValue()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                this.get_BlankingSelectProject()
            }


            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio: true
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    InputChange = (e, record, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].imptable.dataSource.forEach((em, ex) => {
            if (em.id === record.id) {
                em.price = e.target.value
                em.money = record.number * Number(e.target.value)
            }
        })
        this.setState(preState => ({
            Info,
            FlowchartKeys: Math.random(),
        }), () => {
            this.get_addMaterialPrice(record.id, e.target.value)
        })
    }

    get_addMaterialPrice = (id, price) => {
        let { Info } = this.state;
        let data = {
            mac_id: id,
            price: price
        }

        addMaterialPrice(data).then(
            (res) => {
                if (!res.code) {
                    message.success('修改成功')
                } else {
                    message.error(res.data[0] || '价格添加失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelect = (value) => {
        // let { Info, form } = this.state
        // form.project_id = value[0].key
        // form.pro_manager = value[0].project_manager
        // Info.TableInfo.forEach((item, index) => {
        //     if (item.FieldTitle === '项目名称') {
        //         item.value = value[0].pro_name
        //     } else if (item.FieldTitle === '项目编号') {
        //         item.value = value[0].pro_number
        //     } else if (item.FieldTitle === '项目经理') {
        //         item.value = value[0].pro_manager
        //     }
        // })
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            form.pro_manager = value[0].project_manager
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '选择集采专员') {
            form.cc_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '集采专员') {
                    item.value = value[0].username
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleRadio = (value) => {
        let { Info } = this.state
        Info.FirstInfo[0].imptable.uploadUrl.data.buy_de = value
        Info.FirstInfo[0].imptable.dataSource = []
        this.setState(preState => ({
            Info
        })
        )
    }

    // 文件上传改变事件
    updateChange = (info) => {
        let { Info } = this.state
        if (info.file.status === 'done') {
            if (!info.file.response.code) {
                message.success('导入成功')
                Info.FirstInfo[0].imptable.dataSource=[]
                info.file.response.data.forEach((item, index) => {
                    item.key = index + 1
                    Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                })

                this.setState(preState => ({
                    Info
                })
                )
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择集采专员') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择集采专员') {
                this.get_InitialValue()
            }
        })
    }

    handleClear = () => {
        let { Info } = this.state
        Info.FirstInfo[0].imptable.dataSource = []
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKeys, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={FlowchartKeys} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} radioEvent={this.handleRadio} uploadEvent={this.updateChange} auditEvent={this.handleButton} ClearEvent={this.handleClear} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
