import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Operate from '../../../../../components/Operate'
import Search from '../../../../../components/Search'
import Table from '../../../../../components/Table'
import Append from '../../../../../components/addAppend'
import { CenPlantList, CenPlantDetail, createCenPlant } from "../../../../../../utils/purchase"


export default class List extends Component {
    state = {
        searchShow: false,
        modal_Visible: false,
        selectValue: [],
        dataSource: [],
        columns: [
            {
                title: '机械代码',
                dataIndex: 'code',
                key: 'code',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_CenPlantDetail(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '机械名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '购买日期',
                dataIndex: 'buy_day',
                key: 'buy_day',
            },
            {
                title: '机操手',
                dataIndex: 'operUsername',
                key: 'operUsername',
            },
            {
                title: '所在项目',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '油卡编号',
                dataIndex: 'oilCode',
                key: 'oilCode',
            },
            {
                title: '车审日期',
                dataIndex: 'review_day',
                key: 'review_day',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '机械名称', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
        ],
        name: '',
        Info: {
            state: '新增',
            FlowModelName: "机械信息",
            TableInfo: [{
                FieldTitle: '机械代码', //字段标题
                fieldName: 'code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入机械代码', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '联系电话', //字段标题
                fieldName: 'phone', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '机械名称', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入机械名称', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '购买日期', //字段标题
                fieldName: 'buy_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '车审日期', //字段标题
                fieldName: 'review_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '环标申领日期', //字段标题
                fieldName: 'env_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }
            ],
            FirstInfo: []
        },
        pages: {
            limit: 8,
            page: 1,
            total: 0
        },
        form: {},
        FlowchartKey: 1,
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_CenPlantList()
    }

    get_CenPlantList = () => {
        let { dataSource, pages, name, } = this.state
        let data = {
            name: name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        CenPlantList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.plants.length) {
                        res.data.plants.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                code: item.plantCode,
                                phone: item.phone,
                                name: item.plantName,
                                buy_day: item.buy_day,
                                operUsername: item.oper_userName || '/',
                                pro_name: item.proName || '/',
                                oilCode: item.oilCode || '/',
                                review_day: item.review_day,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_CenPlantDetail = (tableId, status) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        CenPlantDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }

                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handleNew = () => {
        const { Info } = this.state;
        Info.state = '新增'

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_CenPlantDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = value.enclosure.length ? value.enclosure : ''
            }
        })

        this.NewForm.current.formRef.current.setFieldsValue({
            code: value.code,
            phone: value.phone,
            name: value.name,
            phone: value.phone,
            name: value.name,
            buy_day: value.buy_day ? moment(value.buy_day, 'YYYY-MM-DD') : '',
            review_day: value.review_day ? moment(value.review_day, 'YYYY-MM-DD') : '',
            env_day: value.env_day ? moment(value.env_day, 'YYYY-MM-DD') : '',
            info: value.info,
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })

        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                code: '',
                phone: '',
                name: '',
                buy_day: '',
                review_day: '',
                env_day: '',
                enclosure: '',
            })
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_CenPlantList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
        }, () => {
            this.get_CenPlantList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_CenPlantList()
        })
    }


    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.code = data.code
        form.phone = data.phone
        form.name = data.name
        form.buy_day = data.buy_day ? data.buy_day.format('YYYY-MM-DD') : ''
        form.review_day = data.review_day ? data.review_day.format('YYYY-MM-DD') : ''
        form.env_day = data.env_day ? data.env_day.format('YYYY-MM-DD') : ''
        form.info = data.info

        createCenPlant(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.modalCancel()
                    this.get_CenPlantList()
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { searchShow, TableInfo, dataSource, columns, pages, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} fileEvent={this.handFile} buttonEvent={this.handButton} />
            </div>
        )
    }
}
