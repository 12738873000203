import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Append from '../../components/addAppend'
import { getAwardList, selectBidding, getAwardDetail, saveAwardBook } from "../../../utils/market"
import InitialValue from '../../components/InitialValue'


export default class List extends Component {
    state = {
        searchShow: false,
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
                width: '300px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer', width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => {
                            this.get_details(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '建设单位',
                dataIndex: 'con_unit',
                key: 'con_unit',
                width: '300px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '中标价格',
                dataIndex: 'award_money',
                key: 'award_money',
            },
            {
                title: '中标时间',
                dataIndex: 'award_time',
                key: 'award_time',
            },
            {
                title: '中标通知书领取',
                dataIndex: 'book_receive',
                key: 'book_receive',
                render: (text, index) => {
                    if (text === 1) {
                        return (
                            <div>
                                有
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                无
                            </div>
                        )
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
            },
        ],
        pro_name: '',
        con_unit: '',
        start_time: '',
        end_time: '',
        start_money: '',
        end_money: '',
        TableInfo: [
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            },
            {
                FieldTitle: '建设单位', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '立项时间', //字段标题
                fieldName: 'time', //字段名称
                fieldType: '日期区间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
                child: [
                    {
                        FieldTitle: '开始时间', //字段标题
                        fieldName: 'start_time', //字段名称
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '结束时间', //字段标题
                        fieldName: 'end_time', //字段名称
                        value: '',
                        width: '50%',
                    },
                ]
            }, {
                FieldTitle: '中标价格', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本区间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
                child: [
                    {
                        FieldTitle: '最小金额', //字段标题
                        fieldName: 'start_money', //字段名称
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '最大金额', //字段标题
                        fieldName: 'end_money', //字段名称
                        value: '',
                        width: '50%',
                    },
                ]
            },

        ],
        Info: {
            state: '新增',
            FlowModelName: "中标通知书",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '建设单位', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '中标价格(元)', //字段标题
                fieldName: 'award_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入中标价格(元)', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '中标时间', //字段标题
                fieldName: 'award_time', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '通知书领取', //字段标题
                fieldName: 'book_receive', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: '有' },
                    { value: 0, label: '无' }
                ]
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'remarks', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }
            ],
            FirstInfo: []
        },
        pages: {
            limit: 8,
            page: 1,
            total: 0
        },
        proModal: false,
        proDataSource: [],
        proColumns: [
            {
                title: '工程编号',
                dataIndex: 'eng_number',
                key: 'eng_number',
            },
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
            {
                title: '建设单位（招标人）',
                dataIndex: 'con_unit',
                key: 'con_unit',
            },
        ],
        proTableInfo: [
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'key', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '98%',
            }
        ],
        key: '',
        proPages: {
            page: 1,
            total: 0,
            limit: 100,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_AwardList()
    }

    get_AwardList = () => {
        let { dataSource, pages, pro_name, con_unit, start_time, end_time, start_money, end_money } = this.state
        let data = {
            pro_name: pro_name,
            con_unit: con_unit,
            start_time: start_time,
            end_time: end_time,
            start_money: start_money,
            end_money: end_money,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        getAwardList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.awards.length) {
                        res.data.awards.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_name: item.pro_name,
                                con_unit: item.con_unit,
                                award_money: item.award_money,
                                award_time: item.award_time,
                                book_receive: item.book_receive,
                                remarks: item.remarks,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_details(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details = (tableId) => {
        this.setState(preState => ({
            modal_Visible: true
        }), () => {
            this.get_AwardDetail(tableId)
        })
    }

    get_AwardDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        getAwardDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    form.bidd_id = res.data.bidd_id
                    Info.state = '详情'

                    Info.TableInfo.forEach((item, index) => {
                        item.disabled = true
                        if (item.FieldTitle === '选择项目') {
                            item.isShow = true
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.bidd.pro_name
                        } else if (item.FieldTitle === '建设单位') {
                            item.value = res.data.bidd.con_unit
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                            if (!res.data.enclosure.length) {
                                item.isShow = true
                            }
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        award_time: res.data.award_time ? moment(res.data.award_time, 'YYYY-MM-DD') : '',
                        award_money: res.data.award_money,
                        book_receive: res.data.book_receive,
                        remarks: res.data.remarks,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        const { Info } = this.state;
        Info.state = '新增'
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
            if (item.FieldTitle === '选择项目') {
                item.isShow = false
            } else if (item.FieldTitle === '附件') {
                item.isShow = false
            }
        })
        this.setState({
            Info,
            modal_Visible: true
        })
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = ''
            } else if (item.FieldTitle === '建设单位') {
                item.value = ''
            } else if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })

        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                award_time: '',
                award_money: '',
                book_receive: '',
                remarks: '',
                enclosure: '',
            })
        })
    }

    handleModal = (value) => {
        console.log('新增', value)
        this.setState({
            modal_Visible: false
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            con_unit: '',
            start_time: '',
            end_time: '',
            start_money: '',
            end_money: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_AwardList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            con_unit: value.con_unit,
            start_time: value.start_time ? value.start_time.format('YYYY-MM-DD') : '',
            end_time: value.end_time ? value.end_time.format('YYYY-MM-DD') : '',
            start_money: value.start_money,
            end_money: value.end_money,
        }, () => {
            this.get_AwardList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_AwardList()
        })
    }

    get_Bidding = () => {
        let { proDataSource, key } = this.state
        let data = {
            key: key
        }
        selectBidding(data).then(
            (res) => {
                if (!res.code) {
                    proDataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                eng_number: item.eng_number,
                                pro_name: item.pro_name,
                                pro_address: item.pro_address,
                                con_unit: item.con_unit,
                            }
                            proDataSource = [...proDataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        proDataSource,
                        proModal: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_Bidding()
        } else {
            this.setState(preState => ({
                proModal: false,
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form } = this.state;
        console.log(value)
        form.bidd_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '建设单位') {
                item.value = value[0].con_unit
            }
        })

        this.setState(preState => ({
            Info,
            form,
            proModal: false,
        })
        )

    }

    handleProCancel = () => {
        this.setState({
            proModal: false,
            key: '',
            pages: {
                limit: 100,
                page: 1,
                total: 0
            },
        })
    }

    searchProQuery = (value) => {
        this.setState({
            key: value.key,
        }, () => {
            this.get_Bidding()
        })
    }

    handleProLimit = () => { }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.award_money = data.award_money
        form.award_time = data.award_time ? data.award_time.format('YYYY-MM-DD') : ''
        form.book_receive = data.book_receive
        form.remarks = data.remarks

        saveAwardBook(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.modalCancel()
                    this.get_AwardList()
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { searchShow, TableInfo, dataSource, columns, pages, modal_Visible, Info, proModal, proDataSource, proPages, proColumns, proTableInfo } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />

                <Append ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} fileEvent={this.handFile} buttonEvent={this.handButton} />

                <InitialValue modal_Visible={proModal} dataSource={proDataSource} pages={proPages} columns={proColumns} TableInfo={proTableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchProQuery} cancelEvent={this.handleProCancel} selectEvent={this.handSelect} limitEvent={this.handleProLimit} />
            </div>
        )
    }
}
