import React, { Component } from 'react'
import moment from 'moment';
import { Form, Select, Input, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import AddConfig from '../../components/AddConfig'
import { createDevelopmentComResearch, DevelopmentComResearchDetail } from "../../../utils/scientific_research"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/paymentApproval.docx',
            templateName: '四川天艺生态园林集团股份有限公司综合/科研付款审批',
            tempData: {
                pay_unit: '',
                con_code: '',
                apply_date: '',
                user_name: '',
                con_name: '',
                con_money: '',
                con_type: '',
                paid_money: '',
                not_money: '',
                class: '',
                unit_name: '',
                invo_type: '',
                money: '',
                replenish_invo: '',
                info: '',
                details: [],
                results: []
            },
            FlowModelName: "综合/科研付款审批",
            TableInfo: [{
                FieldTitle: '日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同类别', //字段标题
                fieldName: 'con_type', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同名称', //字段标题
                fieldName: 'con_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同编号', //字段标题
                fieldName: 'con_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同金额(元)', //字段标题
                fieldName: 'con_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '已累计付款(元)', //字段标题
                fieldName: 'paid_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '剩余未付款(元)', //字段标题
                fieldName: 'not_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '费用类型', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "综合费用" },
                    { value: 2, label: "科研费用" }
                ]
            }, {
                FieldTitle: '付款单位', //字段标题
                fieldName: 'pay_unit', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                    { value: 2, label: "四川天艺优境环境科技有限公司" },
                    { value: 3, label: "四川天艺致远物联网科技有限公司" },
                    { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                ]
            },
            {
                FieldTitle: '是否欠票', //字段标题
                fieldName: 'has_owing', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ]
            },
            {
                FieldTitle: '欠票单位', //字段标题
                fieldName: 'unit_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票类型', //字段标题
                fieldName: 'invo_type', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票金额', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票原因', //字段标题
                fieldName: 'cause', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '补票时间', //字段标题
                fieldName: 'replenish_invo', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [{
                plateName: '综合费用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [{
                        title: '',
                        dataIndex: 'key',
                        key: 'key',
                        width: '80px'
                    },
                    {
                        title: '综合费用类别',
                        dataIndex: 'money_class',
                        key: 'money_class',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                    <Select options={[
                                        { value: 1, label: '办公用品' },
                                        { value: 2, label: '其他费用' }
                                    ]}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                    </Select>
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                    <Select options={[
                                        { value: 1, label: '办公用品' },
                                        { value: 2, label: '其他费用' }
                                    ]}
                                        suffixIcon={<CaretDownOutlined />}
                                        disabled
                                    >
                                    </Select>
                                </Form.Item>)
                            }
                        }

                    },
                    {
                        title: '本次付款金额(元)',
                        dataIndex: 'pay_money',
                        key: 'pay_money',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '公司名称',
                        dataIndex: 'company_name',
                        key: 'company_name',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '开户行',
                        dataIndex: 'bank',
                        key: 'bank',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '银行账号',
                        dataIndex: 'bank_number',
                        key: 'bank_number',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }
                    ]
                },
                isShow: true
            }, {
                plateName: '科研费用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [{
                        title: '',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: '综合费用类别',
                        dataIndex: 'money_class',
                        key: 'money_class',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                    <Select options={[
                                        { value: 1, label: '委托研发费用' },
                                        { value: 2, label: '直接投入费用' }
                                    ]}
                                        suffixIcon={<CaretDownOutlined />}
                                    >
                                    </Select>
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                    <Select options={[
                                        { value: 1, label: '委托研发费用' },
                                        { value: 2, label: '直接投入费用' }
                                    ]}
                                        suffixIcon={<CaretDownOutlined />}
                                        disabled
                                    >
                                    </Select>
                                </Form.Item>)
                            }
                        }

                    },
                    {
                        title: '本次付款金额(元)',
                        dataIndex: 'pay_money',
                        key: 'pay_money',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '公司名称',
                        dataIndex: 'company_name',
                        key: 'company_name',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '开户行',
                        dataIndex: 'bank',
                        key: 'bank',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }, {
                        title: '银行账号',
                        dataIndex: 'bank_number',
                        key: 'bank_number',
                        render: (text, record, index) => {
                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                    <Input />
                                </Form.Item>)
                            } else {
                                return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                    <Input disabled />
                                </Form.Item>)
                            }
                        }

                    }
                    ]
                },
                isShow: true
            },
            {
                plateName: '',
                list: [{
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }]
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DevelopmentComResearchDetail(tableId)
        } else {
            let con_id = sessionStorage.getItem('conId')
            this.get_FormState(con_id)
        }
    }

    get_FormState = (con_id) => {
        const { Info, form } = this.state;
        Info.state = 1
        form.con_id = con_id
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_DevelopmentComResearchDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentComResearchDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        if (res.data.Detail.status === 4) {
                            Info.tempData.pay_unit = res.data.Detail.pay_unit === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.pay_unit === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.pay_unit === 3 ? '四川天艺致远物联网科技有限公司' : res.data.Detail.pay_unit === 3 ? '四川天艺传奇生态茶业有限公司' : ''
                            Info.tempData.con_code = res.data.Detail.con_code || '/'
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.user_name = res.data.Detail.applyUserName
                            Info.tempData.con_name = res.data.Detail.con_name || '/'
                            Info.tempData.con_money = res.data.Detail.con_money || 0
                            Info.tempData.con_type = res.data.Detail.con_type || '/'
                            Info.tempData.paid_money = res.data.Detail.paid_money || 0
                            Info.tempData.not_money = res.data.Detail.not_money || 0
                            Info.tempData.class = res.data.Detail.class === 1 ? '综合费用' : '科研费用'
                            Info.tempData.unit_name = res.data.Detail.has_owing === 1 ? res.data.Detail.unit_name : '/'
                            Info.tempData.invo_type = res.data.Detail.has_owing === 1 ? res.data.Detail.invo_type : '/'
                            Info.tempData.money = res.data.Detail.has_owing === 1 ? res.data.Detail.money : '/'
                            Info.tempData.cause = res.data.Detail.has_owing === 1 ? res.data.Detail.cause : '/'
                            Info.tempData.replenish_invo = res.data.Detail.has_owing === 1 ? res.data.Detail.replenish_invo : '/'
                            Info.tempData.details = []
                            Info.tempData.results = []
                            Info.tempData.info = res.data.Detail.info

                            let details = JSON.parse(res.data.Detail.details)
                            details.forEach((item, index) => {
                                let money_class = ''
                                if (res.data.Detail.class === 1) {
                                    Number(item.money_class) === 1 ? money_class = '办公用品' : money_class = '其他费用'
                                } else if (res.data.Detail.class === 2) {
                                    Number(item.money_class) === 1 ? money_class = '委托研发费用' : money_class = '直接投入费用'
                                }
                                let value = {
                                    money_class: money_class,
                                    pay_money: item.pay_money,
                                    company_name: item.company_name,
                                    bank: item.bank,
                                    bank_number: item.bank_number,
                                };
                                Info.tempData.details = [...Info.tempData.details, value]
                            })

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                        }
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }

                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[2].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    if (res.data.Detail.class === 1) {
                        Info.FirstInfo[0].isShow = false
                        Info.FirstInfo[0].editable.dataSource = []
                        let details = JSON.parse(res.data.Detail.details)
                        details.forEach((item, index) => {
                            const newData = {
                                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                                money_class: item.money_class,
                                pay_money: item.pay_money,
                                company_name: item.company_name,
                                bank: item.bank,
                                bank_number: item.bank_number,
                            };
                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    } else if (res.data.Detail.class === 2) {
                        Info.FirstInfo[1].isShow = false
                        Info.FirstInfo[1].editable.dataSource = []
                        let details = JSON.parse(res.data.Detail.details)
                        details.forEach((item, index) => {
                            const newData = {
                                key: Info.FirstInfo[1].editable.dataSource.length + 1,
                                money_class: item.money_class,
                                pay_money: item.pay_money,
                                company_name: item.company_name,
                                bank: item.bank,
                                bank_number: item.bank_number,
                            };
                            Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                        })
                    }

                    if (res.data.Detail.has_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '欠票单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票原因') {
                                item.isShow = false
                            } else if (item.FieldTitle === '补票时间') {
                                item.isShow = false
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pay_unit: res.data.Detail.pay_unit || '',
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        con_type: res.data.Detail.con_type,
                        con_name: res.data.Detail.con_name,
                        con_code: res.data.Detail.con_code,
                        con_money: res.data.Detail.con_money,
                        paid_money: res.data.Detail.paid_money,
                        not_money: res.data.Detail.not_money,
                        class: res.data.Detail.class,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                        has_owing: res.data.Detail.has_owing,
                        unit_name: res.data.Detail.unit_name,
                        invo_type: res.data.Detail.invo_type,
                        money: res.data.Detail.money,
                        cause: res.data.Detail.cause,
                        replenish_invo: res.data.Detail.replenish_invo ? moment(res.data.Detail.replenish_invo, 'YYYY-MM-DD') : '',
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.pay_unit = data.pay_unit
        form.con_type = data.con_type
        form.con_name = data.con_name
        form.con_code = data.con_code
        form.con_money = data.con_money
        form.paid_money = data.paid_money
        form.not_money = data.not_money
        form.class = data.class
        form.info = data.info
        form.details = []
        form.has_owing = data.has_owing
        form.unit_name = data.unit_name || ''
        form.invo_type = data.invo_type || ''
        form.money = data.money || ''
        form.cause = data.cause || ''
        form.replenish_invo = data.replenish_invo ? data.replenish_invo.format('YYYY-MM-DD') : ''

        if (data.class === 1) {
            if (Info.FirstInfo[0].editable.dataSource.length) {

                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    index++
                    let value = {
                        money_class: data['money_class' + index] ? data['money_class' + index] : '',
                        pay_money: data['pay_money' + index] ? data['pay_money' + index] : '',
                        company_name: data['company_name' + index] ? data['company_name' + index] : '',
                        bank: data['bank' + index] ? data['bank' + index] : '',
                        bank_number: data['bank_number' + index] ? data['bank_number' + index] : '',
                    }

                    form.details = [...form.details, value]
                })


            }
        } else if (data.class === 2) {
            if (Info.FirstInfo[1].editable.dataSource.length) {

                Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                    index++
                    let value = {
                        money_class: data['money_class' + index] ? data['money_class' + index] : '',
                        pay_money: data['pay_money' + index] ? data['pay_money' + index] : '',
                        company_name: data['company_name' + index] ? data['company_name' + index] : '',
                        bank: data['bank' + index] ? data['bank' + index] : '',
                        bank_number: data['bank_number' + index] ? data['bank_number' + index] : '',
                    }

                    form.details = [...form.details, value]
                })


            }
        }

        form.details = JSON.stringify(form.details)

        createDevelopmentComResearch(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         sessionStorage.setItem('conId', '')
                    //         window.history.back(-1)
                    //     })
                    // }
					    setTimeout(() => {
					        sessionStorage.setItem('conId', '')
					        window.history.back(-1)
					    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        if (name === '综合费用明细') {
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                money_class: '',
                pay_money: '',
                company_name: '',
                bank: '',
                bank_number: ''
            };

            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
            Info.FirstInfo[1].editable.dataSource = []
        } else if (name === '科研费用明细') {
            const newData = {
                key: Info.FirstInfo[1].editable.dataSource.length + 1,
                money_class: '',
                pay_money: '',
                company_name: '',
                bank: '',
                bank_number: ''
            };

            Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
            Info.FirstInfo[0].editable.dataSource = []
        }


        this.setState(preState => ({
            Info
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelector = (value) => {
        console.log(value)
        const { Info } = this.state;
        if (value === 1) {
            Info.FirstInfo[0].isShow = false
            Info.FirstInfo[1].isShow = true
        } else {
            Info.FirstInfo[0].isShow = true
            Info.FirstInfo[1].isShow = false
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (value, title) => {
        let { Info } = this.state;
        if (title === '是否欠票') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = false
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = true
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = true
                    }
                })
            }
        }

        this.setState(preState => ({
            Info
        }))
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} fileEvent={this.handFile} selectorEvent={this.handSelector} auditEvent={this.handleButton} radioEvent={this.handleRadio} />
            </div>
        )
    }
}
