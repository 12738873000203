import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { getRevenueAuxiliary, RevenueDetail, getProjectBidding, parentRevenue, saveRevenue } from "../../../utils/market"
import { reviewApplyResult } from "../../../utils/api"
import { selectAvailableSeal } from "../../../utils/public"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: false,
                template: '/incomeContractApprove.docx',
                templateName: '四川天艺生态园林集团股份有限公司合同评审表',
                tempData: {
                    con_type: '',
                    apply_date: '',
                    conName: '',
                    conMoney: '',
                    userName: '',
                    sealName: '',
                    proName: '',
                    owner_unit: '',
                    info: '',
                    results: []
                },
                FlowModelName: "收入合同审批",
                TableInfo: [{
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '100%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" }
                    ]
                }, {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    isShow: true,
                }, {
                    FieldTitle: '日期', //字段标题
                    fieldName: 'date_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同编号', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '是否内部', //字段标题
                    fieldName: 'is_inside', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否内部', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" }
                    ]
                }, {
                    FieldTitle: '合同结算方式', //字段标题
                    fieldName: 'set_method', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同结算方式', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '合同类别', //字段标题
                    fieldName: 'set_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同类别', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '合同性质', //字段标题
                    fieldName: 'set_nature', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同性质', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '关联中标立项', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '中标立项单据', //字段标题
                    fieldName: 'about_project', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    search: true
                }
                ],
                FirstInfo: [{
                    plateName: '合同信息',
                    list: [{
                        FieldTitle: '选择主合同', //字段标题
                        fieldType: '按钮',  //字段类型
                        isShow: true,
                        mandatory: true, //字段是否必填
                    }, {
                        FieldTitle: '主合同编号', //字段标题
                        fieldName: 'parent_id', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    }, {
                        FieldTitle: '主合同名称', //字段标题
                        fieldName: 'parent_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    }, {
                        FieldTitle: '业主合同编号', //字段标题
                        fieldName: 'owner_set_no', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'owner_set_pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入合同名称', //字段是否必填
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '合同总金额(元)', //字段标题
                        fieldName: 'set_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入合同总金额(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    }, {
                        FieldTitle: '工期(天)', //字段标题
                        fieldName: 'duration', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '预计开工时间', //字段标题
                        fieldName: 'plan_time', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '完工时间', //字段标题
                        fieldName: 'completion_time', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '工程预付款', //字段标题
                        fieldName: 'advance_charge', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '质保期', //字段标题
                        fieldName: 'warranty_period', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入质保期', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '养护期', //字段标题
                        fieldName: 'curing_period', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入养护期', //必填要求
                        value: '',
                        width: '50%'
                    },
                    ]
                }, {
                    plateName: '合同条款信息',
                    list: [
                        {
                            FieldTitle: '履约担保', //字段标题
                            fieldName: 'per_bond', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入履约担保', //必填要求
                            value: '',
                            width: '100%'
                        }, {
                            FieldTitle: '付款条款', //字段标题
                            fieldName: 'term_payment', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入付款条款', //必填要求
                            value: '',
                            width: '100%'
                        }, {
                            FieldTitle: '预期违约责任', //字段标题
                            fieldName: 'overdue_liability', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        }, {
                            FieldTitle: '其他重要的违约责任', //字段标题
                            fieldName: 'other_liability', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        }, {
                            FieldTitle: '材料加差调整条款', //字段标题
                            fieldName: 'material_clause', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        }
                    ]
                }, {
                    plateName: '业主单位信息',
                    list: [
                        {
                            FieldTitle: '业主单位名称', //字段标题
                            fieldName: 'owner_unit', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入业主单位名称', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '工程项目地点/面积', //字段标题
                            fieldName: 'pro_address_area', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入工程项目地点/面积', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '联系人', //字段标题
                            fieldName: 'contacts', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入联系人', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'contact_phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入联系电话', //必填要求
                            value: '',
                            width: '50%'
                        }
                    ]
                }, {
                    plateName: '合同说明及附件',
                    list: [
                        {
                            FieldTitle: '相关说明', //字段标题
                            fieldName: 'about_description', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'attachment', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ]
                }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            buttonTitle: '',
            dataSource: [],
            columns: [],
            TableInfo: [],
            pro_name: '',
            owner_unit: '',
            seal_name: '',
            revenue_num: '',
            revenue_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_RevenueAuxiliary()
    }

    get_RevenueAuxiliary = () => {
        let { Info } = this.state
        getRevenueAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同结算方式') {
                            item.selectList = []
                            for (let key in res.data.set_method) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_method[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '合同类别') {
                            item.selectList = []
                            for (let key in res.data.set_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_type[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '合同性质') {
                            item.selectList = []
                            for (let key in res.data.set_nature) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_nature[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_RevenueDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_RevenueDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        RevenueDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.revenue.status === 1 || res.data.revenue.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.revenue.editPermission
                        if (res.data.revenue.is_seal === 1) {
                            form.seal_id = res.data.revenue.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.revenue.sealNames
                                }
                            })
                        }

                        if (res.data.revenue.set_type === 2) {
                            form.parent_id = res.data.revenue.parent_id
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '选择主合同') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '主合同编号') {
                                    item.isShow = false
                                    item.value = res.data.revenue.code
                                }
                                else if (item.FieldTitle === '主合同名称') {
                                    item.isShow = false
                                    item.value = res.data.revenue.parent_name
                                }
                            })
                        }

                    } else {
                        if (res.data.revenue.status === 4) {
                            let con_type = res.data.revenue.set_type === 1 ? '设计合同' : res.data.revenue.set_type === 2 ? '施工合同' : res.data.revenue.set_type === 3 ? '集采合同' : res.data.revenue.set_type === 4 ? '科研施工合同' : res.data.revenue.set_type === 5 ? '科研设计/咨询合同' : res.data.revenue.set_type === 6 ? 'EPC合同' : res.data.revenue.set_type === 7 ? '联营设计合同' : '联营施工合同'
                            Info.tempData.con_type = con_type
                            Info.tempData.number = res.data.revenue.number
                            Info.tempData.apply_date = res.data.revenue.date_day
                            Info.tempData.conName = res.data.revenue.owner_set_pro_name
                            Info.tempData.conMoney = res.data.revenue.set_money
                            Info.tempData.userName = res.data.revenue.applyUserName
                            Info.tempData.sealName = res.data.revenue.sealNames || '/'
                            Info.tempData.proName = res.data.revenue.project.pro_name
                            Info.tempData.owner_unit = res.data.revenue.owner_unit
                            Info.tempData.info = res.data.revenue.about_description
                            Info.tempData.results = []

                            Info.isExport = true

                            res.data.revenue.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }

                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '关联中标立项') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                                if (em.FieldTitle === '附件') {
                                    if (!res.data.revenue.attachment.length) {
                                        em.isShow = true
                                    }
                                }
                            })
                        })

                        if (res.data.revenue.is_seal === 1) {
                            form.seal_id = res.data.revenue.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.revenue.sealNames
                                }
                            })
                        }

                        if (res.data.revenue.set_type === 2) {
                            form.parent_id = res.data.revenue.parent_id
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '主合同编号') {
                                    item.isShow = false
                                    item.value = res.data.revenue.code
                                }
                                else if (item.FieldTitle === '主合同名称') {
                                    item.isShow = false
                                    item.value = res.data.revenue.parent_name
                                }
                            })
                        }
                    }

                    Info.state = res.data.revenue.status
                    Info.process.data = res.data.revenue.reviewAudit
                    Info.process.reviewAudit = res.data.revenue.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.revenue.reviewAudit.ccReading
                    Info.process.audit = res.data.revenue.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.revenue.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '中标立项单据') {
                            item.value = res.data.revenue.about_project
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        form.about_project = res.data.revenue.about_project
                        // form.set_money = res.data.revenue.set_money
                        form.duration = res.data.revenue.duration
                        form.plan_time = res.data.revenue.plan_time
                        form.completion_time = res.data.revenue.completion_time

                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.revenue.project.pro_name
                        } else if (item.FieldTitle === '预计开工时间') {
                            item.value = res.data.revenue.plan_time
                        } else if (item.FieldTitle === '工期(天)') {
                            item.value = res.data.revenue.duration
                        } else if (item.FieldTitle === '完工时间') {
                            item.value = res.data.revenue.completion_time
                        }
                    })

                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.revenue.attachment.length ? res.data.revenue.attachment : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        is_seal: res.data.revenue.is_seal,
                        date_day: res.data.revenue.date_day ? moment(res.data.revenue.date_day, 'YYYY-MM-DD') : '',
                        number: res.data.revenue.number,
                        is_inside: res.data.revenue.is_inside,
                        set_method: res.data.revenue.set_method,
                        set_type: res.data.revenue.set_type,
                        set_nature: res.data.revenue.set_nature,
                        owner_set_no: res.data.revenue.owner_set_no,
                        owner_set_pro_name: res.data.revenue.owner_set_pro_name,
                        set_money: res.data.revenue.set_money,
                        advance_charge: res.data.revenue.advance_charge,
                        warranty_period: res.data.revenue.warranty_period,
                        curing_period: res.data.revenue.curing_period,
                        per_bond: res.data.revenue.per_bond,
                        term_payment: res.data.revenue.term_payment,
                        overdue_liability: res.data.revenue.overdue_liability,
                        other_liability: res.data.revenue.other_liability,
                        material_clause: res.data.revenue.material_clause,
                        owner_unit: res.data.revenue.owner_unit,
                        pro_address_area: res.data.revenue.pro_address_area,
                        contacts: res.data.revenue.contacts,
                        contact_phone: res.data.revenue.contact_phone,
                        about_description: res.data.revenue.about_description,
                        attachment: res.data.revenue.attachment,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectBidding = () => {
        let { dataSource, pages, pro_name, owner_unit } = this.state
        let data = {
            pro_name: pro_name,
            start_money: '',
            end_money: '',
            start_plan: '',
            end_plan: '',
            start_complete: '',
            end_complete: '',
            start_period: '',
            end_period: '',
            owner_unit: owner_unit,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        getProjectBidding(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                award_money: item.award_money,
                                plan_time: item.plan_time,
                                complete_time: item.complete_time,
                                con_period: item.con_period,
                                owner_unit: item.owner_unit,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_contract = () => {
        let { dataSource, pages, revenue_num, revenue_name } = this.state
        let data = {
            pro_id: '',
            revenue_num: revenue_num,
            revenue_name: revenue_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        parentRevenue(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.mainData.length) {
                        res.data.mainData.forEach((item, index) => {
                            let contract = {
                                key: item.table_id,
                                apply_date: item.apply_date,
                                code: item.code,
                                con_name: item.con_name,
                                con_money: item.con_money,
                            }
                            dataSource = [...dataSource, contract]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.number = data.number
        form.is_seal = data.is_seal
        form.date_day = data.date_day ? data.date_day.format('YYYY-MM-DD') : ''
        form.is_inside = data.is_inside
        form.set_method = data.set_method
        form.set_type = data.set_type
        form.set_nature = data.set_nature
        form.owner_set_no = data.owner_set_no
        form.owner_set_pro_name = data.owner_set_pro_name
        form.set_money = data.set_money
        form.advance_charge = data.advance_charge
        form.warranty_period = data.warranty_period
        form.curing_period = data.curing_period
        form.per_bond = data.per_bond
        form.term_payment = data.term_payment
        form.overdue_liability = data.overdue_liability
        form.other_liability = data.other_liability
        form.material_clause = data.material_clause
        form.owner_unit = data.owner_unit
        form.pro_address_area = data.pro_address_area
        form.contacts = data.contacts
        form.contact_phone = data.contact_phone
        form.about_description = data.about_description

        saveRevenue(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '关联中标立项') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '中标金额',
                        dataIndex: 'award_money',
                        key: 'award_money',
                    },
                    {
                        title: '计划开工时间',
                        dataIndex: 'plan_time',
                        key: 'plan_time',
                    },
                    {
                        title: '要求竣工时间',
                        dataIndex: 'complete_time',
                        key: 'complete_time',
                    },
                    {
                        title: '业主单位',
                        dataIndex: 'owner_unit',
                        key: 'owner_unit',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '业主单位', //字段标题
                        fieldName: 'owner_unit', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_ProjectBidding()
            } else if (title === '选择主合同') {
                columns = [
                    {
                        title: '日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '合同编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同总金额(元)',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'code', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_contract()
            } else {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_seal()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
                workAdd: false
            }))
        }
    }

    handleRadio = (value) => {
        let { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = false
                } else if (item.FieldTitle === '印章') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = true
                } else if (item.FieldTitle === '印章') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '关联中标立项') {
            form.about_project = value[0].key
            // form.set_money = value[0].award_money
            form.duration = value[0].con_period
            form.plan_time = value[0].plan_time
            form.completion_time = value[0].complete_time


            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '中标立项单据') {
                    item.value = value[0].key
                }

            })

            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '合同总金额(元)') {
                    item.value = value[0].award_money
                } else if (item.FieldTitle === '预计开工时间') {
                    item.value = value[0].plan_time
                } else if (item.FieldTitle === '工期(天)') {
                    item.value = value[0].con_period
                } else if (item.FieldTitle === '完工时间') {
                    item.value = value[0].complete_time
                }
            })

        } else if (buttonTitle === '选择主合同') {
            form.parent_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '主合同编号') {
                    item.value = value[0].code
                }
                if (item.FieldTitle === '主合同名称') {
                    item.value = value[0].con_name
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 2) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = false
                }
            })
        } else {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '关联中标立项') {
            this.setState({
                pro_name: value.pro_name,
                owner_unit: value.owner_unit,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_ProjectBidding()
            })
        } else if (buttonTitle === '选择主合同') {
            this.setState({
                revenue_num: value.revenue_name,
                revenue_name: value.revenue_num,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_contract()
            })
        } else {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            owner_unit: '',
            seal_name: '',
            revenue_num: '',
            revenue_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[3].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '关联中标立项') {
                this.get_ProjectBidding()
            } else if (buttonTitle === '选择主合同') {
                this.get_contract()
            } else {
                this.get_seal()
            }
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        let { form } = this.state
        if (!form.about_project) {
            message.error('未关联中标立项单据!')
            return
        }
        this.props.history.push({
            pathname: '/market/winning/NewItem',
            query: {
                'tableId': form.about_project,
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}