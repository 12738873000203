import React, { Component } from 'react'
import { Input, Form, Select, message, Modal } from 'antd';
import { CaretDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createAutoProjectDuty, projectTeamAuxiliary, autoProjectTeamDetail,deleteProjectTeam } from "../../../../utils/oa"
import { selectUserName } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: 1,
            FlowModelName: "任命项目经理",
            TableInfo: [{
                FieldTitle: '项目团队单号', //字段标题
                fieldName: 'team_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '自动编号',
                width: '50%'
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'man_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }],
            FirstInfo: [{
                plateName: '项目组成员',
                list: [],
                editable: {
                    dataSource: [],
                    columns: []
                },
            }],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
				edit: false
            }
        },
        modal_Visible: false,
        buttonValue: '',
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        project_id: '',
    }

    config = React.createRef();

    componentDidMount() {
        this.get_projectTeamAuxiliary()
        if (this.props.location.status === 'add') {
            this.get_userInfo()
        } else {
            this.get_autoProjectTeamDetail()
        }
    }

    get_userInfo = () => {
        let project = JSON.parse(this.props.location.query.project)
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目编号') {
                item.value = project.pro_number
            } else if (item.FieldTitle === '项目名称') {
                item.value = project.pro_name
            } else if (item.FieldTitle === '项目经理') {
                item.value = project.manager
            }
        })
        this.setState(preState => ({
            Info,
            project_id: project.pro_id
        }))
    }

    get_autoProjectTeamDetail = () => {
        let project = JSON.parse(this.props.location.query.project)
        const { Info } = this.state;
        let data = {
            project_id: project.pro_id
        }
        autoProjectTeamDetail(data).then(
            (res) => {
                if (!res.code) {
                    Info.FirstInfo[0].editable.dataSource=[]

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目团队编号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManager
                        }
                    })

                    res.data.Detail.teams.forEach((item, index) => {
                        const newData = {
                            key: index + 1,
                            table_id: item.table_id,
                            job_name: item.jobName,
                            duty: item.project_duty,
                            user: item.user,
                            user_name: item.userName,
                            phone: item.userPhone,
                            status: 1,
                        }
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                    })

                    this.setState(preState => ({
                        Info,
                        project_id: project.pro_id
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectTeamAuxiliary = () => {
        const { Info } = this.state;
        projectTeamAuxiliary().then(
            (res) => {
                let selectList = []
                for (let key in res.data.project_job) {
                    let value = {
                        value: key,
                        label: res.data.project_job[key]
                    }
                    selectList = [...selectList, value]
                }
                Info.FirstInfo[0].editable.columns = [
                    {
                        title: '',
                        dataIndex: 'key',
                        key: 'key',
                        width: '5%',
                    },
                    {
                        title: '职位',
                        dataIndex: 'job_name',
                        key: 'job_name',
                        width: '22%',
                        render: (text, record, index) => {
                            if (record.status === 0) {
                                return (
                                    <Form.Item name={'job_name' + record.key} key={index} initialValue={text}>
                                        <Select options={selectList} suffixIcon={<CaretDownOutlined />}>
                                        </Select>
                                    </Form.Item>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }
                    },
                    {
                        title: '姓名',
                        dataIndex: 'user_name',
                        key: 'user_name',
                        width: '20%',
                    },
                    {
                        title: '项目职责',
                        dataIndex: 'duty',
                        key: 'duty',
                        width: '23%',
                        render: (text, record, index) => {
                            if (record.status === 0) {
                                return (
                                    <Form.Item name={'duty' + record.key} key={index} initialValue={text}>
                                        <Input />
                                    </Form.Item>
                                )
                            } else {
                                return (
                                    <div>{text}</div>
                                )
                            }
                        }

                    },
                    {
                        title: '联系方式',
                        dataIndex: 'phone',
                        key: 'phone',
                        width: '20%',
                    },
                    {
                        title: '操作',
                        dataIndex: 'status',
                        key: 'status',
                        width: '10%',
                        render: (text, record, index) => {
                            if (text === 0) {
                                return (
                                    <div className='process-state'>
                                        <div className='rejected' onClick={() => {
                                            this.set_projectDuty(index)
                                        }} style={{ cursor: 'pointer' }}>
                                            保存
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='process-state'>
                                        <div className='rejected' onClick={() => {
                                            this.del_projectTeam(record.table_id)
                                        }} style={{ cursor: 'pointer' }}>
                                            删除
                                        </div>
                                    </div>
                                )
                            }
                        }
                    },
                ]
                this.setState(preState => ({
                    Info
                }))
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        message.success(status === 1 ? '暂存成功' : '提交成功')
    }

    set_projectDuty = (index) => {
        const { Info, project_id } = this.state;
        let value = this.config.current.formRef.current.getFieldsValue()
        let user = Info.FirstInfo[0].editable.dataSource[index].user
        let job_name = value['job_name' + (index + 1)]
        let duty = value['duty' + (index + 1)]
        let data = {
            table_id: '',
            project_id: project_id,
            job_id: job_name,
            duty: duty,
            user: user,
        }

        if (!job_name || !duty) {
            message.error('请完整填写表单!')
            return
        }

        createAutoProjectDuty(data).then(
            (res) => {
                if (!res.code) {
                    this.get_autoProjectTeamDetail()
                    message.success('保存成功')
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    del_projectTeam = (id) => {
        Modal.confirm({
            title: '确认删除该成员吗?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: () => {
                this.set_deleteProjectTeam(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    set_deleteProjectTeam=(id)=>{
        let data = {
            table_id:id
        }
        deleteProjectTeam(data).then(
            (res) => {
                if (!res.code) {
                    this.get_autoProjectTeamDetail()
                    message.success('删除成功')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = (name) => {
        this.get_InitialValue()
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handSelect = (value) => {
        const { Info } = this.state;
        const newData = {
            key: Info.FirstInfo[0].editable.dataSource.length + 1,
            table_id: '',
            job_name: '',
            duty: '',
            user: value[0].key,
            user_name: value[0].username,
            phone: value[0].phone,
            status: 0,
        }
        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]

        this.setState(preState => ({
            Info,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.config} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
