import React, { Component } from 'react'
import { Input, message, Modal, Select } from 'antd';
import { withRouter } from "react-router-dom";
import { CaretDownOutlined } from '@ant-design/icons';
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { userManagerList, updateUserPassword, EditUserPosition } from "../../../utils/public"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            inputModal: false,
            roleModal: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '密码',
                    dataIndex: 'password',
                    key: 'password',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => this.handleModal(true, record.table_id)}>设置</div>
                        )
                    }
                },
                {
                    title: '角色',
                    dataIndex: 'role',
                    key: 'role',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => this.roleEvent(true, record)}>设置</div>
                        )
                    }
                }
            ],
            username: '',
            de_name: '',
            job_name: '',
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '部门名称', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '职位', //字段标题
                    fieldName: 'job_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                }
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            prdForm: {},
            position: {},
        }
    }

    componentDidMount() {
        this.get_userManagerList()
    }

    get_userManagerList = () => {
        let { dataSource, pages, username, de_name, job_name, } = this.state
        let data = {
            username: username,
            de_name: de_name,
            job_name: job_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        userManagerList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.username,
                                deName: item.deName,
                                sex: item.sex,
                                position: item.position,
                                company_job: item.company_job,
                                phone: item.phone,
                                password: ''
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handleNew = () => { }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            username: '',
            de_name: '',
            job_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_userManagerList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            job_name: value.job_name,
        }, () => {
            this.get_userManagerList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_userManagerList()
        })
    }

    handleModal = (status, value) => {
        let { prdForm } = this.state
        if (value) {
            prdForm.user_id = value
        }
        this.setState({
            inputModal: status,
            prdForm
        })
    }

    roleEvent = (status, value) => {
        let { position } = this.state
        if (value) {
            position.table_id = value.table_id
            position.editPosition = value.position ? Number(value.position) : ''
        }
        console.log(position)
        this.setState({
            roleModal: status,
            position
        })
    }

    InputChange = (value) => {
        let { prdForm } = this.state
        prdForm.password = value.target.value
        this.setState({
            prdForm
        })
    }

    updatePassword = () => {
        let { prdForm } = this.state
        if (!prdForm.password) {
            message.error('请输入修改后的密码!')
            return
        }
        updateUserPassword(prdForm).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        inputModal: false,
                    },()=>{
                        this.get_userManagerList()
                    })
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    selectChang = (value) => {
        let { position } = this.state
        position.editPosition = value
        this.setState({
            position
        })
    }

    handleRole = () => {
        let { position } = this.state
        if (!position.editPosition) {
            message.error('请选择员工角色!')
            return
        }
        EditUserPosition(position).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        roleModal: false,
                    },()=>{
                        this.get_userManagerList()
                    })
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { searchShow, inputModal, roleModal, dataSource, columns, TableInfo, position } = this.state
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Modal
                    title="输入密码"
                    centered
                    visible={inputModal}
                    onOk={() => this.updatePassword()}
                    onCancel={() => this.handleModal(false, '')}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    <Input onChange={(e) => this.InputChange(e)} />
                </Modal>

                <Modal
                    title="员工角色"
                    centered
                    visible={roleModal}
                    onOk={() => this.handleRole()}
                    onCancel={() => this.roleEvent(false, '')}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    <Select
                        style={{ width: '324px' }}
                        options={[
                            {
                                value: 1,
                                label: '普通员工'
                            },
                            {
                                value: 2,
                                label: '部门经理(总监)'
                            },
                            {
                                value: 3,
                                label: '副总经理'
                            },
                            {
                                value: 4,
                                label: '总经理'
                            }
                        ]}
                        onChange={(e) => {
                            this.selectChang(e)
                        }}
                        value={position.editPosition}
                        suffixIcon={<CaretDownOutlined />}
                    >
                    </Select>
                </Modal>
            </div>
        )
    }
}

export default withRouter(List)
