import React, { Component } from 'react'
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { revenueMain } from "../../../../utils/market"
import { SporadicMaterialList } from "../../../../utils/project"

import Folder from "../../../../accets/img/folder.png"
import Folders from "../../../../accets/img/folders.png"
import File from "../../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [{
                title: '采购部门',
                dataIndex: 'buy_de',
                key: 'buy_de',
                render: (text, record, index) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_details(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '项目经理',
                dataIndex: 'pro_manager',
                key: 'pro_manager',
            },
            {
                title: '提货时间',
                dataIndex: 'take_time',
                key: 'take_time',
            },
            {
                title: '流程状态',
                dataIndex: 'status',
                key: 'status',
                width: '218px',
                filterIcon: () => (
                    <div>
                        <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                            {
                                this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                            }
                        </span>
                        <CaretDownOutlined style={{ color: '#333333' }} />
                    </div>
                ),
                filterDropdown: () => (
                    <div className='filterDropdown'>
                        {this.state.filterChecked.map((item, index) =>
                            item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                {item.text}
                            </div> : <div key={index} onClick={() => {
                                this.handleScreen(item.value)
                            }}>
                                {item.text}
                            </div>
                        )}
                    </div>
                ),
                render: (text, index) => {
                    if (text === 1) {
                        return (
                            <div className='process-state'>
                                <div className='green'>
                                    暂存中
                                </div>
                            </div>
                        )
                    } else if (text === 2) {
                        return (
                            <div className='process-state'>
                                <div className='audit'>
                                    审批中
                                </div>
                            </div>
                        )
                    } else if (text === 3) {
                        return (
                            <div className='process-state'>
                                <div className='rejected'>
                                    已驳回
                                </div>
                            </div>
                        )
                    } else if (text === 4) {
                        return (
                            <div className='process-state'>
                                <div className='complete'>
                                    已完成
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className='process-state'>
                                <div className='termination'>
                                    已终止
                                </div>
                            </div>
                        )
                    }
                }
            }
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            TableInfo: [
                {
                    FieldTitle: '采购部门', //字段标题
                    fieldName: 'buy_de', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                    selectList: [
                        {
                            value: 1,
                            label: '工程部'
                        },
                        {
                            value: 2,
                            label: '集采中心'
                        }
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            pro_id: '',
            buy_de: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_SporadicMaterialList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SporadicMaterialList = () => {
        let { pro_id, buy_de, dataSource, pages, process_state } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            project_id: pro_id,
            buy_de: buy_de,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SporadicMaterialList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.sporadic.length) {
                        res.data.sporadic.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_manager: item.proManagerName,
                                buy_de: item.buy_de === 1 ? '工程部' : '集采中心',
                                take_time: item.take_time,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/spMaterial/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            buy_de: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_SporadicMaterialList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            buy_de: value.buy_de,
        }, () => {
            this.get_SporadicMaterialList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_SporadicMaterialList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_SporadicMaterialList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_SporadicMaterialList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate dontAdd={true} notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
