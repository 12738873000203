import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Append from '../../components/addAppend'

import { CustomerManagementList, createCustomerManagement, CustomerManagementDetail } from "../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: false,
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '建设单位',
                    dataIndex: 'unit_name',
                    key: 'unit_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CustomerManagementDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '客户分类',
                    dataIndex: 'unit_class',
                    key: 'unit_class',
                },
                {
                    title: '地产/市政',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: '公司地址',
                    dataIndex: 'unit_address',
                    key: 'unit_address',
                },
                {
                    title: '联 系 人',
                    dataIndex: 'contacts',
                    key: 'contacts',
                },
                {
                    title: '联系电话',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '合作项目',
                    dataIndex: 'cooperate',
                    key: 'cooperate',
                },
                {
                    title: '备注',
                    dataIndex: 'job',
                    key: 'job',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '建设单位', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '客户分类', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            Info: {
                state: '新增',
                FlowModelName: "客户信息管理",
                TableInfo: [{
                    FieldTitle: '建设单位', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '客户分类', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "地产" },
                        { value: 2, label: "市政" },
                        { value: 3, label: "科研" },
                        { value: 4, label: "设计" },
                        { value: 5, label: "其他" },
                    ]
                }, {
                    FieldTitle: '地产客户分类', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true,
                    selectList: [
                        { value: 1, label: "商业" },
                        { value: 2, label: "文旅" },
                        { value: 3, label: "康养" },
                        { value: 4, label: "住宅" },
                    ]
                },
                {
                    FieldTitle: '市政客户分类', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true,
                    selectList: [
                        { value: 1, label: "市政" },
                        { value: 2, label: "文旅" },
                    ]
                }, {
                    FieldTitle: '公司地址', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '联系人', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '联系电话', //字段标题
                    fieldName: 'phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '邮编', //字段标题
                    fieldName: 'degree', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'job', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合作项目', //字段标题
                    fieldName: 'cooperate', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合同金额(元)', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '合同时间', //字段标题
                    fieldName: 'con_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '付款方式', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '商票比例', //字段标题
                    fieldName: 'email', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '抵房情况', //字段标题
                    fieldName: 'situation', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '保证金金额', //字段标题
                    fieldName: 'speciality', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '质保期', //字段标题
                    fieldName: 'warranty', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            },
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            classType: 1,
            unit_name: '',
            unit_class: '',
            type: '',
            unit_address: '',
            situation: '',
            form: {},
            selectValue: {},
            FlowchartKey: 1,
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_CustomerManagementList()
    }


    get_CustomerManagementList = () => {
        let { classType, unit_name, unit_class, type, unit_address, situation, dataSource, pages, process_state } = this.state

        let data = {
            data_class: classType,
            unit_name: unit_name,
            unit_class: unit_class,
            type: type,
            unit_address:unit_address,
            situation: situation,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        CustomerManagementList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.customer.length) {
                        res.data.customer.forEach((item, index) => {
                            let unitClass = ''
                            let Type = ''
                            let contact = ''
                            if (classType === 1) {
                                item.unit_class === 1 ? unitClass = '地产' : item.unit_class === 2 ? unitClass = '市政' : item.unit_class === 3 ? unitClass = '科研' : item.unit_class === 4 ? unitClass = '设计' : unitClass = '其他'
                                if (item.unit_class === 1) {
                                    item.type === 1 ? Type = '商业' : item.type === 2 ? Type = '文旅' : item.type === 3 ? Type = '康养' : item.type === 4 ? Type = '住宅' : Type = '/'
                                } else if (item.unit_class === 2) {
                                    item.type === 1 ? Type = '市政' : item.type === 2 ? Type = '文旅' : Type = '/'
                                } else {
                                    Type = '/'
                                }
                            } else if (classType === 2) {
                                item.unit_class === 1 ? unitClass = '私有企业' : item.unit_class === 2 ? unitClass = '国有企业' : item.unit_class === 3 ? unitClass = '外资企业' : item.unit_class === 4 ? unitClass = '合资企业' : item.unit_class === 5 ? unitClass = '大专院校' : item.unit_class === 6 ? unitClass = '科研机构' : item.unit_class === 7 ? unitClass = '政府部门' : unitClass = '事业单位'
                                item.type === 1 ? Type = '园林绿化' : item.type === 2 ? Type = '园林植物' : item.type === 3 ? Type = '立体绿化' : item.type === 4 ? Type = 'BIM' : item.type === 5 ? Type = '土壤修复' : item.type === 6 ? Type = '水污染治理' : Type = '其他'
                                let situation = JSON.parse(item.situation)
                                contact = situation[0].contact
                            } else if (classType === 3) {
                                item.unit_class === 1 ? unitClass = '男' : unitClass = '女'

                            } else if (classType === 4) {
                                unitClass = item.unit_class
                                let situation = JSON.parse(item.situation)
                                contact = situation[0].contact
                            }
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                unit_name: item.unit_name,
                                unit_address: item.unit_address,
                                contacts: item.contacts,
                                job: item.job,
                                degree: item.degree,
                                situation: item.situation,
                                con_money: item.con_money,
                                unit_class: unitClass,
                                type: Type,
                                con_date: item.con_date,
                                phone: item.phone,
                                speciality: item.speciality,
                                warranty: item.warranty,
                                cooperate: item.cooperate,
                                pay_model: item.pay_model,
                                email: item.email,
                                contact: contact,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: res.data.pages
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_CustomerManagementDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_CustomerManagementDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        CustomerManagementDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info, classType } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true

                if (!value.enclosure.length) {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false

                if (item.FieldTitle === '附件') {
                    item.value = value.enclosure
                }
            })
        }


        if (classType === 1) {
            if (value.unit_class === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '地产客户分类') {
                        item.isShow = false
                    }
                })
            } else if (value.unit_class === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '市政客户分类') {
                        item.isShow = false
                    }
                })
            }

            this.NewForm.current.formRef.current.setFieldsValue({
                situation: value.situation,
            })
        } else if (classType === 2) {
            let situation = JSON.parse(value.situation)
            this.NewForm.current.formRef.current.setFieldsValue({
                contact: situation[0].contact,
                duty: situation[0].duty,
                facsimile: situation[0].facsimile,
                landline: situation[0].landline,
                email_address: situation[0].email_address,
            })
        } else if (classType === 3) {
            this.NewForm.current.formRef.current.setFieldsValue({
                situation: value.situation,
            })
        } else if (classType === 4) {
            let situation = JSON.parse(value.situation)
            this.NewForm.current.formRef.current.setFieldsValue({
                contact: situation[0].contact,
                duty: situation[0].duty,
                facsimile: situation[0].facsimile,
                landline: situation[0].landline,
                email_address: situation[0].email_address,
            })
        }


        this.NewForm.current.formRef.current.setFieldsValue({
            unit_name: value.unit_name,
            unit_address: value.unit_address,
            contacts: value.contacts,
            job: value.job,
            degree: value.degree,
            con_money: value.con_money || '',
            unit_class: value.unit_class || '',
            type: value.type || '',
            con_date: value.con_date ? moment(value.con_date, 'YYYY-MM-DD HH:mm:ss') : '',
            phone: value.phone || '',
            speciality: value.speciality || '',
            warranty: value.warranty || '',
            cooperate: value.cooperate || '',
            pay_model: value.pay_model || '',
            email: value.email || '',
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true,
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            unit_name: '',
            unit_class: '',
            type: '',
            unit_address: '',
            situation: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_CustomerManagementList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            unit_name: value.unit_name,
            unit_class: value.unit_class,
            type: value.type,
            unit_address: value.unit_address,
            situation: value.situation,
        }, () => {
            this.get_CustomerManagementList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_CustomerManagementList()
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handPresent = (data, status) => {
        const { form, classType } = this.state;
        form.data_class = classType
        form.unit_name = data.unit_name
        form.unit_address = data.unit_address
        form.contacts = data.contacts
        form.job = data.job
        form.degree = data.degree
        if (classType === 1 || classType === 3) {
            form.situation = data.situation
        } else {
            form.situation = []
            let value = {
                contact: data.contact,
                duty: data.duty,
                facsimile: data.facsimile,
                landline: data.landline,
                email_address: data.email_address,
            }
            form.situation = [...form.situation, value]
            form.situation = JSON.stringify(form.situation)
        }
        form.con_money = data.con_money || ''
        form.unit_class = data.unit_class || ''
        form.type = data.type || ''
        form.con_date = data.con_date ? data.con_date.format('YYYY-MM-DD') : ''
        form.phone = data.phone || ''
        form.speciality = data.speciality || ''
        form.warranty = data.warranty || ''
        form.cooperate = data.cooperate || ''
        form.pay_model = data.pay_model || ''
        form.email = data.email || ''

        createCustomerManagement(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_CustomerManagementList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                unit_name: '',
                unit_address: '',
                contacts: '',
                job: '',
                degree: '',
                situation: '',
                con_money: '',
                unit_class: '',
                type: '',
                con_date: '',
                phone: '',
                speciality: '',
                warranty: '',
                cooperate: '',
                pay_model: '',
                email: '',
                enclosure: '',
                contact: '',
                duty: '',
                facsimile: '',
                landline: '',
                email_address: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_tabIndex = (index) => {
        let { columns, TableInfo, Info } = this.state
        if (index === 1) {
            columns = [
                {
                    title: '建设单位(招标人)',
                    dataIndex: 'unit_name',
                    key: 'unit_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CustomerManagementDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '客户分类',
                    dataIndex: 'unit_class',
                    key: 'unit_class',
                },
                {
                    title: '地产/市政客户分类',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: '公司地址',
                    dataIndex: 'unit_address',
                    key: 'unit_address',
                },
                {
                    title: '联系人',
                    dataIndex: 'contacts',
                    key: 'contacts',
                },
                {
                    title: '联系电话',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '合作项目',
                    dataIndex: 'cooperate',
                    key: 'cooperate',
                },
                {
                    title: '备注',
                    dataIndex: 'job',
                    key: 'job',
                },
            ]
            TableInfo = [
                {
                    FieldTitle: '建设单位(招标人)', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '客户分类', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ]
            Info = {
                state: '新增',
                FlowModelName: "客户信息管理",
                TableInfo: [{
                    FieldTitle: '建设单位', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '客户分类', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "地产" },
                        { value: 2, label: "市政" },
                        { value: 3, label: "科研" },
                        { value: 4, label: "设计" },
                        { value: 5, label: "其他" },
                    ]
                },
                {
                    FieldTitle: '地产客户分类', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: false,
                    selectList: [
                        { value: 1, label: "商业" },
                        { value: 2, label: "文旅" },
                        { value: 3, label: "康养" },
                        { value: 4, label: "住宅" },
                    ]
                },
                {
                    FieldTitle: '市政客户分类', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true,
                    selectList: [
                        { value: 1, label: "市政" },
                        { value: 2, label: "文旅" },
                    ]
                }, {
                    FieldTitle: '公司地址', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '联系人', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '联系电话', //字段标题
                    fieldName: 'phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '邮编', //字段标题
                    fieldName: 'degree', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'job', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合作项目', //字段标题
                    fieldName: 'cooperate', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合同金额(元)', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '合同时间', //字段标题
                    fieldName: 'con_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '付款方式', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '商票比例', //字段标题
                    fieldName: 'email', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '抵房情况', //字段标题
                    fieldName: 'situation', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '保证金金额', //字段标题
                    fieldName: 'speciality', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '质保期', //字段标题
                    fieldName: 'warranty', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            }
        } else if (index === 2) {
            columns = [
                {
                    title: '单位名称',
                    dataIndex: 'unit_name',
                    key: 'unit_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CustomerManagementDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '单位性质',
                    dataIndex: 'unit_class',
                    key: 'unit_class',
                },
                {
                    title: '合作领域',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: '地区',
                    dataIndex: 'unit_address',
                    key: 'unit_address',
                },
                {
                    title: '联系人',
                    dataIndex: 'contact',
                    key: 'contact',
                },
            ]
            TableInfo = [
                {
                    FieldTitle: '单位名称', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '单位性质', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ]
            Info = {
                state: '新增',
                FlowModelName: "合作单位",
                TableInfo: [{
                    FieldTitle: '单位名称', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '单位性质', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "私有企业" },
                        { value: 2, label: "国有企业" },
                        { value: 3, label: "外资企业" },
                        { value: 4, label: "合资企业" },
                        { value: 5, label: "大专院校" },
                        { value: 6, label: "科研机构" },
                        { value: 7, label: "政府部门" },
                        { value: 8, label: "事业单位" },
                    ]
                }, {
                    FieldTitle: '地区', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '合作领域', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "园林绿化" },
                        { value: 2, label: "园林植物" },
                        { value: 3, label: "立体绿化" },
                        { value: 4, label: "BIM" },
                        { value: 5, label: "土壤修复" },
                        { value: 6, label: "水污染治理" },
                        { value: 7, label: "其他" },
                    ]
                }, {
                    FieldTitle: '邮编', //字段标题
                    fieldName: 'degree', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '网址', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '单位地址', //字段标题
                    fieldName: 'job', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '联系人', //字段标题
                    fieldName: 'contact', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '职务', //字段标题
                    fieldName: 'duty', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '传真', //字段标题
                    fieldName: 'facsimile', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '固定电话', //字段标题
                    fieldName: 'landline', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '电子邮箱', //字段标题
                    fieldName: 'email_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            }
        } else if (index === 3) {
            columns = [
                {
                    title: '姓名',
                    dataIndex: 'unit_name',
                    key: 'unit_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CustomerManagementDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '性别',
                    dataIndex: 'unit_class',
                    key: 'unit_class',
                },
                {
                    title: '工作单位',
                    dataIndex: 'unit_address',
                    key: 'unit_address',
                },
                {
                    title: '职务',
                    dataIndex: 'job',
                    key: 'job',
                },
                {
                    title: '技术职称',
                    dataIndex: 'pay_model',
                    key: 'pay_model',
                },
                {
                    title: '专业领域',
                    dataIndex: 'speciality',
                    key: 'speciality',
                },
            ]
            TableInfo = [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '工作单位', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ]
            Info = {
                state: '新增',
                FlowModelName: "合作专家",
                TableInfo: [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '性别', //字段标题
                    fieldName: 'unit_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "男" },
                        { value: 2, label: "女" },
                    ]
                }, {
                    FieldTitle: '出生日期', //字段标题
                    fieldName: 'con_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '工作单位', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '通讯地址', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '职务', //字段标题
                    fieldName: 'job', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '技术职称', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '最高学历', //字段标题
                    fieldName: 'degree', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '授予单位', //字段标题
                    fieldName: 'cooperate', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '联系电话', //字段标题
                    fieldName: 'phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '电子邮箱', //字段标题
                    fieldName: 'email', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '专业领域', //字段标题
                    fieldName: 'speciality', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '个人简介', //字段标题
                    fieldName: 'warranty', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '科研情况', //字段标题
                    fieldName: 'situation', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            }
        } else if (index === 4) {
            columns = [
                {
                    title: '单位名称',
                    dataIndex: 'unit_name',
                    key: 'unit_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CustomerManagementDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '主营行业',
                    dataIndex: 'contacts',
                    key: 'contacts',
                },
                {
                    title: '合作区域',
                    dataIndex: 'job',
                    key: 'job',
                },
                {
                    title: '合作项目',
                    dataIndex: 'cooperate',
                    key: 'cooperate',
                },
                {
                    title: '联系人',
                    dataIndex: 'contact',
                    key: 'contact',
                }
            ]
            TableInfo = [
                {
                    FieldTitle: '单位名称', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ]
            Info = {
                state: '新增',
                FlowModelName: "中介机构",
                TableInfo: [{
                    FieldTitle: '单位名称', //字段标题
                    fieldName: 'unit_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '通讯地址', //字段标题
                    fieldName: 'unit_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '主营行业', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '合作区域', //字段标题
                    fieldName: 'job', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '网址', //字段标题
                    fieldName: 'email', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合作项目', //字段标题
                    fieldName: 'cooperate', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '开户银行', //字段标题
                    fieldName: 'degree', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '银行账户', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '联系人', //字段标题
                    fieldName: 'contact', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '职务', //字段标题
                    fieldName: 'duty', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '传真', //字段标题
                    fieldName: 'facsimile', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '固定电话', //字段标题
                    fieldName: 'landline', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '电子邮箱', //字段标题
                    fieldName: 'email_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            }
        }

        this.setState(preState => ({
            classType: index,
            columns,
            TableInfo,
            Info,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_CustomerManagementList()
        })
    }

    handleSelect = (value) => {
        const { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '地产客户分类') {
                    item.isShow = false
                } else if (item.FieldTitle === '市政客户分类') {
                    item.isShow = true
                }
            })
        } else if (value === 2) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '地产客户分类') {
                    item.isShow = true
                } else if (item.FieldTitle === '市政客户分类') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '地产客户分类') {
                    item.isShow = true
                } else if (item.FieldTitle === '市政客户分类') {
                    item.isShow = true
                }
            })
        }
        this.NewForm.current.formRef.current.setFieldsValue({
            type:'',
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, pages, TableInfo, classType, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>

                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                    </div>

                    <div className='supplier_right designes'>
                        <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>客户信息</div>
                        <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>合作单位</div>
                        <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(3)
                        }}>合作专家</div>
                        <div className={classType === 4 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(4)
                        }}>中介机构</div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                }

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} selectorEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(List)
