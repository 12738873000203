import React, { Component } from 'react'
import axios from 'axios'
import Icon from '@ant-design/icons';
import { Tree, message } from 'antd';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import { KnowledgeAchievementsList, KnowledgeAchievementsDetail, createKnowledgeAchievements, knowledgeAchievementsAuxiliaryData, deleteKnowledgeAchievement } from "../../../../utils/scientific_research"

import Folder from "../../../../accets/img/folder.png"
import Folders from "../../../../accets/img/folders.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '成果名称',
                    dataIndex: 'ach_name',
                    key: 'ach_name',
                    width: '50%',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_KnowledgeAchievementsDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '成果领域',
                    dataIndex: 'ach_area',
                    key: 'ach_area',
                },
                {
                    title: '成果状态',
                    dataIndex: 'ach_status',
                    key: 'ach_status',
                },
                // {
                //     title: '附件',
                //     dataIndex: 'enclosure',
                //     key: 'enclosure',
                //     render: (text, record, index) => {
                //         return (
                //             <div className='download' onClick={() => {
                //                 this.get_download(record)
                //             }}>
                //                 下载
                //             </div>
                //         )
                //     }
                // },
            ],
            ach_name: '',
            ach_class: 1,
            inventor: '',
            username: '',
            start_apply_time: '',
            end_apply_time: '',
            start_give_time: '',
            end_give_time: '',
            TableInfo: [
                {
                    FieldTitle: '成果名称', //字段标题
                    fieldName: 'ach_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '完成作者', //字段标题
                    fieldName: 'inventor', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '完成单位', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '发表日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [
                {
                    title: '四川天艺生态园林集团股份有限公司',
                    key: 0,
                    icon: <FolderIcon />,
                    children: []
                }
            ],
            expandedKeys: [0],
            selectedKeys: [1],
            Info: {
                state: '新增',
                FlowModelName: "成果信息登记表",
                TableInfo: [],
                FirstInfo: [
                    {
                        plateName: '成果概述',
                        list: [
                            {
                                FieldTitle: '成果名称', //字段标题
                                fieldName: 'ach_name', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '成果领域', //字段标题
                                fieldName: 'ach_area', //字段名称
                                fieldType: '选择器',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                                selectList: []
                            },
                            {
                                FieldTitle: '成果类型', //字段标题
                                fieldName: 'ach_class', //字段名称
                                fieldType: '选择器',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                                selectList: []
                            },
                            {
                                FieldTitle: '成果状态', //字段标题
                                fieldName: 'ach_status', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                        ]
                    },
                    {
                        plateName: '专利类',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '代理机构', //字段标题
                                fieldName: 'agency', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '代理人', //字段标题
                                fieldName: 'age_user', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '代理人电话', //字段标题
                                fieldName: 'age_phone', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '申请号', //字段标题
                                fieldName: 'apply_number', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '专利号', //字段标题
                                fieldName: 'ach_code', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '专利权人', //字段标题
                                fieldName: 'username', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '申请日期', //字段标题
                                fieldName: 'apply_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '授权日期', //字段标题
                                fieldName: 'give_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '发明人', //字段标题
                                fieldName: 'inventor', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '到期时间', //字段标题
                                fieldName: 'exp_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                        ]
                    },
                    {
                        plateName: '论文专著类',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '出版机构', //字段标题
                                fieldName: 'ach_code', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '完成单位', //字段标题
                                fieldName: 'username', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '完成作者', //字段标题
                                fieldName: 'inventor', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '发表日期', //字段标题
                                fieldName: 'apply_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                        ]
                    },
                    {
                        plateName: '标准类',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '标准编号', //字段标题
                                fieldName: 'ach_code', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '类型', //字段标题
                                fieldName: 'username', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '主编人员', //字段标题
                                fieldName: 'inventor', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '实施时间', //字段标题
                                fieldName: 'apply_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },

                        ]
                    },
                    {
                        plateName: '软著类',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '登记号', //字段标题
                                fieldName: 'ach_code', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '著作权人', //字段标题
                                fieldName: 'username', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '完成日期', //字段标题
                                fieldName: 'apply_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '发表日期', //字段标题
                                fieldName: 'give_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                        ]
                    },
                    {
                        plateName: '商标类',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '核定使用商品/服务项目', //字段标题
                                fieldName: 'ach_code', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '注册人', //字段标题
                                fieldName: 'username', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '注册日期', //字段标题
                                fieldName: 'apply_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '有效期至', //字段标题
                                fieldName: 'give_time', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                        ]
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'enclosure', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%',
                            },
                        ]
                    }
                ]
            },
            selectValue: [],
            form: {},
            TabletKey: 1,
            FlowchartKey: 2
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_knowledgeAchievementsAuxiliaryData()
    }

    get_knowledgeAchievementsAuxiliaryData = () => {
        let { Info, treeData } = this.state
        knowledgeAchievementsAuxiliaryData().then(
            (res) => {
                if (!res.code) {
                    treeData = [
                        {
                            title: '四川天艺生态园林集团股份有限公司',
                            key: 0,
                            icon: <FolderIcon />,
                            children: []
                        }
                    ]
                    for (let key in res.data.ach_class) {
                        let value = {
                            title: res.data.ach_class[key],
                            key: Number(key),
                            icon: <FoldersIcon />,
                        }
                        treeData[0].children = [...treeData[0].children, value]
                    }

                    Info.FirstInfo.forEach((item, index) => {
                        item.list.forEach((em, ex) => {
                            if (em.FieldTitle === '成果类型') {
                                em.selectList = []
                                for (let key in res.data.ach_class) {
                                    let value = {
                                        value: Number(key),
                                        label: res.data.ach_class[key]
                                    }
                                    em.selectList = [...em.selectList, value]
                                }
                            } else if (em.FieldTitle === '成果领域') {
                                em.selectList = []
                                for (let key in res.data.ach_area) {
                                    let value = {
                                        value: Number(key),
                                        label: res.data.ach_area[key]
                                    }
                                    em.selectList = [...em.selectList, value]
                                }
                            }
                        })
                    })

                    this.setState(preState => ({
                        Info,
                        treeData
                    }), () => {
                        this.get_KnowledgeAchievementsList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_KnowledgeAchievementsList = () => {
        let { ach_class, ach_name, inventor, username, start_apply_time, end_apply_time, start_give_time, end_give_time, dataSource, pages } = this.state
        if (!ach_class) {
            message.error('请选择成果类型')
            return
        }

        let data = {
            ach_class: ach_class,
            ach_name: ach_name,
            ach_status: '',
            inventor: inventor,
            username: username,
            start_apply_time: start_apply_time,
            end_apply_time: end_apply_time,
            start_give_time: start_give_time,
            end_give_time: end_give_time,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        KnowledgeAchievementsList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.technology.length) {
                        res.data.technology.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                ach_name: item.ach_name,
                                ach_area: item.ach_area === 1 ? '立体绿化' : item.ach_area === 2 ? '园林绿化' : item.ach_area === 3 ? '植物培育' : item.ach_area === 4 ? '水生态治理' : item.ach_area === 5 ? '土壤修复' : item.ach_area === 6 ? 'BIM' : '茶叶',
                                ach_status: item.ach_status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info } = this.state
        Info.state = '新增'
        Info.FirstInfo.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_KnowledgeAchievementsDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    handleDel = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法删除多项')
            } else {
                this.delete_KnowledgeAchievement(selectValue[0].table_id)
            }
        } else {
            message.error('请选择删除项')
        }
    }

    get_KnowledgeAchievementsDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        KnowledgeAchievementsDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    delete_KnowledgeAchievement = (tableId) => {
        let data = {
            table_id: tableId
        }
        deleteKnowledgeAchievement(data).then(
            (res) => {
                if (!res.code) {
                    message.success('删除成功')
                    this.setState({
                        TabletKey: Math.random(),
                        selectValue:[]
                    }, () => {
                        this.get_KnowledgeAchievementsList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.FirstInfo.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    em.disabled = true

                    if (!value.enclosure.length) {
                        if (em.FieldTitle === '附件') {
                            em.isShow = true
                        }
                    } else {
                        if (em.FieldTitle === '附件') {
                            em.isShow = false
                            em.value = value.enclosure
                        }
                    }
                })
            })
        } else {
            Info.FirstInfo.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    em.disabled = false
                    if (em.FieldTitle === '附件') {
                        em.isShow = false
                        em.value = value.enclosure
                    }
                })
            })
        }

        if (value.ach_class === 1 || value.ach_class === 2 || value.ach_class === 3) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 2 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value.ach_class === 4) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 4 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value.ach_class === 5 || value.ach_class === 6 || value.ach_class === 7) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 1 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value.ach_class === 8 || value.ach_class === 10 || value.ach_class === 11 || value.ach_class === 12) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 3 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value.ach_class === 9) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 5 || index === 6) {
                    item.isShow = false
                }
            })
        }

        this.NewForm.current.formRef.current.setFieldsValue({
            ach_name: value.ach_name,
            ach_area: value.ach_area,
            ach_class: value.ach_class,
            ach_status: value.ach_status,
            ach_code: value.ach_code,
            apply_number: value.apply_number,
            username: value.username,
            agency: value.agency,
            age_user: value.age_user,
            age_phone: value.age_phone,
            apply_time: value.apply_time ? moment(value.apply_time, 'YYYY-MM-DD HH:mm:ss') : '',
            give_time: value.give_time ? moment(value.give_time, 'YYYY-MM-DD HH:mm:ss') : '',
            exp_time: value.exp_time ? moment(value.exp_time, 'YYYY-MM-DD HH:mm:ss') : '',
            inventor: value.inventor,
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        let { ach_class, selectValue } = this.state
        let table_id = []
        if (selectValue.length > 0) {
            selectValue.forEach((item, index) => {
                table_id = [...table_id, item.table_id]
            })
            table_id = JSON.stringify(table_id)
        } else {
            table_id = ''
        }
        let data = {
            ach_class: ach_class,
            data_ids: table_id
        }

        axios.get('/api/exportKnowledgeAchievements', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '成果信息登记表.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            inventor: '',
            username: '',
            start_apply_time: '',
            end_apply_time: '',
            start_give_time: '',
            end_give_time: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_KnowledgeAchievementsList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            ach_name: value.ach_name,
            inventor: value.inventor,
            username: value.username,
            start_apply_time: value.start_apply_time ? value.start_apply_time.format('YYYY-MM-DD') : '',
            end_apply_time: value.end_apply_time ? value.end_apply_time.format('YYYY-MM-DD') : '',
            start_give_time: value.start_give_time ? value.start_give_time.format('YYYY-MM-DD') : '',
            end_give_time: value.end_give_time ? value.end_give_time.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_KnowledgeAchievementsList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_KnowledgeAchievementsList()
        })
    }

    onSelect = (value) => {
        let { TableInfo, ach_class } = this.state
        ach_class = value[0]
        if (ach_class === 1 || ach_class === 2 || ach_class === 3 || ach_class === 5 || ach_class === 6 || ach_class === 7) {
            TableInfo = [
                {
                    FieldTitle: '成果名称', //字段标题
                    fieldName: 'ach_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '完成作者', //字段标题
                    fieldName: 'inventor', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '完成单位', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '发表日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ]
        } else if (ach_class === 4) {
            TableInfo = [
                {
                    FieldTitle: '成果名称', //字段标题
                    fieldName: 'ach_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '著作权人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '发表日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ]
        } else if (ach_class === 8 || ach_class === 10 || ach_class === 11 || ach_class === 12) {
            TableInfo = [
                {
                    FieldTitle: '成果名称', //字段标题
                    fieldName: 'ach_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '主编人员', //字段标题
                    fieldName: 'inventor', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '实施日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_apply_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ]
        } else {
            TableInfo = [
                {
                    FieldTitle: '成果名称', //字段标题
                    fieldName: 'ach_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ]
        }
        this.setState(preState => ({
            selectedKeys: value,
            ach_class,
            TableInfo,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            TabletKey: Math.random(),
            selectValue: []
        }), () => {
            this.get_KnowledgeAchievementsList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.ach_name = data.ach_name
        form.ach_area = data.ach_area
        form.ach_class = data.ach_class
        form.ach_status = data.ach_status
        form.ach_code = data.ach_code
        form.apply_number = data.apply_number
        form.username = data.username
        form.agency = data.agency
        form.age_user = data.age_user
        form.age_phone = data.age_phone
        form.apply_time = data.apply_time ? data.apply_time.format('YYYY-MM-DD') : ''
        form.give_time = data.give_time ? data.give_time.format('YYYY-MM-DD') : ''
        form.exp_time = data.exp_time ? data.exp_time.format('YYYY-MM-DD') : ''
        form.inventor = data.inventor

        createKnowledgeAchievements(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_KnowledgeAchievementsList()
                } else {
                    message.error(res.data[0] || '保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.FirstInfo.forEach((item, index) => {
            item.isShow = true
            item.list.forEach((em, ex) => {
                em.disabled = false
                if (em.FieldTitle === '附件') {
                    em.value = ''
                }
            })
            if (index === 0 || index === 6) {
                item.isShow = false
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                ach_name: '',
                ach_area: '',
                ach_class: '',
                ach_status: '',
                ach_code: '',
                apply_number: '',
                username: '',
                apply_time: '',
                give_time: '',
                inventor: '',
                enclosure: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        console.log(storeList, fileName, fileValue)
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[6].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    handleSelect = (value) => {
        const { Info } = this.state;
        if (value === 1 || value === 2 || value === 3) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 2 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value === 4) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 4 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value === 5 || value === 6 || value === 7) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 1 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value === 8 || value === 10 || value === 11 || value === 12) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 3 || index === 6) {
                    item.isShow = false
                }
            })
        } else if (value === 9) {
            Info.FirstInfo.forEach((item, index) => {
                item.isShow = true
                if (index === 0 || index === 5 || index === 6) {
                    item.isShow = false
                }
            })
        }
        this.setState(preState => ({
            Info,

        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys, modal_Visible, Info, FlowchartKey, TabletKey } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} dontImport={true} edit={true} del={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} delEvent={this.handleDel} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table key={TabletKey} columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                    }

                    <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} selectorEvent={this.handleSelect} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
