import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { DevelopmentComResearchList } from "../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '日期',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '合同名称',
                    dataIndex: 'con_name',
                    key: 'con_name',
                },
                {
                    title: '合同金额(元)',
                    dataIndex: 'con_money',
                    key: 'con_money',
                },
                {
                    title: '已累计付款(元)',
                    dataIndex: 'paid_money',
                    key: 'paid_money',
                },
                {
                    title: '剩余未付款(元)',
                    dataIndex: 'not_money',
                    key: 'not_money',
                },
                {
                    title: '费用类别',
                    dataIndex: 'class',
                    key: 'class',
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        let con_id = sessionStorage.getItem('conId')
        this.get_DevelopmentComResearchList(con_id)
    }

    get_DevelopmentComResearchList = (con_id) => {
        let { dataSource, pages, process_state } = this.state
        let data = {
            con_id: con_id,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        DevelopmentComResearchList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.research.length) {
                        res.data.research.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                apply_date: item.apply_date,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                paid_money: item.paid_money,
                                not_money: item.not_money,
                                con_money: item.con_money,
                                class: item.class === 1 ? '综合费用' : '科研费用',
                                info: item.info,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/project/PayNewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/project/PayNewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_DevelopmentComResearchList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_DevelopmentComResearchList()
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} seek={true} educe={true} dontImport={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
