import React, { Component } from 'react'
import { Input, Form } from 'antd';

export default class UserRangeInput extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { child } = this.props;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {
                    child ? child.map((item, index) => {
                        if (index > 0) {
                            return (
                                <Form.Item style={{ width: '48%',marginBottom:0  }}
                                    label=''
                                    name={item.fieldName}
                                    key={index}
                                    initialValue={item.value}
                                >
                                    <Input />
                                </Form.Item>
                            )
                        } else {
                            return (
                                <div key={index} style={{  width: '50%',display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Form.Item style={{ width: '96%',marginBottom:0 }}
                                        label=''
                                        name={item.fieldName}
                                        initialValue={item.value}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <div style={{ height:'44px',lineHeight:'44px' }}>
                                        -
                                    </div>
                                </div>
                            )
                        }
                    }) : ''
                }
            </div>
        )
    }
}
