import React, { Component } from 'react'
import { Form, Input, message, DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { researchProjectList, createResTemporary, ResTemporaryDetail, selectNurseryList } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/temporaryWorkerOne.docx',
                templateName: '四川天艺生态园林集团股份有限公司358工程临时用工付款申请表',
                tempData: {
                    username: '',
                    apply_date: '',
                    pro_name: '',
                    month: '',
                    nur_name: '',
                    details: [],
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "临时用工付款申请表",
                flowTips: '(358工程)',
                TableInfo: [
                    {
                        FieldTitle: '起草人', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '申请日期', //字段标题
                        fieldName: 'apply_day', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择申请日期', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '用工类别', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择用工类别', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '项目类'
                            },
                            {
                                value: 2,
                                label: '科研类'
                            }
                        ]
                    },
                    {
                        FieldTitle: '统计月份', //字段标题
                        fieldName: 'month', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择统计月份', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            { value: 1, label: "1月" },
                            { value: 2, label: "2月" },
                            { value: 3, label: "3月" },
                            { value: 4, label: "4月" },
                            { value: 5, label: "5月" },
                            { value: 6, label: "6月" },
                            { value: 7, label: "7月" },
                            { value: 8, label: "8月" },
                            { value: 9, label: "9月" },
                            { value: 10, label: "10月" },
                            { value: 11, label: "11月" },
                            { value: 12, label: "12月" }
                        ],
                    },
                    {
                        FieldTitle: '选择项目', //字段标题
                        fieldType: '按钮',  //字段类型
                    }, {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '选择苗圃', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                        isShow: true
                    },
                    {
                        FieldTitle: '苗圃名称', //字段标题
                        fieldName: 'plant_type', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
                FirstInfo: [
                    {
                        plateName: '工资明细',
                        list: [],
                        editable: {
                            dataSource: [],
                            columns: [
                                {
                                    title: '序号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: '80px',
                                    render: (text, record, index) => {
                                        return (
                                            <div>{index + 1}</div>
                                        )
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'controls',
                                    key: 'controls',
                                    width: '100px',
                                    render: (text, record, index) => {
                                        return (
                                            <div className='download' onClick={() => {
                                                this.handleDel(index, record.key)
                                            }}>
                                                删除
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '姓名',
                                    dataIndex: 'username',
                                    key: 'username',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'username' + record.key} key={index} initialValue={text}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'username' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '身份证号码',
                                    dataIndex: 'id_card',
                                    key: 'id_card',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'id_card' + record.key} key={index} initialValue={text}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'id_card' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '开户银行',
                                    dataIndex: 'bank',
                                    key: 'bank',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '银行卡号',
                                    dataIndex: 'bank_number',
                                    key: 'bank_number',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '实发工资',
                                    dataIndex: 'wage',
                                    key: 'wage',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'wage' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, index)}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'wage' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '备注',
                                    dataIndex: 'info',
                                    key: 'info',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                    <Input />
                                                </Form.Item>

                                            )

                                        } else {
                                            return (
                                                <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                },
                            ],
                            summary: true,
                            summaryValue: {
                                colspan: 8,
                                name: '合计',
                                value: 0
                            }
                        },
                    }]
            },
            modal_Visible: false,
            buttonTitle: '',
            dataSource: [],
            columns: [],
            TableInfo: [],
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            FlowchartKey: 1
        }

    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ResTemporaryDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                }
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '起草人') {
                item.value = userInfo.name
            }
        })
        this.setState(preState => ({
            Info,
            form,
            FlowchartKey: Math.random(),
        }))
    }

    get_ResTemporaryDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        ResTemporaryDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.project_id || ''
                        form.nur_id = res.data.nur_id || ''
                        Info.process.edit = res.data.editPermission

                        if (res.data.class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择项目') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '选择苗圃') {
                                    item.isShow = true
                                }
                            })
                        } else {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择项目') {
                                    item.isShow = true
                                } else if (item.FieldTitle === '选择苗圃') {
                                    item.isShow = true
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择苗圃') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })


                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '姓名',
                                dataIndex: 'username',
                                key: 'username',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'username' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'username' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '身份证号码',
                                dataIndex: 'id_card',
                                key: 'id_card',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'id_card' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'id_card' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '开户银行',
                                dataIndex: 'bank',
                                key: 'bank',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '银行卡号',
                                dataIndex: 'bank_number',
                                key: 'bank_number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '实发工资',
                                dataIndex: 'wage',
                                key: 'wage',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'wage' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'wage' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>

                                        )

                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]

                        Info.FirstInfo[0].editable.summaryValue.colspan = 7


                        if (res.data.status === 4) {
                            let details = JSON.parse(res.data.content)
                            Info.tempData.apply_date = res.data.apply_day
                            Info.tempData.username = res.data.applyUsername
                            Info.tempData.month = res.data.month + '月'
                            Info.tempData.details = []
                            Info.tempData.results = []

                            if (res.data.class === 1) {
                                Info.template = '/temporaryWorkerOne.docx'
                                Info.tempData.pro_name = res.data.pro_name || ''
                            } else {
                                Info.template = '/temporaryWorkerTwo.docx'
                                Info.tempData.nur_name = res.data.nurName || ''

                            }

                            details.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    id_card: item.id_card,
                                    bank: item.bank,
                                    bank_number: item.bank_number,
                                    wage: item.wage,
                                    info: item.info,
                                };
                                Info.tempData.details = [...Info.tempData.details, value]
                            })

                            res.data.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '起草人') {
                            item.value = res.data.applyUsername
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    if (res.data.class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '项目名称') {
                                item.isShow = false
                                item.value = res.data.pro_name
                            } else if (item.FieldTitle === '苗圃名称') {
                                item.isShow = true
                            }
                        })
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '项目名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '苗圃名称') {
                                item.isShow = false
                                item.value = res.data.nurName
                            }
                        })
                    }

                    let content = JSON.parse(res.data.content)
                    Info.FirstInfo[0].editable.dataSource = []
                    Info.FirstInfo[0].editable.summaryValue.value = 0

                    content.forEach((item, index) => {
                        let key = Info.FirstInfo[0].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            username: item.username,
                            id_card: item.id_card,
                            bank: item.bank,
                            bank_number: item.bank_number,
                            wage: item.wage,
                            info: item.info,
                        };
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'username' + key)()]: item.username,
                            [(() => 'id_card' + key)()]: item.id_card,
                            [(() => 'bank' + key)()]: item.bank,
                            [(() => 'bank_number' + key)()]: item.bank_number,
                            [(() => 'wage' + key)()]: item.wage,
                            [(() => 'info' + key)()]: item.info,
                        })
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        Info.FirstInfo[0].editable.summaryValue.value = Info.FirstInfo[0].editable.summaryValue.value + Number(item.wage)
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.apply_day ? moment(res.data.apply_day, 'YYYY-MM-DD') : '',
                        class: res.data.class,
                        month: res.data.month,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        //FlowchartKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchProjectList = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            status: 4,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        researchProjectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.researches.length) {
                        res.data.researches.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                cre_day: item.cre_day,
                                over_day: item.over_day,
                                plant_open_day: item.plant_open_day,
                                select_money: item.select_money,
                                duration: item.duration,
                                is_over: item.is_over,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectNurseryList = () => {
        let { dataSource } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                key: item.id,
                                code: item.code,
                                name: item.name
                            }
                            dataSource = [...dataSource, value]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.class = data.class
        form.month = data.month
        form.team = data.team
        form.content = []

        if (Info.FirstInfo[0].editable.dataSource.length) {
            Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                let value = {
                    username: data['username' + item.key] ? data['username' + item.key] : '',
                    id_card: data['id_card' + item.key] ? data['id_card' + item.key] : '',
                    bank: data['bank' + item.key] ? data['bank' + item.key] : '',
                    bank_number: data['bank_number' + item.key] ? data['bank_number' + item.key] : '',
                    wage: data['wage' + item.key] ? data['wage' + item.key] : '',
                    info: data['info' + item.key] ? data['info' + item.key] : '',
                }
                form.content = [...form.content, value]
            })
        }

        form.content = JSON.stringify(form.content)

        createResTemporary(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleRadio = (value, name) => {
        let { form, Info } = this.state;
        if (name === '用工类别') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择项目') {
                        item.isShow = false
                    } else if (item.FieldTitle === '项目名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择苗圃') {
                        item.isShow = true
                    } else if (item.FieldTitle === '苗圃名称') {
                        item.isShow = true
                        item.value = ''
                    }
                })
                form.nur_id = ''
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择项目') {
                        item.isShow = true
                    } else if (item.FieldTitle === '项目名称') {
                        item.isShow = true
                        item.value = ''
                    } else if (item.FieldTitle === '选择苗圃') {
                        item.isShow = false
                    } else if (item.FieldTitle === '苗圃名称') {
                        item.isShow = false
                    }
                })
                form.project_id = ''
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handleWorkAdd = (name) => {
        let { Info } = this.state
        const newData = {
            key: Info.FirstInfo[0].editable.dataSource.length + 1,
            username: '',
            id_card: '',
            bank: '',
            bank_number: '',
            wage: '',
            info: '',
        };
        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        this.setState(preState => ({
            Info,
        }))
    }

    InputChange = (e, key) => {
        let { Info } = this.state
        Info.FirstInfo[0].editable.summaryValue.value = 0
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index === key) {
                item.wage = Number(e.target.value)
            }
            Info.FirstInfo[0].editable.summaryValue.value = Info.FirstInfo[0].editable.summaryValue.value + Number(item.wage)
        })
        this.setState(preState => ({
            Info,
        }))
    }

    handButton = (title, state) => {
        let { form, columns, TableInfo } = this.state
        if (state) {
            if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '中标金额',
                        dataIndex: 'select_money',
                        key: 'select_money',
                    },
                    {
                        title: '开工时间',
                        dataIndex: 'plant_open_day',
                        key: 'plant_open_day',
                    },
                    {
                        title: '竣工时间',
                        dataIndex: 'over_day',
                        key: 'over_day',
                    },
                ]

                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]

                this.get_researchProjectList()
            } else {
                columns = [
                    {
                        title: '苗圃编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '苗圃名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ]

                TableInfo = []

                this.get_selectNurseryList()
            }

            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state

        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                }
            })
        } else {
            form.nur_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '苗圃名称') {
                    item.value = value[0].name
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_researchProjectList()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_researchProjectList()
            } else {
                this.get_selectNurseryList()
            }
        })
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'username' + e)()]: '',
            [(() => 'id_card' + e)()]: '',
            [(() => 'bank' + e)()]: '',
            [(() => 'bank_number' + e)()]: '',
            [(() => 'wage' + e)()]: '',
            [(() => 'info' + e)()]: '',
        })
        Info.FirstInfo[0].editable.summaryValue.value = 0
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
                Info.FirstInfo[0].editable.summaryValue.value = Info.FirstInfo[0].editable.summaryValue.value + Number(item.wage)
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }


    render() {
        let { FlowchartKey, Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={FlowchartKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} rowAddEvent={this.handleWorkAdd} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}