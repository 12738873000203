import React, { Component } from 'react'
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import { staffList } from "../../../../utils/oa"

export default class List extends Component {
    state = {
        searchShow: false,
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '设备编号',
                dataIndex: 'pro_name',
                key: 'pro_name',
                render: (text, record, index) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_details(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '设备名称',
                dataIndex: 'con_unit',
                key: 'con_unit',
            },
            {
                title: '设备厂家',
                dataIndex: 'award_money',
                key: 'award_money',
            },
            {
                title: '设备规格',
                dataIndex: 'award_time',
                key: 'award_time',
            },
            {
                title: '项目名称',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '项目编号',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '机械类别',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '机械名称',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '操作人',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '开始时间',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '结束时间',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '是否启用',
                dataIndex: 'remarks',
                key: 'remarks',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '设备名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            },
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '操作人', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '立项时间', //字段标题
                fieldName: 'time', //字段名称
                fieldType: '日期区间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
                child: [
                    {
                        FieldTitle: '开始时间', //字段标题
                        fieldName: 'start_time', //字段名称
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '结束时间', //字段标题
                        fieldName: 'end_time', //字段名称
                        value: '',
                        width: '50%',
                    },
                ]
            },
        ],
        Info: {
            FlowModelName: "GPS设备分配管理",
            TableInfo: [{
                FieldTitle: '设备编号', //字段标题
                fieldName: 'seal_ids', //字段名称
                fieldType: '文本',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '设备名称', //字段标题
                fieldName: 'date_day', //字段名称
                fieldType: '文本',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '设备厂家', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '设备规格', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'set_type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
                selectList: [
                    { value: '1', label: "有" },
                    { value: '2', label: "无" }
                ]
            },
            {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'about_project', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '机械类别', //字段标题
                fieldName: 'about_project', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '机械名称', //字段标题
                fieldName: 'about_project', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '操作人', //字段标题
                fieldName: 'about_project', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '开始时间', //字段标题
                fieldName: 'set_method', //字段名称
                fieldType: '时间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '结束时间', //字段标题
                fieldName: 'set_method', //字段名称
                fieldType: '时间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '是否启用', //字段标题
                fieldName: 'set_type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "是" },
                    { value: '2', label: "否" }
                ]
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'about_project', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            ],
            FirstInfo: []
        }
    }

    // componentDidMount() {
    //     this.get_staffList()
    // }

    get_staffList = () => {
        let { dataSource, pages, start, end, name, apply_user, process_state } = this.state
        let data = {
            start: start,
            end: end,
            name: name,
            apply_user: apply_user,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        staffList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                created_at: item.created_at,
                                apply_user: item.apply_user,
                                name: item.name,
                                job_day: item.job_day,
                                department: item.department,
                                position: item.position,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/purchase/supplier/storage/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleNew = () => {
        this.setState({
            modal_Visible: true
        })
    }

    handleEdit = () => {
        this.setState({
            modal_Visible: true
        })
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false
        })
    }

    handleModal = (value) => {
        console.log('新增', value)
        this.setState({
            modal_Visible: false
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            start: '',
            end: '',
            name: '',
            apply_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_staffList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
            apply_user: value.apply_user,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : ''
        }, () => {
            this.get_staffList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_staffList()
        })
    }

    render() {
        return (
            <div>
                <Operate dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit}/>

                <Append modal_Visible={this.state.modal_Visible} modalEvent={this.handleModal} cancelEvent={this.modalCancel} Info={this.state.Info} />
            </div>
        )
    }
}
