import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createProjectUser, projectUserDetail } from "../../../../utils/oa"
import { selectUserName } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: 1,
            FlowModelName: "组建项目团队",
            TableInfo: [{
                FieldTitle: '任命单号', //字段标题
                fieldName: 'amt_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '自动编号',
                width: '50%'
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择项目经理', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择项目副经理', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '项目副经理', //字段标题
                fieldName: 'vice_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '选择执行经理', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '执行经理', //字段标题
                fieldName: 'imp_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '选择技术负责人', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '技术负责人', //字段标题
                fieldName: 'technology', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '任命要求', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '任命书', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
				edit: false,
                data:{
                    InheritedFrom: ''
                }
            }
        },
        modal_Visible: false,
        buttonValue: '',
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query.tableId) {
            let tableId = this.props.location.query.tableId
            if (this.props.location.query.handle) {
                this.get_projectUserDetail(tableId, '调整任命')
            } else {
                this.get_projectUserDetail(tableId)
            }
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let project = JSON.parse(this.props.location.query.project)
        const { Info, form } = this.state;
        form.project_id = project.pro_id
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目编号') {
                item.value = project.pro_number
            } else if (item.FieldTitle === '项目名称') {
                item.value = project.pro_name
            }
        })
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_projectUserDetail = (tableId, handle) => {
        const {
            Info,
            form
        } = this.state;
        let data = {
            table_id: tableId
        }
        projectUserDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3 || handle) {
                        form.table_id = tableId
						Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目经理') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择项目副经理') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择执行经理') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择技术负责人') {
                                item.isShow = true
                            } else if (item.FieldTitle === '任命书') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                        Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                        Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
						Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission
                    }

                    if (handle) {
                        Info.state = 10
                    } else {
                        Info.state = res.data.Detail.status
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '任命单号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.managerName
                            form.manager = res.data.Detail.manager
                        } else if (item.FieldTitle === '项目副经理') {
                            item.value = res.data.Detail.viceName
                            form.vice_manager = res.data.Detail.vice_manager
                        } else if (item.FieldTitle === '执行经理') {
                            item.value = res.data.Detail.imp_managerName
                            form.imp_manager = res.data.Detail.imp_manager
                        } else if (item.FieldTitle === '技术负责人') {
                            item.value = res.data.Detail.technologyName
                            form.technology = res.data.Detail.technology
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status

        createProjectUser(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
					    setTimeout(() => {
					        window.history.back(-1)
					    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
            this.setState(preState => ({
                buttonValue: title
            }))
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonValue: ''
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form, buttonValue } = this.state;
        if (buttonValue === '选择项目经理') {
            form.manager = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目经理') {
                    item.value = value[0].username
                }
            })
        } else if (buttonValue === '选择项目副经理') {
            form.vice_manager = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目副经理') {
                    item.value = value[0].username
                }
            })
        } else if (buttonValue === '选择执行经理') {
            form.imp_manager = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '执行经理') {
                    item.value = value[0].username
                }
            })
        } else if (buttonValue === '选择技术负责人') {
            form.technology = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '技术负责人') {
                    item.value = value[0].username
                }
            })
        }
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
