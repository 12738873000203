import http from '../utils/http';

function indexData(data) {
    return http("get", '/api/indexData', data);
}

function flowStayList(data) {
    return http("get", '/api/flowStayList', data);
}

function flowReviewRead(data) {
    return http("post", '/api/flowReviewRead', data);
}

function flowSeeLook(data) {
    return http("get", '/api/flowSeeLook', data);
}

function flowCcRead(data) {
    return http("post", '/api/flowCcRead', data);
}

function userWeeklyLookList(data) {
    return http("get", '/api/userWeeklyLookList', data);
}

function userReadWeekly(data) {
    return http("post", '/api/userReadWeekly', data);
}

function createUserCommon(data) {
    return http("post", '/api/createUserCommon', data);
}

function userCommonList(data) {
    return http("get", '/api/userCommonList', data);
}

function getUseFunction(data) {
    return http("get", '/api/getUseFunction', data);
}

function userCommonDelete(data) {
    return http("post", '/api/userCommonDelete', data);
}

function stayReadConstructionLog(data) {
    return http("get", '/api/stayReadConstructionLog', data);
}

function readConstructionLog(data) {
    return http("post", '/api/readConstructionLog', data);
}

function approvalProcess(data) {
    return http("get", '/api/approvalProcess', data);
}

function previewNotice(data) {
    return http("get", '/api/previewNotice', data);
}

function IndexNoticeList(data) {
    return http("get", '/api/IndexNoticeList', data);
}

function IndexProcessRead(data) {
    return http("post", '/api/IndexProcessRead', data);
}

export {
    indexData,
    flowStayList,
    flowReviewRead,
    flowSeeLook,
    flowCcRead,
    userWeeklyLookList,
    userReadWeekly,
    createUserCommon,
    userCommonList,
    getUseFunction,
    userCommonDelete,
    stayReadConstructionLog,
    readConstructionLog,
    approvalProcess,
    previewNotice,
    IndexNoticeList,
    IndexProcessRead
}