export const resData = {
  max: 10,
  // data: [
  //   {
  //     name: "北京",
  //     value: 218
  //   },
  //   {
  //     value: 122,
  //     name: "广东"
  //   },
  //   {
  //     value: 119,
  //     name: "台湾"
  //   },
  //   {
  //     value: 81,
  //     name: "香港"
  //   },
  //   {
  //     value: 74,
  //     name: "山东"
  //   },
  //   {
  //     value: 68,
  //     name: "江苏"
  //   },
  //   {
  //     value: 62,
  //     name: "浙江"
  //   },
  //   {
  //     value: 49,
  //     name: "上海"
  //   },
  //   {
  //     value: 48,
  //     name: "河北"
  //   },
  //   {
  //     value: 43,
  //     name: "河南"
  //   },
  //   {
  //     value: 41,
  //     name: "辽宁"
  //   },
  //   {
  //     value: 38,
  //     name: "NULL"
  //   },
  //   {
  //     value: 36,
  //     name: "四川"
  //   },
  //   {
  //     value: 33,
  //     name: "湖北"
  //   },
  //   {
  //     value: 31,
  //     name: "湖南"
  //   },
  //   {
  //     value: 29,
  //     name: "安徽"
  //   },
  //   {
  //     value: 28,
  //     name: "吉林"
  //   },
  //   {
  //     value: 26,
  //     name: "江西"
  //   },
  //   {
  //     value: 24,
  //     name: "新疆"
  //   },
  //   {
  //     value: 24,
  //     name: "重庆"
  //   },
  //   {
  //     value: 23,
  //     name: "福建"
  //   },
  //   {
  //     value: 19,
  //     name: "广西"
  //   },
  //   {
  //     value: 18,
  //     name: "山西"
  //   },
  //   {
  //     value: 16,
  //     name: "云南"
  //   },
  //   {
  //     value: 16,
  //     name: "内蒙古"
  //   },
  //   {
  //     value: 16,
  //     name: "黑龙江"
  //   },
  //   {
  //     value: 12,
  //     name: "陕西"
  //   },
  //   {
  //     value: 12,
  //     name: "天津"
  //   },
  //   {
  //     value: 11,
  //     name: "宁夏"
  //   },
  //   {
  //     value: 10,
  //     name: "甘肃"
  //   },
  //   {
  //     value: 8,
  //     name: "贵州"
  //   },
  //   {
  //     value: 4,
  //     name: "西藏"
  //   },
  //   {
  //     value: 4,
  //     name: "青海"
  //   },
  //   {
  //     value: 1,
  //     name: "海南"
  //   }
  // ]
};
