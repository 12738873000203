import React, { Component } from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'

import { NurseryList, createNursery, NurseryDetail } from "../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '苗圃编号',
                    dataIndex: 'code',
                    key: 'code',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_NurseryDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '苗圃名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '新增日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
            ],
            Info: {
                state: '新增',
                FlowModelName: "新增苗圃",
                TableInfo: [{
                    FieldTitle: '苗圃编号', //字段标题
                    fieldName: 'code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '苗圃名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                ],
                FirstInfo: []
            },
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            form: {},
        }
    }

    

    componentDidMount() {
        this.get_NurseryList()
    }


    get_NurseryList = () => {
        let { dataSource, pages } = this.state
        let data = {
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        NurseryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let created_at=item.created_at.slice(0,10)
                            let value = {
                                key: index + 1,
                                table_id: item.id,
                                code: item.code,
                                name: item.name,
                                created_at:created_at
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: res.data.pages
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }
    // 新增弹窗
    NewForm = React.createRef();
    get_NurseryDetail = (tableId) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
     
        NurseryDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    Info.state = '详情'
                    // Info.FlowModelName = "苗圃详情"
                    Info.TableInfo.forEach((item, index) => {
                        item.disabled = true
                    })
                   
                    this.NewForm.current.formRef.current.setFieldsValue({
                        code: res.data.Detail.code,
                        name: res.data.Detail.name,
                    })
                   
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_NurseryList()
        })
    }

    handSelect = () => { }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.code = data.code
        form.name = data.name

        createNursery(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_NurseryList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                unit_name: '',
                unit_address: '',
                contacts: '',
                job: '',
                degree: '',
                situation: '',
                con_money: '',
                unit_class: '',
                type: '',
                con_date: '',
                phone: '',
                speciality: '',
                warranty: '',
                cooperate: '',
                pay_model: '',
                email: '',
                enclosure: '',
                contact: '',
                duty: '',
                facsimile: '',
                landline: '',
                email_address: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }


    render() {
        let { dataSource, columns, pages, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} seek={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                {
                    <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                }

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} />
            </div>
        )
    }
}

export default withRouter(List)
