import React, { Component } from 'react'
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { Tree, message, Select } from 'antd';
import axios from 'axios'
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { revenueMain } from "../../../utils/market"
import { sporadicList } from "../../../utils/project"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    width: '80px',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{index + 1}</div>
                        )
                    }
                },
                {
                    title: '申请人',
                    dataIndex: 'apply_username',
                    key: 'apply_username',
                },
                {
                    title: '申请日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '工作日期',
                    dataIndex: 'start_time',
                    key: 'start_time',
                },
                {
                    title: '班组名称',
                    dataIndex: 'team',
                    key: 'team',
                },
                {
                    title: '统计月份',
                    dataIndex: 'month',
                    key: 'month',
                    render: (text, index) => {
                        return (
                            <div>{text}月份</div>
                        )
                    }
                },
                {
                    title: '普工(工日)',
                    dataIndex: 'tome',
                    key: 'tome',
                },
                {
                    title: '技工(工日)',
                    dataIndex: 'skill',
                    key: 'skill',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pro_name: '',
            pro_id: '',
            team: '',
            start: '',
            end: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '工作时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
                {
                    FieldTitle: '班组名称', //字段标题
                    fieldName: 'team', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_sporadicList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_sporadicList = () => {
        let { pro_name, pro_id, monthValue,team, start, end, dataSource, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            pro_name: pro_name,
            project_id: pro_id,
            team: team,
            month: monthValue,
            start: start,
            end: end,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        sporadicList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.sporadic.length) {
                        res.data.sporadic.forEach((item, index) => {
                            let created_at = item.created_at.slice(0, 10)
                            let start_time = item.content[0].start_time ? item.content[0].start_time.slice(0, 10) : ''
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                apply_username: item.apply_username,
                                created_at: created_at,
                                start_time: start_time,
                                team: item.team,
                                tome: item.content[0].tome,
                                skill: item.content[0].skill,
                                month: item.month,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/labor/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        let { pro_name, monthValue, pro_id } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            pro_name: pro_name,
            month: monthValue,
            project_id: pro_id,
            team: ''
        }
        axios.get('/api/sporadicLeadingOut', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                })

                const link = document.createElement('a');
                link.download = '零星用工.xlsx'
                link.href = URL.createObjectURL(blob)
                link.target = "_blank"
                document.body.appendChild(link)
                link.click();
                //释放URL对象
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            } else {
                message.error('数据参数错误!')
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            pro_name: '',
            team: '',
            start: '',
            end: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_sporadicList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            team: value.team,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_sporadicList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_sporadicList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_sporadicList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_sporadicList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    selectChange = (value) => {
        this.setState(preState => ({
            monthValue: value
        }), () => {
            this.get_sporadicList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, monthList, monthValue, treeData, expandedKeys, selectedKeys, } = this.state
        return (
            <div className='contract_List'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <Operate dontAdd={true} notPrint={true} dontImport={true} searchEvent={this.handleSearch} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />
                        <div className='screen_right'>
                            <div>
                                <div>选择月份 :</div>
                                <Select
                                    options={monthList}
                                    value={monthValue}
                                    onChange={(e) => {
                                        this.selectChange(e)
                                    }}
                                    suffixIcon={<CaretDownOutlined />}
                                >
                                </Select>
                            </div>
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
