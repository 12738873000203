import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import InitialValue from '../../../components/InitialValue'
import AddConfig from '../../../components/AddConfig'
import { createExcellentSupplier, excellentSupplierDetail, supplierSelect } from "../../../../utils/purchase"
import { reviewApplyResult } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "优质供应商入库",
            TableInfo: [
                {
                    FieldTitle: '入库日期', //字段标题
                    fieldName: 'apply_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '选择供应商', //字段标题
                    fieldType: '按钮',  //字段类型
                },
                {
                    FieldTitle: '供应商名称', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '供应商类型', //字段标题
                    fieldName: 'sup_class', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '所属分类', //字段标题
                    fieldName: 'class', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '注册地址', //字段标题
                    fieldName: 're_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '法定代表人', //字段标题
                    fieldName: 'legal_person', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '联系电话', //字段标题
                    fieldName: 'phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '开户银行', //字段标题
                    fieldName: 'bank_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '银行账号', //字段标题
                    fieldName: 'bank_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '营业执照范围', //字段标题
                    fieldName: 'range', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '业务联系人', //字段标题
                    fieldName: 'contact', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '业务联系电话', //字段标题
                    fieldName: 'con_phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '其他说明', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
            ],
            FirstInfo: []
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '供应商名称',
                dataIndex: 'sup_name',
                key: 'sup_name',
            },
            {
                title: '供应商类型',
                dataIndex: 'class',
                key: 'class',
            },
            {
                title: '所属分类',
                dataIndex: 'mat_class',
                key: 'mat_class',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '联系人',
                dataIndex: 'contacts',
                key: 'contacts',
            },
            {
                title: '付款方式',
                dataIndex: 'pay_model',
                key: 'pay_model',
            },
            {
                title: '建档人',
                dataIndex: 'apply_user',
                key: 'apply_user',
            },
        ],
        TableInfo: [{
            FieldTitle: '供应商名称', //字段标题
            fieldName: 'sup_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '31%',
        }, {
            FieldTitle: '供应商类型', //字段标题
            fieldName: 'class', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '31%',
            selectList: [
                {
                    label: '材料',
                    value: 1
                },
                {
                    label: '劳务',
                    value: 2
                },
                {
                    label: '机械',
                    value: 3
                }
            ]
        }, {
            FieldTitle: '所属分类', //字段标题
            fieldName: 'mat_class', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '31%',
        }],
        sup_class: '',
        sup_name: '',
        mat_class: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_excellentSupplierDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_excellentSupplierDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        excellentSupplierDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.sup_id = res.data.Detail.sup_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择供应商') {
                                item.isShow = true
                            }

                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '供应商名称') {
                            item.value = res.data.Detail.supName
                        } else if (item.FieldTitle === '供应商类型') {
                            item.value = res.data.Detail.className
                        } else if (item.FieldTitle === '所属分类') {
                            item.value = res.data.Detail.matClass
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        re_address: res.data.Detail.re_address,
                        legal_person: res.data.Detail.legal_person,
                        phone: res.data.Detail.phone,
                        bank_name: res.data.Detail.bank_name,
                        bank_number: res.data.Detail.bank_number,
                        range: res.data.Detail.range,
                        contact: res.data.Detail.contact,
                        con_phone: res.data.Detail.con_phone,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    if (res.data.Detail.is_booking === 1) {
                        Info.FirstInfo[1].isShow = false
                    }

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date.format('YYYY-MM-DD')
        form.re_address = data.re_address
        form.legal_person = data.legal_person
        form.phone = data.phone
        form.bank_name = data.bank_name
        form.bank_number = data.bank_number
        form.range = data.range
        form.contact = data.contact
        form.con_phone = data.con_phone
        form.info = data.info

        createExcellentSupplier(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierSelect = () => {
        let { dataSource, pages, sup_class, sup_name, mat_class } = this.state
        let data = {
            class: sup_class,
            sup_name: sup_name,
            mat_class: mat_class,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : '机械',
                                mat_class: item.matClass,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                contacts: item.contacts,
                                pay_model: item.payModel,
                                address: item.address,
                                bank_name: item.bank_name,
                                bank_number: item.bank_number,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_supplierSelect()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code !== 1010) {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelect = (value) => {
        const { Info, form } = this.state;
        form.sup_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '供应商名称') {
                item.value = value[0].sup_name
            } else if (item.FieldTitle === '供应商类型') {
                item.value = value[0].class
            } else if (item.FieldTitle === '所属分类') {
                item.value = value[0].mat_class
            }
        })


        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }), () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                re_address: value[0].address,
                bank_name: value[0].bank_name,
                bank_number: value[0].bank_number,
                contact: value[0].contacts,
                con_phone: value[0].phone,
            })
        })


    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    searchQuery = (value) => {
        this.setState({
            sup_class: value.sup_class,
            sup_name: value.sup_name,
            mat_class: value.mat_class,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            }
        }, () => {
            this.get_supplierSelect()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            sup_class: '',
            sup_name: '',
            mat_class: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_supplierSelect()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd' >
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}