import React, { Component } from 'react'
import Icon, { CaretDownOutlined, FormOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Tree, message, Input, Button } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { designPublicTaskList } from "../../../utils/market"
import { DesignHandoverList, getDesignSummary, createDesignSummary } from "../../../utils/design"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import Icon_01 from "../../../accets/img/icon_01.png"

const { TextArea } = Input;

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '文件性质',
                    dataIndex: 'class_type',
                    key: 'class_type',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '文件类型',
                    dataIndex: 'file_type',
                    key: 'file_type',
                },
                {
                    title: '上传人',
                    dataIndex: 'apply_user',
                    key: 'apply_user',
                },
                {
                    title: '上传时间',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '移交内容描述',
                    dataIndex: 'transfer_content',
                    key: 'transfer_content',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            TableInfo: [
                {
                    FieldTitle: '文件性质', //字段标题
                    fieldName: 'class_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    selectList: [
                        {
                            label: '方案',
                            value: 1,
                        },
                        {
                            label: '施工图',
                            value: 2,
                        },
                    ]
                },
                {
                    FieldTitle: '文件类型', //字段标题
                    fieldName: 'file_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    selectList: [
                        {
                            label: 'PPT',
                            value: 1,
                        },
                        {
                            label: 'PDF',
                            value: 2,
                        },
                        {
                            label: 'CAD',
                            value: 3,
                        },
                        {
                            label: '纸质',
                            value: 4,
                        },
                    ]
                },
                {
                    FieldTitle: '上传人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '上传时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            pro_id: '',
            class_type: '',
            file_type: '',
            apply_user: '',
            start: '',
            end: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            textValue: '',
            reportList: [],
            designSummary: false,
            isUnfold: true,
            proList: []
        }
    }

    componentDidMount() {
        this.get_designPublicTaskList()
        this.get_userInfo()
    }

    get_userInfo = () => {
        let { designSummary } = this.state
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            permission.forEach((item, index) => {
                if (item === 'createDesignSummary') {
                    designSummary = true
                }
            })
        }
        this.setState(preState => ({
            designSummary,
        })
        )
    }

    get_designPublicTaskList = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys,proList } = this.state
        designPublicTaskList().then(
            (res) => {
                if (!res.code) {
                    treeData = []
                    proList=[]
                    for (let key in res.data) {
                        let children = res.data[key]
                        let value = {
                            title: key,
                            key: key,
                            icon: <FolderIcon />,
                            children: []
                        }

                        children.forEach((item, index) => {
                            proList = [...proList, item]
                            let chilValue = {
                                title: item.pro_name,
                                icon: <FoldersIcon />,
                                key: item.table_id,
                            }
                            value.children = [...value.children, chilValue]
                        })
                        treeData = [...treeData, value]
                    }

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['2023']
                        treeData.forEach((item, index) => {
                            if (item.key === '2023') {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.key
                                        selectedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                        proList
                    }, () => {
                        this.get_DesignHandoverList()
                        this.get_DesignSummary()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DesignHandoverList = () => {
        let { pro_id, class_type, file_type, apply_user, start, end, dataSource, columns, pages, process_state } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            class: class_type,
            file_type: file_type,
            apply_user: apply_user,
            start: start,
            end: end,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        DesignHandoverList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.designs.length) {
                        res.data.designs.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                class_type: item.class === 1 ? '方案' : '施工图',
                                file_type: '',
                                apply_user: item.applyUserName,
                                created_at: item.created_at,
                                transfer_content: item.transfer_content,
                                status: item.status,
                            }
                            let file_type = JSON.parse(item.file_type)
                            file_type.forEach((em, ex) => {
                                if (em === 1) {
                                    value.file_type = value.file_type + ' ' + 'PPT'
                                } else if (em === 2) {
                                    value.file_type = value.file_type + ' ' + 'PDF'
                                } else if (em === 3) {
                                    value.file_type = value.file_type + ' ' + 'CAD'
                                } else if (em === 4) {
                                    value.file_type = value.file_type + ' ' + '纸质'
                                }
                            })
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        columns,
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DesignSummary = () => {
        let { pro_id } = this.state
        if (!pro_id) {
            // message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
        }
        getDesignSummary(data).then(
            (res) => {
                if (!res.code) {
                    this.setState({
                        reportList: res.data.content
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_createDesignSummary = () => {
        let { pro_id, textValue } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            content: textValue
        }
        createDesignSummary(data).then(
            (res) => {
                if (!res.code) {
                    message.success('编辑成功')
                    this.setState(preState => ({
                        textValue: ''
                    }), () => {
                        this.get_DesignSummary()
                    })
                } else {
                    message.error(res.data[0] || '编辑失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/design/documentTransfer/NewItem'
        let CachingKeys = getCachingKeys()
        let { pro_id, proList } = this.state
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
		localStorage.setItem('itemForm', '')
        setTimeout(() => {
            proList.forEach((item, index) => {
                if (item.table_id === pro_id) {
                    localStorage.setItem('itemForm', JSON.stringify(item))
                }
            })
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/design/documentTransfer/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            class_type: '',
            file_type: '',
            apply_user: '',
            start: '',
            end: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_DesignHandoverList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            class_type: value.class_type,
            file_type: value.file_type,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
            apply_user: value.apply_user,
        }, () => {
            this.get_DesignHandoverList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_DesignHandoverList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_DesignHandoverList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_DesignHandoverList()
            this.get_DesignSummary()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    textChange = (value) => {
        this.setState(preState => ({
            textValue: value.target.value,
        })
        )
    }

    UnfoldChange = () => {
        let { isUnfold } = this.state
        this.setState(preState => ({
            isUnfold: !isUnfold
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys, textValue, designSummary, reportList, isUnfold } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div onClick={() => this.UnfoldChange()} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='FirstTitle' style={{ width: '50%' }}>
                            <div>
                                <div>设计进度汇报</div>
                                <div><img src={Icon_01} alt=""></img></div>
                            </div>
                        </div>

                        {
                            !isUnfold ? <div style={{ cursor: 'pointer', display: 'flex', marginBottom: '20px', fontSize: '16px' }} >
                                <DoubleRightOutlined style={{ transform: 'rotate(90deg)', marginRight: '20px' }} />
                                <div>展开</div>
                            </div> : <div style={{ cursor: 'pointer', display: 'flex', marginBottom: '20px', fontSize: '16px' }}>
                                <DoubleRightOutlined style={{ transform: 'rotate(-90deg)', marginRight: '20px' }} />
                                <div>收起</div>
                            </div>
                        }
                    </div>

                    {
                        designSummary ? <div>
                            <div className='ListOperate'>
                                <Button style={{ marginLeft: '0px' }} onClick={() => {
                                    this.get_createDesignSummary()
                                }}>
                                    <FormOutlined />
                                    <div>添加</div>
                                </Button>
                            </div>

                            <TextArea
                                autoSize={{
                                    minRows: 5,
                                    maxRows: 10,
                                }}
                                value={textValue}
                                style={{ marginBottom: '20px' }}
                                onChange={(e) => this.textChange(e)}
                            />
                        </div> : ''
                    }

                    {
                        isUnfold ? reportList.map((item, index) => {
                            return (
                                <div className='reportList' key={index} style={{ marginBottom: '20px', fontSize: '16px', backgroundColor: '#FFFFFF', padding: '5px 10px', borderRadius: '4px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>提交人：{item.name}</div>
                                        <div>{item.created_at}</div>
                                    </div>
                                    <TextArea
                                        autoSize={{
                                            minRows: 5,
                                            maxRows: 10,
                                        }}
                                        value={item.content}
                                        style={{ marginBottom: '20px' }}
                                        onChange={(e) => this.textChange(e)}
                                    />
                                </div>
                            )
                        }) : ''
                    }

                    <div className='FirstTitle'>
                        <div>
                            <div>设计文件移交</div>
                            <div><img src={Icon_01} alt=""></img></div>
                        </div>
                    </div>

                    <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
