import React, { Component } from 'react'
import { Table, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
// import Table from '../../components/Table'
import { ResearchProjectDataList, researchProjectAuxiliary } from "../../../utils/scientific_research"
import moneyImg from "../../../accets/img/money.png"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    width:'240px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '235px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '开工时间',
                    dataIndex: 'plant_open_day',
                    key: 'plant_open_day',
                },
                {
                    title: '项目性质',
                    dataIndex: 'pro_class',
                    key: 'pro_class',
                },
                {
                    title: '项目负责人',
                    dataIndex: 'pro_manager',
                    key: 'con_monepro_managery',
                },
                {
                    title: '材料总价(元)',
                    dataIndex: 'mac_money',
                    key: 'mac_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '人工总价(元)',
                    dataIndex: 'peo_cost',
                    key: 'peo_cost',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '结算面积(㎡)',
                    dataIndex: 'set_area',
                    key: 'set_area',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '结算金额(元)',
                    dataIndex: 'set_money',
                    key: 'set_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#E4DD40' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '利润率(%)',
                    dataIndex: 'profit_rate',
                    key: 'profit_rate',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#EC5355' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '',
                    dataIndex: '',
                    key: '',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer', backgroundColor: '#038DCE', width: '76px', height: '32px', lineHeight: '32px', color: '#ffffff', textAlign: 'center', borderRadius: '3px' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>查看详情</div>
                        )
                    }
                },
            ],
            pro_name: '',
            pro_class: '',
            start: '',
            end: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '项目性质', //字段标题
                    fieldName: 'pro_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    selectList: []
                },
                {
                    FieldTitle: '立项时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            summaryList: [
                {
                    name: '材料总价(元)',
                    value: 0
                },
                {
                    name: '人工总价(元)',
                    value: 0
                },
                {
                    name: '结算面积(㎡)',
                    value: 0
                },
                {
                    name: '结算总额(元)',
                    value: 0
                }
            ],
            windowHeight: 0
        }
    }

    componentDidMount() {
        let { windowHeight } = this.state
        let height = window.innerHeight;
        windowHeight = height - 410
        this.setState(preState => ({
            windowHeight,
        }), () => {
            this.get_researchProjectAuxiliary()
        })
    }

    get_researchProjectAuxiliary = () => {
        let { TableInfo } = this.state
        researchProjectAuxiliary().then(
            (res) => {
                if (!res.code) {
                    TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目性质') {
                            item.selectList = []
                            for (let key in res.data.researchProjectMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.researchProjectMode[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        TableInfo,
                    }), () => {
                        this.get_ResearchProjectDataList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ResearchProjectDataList = () => {
        let { dataSource, pro_name, pro_class, start, end,summaryList } = this.state
        let data = {
            pro_name: pro_name,
            pro_class: pro_class,
            start: start,
            end: end,
        }
        ResearchProjectDataList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_name: item.pro_name,
                                plant_open_day:item.plant_open_day,
                                pro_class: item.proClassName,
                                pro_manager: item.pro_manager,
                                mac_money: item.mac_money,
                                peo_cost: item.peo_cost,
                                set_area: item.set_area,
                                set_money: item.set_money,
                                profit_rate:item.profit_rate
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    summaryList[0].value=res.data.macAllMoney
                    summaryList[1].value=res.data.peoAllMoney
                    summaryList[2].value=res.data.setAllArea
                    summaryList[3].value=res.data.setAllMoney

                    this.setState(preState => ({
                        dataSource,
                        summaryList
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/item/projectKanban/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        let { windowHeight } = this.state
        windowHeight = windowHeight - 140
        this.setState({
            searchShow: true,
            windowHeight
        })
    }

    handleCancel = () => {
        let { windowHeight } = this.state
        windowHeight = windowHeight + 140
        this.setState({
            searchShow: false,
            pro_name: '',
            pro_class: '',
            start: '',
            end: '',
            windowHeight
        }, () => {
            this.get_ResearchProjectDataList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pro_class: value.pro_class,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_ResearchProjectDataList()
        })
    }

    handleHeight = () => {
        let { windowHeight, searchShow } = this.state
        let height = window.innerHeight;
        if (searchShow) {
            windowHeight = height - 550
        } else {
            windowHeight = height - 410
        }
        this.setState({
            windowHeight
        })
    }


    render() {
        let { searchShow, columns, dataSource, TableInfo, summaryList, windowHeight } = this.state
        window.addEventListener('resize', () => {
            this.handleHeight()
        });
        return (
            <div className='project_kanban'>
                <div>
                    <div></div>
                    <div>358项目总看板</div>
                </div>

                <div className='ListOperate'>
                    {
                        searchShow ? <Button style={{ marginLeft: 0, backgroundColor: '#038DCE', color: '#FFFFFF' }} type="primary">
                            <SearchOutlined />
                            <div>搜索</div>
                        </Button> : <Button style={{ marginLeft: 0, backgroundColor: '#038DCE', color: '#FFFFFF' }} type="primary" onClick={() => {
                            this.handleSearch()
                        }} >
                            <SearchOutlined />
                            <div>搜索</div>
                        </Button>
                    }

                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <div style={{ border: '1px solid rgba(7, 118, 181, .7)' }}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        scroll={{
                            y: windowHeight,
                        }}
                        summary={() => (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={10} className="total_prices">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={moneyImg} alt=""></img>
                                            <div style={{ marginLeft: '20px' }}>合计</div>
                                            {
                                                summaryList.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <span style={{ marginLeft: '50px' }}>{item.name}</span>
                                                            <span style={{ marginLeft: '15px', color: item.name === '结算总额(元)' ? '#E4DD40' : '#07D7F3', fontSize: '20px' }}>{item.value}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                    />
                </div>



            </div>

            // border-bottom: 1px solid rgba(7, 118, 181, .7);
        )
    }
}


export default withRouter(List)
