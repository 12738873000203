import React, { Component } from 'react'
import { Input } from 'antd';
const { TextArea } = Input;

export default class UserTextArea extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    textAreaRef = React.createRef();

    render() {
        let { disabled, placeholder, value, onChange } = this.props;
        return (
            <div>
                <TextArea
                    ref={this.textAreaRef}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoSize={{
                        minRows: 6,
                        maxRows: 15,
                    }}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    value={value}
                />
            </div>
        )
    }
}

