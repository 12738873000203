import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { revenueMain } from "../../../utils/market"
import { processDocumentList } from "../../../utils/project"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: ' 上传部门',
                    dataIndex: 'deName',
                    key: 'deName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '文件说明',
                    dataIndex: 'info',
                    key: 'info',
                    width: '260px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '上传人',
                    dataIndex: 'userName',
                    key: 'userName',
                },
                {
                    title: '上传时间',
                    dataIndex: 'created_at',
                    key: 'created_at',
                }, {
                    title: '上传状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div style={{ fontSize: '14px' }}>
                                    <span style={{ color: '#187AF9', fontSize: '16px' }}>{record.remainder}</span>小时后进行云归档
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        云归档完成
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '上传部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '上 传 人', //字段标题
                    fieldName: 'up_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            pro_id: '',
            de_name: '',
            up_user: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            proList: []
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys, proList } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    proList = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            proList = [...proList, ...item.project[key]]
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (this.props.location.query) {
                        pro_id = this.props.location.query.tableId
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            item.children.forEach((em, ex) => {
                                em.children.forEach((m, x) => {
                                    if (m.key === pro_id) {
                                        selectedKeys.push(m.key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            })
                        })
                    } else {
                        if (!pro_id) {
                            selectedKeys = []
                            expandedKeys = ['1']
                            treeData.forEach((item, index) => {
                                if (index === 0) {
                                    item.children.forEach((em, ex) => {
                                        if (ex === 0) {
                                            pro_id = em.children[0].key
                                            selectedKeys.push(em.children[0].key)
                                            expandedKeys.push(em.key)
                                        }
                                    })
                                }
                            })
                        }
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                        proList
                    }, () => {
                        this.get_processDocumentList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_processDocumentList = () => {
        let { pro_id, de_name, up_user, dataSource, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            project_id: pro_id,
            de_name: de_name,
            up_user: up_user,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit),
        }
        processDocumentList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.files.length) {
                        res.data.files.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                deName: item.deName,
                                userName: item.applyUsername,
                                created_at: item.created_at,
                                info: item.info,
                                remainder: item.remainder,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/project/dataUpload/NewItem'
        let CachingKeys = getCachingKeys()
        let { pro_id, proList } = this.state
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        localStorage.setItem('itemForm', '')
        setTimeout(() => {
            proList.forEach((item, index) => {
                if (item.project_id === pro_id) {
                    localStorage.setItem('itemForm', JSON.stringify(item))
                }
            })
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/dataUpload/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }


    handleCancel = () => {
        this.setState({
            searchShow: false,
            de_name: '',
            up_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_processDocumentList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            de_name: value.de_name,
            up_user: value.up_user,
        }, () => {
            this.get_processDocumentList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_processDocumentList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_processDocumentList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys, pages } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
