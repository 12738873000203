import React, { Component } from 'react'
import { message, Form, Input, Modal } from 'antd';
import moment from 'moment';
import axios from 'axios'
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { selectAvailableSeal } from "../../../../utils/public"
import { createSupConfirmPrice, SupConfirmPriceDetail, aboutCostBudget, supplierSelect, addSupUpdatePrice, selectProjectPickList } from "../../../../utils/purchase"
import { BlankingSelectProject } from "../../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"
import excess from "../../../../accets/img/excess.png"
import drop from "../../../../accets/img/drop.png"


export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                unSubmit: false,
                unExport: true,
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "供应商认价审批",
                TableInfo: [
                    {
                        FieldTitle: '认价编号', //字段标题
                        fieldName: 'code', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '(自动编号)',
                        width: '50%',
                    },
                    {
                        FieldTitle: '认价日期', //字段标题
                        fieldName: 'apply_date', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择认价日期', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '认价类别', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择认价类别', //必填要求
                        value: '',
                        width: '50%',
                        newline: true,
                        radioList: [
                            { value: 1, label: "材料" },
                            { value: 2, label: "劳务" },
                            { value: 3, label: "机械" },
                            { value: 7, label: "零星材料" },
                            { value: 8, label: "双包" },
                        ]
                    },
                    {
                        FieldTitle: '是否盖章', //字段标题
                        fieldName: 'is_seal', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择是否盖章', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "是" },
                            { value: 2, label: "否" },
                        ],
                    },
                    {
                        FieldTitle: '选择印章', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                        isShow: true
                    },
                    {
                        FieldTitle: '印章', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true
                    },
                    {
                        FieldTitle: '选择项目', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'project_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_nimber', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'pro_manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '选择成本专员', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '成本专员', //字段标题
                        fieldName: 'cost_user', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },

                    // {
                    //     FieldTitle: '关联人材机下料单', //字段标题
                    //     fieldType: '按钮',  //字段类型
                    //     mandatory: true, //字段是否必填
                    // },
                    // {
                    //     FieldTitle: '下料单单据', //字段标题
                    //     fieldName: 'pick_id', //字段名称
                    //     fieldType: '默认',  //字段类型
                    //     disabled: false,  //字段是否禁用
                    //     mandatory: false, //字段是否必填
                    //     required: '', //必填要求
                    //     value: '',
                    //     width: '50%',
                    //     search: true,
                    // },
                    {
                        FieldTitle: '选择中标供应商', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '中标供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
                FirstInfo: [
                    {
                        plateName: '材料认价详情',
                        list: [
                            {
                                FieldTitle: '税率(%)', //字段标题
                                fieldName: 'tax', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                        ],
                        editable: {
                            scroll: true,
                            scrollWidth: 2800,
                            dataSource: [],
                            columns: [
                                // {
                                //     title: '',
                                //     dataIndex: 'key',
                                //     key: 'key',
                                //     width: '80px',
                                //     fixed: 'left',
                                // },
                                {
                                    title: '序号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: '80px',
                                    fixed: 'left',
                                    render: (text, record, index) => {
                                        return (
                                            <div>{index + 1}</div>
                                        )
                                    }
                                },
                                {
                                    title: '操  作',
                                    dataIndex: 'controls',
                                    key: 'controls',
                                    width: '100px',
                                    fixed: 'left',
                                    render: (text, record, index) => {
                                        return (
                                            <div className='download' onClick={() => {
                                                this.handleDel(index, record.key)
                                            }}>
                                                删除
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '名称/工种',
                                    dataIndex: 'name',
                                    key: 'name',
                                    fixed: 'left',
                                },
                                {
                                    title: '规格型号',
                                    dataIndex: 'model',
                                    key: 'model',
                                    width: '200px',
                                    render: (text, record, index) => {
                                        return (
                                            <div title={text} style={{ cursor: 'pointer', width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                        )
                                    }
                                },
                                {
                                    title: '计量单位',
                                    dataIndex: 'unit',
                                    key: 'unit',
                                },
                                {
                                    title: '数量/工程量',
                                    dataIndex: 'number',
                                    key: 'number',
                                },
                                {
                                    title: '预 算 价',
                                    dataIndex: 'budget',
                                    key: 'budget',
                                    render: (text, record, index) => {
                                        return (
                                            <div>
                                                <div>
                                                    预算单价: {text[0] ? text[0] : '/'}
                                                </div>
                                                <div>
                                                    预算总价: {text[1] ? text[1] : '/'}
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '合 同 价',
                                    dataIndex: 'contract',
                                    key: 'contract',
                                    render: (text, record, index) => {
                                        return (
                                            <div>
                                                <div>
                                                    合同单价: {text[0] || '/'}
                                                </div>
                                                <div>
                                                    合同总价: {text[1] || '/'}
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '采购价(含税)',
                                    dataIndex: 'purchase',
                                    key: 'purchase',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <div>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div>单价</div>
                                                        <Form.Item name={'pur_unit_price' + record.key} key={index} initialValue={text[0]} onChange={(e) => this.InputChange(e, record.id, 'pur_unit_price')} style={{ width: '68%', marginLeft: '10px' }}>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>总价</div>
                                                        <div className='ant-input' style={{ width: '68%', height: '44px', marginLeft: '10px', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div>单价</div>
                                                        <Form.Item name={'pur_unit_price' + record.key} key={index} initialValue={text[0]} onChange={(e) => this.InputChange(e, record.id, 'pur_unit_price')} style={{ width: '68%', marginLeft: '10px' }}>
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>总价</div>
                                                        <div className='ant-input' style={{ width: '68%', height: '44px', marginLeft: '10px', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                },
                                {
                                    title: '采购价(不含税)',
                                    dataIndex: 'procurement',
                                    key: 'procurement',
                                    render: (text, record, index) => {
                                        return (
                                            <div>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div>单价</div>
                                                    <div className='ant-input' style={{ width: '68%', height: '44px', margin: '0 10px', fontSize: '16px', color: '#333' }}>{text[0].value}</div>
                                                    <img src={text[0].state === 1 ? excess : text[0].state === 2 ? drop : ''} alt=""></img>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>总价</div>
                                                    <div className='ant-input' style={{ width: '68%', height: '44px', margin: '0 10px', fontSize: '16px', color: '#333' }}>{text[1].value}</div>
                                                    <img src={text[1].state === 1 ? excess : text[1].state === 2 ? drop : ''} alt=""></img>
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '税率(%)',
                                    dataIndex: 'tax',
                                    key: 'tax',
                                    width: '120px',
                                },
                                // {
                                //     title: '税率(%)',
                                //     dataIndex: 'tax',
                                //     key: 'tax',
                                //     width: '120px',
                                //     render: (text, record, index) => {
                                //         if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                //             return (
                                //                 <Form.Item name={'tax' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tax')}>
                                //                     <Input />
                                //                 </Form.Item>
                                //             )
                                //         } else {
                                //             return (
                                //                 <Form.Item name={'tax' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tax')}>
                                //                     <Input disabled />
                                //                 </Form.Item>
                                //             )
                                //         }
                                //     }
                                // },
                                {
                                    title: '利润率(%)',
                                    dataIndex: 'profit',
                                    key: 'profit',
                                    width: '120px',
                                    render: (text, record, index) => {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '74%', margin: '0 10px', fontSize: !text ? '16px' : '22px', color: !text ? '#333333' : record.estimated_profit ? 'red' : '#00dc9e' }}>{text || '/'}</div>
                                                <img src={!text ? '' : record.estimated_profit ? excess : drop} alt=""></img>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '备  注',
                                    dataIndex: 'info',
                                    key: 'info',
                                    width: '400px',
                                    render: (text, record, index) => {
                                        if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                            return (
                                                <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'info')}>
                                                    <Input />
                                                </Form.Item>
                                            )
                                        } else {
                                            return (
                                                <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'info')}>
                                                    <Input disabled />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                }
                            ]
                        },
                    },
                    {
                        plateName: '材料价格调整',
                        list: [],
                        isShow: true,
                        amendTable: {
                            scroll: false,
                            dataSource: [],
                            columns: []
                        }
                    }
                ]
            },
            confirm_Modal: false,
            modal_Visible: false,
            buttonTitle: '',
            seal_name: '',
            sup_class: '',
            sup_name: '',
            mat_class: '',
            username: '',
            de_name: '',
            pro_name: '',
            name: '',
            mac_id: '',
            price: '',
            pick_code: '',
            dataSource: [],
            columns: [],
            TableInfo: [],
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: true,
            FlowchartKey: 2,
            formKey: 1,
            tax: ''
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SupConfirmPriceDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = project.pro_manager
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_SupConfirmPriceDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        SupConfirmPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.class = res.data.Detail.class
                        form.sup_id = res.data.Detail.sup_id
                        form.project_id = res.data.Detail.project_id
                        form.cost_user = res.data.Detail.cost_user
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                    } else {
                        form.table_id = tableId

                        Info.FirstInfo[0].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                fixed: 'left',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '名称/工种',
                                dataIndex: 'name',
                                key: 'name',
                                fixed: 'left',
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                width: '200px',
                                render: (text, record, index) => {
                                    return (
                                        <div title={text} style={{ cursor: 'pointer', width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                    )
                                }
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '数量/工程量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '预 算 价',
                                dataIndex: 'budget',
                                key: 'budget',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                预算单价: {text[0] ? text[0] : '/'}
                                            </div>
                                            <div>
                                                预算总价: {text[1] ? text[1] : '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '合 同 价',
                                dataIndex: 'contract',
                                key: 'contract',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                合同单价: {text[0] || '/'}
                                            </div>
                                            <div>
                                                合同总价: {text[1] || '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '采购价(含税)',
                                dataIndex: 'purchase',
                                key: 'purchase',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <div>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div>单价</div>
                                                    <Form.Item name={'pur_unit_price' + record.key} key={index} initialValue={text[0]} onChange={(e) => this.InputChange(e, record.id, 'pur_unit_price')} style={{ width: '68%', marginLeft: '10px' }}>
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>总价</div>
                                                    <div className='ant-input' style={{ width: '68%', height: '44px', marginLeft: '10px', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div>单价</div>
                                                    <Form.Item name={'pur_unit_price' + record.key} key={index} initialValue={text[0]} onChange={(e) => this.InputChange(e, record.id, 'pur_unit_price')} style={{ width: '68%', marginLeft: '10px' }}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>总价</div>
                                                    <div className='ant-input' style={{ width: '68%', height: '44px', marginLeft: '10px', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: '采购价(不含税)',
                                dataIndex: 'procurement',
                                key: 'procurement',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                <div>单价</div>
                                                <div className='ant-input' style={{ width: '68%', height: '44px', margin: '0 10px', fontSize: '16px', color: '#333' }}>{text[0].value}</div>
                                                <img src={text[0].state === 1 ? excess : text[0].state === 2 ? drop : ''} alt=""></img>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>总价</div>
                                                <div className='ant-input' style={{ width: '68%', height: '44px', margin: '0 10px', fontSize: '16px', color: '#333' }}>{text[1].value}</div>
                                                <img src={text[1].state === 1 ? excess : text[1].state === 2 ? drop : ''} alt=""></img>
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '税率(%)',
                                dataIndex: 'tax',
                                key: 'tax',
                                width: '120px',
                            },
                            {
                                title: '利润率(%)',
                                dataIndex: 'profit',
                                key: 'profit',
                                width: '120px',
                                render: (text, record, index) => {
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '74%', margin: '0 10px', fontSize: !text ? '16px' : '22px', color: !text ? '#333333' : record.estimated_profit ? 'red' : '#00dc9e' }}>{text || '/'}</div>
                                            <img src={!text ? '' : record.estimated_profit ? excess : drop} alt=""></img>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '备  注',
                                dataIndex: 'info',
                                key: 'info',
                                width: '400px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            }
                        ]

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                            })
                        })

                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '选择中标供应商' || item.FieldTitle === '选择项目' || item.FieldTitle === '选择成本专员' || item.FieldTitle === '关联人材机下料单') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }

                        })

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }
                    }

                    // if (res.data.Detail.status === 4) {
                    //     form.table_id = tableId
                    //     form.sup_id = res.data.Detail.sup_id
                    //     form.project_id = res.data.Detail.project_id
                    //     Info.FirstInfo[1].isShow = false
                    //     Info.FirstInfo[1].amendTable.dataSource = []

                    //     res.data.Detail.content.forEach((item, index) => {
                    //         const value = {
                    //             key: index + 1,
                    //             id: item.id,
                    //             mac_id: item.mac_id,
                    //             name: item.name,
                    //             model: item.model,
                    //             unit: item.unit,
                    //             pur_unit_price: item.pur_unit_price,
                    //         }
                    //         Info.FirstInfo[1].amendTable.columns = [{
                    //             title: '',
                    //             dataIndex: 'key',
                    //             key: 'key',
                    //         },
                    //         {
                    //             title: '名称/工种',
                    //             dataIndex: 'name',
                    //             key: 'name',
                    //         },
                    //         {
                    //             title: '规格型号',
                    //             dataIndex: 'model',
                    //             key: 'model',
                    //             render: (text, record, index) => {
                    //                 return (
                    //                     <div title={record.model}>{text ? text.slice(0, 10) : ''}</div>
                    //                 )
                    //             }
                    //         },
                    //         {
                    //             title: '计量单位',
                    //             dataIndex: 'unit',
                    //             key: 'unit',
                    //         },
                    //         {
                    //             title: '采购价',
                    //             dataIndex: 'pur_unit_price',
                    //             key: 'pur_unit_price',
                    //         },
                    //         ]
                    //         if (item.updatePriceDetail.length) {
                    //             item.updatePriceDetail.forEach((em, ex) => {
                    //                 let dataIndex = 'price_' + (ex + 1)
                    //                 let title = {
                    //                     title: '第' + (ex + 1) + '次价格调整',
                    //                     dataIndex: dataIndex,
                    //                     key: dataIndex,
                    //                     render: (text, record, index) => {
                    //                         if (text) {
                    //                             return (
                    //                                 <Form.Item name={dataIndex + '_' + record.key} key={index} initialValue={text} onBlur={(e) => this.moneyChange(e, record.mac_id, dataIndex)}>
                    //                                     <Input disabled />
                    //                                 </Form.Item>
                    //                             )
                    //                         } else {
                    //                             return (
                    //                                 <Form.Item name={dataIndex + '_' + record.key} key={index} initialValue={text} onBlur={(e) => this.moneyChange(e, record.mac_id, dataIndex)}>
                    //                                     <Input />
                    //                                 </Form.Item>
                    //                             )
                    //                         }
                    //                     }
                    //                 }
                    //                 value[dataIndex] = em.price
                    //                 Info.FirstInfo[1].amendTable.columns = [...Info.FirstInfo[1].amendTable.columns, title]
                    //             })

                    //             let dataIndex = 'price_' + (item.updatePriceDetail.length + 1)
                    //             let title = {
                    //                 title: '第' + (item.updatePriceDetail.length + 1) + '次价格调整',
                    //                 dataIndex: dataIndex,
                    //                 key: dataIndex,
                    //                 render: (text, record, index) =>
                    //                     <Form.Item name={dataIndex + '_' + record.key} key={index} initialValue={text} onBlur={(e) => this.moneyChange(e, record.mac_id, dataIndex)}>
                    //                         <Input />
                    //                     </Form.Item>
                    //             }
                    //             value[dataIndex] = ''
                    //             Info.FirstInfo[1].amendTable.columns = [...Info.FirstInfo[1].amendTable.columns, title]

                    //         } else {
                    //             let title = {
                    //                 title: '第1次价格调整',
                    //                 dataIndex: 'price_01',
                    //                 key: 'price_01',
                    //                 render: (text, record, index) =>
                    //                     <Form.Item name={'price_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.moneyChange(e, record.mac_id, 'price_01')}>
                    //                         <Input />
                    //                     </Form.Item>
                    //             }
                    //             value.price_01 = ''
                    //             Info.FirstInfo[1].amendTable.columns = [...Info.FirstInfo[1].amendTable.columns, title]
                    //         }

                    //         Info.FirstInfo[1].amendTable.dataSource = [...Info.FirstInfo[1].amendTable.dataSource, value]

                    //     })
                    // }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.class === 1) {
                        Info.FirstInfo[0].plateName = '材料认价详情'
                    } else if (res.data.Detail.class === 2) {
                        Info.FirstInfo[0].plateName = '劳务认价详情'
                    } else if (res.data.Detail.class === 3) {
                        Info.FirstInfo[0].plateName = '机械认价详情'
                    } else if (res.data.Detail.class === 7) {
                        Info.FirstInfo[0].plateName = '零星材料认价详情'
                    } else {
                        Info.FirstInfo[0].plateName = '双包认价详情'
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '认价编号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '认价日期') {
                            item.value = res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : ''
                        } else if (item.FieldTitle === '认价类别') {
                            item.value = res.data.Detail.class
                        } else if (item.FieldTitle === '是否盖章') {
                            item.value = res.data.Detail.is_seal
                        } else if (item.FieldTitle === '中标供应商名称') {
                            item.value = res.data.Detail.supplierName
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManager
                        } else if (item.FieldTitle === '成本专员') {
                            item.value = res.data.Detail.costUser
                        } else if (item.FieldTitle === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    Info.FirstInfo[0].editable.dataSource = []

                    // form.pick_id = res.data.Detail.content[0].pick_id
                    res.data.Detail.content.forEach((item, index) => {
                        let key = index + 1
                        const newData = {
                            key: key,
                            id: item.mac_id,
                            // pick_id: item.pick_id,
                            name: item.name,
                            model: item.model,
                            unit: item.unit,
                            number: item.number,
                            budget: [item.unit_price, item.money],
                            contract: [item.award_price, item.con_money],
                            pur_unit_price: item.pur_unit_price,
                            pur_total_price: item.pur_total_price,
                            is_excess: res.data.Detail.class !== 7 ? item.is_excess : 0,
                            purchase: [item.pur_unit_price, item.pur_total_price],
                            procurement: [
                                {
                                    value: item.ver_unit_price,
                                    state: res.data.Detail.class !== 7 ? item.is_excess : 0
                                },
                                {
                                    value: item.ver_total_price,
                                    state: res.data.Detail.class !== 7 ? item.is_excess : 0
                                }
                            ],
                            tax: item.tax,
                            profit: item.profit,
                            estimated_profit: item.estimated_profit,
                            info: item.info,
                        };

                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'pur_unit_price' + key)()]: item.pur_unit_price,
                            [(() => 'info' + key)()]: item.info,
                        })

                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]

                        if (index === 0) {
                            Info.FirstInfo[0].list.forEach((em, dex) => {
                                if (em.FieldTitle === '税率(%)') {
                                    em.value = item.tax
                                }
                            })
                        }
                    })

                    if (res.data.Detail.total_estimated) {
                        Info.FirstInfo[0].calculate = {
                            label: '总利润率(%):',
                            value: res.data.Detail.total_estimated,
                            status: res.data.Detail.estimated_profit
                        }

                        Info.FirstInfo[0].all_total_price = {
                            label: '总采购价:',
                            value: res.data.Detail.all_total_price,
                        }

                        Info.FirstInfo[0].all_conMoney = {
                            label: '总合同价:',
                            value: res.data.Detail.all_conMoney,
                        }
                    }

                    // this.NewForm.current.formRef.current.setFieldsValue({
                    //     apply_date: moment(res.data.Detail.apply_date, 'YYYY-MM-DD'),
                    //     class: res.data.Detail.class,
                    //     is_seal: res.data.Detail.is_seal,
                    //     info: res.data.Detail.info,
                    //     enclosure: res.data.Detail.enclosure
                    // })

                    this.setState(preState => ({
                        Info,
                        form,
                        formKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.class = data.class
        form.is_seal = data.is_seal
        form.info = data.info
        form.content = Info.FirstInfo[0].editable.dataSource
        form.content = JSON.stringify(form.content)


        createSupConfirmPrice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select
                        Info.unSubmit = true

                        this.setState({
                            Info
                        })
                    } else {
                        Info.unSubmit = true
                        this.setState({
                            Info
                        })
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierSelect = () => {
        let { dataSource, pages, sup_class, sup_name, mat_class } = this.state
        let data = {
            class: sup_class,
            sup_name: sup_name,
            mat_class: mat_class,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : '机械',
                                mat_class: item.matClass,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                contacts: item.contacts,
                                pay_model: item.payModel,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutCostBudget = () => {
        let { dataSource, pages, name, form } = this.state
        // if (!form.pick_id) {
        //     message.error('请关联下料单单据!')
        //     return
        // }

        if (!form.project_id) {
            message.error('请选择项目名称!')
            return
        }
        let data = {
            // table_id: form.pick_id,
            project_id: form.project_id,
            class: form.class,
            name: name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutCostBudget(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.content.length) {
                        res.data.content.forEach((item, index) => {
                            let user = {
                                key: item.id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                one: item.one || '/',
                                two: item.two || '/',
                                three: item.three || '/',
                                budget: [item.unit_price, item.money],
                                contract: [item.award_price, item.con_money],
                                info: item.info,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectPickList = () => {
        let { dataSource, pages, pick_code, form } = this.state
        let data = {
            project_id: form.project_id,
            class: form.class,
            pick_code: pick_code,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectPickList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.pickLists.length) {
                        res.data.pickLists.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                proName: item.proName,
                                applyUsername: item.applyUsername,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : item.class === 3 ? '机械' : item.class === 7 ? '零星材料' : '双包',
                                created_at: item.created_at,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                radio = false
                this.get_seal()
            } else if (title === '选择中标供应商') {
                columns = [
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '供应商类型',
                        dataIndex: 'class',
                        key: 'class',
                    },
                    {
                        title: '所属分类',
                        dataIndex: 'mat_class',
                        key: 'mat_class',
                    },
                    {
                        title: '电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '联系人',
                        dataIndex: 'contacts',
                        key: 'contacts',
                    },
                    {
                        title: '付款方式',
                        dataIndex: 'pay_model',
                        key: 'pay_model',
                    },
                    {
                        title: '建档人',
                        dataIndex: 'apply_user',
                        key: 'apply_user',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }, {
                        FieldTitle: '供应商类型', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '选择器', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                        selectList: [
                            {
                                label: '材料',
                                value: 1
                            },
                            {
                                label: '劳务',
                                value: 2
                            },
                            {
                                label: '机械',
                                value: 3
                            }
                        ]
                    }, {
                        FieldTitle: '所属分类', //字段标题
                        fieldName: 'mat_class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }
                ]
                radio = true
                this.get_supplierSelect()
            } else if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                radio = true
                this.get_BlankingSelectProject()
            } else if (title === '选择成本专员') {
                columns = [
                    {
                        title: '姓名',
                        dataIndex: 'username',
                        key: 'username',
                    },
                    {
                        title: '性别',
                        dataIndex: 'sex',
                        key: 'sex',
                        render: (text, index) => {
                            if (text === '1') {
                                return (
                                    <span> 男 </span>
                                )
                            } else {
                                return (<span> 女 </span>
                                )
                            }
                        }
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'deName',
                        key: 'deName',
                    },
                    {
                        title: '职位',
                        dataIndex: 'company_job',
                        key: 'company_job',
                    },
                    {
                        title: '手机',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '部门', //字段标题
                        fieldName: 'de_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                radio = true
                this.get_InitialValue()
            }
            // else if (title === '关联人材机下料单') {
            //     columns = [
            //         {
            //             title: '项目名称',
            //             dataIndex: 'proName',
            //             key: 'proName',
            //         },
            //         {
            //             title: '提交人',
            //             dataIndex: 'applyUsername',
            //             key: 'applyUsername',
            //         },
            //         {
            //             title: '下料单类别',
            //             dataIndex: 'class',
            //             key: 'class',
            //         },
            //         {
            //             title: '时间',
            //             dataIndex: 'created_at',
            //             key: 'created_at',
            //         },
            //     ]
            //     TableInfo = [
            //         {
            //             FieldTitle: '下料单编码', //字段标题
            //             fieldName: 'pick_code', //字段名称
            //             fieldType: '文本', //字段类型
            //             disabled: false, //字段是否禁用
            //             mandatory: false, //字段是否必填
            //             required: '', //必填要求
            //             value: '',
            //             width: '95%',
            //         }
            //     ]
            //     radio = true
            //     this.get_selectProjectPickList()
            // }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                seal_name: '',
                sup_class: '',
                sup_name: '',
                mat_class: '',
                username: '',
                de_name: '',
                pro_name: '',
                name: '',
                pick_code: '',
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
            }))
        }
    }

    handleWorkAdd = () => {
        let { columns, TableInfo, tax } = this.state
        if (!tax) {
            message.error('请输入税率(%)')
            return
        }
        columns = [
            {
                title: '名称/工种',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '规格型号',
                dataIndex: 'model',
                key: 'model',
                render: (text, record, index) => {
                    return (
                        <div title={record.model}>{text ? text.slice(0, 10) : ''}</div>
                    )
                }
            },
            {
                title: '计量单位',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: '数量/工程量',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '一级',
                dataIndex: 'one',
                key: 'one',
            },
            {
                title: '二级',
                dataIndex: 'two',
                key: 'two',
            },
            {
                title: '三级',
                dataIndex: 'three',
                key: 'three',
            },
            {
                title: '备注',
                dataIndex: 'info',
                key: 'info',
            },
        ]
        TableInfo = [
            {
                FieldTitle: '名称/工种', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            },
        ]
        this.setState(preState => ({
            columns,
            TableInfo,
            buttonTitle: '',
            radio: false,
            FlowchartKey: Math.random(),
        }), () => {
            this.get_aboutCostBudget()
        })
    }

    handleRadio = (value, name) => {
        let { Info, form } = this.state;
        if (name === '是否盖章') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = false
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = false
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = true
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = true
                    }
                })
            }
        } else {
            form.class = value
            if (value === 1) {
                Info.FirstInfo[0].plateName = '材料认价详情'
            } else if (value === 2) {
                Info.FirstInfo[0].plateName = '劳务认价详情'
            } else if (value === 3) {
                Info.FirstInfo[0].plateName = '机械认价详情'
            } else if (value === 7) {
                Info.FirstInfo[0].plateName = '零星材料认价详情'
            } else {
                Info.FirstInfo[0].plateName = '双包认价详情'
            }
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle, tax } = this.state;
        if (buttonTitle === '选择印章') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        } else if (buttonTitle === '选择中标供应商') {
            form.sup_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '中标供应商名称') {
                    item.value = value[0].sup_name
                }
            })
        } else if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '选择成本专员') {
            form.cost_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '成本专员') {
                    item.value = value[0].username
                }
            })
        }
        // else if (buttonTitle === '关联人材机下料单') {
        //     form.pick_id = value[0].key
        //     Info.TableInfo.forEach((item, index) => {
        //         if (item.FieldTitle === '下料单单据') {
        //             item.value = value[0].key
        //         }
        //     })
        // } 
        else {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    value.forEach((em, ex) => {
                        if (em.key === item.id) {
                            value.splice(ex, 1)
                        }
                    })
                })
                value.forEach((item, index) => {
                    let key = Info.FirstInfo[0].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                        id: item.key,
                        // pick_id: form.pick_id,
                        name: item.name,
                        model: item.model,
                        unit: item.unit,
                        number: item.number,
                        budget: item.budget,
                        contract: item.contract,
                        pur_unit_price: '',
                        pur_total_price: '',
                        is_excess: 0,
                        purchase: ['', ''],
                        procurement: [
                            {
                                value: '',
                                state: 0
                            },
                            {
                                value: '',
                                state: 0
                            }
                        ],
                        tax: tax,
                        profit: '',
                        info: '',
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            } else {
                value.forEach((item, index) => {
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource.length + 1,
                        id: item.key,
                        // pick_id: form.pick_id,
                        name: item.name,
                        model: item.model,
                        unit: item.unit,
                        number: item.number,
                        budget: item.budget,
                        contract: item.contract,
                        pur_unit_price: '',
                        pur_total_price: '',
                        is_excess: 0,
                        purchase: ['', ''],
                        procurement: [
                            {
                                value: '',
                                state: 0
                            },
                            {
                                value: '',
                                state: 0
                            }
                        ],
                        tax: tax,
                        profit: '',
                        info: '',
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            }
        }

        this.setState(preState => ({
            Info,
            form,
            seal_name: '',
            sup_class: '',
            sup_name: '',
            mat_class: '',
            username: '',
            de_name: '',
            pro_name: '',
            name: '',
            pick_code: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleInput = (value, name) => {
        let { Info, form } = this.state
        if (name === '税率(%)') {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    let unit_price = Number(item.budget[0])
                    let money = Number(item.budget[1])
                    let Tax_rate = Number(value) / 100

                    //采购价（不含税）
                    let pro_unit_price = (item.purchase[0] / (1 + Tax_rate)).toFixed(2)
                    let pro_total_price = (Number(pro_unit_price) * Number(item.number)).toFixed(2)

                    item.procurement[0].value = pro_unit_price
                    item.procurement[1].value = pro_total_price

                    if (form.class !== 7) {
                        if (unit_price > pro_unit_price) {
                            item.procurement[0].state = 2
                            item.is_excess = 2
                        } else if (unit_price < pro_unit_price) {
                            item.procurement[0].state = 1
                            item.is_excess = 1
                        } else {
                            item.procurement[0].state = 0
                            item.is_excess = 0
                        }

                        if (money > pro_total_price) {
                            item.procurement[1].state = 2
                        } else if (money < pro_total_price) {
                            item.procurement[1].state = 1
                        } else {
                            item.procurement[1].state = 0
                        }
                    }
                })
            }

            this.setState(preState => ({
                tax: value,
                Info
            }), () => {
                if (Info.FirstInfo[0].editable.dataSource.length) {
                    Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                        this.get_dataSource(item.id)
                    })
                }
            })
        }
    }

    InputChange = (e, id, title) => {
        let { Info, form } = this.state;
        if (title === 'pur_unit_price') {
            Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {

                if (em.id === id) {
                    let unit_price = Number(em.budget[0])
                    let money = Number(em.budget[1])
                    let pur_unit_price = Number(e.target.value)
                    let pur_total_price = Number(e.target.value) * Number(em.number)

                    em.purchase[0] = pur_unit_price
                    em.purchase[1] = pur_total_price

                    em.pur_unit_price = pur_unit_price
                    em.pur_total_price = pur_total_price

                    if (em.tax) {
                        let Tax_rate = Number(em.tax) / 100

                        //采购价（不含税）
                        let pro_unit_price = (pur_unit_price / (1 + Tax_rate)).toFixed(2)
                        let pro_total_price = (Number(pro_unit_price) * Number(em.number)).toFixed(2)

                        em.procurement[0].value = pro_unit_price
                        em.procurement[1].value = pro_total_price

                        if (form.class !== 7) {
                            if (unit_price > pro_unit_price) {
                                em.procurement[0].state = 2
                                em.is_excess = 2
                            } else if (unit_price < pro_unit_price) {
                                em.procurement[0].state = 1
                                em.is_excess = 1
                            } else {
                                em.procurement[0].state = 0
                                em.is_excess = 0
                            }

                            if (money > pro_total_price) {
                                em.procurement[1].state = 2
                            } else if (money < pro_total_price) {
                                em.procurement[1].state = 1
                            } else {
                                em.procurement[1].state = 0
                            }
                        }
                    }
                }

            })

        }
        // else if (title === 'tax') {
        //     Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
        //         if (em.id === id) {
        //             let unit_price = Number(em.budget[0])
        //             let money = Number(em.budget[1])
        //             let Tax_rate = Number(e.target.value) / 100
        //             let pur_unit_price = Number(em.pur_unit_price)

        //             //采购价（不含税）
        //             let pro_unit_price = (pur_unit_price / (1 + Tax_rate)).toFixed(2)
        //             console.log(pro_unit_price)
        //             let pro_total_price = (Number(pro_unit_price) * Number(em.number)).toFixed(2)
        //             console.log(pro_total_price)
        //             em.procurement[0].value = pro_unit_price
        //             em.procurement[1].value = pro_total_price

        //             if (form.class !== 7) {
        //                 if (unit_price > pro_unit_price) {
        //                     em.procurement[0].state = 2
        //                     em.is_excess = 2
        //                 } else if (unit_price < pro_unit_price) {
        //                     em.procurement[0].state = 1
        //                     em.is_excess = 1
        //                 } else {
        //                     em.procurement[0].state = 0
        //                     em.is_excess = 0
        //                 }

        //                 if (money > pro_total_price) {
        //                     em.procurement[1].state = 2
        //                 } else if (money < pro_total_price) {
        //                     em.procurement[1].state = 1
        //                 } else {
        //                     em.procurement[1].state = 0
        //                 }
        //             }

        //             em.tax = e.target.value
        //         }
        //     })

        // } 
        else {
            Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
                if (em.id === id) {
                    em[title] = e.target.value
                }
            })
        }
        this.setState(preState => ({
            Info
        }), () => {
            if (title === 'pur_unit_price' || title === 'tax') {
                this.get_dataSource(id)
            }
        })
    }

    get_dataSource = (id) => {
        let { Info } = this.state;
        let dataSource = []
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (item.id === id) {
                const newData = {
                    key: item.key,
                    id: item.id,
                    // pick_id: item.pick_id,
                    name: item.name,
                    model: item.model,
                    unit: item.unit,
                    number: item.number,
                    budget: item.budget,
                    contract: item.contract,
                    purchase: item.purchase,
                    procurement: item.procurement,
                    pur_unit_price: item.pur_unit_price,
                    pur_total_price: item.pur_total_price,
                    is_excess: item.is_excess,
                    tax: item.tax,
                    profit: item.profit,
                    info: item.info,
                }
                dataSource = [...dataSource, newData]
            } else {
                dataSource = [...dataSource, item]
            }
        })
        console.log(dataSource)
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info
        }))
    }

    moneyChange = (e, id) => {
        this.setState(preState => ({
            mac_id: id,
            price: e.target.value,
            confirm_Modal: true
        }))
    }

    cancelModal = (e, id) => {
        this.setState(preState => ({
            confirm_Modal: false
        }))
    }

    SupUpdatePrice = () => {
        const { form, mac_id, price } = this.state;
        let data = {
            mac_id: mac_id,
            price: price,
            project_id: form.project_id,
            sup_id: form.sup_id,
        }
        addSupUpdatePrice(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState(preState => ({
                        confirm_Modal: false
                    }), () => {
                        this.get_SupConfirmPriceDetail(form.table_id)
                    })

                } else {
                    message.error(res.data[0] || '保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleCancel = () => {
        this.setState(preState => ({
            seal_name: '',
            sup_class: '',
            sup_name: '',
            mat_class: '',
            username: '',
            de_name: '',
            pro_name: '',
            name: '',
            pick_code: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        this.setState({
            seal_name: value.seal_name,
            sup_class: value.class,
            sup_name: value.sup_name,
            mat_class: value.mat_class,
            username: value.username,
            de_name: value.de_name,
            pro_name: value.pro_name,
            name: value.name,
            pick_code: value.pick_code,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            }
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '选择中标供应商') {
                this.get_supplierSelect()
            } else if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择成本专员') {
                this.get_InitialValue()
            }
            // else if (buttonTitle === '关联人材机下料单') {
            //     this.get_selectProjectPickList()
            // } 
            else {
                this.get_aboutCostBudget()
            }
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '选择中标供应商') {
                this.get_supplierSelect()
            } else if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择成本专员') {
                this.get_InitialValue()
            }
            // else if (buttonTitle === '关联人材机下料单') {
            //     this.get_selectProjectPickList()
            // } 
            else {
                this.get_aboutCostBudget()
            }
        })
    }

    handleSave = () => {
        console.log('保存')
    }

    // handleSearch = () => {
    //     let { form } = this.state
    //     if (!form.pick_id) {
    //         message.error('未关联下料单单据!')
    //         return
    //     }
    //     this.props.history.push({
    //         pathname: '/project/blanking/NewItem',
    //         query: {
    //             'tableId': form.pick_id,
    //         }
    //     })
    // }

    handleExport = () => {
        let { form } = this.state
        let data = {
            confirmPrice_id: form.table_id
        }
        axios.get('/api/SupConfirmPriceLeadingOut', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                })

                const link = document.createElement('a');
                link.download = '供应商认价审批.xlsx'
                link.href = URL.createObjectURL(blob)
                link.target = "_blank"
                document.body.appendChild(link)
                link.click();
                //释放URL对象
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            } else {
                message.error('数据参数错误!')
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'pur_unit_price' + e)()]: '',
            [(() => 'info' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, formKey, modal_Visible, dataSource, columns, TableInfo, pages, confirm_Modal, radio, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={formKey} ref={this.NewForm} Info={Info} exportEvent={this.handleExport} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} rowAddEvent={this.handleWorkAdd} saveEvent={this.handleSave} InputEvent={this.handleInput} searchEvent={this.handleSearch} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />


                <Modal
                    title='是否保存价格'
                    centered
                    visible={confirm_Modal}
                    onOk={() => this.SupUpdatePrice()}
                    onCancel={() => this.cancelModal()}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    保存价格后不可删除！
                </Modal>
            </div>
        )
    }
}
