import React, { Component } from 'react'
import { Input } from 'antd';

export default class UserInput extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.value) {
    //         return { value: props.value };
    //     } else {
    //         return null;
    //     }
    // }

    render() {
        let { disabled,placeholder, fieldType,fileName, value,onChange,InputEvent } = this.props;
        return (
            <div>
                <Input
                    type={fieldType}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={(e) => {
                        onChange(e.target.value)
                        if(fileName==='本月完成产值(元)' || fileName==='进度款支付比例(%)' || fileName==='本次核销借款(元)' || fileName==='本次实付金额(元)'|| fileName==='税率(%)'|| fileName==='本期计量审定金额(元)'|| fileName==='本期应付金额(元)'){
                            InputEvent(e.target.value,fileName)
                        }
                    }}                 
                    value={value}
                />
            </div>
        )
    }
}
