import React, { Component } from 'react'
import moment from 'moment';
import Big from 'big.js';
import { Form, Select, Input, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import amountInWords from '../../../../components/amountInWords'
import { createPatentRenewal, PatentRenewalDetail, KnowledgeSelectList } from "../../../../utils/public"
import { reviewApplyResult } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/patentRenewal.docx',
            templateName: '四川天艺生态园林集团股份有限公司费用报销单',
            tempData: {
                pay_unit: '',
                con_code: '',
                apply_date: '',
                user_name: '',
                con_name: '',
                pay_money: '',
                company_name:'',
                bank:'',
                bank_number:'',
                con_type: '',
                unit_name: '',
                invo_type: '',
                money: '',
                replenish_invo: '',
                info: '',
                results: []
            },
            FlowModelName: "专利续费付款申请",
            TableInfo: [{
                FieldTitle: '申请日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择申请日期', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同类别', //字段标题
                fieldName: 'con_type', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同名称', //字段标题
                fieldName: 'con_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同编号', //字段标题
                fieldName: 'con_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '付款金额(元)', //字段标题
                fieldName: 'pay_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入付款金额', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '公司名称', //字段标题
                fieldName: 'company', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入公司名称', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '开户行', //字段标题
                fieldName: 'bank_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入开户行', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '银行账号', //字段标题
                fieldName: 'bank_num', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入银行账号', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '付款单位', //字段标题
                fieldName: 'pay_unit', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择报销单位', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                    { value: 2, label: "四川天艺优境环境科技有限公司" },
                    { value: 3, label: "四川天艺致远物联网科技有限公司" },
                    { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                ]
            }, {
                FieldTitle: '是否欠票', //字段标题
                fieldName: 'has_owing', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择是否欠票', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ]
            },
            {
                FieldTitle: '欠票单位', //字段标题
                fieldName: 'unit_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票类型', //字段标题
                fieldName: 'invo_type', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票金额', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票原因', //字段标题
                fieldName: 'cause', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '补票时间', //字段标题
                fieldName: 'replenish_invo', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [{
                plateName: '专利续费明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        // {
                        //     title: '',
                        //     dataIndex: 'key',
                        //     key: 'key',
                        //     width: '100px'
                        // },
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, 0, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '成果名称',
                            dataIndex: 'ach_name',
                            key: 'ach_name',
                        },
                        {
                            title: '专利类型',
                            dataIndex: 'ach_class',
                            key: 'ach_class',
                        },
                        {
                            title: '专利领域',
                            dataIndex: 'ach_area',
                            key: 'ach_area',
                        },
                        {
                            title: '续费金额(元)',
                            dataIndex: 'renewal',
                            key: 'renewal',
                            render: (text, record, index) => {
                                return (
                                    <Form.Item name={'renewal' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.patent_id)}>
                                        <Input />
                                    </Form.Item>
                                )
                            }

                        }
                    ],
                    summary: true,
                    summaryValue: {
                        colspan: 6,
                        name: '续费总额(元)',
                        value: 0
                    }
                },
            }, {
                plateName: '',
                list: [{
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },],
            },
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '成果名称',
                dataIndex: 'ach_name',
                key: 'ach_name',
            },
            {
                title: '专利类型',
                dataIndex: 'ach_class',
                key: 'ach_class',
            },
            {
                title: '成果领域',
                dataIndex: 'ach_area',
                key: 'ach_area',
            },
        ],
        TableInfo: [{
            FieldTitle: '成果名称', //字段标题
            fieldName: 'ach_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '95%',
        }],
        ach_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_sementDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info,
        })
        )
    }

    get_sementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        PatentRenewalDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[0].editable = {
                            dataSource: [],
                            columns: [
                                {
                                    title: '序号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: '80px',
                                    render: (text, record, index) => {
                                        return (
                                            <div>{index + 1}</div>
                                        )
                                    }
                                },
                                {
                                    title: '成果名称',
                                    dataIndex: 'ach_name',
                                    key: 'ach_name',
                                },
                                {
                                    title: '专利类型',
                                    dataIndex: 'ach_class',
                                    key: 'ach_class',
                                },
                                {
                                    title: '专利领域',
                                    dataIndex: 'ach_area',
                                    key: 'ach_area',
                                },
                                {
                                    title: '续费金额(元)',
                                    dataIndex: 'renewal',
                                    key: 'renewal',
                                    render: (text, record, index) => {
                                        return (
                                            <Form.Item name={'renewal' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }

                                }
                            ],
                            summary: true,
                            summaryValue: {
                                colspan: 5,
                                name: '续费总额(元)',
                                value: 0
                            }
                        }

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            item.disabled = true
                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.Detail.status === 4) {
                            Info.tempData.pay_unit = res.data.Detail.pay_unit === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.pay_unit === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.pay_unit === 3 ? '四川天艺致远物联网科技有限公司' : res.data.Detail.pay_unit === 4 ? '四川天艺传奇生态茶业有限公司' : ''
                            Info.tempData.con_code = res.data.Detail.con_code || '/'
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.con_name = res.data.Detail.con_name || '/'
                            Info.tempData.pay_money = res.data.Detail.pay_money || 0
                            Info.tempData.con_type = res.data.Detail.con_type || '/'
                            Info.tempData.company_name = res.data.Detail.company
                            Info.tempData.bank = res.data.Detail.bank_name
                            Info.tempData.bank_number = res.data.Detail.bank_num
                            Info.tempData.unit_name = res.data.Detail.has_owing === 1 ? res.data.Detail.unit_name : '/'
                            Info.tempData.invo_type = res.data.Detail.has_owing === 1 ? res.data.Detail.invo_type : '/'
                            Info.tempData.money = res.data.Detail.has_owing === 1 ? res.data.Detail.money : '/'
                            Info.tempData.cause = res.data.Detail.has_owing === 1 ? res.data.Detail.cause : '/'
                            Info.tempData.replenish_invo = res.data.Detail.has_owing === 1 ? res.data.Detail.replenish_invo : '/'
                            Info.tempData.results = []
                            Info.tempData.info = res.data.Detail.info || ''

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.has_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '欠票单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票原因') {
                                item.isShow = false
                            } else if (item.FieldTitle === '补票时间') {
                                item.isShow = false
                            }
                        })
                    }

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    let total_money = 0
                    Info.FirstInfo[0].editable.dataSource = []
                    res.data.Detail.details.forEach((item, index) => {
                        let key = Info.FirstInfo[0].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            patent_id: item.patent_id,
                            ach_area: item.ach_area,
                            ach_class: item.ach_class,
                            ach_name: item.ach_name,
                            renewal: item.renewal
                        };
                        // total_money = (Number(item.renewal) * 10000 + total_money * 10000) / 10000
                        total_money = Big(Number(item.renewal)).plus(Number(total_money)).toFixed(2);
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'renewal' + key)()]: item.renewal,
                        })
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                    })
                    Info.FirstInfo[0].editable.summaryValue.value = total_money


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        con_type: res.data.Detail.con_type,
                        con_name: res.data.Detail.con_name,
                        con_code: res.data.Detail.con_code,
                        pay_money: res.data.Detail.pay_money,
                        bank_name: res.data.Detail.bank_name,
                        bank_num: res.data.Detail.bank_num,
                        company: res.data.Detail.company,
                        pay_unit: res.data.Detail.pay_unit,
                        has_owing: res.data.Detail.has_owing,
                        unit_name: res.data.Detail.unit_name || '',
                        invo_type: res.data.Detail.invo_type || '',
                        money: res.data.Detail.money || '',
                        cause: res.data.Detail.cause || '',
                        replenish_invo: res.data.Detail.replenish_invo ? moment(res.data.Detail.replenish_invo, 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_KnowledgeSelectList = () => {
        let { dataSource, pages, ach_name } = this.state
        let data = {
            ach_name: ach_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        KnowledgeSelectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.technology.length) {
                        res.data.technology.forEach((item, index) => {
                            let value = {
                                key: item.table_id,
                                ach_name: item.ach_name,
                                ach_area: item.ach_area === 1 ? '立体绿化' : item.ach_area === 2 ? '园林绿化' : item.ach_area === 3 ? '植物培育' : item.ach_area === 4 ? '水生态治理' : item.ach_area === 5 ? '土壤修复' : item.ach_area === 6 ? 'BIM' : '茶叶',
                                ach_class: item.ach_class === 5 ? '实用新型专利' : item.ach_class === 6 ? '发明专利' : '外观专利',
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.con_type = data.con_type
        form.con_name = data.con_name
        form.con_code = data.con_code
        form.pay_money = data.pay_money
        form.bank_name = data.bank_name
        form.bank_num = data.bank_num
        form.company = data.company
        form.info = data.info
        form.pay_unit = data.pay_unit
        form.has_owing = data.has_owing
        form.unit_name = data.unit_name || ''
        form.invo_type = data.invo_type || ''
        form.money = data.money || ''
        form.cause = data.cause || ''
        form.replenish_invo = data.replenish_invo ? data.replenish_invo.format('YYYY-MM-DD') : ''
        form.details = Info.FirstInfo[0].editable.dataSource
        form.details = JSON.stringify(Info.FirstInfo[0].editable.dataSource)

        createPatentRenewal(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = () => {
        this.get_KnowledgeSelectList()
    }

    handButton = (title, state) => {
        if (!state) {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info } = this.state

        if (Info.FirstInfo[0].editable.dataSource.length) {
            Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                value.forEach((em, ex) => {
                    if (em.key === item.user_id) {
                        value.splice(ex, 1)
                    }
                })
            })

            value.forEach((item, index) => {
                let key = Info.FirstInfo[0].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                    patent_id: item.key,
                    ach_name: item.ach_name,
                    ach_class: item.ach_class,
                    ach_area: item.ach_area,
                    renewal: ''
                };

                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
            })
        } else {
            value.forEach((item, index) => {
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource.length + 1,
                    patent_id: item.key,
                    ach_name: item.ach_name,
                    ach_class: item.ach_class,
                    ach_area: item.ach_area,
                    renewal: ''
                };

                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
            })
        }
        this.setState(preState => ({
            Info,
            modal_Visible: false,
            ach_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        }))
    }

    handleRadio = (value, title) => {
        let { Info } = this.state;
        if (title === '是否欠票') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = false
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = true
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = true
                    }
                })
            }
        }
        this.setState(preState => ({
            Info
        }))
    }


    InputChange = (e, id) => {
        let { Info } = this.state;
        let total_money = 0

        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.patent_id === id) {
                // total_money = (Number(e.target.value) * 10000 + total_money * 10000) / 10000
                total_money = Big(Number(e.target.value)).plus(Number(total_money)).toFixed(2);
                em.renewal = e.target.value
            } else {
                if (em.renewal) {
                    // total_money = (Number(em.renewal) * 10000 + total_money * 10000) / 10000
                    total_money = Big(Number(em.renewal)).plus(Number(total_money)).toFixed(2);
                }
            }
        })

        Info.FirstInfo[0].editable.summaryValue.value = total_money



        this.setState(preState => ({
            Info
        }))
    }

    searchQuery = (value) => {
        this.setState({
            ach_name: value.ach_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_KnowledgeSelectList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            ach_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_KnowledgeSelectList()
        })
    }

    handleDel = (key, state, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'renewal' + e)()]: '',
        })
        Info.FirstInfo[state].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[state].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} rowAddEvent={this.handleWorkAdd} fileEvent={this.handFile} radioEvent={this.handleRadio} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={false} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
