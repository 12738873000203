import React, { Component } from 'react'
import { Input, Form, message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createPartTime, partTimeWagesDetails } from "../../../../utils/oa"
import { selectProjectName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
	state = {
		Info: {
			state: '',
			FlowModelName: "临时用工工资表",
			TableInfo: [{
				FieldTitle: '用工日期', //字段标题
				fieldName: 'applyDate', //字段名称
				fieldType: '日期', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请选择用工日期', //必填要求
				value: '',
				width: '50%'
			},],
			FirstInfo: [{
				plateName: '工资表',
				list: [],
				editable: {
					scroll: true,
					scrollWidth: '2500px',
					dataSource: [],
					columns: [
						// {
						// 	title: '',
						// 	dataIndex: 'key',
						// 	key: 'key',
						// 	width: '80px'
						// },
						{
							title: '序号',
							dataIndex: 'id',
							key: 'id',
							width: '80px',
							render: (text, record, index) => {
								return (
									<div>{index + 1}</div>
								)
							}
						},
						{
							title: '操作',
							dataIndex: 'controls',
							key: 'controls',
							width: '100px',
							render: (text, record, index) => {
								return (
									<div className='download' onClick={() => {
										this.handleDel(index, record.key)
									}}>
										删除
									</div>
								)
							}
						},
						{
							title: '项目名称',
							dataIndex: 'project_name',
							key: 'project_name',
							width: '200px'
						},
						{
							title: '姓名',
							dataIndex: 'username',
							key: 'username',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'username' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'username' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '身份证号码',
							dataIndex: 'card',
							key: 'card',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'card' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'card' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '手机',
							dataIndex: 'phone',
							key: 'phone',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'phone' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'phone' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '所属月份',
							dataIndex: 'month',
							key: 'month',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'month' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'month' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '计时工资(元)',
							dataIndex: 'unit_price',
							key: 'unit_price',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'unit_price' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'unit_price' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '用工天数',
							dataIndex: 'days',
							key: 'days',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'days' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'days' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '实发工资',
							dataIndex: 'money',
							key: 'money',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'money' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'money' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '备注(银行卡号)',
							dataIndex: 'info',
							key: 'info',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'info' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'info' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '签字',
							dataIndex: 'sign',
							key: 'sign',
							width: '120px'
						},
					]
				},
			}],
			process: {
				reviewAudit: [],
				ccReading: [],
				audit: false,
				edit: false
			}
		},
		FlowchartKey: 1,
		modal_Visible: false,
		dataSource: [],
		columns: [{
			title: '项目名称',
			dataIndex: 'pro_name',
			key: 'pro_name',
		},
		{
			title: '项目编号',
			dataIndex: 'pro_number',
			key: 'pro_number',
		},
		{
			title: '项目经理',
			dataIndex: 'managerName',
			key: 'managerName',
		},
		{
			title: '项目地址',
			dataIndex: 'pro_address',
			key: 'pro_address',
		},
		],
		TableInfo: [{
			FieldTitle: '项目名称', //字段标题
			fieldName: 'pro_name', //字段名称
			fieldType: '文本', //字段类型
			disabled: false, //字段是否禁用
			mandatory: false, //字段是否必填
			required: '', //必填要求
			value: '',
			width: '95%',
		}],
		pro_name: '',
		pages: {
			page: 1,
			dataNumber: 1,
			limit: 10,
		},
		form: {}
	}

	NewForm = React.createRef();

	componentDidMount() {
		if (this.props.location.query) {
			let tableId = this.props.location.query.tableId
			this.get_partTimeWagesDetails(tableId)
		} else {
			this.get_FormState()
		}
	}

	get_FormState = () => {
		const { Info } = this.state;
		Info.state = 1
		this.setState(preState => ({
			Info
		}))
	}

	get_partTimeWagesDetails = (tableId) => {
		const { Info, form } = this.state;
		let data = {
			table_id: tableId
		}
		partTimeWagesDetails(data).then(
			(res) => {
				if (!res.code) {
					if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
						form.table_id = tableId
						form.partTimes = res.data.Detail.details
						Info.process.edit = res.data.Detail.editPermission
					} else {
						Info.TableInfo.forEach((item, index) => {
							item.disabled = true
						})

						Info.FirstInfo[0].editable.columns = [
							{
								title: '序号',
								dataIndex: 'id',
								key: 'id',
								width: '80px',
								render: (text, record, index) => {
									return (
										<div>{index + 1}</div>
									)
								}
							},
							{
								title: '项目名称',
								dataIndex: 'project_name',
								key: 'project_name',
								width: '200px'
							},
							{
								title: '姓名',
								dataIndex: 'username',
								key: 'username',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'username' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'username' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '身份证号码',
								dataIndex: 'card',
								key: 'card',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'card' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'card' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '手机',
								dataIndex: 'phone',
								key: 'phone',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'phone' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'phone' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '所属月份',
								dataIndex: 'month',
								key: 'month',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'month' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'month' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '计时工资(元)',
								dataIndex: 'unit_price',
								key: 'unit_price',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'unit_price' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'unit_price' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '用工天数',
								dataIndex: 'days',
								key: 'days',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'days' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'days' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '实发工资',
								dataIndex: 'money',
								key: 'money',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'money' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'money' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '备注(银行卡号)',
								dataIndex: 'info',
								key: 'info',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'info' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'info' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '签字',
								dataIndex: 'sign',
								key: 'sign',
								width: '120px'
							},
						]
					}

					Info.state = res.data.Detail.status
					Info.process.data = res.data.Detail.reviewAudit
					Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
					Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
					Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

					res.data.Detail.details.forEach((item, index) => {
						let key = Info.FirstInfo[0].editable.dataSource.length + 1
						const newData = {
							key: key,
							project_id: item.project_id,
							project_name: item.projectName,
							username: item.username,
							card: item.id_card,
							phone: item.phone,
							month: item.month,
							unit_price: item.unit_price,
							days: item.days,
							money: item.money,
							info: item.info,
						};

						this.NewForm.current.formRef.current.setFieldsValue({
							[(() => 'username' + key)()]: item.username,
							[(() => 'card' + key)()]: item.id_card,
							[(() => 'phone' + key)()]: item.phone,
							[(() => 'month' + key)()]: item.month,
							[(() => 'unit_price' + key)()]: item.unit_price,
							[(() => 'days' + key)()]: item.days,
							[(() => 'money' + key)()]: item.money,
							[(() => 'info' + key)()]: item.info,
						})

						Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
					})

					this.NewForm.current.formRef.current.setFieldsValue({
						applyDate: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
					})

					this.setState(preState => ({
						Info,
						form
					}))
				} else {
					message.error(res.data[0] || '数据获取失败!')
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}


	get_ProjectName = () => {
		let {
			dataSource,
			pages,
			pro_name
		} = this.state
		let data = {
			pro_name: pro_name,
			page: Number(pages.page),
			limit: Number(pages.limit)
		}
		selectProjectName(data).then(
			(res) => {
				if (!res.code) {
					dataSource = []
					if (res.data.projects.length) {
						res.data.projects.forEach((item, index) => {
							let user = {
								key: item.table_id,
								pro_name: item.pro_name,
								pro_number: item.pro_number,
								managerName: item.managerName,
								pro_address: item.pro_address,
							}
							dataSource = [...dataSource, user]
						})
						this.setState(preState => ({
							dataSource,
							pages: res.data.pages,
							modal_Visible: true,
						}))
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}


	handPresent = (data, status) => {
		const { form, Info } = this.state;
		form.status = status
		form.applyDate = data.applyDate ? data.applyDate.format('YYYY-MM-DD') : ''

		form.partTimes.forEach((item, index) => {
			item.username = data['username' + item.key] ? data['username' + item.key] : ''
			item.id_card = data['card' + item.key] ? data['card' + item.key] : ''
			item.phone = data['phone' + item.key] ? data['phone' + item.key] : ''
			item.month = data['month' + item.key] ? data['month' + item.key] : ''
			item.unit_price = data['unit_price' + item.key] ? data['unit_price' + item.key] : ''
			item.days = data['days' + item.key] ? data['days' + item.key] : ''
			item.money = data['money' + item.key] ? data['money' + item.key] : ''
			item.info = data['info' + item.key] ? data['info' + item.key] : ''
		})

		form.partTimes = JSON.stringify(form.partTimes)

		createPartTime(form).then(
			(res) => {
				if (!res.code) {
					message.success(form.status === 1 ? '暂存成功' : '提交成功')
					// if (form.status === 2) {
					// 	setTimeout(() => {
					// 		window.history.back(-1)
					// 	})
					// }
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '提交失败,请检查表单!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handButton = (title, state) => {
		if (state) {
			this.get_ProjectName()
		} else {
			this.setState(preState => ({
				pages: {
					page: 1,
					total: 0,
					limit: 10,
				},
				modal_Visible: false,
			}))
		}
	}

	handleWorkAdd = (name) => {
		this.setState({
			FlowchartKey: Math.random(),
		}, () => {
			this.get_ProjectName()
		})
	}

	handSelect = (value) => {
		let { Info, form } = this.state
		if (Info.FirstInfo[0].editable.dataSource.length) {
			Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
				value.forEach((em, ex) => {
					if (em.key === item.project_id) {
						value.splice(ex, 1)
					}
				})

				value.forEach((item, index) => {
					let key = Info.FirstInfo[0].editable.dataSource.length - 1
					const newData = {
						key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
						project_id: item.key,
						project_name: item.pro_name,
						username: '',
						card: '',
						phone: '',
						month: '',
						unit_price: '',
						days: '',
						money: '',
						info: '',
					};

					Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
				})
			})
		} else {
			value.forEach((item, index) => {
				const newData = {
					key: Info.FirstInfo[0].editable.dataSource.length + 1,
					project_id: item.key,
					project_name: item.pro_name,
					username: '',
					card: '',
					phone: '',
					month: '',
					days: '',
					money: '',
					info: '',
				};

				Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
			})
		}

		form.partTimes = []

		Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
			let value = {
				project_id: item.project_id,
				id: '',
				sign: '',
			}
			form.partTimes.push(value)
		})

		this.setState(preState => ({
			Info,
			form,
			pages: {
				page: 1,
				total: 0,
				limit: 10,
			},
			modal_Visible: false,
		}))
	}

	searchQuery = (value) => {
		this.setState({
			pro_name: value.pro_name,
			pages: {
				page: 1,
				total: 0,
				limit: 10,
			},
		}, () => {
			this.get_ProjectName()
		})
	}

	handleCancel = () => {
		this.setState({
			modal_Visible: false,
			pro_name: '',
			pages: {
				limit: 10,
				page: 1,
				total: 0
			},
		})
	}

	handleButton = (type, value) => {
		let {
			Info
		} = this.state
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		let data = {
			tableName: '',
			table_id: '',
			reviewResult: type,
			opinion: value,
			review_id: '',
		}

		Info.process.reviewAudit.forEach((item, index) => {
			if (item.user_id === userInfo.userId) {
				data.tableName = item.table_name
				data.table_id = item.table_id
				data.review_id = item.id
			}
		})
		reviewApplyResult(data).then(
			(res) => {
				if (!res.code) {
					message.success('审核成功!')
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '审核失败!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handleLimit = (page, limit) => {
		let {
			pages
		} = this.state
		pages.page = page
		pages.limit = limit
		this.setState({
			pages
		}, () => {
			this.get_ProjectName()
		})
	}

	handleDel = (key, e) => {
		let { Info } = this.state
		let dataSource = []
		this.NewForm.current.formRef.current.setFieldsValue({
			[(() => 'username' + e)()]: '',
			[(() => 'card' + e)()]: '',
			[(() => 'phone' + e)()]: '',
			[(() => 'month' + e)()]: '',
			[(() => 'unit_price' + e)()]: '',
			[(() => 'days' + e)()]: '',
			[(() => 'money' + e)()]: '',
			[(() => 'info' + e)()]: '',
		})
		Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
			if (index !== key) {
				dataSource = [...dataSource, item]
			}
		})
		Info.FirstInfo[0].editable.dataSource = dataSource
		this.setState(preState => ({
			Info,
		})
		)
	}

	render() {
		let { Info, modal_Visible, dataSource, columns, TableInfo, pages, FlowchartKey } = this.state
		return (
			<div className='configAdd' >
				<AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} />

				<InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} auditEvent={this.handleButton} />
			</div>
		)
	}
}
