import React, { Component } from 'react'
import { Checkbox, message } from 'antd';
import axios from 'axios'
import { EyeOutlined, DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { fileDownload, filePreview, BulkDownload } from "../utils/api"

export default class UserAttach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],     //文件列表
        };
    }

    componentDidMount() {
        // console.log(this.props.fileValue)
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.table_id
        }
        axios.get('/api/fileDownload', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = file.filename
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
        // fileDownload(data).then(
        //     (res) => {
        //         if (!res.code) {
        //             let link = document.createElement("a")
        //             link.download = file.filename
        //             link.href = res.data.path
        //             if (file.fileType === 'jpg' || file.fileType === 'png' || file.fileType === 'jpeg' || file.fileType === 'pdf') {
        //                 link.target = "_blank"
        //             }
        //             link.click()
        //         } else {
        //             message.error(res.data.data[0] || '预览失败')
        //         }
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    checkChange = (value) => {
        this.setState(preState => ({
            fileList: value
        })
        )
    };

    batch_download = () => {
        let { fileList } = this.state
        if (!fileList.length) {
            message.error('请选择下载文件')
            return
        }
        let data = {
            file_ids: JSON.stringify(fileList)
        }
        BulkDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.href = res.data.path
                    link.download = res.data.fileName; // 设置新的文件名
                    console.log(res.data.fileName)
                    document.body.appendChild(link);
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        const { fileValue } = this.props
        return (
            <div className='uploadList'>
                <Checkbox.Group
                    style={{
                        width: '100%',
                    }}
                    onChange={this.checkChange}
                >
                    {
                        fileValue.length ? fileValue.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div></div>
                                    <div>
                                        <div>
                                            <Checkbox value={item.table_id} style={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: '#333333',
                                            }}><span title={item.filename}>{item.filename ? item.filename.slice(0, 20) : ''}</span></Checkbox>
                                        </div>
                                        <div>
                                            <div>{item.username}于{item.created_at}上传</div>
                                            <div>
                                                <EyeOutlined style={{ color: "#339EF9", marginRight: '10px' }} onClick={() => {
                                                    this.get_filePreview(item)
                                                }} />
                                                {
                                                    item.is_download === 1 ? <DownloadOutlined style={{ color: "#339EF9" }} onClick={() => {
                                                        this.get_download(item)
                                                    }} /> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : ''
                    }
                </Checkbox.Group>
                {
                    fileValue.length ? <div className='batch' style={{ cursor: 'pointer' }} onClick={() => {
                        this.batch_download()
                    }}>
                        <PaperClipOutlined style={{ color: "#339EF9", marginRight: '10px' }} />
                        <div>批量下载</div>
                    </div> : ''
                }
            </div>
        )
    }
}
