import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import Search from '../../components/Search'
import { FinanceReApproval } from "../../../utils/api"


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '报销人',
                    dataIndex: 'username',
                    key: 'username',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '提单日期',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
                {
                    title: '业务部门',
                    dataIndex: 'bus_de',
                    key: 'bus_de',
                },
                {
                    title: '项目名称',
                    dataIndex: 'projectName',
                    key: 'projectName',
                },
                {
                    title: '费用类型',
                    dataIndex: 'class',
                    key: 'class',
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div>综合费用</div>
                            )
                        } else if (text === 2) {
                            return (
                                <div>科研费用</div>
                            )
                        } else if (text === 3) {
                            return (
                                <div>项目费用</div>
                            )
                        }
                    }
                },
                {
                    title: '报销金额(元)',
                    dataIndex: 'total_money',
                    key: 'total_money',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    render: (text, index) => {
                        if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            TableInfo: [              
                {
                    FieldTitle: '报销人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            username:'',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_FinanceReApproval()
    }

    get_FinanceReApproval = () => {
        let { dataSource, pages, username } = this.state
        let data = {
            username: username,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        FinanceReApproval(data).then(
            (res) => {
                if (!res.code) {
                    console.log(res)
                    dataSource = []
                    if (res.data.reimbursements.length) {
                        res.data.reimbursements.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.username,
                                apply_date: item.apply_date,
                                bus_de: item.deName,
                                projectName: item.proName||'/',
                                class: item.class,
                                total_money: item.total_money,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {}

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/payment/reimbursement/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            username: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_FinanceReApproval()
        })
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
        }, () => {
            this.get_FinanceReApproval()
        })
    }

   

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_FinanceReApproval()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_FinanceReApproval()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} dontAdd={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchEvent={this.handleSearch} searchShow={searchShow} />
                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }
                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
