import React, { Component } from 'react'
import axios from 'axios'
import md5 from 'md5'
import { Upload, message, Modal, Progress } from 'antd';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { deleteFile } from "../utils/api"
import attachImg from "../accets/img/attach.png"

export default class UserAttach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],//存放上传信息：比如路径、文件名
            storeList: [],//存放回显信息：修改时的路径、文件名
            file: {},
            fileChunkList: [],
            is_download: 1,
            isProgress: false,
            percent: 0,
            fileMd5: '',
        };
    }

    upload = React.createRef();

    static getDerivedStateFromProps(props, state) {
        // console.log(props)
        if (props.fileValue) {
            let fileList = []
            let storeList = []
            if (props.fileValue.length) {
                props.fileValue.forEach((item, index) => {
                    let fileValue = {
                        name: item.filename,
                        att_id: item.table_id,
                        path: ''
                    }
                    fileList.push(fileValue)
                    storeList.push(item.table_id)
                })
            }
            return {
                fileList: fileList,
                storeList: storeList
            }
        } else {
            return null
        }
    }

    updateChange = ({ file, fileList }) => {
        let _this = this
        Modal.confirm({
            title: '',
            content: '是否允许文件下载',
            okText: '是',
            cancelText: '否',
            onOk() {
                _this.setState(preState => ({
                    file: file,
                    is_download: 1
                }), () => {
                    _this.createFileChunk()
                })
            },
            onCancel() {
                _this.setState(preState => ({
                    file: file,
                    is_download: 2
                }), () => {
                    _this.createFileChunk()
                })
            },
        })
    }

    createFileChunk = () => {
        let { file, fileChunkList, isProgress } = this.state
        let chunkSize = 1024 * 1024  // 切片每次1M
        let filecount = Math.ceil(file.size / chunkSize)
        let currentChunk = 0 // 当前上传的chunk
        let index = 0
        fileChunkList = []
        while (currentChunk < file.size) {
            const chunk = file.slice(currentChunk, currentChunk + chunkSize);
            fileChunkList.push({ file: chunk, index, filecount }); // 可以加入很多信息。比如MD5
            currentChunk += chunkSize;
            index += 1;
        }

        if (filecount > 5) {
            isProgress = true
        }

        fileChunkList.forEach((item, index) => {
            if (index === 0) {
                let reader = new FileReader()
                let fileDate = item.file
                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    this.setState(preState => ({
                        fileChunkList,
                        isProgress,
                        fileMd5: md5(e.target.result)
                    }), () => {
                        this.fileUpload(0)
                    })
                }
            }
        })


    }

    fileUpload = (uploadIndex) => {
        let { file, fileChunkList, is_download, fileList, storeList, fileMd5 } = this.state
        fileChunkList.forEach((item, index) => {//分片传输
            if (index === uploadIndex) {
                let reader = new FileReader()
                let fileDate = item.file
                let data = {
                    file: '',
                    name: file.name,
                    index: item.index,
                    filecount: item.filecount,
                    MD5: fileMd5,
                    is_download: is_download
                }

                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    data.file = e.target.result
                    axios({
                        method: 'post',
                        url: '/api/fileUpload',
                        data: data,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem("Token")
                        }
                    }).then((res) => {
                        console.log(res)
                        if (!res.data.code) {
                            if (res.data.data.result) {
                                let index = res.data.data.index
                                this.fileUpload(index)

                                this.setState(preState => ({
                                    percent: Math.ceil((100 / item.filecount) * index)
                                })
                                )
                            } else {
                                let value = {
                                    name: file.name,
                                    filename: file.name,
                                    att_id: res.data.data.att_id,
                                    table_id: res.data.data.att_id,
                                    path: res.data.data.path,
                                }
                                fileList.push(value)
                                storeList.push(res.data.data.att_id)
                                this.props.fileEvent(storeList, this.props.fileName, value)
                                message.success("上传成功");
                                this.setState(preState => ({
                                    fileList,
                                    storeList,
                                    percent: 0,
                                    isProgress: false,
                                    fileMd5: ''
                                })
                                )
                            }
                        }
                    }).catch((err) => {
                        message.error("上传失败，请稍后重试！");
                        return;
                    })
                }
            }
        })
    }

    // 移除文件
    removeFile = (index) => {
        const { fileList, storeList } = this.state
        fileList.splice(index, 1)
        storeList.splice(index, 1)
        this.props.fileEvent(storeList, this.props.fileName)
        this.setState(preState => ({
            fileList,
            storeList
        })
        )
    }

    get_deleteFile = (index) => {
        const { storeList } = this.state
        let data = {
            file_id: storeList[index]
        }
        deleteFile(data).then(
            (res) => {
                if (!res.code) {
                    this.removeFile(index)
                } else {
                    message.error('删除失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.att_id
        }
        axios.get('/api/fileDownload', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = file.name
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const { fileList, percent, isProgress, is_download } = this.state
        return (
            <div className='fileList'>
                <Upload
                    showUploadList={false}
                    beforeUpload={(file, fileList) => {
                        return false
                    }}
                    onChange={this.updateChange}   //上传状态改变事件                      
                >
                    <img ref={this.upload} src={attachImg} alt="" ></img>
                    {isProgress ? <Progress percent={percent} /> : ''}
                </Upload>
                <div>
                    {
                        fileList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div></div>
                                    <div>
                                        <div title={item.name}>{item.name}</div>
                                        <div>
                                            {
                                                is_download === 1 ? <DownloadOutlined style={{ color: "#339EF9", marginRight: '10px' }} onClick={() => {
                                                    this.get_download(item)
                                                }} /> : ''
                                            }
                                            <CloseOutlined style={{ color: "#339EF9" }} onClick={() => {
                                                this.get_deleteFile(index)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        )
    }
}
