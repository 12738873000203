import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { researchContractAuxiliary, researchContractDetail, researchProjectList, researchContractList, createResearchContract } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"
import { selectAvailableSeal } from "../../../../utils/public"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: false,
                template: '/conSuccessfulCeontractReview.docx',
                templateName: '四川天艺生态园林集团股份有限公司项目合同评审表',
                tempData: {
                    conType: '',
                    apply_date: '',
                    conName: '',
                    conNum: '',
                    conMoney: '',
                    party_a: '',
                    party_b: '',
                    is_seal: '',
                    sealName: '',
                    info: '',
                    results: []
                },
                FlowModelName: "成果转化项目合同审批",
                flowTips: '(358工程)',
                TableInfo: [{
                    FieldTitle: '起草人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择申请日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同类型', //字段标题
                    fieldName: 'con_type', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同类型', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            value: 1,
                            label: '采购合同'
                        },
                        {
                            value: 2,
                            label: '收入合同'
                        }
                    ]
                }, {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" }
                    ]
                }, {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    isShow: true,
                }, {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'con_num', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同编号', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同类别', //字段标题
                    fieldName: 'con_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同类别', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "材料" },
                        { value: 2, label: "劳务" }
                    ]
                }, {
                    FieldTitle: '合同名称', //字段标题
                    fieldName: 'con_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同名称', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '合同性质', //字段标题
                    fieldName: 'con_nature', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同性质', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "主合同" },
                        { value: 2, label: "补充合同" }
                    ]
                }, {
                    FieldTitle: '选择主合同', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '主合同名称', //字段标题
                    fieldName: 'parent_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                }, {
                    FieldTitle: '合同甲方', //字段标题
                    fieldName: 'party_a', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同甲方', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '合同乙方', //字段标题
                    fieldName: 'party_b', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同乙方', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '发票类型', //字段标题
                    fieldName: 'invo_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择发票类型', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "普票" },
                        { value: 2, label: "专票" }
                    ]
                }, {
                    FieldTitle: '合同金额(元)', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '税率(%)', //字段标题
                    fieldName: 'tax', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '相关说明', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: [{
                    plateName: '业主单位信息',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '业主单位名称', //字段标题
                            fieldName: 'owner_unit', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入业主单位名称', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '工程项目地点/面积', //字段标题
                            fieldName: 'pro_address_area', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入工程项目地点/面积', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '联系人', //字段标题
                            fieldName: 'contacts', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入联系人', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'contact_phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入联系电话', //必填要求
                            value: '',
                            width: '50%'
                        }
                    ]
                }, {
                    plateName: '合同说明及附件',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '相关说明', //字段标题
                            fieldName: 'about_description', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'attachment', //字段名称
                            fieldType: 'enclosure',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ]
                }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            radio: true,
            buttonTitle: '',
            dataSource: [],
            columns: [],
            TableInfo: [],
            pro_name: '',
            con_party: '',
            seal_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
        // props.cacheLifecycles.didCache(this.componentDidCache)
        // props.cacheLifecycles.didRecover(this.componentDidRecover)
    }

    // componentDidCache = () => {
    //     console.log('列表缓存')
    // }

    // componentDidRecover = () => {
    //     console.log('列表中恢复')
    // }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_researchContractAuxiliary()
    }

    get_researchContractAuxiliary = () => {
        let { Info } = this.state
        researchContractAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同甲方') {
                            item.selectList = []
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '合同乙方') {
                            item.selectList = []
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_researchContractDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.res_pro_id = project.table_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    if (project) {
                        item.value = project.pro_name
                    }
                }
            })
        }
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '起草人') {
                item.value = userInfo.name
            }
        })
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_researchContractDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        researchContractDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.res_pro_id = res.data.res_pro_id
                        form.con_type = res.data.con_type
                        Info.process.edit = res.data.editPermission
                        if (res.data.is_seal === 1) {
                            form.seal_ids = res.data.seal_ids
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.sealNames
                                }
                            })
                        }

                        if (res.data.con_nature === 2) {
                            form.con_id = res.data.con_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择主合同') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '主合同名称') {
                                    item.isShow = false
                                    item.value = res.data.parent_name
                                }
                            })
                        }

                    } else {
                        if (res.data.status === 4) {
                            let con_type = res.data.con_type === 1 ? '采购合同' : '收入合同'
                            let party_a = ''
                            let party_b = ''

                            if (res.data.con_type === 1) {
                                party_a = Number(res.data.party_a) === 1 ? '四川天艺生态园林集团股份有限公司' : Number(res.data.party_a) === 2 ? '四川天艺优境环境科技有限公司' : Number(res.data.party_a) === 3 ? '四川天艺致远物联网科技有限公司' : '四川天艺传奇生态茶叶有限公司'
                                party_b = res.data.party_b
                            } else {
                                party_a = party_a
                                party_b = Number(res.data.party_b) === 1 ? '四川天艺生态园林集团股份有限公司' : Number(res.data.party_b) === 2 ? '四川天艺优境环境科技有限公司' : Number(res.data.party_b) === 3 ? '四川天艺致远物联网科技有限公司' : '四川天艺传奇生态茶叶有限公司'
                            }

                            Info.tempData.conType = con_type
                            Info.tempData.apply_date = res.data.apply_day
                            Info.tempData.conName = res.data.con_name
                            Info.tempData.conNum = res.data.con_num
                            Info.tempData.conMoney = res.data.con_money
                            Info.tempData.party_a = party_a
                            Info.tempData.party_b = party_b
                            Info.tempData.is_seal = res.data.is_seal === 1 ? '是' : '否'
                            Info.tempData.sealName = res.data.sealNames || '/'
                            Info.tempData.info = res.data.info
                            Info.tempData.results = []

                            Info.isExport = true

                            res.data.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }

                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.is_seal === 1) {
                            form.seal_ids = res.data.seal_ids
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.sealNames
                                }
                            })
                        }

                        if (res.data.con_nature === 2) {
                            form.con_id = res.data.con_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '主合同名称') {
                                    item.isShow = false
                                    item.value = res.data.parent_name
                                }
                            })
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    if (res.data.con_type === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '合同类别') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同甲方') {
                                item.fieldType = '文本'
                                item.required = '请输入合同甲方'
                            } else if (item.FieldTitle === '合同乙方') {
                                item.fieldType = '选择器'
                                item.required = '请选择合同乙方'
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '起草人') {
                            item.value = res.data.applyUsername
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.proName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.apply_day ? moment(res.data.apply_day, 'YYYY-MM-DD') : '',
                        is_seal: res.data.is_seal,
                        con_type: res.data.con_type,
                        con_class: res.data.con_class,
                        con_num: res.data.con_num,
                        con_name: res.data.con_name,
                        con_nature: res.data.con_nature,
                        party_a: res.data.con_type === 1 ? Number(res.data.party_a) : res.data.party_a,
                        party_b: res.data.con_type === 2 ? Number(res.data.party_b) : res.data.party_b,
                        invo_type: res.data.invo_type,
                        con_money: res.data.con_money,
                        tax: res.data.tax,
                        info: res.data.info,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchProjectList = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            status: 4,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        researchProjectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.researches.length) {
                        res.data.researches.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                cre_day: item.cre_day,
                                over_day: item.over_day,
                                plant_open_day: item.plant_open_day,
                                select_money: item.select_money,
                                duration: item.duration,
                                is_over: item.is_over,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchContractList = () => {
        let { dataSource, pages, con_party, form } = this.state
        let data = {
            con_id: form.res_pro_id,
            con_nature: 1,
            con_type: form.con_type,
            con_party: con_party,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        researchContractList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let con_party = ''
                            if (form.con_type === 1) {
                                con_party = item.party_b
                            } else {
                                con_party = item.party_a
                            }
                            let rowValue = {
                                key: item.table_id,
                                apply_day: item.apply_day,
                                username: item.applyUsername,
                                con_party: con_party,
                                con_class: item.con_class === 1 ? '材料' : item.con_class === 2 ? '劳务' : '/',
                                con_name: item.con_name,
                                con_money: item.con_money,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.is_seal = data.is_seal
        form.con_type = data.con_type
        form.con_class = data.con_class
        form.set_type = data.set_type
        form.con_num = data.con_num
        form.con_name = data.con_name
        form.con_nature = data.con_nature
        form.party_a = data.party_a
        form.party_b = data.party_b
        form.invo_type = data.invo_type
        form.con_money = data.con_money
        form.tax = data.tax
        form.info = data.info

        createResearchContract(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { form, columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '中标金额',
                        dataIndex: 'select_money',
                        key: 'select_money',
                    },
                    {
                        title: '开工时间',
                        dataIndex: 'plant_open_day',
                        key: 'plant_open_day',
                    },
                    {
                        title: '竣工时间',
                        dataIndex: 'over_day',
                        key: 'over_day',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]
                this.get_researchProjectList()
                radio = true
            } else if (title === '选择主合同') {
                columns = [
                    {
                        title: '申请时间',
                        dataIndex: 'apply_day',
                        key: 'apply_day',
                        render: (text, record, index) => {
                            return (
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    this.get_details(record.table_id)
                                }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同类别',
                        dataIndex: 'con_class',
                        key: 'con_class',
                    },
                    {
                        title: '合同乙方',
                        dataIndex: 'con_party',
                        key: 'con_party',
                    },
                    {
                        title: '合同金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                ]

                TableInfo = [
                    {
                        FieldTitle: '合同乙方', //字段标题
                        fieldName: 'code', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]

                if (form.con_type === 2) {
                    columns[2].title = '合同甲方'
                    TableInfo[0].FieldTitle = '合同甲方'
                }
                this.get_researchContractList()
                radio = true
            } else {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_seal()
                radio = false
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
            }))
        }
    }

    handleRadio = (value, name) => {
        let { form, Info } = this.state;
        if (name === '合同类型') {
            form.con_type = value
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '合同类别') {
                        item.isShow = false
                    } else if (item.FieldTitle === '合同甲方') {
                        item.fieldType = '选择器'
                        item.required = '请选择合同甲方'
                    } else if (item.FieldTitle === '合同乙方') {
                        item.fieldType = '文本'
                        item.required = '请输入合同乙方'
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '合同类别') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同甲方') {
                        item.fieldType = '文本'
                        item.required = '请输入合同甲方'
                    } else if (item.FieldTitle === '合同乙方') {
                        item.fieldType = '选择器'
                        item.required = '请选择合同乙方'
                    }
                })
            }
        } else {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = false
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = false
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = true
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = true
                    }
                })
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '选择项目') {
            form.res_pro_id = value[0].key

            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                }
            })

        } else if (buttonTitle === '选择主合同') {
            form.con_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '主合同名称') {
                    item.value = value[0].con_name
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_ids = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_ids = [...form.seal_ids, em.key]
                    })
                    form.seal_ids = JSON.stringify(form.seal_ids)
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 2) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_researchProjectList()
            })
        } else if (buttonTitle === '选择主合同') {
            this.setState({
                con_party: value.con_party,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_researchContractList()
            })
        } else {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            con_party: '',
            seal_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_researchProjectList()
            } else if (buttonTitle === '选择主合同') {
                this.get_researchContractList()
            } else {
                this.get_seal()
            }
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}