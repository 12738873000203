import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { flowSeeLook, flowCcRead } from "../../utils/index"
import Operate from '../components/Operate'
import Search from '../components/Search';
import Table from '../components/Table'

class Flow extends Component {
    state = {
        searchShow: false,
        columns: [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} onClick={() => {
                            this.get_details(record)
                        }} style={{ cursor: 'pointer', width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '申请日期',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
            },
            {
                title: '申请人',
                dataIndex: 'applyUserName',
                key: 'applyUserName',
            },
            {
                title: '申请类型',
                dataIndex: 'functionName',
                key: 'functionName',
            },
            {
                title: '已读/未读',
                dataIndex: 'is_read',
                key: 'is_read',
                render: (text, index) => {
                    if (text === 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ backgroundColor: '#F53500', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></div>
                                <div>未读</div>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ backgroundColor: '#00DC9E', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></span>
                                <span>已读</span>
                            </div>
                        )
                    }
                }
            },
        ],
        dataSource: [],
        TableInfo: [
            {
                FieldTitle: '申请人', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }
        ],
        username: '',
        pages: {
            limit: 8,
            page: 1,
            total: 0
        }
    }

    componentDidMount() {
        this.get_flowSeeLook()
    }

    get_details(item) {
        this.props.history.push({
            pathname: item.deteilUrl,
            query: {
                'tableId': item.table_id,
            }
        })
        this.handleFlowRead(item.table_id)
    }

    get_flowSeeLook = () => {
        let { username, pages, dataSource } = this.state
        let data = {
            username: username,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        flowSeeLook(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.flows.length) {
                        res.data.flows.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                deteilUrl: item.deteilUrl,
                                is_read: item.is_read,
                                title: item.applyUserName + '提交的' + item.functionName,
                                created_at: item.created_at,
                                projectName: item.projectName || '/',
                                applyUserName: item.applyUserName,
                                functionName: item.functionName,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleFlowRead = (tableId) => {
        let data = {
            table_id: tableId
        }
        flowCcRead(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            username: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_flowSeeLook()
        })
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
        }, () => {
            this.get_flowSeeLook()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_flowSeeLook()
        })
    }

    render() {
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} notPrint={true} educe={true} searchEvent={this.handleSearch} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(Flow)
