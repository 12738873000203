import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { selectUserName, reviewApplyResult } from "../../../utils/api"
import { selectAvailableSeal } from "../../../utils/public"
import { ApprovalCorrespondenceDetail, createApprovalCorrespondence, BlankingSelectProject } from "../../../utils/project"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "函件审批",
                TableInfo: [
                    {
                        FieldTitle: '日期', //字段标题
                        fieldName: 'date_day', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '选择部门经理', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true
                    },
                    {
                        FieldTitle: '部门经理', //字段标题
                        fieldName: 'manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '业务部门', //字段标题
                        fieldName: 'de_id', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '是否盖章', //字段标题
                        fieldName: 'is_seal', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            }
                        ]
                    }, {
                        FieldTitle: '选择印章', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                        isShow: true
                    }, {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_id', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true
                    }, {
                        FieldTitle: '函件编号', //字段标题
                        fieldName: 'cor_code', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '函件份数', //字段标题
                        fieldName: 'cor_num', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '报送单位', //字段标题
                        fieldName: 'send_unit', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '选择项目', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }, {
                        FieldTitle: '函件主题', //字段标题
                        fieldName: 'cor_theme', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }, {
                        FieldTitle: '函件内容', //字段标题
                        fieldName: 'content', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '其他说明', //字段标题
                        fieldName: 'other_info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ],
                FirstInfo: [],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            columns: [],
            TableInfo: [],
            seal_name: '',
            pro_name: '',
            username: '',
            de_name: '',
            buttonTitle: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ApprovalCorrespondenceDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { form, Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_ApprovalCorrespondenceDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        ApprovalCorrespondenceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.manager = res.data.Detail.manager
                        form.de_id = res.data.Detail.de_id
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章名称') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择部门经理') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章名称') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '部门经理') {
                            item.value = res.data.Detail.managerName
                        } else if (item.FieldTitle === '业务部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        date_day: res.data.Detail.date_day ? moment(res.data.Detail.date_day, 'YYYY-MM-DD') : '',
                        is_seal: res.data.Detail.is_seal,
                        cor_code: res.data.Detail.cor_code,
                        cor_theme: res.data.Detail.cor_theme,
                        content: res.data.Detail.content,
                        cor_num: res.data.Detail.cor_num,
                        send_unit: res.data.Detail.send_unit,
                        other_info: res.data.Detail.other_info,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleRadio = (value) => {
        const { Info } = this.state
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = false
                } else if (item.FieldTitle === '印章名称') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = true
                } else if (item.FieldTitle === '印章名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                de_id: item.de_id,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.date_day = data.date_day ? data.date_day.format('YYYY-MM-DD') : ''
        form.is_seal = data.is_seal
        form.cor_code = data.cor_code
        form.cor_theme = data.cor_theme
        form.content = data.content
        form.cor_num = data.cor_num
        form.send_unit = data.send_unit
        form.other_info = data.other_info
        form.info = data.info

        createApprovalCorrespondence(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                radio = false
                this.get_seal()
            } else if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'managerName',
                        key: 'managerName',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                radio = true
                this.get_BlankingSelectProject()
            } else {
                columns = [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ]
                TableInfo = [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }]
                radio = true
                this.get_InitialValue()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章名称') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        } else if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                }
            })
        } else {
            form.manager = value[0].key
            form.de_id = value[0].de_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '部门经理') {
                    item.value = value[0].username
                } else if (item.FieldTitle === '业务部门') {
                    item.value = value[0].deName
                }
            })
        }
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        } else if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                }
            }, () => {
                this.get_InitialValue()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            seal_name: '',
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else {
                this.get_InitialValue()
            }
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} radioEvent={this.handleRadio} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}