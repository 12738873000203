import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { awayApplyList } from "../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'apply_user',
                    key: 'apply_user',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '出差时间',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text, index) => {
                        return (
                            <div className='timeStyle'>
                                <div>
                                    <div></div>
                                    <div>{text[0]}</div>
                                </div>
                                <div>
                                    <div></div>
                                    <div>{text[1]}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '出差天数',
                    dataIndex: 'date_num',
                    key: 'date_num',
                },
                {
                    title: '交通工具',
                    dataIndex: 'tool',
                    key: 'tool',
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div>火车</div>
                            )
                        } else if (text === 2) {
                            return (
                                <div>飞机</div>
                            )
                        }
                        else if (text === 3) {
                            return (
                                <div>火车+飞机</div>
                            )
                        }
                        else if (text === 4) {
                            return (
                                <div>高铁(动车)</div>
                            )
                        }
                        else if (text === 5) {
                            return (
                                <div>高铁(动车)+飞机</div>
                            )
                        }
                        else if (text === 6) {
                            return (
                                <div>其他</div>
                            )
                        }
                    }
                },
                {
                    title: '出差地点',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: '出差理由',
                    dataIndex: 'matter',
                    key: 'matter',
                },
                {
                    title: '同行人员',
                    dataIndex: 'peerUserName',
                    key: 'peerUserName',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_AwayApplyList()
    }

    get_AwayApplyList = () => {
        let { dataSource, pages, process_state } = this.state
        let data = {
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        awayApplyList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.aways.length) {
                        res.data.aways.forEach((item, index) => {
                            let peer_userName = ''
                            if (item.peerUserName) {
                                item.peerUserName.forEach((item, index) => {
                                    peer_userName = peer_userName + ' ' + item.userName
                                })
                            }
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                apply_user: item.applyUserName,
                                currentUser: item.currentUser,
                                deName: item.deName,
                                jobName: item.jobName,
                                time: [item.start_date, item.end_date],
                                date_num: item.date_num,
                                tool: item.tool,
                                address: item.address,
                                matter: item.matter,
                                peerUserName: peer_userName,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/public/human/evection/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/human/evection/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_AwayApplyList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_AwayApplyList()
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} seek={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} scroll={true} scrollWidth={2000} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)


