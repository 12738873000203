import React, { Component } from 'react'
import Icon, { CheckOutlined, FileTextOutlined } from '@ant-design/icons';
import { Tree, message, Form, Button, Input, DatePicker, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { withRouter } from "react-router-dom";
import { revenueMain } from "../../../utils/market"
import { createdProjectCollection, projectCollection } from "../../../utils/api"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"
import Icon_01 from "../../../accets/img/icon_01.png"
import moneyImg from "../../../accets/img/money.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pro_id: '',
            money: '',
            coll_time: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            Info: {
                FlowModelName: "项目收款",
                TableInfo: [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'pro_manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                ],
                FirstInfo: [
                    {
                        plateName: '已开票金额',
                        editable: {
                            dataSource: [

                            ],
                            columns: [
                                {
                                    title: '',
                                    dataIndex: 'key',
                                    key: 'key',
                                },
                            ],
                            summary: {
                                colspan: '',
                                name: '总开票金额',
                                value: ''
                            },
                            scroll: false
                        }
                    },
                    {
                        plateName: '实际收入金额',
                        editable: {
                            dataSource: [],
                            columns: [
                                {
                                    title: '',
                                    dataIndex: 'key',
                                    key: 'key',
                                },
                            ],
                            summary: {
                                colspan: '',
                                name: '总收入金额',
                                value: ''
                            },
                            scroll: false
                        }
                    }
                ]
            },
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (this.props.location.query) {
                        pro_id = this.props.location.query.tableId
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            item.children.forEach((em, ex) => {
                                em.children.forEach((m, x) => {
                                    if (m.key === pro_id) {
                                        selectedKeys.push(m.key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            })
                        })
                    } else {
                        if (!pro_id) {
                            selectedKeys = []
                            expandedKeys = ['1']
                            treeData.forEach((item, index) => {
                                if (index === 0) {
                                    item.children.forEach((em, ex) => {
                                        if (ex === 0) {
                                            pro_id = em.children[0].key
                                            selectedKeys.push(em.children[0].key)
                                            expandedKeys.push(em.key)
                                        }
                                    })
                                }
                            })
                        }
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_projectCollection()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectCollection = () => {
        const { Info, pro_id } = this.state;
        let data = {
            project_id: pro_id
        }
        projectCollection(data).then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.project.project_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.project.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.project.pro_manager
                        }
                    })

                    Info.FirstInfo[0].editable.summary.value = res.data.invoice_total
                    Info.FirstInfo[0].editable.summary.colspan = res.data.invoices.length + 1
                    Info.FirstInfo[0].editable.dataSource = []
                    Info.FirstInfo[0].editable.columns = [{
                        title: '',
                        dataIndex: 'key',
                        key: 'key',
                    }]
                    if (res.data.invoices.length) {
                        let value = {
                            key: '已开票金额(元)'
                        }
                        let time = {
                            key: '开票时间'
                        }

                        if (res.data.invoices.length > 3) {
                            Info.FirstInfo[0].editable.scroll = true
                        }

                        res.data.invoices.forEach((item, index) => {
                            let label = {
                                title: '第' + (index + 1) + '次开票',
                                dataIndex: item.table_id,
                                key: item.table_id,
                            }
                            Info.FirstInfo[0].editable.columns = [...Info.FirstInfo[0].editable.columns, label]
                            value[item.table_id] = item.invo_money
                            time[item.table_id] = item.created_at
                        })
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, value]
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, time]
                    }

                    Info.FirstInfo[1].editable.summary.value = res.data.collection_total
                    Info.FirstInfo[1].editable.dataSource = []
                    Info.FirstInfo[1].editable.columns = [{
                        title: '',
                        dataIndex: 'key',
                        key: 'key',
                        width: '150px',
                    }]
                    if (res.data.collection.length) {
                        let value = {
                            key: '已收款金额(元)'
                        }
                        let time = {
                            key: '收款时间'
                        }

                        if (res.data.collection.length > 3) {
                            Info.FirstInfo[1].editable.scroll = true
                        }

                        res.data.collection.forEach((item, index) => {
                            let label = {
                                title: '第' + (index + 1) + '收款到账',
                                dataIndex: 'money' + (index + 1),
                                key: 'money' + (index + 1),
                            }
                            Info.FirstInfo[1].editable.columns = [...Info.FirstInfo[1].editable.columns, label]
                            value['money' + (index + 1)] = item.money
                            time['money' + (index + 1)] = item.created_at
                        })
                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, value]
                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, time]

                        let title = {
                            title: '第' + Info.FirstInfo[1].editable.columns.length + '收款到账',
                            dataIndex: 'money' + Info.FirstInfo[1].editable.columns.length,
                            key: 'money' + Info.FirstInfo[1].editable.columns.length,
                            render: (text, record, index) => {
                                if (record.key === '已收款金额(元)') {
                                    return <Form.Item name='money' key={index} initialValue={text}>
                                        <Input />
                                    </Form.Item>
                                } else {
                                    return <ConfigProvider locale={zhCN}>
                                        <Form.Item name='time' initialValue={text}>
                                            <DatePicker
                                                allowClear={false}
                                                showTime={true}
                                                format='YYYY-MM-DD HH:mm:ss'
                                            />
                                        </Form.Item>
                                    </ConfigProvider>
                                }
                            }

                        }

                        Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                            item['money' + Info.FirstInfo[1].editable.columns.length] = ''
                        })

                        Info.FirstInfo[1].editable.columns = [...Info.FirstInfo[1].editable.columns, title]
                    } else {
                        let value = {
                            key: '已收款金额(元)'
                        }
                        let time = {
                            key: '收款时间'
                        }

                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, value]
                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, time]

                        let title = {
                            title: '第' + Info.FirstInfo[1].editable.columns.length + '收款到账',
                            dataIndex: 'money' + Info.FirstInfo[1].editable.columns.length,
                            key: 'money' + Info.FirstInfo[1].editable.columns.length,
                            render: (text, record, index) => {
                                if (record.key === '已收款金额(元)') {
                                    return <Form.Item name='money' key={index} initialValue={text}>
                                        <Input />
                                    </Form.Item>
                                } else {
                                    return <ConfigProvider locale={zhCN}>
                                        <Form.Item name='time' initialValue={text}>
                                            <DatePicker
                                                allowClear={false}
                                                showTime={true}
                                                format='YYYY-MM-DD HH:mm:ss'
                                            />
                                        </Form.Item>
                                    </ConfigProvider>
                                }
                            }

                        }

                        Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                            item['money' + Info.FirstInfo[1].editable.columns.length] = ''
                        })

                        Info.FirstInfo[1].editable.columns = [...Info.FirstInfo[1].editable.columns, title]
                    }

                    Info.FirstInfo[1].editable.summary.colspan = Info.FirstInfo[1].editable.columns.length

                    this.setState(preState => ({
                        Info
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onFinish = (value) => {
        if (!value.time || !value.money) {
            message.error('请检查表单!')
            return
        }
        let data = {
            project_id: this.state.pro_id,
            id: '',
            money: value.money,
            coll_time: value.time.format('YYYY-MM-DD HH:mm:ss')
        }
        createdProjectCollection(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功!')
                    this.formRef.current.setFieldsValue({
                        money: '',
                        time: ''
                    })
                    this.get_projectCollection()
                } else {
                    message.error(res.data[0] || '保存失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    handleSave = () => {
        let { form } = this.state
        projectCollection(form).then(
            (res) => {
                if (!res.code) {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_projectCollection()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { treeData, expandedKeys, selectedKeys, Info } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div style={{ backgroundColor: '#FFFFFF', padding: '20px', borderRadius: '16px' }}>
                    <Form ref={this.formRef}
                        onFinish={this.onFinish}
                    >
                        <div className='TableOperation'>
                            <Button htmlType="submit">
                                <div><CheckOutlined /></div>
                                <div>保存</div>
                            </Button>
                        </div>
                        <div className='formTitle'>{Info.FlowModelName}</div>

                        {
                            Info.TableInfo.length ? Info.TableInfo.map((item, index) => {
                                return (
                                    <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                        className={item.width === '100%' ? 'standard' : 'moiety'}
                                        label={item.FieldTitle}
                                        key={index}
                                    >
                                        <div className='defaultValue'>{item.value}</div>
                                    </Form.Item>
                                )
                            }) : ''
                        }
                        {
                            Info.FirstInfo.length ? Info.FirstInfo.map((item, index) => {
                                return (
                                    <div key={index} style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            item.plateName ? <div className='FirstTitle'>
                                                <div>
                                                    <div>{item.plateName}</div>
                                                    <div><img src={Icon_01} alt=""></img></div>
                                                </div>
                                            </div> : ''
                                        }

                                        {
                                            item.editable.scroll ? <div style={{ width: '100%' }} className="form_table">
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                            scroll={{
                                                                x: 1800,
                                                            }}
                                                            summary={() => (
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0} colSpan={item.editable.summary.colspan} className="total_prices total_price">
                                                                        <img src={moneyImg} alt=""></img>
                                                                        <span>{item.editable.summary.name}</span>
                                                                        <span>{item.editable.summary.value}</span>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div> : <div style={{ width: '100%' }} className="form_table">
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                            summary={() => (
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0} colSpan={item.editable.summary.colspan} className="total_prices total_price" >
                                                                        <img src={moneyImg} alt=""></img>
                                                                        <span>{item.editable.summary.name}</span>
                                                                        <span>{item.editable.summary.value}</span>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                )
                            }) : ''
                        }

                    </Form >
                </div>
            </div>
        )
    }
}

export default withRouter(List)
