import React, { Component } from 'react'
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider, Table } from 'antd';
import moneyImg from "../../accets/img/money.png"

export default class Tables extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    rowSelectionChange = (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    render() {
        let { dataSource, columns, summary, summaryValue, selection, pages, dataLimit, scroll, scrollWidth, radio, expandedRowKey, checkStrictly } = this.props
        const rowSelection = {
            type: radio ? 'radio' : 'checkbox',
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
                this.rowSelectionChange(selectedRowKeys, selectedRows)
                this.props.selectEvent(selectedRows)
            },
            checkStrictly: !checkStrictly ? true : false
        };

        const pagination = {
            total: pages ? pages.total : 0, //数据的总条数
            showTotal: (total) => `共${total}条`,
            defaultCurrent: pages ? pages.page : 1,//默认当前的页数
            defaultPageSize: pages ? pages.limit : 0,//默认每页的条数,
            showSizeChanger: true,
            pageSizeOptions: ["8", "10", "15", "20", '50', '100', '200'],
            onChange: (page, pageSize) => {
                dataLimit(page, pageSize)
            },
        }


        const onScroll = {
            x: scrollWidth || 2500
        }

        return (
            <div>

                {
                    summary ? <ConfigProvider locale={zhCN}>
                        <Table
                            rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                            }}
                            pagination={pages ? pagination : false}
                            rowSelection={!selection ? rowSelection : false}
                            scroll={scroll ? onScroll : false}
                            columns={columns}
                            dataSource={dataSource}
                            tableLayout='fixed'
                            summary={() => (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={summaryValue.colspan} className="total_prices">
                                        <img src={moneyImg} alt=""></img>
                                        <span>{summaryValue.name}</span>
                                        <span>{summaryValue.value}</span>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            )}
                        />
                    </ConfigProvider> : <ConfigProvider locale={zhCN}>
                        <Table
                            rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                            }}
                            pagination={pages ? pagination : false}
                            rowSelection={!selection ? rowSelection : false}
                            scroll={scroll ? onScroll : false}
                            columns={columns}
                            dataSource={dataSource}
                            tableLayout='fixed'
                            expandable={{
                                defaultExpandedRowKeys: expandedRowKey,
                            }}
                            onExpand={(stutas, record) => {
                                this.props.expandEvent(stutas, record)
                            }}
                        />
                    </ConfigProvider>
                }




            </div>
        )
    }
}
