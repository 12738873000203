import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { SettlementPaymentAuxiliary, createSettlementPayment, aboutPaymentContract, SettlementPaymentDetail, aboutResearchContract, ProgressSettlementSelect, nurserySettlementSelect, outboundOrderSelect } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/sanwubaPayment.docx',
                templateName: '四川天艺生态园林集团股份有限公司项目类/科研类付款审批(358工程)',
                tempData: {
                    con_number: '',
                    apply_date: '',
                    apply_user: '',
                    con_name: '',
                    pro_name: '',
                    con_money: '',
                    con_type: '',
                    invo_money: '',
                    acc_money: '',
                    pay_money: '',
                    acc_invoice: '',
                    money_type: '',
                    pay_unit: '',
                    pay_class: '',
                    pay_type: '',
                    pro_money: '',
                    set_money: '',
                    unit_name: '',
                    invo_type: '',
                    money: '',
                    replenish_invo: '',
                    info: '',
                    accout_name: '',
                    bank_name: '',
                    bank_number: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "成果转化项目付款审批",
                flowTips: '(358工程)',
                TableInfo: [
                    {
                        FieldTitle: '起草人', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '申请日期', //字段标题
                        fieldName: 'apply_day', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择申请日期', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '项目付款', //字段标题
                        fieldName: 'is_pro', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择是否为项目付款', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            }
                        ]
                    },
                    {
                        FieldTitle: '选择合同', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    }, {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_num', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '合同类别', //字段标题
                        fieldName: 'con_type', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'proName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '合同总金额(元)', //字段标题
                        fieldName: 'con_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '本次付款(元)', //字段标题
                        fieldName: 'pay_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本次付款(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '已累计付款(元)', //字段标题
                        fieldName: 'used_pay_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本次付款(元)', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    // {
                    //     FieldTitle: '剩余未付款(元)', //字段标题
                    //     fieldName: 'no_pay', //字段名称
                    //     fieldType: '文本',  //字段类型
                    //     disabled: false,  //字段是否禁用
                    //     mandatory: true, //字段是否必填
                    //     required: '请输入本次付款(元)', //必填要求
                    //     value: '',
                    //     width: '50%',
                    //     isShow: true
                    // },
                    {
                        FieldTitle: '费用类型', //字段标题
                        fieldName: 'money_type', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入money_type', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '付款单位', //字段标题
                        fieldName: 'pay_unit', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择付款单位', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [],
                        isShow: true
                    },
                    {
                        FieldTitle: '付款说明', //字段标题
                        fieldName: 'pay_info', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '本次开票金额(元)', //字段标题
                        fieldName: 'invo_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本次开票金额(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '前期已开票金额(元)', //字段标题
                        fieldName: 'used_invo_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '是否欠票', //字段标题
                        fieldName: 'is_owing', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择是否欠票', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "是" },
                            { value: 2, label: "否" },
                        ]
                    },
                    {
                        FieldTitle: '欠票单位', //字段标题
                        fieldName: 'unit_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票类型', //字段标题
                        fieldName: 'invo_type', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票金额', //字段标题
                        fieldName: 'money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票原因', //字段标题
                        fieldName: 'cause', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '补票时间', //字段标题
                        fieldName: 'replenish_invo', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '付款类别', //字段标题
                        fieldName: 'pay_type', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择付款类别', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "预付款" },
                            { value: 2, label: "进度计量审批" },
                            { value: 3, label: "结算审批" },
                        ]
                    },
                    {
                        FieldTitle: '付款节点', //字段标题
                        fieldName: 'pay_class', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '输入付款节点', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true
                    },
                ],
                FirstInfo: [{
                    plateName: '进度付款',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '关联进度计量单据', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '进度计量单据', //字段标题
                            fieldName: 'res_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        },
                        {
                            FieldTitle: '累计进度计量金额(元)', //字段标题
                            fieldName: 'pro_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                },
                {
                    plateName: '结算',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '关联结算单据', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '结算单据', //字段标题
                            fieldName: 'res_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        },
                        {
                            FieldTitle: '结算金额(元)', //字段标题
                            fieldName: 'set_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                },
                {
                    plateName: '出库',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '关联出库单据', //字段标题
                            fieldType: '按钮',  //字段类型
                        },
                        {
                            FieldTitle: '出库单据', //字段标题
                            fieldName: 'outbound', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        },
                    ],
                },
                {
                    plateName: '收款单位信息',
                    list: [
                        {
                            FieldTitle: '收款账号', //字段标题
                            fieldName: 'col_number', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '收款户名', //字段标题
                            fieldName: 'col_username', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '收款银行', //字段标题
                            fieldName: 'bank', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '联系人', //字段标题
                            fieldName: 'contacts', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '备注', //字段标题
                            fieldName: 'con_info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        }
                    ]
                },
                ]
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            con_name: '',
            con_party: '',
            con_type: '',
            pro_name: '',
            supplier: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_SettlementPaymentAuxiliary()
    }

    get_SettlementPaymentAuxiliary = () => {
        let { Info } = this.state
        SettlementPaymentAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '付款单位') {
                            item.selectList = []
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_SettlementPaymentDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        Info.state = 1
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '起草人') {
                item.value = userInfo.name
            }
        })
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_SettlementPaymentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        SettlementPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.con_id = res.data.con_id || ''
                        form.is_pro = res.data.is_pro

                        Info.process.edit = res.data.editPermission

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = false

                            if (item.FieldTitle === '选择合同') {
                                item.isShow = false
                            }
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联进度计量单据') {
                                item.isShow = false
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联结算单据') {
                                item.isShow = false
                            }
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联出库单据') {
                                item.isShow = false
                            }
                        })

                        Info.FirstInfo[3].list.forEach((item, index) => {
                            item.disabled = false
                        })

                    } else {

                        if (res.data.status === 4) {
                            Info.tempData.con_number = res.data.con_num
                            Info.tempData.apply_date = res.data.apply_day
                            Info.tempData.apply_user = res.data.applyUsername
                            Info.tempData.con_name = res.data.con_name
                            Info.tempData.pro_name = res.data.proName || ''
                            Info.tempData.con_money = res.data.con_money
                            Info.tempData.pay_money = res.data.pay_money
                            Info.tempData.class = res.data.is_pro === 1 ? '项目类' : '科研类'
                            Info.tempData.unit_name = res.data.is_owing === 1 ? res.data.unit_name : '/'
                            Info.tempData.invo_type = res.data.is_owing === 1 ? res.data.invo_type : '/'
                            Info.tempData.money = res.data.is_owing === 1 ? res.data.money : '/'
                            Info.tempData.cause = res.data.is_owing === 1 ? res.data.cause : '/'
                            Info.tempData.replenish_invo = res.data.is_owing === 1 ? res.data.replenish_invo : '/'
                            Info.tempData.info = res.data.con_info || ''
                            Info.tempData.accout_name = res.data.col_username || ''
                            Info.tempData.bank_name = res.data.bank || ''
                            Info.tempData.bank_number = res.data.col_number || ''
                            Info.tempData.results = []

                            if (res.data.is_pro === 1) {
                                Info.tempData.con_type = res.data.con_type
                                Info.tempData.invo_money = res.data.invo_money || 0
                                Info.tempData.acc_invoice = res.data.used_invo_money || 0
                                Info.tempData.pay_type = res.data.pay_type === 1 ? '预付款' : res.data.pay_type === 2 ? '进度计量' : '结算审批'
                                Info.tempData.pay_class = res.data.pay_type === 1 ? res.data.pay_class : '/'
                                Info.tempData.pro_money = res.data.pay_type === 2 ? res.data.add_money : '/'
                                Info.tempData.set_money = res.data.pay_type === 3 ? res.data.add_money : '/'
                            } else {
                                Info.tempData.con_type = res.data.contractDetail.con_type === 1 ? '采购合同' : res.data.contractDetail.con_type === 3 ? '其他合同' : ''
                                Info.tempData.money_type = res.data.money_type
                                Info.tempData.pay_unit = res.data.pay_unit === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.pay_unit === 2 ? '四川天艺优境环境科技有限公司' : res.data.pay_unit === 3 ? '四川天艺致远物联网科技有限公司' : '四川天艺传奇生态茶业有限公司'
                                Info.tempData.pay_class = res.data.pay_class
                            }

                            res.data.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联进度计量单据') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联结算单据') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联出库单据') {
                                item.isShow = false
                            }
                        })

                        Info.FirstInfo[3].list.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission



                    if (res.data.is_pro === 1) {
                        form.pay_type = res.data.pay_type
                        form.res_id = res.data.res_id

                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '起草人') {
                                item.value = res.data.applyUsername
                            } else if (item.FieldTitle === '合同名称') {
                                item.value = res.data.con_name
                            } else if (item.FieldTitle === '合同编号') {
                                item.value = res.data.con_num
                            } else if (item.FieldTitle === '合同类别') {
                                item.value = res.data.con_type
                            } else if (item.FieldTitle === '项目名称') {
                                item.isShow = false
                                item.value = res.data.proName
                            } else if (item.FieldTitle === '合同总金额(元)') {
                                item.value = res.data.con_money
                            } else if (item.FieldTitle === '付款说明') {
                                item.isShow = false
                            } else if (item.FieldTitle === '本次开票金额(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '前期已开票金额(元)') {
                                item.isShow = false
                                item.value = res.data.used_invo_money || 0
                            } else if (item.FieldTitle === '已累计付款(元)') {
                                item.isShow = true
                                item.value = res.data.used_pay_money || 0
                            } else if (item.FieldTitle === '剩余未付款(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '费用类型') {
                                item.isShow = true
                            } else if (item.FieldTitle === '付款单位') {
                                item.isShow = true
                            } else if (item.FieldTitle === '付款类别') {
                                item.isShow = false
                            } else if (item.FieldTitle === '付款节点') {
                                item.isShow = true
                            }
                        })

                        if (res.data.pay_type === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '付款节点') {
                                    item.isShow = false
                                }
                            })
                            Info.FirstInfo[0].isShow = true
                            Info.FirstInfo[1].isShow = true
                        } else if (res.data.pay_type === 2) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '付款节点') {
                                    item.isShow = true
                                }
                            })
                            Info.FirstInfo[0].isShow = false
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '进度计量单据') {
                                    item.value = res.data.res_id
                                } else if (item.FieldTitle === '累计进度计量金额(元)') {
                                    item.value = res.data.add_money
                                    form.add_money = res.data.add_money
                                }
                            })
                            Info.FirstInfo[1].isShow = true
                        } else if (res.data.pay_type === 3) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '付款节点') {
                                    item.isShow = true
                                }
                            })
                            Info.FirstInfo[0].isShow = true
                            Info.FirstInfo[1].isShow = false
                            Info.FirstInfo[1].list.forEach((item, index) => {
                                if (item.FieldTitle === '结算单据') {
                                    item.value = res.data.res_id
                                } else if (item.FieldTitle === '结算金额(元)') {
                                    item.value = res.data.add_money
                                    form.add_money = res.data.add_money
                                }
                            })
                        }

                        Info.FirstInfo[2].isShow = true
                        Info.FirstInfo[3].list.forEach((item, index) => {
                            if (item.FieldTitle === '联系人') {
                                item.isShow = false
                            } else if (item.FieldTitle === '联系电话') {
                                item.isShow = false
                            }
                        })

                    } else {
                        form.outbound = res.data.outbound

                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '起草人') {
                                item.value = res.data.applyUsername
                            } else if (item.FieldTitle === '合同名称') {
                                item.value = res.data.con_name
                            } else if (item.FieldTitle === '合同编号') {
                                item.value = res.data.con_num
                            } else if (item.FieldTitle === '合同类别') {
                                item.value = res.data.contractDetail.con_type === 1 ? '采购合同' : res.data.contractDetail.con_type === 3 ? '其他合同' : ''
                            } else if (item.FieldTitle === '合同总金额(元)') {
                                item.value = res.data.con_money
                            } else if (item.FieldTitle === '项目名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '付款说明') {
                                item.isShow = true
                            } else if (item.FieldTitle === '本次开票金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '前期已开票金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '已累计付款(元)') {
                                item.isShow = false
                                item.value = res.data.used_pay_money || 0
                            } else if (item.FieldTitle === '剩余未付款(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '费用类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '付款单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '付款节点') {
                                item.isShow = false
                            } else if (item.FieldTitle === '付款类别') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].isShow = true
                        Info.FirstInfo[1].isShow = true
                        Info.FirstInfo[2].isShow = false
                        Info.FirstInfo[2].list.forEach((item, index) => {
                            if (item.FieldTitle === '出库单据') {
                                item.value = res.data.outbound
                            }
                        })

                        Info.FirstInfo[3].list.forEach((item, index) => {
                            if (item.FieldTitle === '联系人') {
                                item.isShow = false
                            } else if (item.FieldTitle === '联系电话') {
                                item.isShow = false
                            }
                        })
                    }

                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    if (res.data.is_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '欠票单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票原因') {
                                item.isShow = false
                            } else if (item.FieldTitle === '补票时间') {
                                item.isShow = false
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.apply_day ? moment(res.data.apply_day, 'YYYY-MM-DD') : '',
                        is_pro: res.data.is_pro,
                        pay_unit: res.data.pay_unit,
                        money_type: res.data.money_type,
                        pay_money: res.data.pay_money,
                        pay_info: res.data.pay_info,
                        invo_money: res.data.invo_money,
                        invo_type: res.data.invo_type,
                        pay_type: res.data.pay_type,
                        // used_invo_money: res.data.used_invo_money,
                        is_owing: res.data.is_owing,
                        unit_name: res.data.unit_name,
                        invo_type: res.data.invo_type,
                        money: res.data.money,
                        cause: res.data.cause,
                        replenish_invo: res.data.replenish_invo ? moment(res.data.replenish_invo, 'YYYY-MM-DD') : '',
                        pay_class: res.data.pay_class,
                        con_info: res.data.con_info,
                        col_number: res.data.col_number,
                        col_username: res.data.col_username,
                        bank: res.data.bank,
                        contacts: res.data.contacts,
                        phone: res.data.phone,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutPaymentContract = () => {
        let { dataSource, pages, con_number, con_name, form } = this.state
        let data = {
            project_id: form.project_id,
            con_number: con_number,
            con_name: con_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutPaymentContract(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_number: item.con_num,
                                con_class: item.con_class === 1 ? '材料' : item.con_class === 2 ? '劳务' : '/',
                                proName: item.proName,
                                con_money: item.con_money,
                                acc_iov: item.acc_iov,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutResearchContract = () => {
        let { dataSource, pages, con_party, con_type } = this.state
        let data = {
            con_party: con_party,
            con_type: con_type,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutResearchContract(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_number: item.con_num,
                                con_type: item.con_type === 1 ? '采购合同' : item.con_type === 3 ? '其他合同' : '/',
                                con_money: item.con_money,
                                usedPayMoney: item.usedPayMoney
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProgressSettlementSelect = () => {
        let { summaryValue, dataSource, pages, pro_name, supplier, } = this.state
        let data = {
            pro_name: pro_name,
            supplier: supplier,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        ProgressSettlementSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.progress.length) {
                        res.data.progress.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                proName: item.pro_name,
                                supplier: item.party_b,
                                month_pay: item.month_pay,
                                lastMonthMoney: item.lastMonthMoney,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_nurserySettlementSelect = () => {
        let { dataSource, pages, con_name, pro_name } = this.state
        let data = {
            con_name: con_name,
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        nurserySettlementSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                con_set_money: item.con_set_money,
                                pro_name: item.pro_name,
                                sup_name: item.sup_name,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_outboundOrderSelect = () => {
        let { dataSource, pages, } = this.state
        let data = {
            nur_id: '',
            is_product: 2,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        outboundOrderSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.inventory.length) {
                        res.data.inventory.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                nur_name: item.nur_name,
                                warehousing: item.warehousing,
                                total_money: item.all_price,
                                total_mou_toney: item.out_all_price,
                                total_payed_money: item.payed_money,
                                total_period_money: item.period_money,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.is_pro = data.is_pro
        form.pay_unit = data.pay_unit
        form.money_type = data.money_type
        form.pay_money = data.pay_money
        form.pay_info = data.pay_info
        form.invo_money = data.invo_money
        // form.used_invo_money = data.used_invo_money
        form.pay_type = data.pay_type
        form.is_owing = data.is_owing
        form.unit_name = data.unit_name || ''
        form.invo_type = data.invo_type || ''
        form.money = data.money || ''
        form.cause = data.cause || ''
        form.replenish_invo = data.replenish_invo ? data.replenish_invo.format('YYYY-MM-DD') : ''
        form.pay_class = data.pay_class
        form.con_info = data.con_info
        form.col_number = data.col_number
        form.col_username = data.col_username
        form.bank = data.bank
        form.contacts = data.contacts || ''
        form.phone = data.phone || ''
        // form.details = []

        // if (Info.FirstInfo[1].editable.dataSource.length) {
        //     Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
        //         let value = {
        //             money_type: data['money_type' + item.key] ? data['money_type' + item.key] : '',
        //             pay_money: data['pay_money' + item.key] ? data['pay_money' + item.key] : '',
        //             unit_name: data['unit_name' + item.key] ? data['unit_name' + item.key] : '',
        //             bank: data['bank' + item.key] ? data['bank' + item.key] : '',
        //             bank_num: data['bank_num' + item.key] ? data['bank_num' + item.key] : '',
        //         }

        //         form.details = [...form.details, value]
        //     })
        // }

        // form.details = JSON.stringify(form.details)

        createSettlementPayment(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = () => {
        const { Info } = this.state;
        const newData = {
            key: Info.FirstInfo[1].editable.dataSource.length + 1,
            money_type: '',
            pay_money: '',
            unit_name: '',
            bank: '',
            bank_num: ''
        };

        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]

        this.setState(preState => ({
            Info
        })
        )
    }

    handButton = (title, state) => {
        let { form, columns, TableInfo } = this.state
        if (state) {
            if (form.is_pro) {
                console.log(title)
                if (title === '选择合同') {
                    console.log(title)
                    if (form.is_pro === 1) {
                        columns = [
                            {
                                title: '合同名称',
                                dataIndex: 'con_name',
                                key: 'con_name',
                            },
                            {
                                title: '合同编号',
                                dataIndex: 'con_number',
                                key: 'con_number',
                            },
                            {
                                title: '合同类别',
                                dataIndex: 'con_class',
                                key: 'con_class',
                            },
                            {
                                title: '项目名称',
                                dataIndex: 'proName',
                                key: 'proName',
                            },
                            {
                                title: '合同总金额',
                                dataIndex: 'con_money',
                                key: 'con_money',
                            },
                        ]
                        TableInfo = [
                            {
                                FieldTitle: '合同名称', //字段标题
                                fieldName: 'con_name', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%',
                            }
                        ]
                        this.get_aboutPaymentContract()
                    } else {
                        columns = [
                            {
                                title: '合同名称',
                                dataIndex: 'con_name',
                                key: 'con_name',
                            },
                            {
                                title: '合同编号',
                                dataIndex: 'con_number',
                                key: 'con_number',
                            },
                            {
                                title: '合同类型',
                                dataIndex: 'con_type',
                                key: 'con_type',
                            },
                            {
                                title: '合同总金额',
                                dataIndex: 'con_money',
                                key: 'con_money',
                            },
                        ]
                        TableInfo = [
                            {
                                FieldTitle: '合同名称', //字段标题
                                fieldName: 'con_party', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '48%',
                            },
                            {
                                FieldTitle: '合同类别', //字段标题
                                fieldName: 'con_type', //字段名称
                                fieldType: '选择器',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '48%',
                                selectList: [
                                    {
                                        label: '采购合同',
                                        value: 1
                                    },
                                    {
                                        label: '其他合同',
                                        value: 3
                                    }
                                ]
                            }
                        ]
                        this.get_aboutResearchContract()
                    }
                } else if (title === '关联进度计量单据') {
                    columns = [
                        {
                            title: '合同名称',
                            dataIndex: 'con_name',
                            key: 'con_name',
                        },
                        {
                            title: '项目名称',
                            dataIndex: 'proName',
                            key: 'proName',
                        },
                        {
                            title: '供货单位',
                            dataIndex: 'supplier',
                            key: 'supplier',
                        },
                        {
                            title: '本次进度计量金额(元)',
                            dataIndex: 'month_pay',
                            key: 'month_pay',
                        },
                        {
                            title: '累计进度计量金额(元)',
                            dataIndex: 'lastMonthMoney',
                            key: 'lastMonthMoney',
                        }
                    ]

                    TableInfo = [
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '48%',
                        },
                        {
                            FieldTitle: '供货单位', //字段标题
                            fieldName: 'supplier', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '48%',
                        }
                    ]
                    this.get_ProgressSettlementSelect()
                } else if (title === '关联结算单据') {
                    columns = [
                        {
                            title: '合同名称',
                            dataIndex: 'con_name',
                            key: 'con_name',
                        },
                        {
                            title: '项目名称',
                            dataIndex: 'pro_name',
                            key: 'pro_name',
                        },
                        {
                            title: '供货单位',
                            dataIndex: 'sup_name',
                            key: 'sup_name',
                        },
                        {
                            title: '结算金额',
                            dataIndex: 'con_set_money',
                            key: 'con_set_money',
                        },
                    ]

                    TableInfo = [
                        {
                            FieldTitle: '合同名称', //字段标题
                            fieldName: 'con_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '48%',
                        },
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '48%',
                        },
                    ]
                    this.get_nurserySettlementSelect()
                } else if (title === '关联出库单据') {
                    columns = [
                        {
                            title: '出库日期',
                            dataIndex: 'warehousing',
                            key: 'warehousing',
                        },
                        {
                            title: '苗圃名称',
                            dataIndex: 'nur_name',
                            key: 'nur_name',
                        },
                        {
                            title: '生产结算合价',
                            dataIndex: 'total_money',
                            key: 'total_money',
                        },
                        {
                            title: '出库结算合价',
                            dataIndex: 'total_mou_toney',
                            key: 'total_mou_toney',
                        },
                        {
                            title: '累计已付金额',
                            dataIndex: 'total_payed_money',
                            key: 'total_payed_money',
                        },
                        {
                            title: '本期支付金额',
                            dataIndex: 'total_period_money',
                            key: 'total_period_money',
                        },
                    ]

                    TableInfo = []
                    this.get_outboundOrderSelect()
                }

                this.setState(preState => ({
                    columns,
                    TableInfo,
                    buttonTitle: title
                })
                )
            } else {
                message.error('请选择是否为项目付款!')
            }
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                buttonTitle: '',
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择合同') {
            form.con_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '合同名称') {
                    item.value = value[0].con_name
                } else if (item.FieldTitle === '合同编号') {
                    item.value = value[0].con_number
                } else if (item.FieldTitle === '合同类别') {
                    if (form.is_pro === 1) {
                        item.value = value[0].con_class
                    } else {
                        item.value = value[0].con_type
                    }
                } else if (item.FieldTitle === '项目名称') {
                    item.value = value[0].proName
                } else if (item.FieldTitle === '合同总金额(元)') {
                    item.value = value[0].con_money
                } else if (item.FieldTitle === '前期已开票金额(元)') {
                    if (form.is_pro === 1) {
                        item.value = value[0].acc_iov || 0
                        form.used_invo_money = value[0].acc_iov || 0
                    }
                } else if (item.FieldTitle === '已累计付款(元)') {
                    if (form.is_pro === 2) {
                        form.used_pay_money = value[0].usedPayMoney || 0
                        item.value = value[0].usedPayMoney || 0
                    }
                }
            })
        } else if (buttonTitle === '关联进度计量单据') {
            form.res_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '进度计量单据') {
                    item.value = value[0].key
                } else if (item.FieldTitle === '累计进度计量金额(元)') {
                    item.value = value[0].lastMonthMoney
                    form.add_money = value[0].lastMonthMoney
                }
            })
        } else if (buttonTitle === '关联结算单据') {
            form.res_id = value[0].key
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '结算单据') {
                    item.value = value[0].key
                } else if (item.FieldTitle === '结算金额(元)') {
                    item.value = value[0].con_set_money
                    form.add_money = value[0].con_set_money
                }
            })
        } else if (buttonTitle === '关联出库单据') {
            form.outbound = value[0].key
            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.FieldTitle === '出库单据') {
                    item.value = value[0].key
                }
            })
        }


        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleRadio = (value, title) => {
        let { Info, form } = this.state;
        if (title === '是否欠票') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = false
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = true
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = true
                    }
                })
            }
        } else if (title === '项目付款') {
            form.is_pro = value
            this.NewForm.current.formRef.current.setFieldsValue({
                money_type: '',
                pay_money: '',
                pay_info: '',
                invo_money: '',
                invo_type: '',
                // used_invo_money: '',
                is_owing: '',
                pay_class: '',
                con_info: '',
                col_number: '',
                col_username: '',
                bank: '',
                contacts: '',
                phone: '',
                enclosure: '',
            })
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '项目名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '付款说明') {
                        item.isShow = false
                    } else if (item.FieldTitle === '本次开票金额(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '前期已开票金额(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '已累计付款(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '剩余未付款(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '费用类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '付款单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '付款类别') {
                        item.isShow = false
                    } else if (item.FieldTitle === '付款节点') {
                        item.isShow = true
                    }
                })

                if (form.pay_type === 1) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '付款节点') {
                            item.isShow = false
                        }
                    })
                    Info.FirstInfo[0].isShow = true
                    Info.FirstInfo[1].isShow = true
                } else if (form.pay_type === 2) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '付款节点') {
                            item.isShow = true
                        }
                    })
                    Info.FirstInfo[0].isShow = false
                    Info.FirstInfo[1].isShow = true
                } else if (form.pay_type === 2) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '付款节点') {
                            item.isShow = true
                        }
                    })
                    Info.FirstInfo[0].isShow = true
                    Info.FirstInfo[1].isShow = false
                }

                Info.FirstInfo[2].isShow = true

                Info.FirstInfo[3].list.forEach((item, index) => {
                    if (item.FieldTitle === '联系人') {
                        item.isShow = false
                    } else if (item.FieldTitle === '联系电话') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '项目名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '付款说明') {
                        item.isShow = true
                    } else if (item.FieldTitle === '本次开票金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '前期已开票金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '已累计付款(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '剩余未付款(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '费用类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '付款单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '付款类别') {
                        item.isShow = true
                    } else if (item.FieldTitle === '付款节点') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[0].isShow = true
                Info.FirstInfo[1].isShow = true
                Info.FirstInfo[2].isShow = false

                Info.FirstInfo[3].list.forEach((item, index) => {
                    if (item.FieldTitle === '联系人') {
                        item.isShow = true
                    } else if (item.FieldTitle === '联系电话') {
                        item.isShow = true
                    }
                })
            }
        } else if (title === '付款类别') {
            form.pay_type = value
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '付款节点') {
                        item.isShow = false
                    }
                })
                Info.FirstInfo[0].isShow = true
                Info.FirstInfo[1].isShow = true
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '付款节点') {
                        item.isShow = true
                    }
                })
                Info.FirstInfo[0].isShow = false
                Info.FirstInfo[1].isShow = true
            } else if (value === 3) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '付款节点') {
                        item.isShow = true
                    }
                })
                Info.FirstInfo[0].isShow = true
                Info.FirstInfo[1].isShow = false
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    searchQuery = (value) => {
        let { form, buttonTitle } = this.state
        if (buttonTitle === '选择合同') {
            if (form.is_pro === 1) {
                this.setState({
                    con_name: value.con_name,
                    pages: {
                        page: 1,
                        total: 0,
                        limit: 10,
                    },
                }, () => {
                    this.get_aboutPaymentContract()
                })
            } else {
                this.setState({
                    con_type: value.con_type,
                    con_party: value.con_party,
                    pages: {
                        page: 1,
                        total: 0,
                        limit: 10,
                    },
                }, () => {
                    this.get_aboutResearchContract()
                })
            }
        } else if (buttonTitle === '关联进度计量单据') {
            this.setState({
                pro_name: value.pro_name,
                supplier: value.supplier,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_ProgressSettlementSelect()
            })
        } else if (buttonTitle === '关联结算单据') {
            this.setState({
                con_name: value.con_name,
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_nurserySettlementSelect()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            con_name: '',
            con_type: '',
            con_party: '',
            pro_name: '',
            supplier: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            if (form.is_pro === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.fieldName === fileName) {
                        if (item.value && item.value.length) {
                            item.value.forEach((em, ex) => {
                                storeList.forEach((m, x) => {
                                    if (em.table_id === m) {
                                        fileList.push(em)
                                    }
                                })
                            })
                        }
                        if (fileValue) {
                            fileList.push(fileValue)
                        }
                        item.value = fileList
                    }
                })
            } else {
                Info.FirstInfo[3].list.forEach((item, index) => {
                    if (item.fieldName === fileName) {
                        if (item.value && item.value.length) {
                            item.value.forEach((em, ex) => {
                                storeList.forEach((m, x) => {
                                    if (em.table_id === m) {
                                        fileList.push(em)
                                    }
                                })
                            })
                        }
                        if (fileValue) {
                            fileList.push(fileValue)
                        }
                        item.value = fileList
                    }
                })
            }

        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, form, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择合同') {
                if (form.is_pro === 1) {
                    this.get_aboutPaymentContract()
                } else {
                    this.get_aboutResearchContract()
                }
            } else if (buttonTitle === '关联进度计量单据') {
                this.get_ProgressSettlementSelect()
            } else if (buttonTitle === '关联结算单据') {
                this.get_nurserySettlementSelect()
            }
        })
    }

    handleSearch = (name) => {
        console.log(name)
        let { form } = this.state
        if (name === '进度计量单据') {
            if (!form.res_id) {
                message.error('未关联进度计量单据!')
                return
            }
            this.props.history.push({
                pathname: '/scientific_research/achievement_transformation/progressSettlement/NewItem',
                query: {
                    'tableId': form.res_id,
                }
            })
        } else if (name === '结算单据') {
            if (!form.res_id) {
                message.error('未关联结算单据!')
                return
            }
            this.props.history.push({
                pathname: '/scientific_research/achievement_transformation/nurserySettlement/NewItem',
                query: {
                    'tableId': form.res_id,
                }
            })
        } else {
            if (!form.outbound) {
                message.error('未关联出库单据!')
                return
            }
            this.props.history.push({
                pathname: '/scientific_research/achievement_transformation/deliveryfromstorage/NewItem',
                query: {
                    'tableId': form.outbound,
                }
            })
        }

    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} radioEvent={this.handleRadio} auditEvent={this.handleButton} rowAddEvent={this.handleWorkAdd} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}