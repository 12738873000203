import React from "react";
import * as echarts from 'echarts';
export default class EchartsTest extends React.Component {
    constructor(props) {
        super(props)

    }
    componentDidMount() {
        var chartDom = document.getElementById('pie');
        var myChart = echarts.init(chartDom);
        var option;

        console.log(this.props)

        console.log(Number(this.props.project.budgetNumber))
        console.log(Number(this.props.project.budget_excess))

        option = {
            title: {
                text: '',
                left: 'center',
                top: 'center'
            },
            color: ['#2E88CE', '#DFCB27'],
            series: [
                {
                    type: 'pie',
                    data: [
                        {
                            value: this.props.project.budgetNumber || 100,
                            name: '材料总量',
                        },
                        {
                            value: this.props.project.budget_excess || 0,
                            name: '材料超量'
                        }
                    ],
                    radius: ['70%', '30%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                }
            ]
        };

        option && myChart.setOption(option);

    }
    render() {
        return (
            <div>
                <div id="pie" style={{ width: '100%', height: '100%' }}>

                </div>
            </div>
        )
    }
}



