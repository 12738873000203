import React, { Component } from 'react'
import { Form, Input, message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createProjectPayment, projectPaymentDetail } from "../../../../utils/public"
import { selectProjectName, reviewApplyResult } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/projectFundsPayment.docx',
            templateName: '四川天艺生态园林集团股份有限公司工程款付款审批',
            tempData: {
                apply_date: '',
                user_name: '',
                pro_name: '',
                con_name: '',
                con_money: '',
                pay_money: '',
                collection: '',
                has_owing: '',
                unit_name: '',
                invo_type: '',
                money: '',
                replenish_invo: '',
                pay_reason: '',
                info: '',
                details: [],
                results: []
            },
            FlowModelName: "工程款支付审批",
            TableInfo: [{
                FieldTitle: '时间', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'project_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同名称', //字段标题
                fieldName: 'con_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '合同金额(元)', //字段标题
                fieldName: 'con_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '付款金额(元)', //字段标题
                fieldName: 'pay_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入付款金额(元)', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '收款单位', //字段标题
                fieldName: 'collection', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入收款单位', //必填要求
                value: '',
                width: '100%'
            },
            // {
            //     FieldTitle: '进度结算信息', //字段标题
            //     fieldName: 'set_info', //字段名称
            //     fieldType: '单选',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%',
            //     radioList: [
            //         { value: 1, label: "进度计量" },
            //         { value: 2, label: "结算金额" },
            //     ]
            // }, {
            //     FieldTitle: '进度计量', //字段标题
            //     fieldName: 'set_content', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%',
            //     isShow: true
            // }, 
            {
                FieldTitle: '结算金额', //字段标题
                fieldName: 'set_content', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入结算金额', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '付款事由', //字段标题
                fieldName: 'pay_reason', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入付款事由', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '是否欠票', //字段标题
                fieldName: 'has_owing', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择是否欠票', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ]
            },
            {
                FieldTitle: '欠票单位', //字段标题
                fieldName: 'unit_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票类型', //字段标题
                fieldName: 'invo_type', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票金额', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '欠票原因', //字段标题
                fieldName: 'cause', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '补票时间', //字段标题
                fieldName: 'replenish_invo', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [
                {
                    plateName: '费用明细',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '综合费用类别',
                                dataIndex: 'money_class',
                                key: 'money_class',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '本次付款金额(元)',
                                dataIndex: 'pay_money',
                                key: 'pay_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '公司名称',
                                dataIndex: 'company_name',
                                key: 'company_name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '开户行',
                                dataIndex: 'bank',
                                key: 'bank',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '银行账号',
                                dataIndex: 'bank_number',
                                key: 'bank_number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }
                        ]
                    },
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'managerName',
                key: 'managerName',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '95%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_PaymentDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_PaymentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        projectPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '综合费用类别',
                                dataIndex: 'money_class',
                                key: 'money_class',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'money_class' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '本次付款金额(元)',
                                dataIndex: 'pay_money',
                                key: 'pay_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'pay_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '公司名称',
                                dataIndex: 'company_name',
                                key: 'company_name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'company_name' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '开户行',
                                dataIndex: 'bank',
                                key: 'bank',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'bank' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '银行账号',
                                dataIndex: 'bank_number',
                                key: 'bank_number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'bank_number' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }
                        ]

                        if (res.data.Detail.status === 4) {
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.user_name = res.data.Detail.applyUserName
                            Info.tempData.pro_name = res.data.Detail.proName
                            Info.tempData.con_name = res.data.Detail.con_name
                            Info.tempData.con_money = res.data.Detail.con_money
                            Info.tempData.pay_money = res.data.Detail.pay_money
                            Info.tempData.collection = res.data.Detail.collection
                            Info.tempData.has_owing = res.data.Detail.has_owing === 1 ? '是' : '否'
                            Info.tempData.unit_name = res.data.Detail.has_owing === 1 ? res.data.Detail.unit_name : '/'
                            Info.tempData.invo_type = res.data.Detail.has_owing === 1 ? res.data.Detail.invo_type : '/'
                            Info.tempData.money = res.data.Detail.has_owing === 1 ? res.data.Detail.money : '/'
                            Info.tempData.cause = res.data.Detail.has_owing === 1 ? res.data.Detail.cause : '/'
                            Info.tempData.replenish_invo = res.data.Detail.has_owing === 1 ? res.data.Detail.replenish_invo : '/'
                            Info.tempData.pay_reason = res.data.Detail.pay_reason
                            Info.tempData.info = res.data.Detail.info
                            Info.tempData.details = []
                            Info.tempData.results = []

                            let details = JSON.parse(res.data.Detail.details)
                            console.log(details)
                            details.forEach((item, index) => {
                                let value = {
                                    money_class: item.money_class,
                                    pay_money: item.pay_money,
                                    company_name: item.company_name,
                                    bank: item.bank,
                                    bank_number: item.bank_number,
                                };
                                Info.tempData.details = [...Info.tempData.details, value]
                            })

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                            form.project_id = res.data.Detail.project_id
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    if (res.data.Detail.set_info === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '进度计量') {
                                item.isShow = false
                            }
                        })
                    }

                    if (res.data.Detail.set_info === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '结算金额') {
                                item.isShow = false
                            }
                        })
                    }

                    if (res.data.Detail.has_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '欠票单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票原因') {
                                item.isShow = false
                            } else if (item.FieldTitle === '补票时间') {
                                item.isShow = false
                            }
                        })
                    }

                    let details = JSON.parse(res.data.Detail.details)
                    details.forEach((item, index) => {
                        let key = Info.FirstInfo[0].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            money_class: item.money_class,
                            pay_money: item.pay_money,
                            company_name: item.company_name,
                            bank: item.bank,
                            bank_number: item.bank_number,
                        };
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'money_class' + key)()]: item.money_class,
                            [(() => 'pay_money' + key)()]: item.pay_money,
                            [(() => 'company_name' + key)()]: item.company_name,
                            [(() => 'bank' + key)()]: item.bank,
                            [(() => 'bank_number' + key)()]: item.bank_number,
                        })
                        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        con_name: res.data.Detail.con_name,
                        con_money: res.data.Detail.con_money,
                        pay_money: res.data.Detail.pay_money,
                        loan_money: res.data.Detail.loan_money,
                        collection: res.data.Detail.collection,
                        set_info: res.data.Detail.set_info,
                        set_content: res.data.Detail.set_content,
                        pay_reason: res.data.Detail.pay_reason,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                        has_owing: res.data.Detail.has_owing,
                        unit_name: res.data.Detail.unit_name || '',
                        invo_type: res.data.Detail.invo_type || '',
                        money: res.data.Detail.money || '',
                        cause: res.data.Detail.cause || '',
                        replenish_invo: res.data.Detail.replenish_invo ? moment(res.data.Detail.replenish_invo, 'YYYY-MM-DD') : '',
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.con_name = data.con_name
        form.con_money = data.con_money
        form.pay_money = data.pay_money
        form.collection = data.collection
        form.set_info = data.set_info
        form.set_content = data.set_content
        form.pay_reason = data.pay_reason
        form.info = data.info
        form.has_owing = data.has_owing
        form.unit_name = data.unit_name || ''
        form.invo_type = data.invo_type || ''
        form.money = data.money || ''
        form.cause = data.cause || ''
        form.replenish_invo = data.replenish_invo ? data.replenish_invo.format('YYYY-MM-DD') : ''
        form.details = []

        if (Info.FirstInfo[0].editable.dataSource.length) {
            Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                let value = {
                    money_class: data['money_class' + item.key] ? data['money_class' + item.key] : '',
                    pay_money: data['pay_money' + item.key] ? data['pay_money' + item.key] : '',
                    company_name: data['company_name' + item.key] ? data['company_name' + item.key] : '',
                    bank: data['bank' + item.key] ? data['bank' + item.key] : '',
                    bank_number: data['bank_number' + item.key] ? data['bank_number' + item.key] : '',
                }

                form.details = [...form.details, value]
            })
        }

        form.details = JSON.stringify(form.details)


        createProjectPayment(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleRadio = (value, title) => {
        let { Info } = this.state;
        if (title === '进度结算信息') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '进度计量') {
                        item.isShow = false
                    } else if (item.FieldTitle === '结算金额') {
                        item.isShow = true
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '进度计量') {
                        item.isShow = true
                    } else if (item.FieldTitle === '结算金额') {
                        item.isShow = false
                    }
                })
            }
        } else if (title === '是否欠票') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = false
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = true
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = true
                    }
                })
            }
        }
        this.setState(preState => ({
            Info
        }))
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    handleWorkAdd = () => {
        const { Info } = this.state;
        const newData = {
            key: Info.FirstInfo[0].editable.dataSource.length + 1,
            money_class: '',
            pay_money: '',
            company_name: '',
            bank: '',
            bank_number: ''
        };

        Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]

        this.setState(preState => ({
            Info
        })
        )
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'money_class' + e)()]: '',
            [(() => 'pay_money' + e)()]: '',
            [(() => 'company_name' + e)()]: '',
            [(() => 'bank' + e)()]: '',
            [(() => 'bank_number' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} radioEvent={this.handleRadio} auditEvent={this.handleButton} rowAddEvent={this.handleWorkAdd} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
