import React, { Component } from 'react'
import { Input, Form, message, DatePicker, ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import { getDepartmentJob, editUserArchives, userArchivesInformation } from "../../../../utils/oa"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "员工档案",
            TableInfo: [{
                FieldTitle: '姓名', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '企业微信账号', //字段标题
                fieldName: 'com_user_id', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '入职日期', //字段标题
                fieldName: 'job_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '职员状态', //字段标题
                fieldName: 'member_status', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "实习" },
                    { value: '2', label: "试用" },
                    { value: '3', label: "在职" },
                    { value: '4', label: "离职" },
                ]
            },
            {
                FieldTitle: '性别', //字段标题
                fieldName: 'sex', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "男" },
                    { value: '2', label: "女" },
                ]
            },
            {
                FieldTitle: '民族', //字段标题
                fieldName: 'nation', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '出生日期', //字段标题
                fieldName: 'date_birth', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '婚姻状态', //字段标题
                fieldName: 'marital_status', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "已婚" },
                    { value: '2', label: "未婚" },
                ]
            },
            {
                FieldTitle: '政治面貌', //字段标题
                fieldName: 'outlook', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "中共党员" },
                    { value: '2', label: "团员" },
                    { value: '3', label: "群众" },
                    { value: '4', label: "民族党派" },
                ]
            },
            {
                FieldTitle: '学历', //字段标题
                fieldName: 'education', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: '1', label: "本科" },
                    { value: '2', label: "专科" },
                    { value: '3', label: "高中" },
                    { value: '4', label: "初中" },
                    { value: '5', label: "硕士" },
                    { value: '6', label: "博士" },
                ]
            },
            {
                FieldTitle: '毕业院校', //字段标题
                fieldName: 'school', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '毕业年份', //字段标题
                fieldName: 'graduation_year', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '专业', //字段标题
                fieldName: 'major', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '籍贯', //字段标题
                fieldName: 'native_place', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '身份证号', //字段标题
                fieldName: 'id_cart', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '身份证地址', //字段标题
                fieldName: 'cart_address', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '现住址', //字段标题
                fieldName: 'place_residence', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '紧急联系人', //字段标题
                fieldName: 'contacts', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '紧急联系电话', //字段标题
                fieldName: 'contact_phone', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '手机号', //字段标题
                fieldName: 'phone', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '工作电话', //字段标题
                fieldName: 'work_phone', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '邮箱', //字段标题
                fieldName: 'email', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '部门', //字段标题
                fieldName: 'department_id', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: []
            },
            {
                FieldTitle: '职位', //字段标题
                fieldName: 'position_id', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: []
            },
            {
                FieldTitle: '社保卡号', //字段标题
                fieldName: 'social_security', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '劳动合同编号', //字段标题
                fieldName: 'labor_contract', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '转正日期', //字段标题
                fieldName: 'regular_worker', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '合同年限', //字段标题
                fieldName: 'contract_term', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "1" },
                    { value: 2, label: "3" },
                    { value: 3, label: "5" },
                ]
            }, {
                FieldTitle: '工资开户行', //字段标题
                fieldName: 'bank_deposit', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '工资卡号', //字段标题
                fieldName: 'salary_card', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '员工证件材料', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '社保办理截图', //字段标题
                fieldName: 'social', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '劳动合同截图', //字段标题
                fieldName: 'labor', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [
                {
                    plateName: '工作经历',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width:'80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            // {
                            //     title: '操作',
                            //     dataIndex: 'controls',
                            //     key: 'controls',
                            //     width: '100px',
                            //     render: (text, record, index) => {
                            //         return (
                            //             <div className='download' onClick={() => {
                            //                 this.handleDel(index, 0)
                            //             }}>
                            //                 删除
                            //             </div>
                            //         )
                            //     }
                            // },
                            {
                                title: '开始时间',
                                dataIndex: 'start',
                                key: 'start',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'start_01_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        onChange={(e) => this.PickerChange(e, record.key, 'start', 0)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    } else {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'start_01_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        disabled
                                                        onChange={(e) => this.PickerChange(e, record.key, 'start', 0)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    }
                                }

                            },
                            {
                                title: '结束时间',
                                dataIndex: 'end',
                                key: 'end',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'end_01_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        onChange={(e) => this.PickerChange(e, record.key, 'end', 0)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    } else {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'end_01_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        disabled
                                                        onChange={(e) => this.PickerChange(e, record.key, 'end', 0)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    }
                                }

                            },
                            {
                                title: '任职单位及部门',
                                dataIndex: 'unit_de',
                                key: 'unit_de',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'unit_de_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'unit_de', 0)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'unit_de_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'unit_de', 0)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '担任职务',
                                dataIndex: 'job',
                                key: 'job',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'job_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'job', 0)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'job_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'job', 0)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '证明人',
                                dataIndex: 'witness',
                                key: 'witness',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'witness_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'witness', 0)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'witness_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'witness', 0)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '联系方式',
                                dataIndex: 'wit_phone',
                                key: 'wit_phone',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'wit_phone_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'wit_phone', 0)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'wit_phone_01_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'wit_phone', 0)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]
                    },
                },
                {
                    plateName: '教育经历',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width:'80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            // {
                            //     title: '操作',
                            //     dataIndex: 'controls',
                            //     key: 'controls',
                            //     width: '100px',
                            //     render: (text, record, index) => {
                            //         return (
                            //             <div className='download' onClick={() => {
                            //                 this.handleDel(index, 0)
                            //             }}>
                            //                 删除
                            //             </div>
                            //         )
                            //     }
                            // },
                            {
                                title: '开始时间',
                                dataIndex: 'start',
                                key: 'start',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'start_02_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        onChange={(e) => this.PickerChange(e, record.key, 'start', 1)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    } else {
                                        return (
                                            < ConfigProvider locale={zhCN} >
                                                <Form.Item name={'start_02_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        disabled
                                                        onChange={(e) => this.PickerChange(e, record.key, 'start', 1)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider >
                                        )
                                    }
                                }

                            },
                            {
                                title: '结束时间',
                                dataIndex: 'end',
                                key: 'end',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <ConfigProvider locale={zhCN}>
                                                <Form.Item name={'end_02_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        onChange={(e) => this.PickerChange(e, record.key, 'end', 1)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )
                                    } else {
                                        return (
                                            <ConfigProvider locale={zhCN}>
                                                <Form.Item name={'end_02_' + record.key} initialValue={text}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format='YYYY-MM-DD'
                                                        disabled
                                                        onChange={(e) => this.PickerChange(e, record.key, 'end', 1)}
                                                    />
                                                </Form.Item>
                                            </ConfigProvider>
                                        )
                                    }
                                }

                            },
                            {
                                title: '毕业院校',
                                dataIndex: 'school',
                                key: 'school',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'school_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'school', 1)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'school_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'school', 1)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '所读专业',
                                dataIndex: 'major',
                                key: 'major',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'major_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'major', 1)}>
                                            <Input />
                                        </Form.Item>

                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'major_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'major', 1)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '证明人',
                                dataIndex: 'witness',
                                key: 'witness',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'witness_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'witness', 1)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'witness_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'witness', 1)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '联系方式',
                                dataIndex: 'wit_phone',
                                key: 'wit_phone',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'wit_phone_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'wit_phone', 1)}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'wit_phone_02_' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'wit_phone', 1)}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]
                    },
                },
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        departmentList: [],
        form: {},
        isEdit: false,
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_DepartmentJob()
    }

    get_userArchivesInformation = (tableId) => {
        const { Info, form, isEdit, departmentList } = this.state;
        let data = {
            user_id: tableId
        }
        userArchivesInformation(data).then(
            (res) => {
                if (!res.code) {
                    if (!isEdit) {
                        Info.state = 2
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.userInformation.enclosure.length) {
                                if (item.FieldTitle === '员工证件材料') {
                                    item.isShow = true
                                }
                            }

                            if (!res.data.userInformation.social_security) {
                                if (item.FieldTitle === '社保办理截图') {
                                    item.isShow = true
                                }
                            }

                            if (!res.data.userInformation.labor_contract) {
                                if (item.FieldTitle === '劳动合同截图') {
                                    item.isShow = true
                                }
                            }
                        })

                        // Info.FirstInfo[0].editable.columns.splice(0, 1)

                        // Info.FirstInfo[1].editable.columns.splice(0, 1)
                    } else {
                        Info.state = 1
                    }
                    form.table_id = tableId

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '职位') {
                            item.selectList = []
                            departmentList.forEach((em, ex) => {
                                if (em.id === res.data.userInformation.department_id) {
                                    console.log(0)
                                    em.subset.forEach((m, x) => {
                                        let select = {
                                            value: Number(m.id),
                                            label: m.company_job
                                        }

                                        item.selectList = [...item.selectList, select]
                                    })
                                }
                            })
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '员工证件材料') {
                            item.value = res.data.userInformation.enclosure.length ? res.data.userInformation.enclosure : ''
                        } else if (item.FieldTitle === '社保办理截图') {
                            item.value = res.data.userInformation.social_security ? res.data.userInformation.social_security : ''
                        } else if (item.FieldTitle === '劳动合同截图') {
                            item.value = res.data.userInformation.labor_contract ? res.data.userInformation.labor_contract : ''
                        }
                    })

                    if (res.data.userInformation.workExperience.length) {
                        res.data.userInformation.workExperience.forEach((item, index) => {
                            item.key = index + 1
                            item.start = item.start ? moment(item.start, 'YYYY-MM-DD') : ''
                            item.end = item.end ? moment(item.end, 'YYYY-MM-DD') : ''
                        })

                        Info.FirstInfo[0].editable.dataSource = res.data.userInformation.workExperience

                    }

                    if (res.data.userInformation.schools.length) {
                        res.data.userInformation.schools.forEach((item, index) => {
                            item.key = index + 1
                            item.start = item.start ? moment(item.start, 'YYYY-MM-DD') : ''
                            item.end = item.end ? moment(item.end, 'YYYY-MM-DD') : ''
                        })

                        Info.FirstInfo[1].editable.dataSource = res.data.userInformation.schools

                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        name: res.data.userInformation.name,
                        com_user_id: res.data.userInformation.com_userid,
                        job_day: res.data.userInformation.job_day ? moment(res.data.userInformation.job_day, 'YYYY-MM-DD') : '',
                        member_status: res.data.userInformation.member_status,
                        sex: res.data.userInformation.sex,
                        nation: res.data.userInformation.nation,
                        date_birth: res.data.userInformation.date_birth ? moment(res.data.userInformation.date_birth, 'YYYY-MM-DD') : '',
                        marital_status: res.data.userInformation.marital_status,
                        outlook: res.data.userInformation.outlook,
                        education: res.data.userInformation.education,
                        school: res.data.userInformation.school,
                        graduation_year: res.data.userInformation.graduation_year,
                        major: res.data.userInformation.major,
                        native_place: res.data.userInformation.native_place,
                        id_cart: res.data.userInformation.id_cart,
                        cart_address: res.data.userInformation.cart_address,
                        place_residence: res.data.userInformation.place_residence,
                        contacts: res.data.userInformation.contacts,
                        contact_phone: res.data.userInformation.contact_phone,
                        phone: res.data.userInformation.phone,
                        work_phone: res.data.userInformation.work_phone,
                        email: res.data.userInformation.email,
                        department_id: res.data.userInformation.department_id,
                        position_id: res.data.userInformation.position_id,
                        // phone_cost: res.data.userInformation.phone_cost,
                        // restaurant_cost: res.data.userInformation.restaurant_cost,
                        bank_deposit: res.data.userInformation.bank_deposit,
                        salary_card: res.data.userInformation.salary_card,
                        social_security: res.data.userInformation.social_security,
                        labor_contract: res.data.userInformation.labor_contract,
                        regular_worker: res.data.userInformation.regular_worker ? moment(res.data.userInformation.regular_worker, 'YYYY-MM-DD') : '',
                        contract_term: res.data.userInformation.contract_term,
                        enclosure: res.data.userInformation.enclosure,
                        social: res.data.userInformation.social_security,
                        labor: res.data.userInformation.labor_contract,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                } else {
                    message.error(res.data[0] || '数据获取失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DepartmentJob() {
        let { Info } = this.state
        getDepartmentJob().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '部门') {
                            res.data.des.forEach((em, ex) => {
                                let select = {
                                    value: Number(em.id),
                                    label: em.name
                                }
                                item.selectList = [...item.selectList, select]
                            })
                        }
                    })
                    this.setState(preState => ({
                        Info,
                        departmentList: res.data.des,
                        isEdit: this.props.location.query.isEdit
                    }), () => {
                        let tableId = this.props.location.query.tableId
                        this.get_userArchivesInformation(tableId)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.name = data.name
        form.com_user_id = data.com_user_id
        form.job_day = data.job_day ? data.job_day.format('YYYY-MM-DD') : ''
        form.member_status = data.member_status
        form.sex = data.sex || ''
        form.nation = data.nation || ''
        form.date_birth = data.date_birth ? data.date_birth.format('YYYY-MM-DD') : ''
        form.marital_status = data.marital_status || ''
        form.outlook = data.outlook || ''
        form.education = data.education || ''
        form.school = data.school || ''
        form.graduation_year = data.graduation_year || ''
        form.major = data.major || ''
        form.native_place = data.native_place || ''
        form.id_cart = data.id_cart || ''
        form.cart_address = data.cart_address || ''
        form.place_residence = data.place_residence || ''
        form.contacts = data.contacts || ''
        form.contact_phone = data.contact_phone || ''
        form.phone = data.phone || ''
        form.work_phone = data.work_phone || ''
        form.email = data.email || ''
        form.department_id = data.department_id || ''
        form.position_id = data.position_id || ''
        // form.phone_cost = data.phone_cost || ''
        // form.restaurant_cost = data.restaurant_cost || ''
        form.bank_deposit = data.bank_deposit || ''
        form.salary_card = data.salary_card || ''
        form.social_security = data.social_security || ''
        form.labor_contract = data.labor_contract || ''
        form.regular_worker = data.regular_worker ? data.regular_worker.format('YYYY-MM-DD') : ''
        form.contract_term = data.contract_term || ''
        form.workExperience = Info.FirstInfo[0].editable.dataSource || ''
        form.schools = Info.FirstInfo[1].editable.dataSource || ''

        form.workExperience.forEach((item, index) => {
            index++
            if (!item.start) {
                item.start = data['work_start_time' + index] ? data['work_start_time' + index] : ''
                item.end = data['work_end_time' + index] ? data['work_end_time' + index] : ''
                item.unit_de = data['work_employer' + index] ? data['work_employer' + index] : ''
                item.job = data['work_position' + index] ? data['work_position' + index] : ''
                item.witness = data['work_certifier' + index] ? data['work_certifier' + index] : ''
                item.wit_phone = data['work_contact_way' + index] ? data['work_contact_way' + index] : ''
            }
        })

        form.schools.forEach((item, index) => {
            index++
            if (!item.start) {
                item.start = data['education_start_time' + index] ? data['education_start_time' + index] : ''
                item.end = data['education_end_time' + index] ? data['education_end_time' + index] : ''
                item.school = data['education_academy' + index] ? data['education_academy' + index] : ''
                item.major = data['education_major' + index] ? data['education_major' + index] : ''
                item.witness = data['education_certifier' + index] ? data['education_certifier' + index] : ''
                item.wit_phone = data['education_contact_way' + index] ? data['education_contact_way' + index] : ''
            }
        })

        form.workExperience = JSON.stringify(form.workExperience)
        form.schools = JSON.stringify(form.schools)

        editUserArchives(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        if (name === '工作经历') {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                let key = Info.FirstInfo[0].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                    start: '',
                    end: '',
                    unit_de: '',
                    job: '',
                    witness: '',
                    wit_phone: ''
                };
                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
            } else {
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource.length + 1,
                    start: '',
                    end: '',
                    unit_de: '',
                    job: '',
                    witness: '',
                    wit_phone: ''
                };
                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
            }
        } else {
            if (Info.FirstInfo[1].editable.dataSource.length) {
                let key = Info.FirstInfo[1].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                    start: '',
                    end: '',
                    school: '',
                    major: '',
                    witness: '',
                    wit_phone: ''
                };
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
            } else {
                const newData = {
                    key: Info.FirstInfo[1].editable.dataSource.length + 1,
                    start: '',
                    end: '',
                    school: '',
                    major: '',
                    witness: '',
                    wit_phone: ''
                };
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
            }
        }
        this.setState({
            Info
        });
    }

    InputChange = (e, id, title, index) => {
        let { Info } = this.state;
        if (index === 0) {
            Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
                if (em.id === id) {
                    em[title] = e.target.value
                }
            })
        } else {
            Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
                if (em.id === id) {
                    em[title] = e.target.value
                }
            })
        }

        this.setState(preState => ({
            Info
        }))
    }

    PickerChange = (e, key, title, index) => {
        let { Info } = this.state;

        if (index === 0) {
            Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
                if (em.key === key) {
                    em[title] = e.format('YYYY-MM-DD')
                }
            })
        } else {
            Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
                if (em.key === key) {
                    em[title] = e.format('YYYY-MM-DD')
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handSelector = (id) => {
        const { Info, departmentList } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '职位') {
                departmentList.forEach((em, ex) => {
                    if (em.id === id) {
                        em.subset.forEach((m, x) => {
                            let select = {
                                value: Number(m.id),
                                label: m.company_job
                            }
                            item.selectList = [...item.selectList, select]
                        })
                    }
                })
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handleDel = (key, state) => {
        let { Info } = this.state
        let dataSource = []
        Info.FirstInfo[state].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[state].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} selectorEvent={this.handSelector} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
