import http from '../utils/http';

//添加编辑客户管理
function createCustomerManagement(data) {
    return http("post", '/api/createCustomerManagement', data);
}

//客户管理列表
function CustomerManagementList(data) {
    return http("get", '/api/CustomerManagementList', data);
}

//客户管理详情
function CustomerManagementDetail(data) {
    return http("get", '/api/CustomerManagementDetail', data);
}

//添加编辑科技项目申报
function createTechnologyApplication(data) {
    return http("post", '/api/createTechnologyApplication', data);
}

//科技项目申报列表
function TechnologyApplicationList(data) {
    return http("get", '/api/TechnologyApplicationList', data);
}

//科技项目申报列表详情
function TechnologyApplicationDetail(data) {
    return http("get", '/api/TechnologyApplicationDetail', data);
}

//科技项目公司合同审批辅助数据
function TechnologyAuxiliaryData(data) {
    return http("get", '/api/TechnologyAuxiliaryData', data);
}

//科技项目添加编辑公司合同审批
function createTechnologyContractApproval(data) {
    return http("post", '/api/createTechnologyContractApproval', data);
}

//科技项目公司合同审批列表
function contractTechnologyApprovalList(data) {
    return http("get", '/api/contractTechnologyApprovalList', data);
}

//科技项目主合同查询
function selectTechnologyNature(data) {
    return http("get", '/api/selectTechnologyNature', data);
}

//科技项目公司合同审批详情
function contractTechnologyApprovalDetail(data) {
    return http("get", '/api/contractTechnologyApprovalDetail', data);
}

//科技项目付款审批列表
function TechnologyComResearchList(data) {
    return http("get", '/api/TechnologyComResearchList', data);
}

//科技项目付款审批详情
function TechnologyComResearchDetail(data) {
    return http("get", '/api/TechnologyComResearchDetail', data);
}

//科技项目添加编辑付款审批
function createTechnologyComResearch(data) {
    return http("post", '/api/createTechnologyComResearch', data);
}

//科技项目申报结果确认
function technologyConfirms(data) {
    return http("post", '/api/technologyConfirms', data);
}

//添加编辑研发项目申报
function createDevelopmentApplication(data) {
    return http("post", '/api/createDevelopmentApplication', data);
}

//研发项目申报列表
function DevelopmentApplicationList(data) {
    return http("get", '/api/DevelopmentApplicationList', data);
}

//研发项目申报详情
function DevelopmentApplicationDetail(data) {
    return http("get", '/api/DevelopmentApplicationDetail', data);
}

//研发项目申报主合同查询
function selectDevelopmentNature(data) {
    return http("get", '/api/selectDevelopmentNature', data);
}

//研发项目申报公司合同审批列表
function contractDevelopmentApprovalList(data) {
    return http("get", '/api/contractDevelopmentApprovalList', data);
}

//研发项目申报公司合同审批详情
function contractDevelopmentApprovalDetail(data) {
    return http("get", '/api/contractDevelopmentApprovalDetail', data);
}

//研发项目申报添加编辑公司合同审批
function createDevelopmentContractApproval(data) {
    return http("post", '/api/createDevelopmentContractApproval', data);
}

//研发项目添加编辑付款审批
function createDevelopmentComResearch(data) {
    return http("post", '/api/createDevelopmentComResearch', data);
}

//研发项目付款审批列表
function DevelopmentComResearchList(data) {
    return http("get", '/api/DevelopmentComResearchList', data);
}

//研发项目付款审批详情
function DevelopmentComResearchDetail(data) {
    return http("get", '/api/DevelopmentComResearchDetail', data);
}

//获取研发项目信息
function getDevelopmentInfo(data) {
    return http("get", '/api/getDevelopmentInfo', data);
}

//添加编辑中期检查报告
function createDevelopmentInspect(data) {
    return http("post", '/api/createDevelopmentInspect', data);
}

//中期检查详情
function DevelopmentInspectDetail(data) {
    return http("get", '/api/DevelopmentInspectDetail', data);
}

//结题验收申请详情
function DevelopmentAcceptanceDetail(data) {
    return http("get", '/api/DevelopmentAcceptanceDetail', data);
}

//添加编辑结题验收申请
function createDevelopmentAcceptances(data) {
    return http("post", '/api/createDevelopmentAcceptances', data);
}

//延期结题申请列表
function DevelopmentExtensionList(data) {
    return http("get", '/api/DevelopmentExtensionList', data);
}

//延期结题申请详情
function DevelopmentExtensionDetail(data) {
    return http("get", '/api/DevelopmentExtensionDetail', data);
}

//添加编辑延期结题申请
function createDevelopmentExtension(data) {
    return http("post", '/api/createDevelopmentExtension', data);
}

//添加编辑成果信息登记
function createKnowledgeAchievements(data) {
    return http("post", '/api/createKnowledgeAchievements', data);
}

//成果管理列表
function KnowledgeAchievementsList(data) {
    return http("get", '/api/KnowledgeAchievementsList', data);
}

//成果管理详情
function KnowledgeAchievementsDetail(data) {
    return http("get", '/api/KnowledgeAchievementsDetail', data);
}

//成果信息登记辅助数据
function knowledgeAchievementsAuxiliaryData(data) {
    return http("get", '/api/knowledgeAchievementsAuxiliaryData', data);
}

//删除成果信息
function deleteKnowledgeAchievement(data) {
    return http("post", '/api/deleteKnowledgeAchievement', data);
}

//添加编辑证书信息
function createCertificateLog(data) {
    return http("post", '/api/createCertificateLog', data);
}

//证书列表
function CertificateLogList(data) {
    return http("get", '/api/CertificateLogList', data);
}

//证书详情
function CertificateLogDetail(data) {
    return http("get", '/api/CertificateLogDetail', data);
}

//添加编辑文件夹
function createResourceManager(data) {
    return http("post", '/api/createResourceManager', data);
}

//添加文件夹文件
function addResourceFileUpload(data) {
    return http("post", '/api/addResourceFileUpload', data);
}

//文件管理列表
function fileResourceList(data) {
    return http("get", '/api/fileResourceList', data);
}

//删除文件
function deleteResourceFile(data) {
    return http("post", '/api/deleteResourceFile', data);
}

//删除文件夹
function deleteResourceFolder(data) {
    return http("post", '/api/deleteResourceFolder', data);
}

//添加养护项目
function createMaintenanceInspection(data) {
    return http("post", '/api/createMaintenanceInspection', data);
}

//养护项目列表
function MaintenanceInspectionList(data) {
    return http("get", '/api/MaintenanceInspectionList', data);
}

//养护项目详情
function MaintenanceInspectionDetail(data) {
    return http("get", '/api/MaintenanceInspectionDetail', data);
}

//新增养护巡查记录
function createMaintenanceLog(data) {
    return http("post", '/api//createMaintenanceLog', data);
}

//巡查养护记录列表
function MaintenanceLogList(data) {
    return http("get", '/api/MaintenanceLogList', data);
}

//巡查养护记录详情
function MaintenanceLogDetail(data) {
    return http("get", '/api/MaintenanceLogDetail', data);
}

//养护项目总列表
function MaintenanceTallInspectionList(data) {
    return http("get", '/api/MaintenanceTallInspectionList', data);
}

//科研合同审批列表
function contractResearchApprovalList(data) {
    return http("get", '/api/contractResearchApprovalList', data);
}

//科研合同审批详情
function contractResearchApprovalDetail(data) {
    return http("get", '/api/contractResearchApprovalDetail', data);
}
//获取科技项目申报年份列表
function getTechYear(data) {
    return http("get", '/api/getTechYear', data);
}
//获取研发项目年份列表
function getDevelopmentTechYear(data) {
    return http("get", '/api/getDevelopmentTechYear', data);
}

//添加编辑苗圃
function createNursery(data) {
    return http("post", '/api/createNursery', data);
}

//苗圃列表
function NurseryList(data) {
    return http("get", '/api/NurseryList', data);
}

//苗圃详情
function NurseryDetail(data) {
    return http("get", '/api/NurseryDetail', data);
}

//添加编辑苗木
function createPlants(data) {
    return http("post", '/api/createPlants', data);
}

//苗木列表
function plantList(data) {
    return http("get", '/api/plantList', data);
}

//苗木详情
function plantDetail(data) {
    return http("get", '/api/plantDetail', data);
}

//苗木入库导入
function importInventory(data) {
    return http("post", '/api/importInventory', data);
}


//苗木入库
function createSeedlingInventory(data) {
    return http("post", '/api/createSeedlingInventory', data);
}

//入库管理列表
function SeedlingInventoryList(data) {
    return http("get", '/api/SeedlingInventoryList', data);
}

//入库管理详情
function SeedlingInventoryDetail(data) {
    return http("get", '/api/SeedlingInventoryDetail', data);
}

//查询获取苗圃列表
function selectNurseryList(data) {
    return http("get", '/api/selectNurseryList', data);
}

//苗木库存统计
function InventoryStatistics(data) {
    return http("get", '/api/InventoryStatistics', data);
}

//苗木库存批次统计
function SeedlingsBatchStatistics(data) {
    return http("get", '/api/SeedlingsBatchStatistics', data);
}

//编辑苗木批次库存状态
function ShipmentStatusEditing(data) {
    return http("post", '/api/ShipmentStatusEditing', data);
}

//358项目立项辅助数据
function researchProjectAuxiliary(data) {
    return http("get", '/api/researchProjectAuxiliary', data);
}

//358项目立项列表
function researchProjectList(data) {
    return http("get", '/api/researchProjectList', data);
}

//358项目立项详情
function researchProjectDetail(data) {
    return http("get", '/api/researchProjectDetail', data);
}

//科研中心358项目项目列表
function researchProjectMain(data) {
    return http("get", '/api/researchProjectMain', data);
}

//添加编辑358项目立项
function createResearchProject(data) {
    return http("post", '/api/createResearchProject', data);
}

//358项目编辑竣工状态
function EditProjectOver(data) {
    return http("post", '/api/EditProjectOver', data);
}

//合同审批辅助数据
function researchContractAuxiliary(data) {
    return http("get", '/api/researchContractAuxiliary', data);
}

//358项目合同审批列表
function researchContractList(data) {
    return http("get", '/api/researchContractList', data);
}

//358项目合同审批详情
function researchContractDetail(data) {
    return http("get", '/api/researchContractDetail', data);
}

//创建编辑358项目合同审批
function createResearchContract(data) {
    return http("post", '/api/createResearchContract', data);
}

//合同审批辅助数据
function researchConAuxiliary(data) {
    return http("get", '/api/researchConAuxiliary', data);
}

//358合同审批列表
function researchConList(data) {
    return http("get", '/api/researchConList', data);
}

//358合同审批详情
function researchConDetail(data) {
    return http("get", '/api/researchConDetail', data);
}

//创建编辑358合同审批
function createResearchCon(data) {
    return http("post", '/api/createResearchCon', data);
}

//苗圃结算审批合同查询
function NurserySettlementContract(data) {
    return http("get", '/api/NurserySettlementContract', data);
}

//苗圃结算审批列表
function nurserySettlementList(data) {
    return http("get", '/api/nurserySettlementList', data);
}

//苗圃结算审批详情
function nurserySettlementDetail(data) {
    return http("get", '/api/nurserySettlementDetail', data);
}

//添加编辑苗圃结算审批
function createNurserySettlement(data) {
    return http("post", '/api/createNurserySettlement', data);
}

//358合同审批辅助数据
function SettlementPaymentAuxiliary(data) {
    return http("get", '/api/SettlementPaymentAuxiliary', data);
}

//358付款审批项目合同查询
function aboutPaymentContract(data) {
    return http("get", '/api/aboutPaymentContract', data);
}

//358付款审批列表
function SettlementPaymentList(data) {
    return http("get", '/api/SettlementPaymentList', data);
}

//358付款审批详情
function SettlementPaymentDetail(data) {
    return http("get", '/api/SettlementPaymentDetail', data);
}

//358添加编辑付款审批
function createSettlementPayment(data) {
    return http("post", '/api/createSettlementPayment', data);
}

//358付款审批进度计量查询
function ProgressSettlementSelect(data) {
    return http("get", '/api/ProgressSettlementSelect', data);
}

//358付款审批 结算审批查询
function nurserySettlementSelect(data) {
    return http("get", '/api/nurserySettlementSelect', data);
}

//认质核价审批列表
function RecognitionPriceList(data) {
    return http("get", '/api/RecognitionPriceList', data);
}

//认质核价审批详情
function RecognitionPriceDetail(data) {
    return http("get", '/api/RecognitionPriceDetail', data);
}

//添加编辑认质核价审批
function createRecognitionPrice(data) {
    return http("post", '/api/createRecognitionPrice', data);
}

//358临时用工申请列表
function ResTemporaryList(data) {
    return http("get", '/api/ResTemporaryList', data);
}

//358临时用工申请详情
function ResTemporaryDetail(data) {
    return http("get", '/api/ResTemporaryDetail', data);
}

//添加编辑358临时用工申请
function createResTemporary(data) {
    return http("post", '/api/createResTemporary', data);
}

//358付款审批合同审批查询
function aboutResearchContract(data) {
    return http("get", '/api/aboutResearchContract', data);
}

//358进度结算-合同查询
function ResProgressSelectCon(data) {
    return http("get", '/api/ResProgressSelectCon', data);
}

//358进度结算审批列表
function ResProgressSettlementList(data) {
    return http("get", '/api/ResProgressSettlementList', data);
}

//358进度计量审批详情
function ResProgressSettlementDetail(data) {
    return http("get", '/api/ResProgressSettlementDetail', data);
}

//添加编辑358进度计量审批
function createResProgressSettlement(data) {
    return http("post", '/api/createResProgressSettlement', data);
}

//358项目数据总看板
function ResearchProjectDataList(data) {
    return http("post", '/api/ResearchProjectDataList', data);
}

//358项目看板数据详情
function ResearchProjectDataInformation(data) {
    return http("post", '/api/ResearchProjectDataInformation', data);
}

//358付款审批出库单查询
function outboundOrderSelect(data) {
    return http("get", '/api/outboundOrderSelect', data);
}


export {
    createCustomerManagement,
    CustomerManagementList,
    CustomerManagementDetail,
    createTechnologyApplication,
    TechnologyApplicationList,
    TechnologyApplicationDetail,
    TechnologyAuxiliaryData,
    createTechnologyContractApproval,
    contractTechnologyApprovalList,
    selectTechnologyNature,
    contractTechnologyApprovalDetail,
    TechnologyComResearchList,
    TechnologyComResearchDetail,
    createTechnologyComResearch,
    technologyConfirms,
    createDevelopmentApplication,
    DevelopmentApplicationList,
    DevelopmentApplicationDetail,
    selectDevelopmentNature,
    contractDevelopmentApprovalList,
    contractDevelopmentApprovalDetail,
    createDevelopmentContractApproval,
    createDevelopmentComResearch,
    DevelopmentComResearchList,
    DevelopmentComResearchDetail,
    getDevelopmentInfo,
    createDevelopmentInspect,
    DevelopmentInspectDetail,
    DevelopmentAcceptanceDetail,
    createDevelopmentAcceptances,
    DevelopmentExtensionList,
    DevelopmentExtensionDetail,
    createDevelopmentExtension,
    createKnowledgeAchievements,
    KnowledgeAchievementsList,
    KnowledgeAchievementsDetail,
    knowledgeAchievementsAuxiliaryData,
    deleteKnowledgeAchievement,
    createCertificateLog,
    CertificateLogList,
    CertificateLogDetail,
    createResourceManager,
    addResourceFileUpload,
    fileResourceList,
    deleteResourceFile,
    deleteResourceFolder,
    createMaintenanceInspection,
    MaintenanceInspectionList,
    MaintenanceInspectionDetail,
    createMaintenanceLog,
    MaintenanceLogList,
    MaintenanceLogDetail,
    MaintenanceTallInspectionList,
    contractResearchApprovalList,
    contractResearchApprovalDetail,
    getTechYear,
    getDevelopmentTechYear,
    createNursery,
    NurseryList,
    NurseryDetail,
    createPlants,
    plantList,
    plantDetail,
    importInventory,
    createSeedlingInventory,
    SeedlingInventoryList,
    SeedlingInventoryDetail,
    selectNurseryList,
    InventoryStatistics,
    SeedlingsBatchStatistics,
    ShipmentStatusEditing,
    researchProjectAuxiliary,
    researchProjectList,
    researchProjectDetail,
    researchProjectMain,
    createResearchProject,
    EditProjectOver,
    researchContractAuxiliary,
    researchContractList,
    researchContractDetail,
    createResearchContract,
    researchConAuxiliary,
    researchConList,
    researchConDetail,
    createResearchCon,
    NurserySettlementContract,
    nurserySettlementList,
    nurserySettlementDetail,
    createNurserySettlement,
    SettlementPaymentAuxiliary,
    aboutPaymentContract,
    SettlementPaymentList,
    SettlementPaymentDetail,
    createSettlementPayment,
    RecognitionPriceList,
    RecognitionPriceDetail,
    createRecognitionPrice,
    ResTemporaryList,
    ResTemporaryDetail,
    createResTemporary,
    aboutResearchContract,
    ResProgressSelectCon,
    ResProgressSettlementList,
    ResProgressSettlementDetail,
    createResProgressSettlement,
    nurserySettlementSelect,
    ProgressSettlementSelect,
    ResearchProjectDataList,
    ResearchProjectDataInformation,
    outboundOrderSelect
}
