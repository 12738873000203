import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import { researchProjectAuxiliary, createResearchProject, researchProjectDetail } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "成果转化项目立项申请",
                flowTips: '(358工程)',
                TableInfo: [{
                    FieldTitle: '立项类别', //字段标题
                    fieldName: 'pro_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择立项类别', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                },{
                    FieldTitle: '二级项目类别', //字段标题
                    fieldName: 'two_level', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择立项类别', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '立项时间', //字段标题
                    fieldName: 'cre_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择立项时间', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入项目名称', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '省份', //字段标题
                    fieldName: 'province', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择省份', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '城市', //字段标题
                    fieldName: 'city', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择城市', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '中标单位', //字段标题
                    fieldName: 'select_unit', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择中标单位', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '中标金额(元)', //字段标题
                    fieldName: 'select_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '计划开工时间', //字段标题
                    fieldName: 'plan_open_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '要求竣工时间', //字段标题
                    fieldName: 'over_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '工期(天)', //字段标题
                    fieldName: 'duration', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '施工面积(㎡)', //字段标题
                    fieldName: 'build_area', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }
                ],
                FirstInfo: [
                    {
                        plateName: '甲方单位信息',
                        list: [
                            {
                                FieldTitle: '总包单位', //字段标题
                                fieldName: 'gen_unit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入总包单位', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '总包联系人', //字段标题
                                fieldName: 'gen_proxy', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请把输入总包联系人', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '总包联系方式', //字段标题
                                fieldName: 'gen_contact', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入总包联系方式', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主单位', //字段标题
                                fieldName: 'owner_unit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主联系人', //字段标题
                                fieldName: 'owner_proxy', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主联系方式', //字段标题
                                fieldName: 'contact', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'enclosure', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            }
                        ]
                    },
                    {
                        plateName: '竣工信息',
                        isShow: true,
                        list: [
                            {
                                FieldTitle: '竣工时间', //字段标题
                                fieldName: 'over_time', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '人工工日', //字段标题
                                fieldName: 'peo_date', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '人工成本(元)', //字段标题
                                fieldName: 'peo_cost', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '结算金额(元)', //字段标题
                                fieldName: 'set_money', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '结算面积(㎡)', //字段标题
                                fieldName: 'set_area', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },{
                                FieldTitle: '其他费用', //字段标题
                                fieldName: 'other_cost', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                        ]
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            provinces: [],
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_researchProjectAuxiliary()
    }

    get_researchProjectAuxiliary = () => {
        let { Info } = this.state
        researchProjectAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '立项类别') {
                            item.selectList = []
                            for (let key in res.data.researchProjectMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.researchProjectMode[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '省份') {
                            item.selectList = []
                            res.data.provinces.forEach((tem, dex) => {
                                let value = {
                                    value: tem.value,
                                    label: tem.label
                                }
                                item.selectList = [...item.selectList, value]
                            })
                        } else if (item.FieldTitle === '中标单位') {
                            item.selectList = []
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }else if (item.FieldTitle === '二级项目类别') {
                            item.selectList = []
                            for (let key in res.data.researchProjectTwoLevelMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.researchProjectTwoLevelMode[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        provinces: res.data.provinces
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_ProjectDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_ProjectDetail = (tableId) => {
        const { Info, form, provinces } = this.state;
        let data = {
            table_id: tableId
        }
        researchProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.pro_number = res.data.pro_number
                        Info.process.edit = res.data.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.over_time) {
                            Info.FirstInfo[1].isShow = false
                            Info.FirstInfo[1].list.forEach((item, index) => {
                                if (item.FieldTitle === '竣工时间') {
                                    item.value = res.data.over_time
                                } else if (item.FieldTitle === '人工工日') {
                                    item.value = res.data.peo_date
                                } else if (item.FieldTitle === '人工成本(元)') {
                                    item.value = res.data.peo_cost
                                }else if (item.FieldTitle === '结算金额(元)') {
                                    item.value = res.data.set_money
                                }else if (item.FieldTitle === '结算面积(㎡)') {
                                    item.value = res.data.set_area
                                }else if (item.FieldTitle === '其他费用') {
                                    item.value = res.data.other_cost
                                }

                            })
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    provinces.forEach((item, index) => {
                        if (item.value === res.data.province) {
                            Info.TableInfo.forEach((tem, dex) => {
                                if (tem.FieldTitle === '城市') {
                                    tem.selectList = []
                                    item.children.forEach((em, ex) => {
                                        let value = {
                                            value: em.value,
                                            label: em.label
                                        }
                                        tem.selectList = [...tem.selectList, value]
                                    })
                                }
                            })
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目编号') {
                            item.value = res.data.pro_number
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_class: res.data.pro_class,
                        two_level:res.data.two_level,
                        cre_day: res.data.cre_day ? moment(res.data.cre_day, 'YYYY-MM-DD') : '',
                        pro_manager: res.data.pro_manager,
                        province: res.data.province,
                        city: res.data.city,
                        pro_name: res.data.pro_name,
                        select_unit: res.data.select_unit,
                        select_money: res.data.select_money,
                        plan_open_day: res.data.plant_open_day ? moment(res.data.plant_open_day, 'YYYY-MM-DD') : '',
                        over_day: res.data.over_day ? moment(res.data.over_day, 'YYYY-MM-DD') : '',
                        duration: res.data.duration,
                        build_area:res.data.build_area,
                        owner_unit: res.data.owner_unit,
                        owner_proxy: res.data.owner_proxy,
                        contact: res.data.contact,
                        gen_unit: res.data.gen_unit,
                        gen_proxy: res.data.gen_proxy,
                        gen_contact: res.data.gen_contact,
                        enclosure: res.data.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_class = data.pro_class
        form.two_level = data.two_level
        form.pro_manager = data.pro_manager
        form.province = data.province
        form.city = data.city
        form.pro_name = data.pro_name
        form.select_unit = data.select_unit
        form.select_money = data.select_money
        form.duration = data.duration
        form.build_area=data.build_area
        form.owner_unit = data.owner_unit
        form.owner_proxy = data.owner_proxy
        form.contact = data.contact
        form.gen_unit = data.gen_unit
        form.gen_proxy = data.gen_proxy
        form.gen_contact = data.gen_contact
        form.cre_day = data.cre_day ? data.cre_day.format('YYYY-MM-DD') : ''
        form.plan_open_day = data.plan_open_day ? data.plan_open_day.format('YYYY-MM-DD') : ''
        form.over_day = data.over_day ? data.over_day.format('YYYY-MM-DD') : ''

        createResearchProject(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelector = (value) => {
        const { Info, provinces } = this.state;
        provinces.forEach((item, index) => {
            if (item.value === value) {
                Info.TableInfo.forEach((tem, dex) => {
                    if (tem.FieldTitle === '城市') {
                        tem.selectList = []
                        item.children.forEach((em, ex) => {
                            let value = {
                                value: Number(em.value),
                                label: em.label
                            }
                            tem.selectList = [...tem.selectList, value]
                        })
                    }
                })
            }
        })

        this.setState(preState => ({
            Info
        })
        )
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} selectorEvent={this.handSelector} auditEvent={this.handleButton} />
            </div>
        )
    }
}
