import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { reviewApplyResult, selectUserName } from "../../../utils/api"
import { DesignMarkProjectDetail, createDesignMarkProject } from "../../../utils/market"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "设计项目任务申请",
                TableInfo: [],
                FirstInfo: [
                    {
                        plateName: '项目基本信息',
                        list: [
                            {
                                FieldTitle: '项目名称', //字段标题
                                fieldName: 'pro_name', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入项目名称', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '建设单位及注册资本', //字段标题
                                fieldName: 'project', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入建设单位及注册资本', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '设计周期', //字段标题
                                fieldName: 'cycle', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入设计周期', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '项目开始时间', //字段标题
                                fieldName: 'complete', //字段名称
                                fieldType: '日期',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请选择项目开始时间', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '选择进度汇报接收人', //字段标题
                                fieldType: '按钮', //字段类型
                                mandatory: true, //字段是否必填
                            },
                            {
                                FieldTitle: '进度汇报接收人', //字段标题
                                fieldName: 'pro_users', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%',
                            },
                            {
                                FieldTitle: '项目地址及概况', //字段标题
                                fieldName: 'pro_info', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入项目地址及概况', //必填要求
                                value: '',
                                width: '100%'
                            },
                        ],
                    },
                    {
                        plateName: '项目详细信息',
                        list: [
                            {
                                FieldTitle: '企业资质要求', //字段标题
                                fieldName: 'content', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            }, {
                                FieldTitle: '企业业绩要求', //字段标题
                                fieldName: 'demand', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '选择项目负责人', //字段标题
                                fieldType: '按钮', //字段类型
                                isShow: true
                            },
                            {
                                FieldTitle: '项目负责人', //字段标题
                                fieldName: 'pro_head', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                                isShow: true
                            }, {
                                FieldTitle: '选择项目参与人员', //字段标题
                                fieldType: '按钮', //字段类型
                                isShow: true
                            },
                            {
                                FieldTitle: '项目参与人员', //字段标题
                                fieldName: 'pro_users', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%',
                                isShow: true
                            },
                            {
                                FieldTitle: '项目投资估算', //字段标题
                                fieldName: 'investment', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '设计费控制价', //字段标题
                                fieldName: 'control_price', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '设计项目类型', //字段标题
                                fieldName: 'type', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '付款条件和支付方式', //字段标题
                                fieldName: 'pay_model', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            },
                            {
                                FieldTitle: '备注', //字段标题
                                fieldName: 'info', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                        ],
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            columns: [{
                title: '姓名',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, index) => {
                    if (text === '1') {
                        return (
                            <span> 男 </span>
                        )
                    } else {
                        return (<span> 女 </span>
                        )
                    }
                }
            },
            {
                title: '部门名称',
                dataIndex: 'deName',
                key: 'deName',
            },
            {
                title: '职位',
                dataIndex: 'company_job',
                key: 'company_job',
            },
            {
                title: '手机',
                dataIndex: 'phone',
                key: 'phone',
            },
            ],
            TableInfo: [{
                FieldTitle: '姓名', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '部门', //字段标题
                fieldName: 'de_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }],
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            FlowchartKey: 1,
            buttonTitle: ''
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DesignMarkProjectDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_DesignMarkProjectDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DesignMarkProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                // if (em.FieldTitle === '选择项目负责人') {
                                //     em.isShow = true
                                // } else if (em.FieldTitle === '选择项目参与人员') {
                                //     em.isShow = true
                                // }

                                if (em.FieldTitle === '选择进度汇报接收人') {
                                    em.isShow = true
                                }
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        // if (item.FieldTitle === '项目负责人') {
                        //     item.value = res.data.detail.proHeadName
                        //     form.pro_head = res.data.detail.pro_head
                        // } else if (item.FieldTitle === '项目参与人员') {
                        //     item.value = res.data.detail.proUsers
                        //     form.pro_users = res.data.detail.pro_users
                        // }

                        if (item.FieldTitle === '进度汇报接收人') {
                            item.value = res.data.Detail.proUsers
                            form.pro_users = res.data.Detail.pro_users
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        cycle: res.data.Detail.cycle,
                        project: res.data.Detail.project,
                        complete: moment(res.data.Detail.complete, 'YYYY-MM-DD'),
                        pro_info: res.data.Detail.pro_info,
                        content: res.data.Detail.content,
                        demand: res.data.Detail.demand,
                        investment: res.data.Detail.investment,
                        control_price: res.data.Detail.control_price,
                        type: res.data.Detail.type,
                        pay_model: res.data.Detail.pay_model,
                        info: res.data.Detail.info,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.cycle = data.cycle
        form.project = data.project
        form.complete = data.complete ? data.complete.format('YYYY-MM-DD') : ''
        form.pro_info = data.pro_info
        form.content = data.content
        form.demand = data.demand
        form.investment = data.investment
        form.control_price = data.control_price
        form.type = data.type
        form.pay_model = data.pay_model
        form.info = data.info

        createDesignMarkProject(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.setState(preState => ({
                buttonTitle: title,
                FlowchartKey: Math.random(),
            }), () => {
                this.get_InitialValue()
            })
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                workAdd: false
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form } = this.state;

        // if (buttonTitle === '选择项目负责人') {
        //     form.pro_head = value[0].key
        //     Info.FirstInfo[1].list.forEach((item, index) => {
        //         if (item.FieldTitle === '项目负责人') {
        //             item.value = value[0].username
        //         }
        //     })
        // } else {
        //     let pro_users = []
        //     let pro_userName = []
        //     value.forEach((item, index) => {
        //         pro_users.push(item.key)
        //         pro_userName.push(item.username + ' , ')
        //     })
        //     Info.FirstInfo[1].list.forEach((item, index) => {
        //         if (item.FieldTitle === '项目参与人员') {
        //             item.value = pro_userName
        //         }
        //     })
        //     form.pro_users = JSON.stringify(pro_users)
        // }

        let pro_users = []
        let pro_userName = []
        value.forEach((item, index) => {
            pro_users.push(item.key)
            pro_userName.push(item.username + ' , ')
        })
        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '进度汇报接收人') {
                item.value = pro_userName
            }
        })
        form.pro_users = JSON.stringify(pro_users)

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            }
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}