import React, { Component } from 'react'
import { Input, Form, message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { BlankingSelectProject } from "../../../utils/project"
import { SupConfirmPriceList, getConfirmMacDetail, selectMacChangePrice, updateMacPrice, updateMacPriceDetail } from "../../../utils/purchase"
import { reviewApplyResult } from "../../../utils/api"
import excess from "../../../accets/img/excess.png"
import drop from "../../../accets/img/drop.png"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "材料调价审批",
            TableInfo: [{
                FieldTitle: '申请日期', //字段标题
                fieldName: 'apply_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择申请日期', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'pro_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '选择认价审批单', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            },
            {
                FieldTitle: '认价编号', //字段标题
                fieldName: 'cc_user', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '中标供应商', //字段标题
                fieldName: 'sup_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            ],
            FirstInfo: [
                {
                    plateName: '材料调价详情',
                    list: [],
                    editable: {
                        scroll: true,
                        scrollWidth: 2800,
                        dataSource: [],
                        columns: [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                fixed: 'left',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操  作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                fixed: 'left',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '名称/工种',
                                dataIndex: 'name',
                                key: 'name',
                                fixed: 'left',
                            },
                            {
                                title: '采购价(含税)',
                                dataIndex: 'purchase',
                                key: 'purchase',
                                width: '500px',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                <div style={{ width: '200px' }}>起草时间：{record.confirm_day}</div>
                                                <div style={{ width: '264px' }}>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>采购单价</div>
                                                        <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[0]}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>采购总价</div>
                                                        <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                record.updatePrice.map((em, ex) => {
                                                    return (
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                            <div style={{ width: '200px' }}>调整时间: {em.update_day}</div>
                                                            <div style={{ width: '264px' }}>
                                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                    <div style={{ width: '32%' }}>调整后单价</div>
                                                                    {
                                                                        em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.tax_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.tax_price}</div>
                                                                    }
                                                                    <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '32%' }}>调整后总价</div>
                                                                    {
                                                                        em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.tax_all}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.tax_all}</div>
                                                                    }
                                                                    <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                <div style={{ width: '200px' }}></div>
                                                <div style={{ width: '264px' }}>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>调整后单价</div>
                                                        <Form.Item name={'pur_unit_price' + record.key} key={index} initialValue={record.pur_unit_price} onChange={(e) => this.InputChange(e, record.key)} style={{ width: '64%', marginLeft: '10px' }}>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>调整后总价</div>
                                                        {
                                                            record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pur_total_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pur_total_price}</div>
                                                        }
                                                        <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }
                            },
                            {
                                title: '采购价(不含税)',
                                dataIndex: 'procurement',
                                key: 'procurement',
                                width: '300px',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div style={{ padding: '10px 0' }}>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>采购单价</div>
                                                    <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[0]}</div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>采购总价</div>
                                                    <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                </div>
                                            </div>

                                            {
                                                record.updatePrice.length ? record.updatePrice.map((em, ex) => {
                                                    return (
                                                        <div style={{ padding: '10px 0' }}>
                                                            <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                <div style={{ width: '32%' }}>调整后单价</div>
                                                                {
                                                                    em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.price}</div>
                                                                }
                                                                <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '32%' }}>调整后总价</div>
                                                                {
                                                                    em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.price_all}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.price_all}</div>
                                                                }
                                                                <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : ''
                                            }

                                            <div style={{ padding: '10px 0' }}>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>调整后单价</div>
                                                    {
                                                        record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pro_unit_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pro_unit_price}</div>
                                                    }
                                                    <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>调整后总价</div>
                                                    {
                                                        record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pro_total_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pro_total_price}</div>
                                                    }
                                                    <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '预 算 价',
                                dataIndex: 'budget',
                                key: 'budget',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                预算单价: {text[0] ? text[0] : '/'}
                                            </div>
                                            <div>
                                                预算总价: {text[1] ? text[1] : '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '合 同 价',
                                dataIndex: 'contract',
                                key: 'contract',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                合同单价: {text[0] || '/'}
                                            </div>
                                            <div>
                                                合同总价: {text[1] || '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    return (
                                        <div title={text} style={{ cursor: 'pointer', width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                    )
                                }
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '数量/工程量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '税率(%)',
                                dataIndex: 'tax',
                                key: 'tax',
                                width: '120px',
                            },
                            {
                                title: '利润率(%)',
                                dataIndex: 'profit',
                                key: 'profit',
                                width: '120px',
                            },
                            {
                                title: '备  注',
                                dataIndex: 'info',
                                key: 'info',
                                width: '400px',
                            }
                        ]
                    },
                },
                {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '备注', //字段标题
                            fieldName: 'info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        pro_name: '',
        sup_name: '',
        mac_name:'',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
        FlowchartKey: 2,
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_MacPriceDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = project.pro_manager
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_MacPriceDetail = (tableId) => {
        let { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        updateMacPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.confirm_id = res.data.Detail.confirm_id
                        Info.process.edit = res.data.Detail.editPermission

                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择认价审批单') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                fixed: 'left',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '名称',
                                dataIndex: 'name',
                                key: 'name',
                                fixed: 'left',
                            },
                            {
                                title: '采购价(含税)',
                                dataIndex: 'purchase',
                                key: 'purchase',
                                width: '500px',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                <div style={{ width: '200px' }}>起草时间：{record.confirm_day}</div>
                                                <div style={{ width: '264px' }}>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>采购单价</div>
                                                        <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[0]}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>采购总价</div>
                                                        <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                record.updatePrice.map((em, ex) => {
                                                    return (
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                            <div style={{ width: '200px' }}>调整时间: {em.update_day}</div>
                                                            <div style={{ width: '264px' }}>
                                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                    <div style={{ width: '32%' }}>调整后单价</div>
                                                                    {
                                                                        em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.tax_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.tax_price}</div>
                                                                    }
                                                                    <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ width: '32%' }}>调整后总价</div>
                                                                    {
                                                                        em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.tax_all}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.tax_all}</div>
                                                                    }
                                                                    <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                                {
                                                    record.update_day ? <div style={{ width: '200px' }}>调整时间：{record.update_day}</div> : <div style={{ width: '200px' }}></div>
                                                }
                                                <div style={{ width: '264px' }}>
                                                    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>调整后单价</div>
                                                        {
                                                            record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pur_unit_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pur_unit_price}</div>
                                                        }
                                                        <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '32%' }}>调整后总价</div>
                                                        {
                                                            record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pur_total_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pur_total_price}</div>
                                                        }
                                                        <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }
                            },
                            {
                                title: '采购价(不含税)',
                                dataIndex: 'procurement',
                                key: 'procurement',
                                width: '300px',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div style={{ padding: '10px 0' }}>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>采购单价</div>
                                                    <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[0]}</div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>采购总价</div>
                                                    <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{text[1]}</div>
                                                </div>
                                            </div>

                                            {
                                                record.updatePrice.length ? record.updatePrice.map((em, ex) => {
                                                    return (
                                                        <div style={{ padding: '10px 0' }}>
                                                            <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                <div style={{ width: '32%' }}>调整后单价</div>
                                                                {
                                                                    em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.price}</div>
                                                                }
                                                                <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '32%' }}>调整后总价</div>
                                                                {
                                                                    em.is_excess === 1 || em.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{em.price_all}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{em.price_all}</div>
                                                                }
                                                                <img src={em.is_excess === 1 ? excess : em.is_excess === 2 ? drop : ''} alt=""></img>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : ''
                                            }

                                            <div style={{ padding: '10px 0' }}>
                                                <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>调整后单价</div>
                                                    {
                                                        record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pro_unit_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pro_unit_price}</div>
                                                    }
                                                    <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: '32%' }}>调整后总价</div>
                                                    {
                                                        record.is_excess === 1 || record.is_excess === 2 ? <div className='ant-input' style={{ width: '58%', height: '44px', marginLeft: '4%', marginRight: '4%', fontSize: '16px', color: '#333' }}>{record.pro_total_price}</div> : <div className='ant-input' style={{ width: '64%', height: '44px', marginLeft: '4%', fontSize: '16px', color: '#333' }}>{record.pro_total_price}</div>
                                                    }
                                                    <img src={record.is_excess === 1 ? excess : record.is_excess === 2 ? drop : ''} alt=""></img>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '预 算 价',
                                dataIndex: 'budget',
                                key: 'budget',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                预算单价: {text[0] ? text[0] : '/'}
                                            </div>
                                            <div>
                                                预算总价: {text[1] ? text[1] : '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '合 同 价',
                                dataIndex: 'contract',
                                key: 'contract',
                                render: (text, record, index) => {
                                    return (
                                        <div>
                                            <div>
                                                合同单价: {text[0] || '/'}
                                            </div>
                                            <div>
                                                合同总价: {text[1] || '/'}
                                            </div>
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    return (
                                        <div title={text} style={{ cursor: 'pointer', width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                    )
                                }
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '数量/工程量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '税率(%)',
                                dataIndex: 'tax',
                                key: 'tax',
                                width: '120px',
                            },
                            {
                                title: '利润率(%)',
                                dataIndex: 'profit',
                                key: 'profit',
                                width: '120px',
                            },
                            {
                                title: '备  注',
                                dataIndex: 'info',
                                key: 'info',
                                width: '400px',
                            }
                        ]

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].editable.dataSource = []
                    if (res.data.Detail.details.length) {
                        res.data.Detail.details.forEach((item, index) => {
                            let key = Info.FirstInfo[0].editable.dataSource.length + 1
                            let tax_price, tax_all, price, price_all, update_day, is_excess
                            let updatePrice = []

                            if (item.updatePrice.length > 1) {
                                let key = item.updatePrice.length - 1
                                let priceList = []

                                if (item.PassUpdatePrice && item.PassUpdatePrice.length) {
                                    item.updatePrice.forEach((em, ex) => {
                                        if (ex !== key) {
                                            priceList = [...priceList, em]
                                        }
                                    })

                                    item.PassUpdatePrice.forEach((em, ex) => {
                                        priceList = [...priceList, em]
                                    })

                                    item.updatePrice.forEach((em, ex) => {
                                        if (ex === key) {
                                            priceList = [...priceList, em]
                                        }
                                    })
                                } else {
                                    priceList = [...priceList, ...item.updatePrice]
                                }

                                let value = priceList.length - 1
                                priceList.forEach((em, ex) => {
                                    if (ex === value) {
                                        let pur_unit_price = priceList[ex - 1].tax_price
                                        tax_price = em.tax_price
                                        tax_all = em.tax_all
                                        price = em.price
                                        price_all = em.price_all
                                        update_day = em.update_day

                                        if (Number(pur_unit_price) > Number(tax_price)) {
                                            is_excess = 2
                                        } else if (Number(pur_unit_price) < Number(tax_price)) {
                                            is_excess = 1
                                        } else {
                                            is_excess = 0
                                        }
                                    } else {
                                        if (ex === 0) {
                                            let pur_unit_price = item.confrimPriceDetail.pur_unit_price
                                            if (Number(pur_unit_price) > Number(em.tax_price)) {
                                                em.is_excess = 2
                                            } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                                em.is_excess = 1
                                            } else {
                                                em.is_excess = 0
                                            }
                                        } else {
                                            let pur_unit_price = priceList[ex - 1].tax_price
                                            if (Number(pur_unit_price) > Number(em.tax_price)) {
                                                em.is_excess = 2
                                            } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                                em.is_excess = 1
                                            } else {
                                                em.is_excess = 0
                                            }
                                        }
                                        updatePrice = [...updatePrice, em]
                                    }
                                })
                            } else {
                                tax_price = item.updatePrice[0].tax_price
                                tax_all = item.updatePrice[0].tax_all
                                price = item.updatePrice[0].price
                                price_all = item.updatePrice[0].price_all
                                update_day = item.updatePrice[0].update_day
                                updatePrice = []

                                if (item.PassUpdatePrice && item.PassUpdatePrice.length) {
                                    let key = item.PassUpdatePrice.length - 1
                                    item.PassUpdatePrice.forEach((em, ex) => {
                                        if (ex === 0) {
                                            let pur_unit_price = item.confrimPriceDetail.pur_unit_price
                                            if (Number(pur_unit_price) > Number(em.tax_price)) {
                                                em.is_excess = 2
                                            } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                                em.is_excess = 1
                                            } else {
                                                em.is_excess = 0
                                            }
                                        } else {
                                            let pur_unit_price = item.updatePrice[ex - 1].tax_price
                                            if (Number(pur_unit_price) > Number(em.tax_price)) {
                                                em.is_excess = 2
                                            } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                                em.is_excess = 1
                                            } else {
                                                em.is_excess = 0
                                            }
                                        }
                                        updatePrice = [...updatePrice, em]
                                    })

                                    if (Number(item.PassUpdatePrice[key].tax_price) > Number(tax_price)) {
                                        is_excess = 2
                                    } else if (Number(item.PassUpdatePrice[key].tax_price) < Number(tax_price)) {
                                        is_excess = 1
                                    } else {
                                        is_excess = 0
                                    }
                                } else {
                                    if (Number(item.confrimPriceDetail.pur_unit_price) > Number(tax_price)) {
                                        is_excess = 2
                                    } else if (Number(item.confrimPriceDetail.pur_unit_price) < Number(tax_price)) {
                                        is_excess = 1
                                    } else {
                                        is_excess = 0
                                    }
                                }

                            }

                            let value = {
                                key: key,
                                mac_id: item.mac_id,
                                name: item.confrimPriceDetail.name,
                                model: item.confrimPriceDetail.model,
                                unit: item.confrimPriceDetail.unit,
                                number: item.confrimPriceDetail.number,
                                confirm_day: item.confrimPriceDetail.confirm_day,
                                budget: [item.budPrices.unit_price, item.budPrices.money],
                                contract: [item.budPrices.award_price, item.budPrices.con_money],
                                purchase: [item.confrimPriceDetail.pur_unit_price, item.confrimPriceDetail.pur_total_price],
                                procurement: [item.confrimPriceDetail.ver_unit_price, item.confrimPriceDetail.ver_total_price],
                                updatePrice: updatePrice,
                                pur_unit_price: tax_price,
                                pur_total_price: tax_all,
                                pro_unit_price: price,
                                pro_total_price: price_all,
                                update_day: update_day,
                                is_excess: is_excess,
                                tax: item.confrimPriceDetail.tax,
                                profit: item.confrimPriceDetail.profit,
                                info: item.confrimPriceDetail.info,
                            }

                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, value]

                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'pur_unit_price' + key)()]: item.tax_price,
                            })
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManager
                        } else if (item.FieldTitle === '认价编号') {
                            item.value = res.data.Detail.confirmNumber
                        } else if (item.FieldTitle === '中标供应商') {
                            item.value = res.data.Detail.supName
                        }
                    })

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.Detail.apply_day ? moment(res.data.Detail.apply_day, 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SupConfirmPriceList = () => {
        let { form, sup_name, dataSource, pages, } = this.state
        if (!form.project_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            class: 1,
            project_id: form.project_id,
            pro_name: '',
            sup_name: sup_name,
            status: 4,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SupConfirmPriceList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.confirms.length) {
                        res.data.confirms.forEach((item, index) => {
                            let value = {
                                key: item.table_id,
                                code: item.code,
                                sup_name: item.sup_name,
                                applyUsername: item.applyUsername,
                                apply_date: item.apply_date,
                                info: item.info,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ConfirmMacDetail = () => {
        let { form, dataSource,mac_name } = this.state
        if (!form.confirm_id) {
            message.error('请选择认价审批单')
            return
        }

        let data = {
            confirm_id: form.confirm_id,
            mac_name:mac_name,
        }
        getConfirmMacDetail(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let value = {
                                key: item.mac_id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                tax: item.tax,
                                profit: item.profit,
                                info: item.info,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        modal_Visible: true,
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_MacChangePrice = (value) => {
        let { Info, form } = this.state
        let idList = []
        value.forEach((item, index) => {
            idList = [...idList, item.key]
        })
        idList = JSON.stringify(idList)
        let data = {
            project_id: form.project_id,
            confirm_id: form.confirm_id,
            mac_ids: idList
        }
        selectMacChangePrice(data).then(
            (res) => {
                if (!res.code) {
                    if (Info.FirstInfo[0].editable.dataSource.length) {
                        res.data.forEach((item, index) => {
                            let key = Info.FirstInfo[0].editable.dataSource.length - 1
                            const newData = {
                                key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                                mac_id: item.mac_id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                confirm_day: item.confirm_day,
                                budget: [item.macBudPrices.unit_price, item.macBudPrices.money],
                                contract: [item.macBudPrices.award_price, item.macBudPrices.con_money],
                                purchase: [item.pur_unit_price, item.pur_total_price],
                                procurement: [item.ver_unit_price, item.ver_total_price],
                                updatePrice: item.updatePrice,
                                pur_unit_price: '',
                                pur_total_price: '',
                                pro_unit_price: '',
                                pro_total_price: '',
                                tax: item.tax,
                                profit: item.profit,
                                info: item.info,
                            };

                            if (item.updatePrice.length) {
                                item.updatePrice.forEach((em, ex) => {
                                    if (ex == 0) {
                                        if (Number(em.tax_price) > Number(item.pur_unit_price)) {
                                            em.is_excess = 2
                                        } else if (Number(em.tax_price) < Number(item.pur_unit_price)) {
                                            em.is_excess = 1
                                        } else {
                                            em.is_excess = 0
                                        }
                                    } else {
                                        let pur_unit_price = item.updatePrice[ex - 1].tax_price
                                        console.log(pur_unit_price)
                                        if (Number(em.tax_price) > Number(pur_unit_price)) {
                                            em.is_excess = 1
                                        } else if (Number(em.tax_price) < Number(pur_unit_price)) {
                                            em.is_excess = 2
                                        } else {
                                            em.is_excess = 0
                                        }
                                    }
                                })
                            }

                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    } else {
                        res.data.forEach((item, index) => {
                            const newData = {
                                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                                mac_id: item.mac_id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                confirm_day: item.confirm_day,
                                budget: [item.macBudPrices.unit_price, item.macBudPrices.money],
                                contract: [item.macBudPrices.award_price, item.macBudPrices.con_money],
                                purchase: [item.pur_unit_price, item.pur_total_price],
                                procurement: [item.ver_unit_price, item.ver_total_price],
                                updatePrice: item.updatePrice,
                                pur_unit_price: '',
                                pur_total_price: '',
                                pro_unit_price: '',
                                pro_total_price: '',
                                tax: item.tax,
                                profit: item.profit,
                                info: item.info,
                            };

                            if (item.updatePrice.length) {
                                item.updatePrice.forEach((em, ex) => {
                                    if (ex == 0) {
                                        if (Number(em.tax_price) > Number(item.pur_unit_price)) {
                                            em.is_excess = 1
                                        } else if (Number(em.tax_price) < Number(item.pur_unit_price)) {
                                            em.is_excess = 2
                                        } else {
                                            em.is_excess = 0
                                        }
                                    } else {
                                        let pur_unit_price = item.updatePrice[ex - 1].tax_price
                                        console.log(pur_unit_price)
                                        if (Number(em.tax_price) > Number(pur_unit_price)) {
                                            em.is_excess = 1
                                        } else if (Number(em.tax_price) < Number(pur_unit_price)) {
                                            em.is_excess = 2
                                        } else {
                                            em.is_excess = 0
                                        }
                                    }
                                })
                            }


                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    }

                    this.setState({

                    })
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.info = data.info
        form.details = []

        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (item.updatePrice.length) {
                item.updatePrice.forEach((em, ex) => {
                    let value = {
                        mac_id: em.mac_id,
                        tax_price: em.tax_price,
                        tax_all: em.tax_all,
                        price: em.price,
                        price_all: em.price_all,
                        apply_day: em.update_day
                    }
                    form.details = [...form.details, value]
                })
            }
            let value = {
                mac_id: item.mac_id,
                tax_price: item.pur_unit_price,
                tax_all: item.pur_total_price,
                price: item.pro_unit_price,
                price_all: item.pro_total_price,
                apply_day: ''
            }
            form.details = [...form.details, value]
        })

        console.log(form.details)

        form.details = JSON.stringify(form.details)

        updateMacPrice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择认价审批单') {
                columns = [
                    {
                        title: ' 中标供应商',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '提交人',
                        dataIndex: 'applyUsername',
                        key: 'applyUsername',
                    },
                    {
                        title: '认价日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '备注',
                        dataIndex: 'info',
                        key: 'info',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供 应 商', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                ]
                this.get_SupConfirmPriceList()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }]
                this.get_BlankingSelectProject()
            }

            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio: true
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handleWorkAdd = () => {
        let { columns, TableInfo } = this.state
        columns = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
            },
            {
                title: '规格型号',
                dataIndex: 'model',
                key: 'model',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer', width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '计量单位',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: '数量',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '税率(%)',
                dataIndex: 'tax',
                key: 'tax',
            },
            {
                title: '备  注',
                dataIndex: 'info',
                key: 'info',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer', width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            }
        ]
        TableInfo = [
            {
                FieldTitle: '材料名称', //字段标题
                fieldName: 'mac_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
        ]
        this.setState(preState => ({
            buttonTitle: '认价审批单材料信息',
            columns,
            TableInfo,
            radio: false,
            FlowchartKey: Math.random(),
        }), () => {
            this.get_ConfirmMacDetail()
        })
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '选择认价审批单') {
            form.confirm_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '认价编号') {
                    item.value = value[0].code
                } else if (item.FieldTitle === '中标供应商') {
                    item.value = value[0].sup_name
                }
            })
        } else {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    value.forEach((em, ex) => {
                        if (em.key === item.id) {
                            value.splice(ex, 1)
                        }
                    })
                })
                this.get_MacChangePrice(value)
            } else {
                this.get_MacChangePrice(value)
            }
        }

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            sup_name: '',
            mac_name:'',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    InputChange = (e, key) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (item.key === key) {
                let pur_unit_price = Number(e.target.value)
                let pur_total_price = Number(e.target.value) * Number(item.number)
                let Tax_rate = Number(item.tax) / 100

                //采购价（不含税）
                let pro_unit_price = (pur_unit_price / (1 + Tax_rate)).toFixed(2)
                let pro_total_price = (Number(pro_unit_price) * Number(item.number)).toFixed(2)

                item.pur_unit_price = pur_unit_price
                item.pur_total_price = pur_total_price
                item.pro_unit_price = pro_unit_price
                item.pro_total_price = pro_total_price

                if (item.updatePrice.length) {
                    item.updatePrice.forEach((em, ex) => {
                        if (ex === (item.updatePrice.length - 1)) {
                            if (Number(em.tax_price) > pur_unit_price) {
                                item.is_excess = 2
                            } else if (Number(em.tax_price) < pur_unit_price) {
                                item.is_excess = 1
                            } else {
                                item.is_excess = 0
                            }
                        }
                    })
                } else {
                    if (Number(item.purchase[0]) > pur_unit_price) {
                        item.is_excess = 2
                    } else if (Number(item.purchase[0]) < pur_unit_price) {
                        item.is_excess = 1
                    } else {
                        item.is_excess = 0
                    }
                }


            }

        })
        this.setState(preState => ({
            Info
        }), () => {
            this.get_dataSource(key)
        })
    }

    get_dataSource = (key) => {
        let { Info } = this.state;
        let dataSource = []
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (item.key === key) {
                const newData = {
                    key: item.key,
                    mac_id: item.mac_id,
                    name: item.name,
                    model: item.model,
                    unit: item.unit,
                    number: item.number,
                    budget: item.budget,
                    contract: item.contract,
                    purchase: item.purchase,
                    procurement: item.procurement,
                    updatePrice: item.updatePrice,
                    pur_unit_price: item.pur_unit_price,
                    pur_total_price: item.pur_total_price,
                    pro_unit_price: item.pro_unit_price,
                    pro_total_price: item.pro_total_price,
                    is_excess: item.is_excess,
                    tax: item.tax,
                    profit: item.profit,
                    info: item.info,
                }
                dataSource = [...dataSource, newData]
            } else {
                dataSource = [...dataSource, item]
            }
        })

        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info
        }))
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择认价审批单') {
            this.setState({
                sup_name: value.sup_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_SupConfirmPriceList()
            })
        }else{
            this.setState({
                mac_name: value.mac_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_ConfirmMacDetail()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            sup_name: '',
            mac_name:'',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择认价审批单') {
                this.get_SupConfirmPriceList()
            } else {
                this.get_ConfirmMacDetail()
            }
        })
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'pur_unit_price' + e)()]: '',
        })

        Info.FirstInfo[0].editable.columns.forEach((em, ex) => {
            if (em.title === '采购价(含税)') {
                em.width = "300px"
            }
        })

        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
                if (item.updatePrice.length) {
                    Info.FirstInfo[0].editable.columns.forEach((em, ex) => {
                        if (em.title === '采购价(含税)') {
                            em.width = "500px"
                        }
                    })
                }
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, formKey, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={formKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
