import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createUserLoan, userLoanDetail } from "../../../../utils/public"
import { proManagerProjects, reviewApplyResult } from "../../../../utils/api"
import amountInWords from '../../../../components/amountInWords'

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/loanDocuments.docx',
            templateName: '四川天艺生态园林集团股份有限公司员工借款单',
            tempData: {
                number: '',
                apply_date: '',
                user_name: '',
                deName: '',
                type: '',
                proName: '',
                use: '',
                repayment_date: '',
                bor_unit: '',
                money: '',
                uppercase_money: '',
                info: '',
                results: []
            },
            FlowModelName: "员工借款审批",
            TableInfo: [{
                FieldTitle: '单据编号', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '(自动编号)',
                width: '50%'
            }, {
                FieldTitle: '借款人', //字段标题
                fieldName: 'user_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '提单日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择提单日期', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '业务部门', //字段标题
                fieldName: 'bus_de', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '借款类型', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入借款类型', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    {
                        value: 1,
                        label: '项目借款'
                    },
                    {
                        value: 2,
                        label: '个人借款'
                    }
                ]
            },
            {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
                isShow: true
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '借款单位', //字段标题
                fieldName: 'bor_unit', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择借款单位', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                    { value: 2, label: "四川天艺优境环境科技有限公司" },
                    { value: 3, label: "四川天艺致远物联网科技有限公司" },
                    { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                ]
            }, {
                FieldTitle: '借款金额(元)', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入本次借款金额(元)', //必填要求
                placeholder: '',
                value: '',
                width: '50%'
            }, {
                FieldTitle: '借款用途', //字段标题
                fieldName: 'use', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入借款用途', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '预计还款日期', //字段标题
                fieldName: 'repayment_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择预计还款日期', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [
                {
                    plateName: '',
                    isShow: true,
                    list: [{
                        FieldTitle: '前期未还款金额(元)', //字段标题
                        fieldName: 'unpaid_amount', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '前期未还款原因', //字段标题
                        fieldName: 'unpaid_reason', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '前期借款信息核对', //字段标题
                        fieldName: 'information_check', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '前期借款金额(元)', //字段标题
                        fieldName: 'pre_loan_amount', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '已报账核销金额(元)', //字段标题
                        fieldName: 'amount_reimbursed', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '前期借款余额(元)', //字段标题
                        fieldName: 'pre_loan_balance', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '附件张数', //字段标题
                        fieldName: 'eng_num', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '强制还款日期', //字段标题
                        fieldName: 'mandatory_date', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    }]
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'managerName',
                key: 'managerName',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '100%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_userLoanDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info, form } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '借款人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '业务部门') {
                item.value = userInfo.deName
                form.bus_de = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    get_userLoanDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        userLoanDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission


                        if (res.data.Detail.class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择项目') {
                                    item.isShow = true
                                } else if (item.FieldTitle === '项目名称') {
                                    item.isShow = true
                                }
                            })
                        }

                    } else {
                        if (res.data.Detail.status === 4) {
                            Info.tempData.number = res.data.Detail.code
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.user_name = res.data.Detail.username
                            Info.tempData.deName = res.data.Detail.bus_de
                            Info.tempData.type = res.data.Detail.class === 1 ? '项目借款' : '个人借款'
                            Info.tempData.proName = res.data.Detail.projectName || '/'
                            Info.tempData.use = res.data.Detail.use
                            Info.tempData.repayment_date = res.data.Detail.repayment_date
                            Info.tempData.bor_unit = res.data.Detail.bor_unit === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.bor_unit === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.bor_unit === 3 ? '四川天艺致远物联网科技有限公司' : '四川天艺传奇生态茶业有限公司'
                            Info.tempData.money = res.data.Detail.money
                            Info.tempData.uppercase_money = amountInWords(Number(res.data.Detail.money))
                            Info.tempData.info = res.data.Detail.info || ''
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                            console.log(Info.tempData)
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '项目名称') {
                                if (res.data.Detail.class === 1) {
                                    item.isShow = false
                                }
                            }
                        })

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '借款人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '业务部门') {
                            item.value = res.data.Detail.bus_de
                        } else if (item.FieldTitle === '项目名称') {
                            form.project_id = res.data.Detail.project_id || ''
                            item.value = res.data.Detail.projectName || ''
                        } else if (item.FieldTitle === '单据编号') {
                            item.value = res.data.Detail.code
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        use: res.data.Detail.use,
                        money: res.data.Detail.money,
                        class: res.data.Detail.class,
                        bor_unit: res.data.Detail.bor_unit,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                        repayment_date: res.data.Detail.repayment_date ? moment(res.data.Detail.repayment_date, 'YYYY-MM-DD') : '',
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        proManagerProjects(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.manager,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.bor_unit = data.bor_unit
        form.use = data.use
        form.class = data.class
        form.money_class = 1
        form.money = data.money
        form.info = data.info
        form.repayment_date = data.repayment_date ? data.repayment_date.format('YYYY-MM-DD') : ''

        createUserLoan(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    handleRadio = (value, name) => {
        let { Info } = this.state
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择项目') {
                    item.isShow = false
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = false
                } else if (item.FieldTitle === '借款金额(元)') {
                    item.placeholder = '项目借款金额不得大于20000元'
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择项目') {
                    item.isShow = true
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '借款金额(元)') {
                    item.placeholder = ''
                }
            })
        }

        this.setState(preState => ({
            Info
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
