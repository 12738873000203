import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { Select, Modal } from 'antd';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../../components/Operate'
import Table from '../../../../components/Table'
import { SeedlingInventoryList, selectNurseryList } from "../../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '出库时间',
                    dataIndex: 'warehousing',
                    key: 'warehousing',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '植物编号',
                    dataIndex: 'plant_code',
                    key: 'plant_code',
                },
                {
                    title: '植物名称',
                    dataIndex: 'plant_name',
                    key: 'plant_name',
                },
                {
                    title: '型号规格',
                    dataIndex: 'model',
                    key: 'model',
                },
                {
                    title: '单位',
                    dataIndex: 'supply_unit',
                    key: 'supply_unit',
                },
                {
                    title: '出库数量',
                    dataIndex: 'see_number',
                    key: 'see_number',
                },
                {
                    title: '出库单价',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: '出库总额',
                    dataIndex: 'all_price',
                    key: 'all_price',
                },
                {
                    title: '入库时间',
                    dataIndex: 'godown',
                    key: 'godown',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            nurList: [],
            nurValue: '',
            templateList: [],
            templateShow: false
        }
    }

    componentDidMount() {
        this.get_selectNurseryList()
    }

    get_selectNurseryList = () => {
        let { nurList, nurValue } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    nurList = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                label: item.name,
                                value: item.id
                            }
                            nurList = [...nurList, value]

                            if (!nurValue) {
                                if (index === 0) {
                                    nurValue = item.id
                                }
                            }
                        })
                    }

                    this.setState(preState => ({
                        nurList,
                        nurValue
                    }), () => {
                        this.get_SeedlingInventoryList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SeedlingInventoryList = () => {
        let { dataSource, pages, nurValue, process_state } = this.state
        let data = {
            queryCriteria: 'personal',
            nur_id: nurValue,
            operate: 'out',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SeedlingInventoryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.seedling.length) {
                        res.data.seedling.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.sheet_id,
                                warehousing: item.warehousing,
                                plant_name: item.plant_name,
                                plant_code: item.plant_code,
                                model: item.model,
                                supply_unit: item.unit,
                                see_number: item.see_number,
                                price: item.price,
                                all_price: item.all_price,
                                godown:item.godown,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        templateList: res.data.template
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/deliveryfromstorage/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/deliveryfromstorage/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            con_name: '',
            con_code: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            con_name: value.con_name,
            con_code: value.con_code
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_SeedlingInventoryList()
        })
    }

    nurChange = (value) => {
        this.setState({
            nurValue: value,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => { this.get_SeedlingInventoryList() })
    }

    handleTemplate = () => {
        this.setState({
            templateShow: true,
        })
    }

    cancelTemplate = () => {
        this.setState({
            templateShow: false,
        })
    }

    handlePath = (path) => {
        let link = document.createElement("a")
        link.href = path
        link.click()
    }

    render() {
        let { dataSource, columns, pages, nurList, nurValue, templateShow, templateList } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} seek={true} template={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} templateEvent={this.handleTemplate} />
                    </div>

                    <div className='screen_right'>
                        <div>
                            <div>苗圃名称 :</div>
                            <Select
                                options={nurList}
                                value={nurValue}
                                onChange={(e) => {
                                    this.nurChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>
                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />

                <Modal
                    title="下载模板"
                    centered
                    visible={templateShow}
                    onCancel={() => this.cancelTemplate()}
                    wrapClassName='userModal template'
                    maskClosable={false}
                    footer={[]}
                >
                    {
                        templateList.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    this.handlePath(item.filePath)
                                }}>
                                    {item.file_name}
                                </div>
                            )
                        })
                    }
                </Modal>
            </div>
        )
    }
}


export default withRouter(List)
