import http from '../utils/http';

//获取投标决策辅助选项
function BidWinningList(data) {
    return http("get", '/api/BidWinningList', data);
}

//添加中标清单
function createBidWinningList(data) {
    return http("post", '/api/createBidWinningList', data);
}

//人材机价格对比
function PriceComparison(data) {
    return http("get", '/api/PriceComparison', data);
}

//导入项目成本预算表
function ImportTable(data) {
    return http("post", '/api/ImportTable', data);
}

//项目成本明细表查询
function selectBudgetTable(data) {
    return http("get", '/api/selectBudgetTable', data);
}

//项目成本预算统计信息
function projectCostStatistics(data) {
    return http("get", '/api/projectCostStatistics', data);
}

//编辑成本预算统计
function EditCostBudget(data) {
    return http("post", '/api/EditCostBudget', data);
}

//添加编辑成本预算明细
function EditBudgetTable(data) {
    return http("post", '/api/EditBudgetTable', data);
}

//人材机工程量对比
function comparisonQuantities(data) {
    return http("get", '/api/comparisonQuantities', data);
}

//清标材料单导出
function exportProjectBudTable(data) {
    return http("get", '/api/exportProjectBudTable', data);
}

//添加编辑工程进度款审批
function createCostProgressPayment(data) {
    return http("post", '/api/createCostProgressPayment', data);
}

//工程进度款项目查询
function selectContractProject(data) {
    return http("get", '/api/selectContractProject', data);
}

//工程进度款审批列表
function CostProgressList(data) {
    return http("get", '/api/CostProgressList', data);
}

//工程进度款审批详情
function CostProgressDetail(data) {
    return http("get", '/api/CostProgressDetail', data);
}

//工程进度款查询可用年份
function ProjectCreateYear(data) {
    return http("get", '/api/ProjectCreateYear', data);
}

//工程进度款数据汇总表
function SummaryProgressPayment(data) {
    return http("get", '/api/SummaryProgressPayment', data);
}

//工厂进度款汇总数据导出
function ExportSummaryProgress(data) {
    return http("get", '/api/ExportSummaryProgress', data);
}

//编辑工程进度款甲方审批状态
function CostProgressReviewStatus(data) {
    return http("post", '/api/CostProgressReviewStatus', data);
}

export {
    BidWinningList,
    createBidWinningList,
    PriceComparison,
    ImportTable,
    selectBudgetTable,
    projectCostStatistics,
    EditCostBudget,
    EditBudgetTable,
    comparisonQuantities,
    exportProjectBudTable,
    createCostProgressPayment,
    selectContractProject,
    CostProgressList,
    CostProgressDetail,
    ProjectCreateYear,
    SummaryProgressPayment,
    ExportSummaryProgress,
    CostProgressReviewStatus
}
