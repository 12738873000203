import http from '../utils/http';

//获取功能表单字段
function getTableAtt(data) {
    return http("get", '/api/getTableAtt', data);
}

//添加企业微信通知配置
function createFunctionNotice(data) {
    return http("post", '/api/createFunctionNotice', data);
}

//通知配置列表
function FunctionNoticeList(data) {
    return http("get", '/api/FunctionNoticeList', data);
}

//企业微信通知配置详情
function FunctionNoticeContent(data) {
    return http("get", '/api/FunctionNoticeContent', data);
}

//启用禁用消息通知
function enginNoticeConfig(data) {
    return http("post", '/api/enginNoticeConfig', data);
}


export {
    getTableAtt,
    createFunctionNotice,
    FunctionNoticeList,
    FunctionNoticeContent,
    enginNoticeConfig
}
