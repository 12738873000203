import React, { Component } from 'react'
import { DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';

export default class UserDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let dateFormat
        let { disabled, onChange, value, showTime } = this.props;
        showTime ? dateFormat = 'YYYY-MM-DD HH:mm:ss' : dateFormat = 'YYYY-MM-DD'
        return (
            <div>
                <ConfigProvider locale={zhCN}>
                    <DatePicker
                        allowClear={false}
                        disabled={disabled}
                        value={value}
                        showTime={showTime}
                        onChange={(date, dateString) => {
                            onChange(moment(dateString, dateFormat))
                        }}
                        format={dateFormat}
                    />
                </ConfigProvider>
            </div>
        )
    }
}
