import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree, Table } from 'antd';
import { withRouter } from "react-router-dom";
import { revenueMain } from "../../../utils/market"
import { projectCostAccumulation } from "../../../utils/api"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"
import Icon_01 from "../../../accets/img/icon_01.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pro_id: '',
            pro_name: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            taxTotalMoney: 0.00,
            conNotTaxMoney: 0.00,
            conTotalMoney: 0.00,
            sum_columns: [
                {
                    title: '序号',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: '项目大类/类别',
                    dataIndex: 'class',
                    key: 'class',
                },
                {
                    title: '预算成本(元)',
                    dataIndex: 'budget',
                    key: 'budget',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '实际成本(元)',
                    dataIndex: 'actual',
                    key: 'actual',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '已付款(元)',
                    dataIndex: 'paid',
                    key: 'paid',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '未付款(元)',
                    dataIndex: 'unpaid',
                    key: 'unpaid',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
            ],
            sum_dataSource: [
                {
                    key: 1,
                    class: '材料',
                    budget: 0,
                    actual: 0,
                    paid: 0,
                    unpaid: 0
                },
                {
                    key: 2,
                    class: '劳务',
                    budget: 0,
                    actual: 0,
                    paid: 0,
                    unpaid: 0
                },
                {
                    key: 3,
                    class: '机械',
                    budget: 0,
                    actual: 0,
                    paid: 0,
                    unpaid: 0
                },

                {
                    key: '合计',
                    class: '',
                    budget: 0,
                    actual: 0,
                    paid: 0,
                    unpaid: 0
                }
            ],
            det_columns: [
                {
                    title: '序号',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: '项目大类',
                    dataIndex: 'class',
                    key: 'class',
                },
                {
                    title: '项目类别',
                    dataIndex: 'pay_class',
                    key: 'pay_class',
                    render: (text, record, index) => {
                        if (text === 'prog') {
                            return (
                                <div>进度结算</div>
                            )
                        } else {
                            return (
                                <div>结算</div>
                            )
                        }
                    }
                },
                {
                    title: '预算成本(元)',
                    dataIndex: 'budget',
                    key: 'budget',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '实际成本',
                    children: [
                        {
                            title: '供应商单位名称',
                            dataIndex: 'sup_name',
                            key: 'sup_name',
                        },
                        {
                            title: '进度/结算金额(元)',
                            dataIndex: 'set_money',
                            key: 'set_money',
                            render: (text, record, index) => {
                                return (
                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                                )
                            }
                        }
                    ]
                },
                {
                    title: '已付款(元)',
                    dataIndex: 'pay_money',
                    key: 'pay_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '应付款(元)',
                    dataIndex: 'should_money',
                    key: 'should_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{text}</div>
                        )
                    }
                }
            ],
            det_dataSource: []
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_projectCostAccumulation()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectCostAccumulation = () => {
        let { pro_name, taxTotalMoney, conNotTaxMoney, conTotalMoney, sum_dataSource, det_dataSource, pro_id } = this.state;
        let data = {
            project_id: pro_id
        }
        projectCostAccumulation(data).then(
            (res) => {
                if (!res.code) {
                    pro_name = res.data.projectName
                    taxTotalMoney = res.data.taxTotalMoney
                    conNotTaxMoney = res.data.conNotTaxMoney
                    conTotalMoney = res.data.conTotalMoney

                    sum_dataSource = []

                    res.data.Summary.Machinery.class = '材料'
                    res.data.Summary.Machinery.key = 1
                    sum_dataSource = [...sum_dataSource, res.data.Summary.Machinery]

                    res.data.Summary.Artificial.class = '劳务'
                    res.data.Summary.Artificial.key = 2
                    sum_dataSource = [...sum_dataSource, res.data.Summary.Artificial]

                    res.data.Summary.Mechanics.class = '机械'
                    res.data.Summary.Mechanics.key = 3
                    sum_dataSource = [...sum_dataSource, res.data.Summary.Mechanics]

                    res.data.Summary.Summary.class = ''
                    res.data.Summary.Summary.key = '合计'
                    sum_dataSource = [...sum_dataSource, res.data.Summary.Summary]

                    det_dataSource = []

                    if (res.data.macDetail.length) {
                        res.data.macDetail.forEach((item, index) => {
                            item.key = index + 1

                            det_dataSource = [...det_dataSource, item]
                        })
                    }


                    this.setState(preState => ({
                        pro_name,
                        taxTotalMoney,
                        conNotTaxMoney,
                        conTotalMoney,
                        sum_dataSource,
                        det_dataSource
                    }))

                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_projectCostAccumulation()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { treeData, expandedKeys, selectedKeys, sum_columns, sum_dataSource, det_columns, det_dataSource, pro_name, taxTotalMoney, conNotTaxMoney, conTotalMoney } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div style={{ backgroundColor: '#FFFFFF', padding: '20px', borderRadius: '16px' }}>
                    <div className='formTitle'>{pro_name}</div>
                    <div className='FirstTitle'>
                        <div>
                            <div>项目成本汇总表</div>
                            <div><img src={Icon_01} alt=""></img></div>
                        </div>
                    </div>
                    <div className='summaryTable'>
                        <div>
                            <div>合同含税金额(元)</div>
                            <div>{conTotalMoney}</div>
                        </div>

                        <div>
                            <div>增值税(元)</div>
                            <div>{taxTotalMoney}</div>
                        </div>

                        <div>
                            <div>合同不含税金额(元)</div>
                            <div>{conNotTaxMoney}</div>
                        </div>
                    </div>

                    <Table columns={sum_columns} dataSource={sum_dataSource} pagination={false} bordered rowClassName={(record, index) => {
                        let className = 'light-row';
                        if (index % 2 === 1) className = 'dark-row';
                        return className;
                    }} />

                    <div className='FirstTitle' style={{ marginTop: '20px' }}>
                        <div>
                            <div>附表-项目成本明细表</div>
                            <div><img src={Icon_01} alt=""></img></div>
                        </div>
                    </div>

                    <Table columns={det_columns} dataSource={det_dataSource} pagination={false} bordered rowClassName={(record, index) => {
                        let className = 'light-row';
                        if (index % 2 === 1) className = 'dark-row';
                        return className;
                    }} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
