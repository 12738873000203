import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { weeklyList } from "../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '部门',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                },
                {
                    title: '本周完成工作',
                    dataIndex: 'over_content',
                    key: 'over_content',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '下周工作计划',
                    dataIndex: 'next_week',
                    key: 'next_week',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '160px',
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            start: '',
            end: '',
            user_id: '',
            de_id: '',
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_weeklyList()
    }

    get_weeklyList = () => {
        let { dataSource, pages, start, end, user_id, process_state } = this.state
        let data = {
            start: start,
            end: end,
            user_id: user_id,
            de_id: '',
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        weeklyList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.weekly.length) {
                        res.data.weekly.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.username,
                                deName: item.deName,
                                created_at: item.created_at,
                                pro_name: item.pro_name,
                                over_content: item.over_content,
                                next_week: item.next_week,
                                info: item.info,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/public/human/weekly/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/human/weekly/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            start: '',
            end: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_weeklyList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
            user_id: value.name
        }, () => {
            this.get_weeklyList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_weeklyList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)

