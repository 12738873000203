import React, { Component } from 'react'
import { FolderOutlined, FileTextOutlined } from '@ant-design/icons';
import { Layout, Menu, Input, Dropdown } from 'antd';
import { withRouter } from "react-router-dom";
import iconImg from "../accets/img/icon.png"

const { Sider } = Layout;
const { Search } = Input;

class SideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuList: [
                {
                    key: '/disposition',
                    label: '系统配置',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/disposition/notification/List',
                            label: '企业微信通知配置',
                            value: '系统配置-企业微信通知配置',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/disposition/send/List',
                            label: '抄送通知配置',
                            value: '系统配置-抄送通知配置',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/disposition/history/List',
                            label: '访问历史记录',
                            value: '访问历史数据',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                },
                {
                    key: '/item',
                    label: '项目总看板',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/item/control/List',
                            label: '项目动态总控表',
                            value: '项目总看板-项目动态总控表',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/finance/aggregation/List',
                            label: '项目成本归集表',
                            value: '项目总看板-项目成本归集表',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/item/projectKanban/List',
                            label: '358项目总看板',
                            value: '科研院-358项目数据看板',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                },
                {
                    key: '/design',
                    label: '设计中心',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/design/documentTransfer/List',
                            label: '设计文件移交及进度汇报',
                            value: '设计中心-设计文件移交及进度汇报',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                }, {
                    key: '/scientific_research',
                    label: '科研院',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/scientific_research/customer/List',
                            label: '客户管理',
                            value: '科研院-客户管理',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/scientific_research/project_application/List',
                            label: '科技项目申报',
                            value: '科研院-科技项目申报',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/scientific_research/project/List',
                            label: '研发项目',
                            value: '科研院-研发项目',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/scientific_research/knowledge',
                            label: '科研知识管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/scientific_research/knowledge/achievement/List',
                                    label: '成果管理',
                                    value: '科研院-成果信息登记',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/knowledge/honor/List',
                                    label: '资质荣誉证书',
                                    value: '科研院-资质荣誉证书',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/scientific_research/datum/List',
                            label: '资料管理',
                            value: '科研院-资料管理',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/scientific_research/patrol/List',
                            label: '项目养护巡查',
                            value: '科研院-项目养护巡查',
                            hidden: false,
                            icon: <FileTextOutlined />
                        }, {
                            key: '/scientific_research/contract/List',
                            label: '科研相关合同',
                            value: '科研院-科研相关合同',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/scientific_research/achievement_transformation',
                            label: '三五八成果转化',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/scientific_research/achievement_transformation/nursery/List',
                                    label: '苗圃名称对应表',
                                    value: '科研院-苗圃管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/plantIntroduction/List',
                                    label: '植物引种记录表',
                                    value: '科研院-苗木管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/storage/List',
                                    label: '出入库管理',
                                    value: '科研院-出入库管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />,
                                    children: [
                                        {
                                            key: '/scientific_research/achievement_transformation/beputinstorage/List',
                                            label: '入库管理',
                                            value: '科研院-入库苗木',
                                            hidden: false,
                                            icon: <FileTextOutlined />
                                        },
                                        {
                                            key: '/scientific_research/achievement_transformation/deliveryfromstorage/List',
                                            label: '出库管理',
                                            value: '科研院-出库苗木',
                                            hidden: false,
                                            icon: <FileTextOutlined />
                                        },
                                        {
                                            key: '/scientific_research/achievement_transformation/inventoryStatistics/List',
                                            label: '库存统计',
                                            value: '科研院-苗木库存统计',
                                            hidden: false,
                                            icon: <FileTextOutlined />
                                        },
                                    ]
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/projectInitiation/List',
                                    label: '项目立项',
                                    value: '科研院-358项目立项',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/proContractApproval/List',
                                    label: '项目合同审批',
                                    value: '科研院-358项目合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/contractApproval/List',
                                    label: '合同审批',
                                    value: '科研院-358合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/progressSettlement/List',
                                    label: '358进度计量',
                                    value: '科研院-358进度结算',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/nurserySettlement/List',
                                    label: '358结算审批',
                                    value: '科研院-苗圃结算审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/paymentApproval/List',
                                    label: '358付款审批',
                                    value: '科研院-358付款审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/identity/List',
                                    label: '认质核价审批',
                                    value: '科研院-认质核价审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/scientific_research/achievement_transformation/temporaryWorker/List',
                                    label: '临时用工付款申请表',
                                    value: '科研院-358临时用工申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                    ]
                },
                {
                    key: '/market',
                    label: '市场开发',
                    icon: <FolderOutlined />,
                    hidden: false,
                    children: [
                        {
                            key: '/market/application/List',
                            label: '投标决策审批表',
                            value: '市场开发-投标决策审批表',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/market/margin/List',
                            label: '投标保证金支付申请',
                            value: '市场开发-投标保证金支付申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/market/notice/List',
                            label: '中标通知书',
                            value: '市场开发-中标通知书',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/market/winning/List',
                            label: '项目中标立项',
                            value: '市场开发-项目中标立项',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/market/contract/List',
                            label: '收入合同审批',
                            value: '市场开发-收入合同审批',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/market/designScheme/List',
                            label: '设计项目任务申请',
                            value: '市场部-设计项目申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        }, {
                            key: '/market/approximateSum/List',
                            label: '概算项目任务申请',
                            value: '市场部-概算项目申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                },
                {
                    key: '/cost',
                    label: '成本控制',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/cost/labor/List',
                            label: '零星用工',
                            value: '成本控制-零星用工',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/supplier/List',
                            label: '供应商认价审批',
                            value: '成本控制-供应商认价审批',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/winning/List',
                            label: '中标清单',
                            value: '成本控制-中标清单',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/costbudget/List',
                            label: '成本预算明细表',
                            value: '成本控制-成本预算明细表',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/stronger/List',
                            label: '人材机总库',
                            value: '成本控制-人材机总库',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/price/List',
                            label: '人材机价格对比',
                            value: '成本控制-人材机价格对比',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/quantities/List',
                            label: '人材机工程量对比',
                            value: '成本控制-人材机工程量对比',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/cost/progressPayment/List',
                            label: '工程进度款审批',
                            value: '成本控制-工程进度款审批',
                            hidden: false,
                            icon: <FileTextOutlined />
                        }
                    ]
                },
                {
                    key: '/project',
                    label: '工程部',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/project/eng',
                            label: '工程管理中心',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/project/blanking/List',
                                    label: '人材机下料单',
                                    value: '工程部-人材机下料单',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/labor/List',
                                    label: '零星用工',
                                    value: '工程部-零星用工',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/goods/List',
                                    label: '收货管理',
                                    value: '工程部-收货管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/schedule/List',
                                    label: '人材机总计划表',
                                    value: '工程部-人材机总计划表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/invoice/List',
                                    label: '业主开票申请',
                                    value: '工程部-业主开票申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                }, {
                                    key: '/project/build/List',
                                    label: '施工日志',
                                    value: '工程部-施工日志',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/machinery/List',
                                    label: '机械台班记录表',
                                    value: '工程部-机械台班记录表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/spMaterial/List',
                                    label: '项目辅助用材及其他申请',
                                    value: '工程部-项目辅助用材及其他申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/project/data',
                            label: '资料管理中心',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/project/outputValue/List',
                                    label: '人材机产值申报',
                                    value: '工程部-人材机产值申报',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/material/List',
                                    label: '资料汇总表',
                                    value: '工程部-资料汇总表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/contract/List',
                                    label: '人材机合同审批',
                                    value: '工程部-人材机合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/settlement/List',
                                    label: '进度计量审批',
                                    value: '工程部-进度计量审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                }, {
                                    key: '/project/settlements/List',
                                    label: '结算审批',
                                    value: '工程部-结算审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                }, {
                                    key: '/project/payment/List',
                                    label: '人材机付款审批',
                                    value: '工程部-人材机付款审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                }, {
                                    key: '/project/account/List',
                                    label: '合作项目账户申请',
                                    value: '工程部-合作项目账户申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/responsibilityInformation/List',
                                    label: '获取项目责任书信息',
                                    value: '工程部-获取项目责任书信息',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/responsibilityReview/List',
                                    label: '项目责任书评审',
                                    value: '工程部-项目责任书评审',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/handoverOrder/List',
                                    label: '资料移交单',
                                    value: '工程部-资料移交',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/notice/List',
                                    label: '通知文件审批',
                                    value: '工程部-通知文件审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/receive/List',
                                    label: '收文管理',
                                    value: '工程部-收文管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/letter/List',
                                    label: '函件审批',
                                    value: '工程部-函件审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/designScheme/List',
                                    label: '设计项目任务申请',
                                    value: '工程部-设计项目申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/dataArchiving/List',
                                    label: '工程资料云归档',
                                    value: '工程部-工程资料云归档',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/project/attendanceSummary/List',
                                    label: '出勤签到汇总',
                                    value: '工程部-出勤签到汇总',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                }
                            ]
                        },
                    ]
                },
                {
                    key: '/purchase',
                    label: '集采中心',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/purchase/system/List',
                            label: '集采管理制度',
                            value: '集采中心-集采管理制度',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/purchase/stronger/List',
                            label: '人材机总库',
                            value: '集采中心-人材机总库',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/purchase/priceAdjustment/List',
                            label: '材料调价审批',
                            value: '集采中心-材料调价审批',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/purchase/supplier',
                            label: '供应商及认价管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/purchase/supplier/management/List',
                                    label: '供应商入库',
                                    value: '集采中心-供应商入库',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/supplier/storage/List',
                                    label: '优质供应商入库',
                                    value: '集采中心-优质供应商入库',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/supplier/audit/List',
                                    label: '供应商认价审批',
                                    value: '集采中心-供应商认价审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/supplier/information/List',
                                    label: '供应商认价详情数据',
                                    value: '集采中心-供应商认价详情数据',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/purchase/application',
                            label: '工程管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/purchase/application/List',
                                    label: '投标决策审批表',
                                    value: '集采中心-投标决策审批表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/winning/List',
                                    label: '项目中标立项',
                                    value: '集采中心-项目中标立项',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/market_contract/List',
                                    label: '收入合同审批',
                                    value: '集采中心-收入合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/blanking/List',
                                    label: '人材机下料单',
                                    value: '集采中心-人材机下料单',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/labor/List',
                                    label: '零星用工',
                                    value: '集采中心-零星用工',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/goods/List',
                                    label: '收货管理',
                                    value: '集采中心-收货管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/machinery/List',
                                    label: '机械台班记录表',
                                    value: '集采中心-机械台班记录表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/spMaterial/List',
                                    label: '项目辅助用材及其他申请',
                                    value: '集采中心-项目辅助用材及其他申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/schedule/List',
                                    label: '人材机总计划表',
                                    value: '集采中心-人材机总计划表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/purchase/contract/List',
                                    label: '人材机合同审批',
                                    value: '集采中心-人材机合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                // {
                                //     key: '/purchase/equipment/List',
                                //     label: 'GPS设备分配管理',
                                //   hidden:false,
                                //     icon: <FileTextOutlined />
                                // },
                                // {
                                //     key: '/purchase/registration/List',
                                //     label: '工程机械上下机登记',
                                //   hidden:false,
                                //     icon: <FileTextOutlined />
                                // },
                                // {
                                //     key: '/purchase/trajectory/List',
                                //     label: '工程机械运动轨迹',
                                //       hidden:false,
                                //     icon: <FileTextOutlined />
                                // },
                            ]
                        }, {
                            key: '/purchase/warehouse',
                            label: '仓库管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/purchase/warehouse/fixedAssets',
                                    label: '固定资产管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />,
                                    children: [
                                        {
                                            key: '/purchase/warehouse/fixedAssets/machine',
                                            label: '机械资产明细',
                                            hidden: false,
                                            icon: <FileTextOutlined />,
                                            children: [
                                                {
                                                    key: '/purchase/warehouse/fixedAssets/machine/mechanicalInformation/List',
                                                    label: '机械信息',
                                                    value: '集采中心-机械管理',
                                                    hidden: false,
                                                    icon: <FileTextOutlined />
                                                },
                                                {
                                                    key: '/purchase/warehouse/fixedAssets/machine/machineOperator/List',
                                                    label: '机操手信息',
                                                    value: '集采中心-机操手管理',
                                                    hidden: false,
                                                    icon: <FileTextOutlined />
                                                },
                                                {
                                                    key: '/purchase/warehouse/fixedAssets/machine/fuelCard/List',
                                                    label: '油卡信息',
                                                    value: '集采中心-油卡管理',
                                                    hidden: false,
                                                    icon: <FileTextOutlined />
                                                },
                                                {
                                                    key: '/purchase/warehouse/fixedAssets/machine/allot/List',
                                                    label: '调拨申请',
                                                    value: '集采中心-设备调拨',
                                                    hidden: false,
                                                    icon: <FileTextOutlined />
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    key: '/finance',
                    label: '财务部',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/finance/collectmoney/List',
                            label: '项目收款',
                            value: '财务部-项目收款',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/finance/pay/List',
                            label: '项目付款',
                            value: '财务部-项目付款',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/finance/reimbursement/List',
                            label: '费用报销',
                            value: '财务部-费用报销',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                },
                {
                    key: '/administrative',
                    label: '行政管理',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        {
                            key: '/administrative/user/List',
                            label: '用户管理',
                            value: '行政管理-用户管理',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/administrative/userGroup/List',
                            label: '用户组管理',
                            value: '行政管理-用户组管理',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/administrative/human',
                            label: '人力资源',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/human/induction/List',
                                    label: '入职办理',
                                    value: '行政管理-入职办理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/archives/List',
                                    label: '员工档案',
                                    value: '行政管理-员工档案',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/positive/List',
                                    label: '转正申请',
                                    value: '行政管理-转正申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/departure/List',
                                    label: '离职办理',
                                    value: '行政管理-离职办理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/turnoverHandover/List',
                                    label: '离职交接',
                                    value: '行政管理-离职交接',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/labor/List',
                                    label: '用工申请',
                                    value: '行政管理-用工申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/temporaryWork/List',
                                    label: '临时用工申请',
                                    value: '行政管理-临时用工申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/payroll/List',
                                    label: '临时用工工资表',
                                    value: '行政管理-临时用工工资表',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/human/projectTeam/List',
                                    label: '组建项目团队',
                                    value: '行政管理-组建项目团队',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/attendance',
                            label: '考勤管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/attendance/reissueCard/List',
                                    label: '补卡申请',
                                    value: '行政管理-补卡申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/attendance/brilliant/List',
                                    label: '出差申请',
                                    value: '行政管理-出差申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/attendance/leave/List',
                                    label: '请假申请',
                                    value: '行政管理-请假申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/attendance/send/List',
                                    label: '派遣申请',
                                    value: '行政管理-派遣申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/attendance/summary/List',
                                    label: '考勤汇总',
                                    value: '行政管理-考勤汇总',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/performance',
                            label: '绩效考核',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/performance/standard/List',
                                    label: '考核标准',
                                    value: '行政管理-考核标准',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/performance/record/List',
                                    label: '考核记录',
                                    value: '行政管理-考核记录',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/workSummary',
                            label: '工作总结',
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/workSummary/weekly/List',
                                    label: '工作周报',
                                    value: '行政管理-工作周报',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/workSummary/annual/List',
                                    label: '年度总结',
                                    value: '行政管理-年度总结',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/seal',
                            label: '印章管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/seal/chapter/List',
                                    label: '用章申请',
                                    value: '行政管理-用章申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/seal/management/List',
                                    label: '印章管理',
                                    value: '行政管理-印章管理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/seal/engrave/List',
                                    label: '印章刻制申请',
                                    value: '行政管理-印章刻制申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/seal/cancellation/List',
                                    label: '印章注销',
                                    value: '行政管理-印章注销',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        }, {
                            key: '/administrative/vehicle',
                            label: '车辆管理',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/vehicle/car/List',
                                    label: '用车申请',
                                    value: '行政管理-用车申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/vehicle/dispatch/List',
                                    label: '车辆派遣',
                                    value: '行政管理-车辆派遣',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/equipment',
                            label: '设备购置',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/administrative/equipment/ordinary/List',
                                    label: '设备购置申请',
                                    value: '行政管理-设备购置申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/equipment/stationery/List',
                                    label: '办公用品申请',
                                    value: '行政管理-办公用品申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/administrative/equipment/administrative/List',
                                    label: '行政设备购置申请',
                                    value: '行政管理-行政设备购置申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/administrative/application/List',
                            label: '流程设计',
                            value: '行政管理-流程设计',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/administrative/notice/List',
                            label: '发文管理',
                            value: '行政-发文管理',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                },
                {
                    key: '/public',
                    label: '公共菜单',
                    hidden: false,
                    icon: <FolderOutlined />,
                    children: [
                        // {
                        //     key: '/public/system/List',
                        //     label: '系统日志',
                        //                 hidden:false,
                        //     icon: <FileTextOutlined />
                        // },
                        {
                            key: '/public/human/leave/List',
                            label: '请假申请',
                            value: '公共菜单-请假申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/human/evection/List',
                            label: '出差申请',
                            value: '公共菜单-出差申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/human/reissueCard/List',
                            label: '补卡申请',
                            value: '公共菜单-补卡申请',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/human/weekly/List',
                            label: '工作周报',
                            value: '公共菜单-工作周报',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/construction/List',
                            label: '施工组织设计审批',
                            value: '公共菜单-施工组织设计审批',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/temporaryPipe/List',
                            label: '工作通知暂管申请',
                            value: '公共菜单-工作通知暂管',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                        {
                            key: '/public/payment',
                            label: '付款审批',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/public/payment/borrowing/List',
                                    label: '员工借款审批',
                                    value: '公共菜单-员工借款审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/payment/reimbursement/List',
                                    label: '员工报销审批',
                                    value: '公共菜单-员工报销审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/payment/comprehensive/List',
                                    label: '综合/科研付款审批',
                                    value: '公共菜单-综合/科研付款审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/payment/engineering/List',
                                    label: '项目其他支付审批',
                                    value: '公共菜单-工程款支付审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/payment/patentRenewal/List',
                                    label: '专利续费付款审批',
                                    value: '公共菜单-专利续费付款审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/public/human',
                            label: '人力资源',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/public/human/labor/List',
                                    label: '用工申请',
                                    value: '公共菜单-用工申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/human/temporaryWork/List',
                                    label: '临时用工申请',
                                    value: '公共菜单-临时用工申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/human/positive/List',
                                    label: '转正申请',
                                    value: '公共菜单-转正申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/human/departure/List',
                                    label: '离职办理',
                                    value: '公共菜单-离职办理',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/human/send/List',
                                    label: '派遣申请',
                                    value: '公共菜单-派遣申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/human/conclusion/List',
                                    label: '年度总结',
                                    value: '公共菜单-年度总结',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/public/office',
                            label: '行政办公',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/public/office/chapter/List',
                                    label: '用章申请',
                                    value: '公共菜单-用章申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/car/List',
                                    label: '用车申请',
                                    value: '公共菜单-用车申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/vehicle/List',
                                    label: '车辆派遣',
                                    value: '公共菜单-车辆派遣',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/business/List',
                                    label: '业务招待申请',
                                    value: '公共菜单-业务招待申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/stationery/List',
                                    label: '办公用品申请',
                                    value: '公共菜单-办公用品申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/purchase/List',
                                    label: '设备购置申请',
                                    value: '公共菜单-设备购置申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                                {
                                    key: '/public/office/seal/List',
                                    label: '印章刻制申请',
                                    value: '公共菜单-印章刻制申请',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/public/contract',
                            label: '合同审批',
                            hidden: false,
                            icon: <FileTextOutlined />,
                            children: [
                                {
                                    key: '/public/contract/List',
                                    label: '公司合同审批',
                                    value: '公共菜单-公司合同审批',
                                    hidden: false,
                                    icon: <FileTextOutlined />
                                },
                            ]
                        },
                        {
                            key: '/project/dataUpload/List',
                            label: '工程过程资料上传',
                            value: '公共菜单-过程资料上传',
                            hidden: false,
                            icon: <FileTextOutlined />
                        },
                    ]
                }
            ],
            itemList: [],
            selectedKeys: [],
            openKeys: [],
            FlowchartKey: 1,
            path: ''
        }
    }

    componentDidMount() {
        let permission = JSON.parse(localStorage.getItem("permission"))
        let { menuList } = this.state

        menuList.forEach((item, index) => {
            item.hidden = true
            item.children.forEach((tem, ndex) => {
                if (tem.children) {
                    tem.hidden = true
                    tem.children.forEach((em, dex) => {
                        if (em.children) {
                            em.hidden = true
                            em.children.forEach((m, ex) => {
                                if (m.children) {
                                    m.hidden = true
                                    m.children.forEach((n, w) => {
                                        n.hidden = true
                                        if (permission) {
                                            permission.forEach((e, x) => {
                                                if (n.value === e) {
                                                    n.hidden = false
                                                    m.hidden = false
                                                    em.hidden = false
                                                    tem.hidden = false
                                                    item.hidden = false
                                                }
                                            })
                                        }
                                    })
                                } else {
                                    m.hidden = true
                                    if (permission) {
                                        permission.forEach((e, x) => {
                                            if (m.value === e) {
                                                m.hidden = false
                                                em.hidden = false
                                                tem.hidden = false
                                                item.hidden = false
                                            }
                                        })
                                    }
                                }

                            })
                        } else {
                            em.hidden = true
                            if (permission) {
                                permission.forEach((e, x) => {
                                    if (em.value === e) {
                                        em.hidden = false
                                        tem.hidden = false
                                        item.hidden = false
                                    }
                                })
                            }
                        }
                    })
                } else {
                    tem.hidden = true
                    if (permission) {
                        permission.forEach((e, x) => {
                            if (tem.value === e) {
                                tem.hidden = false
                                item.hidden = false
                            }
                        })
                    }
                }
            })
        })

        this.setState(preState => ({
            menuList,
            FlowchartKey: Math.random(),
        })
        )
    }

    onSearch = (value) => {
        console.log(value)
    };

    searchChange = (e) => {
        let { menuList } = this.state
        let value = e.target.value
        let searchList = []
        if (value) {
            menuList.forEach((item, index) => {
                item.children.forEach((tem, dex) => {
                    if (tem.children) {
                        tem.children.forEach((em, ex) => {
                            if (!em.hidden) {
                                if (em.label.search(value) !== -1) {
                                    let label = {
                                        label: (
                                            <span>{em.label}</span>
                                        ),
                                        key: em.key,
                                        value: em.value
                                    }
                                    searchList = [...searchList, label]
                                }
                            }
                        })

                    } else {
                        if (!tem.hidden) {
                            if (tem.label.search(value) !== -1) {
                                let label = {
                                    label: (
                                        <span>{tem.label}</span>
                                    ),
                                    key: tem.key,
                                    value: tem.value
                                }
                                searchList = [...searchList, label]
                            }
                        }

                    }
                })
            })
        }
        this.setState(preState => ({
            itemList: searchList
        })
        )
    }

    menu = () => (
        <Menu
            items={this.state.itemList}
            onClick={(e) => {
                console.log(e, e.item.props.value)
                this.handleItem(e.key)
            }}
        />
    )

    handleItem = (value) => {
        this.setState(preState => ({
            itemList: [],
            FlowchartKey: Math.random(),
        })
        )
        this.props.history.push(value)
    }

    onOpenChange = (value) => {
        this.setState(preState => ({
            openKeys: value
        })
        )
    }

    onSelect = (value) => {
        let { path, selectedKeys } = this.state
        if (path !== value.key) {
            selectedKeys = []
            selectedKeys.push(value.key)
            this.setState(preState => ({
                selectedKeys: selectedKeys,
                path: value.key
            }), () => {
                this.props.history.push(value.key)
            })
        }
    }

    render() {
        const { menuList, FlowchartKey, openKeys, selectedKeys } = this.state
        return (
            <Sider className='sideMenu' key={FlowchartKey}>
                <div className='sideMenu_plate'>
                    <img src={iconImg} alt=""></img>
                    <div>
                        <Dropdown overlay={this.menu} trigger={['click']}>
                            <Search
                                placeholder="菜单查询"
                                onSearch={(e) => {
                                    this.onSearch(e)
                                }}

                                onChange={(e) => {
                                    this.searchChange(e)
                                }}
                            />
                        </Dropdown>

                    </div>
                </div>

                <Menu
                    openKeys={openKeys}
                    selectedKeys={selectedKeys}
                    onOpenChange={(e) => {
                        this.onOpenChange(e)
                    }}
                    onSelect={(e) => {
                        this.onSelect(e)
                    }}
                    mode="inline"
                    items={menuList}
                />
            </Sider>
        )
    }
}

export default withRouter(SideMenu)
