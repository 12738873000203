import React, { Component } from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import { sealManagerList, createSealManager } from "../../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '印章名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '有效期',
                    dataIndex: 'end_day',
                    key: 'end_day',
                },
                {
                    title: '印鉴',
                    dataIndex: 'enclosure',
                    key: 'enclosure',
                },
                {
                    title: '创建时间',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '印章名称', //字段标题
                    fieldName: 'key', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            Info: {
                FlowModelName: "印章管理",
                TableInfo: [{
                    FieldTitle: '印章名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入印章名称', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '有效期', //字段标题
                    fieldName: 'end_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择有效期', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '印鉴', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            },
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            key: '',
            form: {}
        }
    }

    componentDidMount() {
        this.get_sealManagerList()
    }

    get_sealManagerList = () => {
        let { dataSource, pages, key } = this.state
        let data = {
            key: key,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        sealManagerList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.sealManagers.length) {
                        res.data.sealManagers.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                name: item.name,
                                end_day: item.end_day,
                                enclosure: item.enclosure,
                                created_at: item.created_at,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handleNew = () => {
        this.setState({
            modal_Visible: true
        })
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false
        })
    }

    handPresent = (value) => {
        const { form,Info } = this.state
        form.name = value.name
        form.end_day = value.end_day.format('YYYY-MM-DD')
        createSealManager(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState({
                        modal_Visible: false
                    },()=>{
                        this.get_sealManagerList()
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            apply_user: '',
            start: '',
            end: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_sealManagerList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            key: value.key,
        }, () => {
            this.get_sealManagerList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_sealManagerList()
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Append modal_Visible={this.state.modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} Info={this.state.Info} fileEvent={this.handFile} />
            </div>
        )
    }
}


export default withRouter(List)
