import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../../components/AddConfig'
import InitialValue from '../../../../components/InitialValue'
import { createSeedlingInventory, SeedlingInventoryDetail, selectNurseryList } from "../../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "入库管理",
            TableInfo: [
                {
                    FieldTitle: '入库时间', //字段标题
                    fieldName: 'warehousing', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择入库时间', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '入库类型', //字段标题
                    fieldName: 'type', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择入库类型', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            label: '外部购买',
                            value: 1
                        },
                        {
                            label: '自主培育',
                            value: 2
                        }
                    ]
                },
                {
                    FieldTitle: '选择入库苗圃', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '入库苗圃', //字段标题
                    fieldName: 'plant_type', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '苗圃编号', //字段标题
                    fieldName: 'plant_type', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
            ],
            FirstInfo: [
                {
                    plateName: '清单明细',
                    list: [],
                    imptable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '',
                                dataIndex: 'key',
                                key: 'key',
                            },
                            {
                                title: '植物编号',
                                dataIndex: 'plant_code',
                                key: 'plant_code',
                            }, {
                                title: '采购单位',
                                dataIndex: 'supply_unit',
                                key: 'supply_unit',
                            },
                            {
                                title: '植物名称',
                                dataIndex: 'plant_name',
                                key: 'plant_name',
                            },
                            {
                                title: '型号规格',
                                dataIndex: 'model',
                                key: 'model',
                            },
                            {
                                title: '入库数量',
                                dataIndex: 'see_number',
                                key: 'see_number',
                            },
                            {
                                title: '入库单价',
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: '入库总额',
                                dataIndex: 'all_price',
                                key: 'all_price',
                            },
                            {
                                title: '负责人/签收人',
                                dataIndex: 'signatory',
                                key: 'signatory',
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/importInventory',
                            name: 'seedling',
                            data: {
                                is_product: 1,
                                operate :'in'
                            }
                        },
                        summary: true,
                        summaryValue: {
                            colspan: 10,
                            name: '植物入库总额(元)',
                            value: 0
                        }
                    }
                },
                {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '验收单', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '苗圃编号',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: '苗圃名称',
                dataIndex: 'name',
                key: 'name',
            },
        ],
        TableInfo: [],
        pages: {
            page: 1,
            total: 0,
            limit: 50,
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SeedlingInventoryDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_selectNurseryList = () => {
        let { dataSource } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                key: item.id,
                                code: item.code,
                                name: item.name
                            }
                            dataSource = [...dataSource, value]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_SeedlingInventoryDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        SeedlingInventoryDetail(data).then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        item.disabled = true

                        if (item.FieldTitle === '入库苗圃') {
                            item.value = res.data.Detail.nur_name
                        } else if (item.FieldTitle === '苗圃编号') {
                            item.value = res.data.Detail.nur_code
                        }else if (item.FieldTitle === '选择入库苗圃') {
                            item.isShow=true
                        }
                    })

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        item.disabled = true
                        if (item.FieldTitle === '验收单') {
                            if (res.data.Detail.enclosure.length) {
                                item.value = res.data.Detail.enclosure
                            } else {
                                item.isShow = true
                            }
                        }
                    })

                    if (res.data.Detail.details) {
                        let total_money = 0
                        Info.FirstInfo[0].imptable.dataSource = []
                        res.data.Detail.details.forEach((item, index) => {
                            item.key = index + 1
                            Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                            total_money = total_money + Number(item.all_price)
                        })
                        Info.FirstInfo[0].imptable.summaryValue.value = total_money
                    }

                    Info.state = res.data.Detail.status


                    this.NewForm.current.formRef.current.setFieldsValue({
                        warehousing: res.data.Detail.warehousing ? moment(res.data.Detail.warehousing, 'YYYY-MM-DD') : '',
                        type: res.data.Detail.type,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.is_product = 1
        form.operate = 'in'
        form.warehousing = data.warehousing ? data.warehousing.format('YYYY-MM-DD') : ''
        form.type = data.type
        form.nur_table = JSON.stringify(Info.FirstInfo[0].imptable.dataSource)


        createSeedlingInventory(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // 文件上传改变事件
    updateChange = (info) => {
        let { Info } = this.state
        if (info.file.status === 'done') {
            console.log(info)
            if (!info.file.response.code) {
                message.success('导入成功')
                info.file.response.data.data.forEach((item, index) => {
                    item.key = index + 1
                    Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                })

                Info.FirstInfo[0].imptable.summaryValue.value = info.file.response.data.total_money

                this.setState(preState => ({
                    Info
                })
                )
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    handleClear = () => {
        let { Info } = this.state
        Info.FirstInfo[0].imptable.dataSource = []
        this.setState(preState => ({
            Info,
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handButton = (title, state) => {
        if (state) {
            this.get_selectNurseryList()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.nur_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '入库苗圃') {
                item.value = value[0].name
            } else if (item.FieldTitle === '苗圃编号') {
                item.value = value[0].code
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_selectNurseryList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.selectNurseryList()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} uploadEvent={this.updateChange} ClearEvent={this.handleClear} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
