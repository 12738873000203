import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Operate from '../../../../../components/Operate'
import Search from '../../../../../components/Search'
import Table from '../../../../../components/Table'
import Append from '../../../../../components/addAppend'
import { OilCardList, getOilCardDetail, createOilCard, operatorList } from "../../../../../../utils/purchase"


export default class List extends Component {
    state = {
        searchShow: false,
        modal_Visible: false,
        selectValue: [],
        dataSource: [],
        columns: [
            {
                title: '公司名称',
                dataIndex: 'com_name',
                key: 'com_name',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_getOilCardDetail(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '油卡编号',
                dataIndex: 'oil_code',
                key: 'oil_code',
            },
            {
                title: '定油',
                dataIndex: 'oil_number',
                key: 'oil_number',
            },
            {
                title: '可使用范围',
                dataIndex: 'use_range',
                key: 'use_range',
            },
            {
                title: '机操手',
                dataIndex: 'oper_user',
                key: 'oper_user',
            },
            {
                title: '所在项目',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '所在机械',
                dataIndex: 'plant_name',
                key: 'plant_name',
            },
            {
                title: '使用状态',
                dataIndex: 'use_status',
                key: 'use_status',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '公司名称', //字段标题
                fieldName: 'com_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
        ],
        com_name: '',
        Info: {
            state: '新增',
            FlowModelName: "油卡信息",
            TableInfo: [{
                FieldTitle: '公司名称', //字段标题
                fieldName: 'com_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入公司名称', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '油卡编号', //字段标题
                fieldName: 'oil_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入油卡编号', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '定油', //字段标题
                fieldName: 'oil_number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '可使用范围', //字段标题
                fieldName: 'use_range', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '使用状态', //字段标题
                fieldName: 'use_status', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择使用状态', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        label: '使用中',
                        value: '使用中'
                    },
                    {
                        label: '已废弃',
                        value: '已废弃'
                    },
                    {
                        label: '已移交',
                        value: '已移交'
                    }
                ]
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }
            ],
            FirstInfo: []
        },
        pages: {
            limit: 8,
            page: 1,
            total: 0
        },
        form: {},
        FlowchartKey: 1,
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_OilCardList()
    }

    get_OilCardList = () => {
        let { dataSource, pages, com_name, } = this.state
        let data = {
            com_name: com_name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        OilCardList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.operators.length) {
                        res.data.operators.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                com_name: item.com_name,
                                oil_code: item.oil_code,
                                oil_number: item.oil_number,
                                use_range: item.use_range,
                                use_status: item.use_status,
                                oper_user: item.oper_userName || '/',
                                pro_name: item.proName || '/',
                                plant_name: item.plantName || '/',
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_getOilCardDetail = (tableId, status) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        getOilCardDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.oper_user = res.data.oper_user
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }

                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        const { Info } = this.state;
        Info.state = '新增'

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_getOilCardDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = value.enclosure.length ? value.enclosure : ''
            }
        })

        this.NewForm.current.formRef.current.setFieldsValue({
            com_name: value.com_name,
            oil_code: value.oil_code,
            oil_number: value.oil_number,
            use_range: value.use_range,
            use_status: value.use_status,
            info: value.info,
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })

        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                com_name: '',
                oil_code: '',
                oil_number: '',
                use_range: '',
                use_status: '',
                info: '',
                enclosure: '',
            })
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            com_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_OilCardList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            com_name: value.com_name,
        }, () => {
            this.get_OilCardList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_OilCardList()
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.com_name = data.com_name
        form.oil_code = data.oil_code
        form.oil_number = data.oil_number
        form.use_range = data.use_range
        form.use_status = data.use_status
        form.info = data.info

        createOilCard(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.modalCancel()
                    this.get_OilCardList()
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { searchShow, TableInfo, dataSource, columns, pages, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} fileEvent={this.handFile} buttonEvent={this.handButton} />
            </div>
        )
    }
}
