import React, { Component } from 'react'
import { Select, Button } from 'antd';
import { CaretDownOutlined, FileDoneOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '部门',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '一月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '二月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '三月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '四月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '五月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '六月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '七月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '八月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '九月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '十月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '十一月',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '十二月',
                    dataIndex: 'name',
                    key: 'name',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            yearList: [
                { value: 1, label: "2022年" },
                { value: 2, label: "2023年" },
                { value: 3, label: "2024年" },
                { value: 4, label: "2025年" },
            ],
            yearValue: '',
            teamList: [
                { value: 1, label: "全部" },
                { value: 2, label: "市场部" },
                { value: 3, label: "工程部" },
                { value: 4, label: "设计部" },
                { value: 5, label: "集采中心" },
            ],
            teamValue: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    handleNew = () => {}

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false
        })
    }

    searchQuery = () => {
        console.log('筛选')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {})
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, yearValue, yearList, teamList, teamValue } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <div className='history ListOperate' style={{width:'auto',marginRight:'15px'}}>
                            <Button>
                                <FileDoneOutlined />
                                <div>考核结果</div>
                            </Button>
                        </div>
                        <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />
                    </div>


                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择部门 :</div>
                            <Select
                                options={teamList}
                                value={teamValue}
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit}/>
            </div>
        )
    }
}

export default withRouter(List)
