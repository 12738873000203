import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createTemporaryEmployment, temporaryEmploymentDetail } from "../../../../utils/oa"
import { selectProjectName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "临时用工申请",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true,
                isShow: false,
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'project_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'project_code', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目面积', //字段标题
                fieldName: 'pro_area', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请填写项目面积', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '临时用工人数', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请填写临时用工人数', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '工资标准', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请填写工资标准', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '用工姓名', //字段标题
                fieldName: 'usernames', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请填写用工姓名', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '开始时间', //字段标题
                fieldName: 'start', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择开始时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '结束时间', //字段标题
                fieldName: 'end', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择结束时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [{
            title: '项目名称',
            dataIndex: 'pro_name',
            key: 'pro_name',
        },
        {
            title: '项目编号',
            dataIndex: 'pro_number',
            key: 'pro_number',
        },
        {
            title: '项目经理',
            dataIndex: 'managerName',
            key: 'managerName',
        },
        {
            title: '项目地址',
            dataIndex: 'pro_address',
            key: 'pro_address',
        },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '95%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_EmploymentDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_EmploymentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        temporaryEmploymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        project_id: res.data.Detail.project_id,
                        pro_area: res.data.Detail.pro_area,
                        number: res.data.Detail.number,
                        usernames: res.data.Detail.usernames,
                        money: res.data.Detail.money,
                        start: res.data.Detail.start ? moment(res.data.Detail.start, 'YYYY-MM-DD') : '',
                        end: res.data.Detail.end ? moment(res.data.Detail.end, 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_area = data.pro_area
        form.number = data.number
        form.usernames = data.usernames
        form.money = data.money
        form.start = data.start ? data.start.format('YYYY-MM-DD') : ''
        form.end = data.end ? data.end.format('YYYY-MM-DD') : ''
        form.info = data.info

        createTemporaryEmployment(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
