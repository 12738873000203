import React, { Component } from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import { ApartmentOutlined } from '@ant-design/icons';
import { Modal, Button, Checkbox } from 'antd';
import { indexData, getUseFunction, createUserCommon, userCommonList, userCommonDelete, previewNotice, IndexProcessRead, readConstructionLog } from "../../utils/index"
import userImg from "../../accets/img/user.png"
import homeImg from "../../accets/img/home_01.png"
import state_01Img from "../../accets/img/state_01.png"
import state_02Img from "../../accets/img/state_02.png"
import state_03Img from "../../accets/img/state_03.png"
import state_04Img from "../../accets/img/state_04.png"
import functionImg from "../../accets/img/function.png"
import add_01Img from "../../accets/img/add_01.png"
import edit_01Img from "../../accets/img/edit_01.png"
import add_02Img from "../../accets/img/add_02.png"
import edit_02Img from "../../accets/img/edit_02.png"
import deleteImg from "../../accets/img/delete.png"
import clearImg from "../../accets/img/clear.png"


class Index extends Component {
    state = {
        userName: '',
        commonId: '',
        processInfo: {},
        notices: [],
        commonList: [],
        functionList: [],
        commonly: [],
        confirm_Modal: false,
        clear_Modal: false,
        add_Modal: false,
        prompt_Modal: false,
        isEdit: false,
        isBacklog: false
    };

    componentDidMount() {
        this.get_userInfo()
        this.get_indexData()
        this.get_userCommonList()
    }

    get_userInfo = () => {
        let { isBacklog } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            permission.forEach((item, index) => {
                if (item === 'eng_personnel') {
                    isBacklog = true
                }
            })
        }
        this.setState(preState => ({
            userName: userInfo.name,
            isBacklog
        })
        )
    }

    get_indexData = () => {
        let { processInfo, notices } = this.state
        indexData().then(
            (res) => {
                if (!res.code) {
                    processInfo.flowsNumber = res.data.flowsNumber
                    processInfo.flowsIsRead = res.data.flowsIsRead
                    processInfo.flowsCcNumber = res.data.flowsCcNumber
                    processInfo.flowsCcIsRead = res.data.flowsCcIsRead
                    processInfo.weeklyNumber = res.data.weeklyNumber || 0
                    processInfo.weeklyIsRead = res.data.weeklyIsRead || false
                    processInfo.ConstructionIsRead = res.data.ConstructionIsRead || false
                    processInfo.ConstructionNumber = res.data.ConstructionNumber || 0
                    notices = res.data.notices
                    this.setState(preState => ({
                        processInfo,
                        notices
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_UseFunction = () => {
        let { functionList } = this.state
        getUseFunction().then(
            (res) => {
                if (!res.code) {
                    functionList = []
                    res.data.command.forEach((item, index) => {
                        if (item.list_url) {
                            let value = {
                                value: item.list_url,
                                label: item.name
                            }
                            functionList = [...functionList, value]
                        }
                    })
                    this.setState(preState => ({
                        functionList,
                        add_Modal: true
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userCommonList = () => {
        let { commonList, commonly } = this.state
        userCommonList().then(
            (res) => {
                if (!res.code) {
                    commonList = []
                    commonly = []
                    res.data.commons.forEach((item, index) => {
                        let value = {
                            value: item.fun_value,
                            label: item.fun_name,
                            id: item.id
                        }
                        commonList = [...commonList, value]
                        commonly = [...commonly, item.fun_value]
                    })
                    this.setState(preState => ({
                        commonList,
                        commonly,
                        prompt_Modal: false
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleUserCommon = () => {
        let { functionList, commonly } = this.state
        let data = {
            command: [],
        }
        functionList.forEach((item, index) => {
            commonly.forEach((tem, dex) => {
                if (tem === item.value) {
                    let value = {
                        fun_name: item.label,
                        fun_value: item.value
                    }
                    data.command = [...data.command, value]
                }
            })
        })
        data.command = JSON.stringify(data.command)
        createUserCommon(data).then(
            (res) => {
                if (!res.code) {
                    this.setState({
                        add_Modal: false,
                        prompt: '已添加!',
                        prompt_Modal: true
                    });
                } else {
                    message.error('添加失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleClear = (e) => {
        e.stopPropagation();
        this.setState({
            clear_Modal: true,
        })
    }

    handleRead = () => {
        let { isBacklog } = this.state
        if (!isBacklog) {
            this.get_IndexProcessRead()
        } else {
            this.get_readConstructionLog()
        }
    }

    get_IndexProcessRead = () => {
        IndexProcessRead().then(
            (res) => {
                if (!res.code) {
                    message.success('已将所有待阅标为已读!')
                    this.get_indexData()
                    this.setState({
                        clear_Modal: false,
                    })
                } else {
                    message.error('一键阅读失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_readConstructionLog = () => {
        let data = {
            range: 'all'
        }
        readConstructionLog(data).then(
            (res) => {
                if (!res.code) {
                    message.success('已将所有日志标为已读!')
                    this.get_indexData()
                    this.setState({
                        clear_Modal: false,
                    })
                } else {
                    message.error('一键阅读失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleCommonDelete = () => {
        let { commonId } = this.state
        let data = {
            common_id: commonId
        }
        userCommonDelete(data).then(
            (res) => {
                if (!res.code) {
                    this.setState({
                        confirm_Modal: false,
                        prompt: '已删除!',
                        prompt_Modal: true
                    });
                } else {
                    message.error('删除错误!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_menu = (item) => {
        this.props.history.push(item.value)
    }

    selectCheck = (value) => {
        this.setState({
            commonly: value
        });
    }

    get_process = (value) => {
        this.props.history.push(value)
    }

    handleEdit = () => {
        let { isEdit } = this.state
        this.setState({
            isEdit: !isEdit
        });
    }

    handleDel = (e, value) => {
        e.stopPropagation()
        this.setState({
            confirm_Modal: true,
            commonId: value
        })
    }

    get_cancelModal = () => {
        this.setState({
            confirm_Modal: false,
            add_Modal: false,
            prompt_Modal: false,
            clear_Modal: false
        });
    }

    get_previewNotice = (file) => {
        let data = {
            table_id: file.table_id
        }
        previewNotice(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                } else {
                    message.error(res.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { processInfo, notices, functionList, commonList, commonly, add_Modal, prompt_Modal, confirm_Modal, prompt, isEdit, isBacklog, clear_Modal } = this.state
        return (
            <div>
                <div className='index_introduce'>
                    <div>
                        <div>
                            <img src={userImg} alt="" style={{ width: '52px', height: '52px' }}></img>
                        </div>
                        <div>
                            你好{this.state.userName}，欢迎来到云数控~
                        </div>
                    </div>
                    <div>
                        <img src={homeImg} alt=""></img>
                    </div>
                </div>

                <div className='index_style_01'>
                    <div className='common_function'>
                        <div>通知公告</div>
                        <div className='my_process' onClick={() => this.get_process('/administrative/notice/Lists')}>
                            {/* <ApartmentOutlined /> */}
                            <div>查看更多</div>
                        </div>
                    </div>
                    <div className='index_notice'>
                        {
                            notices.length ? notices.map((item, index) => {
                                return (
                                    <div style={{ cursor: 'pointer' }} onClick={() => this.get_previewNotice(item)} key={index}>
                                        <div><li>{item.notice_name}</li></div>
                                        <div>{item.public_day}</div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>

                <div className='index_style_02'>
                    <div className='common_function'>
                        <div>流程信息</div>
                        <div className='my_process' onClick={() => this.get_process('/process')}>
                            {/* <ApartmentOutlined /> */}
                            <div>我的流程</div>
                        </div>
                    </div>
                    <div className='index_flow'>
                        <div onClick={() => this.get_process('/backlog')}>
                            <div>
                                <div className='flow_backlog'>
                                    <div>
                                        <div style={{ backgroundColor: processInfo.flowsIsRead ? '#F53500' : '#FFFFFF' }}></div>
                                        <div>{processInfo.flowsNumber}</div>
                                    </div>
                                    <div>
                                        <div>点击查看</div>
                                        <div className='flow_backlog_state_01'></div>
                                    </div>
                                </div>
                                <div><img src={state_01Img} alt=""></img></div>
                            </div>
                            <div>流程待办</div>
                        </div>

                        {
                            !isBacklog ? <div onClick={() => this.get_process('/flow')}>
                                <div>
                                    <div className='flow_backlog'>
                                        <div>
                                            <div style={{ backgroundColor: processInfo.flowsCcIsRead ? '#F53500' : '#FFFFFF' }}></div>
                                            <div>{processInfo.flowsCcNumber}</div>
                                            {
                                                Number(processInfo.flowsCcNumber) > 0 ? <img src={clearImg} alt="" className='clear' onClick={(e) => {
                                                    this.handleClear(e)
                                                }}></img> : ''
                                            }
                                        </div>
                                        <div>
                                            <div>点击查看</div>
                                            <div className='flow_backlog_state_02'></div>
                                        </div>
                                    </div>
                                    <div><img src={state_02Img} alt=""></img></div>
                                </div>
                                <div>流程待阅</div>
                            </div> : <div onClick={() => this.get_process('/log')}>
                                <div>
                                    <div className='flow_backlog'>
                                        <div>
                                            <div style={{ backgroundColor: processInfo.ConstructionIsRead ? '#F53500' : '#FFFFFF' }}></div>
                                            <div>{processInfo.ConstructionNumber}</div>
                                            {
                                                Number(processInfo.ConstructionNumber) > 0 ? <img src={clearImg} alt="" className='clear' onClick={(e) => {
                                                    this.handleClear(e)
                                                }}></img> : ''
                                            }
                                        </div>
                                        <div>
                                            <div>点击查看</div>
                                            <div className='flow_backlog_state_02'></div>
                                        </div>
                                    </div>
                                    <div><img src={state_02Img} alt=""></img></div>
                                </div>
                                <div>施工日志</div>
                            </div>
                        }

                        <div onClick={() => this.get_process('/week')}>
                            <div>
                                <div className='flow_backlog'>
                                    <div>
                                        <div style={{ backgroundColor: processInfo.weeklyIsRead ? '#F53500' : '#FFFFFF' }}></div>
                                        <div>{processInfo.weeklyNumber}</div>
                                    </div>
                                    <div>
                                        <div>点击查看</div>
                                        <div className='flow_backlog_state_03'></div>
                                    </div>
                                </div>
                                <div><img src={state_03Img} alt=""></img></div>
                            </div>
                            <div>周报待阅</div>
                        </div>
                    </div>
                </div>

                <div className='index_style_01'>
                    <div className='common_function'>
                        <div> 常用功能</div>
                        <div>
                            <div>
                                {
                                    isEdit ? <img src={edit_02Img} alt="" onClick={() => this.handleEdit()}></img> : <img src={edit_01Img} alt="" onClick={() => this.handleEdit()}></img>
                                }
                            </div>
                            <div>
                                {
                                    add_Modal ? <img src={add_02Img} alt=""></img> : <img src={add_01Img} alt="" onClick={() => this.get_UseFunction()}></img>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='index_function'>
                        {
                            commonList.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        this.get_menu(item)
                                    }} style={{ width: '20%' }}>
                                        <div>
                                            <img src={functionImg} alt=""></img>
                                            {
                                                isEdit ? <img src={deleteImg} alt="" onClick={(e) => this.handleDel(e, item.id)}></img> : ''
                                            }
                                        </div>
                                        <div title={item.label}>
                                            {item.label}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                <Modal
                    title="信息"
                    centered
                    visible={confirm_Modal}
                    onOk={() => this.handleCommonDelete()}
                    onCancel={() => this.get_cancelModal()}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    确定删除吗?
                </Modal>

                <Modal
                    title="信息"
                    centered
                    visible={clear_Modal}
                    onOk={() => this.handleRead()}
                    onCancel={() => this.get_cancelModal()}
                    okText="确认"
                    cancelText="取消"
                    wrapClassName='userModal'
                >
                    确定将流程标为已读吗?
                </Modal>

                <Modal
                    title="信息"
                    centered
                    visible={prompt_Modal}
                    onCancel={() => this.get_cancelModal()}
                    wrapClassName='userModal'
                    footer={[
                        <Button type="primary" onClick={() => this.get_userCommonList()}>确认</Button>
                    ]}
                >
                    {prompt}
                </Modal>

                <Modal
                    title="添加"
                    centered
                    visible={add_Modal}
                    wrapClassName='searchModal'
                    onOk={() => this.handleUserCommon()}
                    onCancel={() => this.get_cancelModal()}
                    okText="添加"
                    cancelText="返回"
                >
                    <div className='title'>添加常用功能</div>
                    <Checkbox.Group options={functionList} defaultValue={commonly} onChange={(e) => {
                        this.selectCheck(e)
                    }} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(Index)
