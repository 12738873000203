import React, { Component } from 'react'
import { Input, Form, message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createReceiptManagement, receiptManagementDetail, BlankingSelectProject, projectMaterial } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"
import { supplierSelect } from "../../../utils/purchase"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "收货管理",
            TableInfo: [
                {
                    FieldTitle: '收货单号', //字段标题
                    fieldName: 'waybill', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入收货单号', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '送货日期', //字段标题
                    fieldName: 'delivery_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择送货日期', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '收货类别', //字段标题
                    fieldName: 'class', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择收货类别', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            value: 1,
                            label: '材料'
                        },
                        {
                            value: 7,
                            label: '零星材料'
                        }
                    ]
                },
                {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '收货地址', //字段标题
                    fieldName: 'address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '选择供应商', //字段标题
                    fieldType: '按钮',  //字段类型
                }, {
                    FieldTitle: '供应商名称', //字段标题
                    fieldName: 'supplier', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '供应商电话', //字段标题
                    fieldName: 'sup_phone', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
            ],
            FirstInfo: [{
                plateName: '收货单详情',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操 作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '120px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            }
                        },
                        {
                            title: '名 称',
                            dataIndex: 'name',
                            key: 'name',
                            width: '120px',
                            render: (text, record, index) => {
                                return (
                                    <div title={text} style={{ width: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                )
                            }
                        },
                        {
                            title: '规格型号',
                            dataIndex: 'model',
                            key: 'model',
                            width: '150px',
                            render: (text, record, index) => {
                                return (
                                    <div title={text} style={{ width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                )
                            }
                        },
                        {
                            title: '计量单位',
                            dataIndex: 'unit',
                            key: 'unit',
                            width: '150px',
                        },
                        {
                            title: '送货数量',
                            dataIndex: 'pua_number',
                            key: 'pua_number',
                            width: '150px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'pua_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pua_number')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'pua_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pua_number')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }


                        },
                        {
                            title: '实收数量',
                            dataIndex: 'give_number',
                            key: 'give_number',
                            width: '150px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'give_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'give_number')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'give_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'give_number')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '产品质量',
                            dataIndex: 'keep_alive',
                            key: 'keep_alive',
                            width: '150px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'keep_alive' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'keep_alive')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'keep_alive' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'keep_alive')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '退货原因',
                            dataIndex: 'result',
                            key: 'result',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'result')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'result')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                    ]
                }
            }, {
                plateName: '',
                list: [{
                    FieldTitle: '货物产地', //字段标题
                    fieldName: 'origin', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同是否含运费', //字段标题
                    fieldName: 'is_freight', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择合同是否含运费', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "合同含运费" },
                        { value: 2, label: "合同不含运费" },
                    ]
                }, {
                    FieldTitle: '运费金额', //字段标题
                    fieldName: 'freight_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '运输车辆', //字段标题
                    fieldName: 'freight_ve', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '车牌证号', //字段标题
                    fieldName: 've_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '司机电话', //字段标题
                    fieldName: 'driver_phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '司机证件号码', //字段标题
                    fieldName: 'driver_card', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ]
            }],
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        username: '',
        de_name: '',
        material: '',
        pro_name: '',
        sup_class: '',
        sup_name: '',
        mat_class: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_receiptManagementDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_receiptManagementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        receiptManagementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.class = res.data.Detail.class
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }

                            if (item.FieldTitle === '选择供应商') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '名 称',
                                dataIndex: 'name',
                                key: 'name',
                                width: '120px',
                                render: (text, record, index) => {
                                    return (
                                        <div title={text} style={{ width: '100px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                    )
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                width: '150px',
                                render: (text, record, index) => {
                                    return (
                                        <div title={text} style={{ width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                    )
                                }
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                width: '150px',
                            },
                            {
                                title: '送货数量',
                                dataIndex: 'pua_number',
                                key: 'pua_number',
                                width: '150px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'pua_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pua_number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'pua_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pua_number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }


                            },
                            {
                                title: '实收数量',
                                dataIndex: 'give_number',
                                key: 'give_number',
                                width: '150px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'give_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'give_number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'give_number' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'give_number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '产品质量',
                                dataIndex: 'keep_alive',
                                key: 'keep_alive',
                                width: '150px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'keep_alive' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'keep_alive')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'keep_alive' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'keep_alive')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '退货原因',
                                dataIndex: 'result',
                                key: 'result',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'result')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'result')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.content.length) {
                        res.data.Detail.content.forEach((item, index) => {
                            let key = index + 1
                            item.key = key
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'pua_number' + key)()]: item.pua_number,
                                [(() => 'give_number' + key)()]: item.give_number,
                                [(() => 'keep_alive' + key)()]: item.keep_alive,
                                [(() => 'result' + key)()]: item.result,
                            })
                        })
                    }

                    Info.FirstInfo[0].editable.dataSource = res.data.Detail.content

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '供应商名称') {
                            item.value = res.data.Detail.sup_name
                        } else if (item.FieldTitle === '供应商电话') {
                            item.value = res.data.Detail.sup_phone
                        }
                    })

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        waybill: res.data.Detail.waybill,
                        class: res.data.Detail.class,
                        address: res.data.Detail.address,
                        origin: res.data.Detail.origin,
                        is_freight: res.data.Detail.is_freight,
                        freight_money: res.data.Detail.freight_money,
                        freight_ve: res.data.Detail.freight_ve,
                        ve_number: res.data.Detail.ve_number,
                        driver_phone: res.data.Detail.driver_phone,
                        driver_card: res.data.Detail.driver_card,
                        delivery_date: res.data.Detail.delivery_date ? moment(res.data.Detail.delivery_date, 'YYYY-MM-DD') : '',
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectMaterial = () => {
        let { form, dataSource, pages, material } = this.state
        if (!form.project_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            pro_id: form.project_id,
            class: form.class,
            material: material,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        projectMaterial(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.materialData.length) {
                        res.data.materialData.forEach((item, index) => {
                            let user = {
                                key: item.id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                cumulative: item.cumulative,
                                qualified: item.qualified,
                                unit_price: item.unit_price,
                                info: item.info,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierSelect = () => {
        let { dataSource, pages, sup_class, sup_name, mat_class } = this.state
        let data = {
            class: sup_class,
            sup_name: sup_name,
            mat_class: mat_class,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : '机械',
                                mat_class: item.matClass,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                contacts: item.contacts,
                                pay_model: item.payModel,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.waybill = data.waybill
        form.class = data.class
        form.delivery_date = data.delivery_date ? data.delivery_date.format('YYYY-MM-DD') : ''
        form.address = data.address
        form.origin = data.origin
        form.is_freight = data.is_freight
        form.freight_money = data.freight_money
        form.freight_ve = data.freight_ve
        form.ve_number = data.ve_number
        form.driver_phone = data.driver_phone
        form.driver_card = data.driver_card
        form.delivery_date = data.delivery_date ? data.delivery_date.format('YYYY-MM-DD') : ''
        form.content = JSON.stringify(Info.FirstInfo[0].editable.dataSource)

        createReceiptManagement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                this.get_BlankingSelectProject()
            } else if (title === '选择供应商') {
                columns = [
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '供应商类型',
                        dataIndex: 'class',
                        key: 'class',
                    },
                    {
                        title: '所属分类',
                        dataIndex: 'mat_class',
                        key: 'mat_class',
                    },
                    {
                        title: '电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '联系人',
                        dataIndex: 'contacts',
                        key: 'contacts',
                    },
                    {
                        title: '付款方式',
                        dataIndex: 'pay_model',
                        key: 'pay_model',
                    },
                    {
                        title: '建档人',
                        dataIndex: 'apply_user',
                        key: 'apply_user',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }, {
                        FieldTitle: '供应商类型', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                        selectList: [
                            {
                                label: '材料',
                                value: 1
                            },
                            {
                                label: '劳务',
                                value: 2
                            },
                            {
                                label: '机械',
                                value: 3
                            }
                        ]
                    }, {
                        FieldTitle: '所属分类', //字段标题
                        fieldName: 'mat_class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }
                ]
                this.get_supplierSelect()
            } else {
                columns = [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ]
                TableInfo = [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }]
                this.get_InitialValue()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio: true,
            })
            )

        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handleWorkAdd = (name) => {
        let { columns, TableInfo, form } = this.state
        if (!form.class) {
            message.error('请选择收货类别')
            return
        }
        columns = [
            {
                title: '名 称',
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '规格型号',
                dataIndex: 'model',
                key: 'model',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '计量单位',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: '备 注',
                dataIndex: 'info',
                key: 'info',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            }
        ]
        TableInfo = [
            {
                FieldTitle: '名称', //字段标题
                fieldName: 'material', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            }
        ]
        this.setState(preState => ({
            buttonTitle: '下料信息',
            columns,
            TableInfo,
            radio: false,
        }), () => {
            this.get_projectMaterial()
        })
    }

    InputChange = (e, id, title) => {
        let { Info } = this.state;
        console.log(title)
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.id === id) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                }
            })
        } else if (buttonTitle === '选择供应商') {
            form.sup_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '供应商名称') {
                    item.value = value[0].sup_name
                } else if (item.FieldTitle === '供应商电话') {
                    item.value = value[0].phone
                }
            })
        } else {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    value.forEach((em, ex) => {
                        if (em.key === item.id) {
                            value.splice(ex, 1)
                        }
                    })
                })

                value.forEach((item, index) => {
                    let key = Info.FirstInfo[0].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                        id: item.key,
                        name: item.name,
                        unit: item.unit,
                        model: item.model,
                        pua_number: '',
                        give_number: '',
                        keep_alive: '',
                        result: '',
                        total_time: '',
                        oil_con: ''
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            } else {
                value.forEach((item, index) => {
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource.length + 1,
                        id: item.key,
                        name: item.name,
                        unit: item.unit,
                        model: item.model,
                        pua_number: '',
                        give_number: '',
                        keep_alive: '',
                        result: '',
                        total_time: '',
                        oil_con: ''
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            }
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleRadio = (value) => {
        let { form, Info } = this.state;
        form.class = value
        Info.FirstInfo[0].editable.dataSource = []
        this.setState(preState => ({
            form,
            Info
        }))
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择供应商') {
            this.setState({
                sup_class: value.sup_class,
                sup_name: value.sup_name,
                mat_class: value.mat_class,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_supplierSelect()
            })
        } else {
            this.setState({
                material: value.material,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_projectMaterial()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            username: '',
            de_name: '',
            material: '',
            sup_class: '',
            sup_name: '',
            mat_class: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择供应商') {
                this.get_supplierSelect()
            } else {
                this.get_projectMaterial()
            }
        })
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'pua_number' + e)()]: '',
            [(() => 'give_number' + e)()]: '',
            [(() => 'keep_alive' + e)()]: '',
            [(() => 'result' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
