import React, { Component } from 'react'
import moment from 'moment';
import Big from 'big.js';
import { Form, Select, Input, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import amountInWords from '../../../../components/amountInWords'
import { createUserReimbursement, userReimbursementDetail } from "../../../../utils/public"
import { selectProjectName, reviewApplyResult } from "../../../../utils/api"


export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/expenseReimbursement.docx',
            templateName: '四川天艺生态园林集团股份有限公司费用报销单',
            tempData: {
                re_unit: '',
                class: '',
                apply_date: '',
                user_name: '',
                deName: '',
                proName: '',
                is_pro: '',
                eng_num: '',
                total_money: '',
                uppercase_money: '',
                info: '',
                details: [],
                results: []
            },
            FlowModelName: "费用报销审批",
            TableInfo: [{
                FieldTitle: '单据编号', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '(自动编号)',
                width: '50%'
            }, {
                FieldTitle: '报销人', //字段标题
                fieldName: 'user_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '提单日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择提单日期', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '业务部门', //字段标题
                fieldName: 'bus_de', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '报销单位', //字段标题
                fieldName: 're_unit', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择报销单位', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                    { value: 2, label: "四川天艺优境环境科技有限公司" },
                    { value: 3, label: "四川天艺致远物联网科技有限公司" },
                    { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                ]
            }, {
                FieldTitle: '是否为项目费用', //字段标题
                fieldName: 'is_pro', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择是否为项目费用', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 0, label: "否" }
                ]
            }, {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                isShow: true,
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'project_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
                isShow: true
            },
            {
                FieldTitle: '附件张数', //字段标题
                fieldName: 'eng_num', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入附件张数', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '本次核销借款(元)', //字段标题
                fieldName: 'loan_money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入本次核销借款(元)', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '本次实付金额(元)', //字段标题
                fieldName: 'amount_paid', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入本次实付金额(元)', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '金额合计(元)', //字段标题
                fieldName: 'total_money', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '前期借支余额(元)', //字段标题
                fieldName: 'debit_balance', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '费用类型', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择费用类型', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "综合费用" },
                    { value: 2, label: "科研费用" },
                    { value: 3, label: "项目费用" }
                ]
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [{
                plateName: '综合费用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        // {
                        //     title: '',
                        //     dataIndex: 'key',
                        //     key: 'key',
                        //     width: '100px'
                        // },
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, 0, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '综合费用类别',
                            dataIndex: 'expense_category',
                            key: 'expense_category',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '办公费用' },
                                                { value: 2, label: '差旅费用' },
                                                { value: 3, label: '业务费用' },
                                                { value: 4, label: '交通费用' },
                                                { value: 5, label: '通讯费用' },
                                                { value: 6, label: '其他费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '办公费用' },
                                                { value: 2, label: '差旅费用' },
                                                { value: 3, label: '业务费用' },
                                                { value: 4, label: '交通费用' },
                                                { value: 5, label: '通讯费用' },
                                                { value: 6, label: '其他费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '报销金额',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                            key: 'remark',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        }
                    ]
                },
                isShow: true
            },
            {
                plateName: '科研费用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        // {
                        //     title: '',
                        //     dataIndex: 'key',
                        //     key: 'key',
                        //     width: '100px'
                        // },
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, 1, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '科研费用类别',
                            dataIndex: 'expense_category',
                            key: 'expense_category',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '人员人工费用' },
                                                { value: 2, label: '直接投入费用' },
                                                { value: 3, label: '委托研发费用' },
                                                { value: 4, label: '研发基地费用' },
                                                { value: 5, label: '新产品设计费' },
                                                { value: 6, label: '其他相关费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '人员人工费用' },
                                                { value: 2, label: '直接投入费用' },
                                                { value: 3, label: '委托研发费用' },
                                                { value: 4, label: '研发基地费用' },
                                                { value: 5, label: '新产品设计费' },
                                                { value: 6, label: '其他相关费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '报销金额',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                            key: 'remark',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        }
                    ]
                },
                isShow: true
            },
            {
                plateName: '项目费用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, 2, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '项目费用类别',
                            dataIndex: 'expense_category',
                            key: 'expense_category',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '办公费用' },
                                                { value: 2, label: '差旅费用' },
                                                { value: 3, label: '业务费用' },
                                                { value: 4, label: '交通费用' },
                                                { value: 5, label: '餐饮费用' },
                                                { value: 6, label: '零星材料采购费用' },
                                                { value: 7, label: '其他费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '办公费用' },
                                                { value: 2, label: '差旅费用' },
                                                { value: 3, label: '业务费用' },
                                                { value: 4, label: '交通费用' },
                                                { value: 5, label: '餐饮费用' },
                                                { value: 6, label: '零星材料采购费用' },
                                                { value: 7, label: '其他费用' }
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '报销金额',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        }, {
                            title: '备注',
                            dataIndex: 'remark',
                            key: 'remark',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }

                        }
                    ]
                },
                isShow: true
            },
            {
                plateName: '',
                list: [{
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }]
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'managerName',
                key: 'managerName',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '95%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_sementDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info, form } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '报销人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '业务部门') {
                item.value = userInfo.deName
                form.bus_de = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    get_sementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        userReimbursementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                        form.bus_de = res.data.Detail.bus_de

                        form.loan_money = Number(res.data.Detail.loan_money)
                        form.amount_paid = Number(res.data.Detail.amount_paid)
                        form.total_money = Number(res.data.Detail.total_money)
                    } else {
                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '综合费用类别',
                                dataIndex: 'expense_category',
                                key: 'expense_category',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '办公费用' },
                                                    { value: 2, label: '差旅费用' },
                                                    { value: 3, label: '业务费用' },
                                                    { value: 4, label: '交通费用' },
                                                    { value: 5, label: '通讯费用' },
                                                    { value: 6, label: '其他费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '办公费用' },
                                                    { value: 2, label: '差旅费用' },
                                                    { value: 3, label: '业务费用' },
                                                    { value: 4, label: '交通费用' },
                                                    { value: 5, label: '通讯费用' },
                                                    { value: 6, label: '其他费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                    disabled
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '报销金额',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '备注',
                                dataIndex: 'remark',
                                key: 'remark',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }
                        ]

                        Info.FirstInfo[1].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '科研费用类别',
                                dataIndex: 'expense_category',
                                key: 'expense_category',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '人员人工费用' },
                                                    { value: 2, label: '直接投入费用' },
                                                    { value: 3, label: '委托研发费用' },
                                                    { value: 4, label: '研发基地费用' },
                                                    { value: 5, label: '新产品设计费' },
                                                    { value: 6, label: '其他相关费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '人员人工费用' },
                                                    { value: 2, label: '直接投入费用' },
                                                    { value: 3, label: '委托研发费用' },
                                                    { value: 4, label: '研发基地费用' },
                                                    { value: 5, label: '新产品设计费' },
                                                    { value: 6, label: '其他相关费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                    disabled
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '报销金额',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '备注',
                                dataIndex: 'remark',
                                key: 'remark',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }
                        ]

                        Info.FirstInfo[2].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '项目费用类别',
                                dataIndex: 'expense_category',
                                key: 'expense_category',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '办公费用' },
                                                    { value: 2, label: '差旅费用' },
                                                    { value: 3, label: '业务费用' },
                                                    { value: 4, label: '交通费用' },
                                                    { value: 5, label: '餐饮费用' },
                                                    { value: 6, label: '零星材料采购费用' },
                                                    { value: 7, label: '其他费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'expense_category' + record.key} key={index} initialValue={text}>
                                                <Select options={[
                                                    { value: 1, label: '办公费用' },
                                                    { value: 2, label: '差旅费用' },
                                                    { value: 3, label: '业务费用' },
                                                    { value: 4, label: '交通费用' },
                                                    { value: 5, label: '餐饮费用' },
                                                    { value: 6, label: '零星材料采购费用' },
                                                    { value: 7, label: '其他费用' }
                                                ]}
                                                    suffixIcon={<CaretDownOutlined />}
                                                    disabled
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '报销金额',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }, {
                                title: '备注',
                                dataIndex: 'remark',
                                key: 'remark',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'remark' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }
                        ]

                        if (res.data.Detail.status === 4) {
                            // let date = new Date() // 获取时间
                            // let year = date.getFullYear() // 获取年
                            // let month = date.getMonth() + 1 // 获取月
                            // let strDate = date.getDate() // 获取日
                            Info.tempData.class = res.data.Detail.class === 1 ? '综合费用' : res.data.Detail.class === 2 ? '科研费用' : '项目费用'
                            // Info.tempData.apply_date = year + '年' + month + '月' + strDate + '日'
                            Info.tempData.re_unit = res.data.Detail.re_unit === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.re_unit === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.re_unit === 3 ? '四川天艺致远物联网科技有限公司' : '四川天艺传奇生态茶业有限公司'
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.user_name = res.data.Detail.apply_userName
                            Info.tempData.deName = res.data.Detail.bus_de
                            Info.tempData.is_pro = res.data.Detail.is_pro === 1 ? '是' : '否'
                            Info.tempData.proName = res.data.Detail.is_pro === 1 ? res.data.Detail.projectName : '/'
                            Info.tempData.eng_num = res.data.Detail.eng_num
                            Info.tempData.total_money = res.data.Detail.total_money || 0
                            Info.tempData.uppercase_money = amountInWords(Number(res.data.Detail.total_money))
                            Info.tempData.info = res.data.Detail.info || '/'
                            Info.tempData.details = []
                            Info.tempData.results = []

                            let details = JSON.parse(res.data.Detail.details)
                            details.forEach((item, index) => {
                                let expense_category = ''
                                if (res.data.Detail.class === 1) {
                                    Number(item.expense_category) === 1 ? expense_category = '办公费用' : Number(item.expense_category) === 2 ? expense_category = '差旅费用' : Number(item.expense_category) === 3 ? expense_category = '业务费用' : Number(item.expense_category) === 4 ? expense_category = '交通费用' : Number(item.expense_category) === 5 ? expense_category = '通讯费用' : expense_category = '其他费用'
                                } else if (res.data.Detail.class === 2) {
                                    Number(item.expense_category) === 1 ? expense_category = '人员人工费用' : Number(item.expense_category) === 2 ? expense_category = '直接投入费用' : Number(item.expense_category) === 3 ? expense_category = '委托研发费用' : Number(item.expense_category) === 4 ? expense_category = '研发基地费用' : Number(item.expense_category) === 5 ? expense_category = '新产品设计费' : expense_category = '其他相关费用'
                                } else if (res.data.Detail.class === 3) {
                                    Number(item.expense_category) === 1 ? expense_category = '办公费用' : Number(item.expense_category) === 2 ? expense_category = '差旅费用' : Number(item.expense_category) === 3 ? expense_category = '业务费用' : Number(item.expense_category) === 4 ? expense_category = '交通费用' : Number(item.expense_category) === 5 ? expense_category = '餐饮费用' : Number(item.expense_category) === 6 ? expense_category = '零星材料采购费用' : expense_category = '其他费用'
                                }
                                let value = {
                                    expense_category: expense_category,
                                    amount: item.amount,
                                    remark: item.remark
                                };
                                Info.tempData.details = [...Info.tempData.details, value]
                            })

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[3].list.forEach((item, index) => {
                            item.disabled = true
                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '报销人') {
                            item.value = res.data.Detail.apply_userName
                        } else if (item.FieldTitle === '业务部门') {
                            item.value = res.data.Detail.bus_de
                        } else if (item.FieldTitle === '单据编号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '金额合计(元)') {
                            item.value = Number(res.data.Detail.total_money)
                        }
                    })

                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    if (res.data.Detail.is_pro === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '选择项目') {
                                item.isShow = false
                            } else if (item.FieldTitle === '项目名称') {
                                item.isShow = false
                                form.project_id = res.data.Detail.project_id
                                item.value = res.data.Detail.projectName
                            }
                        })
                    }

                    if (res.data.Detail.class === 1) {
                        Info.FirstInfo[0].isShow = false
                        Info.FirstInfo[0].editable.dataSource = []
                        Info.FirstInfo[1].isShow = true
                        Info.FirstInfo[1].editable.dataSource = []
                        Info.FirstInfo[2].isShow = true
                        Info.FirstInfo[2].editable.dataSource = []
                        let details = JSON.parse(res.data.Detail.details)
                        details.forEach((item, index) => {
                            let key = Info.FirstInfo[0].editable.dataSource.length + 1
                            const newData = {
                                key: key,
                                expense_category: Number(item.expense_category),
                                amount: item.amount,
                                remark: item.remark
                            };
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'expense_category' + key)()]: Number(item.expense_category),
                                [(() => 'amount' + key)()]: item.amount,
                                [(() => 'remark' + key)()]: item.remark,
                            })
                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    } else if (res.data.Detail.class === 2) {
                        Info.FirstInfo[0].isShow = true
                        Info.FirstInfo[0].editable.dataSource = []
                        Info.FirstInfo[1].isShow = false
                        Info.FirstInfo[1].editable.dataSource = []
                        Info.FirstInfo[2].isShow = true
                        Info.FirstInfo[2].editable.dataSource = []
                        let details = JSON.parse(res.data.Detail.details)
                        details.forEach((item, index) => {
                            let key = Info.FirstInfo[1].editable.dataSource.length + 1
                            const newData = {
                                key: key,
                                expense_category: Number(item.expense_category),
                                amount: item.amount,
                                remark: item.remark
                            };
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'expense_category' + key)()]: Number(item.expense_category),
                                [(() => 'amount' + key)()]: item.amount,
                                [(() => 'remark' + key)()]: item.remark,
                            })
                            Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                        })
                    } else if (res.data.Detail.class === 3) {
                        Info.FirstInfo[0].isShow = true
                        Info.FirstInfo[0].editable.dataSource = []
                        Info.FirstInfo[1].isShow = true
                        Info.FirstInfo[1].editable.dataSource = []
                        Info.FirstInfo[2].isShow = false
                        Info.FirstInfo[2].editable.dataSource = []
                        let details = JSON.parse(res.data.Detail.details)
                        details.forEach((item, index) => {
                            let key = Info.FirstInfo[2].editable.dataSource.length + 1
                            const newData = {
                                key: key,
                                expense_category: Number(item.expense_category),
                                amount: item.amount,
                                remark: item.remark
                            };
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'expense_category' + key)()]: Number(item.expense_category),
                                [(() => 'amount' + key)()]: item.amount,
                                [(() => 'remark' + key)()]: item.remark,
                            })
                            Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        bus_de: res.data.Detail.bus_de,
                        re_unit: res.data.Detail.re_unit,
                        is_pro: res.data.Detail.is_pro,
                        eng_num: res.data.Detail.eng_num,
                        loan_money: Number(res.data.Detail.loan_money),
                        amount_paid: Number(res.data.Detail.amount_paid),
                        // total_money: res.data.Detail.total_money,
                        debit_balance: res.data.Detail.debit_balance,
                        class: res.data.Detail.class,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.re_unit = data.re_unit
        form.is_pro = data.is_pro
        form.eng_num = data.eng_num
        form.loan_money = data.loan_money
        form.amount_paid = data.amount_paid
        // form.total_money = data.total_money
        form.debit_balance = data.debit_balance
        form.class = data.class
        form.info = data.info
        form.details = []

        let total_money = 0

        if (data.class === 1) {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    let value = {
                        expense_category: data['expense_category' + item.key] ? data['expense_category' + item.key] : '',
                        amount: data['amount' + item.key] ? data['amount' + item.key] : '',
                        remark: data['remark' + item.key] ? data['remark' + item.key] : ''
                    }

                    if (value.amount) {
                        total_money = Big(Number(value.amount)).plus(Number(total_money)).toFixed(2);
                    }

                    form.details = [...form.details, value]
                })
            }
        } else if (data.class === 2) {
            if (Info.FirstInfo[1].editable.dataSource.length) {
                Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                    let value = {
                        expense_category: data['expense_category' + item.key] ? data['expense_category' + item.key] : '',
                        amount: data['amount' + item.key] ? data['amount' + item.key] : '',
                        remark: data['remark' + item.key] ? data['remark' + item.key] : ''
                    }

                    if (value.amount) {
                        total_money = Big(Number(value.amount)).plus(Number(total_money)).toFixed(2);
                    }

                    form.details = [...form.details, value]
                })
            }
        } else if (data.class === 3) {
            if (Info.FirstInfo[2].editable.dataSource.length) {
                Info.FirstInfo[2].editable.dataSource.forEach((item, index) => {
                    let value = {
                        expense_category: data['expense_category' + item.key] ? data['expense_category' + item.key] : '',
                        amount: data['amount' + item.key] ? data['amount' + item.key] : '',
                        remark: data['remark' + item.key] ? data['remark' + item.key] : ''
                    }

                    if (value.amount) {
                        total_money = Big(Number(value.amount)).plus(Number(total_money)).toFixed(2);
                    }

                    form.details = [...form.details, value]
                })
            }
        }

        console.log(form.total_money, total_money)

        if (Number(form.total_money) !== Number(total_money) && status === 2) {
            message.error('报销金额与金额合计不一致!')
            return
        }

        form.details = JSON.stringify(form.details)

        createUserReimbursement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;


        if (name === '综合费用明细') {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                if (Info.FirstInfo[0].editable.dataSource.length < 5) {
                    let key = Info.FirstInfo[0].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                        expense_category: '',
                        amount: ''
                    };
                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                    Info.FirstInfo[1].editable.dataSource = []
                    Info.FirstInfo[2].editable.dataSource = []
                }
            } else {
                const newData = {
                    key: Info.FirstInfo[0].editable.dataSource.length + 1,
                    expense_category: '',
                    amount: ''
                };
                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                Info.FirstInfo[1].editable.dataSource = []
                Info.FirstInfo[2].editable.dataSource = []
            }
        } else if (name === '科研费用明细') {
            if (Info.FirstInfo[1].editable.dataSource.length) {
                if (Info.FirstInfo[1].editable.dataSource.length < 5) {
                    let key = Info.FirstInfo[1].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[1].editable.dataSource[key].key + 1,
                        expense_category: '',
                        amount: ''
                    };
                    Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                    Info.FirstInfo[0].editable.dataSource = []
                    Info.FirstInfo[2].editable.dataSource = []
                }
            } else {
                const newData = {
                    key: Info.FirstInfo[1].editable.dataSource.length + 1,
                    expense_category: '',
                    amount: ''
                };
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                Info.FirstInfo[0].editable.dataSource = []
                Info.FirstInfo[2].editable.dataSource = []
            }
        } else if (name === '项目费用明细') {
            if (Info.FirstInfo[2].editable.dataSource.length) {
                if (Info.FirstInfo[2].editable.dataSource.length < 5) {
                    let key = Info.FirstInfo[2].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[2].editable.dataSource[key].key + 1,
                        expense_category: '',
                        amount: ''
                    };
                    Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
                    Info.FirstInfo[0].editable.dataSource = []
                    Info.FirstInfo[1].editable.dataSource = []
                }
            } else {
                const newData = {
                    key: Info.FirstInfo[2].editable.dataSource.length + 1,
                    expense_category: '',
                    amount: ''
                };
                Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
                Info.FirstInfo[0].editable.dataSource = []
                Info.FirstInfo[1].editable.dataSource = []
            }
        }

        this.setState(preState => ({
            Info
        })
        )
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleRadio = (value) => {
        let { Info, form } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择项目') {
                    item.isShow = false
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = false
                }
            })
        } else {
            form.project_id = ''
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择项目') {
                    item.isShow = true
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 1) {
            Info.FirstInfo[0].isShow = false
            Info.FirstInfo[1].isShow = true
            Info.FirstInfo[2].isShow = true
        } else if (value === 2) {
            Info.FirstInfo[0].isShow = true
            Info.FirstInfo[1].isShow = false
            Info.FirstInfo[2].isShow = true
        } else if (value === 3) {
            Info.FirstInfo[0].isShow = true
            Info.FirstInfo[1].isShow = true
            Info.FirstInfo[2].isShow = false
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[3].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    handleDel = (key, state, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'expense_category' + e)()]: '',
            [(() => 'amount' + e)()]: '',
            [(() => 'remark' + e)()]: '',
        })
        Info.FirstInfo[state].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[state].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    handleInput = (value, name) => {
        let { form, Info } = this.state
        if (name === '本次核销借款(元)') {
            form.loan_money = value

            if (form.amount_paid || form.amount_paid === 0) {
                form.total_money = Big(Number(form.loan_money)).plus(Number(form.amount_paid)).toFixed(2);
                // form.total_money = Number(form.loan_money) + Number(form.amount_paid)
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '金额合计(元)') {
                        item.value = form.total_money
                    }
                })
            }
        } else if (name === '本次实付金额(元)') {
            form.amount_paid = value

            if (form.loan_money || form.loan_money === 0) {
                form.total_money = Big(Number(form.loan_money)).plus(Number(form.amount_paid)).toFixed(2);
                // form.total_money = Number(form.loan_money) + Number(form.amount_paid)
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '金额合计(元)') {
                        item.value = form.total_money
                    }
                })
            }
        }

        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} rowAddEvent={this.handleWorkAdd} fileEvent={this.handFile} radioEvent={this.handleRadio} selectorEvent={this.handSelector} auditEvent={this.handleButton} InputEvent={this.handleInput} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
