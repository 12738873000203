import React, { Component } from 'react'
import { Tree } from 'antd';
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { ResTemporaryList, researchProjectMain } from "../../../../utils/scientific_research"
import Folder from "../../../../accets/img/folder.png"
import Folders from "../../../../accets/img/folders.png"
import File from "../../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '申请人',
                    dataIndex: 'apply_username',
                    key: 'apply_username',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '申请日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '合计金额',
                    dataIndex: 'money',
                    key: 'money',
                },
                {
                    title: '统计月份',
                    dataIndex: 'month',
                    key: 'month',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pro_id: '',
            apply_user: '',
            class_type: 1,
            TableInfo: [
                {
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            proList: []
        }
    }

    componentDidMount() {
        let { class_type } = this.state
        if (class_type === 1) {
            this.get_researchProjectMain()
        } else {
            this.get_ResTemporaryList()
        }
    }

    get_researchProjectMain = () => {
        let { treeData, pro_id, proList, selectedKeys, expandedKeys, class_type } = this.state
        researchProjectMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    proList = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            proList = [...proList, ...item.project[key]]
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                        proList
                    }, () => {
                        this.get_ResTemporaryList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ResTemporaryList = () => {
        let { dataSource, pages, process_state, pro_id, apply_user, class_type } = this.state
        let data = {
            project_id: pro_id,
            class: class_type,
            apply_user: apply_user,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        ResTemporaryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.temporaries.length) {
                        res.data.temporaries.forEach((item, index) => {
                            if (class_type === 1) {
                                let content = JSON.parse(item.content)
                                let money = 0
                                content.forEach((em, ex) => {
                                    money = money + Number(em.wage)
                                })
                                let value = {
                                    key: index + 1,
                                    table_id: item.table_id,
                                    apply_username: item.applyUsername,
                                    created_at: item.apply_day,
                                    money: money,
                                    month: item.month,
                                    status: item.status,
                                }
                                dataSource = [...dataSource, value]
                            } else {
                                let content = JSON.parse(item.content)
                                let money = 0
                                content.forEach((em, ex) => {
                                    money = money + Number(em.wage)
                                })
                                let value = {
                                    key: index + 1,
                                    table_id: item.table_id,
                                    apply_day: item.apply_day,
                                    username: item.applyUsername,
                                    nur_name:item.nurName,
                                    money: money,
                                    month: item.month,
                                    status: item.status,
                                }
                                dataSource = [...dataSource, value]
                            }
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            total: res.data.pages.total,
                            limit: res.data.pages.limit
                        }
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/temporaryWorker/NewItem'
        let CachingKeys = getCachingKeys()
        let { pro_id, proList, class_type } = this.state
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        localStorage.setItem('itemForm', '')
        setTimeout(() => {
            if (class_type === 1) {
                proList.forEach((item, index) => {
                    if (item.project_id === pro_id) {
                        localStorage.setItem('itemForm', JSON.stringify(item))
                    }
                })
            } else {
                localStorage.setItem('itemForm', '')
            }
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/temporaryWorker/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            apply_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_ResTemporaryList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            apply_user: value.apply_user,
        }, () => {
            this.get_ResTemporaryList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ResTemporaryList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_ResTemporaryList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
            apply_user: '',
        }), () => {
            this.get_ResTemporaryList()
        })
    }

    onExpand = (value) => {
        console.log(value)
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    get_tabIndex = (index) => {
        let { columns, TableInfo } = this.state
        if (index === 1) {
            columns = [
                {
                    title: '申请人',
                    dataIndex: 'apply_username',
                    key: 'apply_username',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '申请日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '合计金额',
                    dataIndex: 'money',
                    key: 'money',
                },
                {
                    title: '统计月份',
                    dataIndex: 'month',
                    key: 'month',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
        } else {
            columns = [
                {
                    title: '申请时间',
                    dataIndex: 'apply_day',
                    key: 'apply_day',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '申请人',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '苗圃名称',
                    dataIndex: 'nur_name',
                    key: 'nur_name',
                },
                {
                    title: '合计金额',
                    dataIndex: 'money',
                    key: 'money',
                },
                {
                    title: '统计月份',
                    dataIndex: 'month',
                    key: 'month',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
        }
        this.setState(preState => ({
            class_type: index,
            columns,
            pro_id: '',
            dataSource: [],
            TableInfo,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }), () => {
            if (index === 1) {
                this.get_researchProjectMain()
            } else {
                this.get_ResTemporaryList()
            }
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, pages, treeData, expandedKeys, selectedKeys, class_type } = this.state
        return (

            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                    </div>

                    <div className='supplier_right employeesType'>
                        <div className={class_type === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>项目类</div>
                        <div className={class_type === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>科研类</div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    class_type === 1 ? <div className='contract_List'>
                        <div className='menuList'>
                            <Tree
                                showLine={{ showLeafIcon: false }}
                                showIcon={true}
                                expandedKeys={expandedKeys}
                                selectedKeys={selectedKeys}
                                defaultExpandParent={true}
                                treeData={treeData}
                                onSelect={(value) => {
                                    this.onSelect(value)
                                }}
                                onExpand={(value) => {
                                    this.onExpand(value)
                                }}
                            />
                        </div>

                        <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />

                    </div> : <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />
                }


            </div>

        )
    }
}

export default withRouter(List)
