import http from '../utils/http';

//查询审批立项完成项目
function BlankingSelectProject(data) {
    return http("get", '/api/BlankingSelectProject', data);
}

//保存下料单
function saveMaterialBill(data) {
    return http("post", '/api/saveMaterialBill', data);
}

//人材机下料单列表
function blankingSheetList(data) {
    return http("get", '/api/blankingSheetList', data);
}

//下料单详情
function materialDetail(data) {
    return http("get", '/api/materialDetail', data);
}

//项目人材机查询
function projectMaterial(data) {
    return http("get", '/api/projectMaterial', data);
}

//对比累积到货合格数量
function blankingMaterialSelect(data) {
    return http("get", '/api/blankingMaterialSelect', data);
}

//添加编辑零星用工
function createSporadic(data) {
    return http("post", '/api/createSporadic', data);
}

//零星用工列表
function sporadicList(data) {
    return http("get", '/api/sporadicList', data);
}

//零星用工详情
function sporadicDetail(data) {
    return http("get", '/api/sporadicDetail', data);
}

//添加编辑收货管理
function createReceiptManagement(data) {
    return http("post", '/api/createReceiptManagement', data);
}

//收货管理详情
function receiptManagementDetail(data) {
    return http("get", '/api/receiptManagementDetail', data);
}

//收货管理列表
function receiptManagementList(data) {
    return http("get", '/api/receiptManagementList', data);
}

//查询人材机合同审批
function selectMachineryList(data) {
    return http("get", '/api/selectMachineryList', data);
}

//添加编辑人材机产值申报
function createOutputDeclaration(data) {
    return http("post", '/api/createOutputDeclaration', data);
}

//收货管理列表
function outputDeclarationList(data) {
    return http("get", '/api/outputDeclarationList', data);
}

//人材机产值申报详情
function outputDeclarationDetail(data) {
    return http("get", '/api/outputDeclarationDetail', data);
}

//添加编辑文件夹
function EngCreateFileManager(data) {
    return http("post", '/api/EngCreateFileManager', data);
}

//添加文件夹文件
function EngAddFileUpload(data) {
    return http("post", '/api/EngAddFileUpload', data);
}

//集采文件管理列表
function EngFileList(data) {
    return http("get", '/api/EngFileList', data);
}

//删除文件
function EngDeleteFile(data) {
    return http("post", '/api/EngDeleteFile', data);
}

//删除文件夹
function EngDeleteFolder(data) {
    return http("post", '/api/EngDeleteFolder', data);
}

//添加人材机总计划表
function createMasterPlan(data) {
    return http("post", '/api/createMasterPlan', data);
}

//人材机总计划表列表
function MasterPlanList(data) {
    return http("get", '/api/MasterPlanList', data);
}

//关联认价审批查询
function aboutSupConfirmSelect(data) {
    return http("get", '/api/aboutSupConfirmSelect', data);
}

//添加编辑人材机合同审批
function createMachinery(data) {
    return http("post", '/api/createMachinery', data);
}

//人材机合同审批辅助数据
function machineryAuxiliary(data) {
    return http("get", '/api/machineryAuxiliary', data);
}

//人材机合同审批列表
function machineryList(data) {
    return http("get", '/api/machineryList', data);
}

//人材机合同审批主合同查询
function machineryMainContractQuery(data) {
    return http("get", '/api/machineryMainContractQuery', data);
}

//人材机合同审批详情
function machineryDetail(data) {
    return http("get", '/api/machineryDetail', data);
}

//添加编辑进度结算审批
function createProgressSettlement(data) {
    return http("post", '/api/createProgressSettlement', data);
}

//进度款计算审批列表
function ProgressSettlementList(data) {
    return http("get", '/api/ProgressSettlementList', data);
}

//进度款计算审批详情
function progressSettlementDetail(data) {
    return http("get", '/api/progressSettlementDetail', data);
}

//添加编辑结算审批
function createSettlementApproval(data) {
    return http("post", '/api/createSettlementApproval', data);
}

//结算审批列表
function SettlementApprovalList(data) {
    return http("get", '/api/SettlementApprovalList', data);
}

//结算审批详情
function SettlementApprovalDetail(data) {
    return http("get", '/api/SettlementApprovalDetail', data);
}

//查询关联人材机合同审批
function aboutMachineryContract(data) {
    return http("get", '/api/aboutMachineryContract', data);
}

//材料进度结算查询
function aboutProgressSettlements(data) {
    return http("get", '/api/aboutProgressSettlements', data);
}

//关联结算审批单据
function aboutSettlementApproval(data) {
    return http("get", '/api/aboutSettlementApproval', data);
}

//添加编辑人材机付款审批
function createMaterialPayment(data) {
    return http("post", '/api/createMaterialPayment', data);
}

//人材机付款审批列表
function MaterialPaymentList(data) {
    return http("get", '/api/MaterialPaymentList', data);
}

//人材机付款审批详情
function MaterialPaymentDetail(data) {
    return http("get", '/api/MaterialPaymentDetail', data);
}

//开票申请列表
function InvoiceApplicationList(data) {
    return http("get", '/api/InvoiceApplicationList', data);
}

//添加编辑开票申请
function createInvoiceApplication(data) {
    return http("post", '/api/createInvoiceApplication', data);
}

//申请开票详情
function InvoiceApplicationDetail(data) {
    return http("get", '/api/InvoiceApplicationDetail', data);
}

//施工日志列表
function ConstructionLogList(data) {
    return http("get", '/api/ConstructionLogList', data);
}

//施工日志详情
function ConstructionLogDetail(data) {
    return http("get", '/api/ConstructionLogDetail', data);
}

//施工日志列表
function createConstructionLog(data) {
    return http("post", '/api/createConstructionLog', data);
}

//施工日志列表
function ConstructionLogSummary(data) {
    return http("get", '/api/ConstructionLogSummary', data);
}

//零星采购列表
function SporadicMaterialList(data) {
    return http("get", '/api/SporadicMaterialList', data);
}

//添加编辑零星材料购买
function createSporadicMaterial(data) {
    return http("post", '/api/createSporadicMaterial', data);
}

//零星采购详情
function SporadicMaterialDetail(data) {
    return http("get", '/api/SporadicMaterialDetail', data);
}

//导入材料清单返回数据
function SporadicMaterialImportTable(data) {
    return http("post", '/api/SporadicMaterialImportTable', data);
}

//零星采购集采修改材料价格
function addMaterialPrice(data) {
    return http("post", '/api/addMaterialPrice', data);
}

//添加合作项目用户账户
function createOperationUser(data) {
    return http("post", '/api/createOperationUser', data);
}

//合作项目用户账户列表
function operationUserList(data) {
    return http("get", '/api/operationUserList', data);
}

//合作项目用户账户详情
function operationUserDetail(data) {
    return http("get", '/api/operationUserDetail', data);
}

//编辑账号状态
function switchOperationUser(data) {
    return http("post", '/api/switchOperationUser', data);
}

//查看用户连接
function selectUserUrl(data) {
    return http("get", '/api/selectUserUrl', data);
}

//项目支付申请列表
function JointPaymentList(data) {
    return http("get", '/api/JointPaymentList', data);
}

//项目支付申请详情
function JointPaymentDetail(data) {
    return http("get", '/api/JointPaymentDetail', data);
}

//编辑合作项目付款信息
function paymentInformation(data) {
    return http("post", '/api/paymentInformation', data);
}

//添加编辑资料移交
function createDataTransfer(data) {
    return http("post", '/api/createDataTransfer', data);
}

//资料移交列表
function DataTransferList(data) {
    return http("get", '/api/DataTransferList', data);
}

//资料移交详情
function DataTransferDetail(data) {
    return http("get", '/api/DataTransferDetail', data);
}

//累计已开票金额(元
function TransferAccThisMonthOutput(data) {
    return http("get", '/api/TransferAccThisMonthOutput', data);
}

//项目责任书辅助数据
function auxiliaryResponsibilities(data) {
    return http("get", '/api/auxiliaryResponsibilities', data);
}

//添加编辑项目责任书信息
function createdProjectResponsibilities(data) {
    return http("post", '/api/createdProjectResponsibilities', data);
}

//获取项目责任书信息列表
function ProjectResponsibilitiesList(data) {
    return http("get", '/api/ProjectResponsibilitiesList', data);
}

//获取项目责任书信息详情
function ProjectResponsibilitiesDetail(data) {
    return http("get", '/api/ProjectResponsibilitiesDetail', data);
}

//項目责任书信息查询
function SelectResponsibilities(data) {
    return http("get", '/api/SelectResponsibilities', data);
}

//添加编辑项目责任书评审
function createResponsibilityReview(data) {
    return http("post", '/api/createResponsibilityReview', data);
}

//项目责任书审评列表
function responsibilityReviewList(data) {
    return http("get", '/api/responsibilityReviewList', data);
}

//项目责任书审评详情
function responsibilityReviewDetail(data) {
    return http("get", '/api/responsibilityReviewDetail', data);
}

//添加编辑通知文件审批
function createNotificationDocument(data) {
    return http("post", '/api/createNotificationDocument', data);
}

//通知文件审批列表
function NotificationDocumentList(data) {
    return http("get", '/api/NotificationDocumentList', data);
}

//通知文件审批详情
function NotificationDocumentDetail(data) {
    return http("get", '/api/NotificationDocumentDetail', data);
}

//添加编辑收文管理
function createReceiveFile(data) {
    return http("post", '/api/createReceiveFile', data);
}

//收文管理列表
function ReceiveFileList(data) {
    return http("get", '/api/ReceiveFileList', data);
}

//收文管理详情
function ReceiveFileDetail(data) {
    return http("get", '/api/ReceiveFileDetail', data);
}

//添加编辑函件审批
function createApprovalCorrespondence(data) {
    return http("post", '/api/createApprovalCorrespondence', data);
}

//函件审批列表
function ApprovalCorrespondenceList(data) {
    return http("get", '/api/ApprovalCorrespondenceList', data);
}

//函件审批详情
function ApprovalCorrespondenceDetail(data) {
    return http("get", '/api/ApprovalCorrespondenceDetail', data);
}

//添加编辑工程部设计项目申请
function createDesignEngProject(data) {
    return http("post", '/api/createDesignEngProject', data);
}

//工程部设计项目申请列表
function DesignEngProjectList(data) {
    return http("get", '/api/DesignEngProjectList', data);
}

//工程部设计项目申请详情
function DesignEngProjectDetail(data) {
    return http("get", '/api/DesignEngProjectDetail', data);
}

//工程资料文件夹内容查询
function folderBuildingContentGet(data) {
    return http("get", '/api/folderBuildingContentGet', data);
}

//添加编辑工程资料文件夹
function createBuildingDocument(data) {
    return http("post", '/api/createBuildingDocument', data);
}

//工程资料云归档文件保存
function documentUploadFile(data) {
    return http("post", '/api/documentUploadFile', data);
}

//过程资料文件列表
function processDocumentList(data) {
    return http("get", '/api/processDocumentList', data);
}

//获取过程资料详情
function processDocumentDetail(data) {
    return http("get", '/api/processDocumentDetail', data);
}

//过程资料上传
function processDocumentUpload(data) {
    return http("post", '/api/processDocumentUpload', data);
}

//过程资料上传撤回操作
function processRecallOperation(data) {
    return http("post", '/api/processRecallOperation', data);
}

//管理外出签到列表
function allUserSignList(data) {
    return http("get", '/api/allUserSignList', data);
}

export {
    BlankingSelectProject,
    saveMaterialBill,
    blankingSheetList,
    materialDetail,
    projectMaterial,
    blankingMaterialSelect,
    createSporadic,
    sporadicList,
    sporadicDetail,
    createReceiptManagement,
    receiptManagementDetail,
    receiptManagementList,
    selectMachineryList,
    createOutputDeclaration,
    outputDeclarationList,
    outputDeclarationDetail,
    EngCreateFileManager,
    EngAddFileUpload,
    EngFileList,
    EngDeleteFile,
    EngDeleteFolder,
    createMasterPlan,
    MasterPlanList,
    aboutSupConfirmSelect,
    createMachinery,
    machineryAuxiliary,
    machineryList,
    machineryMainContractQuery,
    machineryDetail,
    createProgressSettlement,
    ProgressSettlementList,
    progressSettlementDetail,
    createSettlementApproval,
    SettlementApprovalList,
    SettlementApprovalDetail,
    aboutMachineryContract,
    aboutProgressSettlements,
    aboutSettlementApproval,
    createMaterialPayment,
    MaterialPaymentList,
    MaterialPaymentDetail,
    InvoiceApplicationList,
    createInvoiceApplication,
    InvoiceApplicationDetail,
    ConstructionLogList,
    ConstructionLogDetail,
    createConstructionLog,
    ConstructionLogSummary,
    createSporadicMaterial,
    SporadicMaterialImportTable,
    SporadicMaterialList,
    SporadicMaterialDetail,
    addMaterialPrice,
    createOperationUser,
    operationUserList,
    operationUserDetail,
    switchOperationUser,
    selectUserUrl,
    JointPaymentList,
    JointPaymentDetail,
    paymentInformation,
    createDataTransfer,
    DataTransferList,
    DataTransferDetail,
    TransferAccThisMonthOutput,
    auxiliaryResponsibilities,
    createdProjectResponsibilities,
    ProjectResponsibilitiesList,
    ProjectResponsibilitiesDetail,
    SelectResponsibilities,
    createResponsibilityReview,
    responsibilityReviewList,
    responsibilityReviewDetail,
    createNotificationDocument,
    NotificationDocumentList,
    NotificationDocumentDetail,
    createReceiveFile,
    ReceiveFileList,
    ReceiveFileDetail,
    createApprovalCorrespondence,
    ApprovalCorrespondenceList,
    ApprovalCorrespondenceDetail,
    createDesignEngProject,
    DesignEngProjectList,
    DesignEngProjectDetail,
    folderBuildingContentGet,
    createBuildingDocument,
    documentUploadFile,
    processDocumentList,
    processDocumentDetail,
    processDocumentUpload,
    processRecallOperation,
    allUserSignList
}
