import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { reviewApplyResult } from "../../../utils/api"
import { selectAvailableSeal } from "../../../utils/public"
import { supplierSelect } from "../../../utils/purchase"
import { BlankingSelectProject, createMachinery, machineryDetail, machineryMainContractQuery, aboutSupConfirmSelect } from "../../../utils/project"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/contractApprove.docx',
                templateName: '四川天艺生态园林集团股份有限公司合同评审表',
                tempData: {
                    conType: '材料采购',
                    conNumber: '',
                    apply_date: '',
                    pro_name: '',
                    conName: '',
                    conMoney: '',
                    conFirst: '',
                    proName: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "人材机合同审批",
                TableInfo: [{
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择申请日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'con_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同编号', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '是否内部', //字段标题
                    fieldName: 'is_inside', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                },
                {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                },
                {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                },
                {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    isShow: true,
                    width: '100%',
                },
                ],
                FirstInfo: [{
                    plateName: '合同所属项目',
                    list: [
                        {
                            FieldTitle: '选择项目', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '项目经理', //字段标题
                            fieldName: 'pro_manager', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目编号', //字段标题
                            fieldName: 'pro_number', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '合同类别', //字段标题
                            fieldName: 'mac_type', //字段名称
                            fieldType: '单选',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择合同类别', //必填要求
                            value: '',
                            width: '50%',
                            radioList: [
                                { value: 1, label: "材料" },
                                { value: 2, label: "劳务" },
                                { value: 3, label: "机械" },
                                { value: 4, label: "设计" },
                                { value: 7, label: "零星材料" },
                                { value: 8, label: "双包" },
                            ]
                        },
                        {
                            FieldTitle: '关联供应商认价审批单据', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '供应商认价审批单据', //字段标题
                            fieldName: 'price_approval_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        }, {
                            FieldTitle: '收支类别', //字段标题
                            fieldName: 'pay_type', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择收支类别', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "支出合同" },
                                { value: 2, label: "收入合同" },
                            ]
                        }, {
                            FieldTitle: '合同结算方式', //字段标题
                            fieldName: 'set_model', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择合同计算方式', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "单价合同" },
                                { value: 2, label: "总价合同" },
                            ]
                        },
                        {
                            FieldTitle: '合同名称', //字段标题
                            fieldName: 'con_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入合同名称', //必填要求
                            value: '',
                            width: '50%'
                        }, {
                            FieldTitle: '合同性质', //字段标题
                            fieldName: 'con_nature', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择合同性质', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "主合同" },
                                { value: 2, label: "补充合同" },
                            ]
                        },
                    ],
                }, {
                    plateName: '合同信息',
                    list: [
                        {
                            FieldTitle: '选择主合同', //字段标题
                            fieldType: '按钮',  //字段类型
                            isShow: true,
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '主合同编号', //字段标题
                            fieldName: 'main_number', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        },
                        {
                            FieldTitle: '主合同名称', //字段标题
                            fieldName: 'main_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        },
                        {
                            FieldTitle: '合同版本类别', //字段标题
                            fieldName: 'version_type', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择合同版本类别', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "标准版本" },
                                { value: 2, label: "定制版本" },
                            ]
                        },
                        {
                            FieldTitle: '合同甲方', //字段标题
                            fieldName: 'con_first', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '清选择合同甲方', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                                { value: 2, label: "四川天艺优境环境科技有限公司" },
                                { value: 3, label: "四川创意致远建筑工程有限公司" },
                                { value: 4, label: "四川天艺致远物联网科技有限公司" },
                            ]
                        },
                        {
                            FieldTitle: '关联供应商', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '供应商名称', //字段标题
                            fieldName: 'sup_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '材料名称', //字段标题
                            fieldName: 'stuff_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        },
                        {
                            FieldTitle: '班组名称', //字段标题
                            fieldName: 'stuff_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        },
                        {
                            FieldTitle: '机械名称', //字段标题
                            fieldName: 'stuff_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        },
                        {
                            FieldTitle: '合同工期', //字段标题
                            fieldName: 'con_duration', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '合同总金额(元)', //字段标题
                            fieldName: 'con_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '税率(%)', //字段标题
                            fieldName: 'tax', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '发票类型', //字段标题
                            fieldName: 'invo_type', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择发票类型', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                { value: 1, label: "普票" },
                                { value: 2, label: "专票" },
                            ]
                        },
                        {
                            FieldTitle: '供应商资质', //字段标题
                            fieldName: 'sup_pua', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                },
                {
                    plateName: '合同条款信息',
                    list: [
                        {
                            FieldTitle: '合同支付条款', //字段标题
                            fieldName: 'con_pay_terms', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '其他特殊条款', //字段标题
                            fieldName: 'other_terms', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '备注', //字段标题
                            fieldName: 'info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                    ],
                },
                {
                    plateName: '合同说明及附件',
                    list: [
                        {
                            FieldTitle: '相关说明', //字段标题
                            fieldName: 'about_info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }, {
                    plateName: '外部审核意见',
                    list: [
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'review_comments', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                },
                ]
            },

            modal_Visible: false,
            buttonTitle: '',
            dataSource: [],
            columns: [],
            TableInfo: [],

            seal_name: '',
            pro_name: '',
            sup_name: '',
            mat_class: '',
            revenue_num: '',
            revenue_name: '',

            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {},
            radio: true
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_machineryDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
		let project = ''
		Info.state = 1
		if (localStorage.getItem('itemForm')) {
		    project = JSON.parse(localStorage.getItem('itemForm'))
		    form.project_id = project.project_id
			Info.TableInfo.forEach((item, index) => {
			    if (item.FieldTitle === '项目名称') {
			        item.value = project.pro_name
			    } else if (item.FieldTitle === '项目编号') {
			        item.value = project.pro_number
			    } else if (item.FieldTitle === '项目经理') {
			        item.value = project.pro_manager
			    }
			})
		}
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_machineryDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        machineryDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.main_id = res.data.Detail.main_id
                        form.sup_id = res.data.Detail.sup_id
                        Info.process.edit = res.data.Detail.editPermission
                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                    } else {
                        if (res.data.Detail.status === 4) {
                            let conType = res.data.Detail.mac_type === 1 ? '材料采购' : res.data.Detail.mac_type === 2 ? '劳务' : res.data.Detail.mac_type === 3 ? '机械' : res.data.Detail.mac_type === 4 ? '设计' : '零星材料采购'
                            let conFirst = res.data.Detail.con_first === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.con_first === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.con_first === 3 ? '四川创意致远建筑工程有限公司' : '四川天艺致远物联网科技有限公司'
                            Info.tempData.proName = res.data.Detail.pro_name
                            Info.tempData.conType = conType
                            Info.tempData.conNumber = res.data.Detail.con_number
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.pro_name = res.data.Detail.pro_name
                            Info.tempData.conName = res.data.Detail.con_name
                            Info.tempData.conMoney = res.data.Detail.con_money
                            Info.tempData.conFirst = conFirst
                            Info.tempData.supName = res.data.Detail.sup_name
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }

                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '选择项目') {
                                    em.isShow = true
                                } else if (em.FieldTitle === '关联供应商认价审批单据') {
                                    em.isShow = true
                                } else if (em.FieldTitle === '选择主合同') {
                                    em.isShow = true
                                } else if (em.FieldTitle === '关联供应商') {
                                    em.isShow = true
                                }
                            })
                        })

                        if (!res.data.Detail.enclosure.length) {
                            Info.FirstInfo[3].list.forEach((item, index) => {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            })
                        }

                        if (!res.data.Detail.review_comments.length) {
                            Info.FirstInfo[4].isShow = true
                        }

                        if (res.data.Detail.is_seal === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    form.price_approval_id = res.data.Detail.price_approval_id
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.mac_type === 1) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '供应商认价审批单据') {
                                item.value = form.price_approval_id
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '材料名称') {
                                item.isShow = false
                                item.value = res.data.Detail.stuff_name
                            } else if (item.FieldTitle === '供应商名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '供应商资质') {
                                item.value = res.data.Detail.sup_pua === 1 ? '是' : '否'
                            }
                        })
                    } else if (res.data.Detail.mac_type === 2) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '供应商认价审批单据') {
                                item.value = form.price_approval_id
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '班组名称') {
                                item.isShow = false
                                item.value = res.data.Detail.stuff_name
                            } else if (item.FieldTitle === '供应商名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '供应商资质') {
                                item.value = res.data.Detail.sup_pua === 1 ? '是' : '否'
                            }
                        })
                    } else if (res.data.Detail.mac_type === 3) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '供应商认价审批单据') {
                                item.value = form.price_approval_id
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '机械名称') {
                                item.isShow = false
                                item.value = res.data.Detail.stuff_name
                            } else if (item.FieldTitle === '供应商名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '机械名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '供应商资质') {
                                item.value = res.data.Detail.sup_pua === 1 ? '是' : '否'
                            }
                        })
                    } else if (res.data.Detail.mac_type === 4) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联供应商认价审批单据') {
                                item.isShow = true
                            } else if (item.FieldTitle === '供应商认价审批单据') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '关联供应商') {
                                item.isShow = true
                            } else if (item.FieldTitle === '供应商名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '供应商资质') {
                                item.isShow = true
                            }
                        })
                    } else if (res.data.Detail.mac_type === 7) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '供应商认价审批单据') {
                                item.value = form.price_approval_id
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '材料名称') {
                                item.isShow = false
                                item.value = res.data.Detail.stuff_name
                            } else if (item.FieldTitle === '供应商名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '供应商资质') {
                                item.value = res.data.Detail.sup_pua === 1 ? '是' : '否'
                            }
                        })
                    } else if (res.data.Detail.mac_type === 8) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '供应商认价审批单据') {
                                item.value = form.price_approval_id
                            }
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '材料名称') {
                                item.isShow = false
                                item.value = res.data.Detail.stuff_name
                            } else if (item.FieldTitle === '供应商名称') {
                                item.value = res.data.Detail.sup_name
                            } else if (item.FieldTitle === '供应商资质') {
                                item.value = res.data.Detail.sup_pua === 1 ? '是' : '否'
                            }
                        })
                    }

                    if (res.data.Detail.con_nature === 2) {
                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (item.FieldTitle === '主合同名称') {
                                item.value = res.data.Detail.main_name
                            } else if (item.FieldTitle === '主合同编号') {
                                item.value = res.data.Detail.main_number
                            }
                        })
                    }

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManagerName
                        }
                    })


                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    Info.FirstInfo[4].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.review_comments.length ? res.data.Detail.review_comments : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        con_number: res.data.Detail.con_number,
                        is_inside: res.data.Detail.is_inside,
                        is_seal: res.data.Detail.is_seal,
                        mac_type: res.data.Detail.mac_type,
                        pay_type: res.data.Detail.pay_type,
                        set_model: res.data.Detail.set_model,
                        con_name: res.data.Detail.con_name,
                        con_nature: res.data.Detail.con_nature,
                        version_type: res.data.Detail.version_type,
                        con_first: res.data.Detail.con_first,
                        stuff_name: res.data.Detail.stuff_name,
                        con_duration: res.data.Detail.con_duration,
                        con_money: res.data.Detail.con_money,
                        tax: res.data.Detail.tax,
                        invo_type: res.data.Detail.invo_type,
                        // sup_pua: res.data.Detail.sup_pua,
                        con_pay_terms: res.data.Detail.con_pay_terms,
                        other_terms: res.data.Detail.other_terms,
                        info: res.data.Detail.info,
                        about_info: res.data.Detail.about_info,
                        enclosure: res.data.Detail.enclosure,
                        review_comments: res.data.Detail.review_comments,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutSupConfirmSelect = () => {
        let { dataSource, pages, sup_name, pro_name, form } = this.state

        if (!form.mac_type) {
            message.error('请选择合同类别!')
            return
        }

        let data = {
            class: form.mac_type,
            pro_name: pro_name,
            sup_name: sup_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutSupConfirmSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.confirms.length) {
                        res.data.confirms.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                className: item.className,
                                sup_name: item.sup_name,
                                projectName: item.projectName,
                                apply_date: item.apply_date,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_MainContractQuery = () => {
        let { dataSource, pages, form } = this.state
        let data = {
            project_id: form.project_id,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        machineryMainContractQuery(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.main.length) {
                        res.data.main.forEach((item, index) => {
                            let contract = {
                                key: item.table_id,
                                apply_date: item.apply_date,
                                code: item.code,
                                con_name: item.con_name,
                                con_money: item.con_money,
                            }
                            dataSource = [...dataSource, contract]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierSelect = () => {
        let { dataSource, pages, sup_name, mat_class, form } = this.state

        if (!form.mac_type) {
            message.error('请选择合同类别!')
            return
        }

        let data = {
            class: form.mac_type,
            sup_name: sup_name,
            mat_class: mat_class,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : '机械',
                                mat_class: item.matClass,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                contacts: item.contacts,
                                pay_model: item.payModel,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.con_number = data.con_number
        form.is_inside = data.is_inside
        form.is_seal = data.is_seal
        form.mac_type = data.mac_type
        form.pay_type = data.pay_type
        form.set_model = data.set_model
        form.con_name = data.con_name
        form.con_nature = data.con_nature
        form.version_type = data.version_type
        form.con_first = data.con_first
        form.stuff_name = data.stuff_name
        form.con_duration = data.con_duration
        form.con_money = data.con_money
        form.tax = data.tax
        form.invo_type = data.invo_type
        // form.sup_pua = data.sup_pua
        form.con_pay_terms = data.con_pay_terms
        form.other_terms = data.other_terms
        form.info = data.info
        form.about_info = data.about_info

        createMachinery(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, form } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_seal()
            } else if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                        width: '280px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                        width: '280px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_BlankingSelectProject()
            } else if (title === '关联供应商认价审批单据') {
                columns = [
                    {
                        title: '认价类别',
                        dataIndex: 'className',
                        key: 'className',
                    },
                    {
                        title: '认价日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'projectName',
                        key: 'projectName',
                        width: '280px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                        width: '280px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                ]
                this.get_aboutSupConfirmSelect()
            } else if (title === '选择主合同') {
                columns = [
                    {
                        title: '合同编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同总金额(元)',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                ]
                TableInfo = []
                this.get_MainContractQuery()
            } else if (title === '关联供应商') {
                columns = [
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                        width: '180px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{width: '160px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '供应商类型',
                        dataIndex: 'class',
                        key: 'class',
                    },
                    {
                        title: '所属分类',
                        dataIndex: 'mat_class',
                        key: 'mat_class',
                    },
                    {
                        title: '电 话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '联 系 人',
                        dataIndex: 'contacts',
                        key: 'contacts',
                    },
                    {
                        title: '付款方式',
                        dataIndex: 'pay_model',
                        key: 'pay_model',
                    },
                    {
                        title: '建 档 人',
                        dataIndex: 'apply_user',
                        key: 'apply_user',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '所属分类', //字段标题
                        fieldName: 'mat_class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_supplierSelect()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
                workAdd: false
            }))
        }
    }

    handleRadio = (value, title) => {
        let { Info, form } = this.state;

        if (title === '是否盖章') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = false
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = false
                    }
                })
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择印章') {
                        item.isShow = true
                    } else if (item.FieldTitle === '印章') {
                        item.isShow = true
                    }
                })
            }
        } else {
            form.mac_type = value
            if (value === 1) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = true
                    }
                })
            } else if (value === 2) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = true
                    }
                })
            } else if (value === 3) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = false
                    }
                })
            } else if (value === 4) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = true
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = true
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = true
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = true
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = true
                    }
                })
            } else if (value === 7) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = true
                    }
                })
            } else if (value === 8) {
                Info.FirstInfo[0].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商认价审批单据') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商认价审批单据') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[1].list.forEach((item, index) => {
                    if (item.FieldTitle === '关联供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商资质') {
                        item.isShow = false
                    } else if (item.FieldTitle === '材料名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '班组名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '机械名称') {
                        item.isShow = true
                    }
                })
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '选择印章') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        } else if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '关联供应商认价审批单据') {
            form.price_approval_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '供应商认价审批单据') {
                    item.value = value[0].key
                }
            })
        } else if (buttonTitle === '选择主合同') {
            form.main_id = value[0].key
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '主合同名称') {
                    item.value = value[0].main_name
                } else if (item.FieldTitle === '主合同编号') {
                    item.value = value[0].main_number
                }
            })
        } else if (buttonTitle === '关联供应商') {
            form.sup_id = value[0].key
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '供应商名称') {
                    item.value = value[0].sup_name
                } else if (item.FieldTitle === '供应商资质') {
                    item.value = value[0].sup_pua === 1 ? '是' : '否'
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 2) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = false
                }
            })
        } else {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            this.setState({
                seal_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        } else if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '关联供应商认价审批单据') {
            this.setState({
                sup_name: value.sup_name,
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_aboutSupConfirmSelect()
            })
        } else if (buttonTitle === '关联供应商') {
            this.setState({
                sup_name: value.sup_name,
                mat_class: value.mat_class,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_supplierSelect()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            seal_name: '',
            pro_name: '',
            sup_name: '',
            mat_class: '',
            revenue_num: '',
            revenue_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.fieldName === fileName) {
                        if (em.value && em.value.length) {
                            em.value.forEach((m, x) => {
                                storeList.forEach((j, k) => {
                                    if (m.table_id === j) {
                                        fileList.push(m)
                                    }
                                })
                            })

                        }
                        if (fileValue) {
                            fileList.push(fileValue)
                        }
                        em.value = fileList
                    }
                })
            })
            //     if (fileName === 'enclosure') {
            //         Info.FirstInfo[3].list.forEach((item, index) => {
            //             if (item.fieldName === fileName) {
            //                 if (item.value && item.value.length) {
            //                 	item.value.forEach((em, ex) => {
            //                 		storeList.forEach((m, x) => {
            //                 			if (em.table_id === m) {
            //                 				fileList.push(em)
            //                 			}
            //                 		})
            //                 	})
            //                 	item.value = fileList
            //                 } else {
            //                     item.value = fileValue
            //                 }
            //             }
            //         })
            //     } else {
            //         Info.FirstInfo[4].list.forEach((item, index) => {
            //             if (item.fieldName === fileName) {
            //                 if (item.value) {
            //                     item.value.forEach((em, ex) => {
            //                         storeList.forEach((m, x) => {
            //                             if (em.table_id === m) {
            //                                 fileList.push(em)
            //                             }
            //                         })
            //                     })
            //                     item.value = fileList
            //                 }
            //             }
            //         })
            //     }

        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择主合同') {
                this.get_MainContractQuery()
            } else if (buttonTitle === '关联供应商认价审批单据') {
                this.get_aboutSupConfirmSelect()
            } else if (buttonTitle === '关联供应商') {
                this.get_supplierSelect()
            }
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })

                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        let { form } = this.state
        if (!form.price_approval_id) {
            message.error('未关联供应商认价审批单!')
            return
        }
        this.props.history.push({
            pathname: '/purchase/supplier/audit/NewItem',
            query: {
                'tableId': form.price_approval_id,
            }
        })
    }


    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}