import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createHandoverWork, HandoverWorkDetail, getUserHaveFunction } from "../../../../utils/public"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"
import { date } from 'jszip/lib/defaults';

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "离职交接",
            TableInfo: [{
                FieldTitle: '申请人', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '申请日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择申请时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择离职人员', //字段标题
                fieldType: '按钮',  //字段类型           
                mandatory: true, //字段是否必填    
            }, {
                FieldTitle: '离职人员', //字段标题
                fieldName: 'res_user', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择交接人员', //字段标题
                fieldType: '按钮',  //字段类型   
                mandatory: true, //字段是否必填                
            }, {
                FieldTitle: '交接人员', //字段标题
                fieldName: 'acc_user', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择交接功能', //字段标题
                fieldType: '按钮',  //字段类型   
                mandatory: true, //字段是否必填                
            }, {
                FieldTitle: '交接功能', //字段标题
                fieldName: 'content', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        workAdd: false,
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        FlowchartKey: 1,
        buttonTitle: '',
        radio: true,
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_HandoverWorkDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '申请日期') {
                item.value = new Date().toLocaleDateString()
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_HandoverWorkDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        console.log(123,Info, form,data)
        HandoverWorkDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择离职人员') {
                                item.isShow=true
                            } else if (item.FieldTitle === '选择交接人员') {
                                item.isShow=true
                            } else if (item.FieldTitle === '选择交接功能') {
                                item.isShow=true
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '申请日期') {
                            item.value = res.data.Detail.apply_day
                        } else if (item.FieldTitle === '离职人员') {
                            form.res_user = res.data.Detail.res_user
                            item.value = res.data.Detail.resUsername
                        }  else if (item.FieldTitle === '交接人员') {
                            form.acc_user = res.data.Detail.acc_user
                            item.value = res.data.Detail.accUsername
                        } else if (item.FieldTitle === '交接功能') {
                            let content = JSON.parse(res.data.Detail.content)
                            let value = ''
                            content.forEach((item, index) => {
                                if (index === 0) {
                                    value = item.name
                                } else {
                                    value = value + ' , ' + item.name
                                }
                            })
                            this.NewForm.current.formRef.current.setFieldsValue({
                                content: value,
                            })
                            form.content = content
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        info: res.data.Detail.info,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.info = data.info
        if (typeof (form.content) !== 'string') {
            form.content = JSON.stringify(form.content)
        } else {
            form.content = form.content
        }
        createHandoverWork(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name, buttonTitle } = this.state
        let data = {
            username: username,
            de_name: de_name,
            member_status: buttonTitle === '选择离职人员' ? 4 : '',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_UserHaveFunction = () => {
        let { dataSource, form } = this.state
        if (!form.res_user) {
            message.error('请选择离职人员')
            return
        }
        let data = {
            res_user: form.res_user
        }
     
        getUserHaveFunction(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.command.length) {
                        res.data.command.forEach((item, index) => {
                            let user = {
                                key: index + 1,
                                name: item.name,
                                table_name: item.table_name,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: false,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            if (title === '选择离职人员' || title === '选择交接人员') {
                this.setState(preState => ({
                    columns: [{
                        title: '姓名',
                        dataIndex: 'username',
                        key: 'username',
                    },
                    {
                        title: '性别',
                        dataIndex: 'sex',
                        key: 'sex',
                        render: (text, index) => {
                            if (text === '1') {
                                return (
                                    <span> 男 </span>
                                )
                            } else {
                                return (<span> 女 </span>
                                )
                            }
                        }
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'deName',
                        key: 'deName',
                    },
                    {
                        title: '职位',
                        dataIndex: 'company_job',
                        key: 'company_job',
                    },
                    {
                        title: '手机',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    ],
                    TableInfo: [{
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '部门', //字段标题
                        fieldName: 'de_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }],
                    FlowchartKey: Math.random(),
                    buttonTitle: title,
                    radio: true
                }), () => {
                    this.get_InitialValue()
                })
            } else {
                this.setState(preState => ({
                    columns: [{
                        title: '权限名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    ],
                    TableInfo: [],
                    FlowchartKey: Math.random(),
                    buttonTitle: title,
                    radio: false
                }), () => {
                    this.get_UserHaveFunction()
                })
            }
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '选择离职人员') {
            form.res_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '离职人员') {
                    item.value = value[0].username
                }
            })
        } else if (buttonTitle === '选择交接人员') {
            form.acc_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '交接人员') {
                    item.value = value[0].username
                }
            })
        } else {
            let values = ''
            form.content = []
            if (value.length) {
                value.forEach((item, index) => {
                    let row = {
                        table_name: item.table_name,
                        name: item.name,
                    }
                    form.content = [...form.content, row]

                    if (index === 0) {
                        values = item.name
                    } else {
                        values = values + ' , ' + item.name
                    }
                })
            }
            this.NewForm.current.formRef.current.setFieldsValue({
                content: values
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, FlowchartKey, radio } = this.state
        return (
            <div className='configAdd' >
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
