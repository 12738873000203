import React, { Component } from 'react'
import { Select, Button } from 'antd';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import { CaretDownOutlined, FileDoneOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { assessmentDetailList } from "../../../../utils/oa"
import { getCompanyDeName } from "../../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '部门',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '一',
                    dataIndex: 'one',
                    key: 'one',
                },
                {
                    title: '二',
                    dataIndex: 'two',
                    key: 'two',
                },
                {
                    title: '三',
                    dataIndex: 'three',
                    key: 'three',
                },
                {
                    title: '四',
                    dataIndex: 'four',
                    key: 'four',
                },
                {
                    title: '考核说明',
                    dataIndex: 'matter',
                    key: 'matter',
                },
                {
                    title: '当月累计加减',
                    dataIndex: 'cumulative',
                    key: 'cumulative',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'user_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            yearList: [
                { value: 2022, label: "2022年" },
                { value: 2023, label: "2023年" },
                { value: 2024, label: "2024年" },
                { value: 2025, label: "2025年" },
                { value: 2026, label: "2026年" },
                { value: 2027, label: "2027年" },
                { value: 2028, label: "2028年" },
                { value: 2029, label: "2029年" },
                { value: 2030, label: "2030年" },
            ],
            yearValue: '',
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
            teamList: [],
            teamValue: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_assessmentDetailList()
        this.get_CompanyDeName()
    }

    get_CompanyDeName = () => {
        let { teamList } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    res.data.departments.forEach((item, index) => {
                        let value = {
                            value: item.id,
                            label: item.name
                        }
                        teamList = [...teamList, value]
                    })
                    this.setState(preState => ({
                        teamList
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_assessmentDetailList = () => {
        let { dataSource, pages, user_name, yearValue, monthValue, teamValue, process_state } = this.state
        let data = {
            user_id: user_name,
            year: yearValue,
            month: monthValue,
            de_id: teamValue,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        assessmentDetailList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.details.length) {
                        res.data.details.forEach((item, index) => {
                            item.content = JSON.parse(item.content)
                            let rowValue = {
                                key: index + 1,
                                table_id:item.table_id,
                                name: item.name,
                                deName: item.deName,
                                one: item.content.one,
                                two: item.content.two,
                                three: item.content.three,
                                four: item.content.four,
                                matter: item.matter,
                                cumulative:Number(item.content.one)+Number(item.content.two)+Number(item.content.three)+Number(item.content.four),
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
		let value = '/administrative/performance/record/NewItem'
		let CachingKeys = getCachingKeys()
		if (CachingKeys.length) {
			CachingKeys.forEach((item, index) => {
				if (item === value) {
					dropByCacheKey(item)
				}
			})
		}
		setTimeout(() => {
			this.props.history.push(value)
		});
    }

    // handleResult = () => {
    //     this.props.history.push('/administrative/performance/record/ResultList')
    // }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/administrative/performance/record/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            user_name: '',
            yearValue: '',
            monthValue: '',
            teamValue: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_assessmentDetailList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            user_name: value.user_name,
        }, () => {
            this.get_assessmentDetailList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_assessmentDetailList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_assessmentDetailList()
        })
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => { this.get_assessmentDetailList() })
    }

    monthChange = (value) => {
        this.setState({
            monthValue: value
        }, () => { this.get_assessmentDetailList() })
    }

    teamChange = (value) => {
        this.setState({
            teamValue: value
        }, () => { this.get_assessmentDetailList() })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, yearValue, yearList, monthList, monthValue, teamList, teamValue } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                        {/* <div className='history ListOperate'>
                            <Button onClick={() => {
                                this.handleResult()
                            }}>
                                <FileDoneOutlined />
                                <div>考核结果</div>
                            </Button>
                        </div> */}
                    </div>


                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    this.yearChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择月份 :</div>
                            <Select
                                options={monthList}
                                value={monthValue}
                                onChange={(e) => {
                                    this.monthChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择部门 :</div>
                            <Select
                                options={teamList}
                                value={teamValue}
                                onChange={(e) => {
                                    this.teamChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
