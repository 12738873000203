import React, { Component } from 'react'
import { Form, DatePicker, ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';

export default class UserRangePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let dateFormat
        let { child, showTime, disabled } = this.props;
        let  disabledSeconds= () => [0]
        showTime ? dateFormat = 'YYYY-MM-DD HH:mm:ss' : dateFormat = 'YYYY-MM-DD'
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ConfigProvider locale={zhCN}>
                    {
                        child ? child.map((item, index) => {
                            if (index > 0) {
                                return (
                                    <Form.Item style={{ width: '48%', marginBottom: 0 }}
                                        label=''
                                        name={item.fieldName}
                                        key={index}
                                        initialValue={item.value}
                                    >
                                        <DatePicker
                                            allowClear={false}
                                            showTime={showTime}
                                            format={dateFormat}
                                            disabled={disabled}
                                            hourStep={1}
                                            minuteStep={10} 
                                            secondStep={60} 
                                        />
                                    </Form.Item>
                                )
                            } else {
                                return (
                                    <div key={index} style={{ width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Form.Item style={{ width: '96%', marginBottom: 0 }}
                                            label=''
                                            name={item.fieldName}
                                            initialValue={item.value}
                                        >
                                            <DatePicker
                                                allowClear={false}
                                                showTime={showTime}
                                                format={dateFormat}
                                                disabled={disabled}
                                                hourStep={1}
                                                minuteStep={10} 
                                                secondStep={60} 
                                            />
                                        </Form.Item>

                                        <div style={{ height: '44px', lineHeight: '44px' }}>
                                            -
                                        </div>
                                    </div>
                                )
                            }
                        }) : ''
                    }
                </ConfigProvider>
            </div>
        )
    }
}
