import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { TechnologyAuxiliaryData, createDevelopmentContractApproval, selectDevelopmentNature, contractDevelopmentApprovalDetail } from "../../../utils/scientific_research"
import { selectAvailableSeal } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/companyContractApprove.docx',
            templateName: '四川天艺生态园林集团股份有限公司合同评审表',
            tempData: {
                con_type: '',
                apply_date: '',
                conName: '',
                conMoney: '',
                userName: '',
                sealName: '',
                first_party: '',
                party_b: '',
                info: '',
                results: []
            },
            FlowModelName: "公司合同审批",
            TableInfo: [
                {
                    FieldTitle: '日期', //字段标题
                    fieldName: 'apply_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'code', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                },
                {
                    FieldTitle: '科研院合同', //字段标题
                    fieldName: 'is_sc', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                }, {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 0, label: "否" },
                    ]
                }, {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_id', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    newline: true,
                    width: '100%',
                    isShow: true,
                }, {
                    FieldTitle: '收支类别', //字段标题
                    fieldName: 'pay_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '结算合同方式', //字段标题
                    fieldName: 'set_model', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '合同类别', //字段标题
                    fieldName: 'con_category', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '合同性质', //字段标题
                    fieldName: 'con_nature', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                },
            ],
            FirstInfo: [{
                plateName: '合同信息',
                list: [
                    {
                        FieldTitle: '合同版本类别', //字段标题
                        fieldName: 'version_type', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            { value: 1, label: "标准版本" },
                            { value: 2, label: "其他版本" },
                        ]
                    }, {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '选择主合同', //字段标题
                        fieldType: '按钮',  //字段类型
                        isShow: true,
                    }, {
                        FieldTitle: '主合同编号', //字段标题
                        fieldName: 'main_code', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true,
                    }, {
                        FieldTitle: '主合同名称', //字段标题
                        fieldName: 'main_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true,
                    },
                    {
                        FieldTitle: '合同甲方', //字段标题
                        fieldName: 'first_party', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '合同乙方', //字段标题
                        fieldName: 'party_b', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '合同总金额(元)', //字段标题
                        fieldName: 'con_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '税率(%)', //字段标题
                        fieldName: 'tax', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '发票类型', //字段标题
                        fieldName: 'invo_type', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        selectList: []
                    },
                ]
            }, {
                plateName: '合同条款信息',
                list: [{
                    FieldTitle: '合同支付条款', //字段标题
                    fieldName: 'pay_clause', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '其他特殊条款', //字段标题
                    fieldName: 'other_clause', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },]
            }, {
                plateName: '合同说明及附件',
                list: [{
                    FieldTitle: '相关说明', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }]
            }],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        buttonTitle: '',
        dataSource: [],
        columns: [],
        TableInfo: [],
        con_name: '',
        code: '',
        seal_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 50,
        },
        form: {},
        radio: true
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_TechnologyAuxiliaryData()
    }

    get_TechnologyAuxiliaryData = () => {
        let { Info } = this.state
        TechnologyAuxiliaryData().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '收支类别') {
                            for (let key in res.data.pay_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.pay_type[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '结算合同方式') {
                            for (let key in res.data.natureMethod) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.natureMethod[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '合同类别') {
                            for (let key in res.data.natureType) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.natureType[key],
                                    disabled: Number(key) === 5 || Number(key) === 6 || Number(key) === 7 ? true : false,
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '合同性质') {
                            for (let key in res.data.NatureContract) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.NatureContract[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '发票类型') {
                            for (let key in res.data.invoice_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.invoice_type[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_contractDevelopmentApprovalDetail(tableId)
                        } else {
                            let development_id = sessionStorage.getItem('development_id')
                            this.get_userInfo(development_id)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInfo = (development_id) => {
        const { form, Info } = this.state;
        form.development_id = development_id
        Info.state = 1
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    get_contractDevelopmentApprovalDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        contractDevelopmentApprovalDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择印章') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                        if (res.data.Detail.con_nature === 2) {
                            form.main_id = res.data.Detail.main_id
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '选择主合同') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '主合同编号') {
                                    item.value = res.data.Detail.main.code
                                    item.isShow = false
                                } else if (item.FieldTitle === '主合同名称') {
                                    item.value = res.data.Detail.main.con_name
                                    item.isShow = false
                                }
                            })
                        }


                    } else {
                        if (res.data.Detail.status === 4) {
                            let con_type = res.data.Detail.con_category === 1 ? '四川天艺生态园林集团股份有限公司' : res.data.Detail.con_first === 2 ? '四川天艺优境环境科技有限公司' : res.data.Detail.con_first === 3 ? '四川天艺致远物联网科技有限公司' : res.data.Detail.con_first === 4 ? '四川天艺传奇生态茶业有限公司' : res.data.Detail.con_first === 5 ? '四川天艺生态咨询服务' : res.data.Detail.con_first === 6 ? '四川天艺生态合作协议' : res.data.Detail.con_first === 7 ? '四川天艺生态其他' : '天艺生态科技创新研究院'
                            Info.tempData.con_type = con_type
                            Info.tempData.code = res.data.Detail.code
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.conName = res.data.Detail.con_name
                            Info.tempData.conMoney = res.data.Detail.con_money
                            Info.tempData.userName = res.data.Detail.applyUserName
                            Info.tempData.sealName = res.data.Detail.sealNames || '/'
                            Info.tempData.first_party = res.data.Detail.first_party
                            Info.tempData.party_b = res.data.Detail.party_b
                            Info.tempData.info = res.data.Detail.info
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }

                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                            })
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            if (!res.data.Detail.enclosure) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }

                        })

                        if (res.data.Detail.is_seal === 1) {
                            form.seal_id = res.data.Detail.seal_id
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '印章') {
                                    item.isShow = false
                                    item.value = res.data.Detail.sealNames
                                }
                            })
                        }

                        if (res.data.Detail.con_nature === 2) {
                            form.main_id = res.data.Detail.main_id
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '主合同编号') {
                                    item.value = res.data.Detail.main.code
                                    item.isShow = false
                                } else if (item.FieldTitle === '主合同名称') {
                                    item.value = res.data.Detail.main.con_name
                                    item.isShow = false
                                }
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同编号') {
                            item.value = res.data.Detail.code
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '姓名') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '职位名称') {
                            item.value = res.data.Detail.jobName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    Info.FirstInfo[2].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        is_sc: res.data.Detail.is_sc,
                        is_seal: res.data.Detail.is_seal,
                        pay_type: res.data.Detail.pay_type,
                        set_model: res.data.Detail.set_model,
                        con_category: res.data.Detail.con_category,
                        con_nature: res.data.Detail.con_nature,
                        version_type: res.data.Detail.version_type,
                        con_name: res.data.Detail.con_name,
                        first_party: res.data.Detail.first_party,
                        party_b: res.data.Detail.party_b,
                        con_money: res.data.Detail.con_money,
                        tax: res.data.Detail.tax,
                        invo_type: res.data.Detail.invo_type,
                        pay_clause: res.data.Detail.pay_clause,
                        other_clause: res.data.Detail.other_clause,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.is_sc = data.is_sc
        form.is_seal = data.is_seal
        form.pay_type = data.pay_type
        form.set_model = data.set_model
        form.con_category = data.con_category
        form.con_nature = data.con_nature
        form.version_type = data.version_type
        form.con_name = data.con_name
        form.first_party = data.first_party
        form.party_b = data.party_b
        form.con_money = data.con_money
        form.tax = data.tax
        form.invo_type = data.invo_type
        form.pay_clause = data.pay_clause
        form.other_clause = data.other_clause
        form.info = data.info


        createDevelopmentContractApproval(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //         // sessionStorage.setItem('development_id', '')
                    //     })
                    // }
					    setTimeout(() => {
					        window.history.back(-1)
					        // sessionStorage.setItem('development_id', '')
					    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_contract = () => {
        let { dataSource, pages, con_name, code } = this.state
        let data = {
            con_name: con_name,
            code: code,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectDevelopmentNature(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.mainData.length) {
                        res.data.mainData.forEach((item, index) => {
                            let contract = {
                                key: item.table_id,
                                apply_date: item.apply_date,
                                code: item.code,
                                con_name: item.con_name,
                                con_money: item.con_money
                            }
                            dataSource = [...dataSource, contract]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择主合同') {
                columns = [
                    {
                        title: '日期',
                        dataIndex: 'apply_date',
                        key: 'apply_date',
                    },
                    {
                        title: '合同编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同总金额(元)',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'code', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                radio = true
                this.get_contract()
            } else {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                radio = false
                this.get_seal()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
                workAdd: false
            }))
        }
    }

    handleRadio = (value) => {
        let { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = false
                } else if (item.FieldTitle === '印章') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = true
                } else if (item.FieldTitle === '印章') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '选择主合同') {
            form.main_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '主合同编号') {
                    item.value = value[0].code
                }
                if (item.FieldTitle === '主合同名称') {
                    item.value = value[0].con_name
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_id = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 2) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = false
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = false
                }
            })
        } else {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '选择主合同') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同编号') {
                    item.isShow = true
                } else if (item.FieldTitle === '主合同名称') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择主合同') {
            this.setState({
                con_name: value.con_name,
                code: value.code,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_contract()
            })
        } else {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            con_name: '',
            code: '',
            seal_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择主合同') {
                this.get_contract()
            } else {
                this.get_seal()
            }
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
