import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { InvoiceApplicationDetail, createInvoiceApplication, BlankingSelectProject } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Item from 'antd/lib/list/Item';

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
				edit: false
            },
            FlowModelName: "业主开票申请",
            TableInfo: [
                // {
                //     FieldTitle: '申请日期', //字段标题
                //     fieldName: 'apply_date', //字段名称
                //     fieldType: '日期',  //字段类型
                //     disabled: false,  //字段是否禁用
                //     mandatory: false, //字段是否必填
                //     required: '', //必填要求
                //     value: '',
                //     width: '50%'
                // },
                {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                },
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '开票金额(元)', //字段标题
                    fieldName: 'invo_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入开票金额(元)', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '开票类型', //字段标题
                    fieldName: 'invo_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择开票类型', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            label: '专用增值税发票',
                            value: 1
                        },
                        {
                            label: '普通增值税发票',
                            value: 2
                        }
                    ]
                },
                {
                    FieldTitle: '取票方式', //字段标题
                    fieldName: 'get_fun', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择取票方式', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            label: '邮寄',
                            value: 1
                        },
                        {
                            label: '自取',
                            value: 2
                        }
                    ]
                },
                {
                    FieldTitle: '联系方式', //字段标题
                    fieldName: 'contact', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    placeholder: '联系人 联系电话 收货地址',
                    width: '100%',
                    isShow: true
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }
            ],
            FirstInfo: []
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            }, {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'pro_manager',
                key: 'pro_manager',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            }
        ],
        pro_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_InvoiceApplicationDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
		let project = ''
		Info.state = 1
		if (localStorage.getItem('itemForm')) {
		    project = JSON.parse(localStorage.getItem('itemForm'))
		    form.project_id = project.project_id
			Info.TableInfo.forEach((item, index) => {
			    if (item.FieldTitle === '项目名称') {
			        item.value = project.pro_name
			    } else if (item.FieldTitle === '项目编号') {
			        item.value = project.pro_number
			    } else if (item.FieldTitle === '项目经理') {
			        item.value = project.pro_manager
			    }
			})
		}
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_InvoiceApplicationDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        InvoiceApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
						Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })                     
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.pro_manager
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    if (Number(res.data.Detail.get_fun) === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '联系方式') {
                                item.isShow = false
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        invo_money: res.data.Detail.invo_money,
                        invo_type: res.data.Detail.invo_type,
                        get_fun: Number(res.data.Detail.get_fun),
                        contact: res.data.Detail.contact,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.invo_money = data.invo_money
        form.invo_type = data.invo_type
        form.get_fun = data.get_fun
        form.contact = data.contact || ''
        form.info = data.info

        createInvoiceApplication(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_BlankingSelectProject()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            } else if (item.FieldTitle === '项目经理') {
                item.value = value[0].pro_manager
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '联系方式') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '联系方式') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} selectorEvent={this.handSelector} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
