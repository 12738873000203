import React, { Component } from 'react'
import axios from 'axios'
import { Select, Button, Modal, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import calendarImg from "../../../../accets/img/calendar.png"
import { AttendanceSummary } from "../../../../utils/oa"
import { getCompanyDeName } from "../../../../utils/api"


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'userName',
                    key: 'userName',
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '补卡次数',
                    dataIndex: 'CardReplacementNumber',
                    key: 'CardReplacementNumber',
                },
                {
                    title: '请假天数',
                    dataIndex: 'leaveDayNumber',
                    key: 'leaveDayNumber',
                },
                {
                    title: '出差次数',
                    dataIndex: 'awayNumber',
                    key: 'awayNumber',
                },
                {
                    title: '派遣天数',
                    dataIndex: 'day_number',
                    key: 'day_number',
                },
                {
                    title: '派遣时间',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text, index) => {
                        return (
                            <div style={{
                                fontSize: '16px',
                                color: '#339EF9',
                                cursor: 'pointer'
                            }} onClick={() => { this.modalVisible(text) }}>
                                点击查看
                            </div>
                        )
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'user_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            yearList: [
                { value: 2022, label: "2022年" },
                { value: 2023, label: "2023年" },
                { value: 2024, label: "2024年" },
                { value: 2025, label: "2025年" },
                { value: 2026, label: "2026年" },
                { value: 2027, label: "2027年" },
                { value: 2028, label: "2028年" },
                { value: 2029, label: "2029年" },
                { value: 2030, label: "2030年" },
            ],
            yearValue: '',
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
            teamList: [],
            teamValue: '',
            user_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            weeklyModal: false,
            weekList: []
        }
    }

    componentDidMount() {
        this.get_CompanyDeName()
        this.get_AttendanceSummary()
    }

    get_CompanyDeName = () => {
        let { teamList } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    res.data.departments.forEach((item, index) => {
                        let value = {
                            value: item.id,
                            label: item.name
                        }
                        teamList = [...teamList, value]
                    })
                    this.setState(preState => ({
                        teamList
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_AttendanceSummary = () => {
        let { dataSource, pages, user_name, yearValue, monthValue, teamValue } = this.state
        let data = {
            user_id: user_name,
            year: yearValue,
            month: monthValue,
            de_id: teamValue,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        AttendanceSummary(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                userName: item.username,
                                deName: item.deName,
                                CardReplacementNumber: item.CardReplacementNumber,
                                leaveDayNumber: item.leaveDayNumber,
                                awayNumber: item.awayNumber,
                                day_number: item.userDispatch.day_number,
                                time: item.userDispatch.disStartEnd
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        console.log('新增')
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        let { yearValue, monthValue, teamValue } = this.state

        let data = {
            year: yearValue,
            month: monthValue,
            de_id: teamValue,
        }
        axios.get('/api/AttendanceExport', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '考勤汇总.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            user_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_AttendanceSummary()
        })
    }

    searchQuery = (value) => {
        this.setState({
            user_name: value.user_name
        }, () => {
            this.get_AttendanceSummary()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_AttendanceSummary()
        })
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => { this.get_AttendanceSummary() })
    }

    monthChange = (value) => {
        this.setState({
            monthValue: value
        }, () => { this.get_AttendanceSummary() })
    }

    teamChange = (value) => {
        this.setState({
            teamValue: value
        }, () => { this.get_AttendanceSummary() })
    }

    modalVisible = (value) => {
        let { weekList } = this.state
        weekList = []
        if (value.length) {
            value.forEach((item, index) => {
                item.start = item.start ? item.start.slice(0, 11) : ''
                item.end = item.end ? item.end.slice(0, 11) : ''
            })
            weekList = value
            this.setState({
                weekList,
                weeklyModal: true
            })
        } else {
            message.error('暂无派遣时间')
        }
    }

    modalCancel = () => {
        this.setState({
            weeklyModal: false
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, yearValue, yearList, monthList, monthValue, teamList, teamValue, weeklyModal, weekList } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <Operate dontAdd={true} notPrint={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    this.yearChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择月份 :</div>
                            <Select
                                options={monthList}
                                value={monthValue}
                                onChange={(e) => {
                                    this.monthChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择部门 :</div>
                            <Select
                                options={teamList}
                                value={teamValue}
                                onChange={(e) => {
                                    this.teamChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Modal
                    title='派遣时间'
                    centered
                    visible={weeklyModal}
                    onCancel={() => { this.modalCancel() }}
                    wrapClassName='weekly'
                    footer={[
                        <Button type="primary" onClick={() => { this.modalCancel() }}>确认</Button>
                    ]}
                >
                    <div className='weekly_time'>

                        {
                            weekList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={calendarImg} alt=""></img>
                                        <div>
                                            <div style={{ fontSize: '16px' }}>{item.start}</div>
                                            <div>{item.end}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }



                    </div>
                </Modal>

            </div>
        )
    }
}

export default withRouter(List)
