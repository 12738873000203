import React, { Component } from 'react'
import 'moment/locale/zh-cn';
import * as echarts from 'echarts';
import { withRouter } from "react-router-dom";
import Big from 'big.js';
import Table from '../../../components/Table'
import Search from '../../../components/Search'
import LineChart from './LineChart';
import moneyImg from "../../../../accets/img/money.png"
import { confPriceComparison, provinceCityCare } from "../../../../utils/purchase"


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    width: '80px',
                    render: (text, record, index) => {
                        return (
                            <div>{index + 1}</div>
                        )
                    }
                },
                {
                    title: '名 称',
                    dataIndex: 'mac_name',
                    key: 'mac_name',
                },
                {
                    title: '规格型号',
                    dataIndex: 'model',
                    key: 'model',
                    width: '250px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '240px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    dataIndex: 'projectName',
                    key: 'projectName',
                },
                {
                    title: '采购单价(元)',
                    dataIndex: 'pur_unit_price',
                    key: 'pur_unit_price',
                },
                {
                    title: '单位',
                    dataIndex: 'unit',
                    key: 'unit',
                },
                {
                    title: '认价时间',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
                {
                    title: '详情',
                    dataIndex: 'details',
                    key: 'details',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>详情</div>
                        )
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '名 称', //字段标题
                    fieldName: 'mac_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '时 间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
                {
                    FieldTitle: '城市', //字段标题
                    fieldName: 'province', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    selectList: []
                },
            ],
            SearchKey: 1,
            ChartKey: 1,
            classType: 1,
            mac_name: '',
            province: '',
            start: '',
            end: '',
            option: {
                title: {
                    text: ''
                },
                tooltip: {
                    formatter: function (params) {
                        const data = params.value;
                        return '名 称: ' + data[2] + '<br />' +
                            '单价(元): ' + data[1] + '<br />' +
                            '认价时间: ' + data[0];
                    }
                },
                grid: {
                    left: '3%',  // 左边留白距离
                    right: '3%', // 右边留白距离
                    top: '12%',   // 上边留白距离
                    bottom: '12%' // 下边留白距离
                },
                xAxis: {
                    type: 'time',
                    axisLabel: {
                        formatter: function (value) {
                            // 格式化时间刻度显示
                            return echarts.format.formatTime('yyyy-MM-dd', value);
                        },
                        rotate: 25, // 旋转角度
                        //margin: 8, // 刻度与轴线之间的距离
                    },
                    // data:['2023-01-01','2023-06-30','2024-01-01','2024-06-30','2025-01-01','2025-06-30']
                },
                yAxis: {
                    type: 'value',
                    name: '单价(元)'
                },
                series: [{
                    data: [],
                    type: 'scatter'
                }]
            },
            meanValue: 0
        }
    }

    componentDidMount() {
        this.get_provinceCityCare()
    }

    get_provinceCityCare = () => {
        let { TableInfo } = this.state
        provinceCityCare().then(
            (res) => {
                if (!res.code) {
                    TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '城市') {
                            item.selectList = []
                            res.data.forEach((tem, dex) => {
                                let value = {
                                    value: tem.value,
                                    label: tem.label
                                }
                                item.selectList = [...item.selectList, value]
                            })
                        }
                    })

                    this.setState(preState => ({
                        TableInfo
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_confPriceComparison = () => {
        let { classType, mac_name, start, end, province, dataSource, option, meanValue } = this.state
        let data = {
            mac_name: mac_name,
            start: start,
            end: end,
            province: province,
            class: classType,
        }
        confPriceComparison(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data[0].price.length) {
                        // option = {
                        //     title: {
                        //         text: ''
                        //     },
                        //     tooltip: {
                        //         formatter: function (params) {
                        //             const data = params.value;
                        //             return '名 称: ' + data[2] + '<br />' +
                        //                 '单价(元): ' + data[1] + '<br />' +
                        //                 '认价时间: ' + data[0];
                        //         }
                        //     },
                        //     grid: {
                        //         left: '3%',  // 左边留白距离
                        //         right: '3%', // 右边留白距离
                        //         top: '12%',   // 上边留白距离
                        //         bottom: '12%' // 下边留白距离
                        //     },
                        //     xAxis: {
                        //         type: 'time',
                        //         min: '2024-03-01',
                        //         max: '2024-05-30'
                        //     },
                        //     yAxis: {
                        //         type: 'value',
                        //         name: '单价(元)'
                        //     },
                        //     series: [{
                        //         data: [
                        //             ['2024-05-09', 20, '花箱'],
                        //             ['2024-05-10', 30, '花箱'],
                        //             ['2024-05-11', 40, '花箱'],
                        //             ['2024-05-12', 50, '花箱'],
                        //             ['2024-05-12', 60, '花箱']
                        //         ],
                        //         type: 'scatter'
                        //     }]
                        // }

                        let total_money = 0
                        let length = res.data[0].price.length
                        option.series[0].data = []
                        res.data[0].price.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.confirm_id,
                                mac_name: item.mac_name,
                                projectName: item.projectName,
                                pur_unit_price: item.pur_unit_price,
                                apply_date: item.apply_date,
                                unit: item.unit,
                                model: item.model
                            }
                            let array = []
                            array = [item.apply_date, item.pur_unit_price, item.mac_name]
                            option.series[0].data = [...option.series[0].data, array]
                            total_money = Big(Number(item.pur_unit_price)).plus(total_money);
                            dataSource = [...dataSource, value]
                        })

                        meanValue = (total_money / length).toFixed(2)

                        // // 模拟数据点的时间范围
                        // const startDate = new Date(res.data[0].price[0].apply_date);
                        // const endDate = new Date(res.data[0].price[length - 1].apply_date);

                        // // 计算数据点之间的时间间隔
                        // const dataCount = 8; // 假设有5个数据点
                        // const timeInterval = (endDate - startDate) / (dataCount - 1);

                        // option.xAxis.axisLabel.interval = 60 * 60 * 24 * 1000 * timeInterval

                        // console.log(option)


                    } else {
                        option = {
                            title: {
                                text: ''
                            },
                            tooltip: {
                                formatter: function (params) {
                                    const data = params.value;
                                    return '名 称: ' + data[2] + '<br />' +
                                        '单价(元): ' + data[1] + '<br />' +
                                        '认价时间: ' + data[0];
                                }
                            },
                            grid: {
                                left: '3%',  // 左边留白距离
                                right: '3%', // 右边留白距离
                                top: '12%',   // 上边留白距离
                                bottom: '12%' // 下边留白距离
                            },
                            xAxis: {
                                type: 'time',
                            },
                            yAxis: {
                                type: 'value',
                                name: '单价(元)'
                            },
                            series: [{
                                data: [],
                                type: 'scatter'
                            }]
                        }
                    }

                    this.setState({
                        option,
                        dataSource,
                        meanValue,
                        ChartKey: Math.random(),
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/purchase/supplier/audit/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleCancel = () => {
        this.setState({
            mac_name: '',
            province: '',
            start: '',
            end: '',
            dataSource: [],
            option: {
                title: {
                    text: ''
                },
                tooltip: {
                    formatter: function (params) {
                        const data = params.value;
                        return '名 称: ' + data[2] + '<br />' +
                            '单价(元): ' + data[1] + '<br />' +
                            '认价时间: ' + data[0];
                    }
                },
                grid: {
                    left: '3%',  // 左边留白距离
                    right: '3%', // 右边留白距离
                    top: '12%',   // 上边留白距离
                    bottom: '12%' // 下边留白距离
                },
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    type: 'value',
                    name: '单价(元)'
                },
                series: [{
                    data: [],
                    type: 'scatter'
                }]
            },
            SearchKey: Math.random(),
            ChartKey: Math.random(),
            meanValue: 0
        })
    }

    searchQuery = (value) => {
        this.setState({
            mac_name: value.mac_name,
            province: value.province,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_confPriceComparison()
        })
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
        }))
    }

    render() {
        let { dataSource, columns, classType, TableInfo, SearchKey, ChartKey, option, meanValue } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div></div>
                    <div className='supplier_right'>
                        <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>材料</div>
                        <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>劳务</div>
                        <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(3)
                        }}>机械</div>
                    </div>
                </div>

                <div className='searchForm'>
                    <Search key={SearchKey} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="search" />
                </div>

                <div style={{ width: '100%', backgroundColor: '#FFFFFF', padding: '10px 0px', marginBottom: '24px' }}>
                    <div style={{ fontSize: '28px', fontWeight: 'bold', color: '#000000', textAlign: 'center' }}>价格散点图</div>

                    <LineChart key={ChartKey} option={option} style={{ width: '100%', height: '320px' }} />

                    <div className="total_prices" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={moneyImg} alt=""></img>
                        <span>平均价格(元)</span>
                        <span>{meanValue}</span>
                    </div>
                </div>

                <Table selection={true} columns={columns} dataSource={dataSource} />
            </div>

        )
    }
}

export default withRouter(List)
