import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import { createPlants, plantDetail } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "植物引种记录表",
            TableInfo: [
                {
                    FieldTitle: '植物名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请填写植物名称', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '植物编号', //字段标题
                    fieldName: 'plant_code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请填写植物编号', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '系列编号', //字段标题
                    fieldName: 'list_code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请填写系列编号', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '引种数量', //字段标题
                    fieldName: 'plant_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '引种地', //字段标题
                    fieldName: 'address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '所属种类', //字段标题
                    fieldName: 'plant_type', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '开始日期', //字段标题
                    fieldName: 'start_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        buttonTitle: '',
        dataSource: [],
        columns: [],
        TableInfo: [],
        con_name: '',
        code: '',
        seal_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 50,
        },
        form: {},
        radio: true
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_plantDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_plantDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        plantDetail(data).then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        item.disabled = true
                    })

                    Info.state = res.data.Detail.status

                    this.NewForm.current.formRef.current.setFieldsValue({
                        start_time: res.data.Detail.start_time ? moment(res.data.Detail.start_time, 'YYYY-MM-DD') : '',
                        name: res.data.Detail.plant_name,
                        plant_code: res.data.Detail.plant_code,
                        list_code: res.data.Detail.list_code,
                        plant_number: res.data.Detail.plant_number,
                        address: res.data.Detail.address,
                        plant_type: res.data.Detail.plant_type,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.start_time = data.start_time ? data.start_time.format('YYYY-MM-DD') : ''
        form.name = data.name
        form.plant_code = data.plant_code
        form.list_code = data.list_code
        form.plant_number = data.plant_number
        form.address = data.address
        form.plant_type = data.plant_type


        createPlants(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} />
            </div>
        )
    }
}
