import React, { Component } from 'react'
import { Form, Modal, Button, Input, ConfigProvider, Table, Select, message, Upload } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import * as _ from 'lodash';
import { SearchOutlined, DoubleRightOutlined, CheckOutlined, FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import UserInput from '../../components/userInput'
import UserTextArea from '../../components/userTextArea'
import UserRadio from '../../components/userRadio'
import UserCheckbox from '../../components/userCheckbox'
import UserSelect from '../../components/userSelect'
import UserDatePicker from '../../components/userDatePicker'
import MonthDatePicker from '../../components/monthDatePicker'
import UserRangePicker from '../../components/userRangePicker'
import UserTimePicker from '../../components/userTimePicker'
import UserAttach from '../../components/userAttach'
import FileList from '../../components/fileList'
import { reviewApplyResult } from "../../utils/api"
import Icon_01 from "../../accets/img/icon_01.png"
import rigthImage from "../../accets/img/right.png"
import docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import ImageModule from 'docxtemplater-image-module-free' //这句是导出图片用的
import excess from "../../accets/img/excess.png"
import drop from "../../accets/img/drop.png"
import moneyImg from "../../accets/img/money.png"

const { TextArea } = Input;

export default class AddConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUnfold: false,
      modalTitle: '处理意见',
      modalValue: '',
      ideaModal: false,
      roleModal: false,
      selectList: [],
      form: {},
    }
  }
 
  formRef = React.createRef();
  textRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      if (this.props.Info.process.reviewAudit.length > 1) {
        const scrollbar = document.getElementById('scrollbar');
        scrollbar.scrollLeft = scrollbar.scrollWidth
      }
    }, 1000)
  }

  static getDerivedStateFromProps(props, state) {
    let { form, selectList } = state

    if (props.Info.select) {
      form.audit_id = props.Info.select.audit_id
      form.tableName = props.Info.select.tableName
      form.table_id = props.Info.select.table_id
      selectList = []
      props.Info.select.data.forEach((item, index) => {
        let value = {
          value: item.table_id,
          label: item.name
        }
        selectList = [...selectList, value]
      })



      return { roleModal: true, selectList, form }
    } else {
      return null
    }

  }

  initNode = (item) => {
    var node = null
    switch (item.fieldType) {
      case '文本':
        node = <UserInput disabled={item.disabled} placeholder={item.placeholder} fieldType='text' fileName={item.FieldTitle} InputEvent={this.handleInput} />
        break;
      case '文本域':
        node = <UserTextArea ref={this.textRef} disabled={item.disabled} placeholder={item.placeholder} />
        break;
      case '单选':
        node = <UserRadio disabled={item.disabled} radioList={item.radioList} fileName={item.FieldTitle} postEvent={this.handRadio} />
        break;
      case '多选':
        node = <UserCheckbox disabled={item.disabled} checkboxList={item.checkboxList} fileName={item.FieldTitle} postEvent={this.handChexkbox} />
        break;
      case '选择器':
        node = <UserSelect disabled={item.disabled} selectList={item.selectList} fileName={item.FieldTitle} postEvent={this.handSelect} multiple={item.multiple} />
        break;
      case '日期':
        node = <UserDatePicker disabled={item.disabled} />
        break;
      case '月份':
        node = <MonthDatePicker disabled={item.disabled} />
        break;
      case '日期区间':
        node = <UserRangePicker child={item.child} disabled={item.disabled} />
        break;
      case '日期时间':
        node = <UserDatePicker disabled={item.disabled} showTime="true" />
        break;
      case '时间区间':
        node = <UserRangePicker child={item.child} showTime="true" disabled={item.disabled} />
        break;
      case '时间':
        node = <UserTimePicker disabled={item.disabled} />
        break;
      case '附件':
        if (item.disabled) {
          node = <FileList fileValue={item.value} />
        } else {
          node = <UserAttach disabled={item.disabled} fileEvent={this.handFile} fileName={item.fieldName} fileValue={item.value} />
        }
        break;
      default:
        node = null
        break;
    }

    return node
  }

  onFinish = (values) => {
    this.handlePresent(values)
    //this.props.presentEvent(values, 2)
  };

  handleOnSubmit = () => {
    this.handlePresent(this.formRef.current.getFieldsValue())
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      return
    }
  }

  handlePresent = _.throttle((values) => {
    this.props.presentEvent(values, 2)
  }, 5000, {
    leading: true,
    trailing: false,
  });

  onSubmit = () => {
    this.props.presentEvent(this.formRef.current.getFieldsValue(), 1)
  }

  handleSubmit = () => {
    this.props.submitEvent(this.formRef.current.getFieldsValue())
  }

  handFile = (storeList, fileName, value) => {
    this.props.fileEvent(storeList, fileName, value)
  }

  handSelect = (id) => {
    this.props.selectorEvent(id)
  }

  handRadio = (id, name) => {
    this.props.radioEvent(id, name)
  }

  handChexkbox = (id) => {
    this.props.chexkBoxEvent(id)
  }

  UnfoldChange = () => {
    let { isUnfold } = this.state
    this.setState(preState => ({
      isUnfold: !isUnfold
    })
    )
  }

  handleIdea = _.throttle((value) => {
    this.handleIdeas(value)
  }, 5000, {
    leading: true,
    trailing: false,
  });

  handleIdea = (value) => {
    this.setState(preState => ({
      ideaModal: true,
      modalTitle: value === 4 ? '处理意见' : value === 3 ? '驳回意见' : '终止意见',
      modalValue: '',
      modalType: value
    })
    )
  }

  cancelIdea = () => {
    this.setState(preState => ({
      ideaModal: false
    })
    )
  }

  shutIdea = () => {
    this.setState(preState => ({
      ideaModal: false,
    })
    )
  }

  textChange = (value) => {
    this.setState(preState => ({
      modalValue: value.target.value,
    })
    )
  }

  saveIdea = () => {
    let { modalType, modalValue } = this.state
    this.setState(preState => ({
      ideaModal: false,
    }), this.props.auditEvent(modalType, modalValue))
  }

  roleEvent = () => {
    message.error('请选择下一级人员!')
  }

  selectChang = (value) => {
    let { form } = this.state
    form.flowReviewUser = value
    this.setState({
      form
    })
  }

  handleInput = (value, name) => {
    this.props.InputEvent(value, name)
  }

  handleRole = _.throttle((values) => {
    this.handleResult()
  }, 5000, {
    leading: true,
    trailing: false,
  });


  handleResult = () => {
    let { form } = this.state
    if (!form.flowReviewUser) {
      message.error('请选择下一级人员!')
      return
    }
    reviewApplyResult(form).then(
      (res) => {
        if (!res.code) {
          message.success('提交成功!')
          this.setState({
            roleModal: false,
          }, () => {
            // window.location.href = '#/backlog'
            setTimeout(() => {
              window.history.back(-1)
            })
          })
        } else {
          message.error('提交失败!')
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleExport = () => {
    let wordData = this.props.Info.tempData
    let template = this.props.Info.template
    let templateName = this.props.Info.templateName

    const base64DataURLToArrayBuffer = (dataURL) => {
      console.log(dataURL, '路径');
      const base64Regex = /^data:image\/(png|jpg|svg|jpeg|svg\+xml);base64,/;
      console.log(base64Regex.test(dataURL))
      if (!base64Regex.test(dataURL)) {
        return false;
      }
      console.log(1)
      const stringBase64 = dataURL.replace(base64Regex, "");
      let binaryString;
      if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
      } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
      }
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      console.log(bytes.buffer)
      return bytes.buffer;
    }

    JSZipUtils.getBinaryContent(template, function (error, content) {
      let opts = {
        centered: false,
        fileType: "docx",
        getImage: function (tagValue) {
          return base64DataURLToArrayBuffer(tagValue);
        },
        getSize: function () {
          return [98, 40];
        }
      }
      let imageModule = new ImageModule(opts);

      if (error) {
        throw error;
      }

      // 创建一个PizZip实例，内容为模板的内容
      let zip = new PizZip(content);
      // 创建并加载docxtemplater实例对象
      let doc = new docxtemplater()
        .attachModule(imageModule) //图片导出功能
        .loadZip(zip)
        .compile();

      // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
      doc.resolveData(wordData).then(function () {
        doc.render();
        var out = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // 将目标文件对象保存为目标类型的文件，并命名
        saveAs(out, templateName + ".docx");
      })
    });
  }

  render() {
    let { Info } = this.props;
    let { isUnfold, modalTitle, ideaModal, roleModal, selectList } = this.state

    return (
      <div>
        <Form ref={this.formRef}
          colon={false}
        // onFinish={this.onFinish}
        // onKeyDown={this.handleKeyDown}
        >
          {
            Info.state === 1 || (Info.state === 3 && Info.process.edit) || Info.state === 10 ? <div className='TableOperation'>
              {/* <Button htmlType="submit"> */}
              <Button onClick={() => {
                this.handleOnSubmit()
              }}>
                <div><CheckOutlined /></div>
                <div>提交</div>
              </Button>
              {
                Info.FlowModelName === '员工档案' || Info.state === 3 || Info.state === 10 ? '' : <Button onClick={() => {
                  this.onSubmit()
                }}>
                  <div><FileTextOutlined /></div>
                  <div>暂存</div>
                </Button>
              }

              {
                Info.isExport && Info.state === 10 ? <Button onClick={() => {
                  this.handleExport()
                }}>
                  <div><PrinterOutlined /></div>
                  <div >导出</div>
                </Button> : ''
              }

            </div> : (Info.state === 2 || Info.state === 4) && Info.process.audit ? <div className='TableProcess'>
              <Button style={{ backgroundColor: '#33CBD6' }} onClick={() => this.handleIdea(4)}>
                同意
              </Button>
              <Button style={{ backgroundColor: '#FF5757' }} onClick={() => this.handleIdea(3)}>
                驳回
              </Button>
              <Button style={{ backgroundColor: '#FFB341' }} onClick={() => this.handleIdea(5)}>
                终止
              </Button>
              {
                // Info.process.redact ? <Button style={{ backgroundColor: '#999999' }} htmlType="submit">
                Info.process.redact ? <Button style={{ backgroundColor: '#999999' }} onClick={() => {
                  this.handleOnSubmit()
                }}>
                  提交
                </Button> : ''
              }

              {
                Info.process.EditConstructionOrganizationEnclosure ? <Button style={{ backgroundColor: '#999999' }} onClick={() => this.handleSubmit()}>
                  提交
                </Button> : ''
              }
            </div> : Info.state === 2 && Info.process.selfRejectPermission ? <div className='TableProcess'>
              <Button style={{ backgroundColor: '#FF5757' }} onClick={() => this.handleIdea(3)}>
                驳回
              </Button>
            </div> : Info.isExport && Info.state === 4 ? <div className='TableOperation'>
              <Button onClick={() => {
                this.handleExport()
              }}>
                <div><PrinterOutlined /></div>
                <div >导出</div>
              </Button>
            </div> : Info.unExport && Info.state === 4 ? <div className='TableOperation'>
              <Button onClick={() => {
                this.props.exportEvent()
              }}>
                <div><PrinterOutlined /></div>
                <div >导出</div>
              </Button>
            </div> : Info.state === 2 && Info.FlowModelName === "工程过程资料上传" ? <div className='TableProcess'>
              <Button style={{ backgroundColor: '#FF5757' }} onClick={() => {
                this.props.revocationEvent()
              }}>
                驳回
              </Button>
            </div> : ''
          }
          <div className='formTitle'>
            {Info.FlowModelName}

            {
              Info.flowTips ? <span style={{ color: 'red', marginLeft: '10px' }}>{Info.flowTips}</span> : ''
            }

          </div>

          {
            Info.TableInfo.length ? Info.TableInfo.map((item, index) => {
              if (!item.isShow) {
                if (item.fieldType === '按钮') {
                  return (
                    <Form.Item style={{ width: item.width, marginRight: '80%' }}
                      key={index}
                    >
                      <div className='select_button' onClick={() => {
                        this.props.buttonEvent(item.FieldTitle, true)
                      }}>
                        <div>
                          {item.FieldTitle}
                          {
                            item.mandatory ? <span>*</span> : ''
                          }
                        </div>
                      </div>
                    </Form.Item>
                  )
                } else if (item.fieldType === '默认') {
                  if (item.search) {
                    return (
                      <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                        className={item.width === '100%' ? 'standard' : 'moiety'}
                        label={item.FieldTitle}
                        key={index}
                      >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '70%' }}>
                            <div className='defaultValue'>{item.value}</div>
                          </div>
                          <div className='searchOutlined' onClick={() => {
                            this.props.searchEvent(item.FieldTitle)
                          }}>
                            <SearchOutlined style={{ color: '#339EF9' }} />
                            <div>查看单据</div>
                          </div>
                        </div>
                      </Form.Item>
                    )
                  } else {
                    return (
                      <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                        className={item.width === '100%' ? 'standard' : 'moiety'}
                        label={item.FieldTitle}
                        key={index}
                      >
                        <div className='defaultValue'>{item.value}</div>
                      </Form.Item>
                    )
                  }
                } else if (item.fieldType === '列表') {
                  if (item.value.length) {
                    return (
                      <Form.Item label={item.FieldTitle} key={index} style={{ width: '100%' }}>
                        <div className='nameList'>
                          {
                            item.value.map((em, ex) => {
                              return (
                                <div key={ex}>{em}</div>
                              )
                            })
                          }
                        </div>
                      </Form.Item>
                    )
                  }
                } else {
                  return (
                    <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                      className={item.width === '100%' ? 'standard' : 'moiety'}
                      label={item.FieldTitle}
                      name={item.fieldName}
                      key={index}
                      initialValue={item.value}
                      rules={[
                        {
                          required: item.mandatory,
                          message: item.required
                        },
                      ]}
                    >
                      {
                        this.initNode(item)
                      }
                    </Form.Item>
                  )
                }
              }
            }) : ''
          }
          {
            Info.FirstInfo.length ? Info.FirstInfo.map((item, index) => {
              if (!item.isShow) {
                return (
                  <div key={index} style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                    {
                      item.plateName && item.calculate ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='FirstTitle' style={{ width: '40%' }}>
                          <div>
                            <div>{item.plateName}</div>
                            <div><img src={Icon_01} alt=""></img></div>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '60%', justifyContent: 'flex-end' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', color: '#333' }}>{item.all_conMoney.label}</div>
                            <div style={{ fontSize: '32px', marginLeft: '10px' }}>{item.all_conMoney.value}</div>
                          </div>
                          <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', color: '#333' }}>{item.all_total_price.label}</div>
                            <div style={{ fontSize: '32px', marginLeft: '10px' }}>{item.all_total_price.value}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                            <div style={{ fontSize: '16px', color: '#333' }}>{item.calculate.label}</div>
                            <div style={{ color: item.calculate.status ? 'red' : '#00dc9e', fontSize: '32px', margin: '0 10px' }}>{item.calculate.value}</div>
                            <img style={{ width: '15px' }} src={item.calculate.status ? excess : drop} alt=""></img>
                          </div>
                        </div>
                      </div> : item.plateName && item.export && Info.state === 4 ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='FirstTitle' style={{ width: '50%' }}>
                          <div>
                            <div>{item.plateName}</div>
                            <div><img src={Icon_01} alt=""></img></div>
                          </div>
                        </div>
                        <div className='TableOperation' style={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: 'flex-end' }}>
                          <Button onClick={() => {
                            this.props.exportEvent()
                          }}>
                            <div><PrinterOutlined /></div>
                            <div >导出</div>
                          </Button>
                        </div>
                      </div> : item.plateName ? <div className='FirstTitle'>
                        <div>
                          <div>{item.plateName}</div>
                          <div><img src={Icon_01} alt=""></img></div>
                        </div>
                      </div> : ''
                    }
                    {
                      item.list.length ? item.list.map((em, ex) => {
                        if (!em.isShow) {
                          if (em.fieldType === '按钮') {
                            return (
                              <Form.Item style={{ width: em.width, marginRight: '80%' }}
                                key={ex}
                              >
                                <div className='select_button' onClick={() => {
                                  this.props.buttonEvent(em.FieldTitle, true)
                                }}>
                                  <div>
                                    {em.FieldTitle}
                                    {
                                      em.mandatory ? <span>*</span> : ''
                                    }
                                  </div>
                                </div>
                              </Form.Item>
                            )
                          } else if (em.fieldType === '默认') {
                            if (em.search) {
                              return (
                                <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                  className={em.width === '100%' ? 'standard' : 'moiety'}
                                  label={em.FieldTitle}
                                  name={em.fieldName}
                                  key={ex}
                                  initialValue={em.value}
                                >
                                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '70%' }}>
                                      <div className='defaultValue'>{em.value}</div>
                                    </div>
                                    <div className='searchOutlined' onClick={() => {
                                      this.props.searchEvent(em.FieldTitle)
                                    }}>
                                      <SearchOutlined style={{ color: '#339EF9' }} />
                                      <div>查看单据</div>
                                    </div>
                                  </div>
                                </Form.Item>
                              )
                            } else {
                              return (
                                <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                  className={em.width === '100%' ? 'standard' : 'moiety'}
                                  label={em.FieldTitle}
                                  key={ex}
                                >
                                  <div className='defaultValue'>{em.value}</div>
                                </Form.Item>
                              )
                            }
                          } else if (em.fieldType === '列表') {
                            if (em.value.length) {
                              return (
                                <Form.Item label={em.FieldTitle} key={ex} style={{ width: '100%' }}>
                                  <div className='nameList'>
                                    {
                                      em.value.map((m, x) => {
                                        return (
                                          <div key={x}>{m}</div>
                                        )
                                      })
                                    }
                                  </div>
                                </Form.Item>
                              )
                            }
                          } else {
                            return (
                              <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                className={em.width === '100%' ? 'standard' : 'moiety'}
                                label={em.FieldTitle}
                                name={em.fieldName}
                                key={ex}
                                initialValue={em.value}
                                rules={[
                                  {
                                    required: em.mandatory,
                                    message: em.required
                                  },
                                ]}
                              >
                                {
                                  this.initNode(em)
                                }
                              </Form.Item>
                            )
                          }
                        }
                      }) : ''
                    }

                    {
                      !item.table ? '' : item.table.scroll ? <div style={{ width: '100%' }} className="form_table">
                        <Form.Item>
                          <ConfigProvider locale={zhCN}>
                            <Table pagination={false} rowClassName={(record, index) => {
                              let className = 'light-row';
                              if (index % 2 === 1) className = 'dark-row';
                              return className;
                            }}
                              style={{ width: '100%' }}
                              dataSource={item.table.dataSource}
                              columns={item.table.columns}
                              scroll={{
                                x: item.table.scrollWidth || 1800,
                              }}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </div> : <div style={{ width: '100%' }} className="form_table">
                        <Form.Item>
                          <ConfigProvider locale={zhCN}>
                            <Table pagination={false} rowClassName={(record, index) => {
                              let className = 'light-row';
                              if (index % 2 === 1) className = 'dark-row';
                              return className;
                            }}
                              style={{ width: '100%' }}
                              dataSource={item.table.dataSource}
                              columns={item.table.columns}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </div>
                    }

                    {
                      !item.editable ? '' : item.editable.scroll ? <div style={{ width: '100%' }} className="form_table">
                        {
                          Info.state === 1 || Info.state === 3 ? <div style={{
                            width: '82px',
                            backgroundColor: '#D4EBFF',
                            border: '#339EF9 solid 1px',
                            borderRadius: '8px',
                            padding: '5px 0px',
                            fontSize: '16px',
                            color: ' #339EF9',
                            fontWeight: 'bold',
                            marginBottom: '20px',
                            textAlign: 'center',
                            cursor: 'pointer'
                          }} onClick={() => { this.props.rowAddEvent(item.plateName) }}>添加</div> : ''
                        }
                        <Form.Item>

                          {
                            item.editable.summary ? <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.editable.dataSource}
                                columns={item.editable.columns}
                                scroll={{
                                  x: item.editable.scrollWidth || 1800,
                                  y: 500
                                }}
                                summary={() => (
                                  <Table.Summary.Row>
                                    {
                                      item.editable.summaryArray ? <Table.Summary.Cell index={0} colSpan={item.editable.summaryValue.colspan} className="total_prices">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <img src={moneyImg} alt=""></img>
                                          {
                                            item.editable.summaryValue.list.map((em, ex) => {
                                              return (
                                                <div key={ex}>
                                                  <span style={{ marginLeft: '30px' }}>{em.name}</span>
                                                  <span style={{ marginLeft: '15px', color: '#339EF9', fontSize: '28px' }}>{em.value}</span>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </Table.Summary.Cell> : <Table.Summary.Cell index={0} colSpan={item.editable.summaryValue.colspan} className="total_prices">
                                        <img src={moneyImg} alt=""></img>
                                        <span>{item.editable.summaryValue.name}</span>
                                        <span>{item.editable.summaryValue.value}</span>
                                      </Table.Summary.Cell>
                                    }
                                  </Table.Summary.Row>
                                )}
                              />
                            </ConfigProvider> : <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.editable.dataSource}
                                columns={item.editable.columns}
                                scroll={{
                                  x: item.editable.scrollWidth || 1800,
                                  y: 500
                                }}
                              />
                            </ConfigProvider>
                          }

                        </Form.Item>
                      </div> : <div style={{ width: '100%' }} className="form_table">
                        {
                          Info.state === 1 || Info.state === 3 ? <div style={{
                            width: '82px',
                            backgroundColor: '#D4EBFF',
                            border: '#339EF9 solid 1px',
                            borderRadius: '8px',
                            padding: '5px 0px',
                            fontSize: '16px',
                            color: ' #339EF9',
                            fontWeight: 'bold',
                            marginBottom: '20px',
                            textAlign: 'center',
                            cursor: 'pointer'
                          }} onClick={() => { this.props.rowAddEvent(item.plateName) }}>添加</div> : ''
                        }
                        <Form.Item>
                          {
                            item.editable.summary ? <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.editable.dataSource}
                                columns={item.editable.columns}
                                scroll={{
                                  y: 500
                                }}
                                summary={() => (
                                  <Table.Summary.Row>
                                    {
                                      item.editable.summaryArray ? <Table.Summary.Cell index={0} colSpan={item.editable.summaryValue.colspan} className="total_prices">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <img src={moneyImg} alt=""></img>
                                          {
                                            item.editable.summaryValue.list.map((em, ex) => {
                                              return (
                                                <div key={ex}>
                                                  <span style={{ marginLeft: '30px' }}>{em.name}</span>
                                                  <span style={{ marginLeft: '15px', color: '#339EF9', fontSize: '28px' }}>{em.value}</span>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </Table.Summary.Cell> : <Table.Summary.Cell index={0} colSpan={item.editable.summaryValue.colspan} className="total_prices">
                                        <img src={moneyImg} alt=""></img>
                                        <span>{item.editable.summaryValue.name}</span>
                                        <span>{item.editable.summaryValue.value}</span>
                                      </Table.Summary.Cell>
                                    }
                                  </Table.Summary.Row>
                                )}
                              />
                            </ConfigProvider> : <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.editable.dataSource}
                                columns={item.editable.columns}
                                scroll={{
                                  y: 500
                                }}
                              />
                            </ConfigProvider>

                          }

                        </Form.Item>
                      </div>
                    }

                    {
                      !item.imptable ? '' : item.imptable.scroll ? <div style={{ width: '100%' }} className="form_table">
                        {
                          Info.state === 1 || Info.state === 3 ? <div style={{ display: 'flex' }}>
                            <Upload name={item.imptable.uploadUrl.name}
                              action={item.imptable.uploadUrl ? item.imptable.uploadUrl.url : ''} //上传文件地址
                              headers={{
                                "Authorization": "Bearer " + localStorage.getItem("Token")
                              }}
                              data={item.imptable.uploadUrl ? item.imptable.uploadUrl.data : ''}
                              showUploadList={false}
                              onChange={(e) => {
                                this.props.uploadEvent(e)
                              }}   //上传状态改变事件

                            >
                              <div style={{
                                width: '82px',
                                backgroundColor: '#D4EBFF',
                                border: '#339EF9 solid 1px',
                                borderRadius: '8px',
                                padding: '5px 0px',
                                fontSize: '16px',
                                color: ' #339EF9',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}>导入</div>
                            </Upload>
                            <div style={{
                              width: '82px',
                              backgroundColor: '#D4EBFF',
                              border: '#339EF9 solid 1px',
                              borderRadius: '8px',
                              padding: '5px 0px',
                              fontSize: '16px',
                              color: ' #339EF9',
                              fontWeight: 'bold',
                              marginBottom: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              marginLeft: '30px'
                            }} onClick={() => {
                              this.props.ClearEvent()
                            }}>清空</div>
                          </div> : ''
                        }
                        <Form.Item>
                          {
                            item.imptable.summary ? <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.imptable.dataSource}
                                columns={item.imptable.columns}
                                scroll={{
                                  x: item.imptable.scrollWidth || 1800,
                                }}
                                summary={() => (
                                  <Table.Summary.Row>
                                    {
                                      item.imptable.summaryArray ? <Table.Summary.Cell index={0} colSpan={item.imptable.summaryValue.colspan} className="total_prices">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <img src={moneyImg} alt=""></img>
                                          {
                                            item.imptable.summaryValue.list.map((em, ex) => {
                                              return (
                                                <div key={ex}>
                                                  <span style={{ marginLeft: '30px' }}>{em.name}</span>
                                                  <span style={{ marginLeft: '15px', color: '#339EF9', fontSize: '28px' }}>{em.value}</span>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </Table.Summary.Cell> : <Table.Summary.Cell index={0} colSpan={item.imptable.summaryValue.colspan} className="total_prices">
                                        <img src={moneyImg} alt=""></img>
                                        <span>{item.imptable.summaryValue.name}</span>
                                        <span>{item.imptable.summaryValue.value}</span>
                                      </Table.Summary.Cell>
                                    }
                                  </Table.Summary.Row>
                                )}
                              />
                            </ConfigProvider> : <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.imptable.dataSource}
                                columns={item.imptable.columns}
                                scroll={{
                                  x: item.imptable.scrollWidth || 1800,
                                }}
                              />
                            </ConfigProvider>
                          }


                        </Form.Item>
                      </div> : <div style={{ width: '100%' }} className="form_table">
                        {
                          Info.state === 1 || Info.state === 3 ? <div style={{ display: 'flex' }}>
                            <Upload name={item.imptable.uploadUrl.name}
                              action={item.imptable.uploadUrl ? item.imptable.uploadUrl.url : ''} //上传文件地址
                              headers={{
                                "Authorization": "Bearer " + localStorage.getItem("Token")
                              }}
                              data={item.imptable.uploadUrl ? item.imptable.uploadUrl.data : ''}
                              showUploadList={false}
                              onChange={(e) => {
                                this.props.uploadEvent(e)
                              }}   //上传状态改变事件

                            >
                              <div style={{
                                width: '82px',
                                backgroundColor: '#D4EBFF',
                                border: '#339EF9 solid 1px',
                                borderRadius: '8px',
                                padding: '5px 0px',
                                fontSize: '16px',
                                color: ' #339EF9',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}>导入</div>
                            </Upload>
                            <div style={{
                              width: '82px',
                              backgroundColor: '#D4EBFF',
                              border: '#339EF9 solid 1px',
                              borderRadius: '8px',
                              padding: '5px 0px',
                              fontSize: '16px',
                              color: ' #339EF9',
                              fontWeight: 'bold',
                              marginBottom: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              marginLeft: '30px'
                            }} onClick={() => {
                              this.props.ClearEvent()
                            }}>清空</div>
                          </div> : ''
                        }
                        <Form.Item>
                          {
                            item.imptable.summary ? <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.imptable.dataSource}
                                columns={item.imptable.columns}
                                summary={() => (
                                  <Table.Summary.Row>
                                    {
                                      item.imptable.summaryArray ? <Table.Summary.Cell index={0} colSpan={item.imptable.summaryValue.colspan} className="total_prices">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <img src={moneyImg} alt=""></img>
                                          {
                                            item.imptable.summaryValue.list.map((em, ex) => {
                                              return (
                                                <div key={ex}>
                                                  <span style={{ marginLeft: '30px' }}>{em.name}</span>
                                                  <span style={{ marginLeft: '15px', color: '#339EF9', fontSize: '28px' }}>{em.value}</span>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </Table.Summary.Cell> : <Table.Summary.Cell index={0} colSpan={item.imptable.summaryValue.colspan} className="total_prices">
                                        <img src={moneyImg} alt=""></img>
                                        <span>{item.imptable.summaryValue.name}</span>
                                        <span>{item.imptable.summaryValue.value}</span>
                                      </Table.Summary.Cell>
                                    }
                                  </Table.Summary.Row>
                                )}
                              />
                            </ConfigProvider> : <ConfigProvider locale={zhCN}>
                              <Table pagination={false} rowClassName={(record, index) => {
                                let className = 'light-row';
                                if (index % 2 === 1) className = 'dark-row';
                                return className;
                              }}
                                style={{ width: '100%' }}
                                dataSource={item.imptable.dataSource}
                                columns={item.imptable.columns}
                              />
                            </ConfigProvider>
                          }

                        </Form.Item>
                      </div>
                    }

                    {
                      !item.amendTable ? '' : item.amendTable.scroll ? <div style={{ width: '100%' }} className="form_table">
                        <Form.Item>
                          <ConfigProvider locale={zhCN}>
                            <Table pagination={false} rowClassName={(record, index) => {
                              let className = 'light-row';
                              if (index % 2 === 1) className = 'dark-row';
                              return className;
                            }}
                              style={{ width: '100%' }}
                              dataSource={item.amendTable.dataSource}
                              columns={item.amendTable.columns}
                              scroll={{
                                x: item.amendTable.scrollWidth || 1800,
                              }}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </div> : <div style={{ width: '100%' }} className="form_table">
                        <Form.Item>
                          <ConfigProvider locale={zhCN}>
                            <Table pagination={false} rowClassName={(record, index) => {
                              let className = 'light-row';
                              if (index % 2 === 1) className = 'dark-row';
                              return className;
                            }}
                              style={{ width: '100%' }}
                              dataSource={item.amendTable.dataSource}
                              columns={item.amendTable.columns}
                            />
                          </ConfigProvider>
                        </Form.Item>
                      </div>
                    }
                  </div>
                )
              }
            }) : ''
          }
          {
            Info.state !== 1 && Info.process.reviewAudit.length ? <div style={{ width: "100%" }}>

              <div className='secondTitle'>
                <div>
                  <div>流程信息</div>
                  <div><img src={Icon_01} alt=""></img></div>
                </div>
              </div>

              {
                Info.process.reviewAudit.length ? <div className='processState'>
                  <div>
                    <div>流程状态</div>
                    {
                      Info.state === 2 ? <div className='state_button process_icon_01'>审批中</div> : Info.state === 3 ? <div className='state_button process_icon_03'>已驳回</div> :
                        Info.state === 4 || Info.state === 10 ? <div className='state_button process_icon_05'>已完成</div> : <div className='state_button process_icon_04'>已终止</div>
                    }
                    {
                      Info.process.data.InheritedFrom ? <div>
                        继承自：
                        {
                          Info.process.data.InheritedFrom
                        }
                      </div> : ''
                    }
                  </div>
                </div> : ''
              }


              {
                Info.process.reviewAudit.length ? <div className='processSteps' id="scrollbar">
                  {
                    Info.process.reviewAudit.map((item, index) => {
                      return (
                        <div key={index}>
                          <div>
                            <div>
                              {
                                index === 0 ? <div>起草人-{item.positionName}-{item.username}</div> : <div>{item.positionName}-{item.username}</div>
                              }
                              <div>
                                <div>审批状态：</div>
                                {
                                  item.review_status === '未审核' ? <div className='state_button process_icon_01'>未审核</div> : item.review_status === '已同意' ? <div className='state_button process_icon_02'>已同意</div> :
                                    item.review_status === '已驳回' ? <div className='state_button process_icon_03'>已驳回</div> : item.review_status === '已终止' ? <div className='state_button process_icon_04'>已终止</div> : <div className='state_button' style={{ textAlign: 'left' }}>/</div>
                                }
                              </div>
                              <div>
                                {
                                  index === 0 ? <div>
                                    <div>查看时间：</div>
                                    <div>/</div>
                                  </div> : <div>
                                    <div>查看时间：</div>
                                    <div>{item.read_time}</div>
                                  </div>
                                }
                                {
                                  index === 0 ? <div>
                                    <div>提交时间：</div>
                                    <div>{item.review_time}</div>
                                  </div> : <div>
                                    <div>处理时间：</div>
                                    <div>{item.review_time}</div>
                                  </div>
                                }
                                {
                                  index === 0 ? <div>
                                    <div>处理意见：</div>
                                    <div>/</div>
                                  </div> : <div>
                                    <div>处理意见：</div>
                                    <div title={item.opinion}>{item.opinion}</div>
                                  </div>
                                }
                              </div>
                            </div>

                            {
                              (Info.state === 4 || Info.state === 5 || Info.state === 10) && index === Info.process.reviewAudit.length - 1 ? '' : <img src={rigthImage} alt=""></img>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div> : ''
              }

              {
                Info.process.ccReading.length ? <div className='makeCopy'>
                  <div>
                    <div>
                      <div>抄送</div>
                      {
                        !isUnfold ? <div>
                          {
                            Info.process.ccReading.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div>{item.username}</div>
                                  {
                                    item.is_read === 1 ? <div style={{ backgroundColor: '#00DC9E' }}></div> : <div style={{ backgroundColor: '#F53500' }}></div>
                                  }
                                </div>
                              )
                            })
                          }
                        </div> : ''
                      }
                    </div>

                    {
                      !isUnfold ? <div onClick={() => this.UnfoldChange()} style={{ cursor: 'pointer' }}>
                        <DoubleRightOutlined style={{ transform: 'rotate(90deg)', marginRight: '20px' }} />
                        <div>展开</div>
                      </div> : <div onClick={() => this.UnfoldChange()} style={{ cursor: 'pointer' }}>
                        <DoubleRightOutlined style={{ transform: 'rotate(-90deg)', marginRight: '20px' }} />
                        <div>收起</div>
                      </div>
                    }
                  </div>

                  {
                    isUnfold ? <div>
                      {
                        Info.process.ccReading.map((item, index) => {
                          return (
                            <div key={index}>
                              <div>
                                <div>{item.username}</div>
                                <div>
                                  <div>
                                    查看时间：
                                  </div>
                                  <div>
                                    {item.updated_at ? item.updated_at : '/'}
                                  </div>
                                </div>
                                {
                                  item.is_read === 1 ? <div style={{ backgroundColor: '#00DC9E' }}></div> : <div style={{ backgroundColor: '#F53500' }}></div>
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div> : ''
                  }

                </div> : ''
              }

            </div> : ''
          }
        </Form >

        <Modal
          title={modalTitle}
          centered
          visible={ideaModal}
          onOk={() => this.saveIdea()}
          onCancel={() => this.cancelIdea()}
          okText="确认"
          cancelText="取消"
          wrapClassName='userModal'
        >
          <TextArea onChange={(e) => this.textChange(e)} />
        </Modal>

        <Modal
          title="指定下级审核人员"
          centered
          visible={roleModal}
          // onOk={() => this.handleRole()}
          onCancel={() => this.roleEvent()}
          // okText="确认"
          // cancelText="取消"
          wrapClassName='userModal'
          footer={[
            <Button type="primary" onClick={() => this.handleRole()}>确认</Button>
          ]}
        >
          <Select
            style={{ width: '324px' }}
            options={selectList}
            onChange={(e) => {
              this.selectChang(e)
            }}
          //suffixIcon={<CaretDownOutlined />}
          >
          </Select>
          <div style={{ color: 'red', fontSize: '12px', marginTop: '10px', lineHeight: '20px' }}>注：如若不清楚如何选择下一级审批人，保持该浏览器状态，在确定好审批人后再点击提交，<span style={{ fontSize: '18px' }}>请勿直接关闭浏览器！！！</span></div>
        </Modal>
      </div >
    )
  }
}
