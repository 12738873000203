import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { applySeal, getSealAuxiliary, lookApplyContent } from "../../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/sealEngraving.docx',
            templateName: '四川天艺生态园林集团股份有限公司印章刻制申请审批',
            tempData: {
                code: '',
                apply_date: '',
                user_name: '',
                save_user: '',
                person_user: '',
                class: '',
                name: '',
                start_day: '',
                reason: '',
                results: []
            },
            FlowModelName: "印章刻制申请",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '申请',
                    list: [
                        {
                            FieldTitle: '类型', //字段标题
                            fieldName: 'class', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择类型', //必填要求
                            value: '',
                            width: '50%',
                            selectList: []
                        },
                        {
                            FieldTitle: '印章名称', //字段标题
                            fieldName: 'name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入印章名称', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '印章编号', //字段标题
                            fieldName: 'code', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '选择保管人', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '保管人', //字段标题
                            fieldName: 'save_user', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                    ],
                },
                {
                    plateName: '刻制',
                    list: [
                        {
                            FieldTitle: '选择负责人', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '负责人', //字段标题
                            fieldName: 'person_user', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '印鉴', //字段标题
                            fieldName: 'stamp', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                },
                {
                    plateName: '领用',
                    list: [
                        {
                            FieldTitle: '启用日期', //字段标题
                            fieldName: 'start_day', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '刻制原因', //字段标题
                            fieldName: 'reason', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '回执', //字段标题
                            fieldName: 'receipt', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        buttonTitle: '',
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_SealAuxiliary()
    }

    get_SealAuxiliary = () => {
        let { Info } = this.state
        getSealAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '类型') {
                            for (let key in res.data.class) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.class[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_lookApplyContent(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_lookApplyContent = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        lookApplyContent(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.sealApply.status === 1 || res.data.sealApply.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.sealApply.editPermission
                    } else {
                        if (res.data.sealApply.status === 4) {
                            Info.tempData.code = res.data.sealApply.code
                            Info.tempData.apply_date = res.data.sealApply.created_at
                            Info.tempData.user_name = res.data.sealApply.apply_userName
                            Info.tempData.save_user = res.data.sealApply.save_username
                            Info.tempData.person_user = res.data.sealApply.person_username
                            Info.tempData.class = res.data.sealApply.class === 1 ? '公章' : res.data.sealApply.class === 2 ? '项目资料章' : res.data.sealApply.class === 3 ? '财务章' : res.data.sealApply.class === 4 ? '私章' : res.data.sealApply.class === 5 ? '发票章' : '其他'
                            Info.tempData.name = res.data.sealApply.name
                            Info.tempData.start_day = res.data.sealApply.start_day
                            Info.tempData.reason = res.data.sealApply.reason
                            Info.tempData.results = []

                            res.data.sealApply.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                                if (em.FieldTitle === '选择保管人') {
                                    em.isShow = true
                                } else if (em.FieldTitle === '选择负责人') {
                                    em.isShow = true
                                }
                            })
                        })
                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (!res.data.sealApply.stamp.length) {
                                if (item.FieldTitle === '印鉴') {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            if (!res.data.sealApply.receipt.length) {
                                if (item.FieldTitle === '回执') {
                                    item.isShow = true
                                }
                            } else if (!res.data.sealApply.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.sealApply.status

                    Info.process.data = res.data.sealApply.reviewAudit
                    Info.process.reviewAudit = res.data.sealApply.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.sealApply.reviewAudit.ccReading
                    Info.process.audit = res.data.sealApply.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.sealApply.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '保管人') {
                            form.save_user = res.data.sealApply.save_user
                            item.value = res.data.sealApply.save_username
                        }
                    })

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        if (item.FieldTitle === '印鉴') {
                            item.value = res.data.sealApply.stamp.length ? res.data.sealApply.stamp : ''
                        } else if (item.FieldTitle === '负责人') {
                            form.person_user = res.data.sealApply.person_user
                            item.value = res.data.sealApply.person_username
                        }
                    })

                    Info.FirstInfo[2].list.forEach((item, index) => {
                        if (item.FieldTitle === '回执') {
                            item.value = res.data.sealApply.receipt.length ? res.data.sealApply.receipt : ''
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.sealApply.enclosure.length ? res.data.sealApply.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        code: res.data.sealApply.code,
                        class: res.data.sealApply.class,
                        name: res.data.sealApply.name,
                        stamp: res.data.sealApply.stamp,
                        start_day: res.data.sealApply.start_day ? moment(res.data.sealApply.start_day, 'YYYY-MM-DD') : '',
                        reason: res.data.sealApply.reason,
                        receipt: res.data.sealApply.receipt,
                        enclosure: res.data.sealApply.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form } = this.state;
        form.status = status
        form.code = data.code
        form.class = data.class
        form.name = data.name
        form.start_day = data.start_day ? data.start_day.format('YYYY-MM-DD') : ''
        form.reason = data.reason

        applySeal(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    message.error(res.data[0] || '提交失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.fieldName === fileName) {
                        if (em.value && em.value.length) {
                            em.value.forEach((m, x) => {
                                storeList.forEach((j, k) => {
                                    if (m.table_id === j) {
                                        fileList.push(m)
                                    }
                                })
                            })
                            em.value = fileList
                        } else {
                            em.value = fileValue
                        }
                    }
                })
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
            this.setState(preState => ({
                buttonTitle: title,
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        console.log(value)
        let { Info, form, buttonTitle } = this.state
        console.log(buttonTitle)
        if (buttonTitle === '选择保管人') {
            form.save_user = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '保管人') {
                    item.value = value[0].username
                }
            })
        } else {
            console.log(0)
            form.person_user = value[0].key
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '负责人') {
                    item.value = value[0].username
                }
            })
        }
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
