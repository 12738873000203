import React, { Component } from 'react'
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';


export default class UserSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { disabled, selectList, onChange, value, fileName, postEvent, multiple } = this.props;
        return (
            <div>
                <Select
                    mode={multiple ? 'tags' : 'multiple '}
                    disabled={disabled}
                    options={selectList}
                    onChange={(e) => {
                        onChange(e)
                        if (fileName === '部门' || fileName === '补卡原因' || fileName === '合同性质' || fileName === '费用类型' || fileName === '省份' || fileName === '取票方式' || fileName === '文件类型' || fileName === '客户分类' || fileName === '成果类型' || fileName === '申报结果' || fileName === '缴纳方式'|| fileName === '项目类别'|| fileName === '通知类型') {
                            postEvent(e)
                        }
                    }}
                    value={value}
                //suffixIcon={<CaretDownOutlined type='home'/>}
                >
                </Select>
            </div>
        )
    }
}
