import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../../components/AddConfig'
import { createEmploymentApplication, employmentApplicationDetail } from "../../../../utils/oa"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "用工申请",
            TableInfo: [{
                FieldTitle: '发起人', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '发起人部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '职位名称', //字段标题
                fieldName: 'job_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入职位名称', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '工作年限', //字段标题
                fieldName: 'work_year', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入工作年限', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '岗位职责', //字段标题
                fieldName: 'job_duty', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入岗位职责', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '任职要求', //字段标题
                fieldName: 'job_requirements', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入任职要求', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '学历', //字段标题
                fieldName: 'education', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入学历要求', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '年龄', //字段标题
                fieldName: 'age', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '性别', //字段标题
                fieldName: 'sex', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '人数', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ApplicationDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info, form } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '发起人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '发起人部门') {
                item.value = userInfo.deName
            }
        })
        form.launch_user = userInfo.userId
        Info.state = 1
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    get_ApplicationDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        employmentApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '发起人') {
                            item.value = res.data.Detail.launchUsername
                        } else if (item.FieldTitle === '发起人部门') {
                            item.value = res.data.Detail.launchDeName
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        job_name: res.data.Detail.job_name,
                        work_year: res.data.Detail.work_year,
                        job_duty: res.data.Detail.job_duty,
                        job_requirements: res.data.Detail.job_requirements,
                        education: res.data.Detail.education,
                        age: res.data.Detail.age,
                        sex: res.data.Detail.sex,
                        number: res.data.Detail.number,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.job_name = data.job_name
        form.job_duty = data.job_duty
        form.job_requirements = data.job_requirements
        form.work_year = data.work_year
        form.education = data.education
        form.age = data.age
        form.sex = data.sex
        form.number = data.number

        createEmploymentApplication(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} auditEvent={this.handleButton} />
            </div>
        )
    }
}
