import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree, message, Form, Input, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { withRouter } from "react-router-dom";
import { revenueMain } from "../../../utils/market"
import { addSetPayMoney, projectCollection, macContractSupplier } from "../../../utils/api"
import UserRadio from '../../../components/userRadio'
import InitialValue from '../../components/InitialValue'

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"
import Icon_01 from "../../../accets/img/icon_01.png"
import moneyImg from "../../../accets/img/money.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pro_id: '',
            sup_class: '',
            sup_id: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            Info: {
                FlowModelName: "项目付款",
                TableInfo: [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'pro_manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '供应商类别', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '材料'
                            },
                            {
                                value: 2,
                                label: '劳务'
                            },
                            {
                                value: 3,
                                label: '机械'
                            }, {
                                value: 4,
                                label: '设计'
                            }
                        ]
                    },
                    {
                        FieldTitle: '选择供应商', //字段标题
                        fieldType: '按钮',  //字段类型
                    },
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'pro_manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                ],
                FirstInfo: [
                    {
                        plateName: '进度结算付款',
                        editable: {
                            dataSource: [],
                            columns: [
                                {
                                    title: '',
                                    dataIndex: 'key',
                                    key: 'key',
                                },
                                {
                                    title: '供应商名称',
                                    dataIndex: 'sup_name',
                                    key: 'sup_name',
                                },
                                {
                                    title: '结算时间',
                                    dataIndex: 'set_date',
                                    key: 'set_date',
                                },
                                {
                                    title: '结算金额',
                                    dataIndex: 'set_money',
                                    key: 'set_money',
                                },
                                // {
                                //     title: '实际付款金额',
                                //     dataIndex: 'money',
                                //     key: 'money',
                                //     render: (text, record, index) =>
                                //         <Form.Item name={'money_set' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'prog')}>
                                //             <Input />
                                //         </Form.Item>
                                // },
                            ],
                            summary: {
                                colspan: 5,
                                name: '总进度款支付金额(元)',
                                name_02: '总实际付款金额(元)',
                                value: '',
                                value_02: ''
                            },
                            scroll: false,
                            scrollWidth: 0
                        }
                    },
                    {
                        plateName: '结算付款',
                        editable: {
                            dataSource: [],
                            columns: [
                                {
                                    title: '',
                                    dataIndex: 'key',
                                    key: 'key',
                                },
                                {
                                    title: '供应商名称',
                                    dataIndex: 'sup_name',
                                    key: 'sup_name',
                                },
                                {
                                    title: '结算时间',
                                    dataIndex: 'set_date',
                                    key: 'set_date',
                                },
                                {
                                    title: '结算金额',
                                    dataIndex: 'set_money',
                                    key: 'set_money',
                                },
                                // {
                                //     title: '实际付款金额',
                                //     dataIndex: 'money',
                                //     key: 'money',
                                //     render: (text, record, index) =>
                                //         <Form.Item name={'money_prog' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'set')}>
                                //             <Input />
                                //         </Form.Item>
                                // },
                            ],
                            summary: {
                                colspan: 5,
                                name: '总实际付款金额(元)',
                                value: ''
                            },
                            scroll: false,
                            scrollWidth: 0
                        }
                    }
                ]
            },
            modal_Visible: false,
            sup_name: '',
            dataSource: [],
            columns: [
                {
                    title: '供应商类别',
                    dataIndex: 'sup_class',
                    key: 'sup_class',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '联系人',
                    dataIndex: 'sup_contacts',
                    key: 'sup_contacts',
                },
                {
                    title: '联系电话',
                    dataIndex: 'sup_phone',
                    key: 'sup_phone',
                },
                {
                    title: '付款方式',
                    dataIndex: 'sup_payModel',
                    key: 'sup_payModel',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '供应商', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                },
            ],
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            FlowchartKey: 1
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (this.props.location.query) {
                        pro_id = this.props.location.query.tableId
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            item.children.forEach((em, ex) => {
                                em.children.forEach((m, x) => {
                                    if (m.key === pro_id) {
                                        selectedKeys.push(m.key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            })
                        })
                    } else {
                        if (!pro_id) {
                            selectedKeys = []
                            expandedKeys = ['1']
                            treeData.forEach((item, index) => {
                                if (index === 0) {
                                    item.children.forEach((em, ex) => {
                                        if (ex === 0) {
                                            pro_id = em.children[0].key
                                            selectedKeys.push(em.children[0].key)
                                            expandedKeys.push(em.key)
                                        }
                                    })
                                }
                            })
                        }
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_projectCollection()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    initNode = (item) => {
        var node = null
        switch (item.fieldType) {
            case '单选':
                node = <UserRadio disabled={item.disabled} radioList={item.radioList} fileName={item.FieldTitle} postEvent={this.handRadio} />
                break;
            default:
                node = null
                break;
        }

        return node
    }

    get_projectCollection = () => {
        const { Info, pro_id } = this.state;
        let data = {
            project_id: pro_id
        }
        projectCollection(data).then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.project.project_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.project.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.project.pro_manager
                        }
                    })

                    this.setState(preState => ({
                        Info
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_macContractSupplier = () => {
        let { dataSource, pro_id, sup_class, sup_name, sup_id } = this.state;
        let data = {
            project_id: pro_id,
            class: sup_class,
            sup_name: sup_name
        }
        macContractSupplier(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.machinery_ids.length) {
                        res.data.machinery_ids.forEach((item, index) => {
                            let value = {
                                key: item.table_id,
                                sup_name: item.sup_supName,
                                sup_id: item.sup_id,
                                sup_class: item.sup_class,
                                sup_contacts: item.sup_contacts,
                                sup_phone: item.sup_phone,
                                sup_payModel: item.sup_payModel,
                                suppliers: item.suppliers
                            }
                            dataSource = [...dataSource, value]
                        })
                        sup_id = res.data.machinery_ids[0].sup_id
                    }
                    this.setState(preState => ({
                        dataSource,
                        sup_id,
                        modal_Visible: true
                    })
                    )
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    InputChange = (e, id, type) => {
        let { Info, sup_id, pro_id } = this.state
        let data = {
            table_id: id,
            sup_id: sup_id,
            project_id: pro_id,
            money: e.target.value,
            type: type
        }
        addSetPayMoney(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功!')
                    if (type === 'prog') {
                        let dataIndex = 'money' + (Info.FirstInfo[0].editable.columns.length - 4)
                        let title = {
                            title: '实际付款' + (Info.FirstInfo[0].editable.columns.length - 4),
                            dataIndex: dataIndex,
                            key: dataIndex,
                            render: (text, record, index) => {
                                return (
                                    <div>
                                        <div>{e.target.value}</div>
                                        <div>/</div>
                                    </div>
                                )
                            }
                        }

                        let dex = Info.FirstInfo[0].editable.columns.length - 1
                        Info.FirstInfo[0].editable.columns.splice(dex, 0, title)

                        Info.FirstInfo[0].editable.summary.colspan = Info.FirstInfo[0].editable.columns.length
                        Info.FirstInfo[0].editable.summary.value_02 = Number(Info.FirstInfo[0].editable.summary.value_02) + Number(e.target.value)
                        if (Info.FirstInfo[0].editable.columns.length > 6) {
                            Info.FirstInfo[0].editable.scroll = true

                            Info.FirstInfo[0].editable.scrollWidth = 200 * Info.FirstInfo[0].editable.columns.length
                        }
                    } else if (type === 'set') {
                        let dataIndex = 'money' + (Info.FirstInfo[1].editable.columns.length - 4)
                        let title = {
                            title: '实际付款' + (Info.FirstInfo[1].editable.columns.length - 4),
                            dataIndex: dataIndex,
                            key: dataIndex,
                            render: (text, record, index) => {
                                return (
                                    <div>
                                        <div>{e.target.value}</div>
                                        <div>/</div>
                                    </div>
                                )
                            }
                        }

                        let dex = Info.FirstInfo[1].editable.columns.length - 1
                        Info.FirstInfo[1].editable.columns.splice(dex, 0, title)

                        Info.FirstInfo[1].editable.summary.colspan = Info.FirstInfo[1].editable.columns.length
                        Info.FirstInfo[1].editable.summary.value = Number(Info.FirstInfo[1].editable.summary.value) + Number(e.target.value)
                        if (Info.FirstInfo[1].editable.columns.length > 6) {
                            Info.FirstInfo[1].editable.scroll = true

                            Info.FirstInfo[1].editable.scrollWidth = 200 * Info.FirstInfo[1].editable.columns.length
                        }
                    }

                    this.setState(preState => ({
                        Info,
                        FlowchartKey: Math.random(),
                    })
                    )

                } else {
                    message.error(res.data[0] || '保存失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_macContractSupplier()
    }

    handleClose = () => {
        this.setState(preState => ({
            sup_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handRadio = (value) => {
        console.log(value)
        this.setState(preState => ({
            sup_class: value
        })
        )
    }

    handSelect = (value) => {
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '供应商名称') {
                item.value = value[0].sup_name
            }
        })

        Info.FirstInfo[0].editable.summary.value = value[0].suppliers.progressTotalMoney
        Info.FirstInfo[0].editable.summary.value_02 = value[0].suppliers.progressPayMoney
        Info.FirstInfo[1].editable.summary.value = value[0].suppliers.setTotalMoney

        Info.FirstInfo[0].editable.dataSource = []
        if (value[0].suppliers.ProgressArray.length) {
            Info.FirstInfo[0].editable.columns = [
                {
                    title: '',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '结算时间',
                    dataIndex: 'set_date',
                    key: 'set_date',
                },
                {
                    title: '结算金额',
                    dataIndex: 'set_money',
                    key: 'set_money',
                },
            ]
            value[0].suppliers.ProgressArray.forEach((item, index) => {
                let values = {
                    key: index + 1,
                    id: item.proTable_id,
                    sup_name: value[0].suppliers.sup_name,
                    set_date: item.created_at,
                    set_money: item.month_pay,
                }

                if (item.payedMoney || item.payedMoney.length) {
                    item.payedMoney.forEach((em, ex) => {
                        let dataIndex = 'money' + (ex + 1)
                        let title = {
                            title: '实际付款' + (ex + 1),
                            dataIndex: dataIndex,
                            key: dataIndex,
                            render: (text, record, index) => {
                                return (
                                    <div>
                                        <div>{text[0]}</div>
                                        <div>{text[1]}</div>
                                    </div>
                                )
                            }
                        }
                        values[dataIndex] = [em.money, em.created_at]
                        Info.FirstInfo[0].editable.columns = [...Info.FirstInfo[0].editable.columns, title]
                    })

                    let title = {
                        title: '实际付款金额',
                        dataIndex: 'money',
                        key: 'money',
                        render: (text, record, index) =>
                            <Form.Item name={'money_prog' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'prog')}>
                                <Input />
                            </Form.Item>
                    }
                    values.money = ''
                    Info.FirstInfo[0].editable.columns = [...Info.FirstInfo[0].editable.columns, title]
                }


                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, values]

                Info.FirstInfo[0].editable.summary.colspan = Info.FirstInfo[0].editable.columns.length
                if (Info.FirstInfo[0].editable.columns.length > 6) {
                    Info.FirstInfo[0].editable.scroll = true

                    Info.FirstInfo[0].editable.scrollWidth = 200 * Info.FirstInfo[0].editable.columns.length
                }

            })
        }

        Info.FirstInfo[1].editable.dataSource = []
        if (value[0].suppliers.SettlementArray.length) {
            Info.FirstInfo[1].editable.columns = [
                {
                    title: '',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '结算时间',
                    dataIndex: 'set_date',
                    key: 'set_date',
                },
                {
                    title: '结算金额',
                    dataIndex: 'set_money',
                    key: 'set_money',
                },
            ]
            value[0].suppliers.SettlementArray.forEach((item, index) => {
                let values = {
                    key: index + 1,
                    id: item.setTable_id,
                    sup_name: value[0].suppliers.sup_name,
                    set_date: item.created_at,
                    set_money: item.set_money,
                }

                if (item.setPayedMoney || item.setPayedMoney.length) {
                    item.setPayedMoney.forEach((em, ex) => {
                        let dataIndex = 'money' + (ex + 1)
                        let title = {
                            title: '实际付款' + (ex + 1),
                            dataIndex: dataIndex,
                            key: dataIndex,
                            render: (text, record, index) => {
                                return (
                                    <div>
                                        <div>{text[0]}</div>
                                        <div>{text[1]}</div>
                                    </div>
                                )
                            }
                        }
                        values[dataIndex] = [em.money, em.created_at]
                        Info.FirstInfo[1].editable.columns = [...Info.FirstInfo[1].editable.columns, title]
                    })

                    let title = {
                        title: '实际付款金额',
                        dataIndex: 'money',
                        key: 'money',
                        render: (text, record, index) =>
                            <Form.Item name={'money_set' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'set')}>
                                <Input />
                            </Form.Item>
                    }
                    values.money = ''
                    Info.FirstInfo[1].editable.columns = [...Info.FirstInfo[1].editable.columns, title]
                }


                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, values]

                Info.FirstInfo[1].editable.summary.colspan = Info.FirstInfo[1].editable.columns.length
                if (Info.FirstInfo[1].editable.columns.length > 6) {
                    Info.FirstInfo[1].editable.scroll = true

                    Info.FirstInfo[1].editable.scrollWidth = 200 * Info.FirstInfo[1].editable.columns.length
                }
            })
        }

        this.setState(preState => ({
            Info,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        this.setState({
            sup_name: value.sup_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_macContractSupplier()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            sup_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_macContractSupplier()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_projectCollection()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { treeData, expandedKeys, selectedKeys, Info, modal_Visible, dataSource, columns, TableInfo, pages, FlowchartKey } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div style={{ backgroundColor: '#FFFFFF', padding: '20px', borderRadius: '16px' }}>
                    <Form ref={this.formRef}
                        onFinish={this.onFinish}
                        key={FlowchartKey}
                    >
                        <div className='formTitle'>{Info.FlowModelName}</div>

                        {
                            Info.TableInfo.length ? Info.TableInfo.map((item, index) => {
                                if (item.fieldType === '按钮') {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: '80%' }}
                                            key={index}
                                        >
                                            <div className='select_button' onClick={() => {
                                                this.handleButton()
                                            }}>
                                                <div>
                                                    {item.FieldTitle}
                                                    <span>*</span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    )
                                } else if (item.fieldType === '默认') {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                            className={item.width === '100%' ? 'standard' : 'moiety'}
                                            label={item.FieldTitle}
                                            key={index}
                                        >
                                            <div className='defaultValue'>{item.value}</div>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                            className={item.width === '100%' ? 'standard' : 'moiety'}
                                            label={item.FieldTitle}
                                            name={item.fieldName}
                                            key={index}
                                            initialValue={item.value}
                                        >
                                            {
                                                this.initNode(item)
                                            }
                                        </Form.Item>
                                    )
                                }
                            }) : ''
                        }
                        {
                            Info.FirstInfo.length ? Info.FirstInfo.map((item, index) => {
                                return (
                                    <div key={index} style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            item.plateName ? <div className='FirstTitle'>
                                                <div>
                                                    <div>{item.plateName}</div>
                                                    <div><img src={Icon_01} alt=""></img></div>
                                                </div>
                                            </div> : ''
                                        }

                                        {
                                            item.editable.scroll ? <div style={{ width: '100%' }} className="form_table">
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                            scroll={{
                                                                x: item.editable.scrollWidth || 1500,
                                                            }}
                                                            summary={() => (
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0} colSpan={item.editable.summary.colspan} className="total_prices total_price" >
                                                                        <img src={moneyImg} alt=""></img>
                                                                        <span>{item.editable.summary.name}</span>
                                                                        <span>{item.editable.summary.value}</span>
                                                                        {
                                                                            item.editable.summary.name_02 ? <span>{item.editable.summary.name_02}</span> : ''
                                                                        }
                                                                        {
                                                                            item.editable.summary.name_02 ? <span>{item.editable.summary.value_02}</span> : ''
                                                                        }
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div> : <div style={{ width: '100%' }} className="form_table">
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                            summary={() => (
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0} colSpan={item.editable.summary.colspan} className="total_prices total_price" >
                                                                        <img src={moneyImg} alt=""></img>
                                                                        <span>{item.editable.summary.name}</span>
                                                                        <span>{item.editable.summary.value}</span>
                                                                        {
                                                                            item.editable.summary.name_02 ? <span>{item.editable.summary.name_02}</span> : ''
                                                                        }
                                                                        {
                                                                            item.editable.summary.name_02 ? <span>{item.editable.summary.value_02}</span> : ''
                                                                        }
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            )}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                )
                            }) : ''
                        }

                    </Form >

                    <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handleClose} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
