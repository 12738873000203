import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { Tree } from 'antd';
import Trees from './Tree'
import Shrub from './Shrub'
import Stone from './Stone'
import Pipe from './Pipe'
import Folder from "../../../accets/img/folder.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => (
    <img src={Folder} alt=""></img>
);

const FileImage = () => (
    <img src={File} alt=""></img>
);


const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: '乔木',
            treeData: [
                {
                    title: '集采大数据',
                    key: '0-0',
                    icon: <FolderIcon />,
                    children: [
                        {
                            title: '乔木',
                            key: '0-0-1',
                            icon: <FileIcon />
                        },
                        {
                            title: '灌木',
                            key: '0-0-2',
                            icon: <FileIcon />
                        },
                        {
                            title: '石材',
                            key: '0-0-3',
                            icon: <FileIcon />
                        },
                        {
                            title: '管材',
                            key: '0-0-4',
                            icon: <FileIcon />
                        },
                    ],
                }
            ],
            expandedKeys: ['0-0'],
            selectedKeys: ['0-0-1'],
        }
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            type: value[0] === '0-0-1' ? '乔木' : value[0] === '0-0-2' ? '灌木' : value[0] === '0-0-3' ? '石材' : '管材'
        }))
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { treeData, expandedKeys, selectedKeys } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>

                {
                    this.state.type === '乔木' ? <Trees /> : this.state.type === '灌木' ? <Shrub /> : this.state.type === '石材' ? <Stone /> : <Pipe />
                }
            </div>
        )
    }
}

export default withRouter(List)
