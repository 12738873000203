import React, { Component } from 'react'
import { Input, Form, message } from 'antd';
import AddConfig from '../../../components/AddConfig'
import { createEquipmentPurchase, equipmentDetail } from "../../../../utils/oa"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "行政设备购置申请",
            TableInfo: [
                {
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'user_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '申请部门', //字段标题
                    fieldName: 'deName', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
            ],
            FirstInfo: [
                {
                    plateName: '购置设备信息',
                    list: [],
                    editable: {
                        scroll:true,
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '设备名称',
                                dataIndex: 'pur_name',
                                key: 'pur_name',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'pur_name' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'pur_name' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '设备用途',
                                dataIndex: 'use_fun',
                                key: 'use_fun',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'use_fun' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'use_fun' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '金额',
                                dataIndex: 'amount',
                                key: 'amount',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '数量',
                                dataIndex: 'number',
                                key: 'number',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]
                    }
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }


    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_equipmentDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '申请部门') {
                item.value = userInfo.jobName
            } else if (item.FieldTitle === '申请日期') {
                item.value = new Date().toLocaleDateString()
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_equipmentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        equipmentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo[0].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '设备名称',
                                dataIndex: 'pur_name',
                                key: 'pur_name',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'pur_name' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'pur_name' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '设备用途',
                                dataIndex: 'use_fun',
                                key: 'use_fun',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'use_fun' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'use_fun' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '金额',
                                dataIndex: 'amount',
                                key: 'amount',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'amount' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '数量',
                                dataIndex: 'number',
                                key: 'number',
                                width:'200px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '申请部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '申请日期') {
                            item.value = res.data.Detail.created_at
                        }
                    })

                    Info.FirstInfo[0].editable.dataSource = []
                    if (res.data.Detail.details) {
                        res.data.Detail.details.forEach((item, index) => {
                            let key = Info.FirstInfo[0].editable.dataSource.length + 1
                            const newData = {
                                key: key,
                                pur_name: item.pur_name,
                                use_fun: item.use_fun,
                                model: item.model,
                                amount: item.amount,
                                number: item.number,
                                info: item.info,
                            };
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'pur_name' + key)()]: item.pur_name,
                                [(() => 'use_fun' + key)()]: item.use_fun,
                                [(() => 'model' + key)()]: item.model,
                                [(() => 'amount' + key)()]: item.amount,
                                [(() => 'number' + key)()]: item.number,
                                [(() => 'info' + key)()]: item.info,
                            })
                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    }

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                } else {
                    message.error(res.data[0] || '数据获取失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.class = 2
        form.eq_details = Info.FirstInfo[0].editable.dataSource

        form.eq_details.forEach((item, index) => {
            item.pur_name = data['pur_name' + item.key] ? data['pur_name' + item.key] : ''
            item.use_fun = data['use_fun' + item.key] ? data['use_fun' + item.key] : ''
            item.model = data['model' + item.key] ? data['model' + item.key] : ''
            item.amount = data['amount' + item.key] ? data['amount' + item.key] : ''
            item.number = data['number' + item.key] ? data['number' + item.key] : ''
            item.info = data['info' + item.key] ? data['info' + item.key] : ''
        })


        form.eq_details = JSON.stringify(form.eq_details)

        createEquipmentPurchase(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    window.history.back(-1)
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        if (Info.FirstInfo[0].editable.dataSource.length) {
            let key = Info.FirstInfo[0].editable.dataSource.length - 1
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                pur_name: '',
                use_fun: '',
                model: '',
                amount: '',
                number: '',
                info: ''
            };
            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        } else {
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                pur_name: '',
                use_fun: '',
                model: '',
                amount: '',
                number: '',
                info: ''
            };
            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        }
        this.setState({
            Info
        });
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'pur_name' + e)()]: '',
            [(() => 'use_fun' + e)()]: '',
            [(() => 'model' + e)()]: '',
            [(() => 'amount' + e)()]: '',
            [(() => 'number' + e)()]: '',
            [(() => 'info' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }
    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} auditEvent={this.handleButton} />
            </div>
        )
    }
}
