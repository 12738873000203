import React, { Component } from 'react'
import { message, Checkbox, Button } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { JobRolePermission, jobBindPermission } from "../../../utils/public"

class Authority extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkEdit: true,
            dataSource: [],
            columns: [
                {
                    title: '功能',
                    dataIndex: 'name',
                    key: 'name',
                    width:'240px'
                },
                {
                    title: '权限值',
                    dataIndex: 'permissions',
                    key: 'permissions',
                    render: (text, record, index) => {
                        return (
                            <Checkbox.Group disabled={this.state.checkEdit} options={text} defaultValue={record.defaultValue} onChange={(e) => this.selectChange(e, index)} />
                        )
                    }

                },
            ],
            permission: [],
            job_id: '',
            tableKey: 1,
            pages: {
                limit: 200,
                page: 1,
                total: 0
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_JobRolePermission(tableId)
        }
    }

    get_JobRolePermission = (tableId) => {
        let { dataSource } = this.state
        let data = {
            job_id: tableId
        }
        JobRolePermission(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    res.data.roles.forEach((item, index) => {
                        let value = {
                            key: index + 1,
                            name: item.name,
                            defaultValue: res.data.job_permission,
                            permissions: [],
                        }

                        item.permissions.forEach((iem, dex) => {
                            let pro = {
                                value: iem.id,
                                label: iem.pre_name
                            }
                            value.permissions = [...value.permissions, pro]
                        })

                        dataSource = [...dataSource, value]
                    });
                    this.setState(preState => ({
                        dataSource,
                        permission: res.data.job_permission,
                        job_id: tableId
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    selectChange = (value, index) => {
        let { dataSource, permission } = this.state
        permission.forEach((item, dex) => {
            dataSource[index].permissions.forEach((em, ex) => {
                if (item === em.value) {
                    permission.splice(dex, 1)
                }
            })
        })
        permission = [...permission, ...value]

        this.setState(preState => ({
            permission
        })
        )
    }

    handleSave = () => {
        let { permission, job_id } = this.state
        let data = {
            job_id: job_id,
            permission: JSON.stringify(permission)
        }
        jobBindPermission(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功!')
                    this.setState(preState => ({
                        checkEdit: true
                    })
                    )
                } else {
                    message.error('保存失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleEdit = () => {
        this.setState(preState => ({
            checkEdit: false,
            tableKey: Math.random()
        })
        )
    }

    render() {
        let { dataSource, columns, checkEdit, tableKey } = this.state

        return (
            <div>
                {
                    checkEdit ? <Operate dontAdd={true} seek={true} dontImport={true} notPrint={true} edit={true} educe={true} editEvent={this.handleEdit} /> : <div className='history ListOperate'>
                        <Button onClick={() => {
                            this.handleSave()
                        }}>
                            <FileDoneOutlined />
                            <div>保存</div>
                        </Button>
                    </div>
                }

                <Table pages={false} key={tableKey} columns={columns} dataSource={dataSource} selection={true} selectEvent={this.handSelect} />
            </div>
        )
    }
}

export default withRouter(Authority)
