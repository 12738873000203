import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { getFunctionTable } from "../../../utils/oa"
import { getTableAtt, createFunctionNotice, FunctionNoticeContent } from "../../../utils/dispostion"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "企业微信通知配置",
                TableInfo: [{
                    FieldTitle: '选择功能', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '功能名称', //字段标题
                    fieldName: 'table_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '选择接收人', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '接收人', //字段标题
                    fieldName: 'receive_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '选择时间基准', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '时间基准', //字段标题
                    fieldName: 'time_att', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '通知类型', //字段标题
                    fieldName: 'notice_type', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            value: 1,
                            label: '预通知'
                        },
                        {
                            value: 2,
                            label: '即时通知'
                        },
                        {
                            value: 3,
                            label: '延迟通知'
                        }
                    ]
                }, {
                    FieldTitle: '天数', //字段标题
                    fieldName: 'days', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    isShow: true
                }, {
                    FieldTitle: '是否循环', //字段标题
                    fieldName: 'is_loop', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            value: 1,
                            label: '是'
                        },
                        {
                            value: 2,
                            label: '否'
                        },
                    ]
                }, {
                    FieldTitle: '循环类型', //字段标题
                    fieldName: 'loop_unit', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            value: 'year',
                            label: '年'
                        },
                        {
                            value: 'month',
                            label: '月'
                        },
                        {
                            value: 'day',
                            label: '日'
                        }
                    ],
                    isShow: true
                },
                ],
                FirstInfo: [
                    {
                        plateName: '通知内容',
                        list: [
                            {
                                FieldTitle: '标题', //字段标题
                                fieldName: 'title', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            }, {
                                FieldTitle: '插入字段内容', //字段标题
                                fieldType: '按钮',  //字段类型
                            }, {
                                FieldTitle: '内容', //字段标题
                                fieldName: 'content', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },

                        ]
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            username: '',
            de_name: '',
            tableName: '',
            title: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: false,
            read_userName: [],
            FlowchartKey: 1
        }

    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_FunctionNoticeContent(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_FunctionNoticeContent = (tableId) => {
        let { Info, form, read_userName } = this.state;
        let data = {
            id: tableId
        }
        FunctionNoticeContent(data).then(
            (res) => {
                if (!res.code) {
                    if (this.props.location.query.status === '编辑') {
                        form.id = tableId
                        Info.state = 3
                        Info.process.edit = true
                    } else {
                        Info.state = 2
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择功能') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择接收人') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择时间基准') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '插入字段内容') {
                                item.isShow = true
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '功能名称') {
                            item.value = res.data.functionName
                            form.table_name = res.data.table_name
                        } else if (item.FieldTitle === '接收人') {
                            let receive_user = JSON.parse(res.data.receive_user)
                            let userName = []
                            receive_user.forEach((em, ex) => {
                                let value = {
                                    key: em.table_id,
                                    user_name: em.user_name
                                }
                                read_userName = [...read_userName, value]
                                userName.push(em.user_name + ' , ')
                            })
                            item.value = userName
                        } else if (item.FieldTitle === '时间基准') {
                            item.value = res.data.time_att
                            form.time_att = res.data.time_att
                        }
                    })

                    if (res.data.notice_type === 1 || res.data.notice_type === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '天数') {
                                item.isShow = false
                            }
                        })
                    }

                    if (res.data.is_loop === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '循环类型') {
                                item.isShow = false
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        notice_type: res.data.notice_type,
                        days: res.data.days,
                        is_loop: res.data.is_loop,
                        loop_unit: res.data.loop_unit,
                        title: res.data.title,
                        content: res.data.content,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        read_userName
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info, read_userName } = this.state;
        form.status = status
        form.notice_type = data.notice_type
        form.days = data.days
        form.is_loop = data.is_loop
        form.loop_unit = data.loop_unit
        form.title = data.title
        form.content = data.content
        form.receive_user = []
        read_userName.forEach((item, index) => {
            let value = {
                table_id: item.key,
                user_name: item.user_name
            }
            form.receive_user.push(value)
        })
        form.receive_user = JSON.stringify(form.receive_user)

        createFunctionNotice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                user_name: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FunctionTable = () => {
        let { dataSource } = this.state
        getFunctionTable().then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.tables.length) {
                        res.data.tables.forEach((item, index) => {
                            let user = {
                                key: item.tableName,
                                tableName: item.tableComment,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: false,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_TableAtt = () => {
        let { dataSource, form } = this.state
        if (!form.table_name) {
            message.error('请选择功能!')
            return
        }
        let data = {
            table: form.table_name,
        }
        getTableAtt(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    for (let key in res.data) {
                        let value = {
                            key: key,
                            name: res.data[key]
                        }
                        dataSource = [...dataSource, value]
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: false,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio, FlowchartKey, buttonTitle } = this.state
        if (state) {
            if (title === '选择功能') {
                columns = [
                    {
                        title: '功能表',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: '功能名称',
                        dataIndex: 'tableName',
                        key: 'tableName',
                    },
                ]
                TableInfo = []                
                if (buttonTitle !== title) {
                    FlowchartKey = Math.random()
                }
                radio = true
                this.get_FunctionTable()
            } else if (title === '选择接收人') {
                columns = [
                    {
                        title: '姓名',
                        dataIndex: 'user_name',
                        key: 'user_name',
                    },
                    {
                        title: '性别',
                        dataIndex: 'sex',
                        key: 'sex',
                        render: (text, index) => {
                            if (text === '1') {
                                return (
                                    <span> 男 </span>
                                )
                            } else {
                                return (<span> 女 </span>
                                )
                            }
                        }
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'deName',
                        key: 'deName',
                    },
                    {
                        title: '职位',
                        dataIndex: 'company_job',
                        key: 'company_job',
                    },
                    {
                        title: '手机',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '部门', //字段标题
                        fieldName: 'de_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                if (buttonTitle !== title) {
                    FlowchartKey = Math.random()
                }
                radio = false
                this.get_InitialValue()
            } else if (title === '选择时间基准') {
                columns = [
                    {
                        title: '字段名',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: '字段名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ]
                TableInfo = []
                if (buttonTitle !== title) {
                    FlowchartKey = Math.random()
                }
                radio = true
                this.get_TableAtt()
            } else if (title === '插入字段内容') {
                columns = [
                    {
                        title: '字段名',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: '字段名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ]
                TableInfo = []
                if (buttonTitle !== title) {
                    FlowchartKey = Math.random()
                }
                radio = true
                this.get_TableAtt()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio,
                FlowchartKey
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
            }))
        }
    }

    handleRadio = (value) => {
        let { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '循环类型') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '循环类型') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handleSelect = (value) => {
        const { Info } = this.state;
        if (value === 1 || value === 3) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '天数') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '天数') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle, read_userName } = this.state;
        if (buttonTitle === '选择功能') {
            form.table_name = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '功能名称') {
                    item.value = value[0].tableName
                }
            })
        } else if (buttonTitle === '选择接收人') {
            if (value.length) {
                read_userName = []             
                read_userName = [...read_userName, ...value]
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '接收人') {
                        let userName = []
                        read_userName.forEach((em, ex) => {
                            userName.push(em.user_name + ' , ')
                        })
    
                        item.value = userName
                    }
                })
            } else {
                read_userName = []
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '接收人') {
                        item.value = []
                    }
                })
            }

        } else if (buttonTitle === '选择时间基准') {
            form.time_att = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '时间基准') {
                    item.value = value[0].key
                }
            })
        } else if (buttonTitle === '插入字段内容') {
            let textarea = this.NewForm.current.textRef.current.textAreaRef.current.resizableTextArea.textArea
            let start = textarea.selectionStart;
            let end = textarea.selectionEnd;
            let data = textarea.value;
            let inValue = data.substring(0, start) + '{' + value[0].key + '}' + data.substring(end)
            this.NewForm.current.formRef.current.setFieldsValue({
                content: inValue
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
            read_userName
        })
        )

    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择接收人') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            tableName: '',
            title: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择接收人') {
                this.get_InitialValue()
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} radioEvent={this.handleRadio} selectorEvent={this.handleSelect} auditEvent={this.handleButton} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} radio={radio} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
