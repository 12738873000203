import React, { Component } from 'react'
import { Form, Select, Input, message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { CaretDownOutlined } from '@ant-design/icons';
import { createDevelopmentApplication, DevelopmentApplicationDetail } from "../../../utils/scientific_research"
import { reviewApplyResult, getCompanyDeName } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "研发项目",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '研发项目信息',
                    list: [
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目领域', //字段标题
                            fieldName: 'pro_area', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目编号', //字段标题
                            fieldName: 'pro_code', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目等级', //字段标题
                            fieldName: 'pro_grade', //字段名称
                            fieldType: '单选',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            radioList: [
                                {
                                    value: 1,
                                    label: '一般项目(10万(含)以下)'
                                },
                                {
                                    value: 2,
                                    label: '重点项目(10万以上)'
                                }
                            ]
                        },
                        {
                            FieldTitle: '项目类型', //字段标题
                            fieldName: 'pro_type', //字段名称
                            fieldType: '单选',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            radioList: [
                                {
                                    value: 1,
                                    label: 'A.理论基础研究'
                                },
                                {
                                    value: 2,
                                    label: 'B.应用基础研究'
                                },
                                {
                                    value: 3,
                                    label: 'c.应用研究'
                                }
                            ]
                        },
                        {
                            FieldTitle: '项目类别', //字段标题
                            fieldName: 'class', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                {
                                    value: 1,
                                    label: '对外承接'
                                },
                                {
                                    value: 2,
                                    label: '对外资助'
                                },
                                {
                                    value: 3,
                                    label: '自主研发'
                                },
                            ]
                        },
                        {
                            FieldTitle: '甲方信息', //字段标题
                            fieldName: 'unit_info', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '(甲方单位、联系人、联系电话)',
                            width: '100%',
                            isShow: true
                        }, {
                            FieldTitle: '部门名称', //字段标题
                            fieldName: 'de_id', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            selectList: []
                        },
                        {
                            FieldTitle: '项目经费(元)', //字段标题
                            fieldName: 'pro_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目负责人', //字段标题
                            fieldName: 'pro_manager', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '合作单位', //字段标题
                            fieldName: 'coo_unit', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目启动时间', //字段标题
                            fieldName: 'start_time', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '计划结束时间', //字段标题
                            fieldName: 'end_time', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                }, {
                    plateName: '信息',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '100px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 1, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '项目组成员姓名',
                                dataIndex: 'name',
                                key: 'name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }
                            },
                            {
                                title: '单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'unit' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'unit' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '研究方向',
                                dataIndex: 'research_direction',
                                key: 'research_direction',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'research_direction' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'research_direction' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '职责分工',
                                dataIndex: 'duty',
                                key: 'duty',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'duty' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'duty' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]
                    },
                }, {
                    plateName: '直接费用',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '100px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 2, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '直接费用',
                                dataIndex: 'direct_expense',
                                key: 'direct_expense',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_expense' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '设备费' },
                                                { value: 2, label: '材料费' },
                                                { value: 3, label: '测试化验加工费' },
                                                { value: 4, label: '燃料动力费' },
                                                { value: 5, label: '差旅费/会议费' },
                                                { value: 6, label: '出版/文献/信息传播/知识产权事务费' },
                                                { value: 7, label: '劳务费' },
                                                { value: 8, label: '专家咨询费' },
                                                { value: 9, label: '其他费用' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_expense' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '设备费' },
                                                { value: 2, label: '材料费' },
                                                { value: 3, label: '测试化验加工费' },
                                                { value: 4, label: '燃料动力费' },
                                                { value: 5, label: '差旅费/会议费' },
                                                { value: 6, label: '出版/文献/信息传播/知识产权事务费' },
                                                { value: 7, label: '劳务费' },
                                                { value: 8, label: '专家咨询费' },
                                                { value: 9, label: '其他费用' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '天艺支出金额(元)',
                                dataIndex: 'direct_ty_money',
                                key: 'direct_ty_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '合作单位支出金额(元)',
                                dataIndex: 'direct_hz_money',
                                key: 'direct_hz_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '依据及理由',
                                dataIndex: 'direct_argument',
                                key: 'direct_argument',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_argument' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_argument' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]
                    },
                }, {
                    plateName: '间接费用',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '100px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 3, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '间接费用',
                                dataIndex: 'overhead',
                                key: 'overhead',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'overhead' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '绩效支出' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'overhead' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '绩效支出' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '天艺支出金额(元)',
                                dataIndex: 'indirect_ty_money',
                                key: 'indirect_ty_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '合作单位支出金额(元)',
                                dataIndex: 'indirect_hz_money',
                                key: 'indirect_hz_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '依据及理由',
                                dataIndex: 'indirect_argument',
                                key: 'indirect_argument',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_argument' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_argument' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]
                    },
                },
                {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '预期成果', //字段标题
                            fieldName: 'expected_results', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '结题目标', //字段标题
                            fieldName: 'closing_goal', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '项目要点归纳', //字段标题
                            fieldName: 'key_points', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '备注', //字段标题
                            fieldName: 'info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '项目申请书', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ]
                }
            ]
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_CompanyDeName()
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_CompanyDeName = () => {
        let { Info } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '部门名称') {
                            item.selectList = []
                            res.data.departments.forEach((em, ex) => {
                                let value = {
                                    value: em.id,
                                    label: em.name
                                }
                                item.selectList = [...item.selectList, value]
                            })
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_DevelopmentApplicationDetail(tableId)
                        } else {
                            this.get_userInfo()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DevelopmentApplicationDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[1].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '项目组成员姓名',
                                dataIndex: 'name',
                                key: 'name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }
                            },
                            {
                                title: '单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'unit' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'unit' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '研究方向',
                                dataIndex: 'research_direction',
                                key: 'research_direction',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'research_direction' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'research_direction' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '职责分工',
                                dataIndex: 'duty',
                                key: 'duty',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'duty' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'duty' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo[2].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '直接费用',
                                dataIndex: 'direct_expense',
                                key: 'direct_expense',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_expense' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '设备费' },
                                                { value: 2, label: '材料费' },
                                                { value: 3, label: '测试化验加工费' },
                                                { value: 4, label: '燃料动力费' },
                                                { value: 5, label: '差旅费/会议费' },
                                                { value: 6, label: '出版/文献/信息传播/知识产权事务费' },
                                                { value: 7, label: '劳务费' },
                                                { value: 8, label: '专家咨询费' },
                                                { value: 9, label: '其他费用' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_expense' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '设备费' },
                                                { value: 2, label: '材料费' },
                                                { value: 3, label: '测试化验加工费' },
                                                { value: 4, label: '燃料动力费' },
                                                { value: 5, label: '差旅费/会议费' },
                                                { value: 6, label: '出版/文献/信息传播/知识产权事务费' },
                                                { value: 7, label: '劳务费' },
                                                { value: 8, label: '专家咨询费' },
                                                { value: 9, label: '其他费用' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '天艺支出金额(元)',
                                dataIndex: 'direct_ty_money',
                                key: 'direct_ty_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '合作单位支出金额(元)',
                                dataIndex: 'direct_hz_money',
                                key: 'direct_hz_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '依据及理由',
                                dataIndex: 'direct_argument',
                                key: 'direct_argument',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'direct_argument' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'direct_argument' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo[3].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '间接费用',
                                dataIndex: 'overhead',
                                key: 'overhead',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'overhead' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '绩效支出' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'overhead' + record.key} key={index} initialValue={text}>
                                            <Select options={[
                                                { value: 1, label: '绩效支出' },
                                            ]}
                                                suffixIcon={<CaretDownOutlined />}
                                                disabled
                                            >
                                            </Select>
                                        </Form.Item>)
                                    }
                                }

                            },
                            {
                                title: '天艺支出金额(元)',
                                dataIndex: 'indirect_ty_money',
                                key: 'indirect_ty_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_ty_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '合作单位支出金额(元)',
                                dataIndex: 'indirect_hz_money',
                                key: 'indirect_hz_money',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_hz_money' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            }, {
                                title: '依据及理由',
                                dataIndex: 'indirect_argument',
                                key: 'indirect_argument',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (<Form.Item name={'indirect_argument' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>)
                                    } else {
                                        return (<Form.Item name={'indirect_argument' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>)
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '项目申请书') {
                                    if (!res.data.Detail.enclosure.length) {
                                        em.isShow = true
                                    }
                                }
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.class === 1) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '甲方信息') {
                                item.isShow = false
                            }
                        })
                    }

                    Info.FirstInfo[4].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目申请书') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    Info.FirstInfo[1].editable.dataSource = []
                    let information = JSON.parse(res.data.Detail.information)
                    information.forEach((item, index) => {
                        let key = Info.FirstInfo[1].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            name: item.name,
                            unit: item.unit,
                            research_direction: item.research_direction,
                            duty: item.duty
                        };
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'name' + key)()]: item.name,
                            [(() => 'unit' + key)()]: item.unit,
                            [(() => 'research_direction' + key)()]: item.research_direction,
                            [(() => 'duty' + key)()]: item.duty,
                        })
                        Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
                    })

                    Info.FirstInfo[2].editable.dataSource = []
                    let direct = JSON.parse(res.data.Detail.direct)
                    direct.forEach((item, index) => {
                        let key = Info.FirstInfo[2].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            direct_expense: item.direct_expense,
                            direct_ty_money: item.direct_ty_money,
                            direct_hz_money: item.direct_hz_money,
                            direct_argument: item.direct_argument
                        };
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'direct_expense' + key)()]: item.direct_expense,
                            [(() => 'direct_ty_money' + key)()]: item.direct_ty_money,
                            [(() => 'direct_hz_money' + key)()]: item.direct_hz_money,
                            [(() => 'direct_argument' + key)()]: item.direct_argument,
                        })
                        Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
                    })

                    Info.FirstInfo[3].editable.dataSource = []
                    let indirect = JSON.parse(res.data.Detail.indirect)
                    indirect.forEach((item, index) => {
                        let key = Info.FirstInfo[3].editable.dataSource.length + 1
                        const newData = {
                            key: key,
                            overhead: item.overhead,
                            indirect_ty_money: item.indirect_ty_money,
                            indirect_hz_money: item.indirect_hz_money,
                            indirect_argument: item.indirect_argument
                        };
                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'overhead' + key)()]: item.overhead,
                            [(() => 'indirect_ty_money' + key)()]: item.indirect_ty_money,
                            [(() => 'indirect_hz_money' + key)()]: item.indirect_hz_money,
                            [(() => 'indirect_argument' + key)()]: item.indirect_argument,
                        })
                        Info.FirstInfo[3].editable.dataSource = [...Info.FirstInfo[3].editable.dataSource, newData]
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        pro_area: res.data.Detail.pro_area,
                        pro_code: res.data.Detail.pro_code,
                        pro_grade: res.data.Detail.pro_grade,
                        pro_type: res.data.Detail.pro_type,
                        class: res.data.Detail.class,
                        unit_info: res.data.Detail.unit_info,
                        de_id: res.data.Detail.de_id,
                        pro_money: res.data.Detail.pro_money,
                        pro_manager: res.data.Detail.pro_manager,
                        coo_unit: res.data.Detail.coo_unit,
                        phone: res.data.Detail.phone,
                        start_time: res.data.Detail.start_time ? moment(res.data.Detail.start_time, 'YYYY-MM-DD') : '',
                        end_time: res.data.Detail.end_time ? moment(res.data.Detail.end_time, 'YYYY-MM-DD') : '',
                        expected_results: res.data.Detail.expected_results,
                        closing_goal: res.data.Detail.closing_goal,
                        key_points: res.data.Detail.key_points,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.pro_area = data.pro_area
        form.pro_code = data.pro_code
        form.pro_grade = data.pro_grade
        form.pro_type = data.pro_type
        form.class = data.class
        form.unit_info = data.unit_info
        form.de_id = data.de_id
        form.pro_money = data.pro_money
        form.pro_manager = data.pro_manager
        form.coo_unit = data.coo_unit
        form.phone = data.phone
        form.start_time = data.start_time ? data.start_time.format('YYYY-MM-DD') : ''
        form.end_time = data.end_time ? data.end_time.format('YYYY-MM-DD') : ''
        form.expected_results = data.expected_results
        form.closing_goal = data.closing_goal
        form.key_points = data.key_points
        form.info = data.info
        form.information = []
        form.direct = []
        form.indirect = []

        if (Info.FirstInfo[1].editable.dataSource.length) {
            Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
                let value = {
                    name: data['name' + item.key] ? data['name' + item.key] : '',
                    unit: data['unit' + item.key] ? data['unit' + item.key] : '',
                    research_direction: data['research_direction' + item.key] ? data['research_direction' + item.key] : '',
                    duty: data['duty' + item.key] ? data['duty' + item.key] : '',
                }
                form.information = [...form.information, value]
            })
        }

        if (Info.FirstInfo[2].editable.dataSource.length) {
            Info.FirstInfo[2].editable.dataSource.forEach((item, index) => {
                let value = {
                    direct_expense: data['direct_expense' + item.key] ? data['direct_expense' + item.key] : '',
                    direct_ty_money: data['direct_ty_money' + item.key] ? data['direct_ty_money' + item.key] : '',
                    direct_hz_money: data['direct_hz_money' + item.key] ? data['direct_hz_money' + item.key] : '',
                    direct_argument: data['direct_argument' + item.key] ? data['direct_argument' + item.key] : '',
                }
                form.direct = [...form.direct, value]
            })
        }

        if (Info.FirstInfo[3].editable.dataSource.length) {
            Info.FirstInfo[3].editable.dataSource.forEach((item, index) => {
                let value = {
                    overhead: data['overhead' + item.key] ? data['overhead' + item.key] : '',
                    indirect_ty_money: data['indirect_ty_money' + item.key] ? data['indirect_ty_money' + item.key] : '',
                    indirect_hz_money: data['indirect_hz_money' + item.key] ? data['indirect_hz_money' + item.key] : '',
                    indirect_argument: data['indirect_argument' + item.key] ? data['indirect_argument' + item.key] : '',
                }
                form.indirect = [...form.indirect, value]
            })
        }

        form.information = JSON.stringify(form.information)
        form.direct = JSON.stringify(form.direct)
        form.indirect = JSON.stringify(form.indirect)


        createDevelopmentApplication(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[4].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        if (name === '信息') {
            if (Info.FirstInfo[1].editable.dataSource.length) {
                let key = Info.FirstInfo[1].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[1].editable.dataSource[key].key + 1,
                    name: '',
                    unit: '',
                    research_direction: '',
                    duty: ''
                };
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
            } else {
                const newData = {
                    key: Info.FirstInfo[1].editable.dataSource.length + 1,
                    name: '',
                    unit: '',
                    research_direction: '',
                    duty: ''
                };
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
            }
        } else if (name === '直接费用') {
            if (Info.FirstInfo[2].editable.dataSource.length) {
                let key = Info.FirstInfo[2].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[2].editable.dataSource[key].key + 1,
                    direct_expense: '',
                    direct_ty_money: '',
                    direct_hz_money: '',
                    direct_argument: ''
                };
                Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
            } else {
                const newData = {
                    key: Info.FirstInfo[2].editable.dataSource.length + 1,
                    direct_expense: '',
                    direct_ty_money: '',
                    direct_hz_money: '',
                    direct_argument: ''
                };
                Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, newData]
            }
        } else if (name === '间接费用') {
            if (Info.FirstInfo[3].editable.dataSource.length) {
                let key = Info.FirstInfo[3].editable.dataSource.length - 1
                const newData = {
                    key: Info.FirstInfo[3].editable.dataSource[key].key + 1,
                    overhead: '',
                    indirect_ty_money: '',
                    indirect_hz_money: '',
                    indirect_argument: ''
                };
                Info.FirstInfo[3].editable.dataSource = [...Info.FirstInfo[3].editable.dataSource, newData]
            } else {
                const newData = {
                    key: Info.FirstInfo[3].editable.dataSource.length + 1,
                    overhead: '',
                    indirect_ty_money: '',
                    indirect_hz_money: '',
                    indirect_argument: ''
                };
                Info.FirstInfo[3].editable.dataSource = [...Info.FirstInfo[3].editable.dataSource, newData]
            }
        }

        this.setState(preState => ({
            Info
        })
        )
    }

    handleDel = (key, state, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'name' + e)()]: '',
            [(() => 'unit' + e)()]: '',
            [(() => 'research_direction' + e)()]: '',
            [(() => 'duty' + e)()]: '',
            [(() => 'direct_expense' + e)()]: '',
            [(() => 'direct_ty_money' + e)()]: '',
            [(() => 'direct_hz_money' + e)()]: '',
            [(() => 'direct_argument' + e)()]: '',
            [(() => 'overhead' + e)()]: '',
            [(() => 'indirect_ty_money' + e)()]: '',
            [(() => 'indirect_hz_money' + e)()]: '',
            [(() => 'indirect_argument' + e)()]: '',
        })
        Info.FirstInfo[state].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[state].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    handleSelect = (value) => {
        const { Info } = this.state;

        if (value === 1) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '甲方信息') {
                    item.isShow = false
                }
            })
        } else {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '甲方信息') {
                    item.isShow = true
                }
            })
        }

        this.setState(preState => ({
            Info
        })
        )
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} selectorEvent={this.handleSelect} rowAddEvent={this.handleWorkAdd} auditEvent={this.handleButton} />
            </div>
        )
    }
}
