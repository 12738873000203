import React, { Component } from 'react'
import { Button, Form } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import UserInput from '../../components/userInput'
import UserRangeInput from '../../components/userRangeInput'
import UserTextArea from '../../components/userTextArea'
import UserRadio from '../../components/userRadio'
import UserSelect from '../../components/userSelect'
import UserDatePicker from '../../components/userDatePicker'
import UserTimePicker from '../../components/userTimePicker'
import UserRangePicker from '../../components/userRangePicker'


export default class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    formRef = React.createRef();

    initNode = (item) => {
        var node = null
        switch (item.fieldType) {
            case '文本':
                node = <UserInput disabled={item.disabled} fieldType='text' />
                break;
            case '文本区间':
                node = <UserRangeInput child={item.child} />
                break;
            case '文本域':
                node = <UserTextArea disabled={item.disabled} />
                break;
            case '自动编号':
                node = <UserInput disabled={item.disabled} fieldType='text' />
                break;
            case '单选':
                node = <UserRadio disabled={item.disabled} radioList={item.radioList} />
                break;
            case '选择器':
                node = <UserSelect disabled={item.disabled} selectList={item.selectList} />
                break;
            case '日期':
                node = <UserDatePicker disabled={item.disabled} />
                break;
            case '日期区间':
                node = <UserRangePicker child={item.child} />
                break;
            case '日期时间':
                node = <UserDatePicker disabled={item.disabled} showTime="true" />
                break;
            case '时间':
                node = <UserTimePicker disabled={item.disabled} />
                break;
            default:
                node = null
                break;
        }

        return node
    }


    onFinish = (values) => {
        console.log('Received values of form: ', values);
        this.props.searchEvent(values)
    };

    render() {
        let { TableInfo, type } = this.props;
        return (
            <div>
                <Form onFinish={this.onFinish}>
                    {
                        TableInfo.length ? TableInfo.map((item, index) => {
                            return (
                                <Form.Item
                                    // className={item.width === '100%' ? 'all' : item.width === '31%' ? 'moiety' : item.width === '22%' ? 'quarter' : ''}
                                    style={{ width: item.width }}
                                    label={item.FieldTitle}
                                    name={item.fieldName}
                                    key={index}
                                    initialValue={item.value}
                                >
                                    {
                                        this.initNode(item)
                                    }
                                </Form.Item>
                            )
                        }) : ''
                    }

                    {
                        type === 'search' ? <Form.Item className='ListSearch'>
                            <Button htmlType="submit">
                                <SearchOutlined />
                                <div>搜索</div>
                            </Button>

                            <Button onClick={() => {
                                this.props.cancelEvent()
                            }}>
                                <CloseOutlined />
                                <div>清空</div>
                            </Button>
                        </Form.Item> : type === 'close' ? <Form.Item className='ListSearch'>
                            <Button htmlType="submit">
                                <SearchOutlined />
                                <div>搜索</div>
                            </Button>

                            <Button onClick={() => {
                                this.props.cancelEvent()
                            }}>
                                <CloseOutlined />
                                <div>关闭</div>
                            </Button>
                        </Form.Item> : <Form.Item className='ListSearch BasicData'>
                            <Button onClick={() => {
                                this.props.handleEvent()
                            }}>
                                <div>确定</div>
                            </Button>
                            {
                                TableInfo.length ? <Button htmlType="submit">
                                    <div>搜索</div>
                                </Button> : ''
                            }
                        </Form.Item>
                    }


                </Form>
            </div>
        )
    }
}
