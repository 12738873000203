import http from '../utils/http';

/*用户管理 */

//用户管理列表
function userManagerList(data) {
    return http("get", '/api/userManagerList', data);
}

//修改用户密码
function updateUserPassword(data) {
    return http("post", '/api/updateUserPassword', data);
}

//修改用户角色
function EditUserPosition(data) {
    return http("post", '/api/EditUserPosition', data);
}

//修改用户部门
function updateUserDe(data) {
    return http("post", '/api/updateUserDe', data);
}

/*权限 */

//职位权限绑定
function jobBindPermission(data) {
    return http("post", '/api/jobBindPermission', data);
}

//职位角色权限列表
function JobRolePermission(data) {
    return http("get", '/api/JobRolePermission', data);
}

/*用户组管理 */

//添加编辑用户组
function editDepartment(data) {
    return http("post", '/api/editDepartment', data);
}

//用户组列表
function departmentList(data) {
    return http("get", '/api/departmentList', data);
}

//删除用户组
function deleteDepartment(data) {
    return http("post", '/api/deleteDepartment', data);
}

//新增用户组职位

function createCompanyJob(data) {
    return http("post", '/api/createCompanyJob', data);
}

//根据部门id获取职位列表
function deIdGetCompanyJob(data) {
    return http("get", '/api/deIdGetCompanyJob', data);
}

/* 公司合同审批 */

//公司合同审批辅助数据
function contractAuxiliaryData(data) {
    return http("get", '/api/contractAuxiliaryData', data);
}

//添加编辑公司合同审批
function createContractApproval(data) {
    return http("post", '/api/createContractApproval', data);
}

//公司合同审批列表
function contractApprovalList(data) {
    return http("get", '/api/contractApprovalList', data);
}

//主合同查询
function selectNature(data) {
    return http("get", '/api/selectNature', data);
}

//印章查询
function selectAvailableSeal(data) {
    return http("get", '/api/selectAvailableSeal', data);
}

//公司合同审批详情
function contractApprovalDetail(data) {
    return http("get", '/api/contractApprovalDetail', data);
}

/* 付款审批 */
//添加编辑员工借款
function createUserLoan(data) {
    return http("post", '/api/createUserLoan', data);
}

//员工借款列表
function userLoanList(data) {
    return http("get", '/api/userLoanList', data);
}

//借款审批详情
function userLoanDetail(data) {
    return http("get", '/api/userLoanDetail', data);
}

//费用报销辅助数据
function reimbursementAuxiliary(data) {
    return http("get", '/api/reimbursementAuxiliary', data);
}

//添加编辑费用报销审批
function createUserReimbursement(data) {
    return http("post", '/api/createUserReimbursement', data);
}

//报销审批列表
function userReimbursementList(data) {
    return http("get", '/api/userReimbursementList', data);
}

//报销审批详情
function userReimbursementDetail(data) {
    return http("get", '/api/userReimbursementDetail', data);
}

//添加编辑综合/科研付款审批
function createComResearch(data) {
    return http("post", '/api/createComResearch', data);
}

//综合/科研付款审批列表
function comResearchList(data) {
    return http("get", '/api/comResearchList', data);
}

//综合/科研付款审批详情
function comResearchDetail(data) {
    return http("get", '/api/comResearchDetail', data);
}

//添加编辑工程款支付审批
function createProjectPayment(data) {
    return http("post", '/api/createProjectPayment', data);
}

//工程款支付审批列表
function projectPaymentList(data) {
    return http("get", '/api/projectPaymentList', data);
}

//工程款支付审批详情
function projectPaymentDetail(data) {
    return http("get", '/api/projectPaymentDetail', data);
}

/* 转正申请 */

//添加编辑转正申请
function createUserConf(data) {
    return http("post", '/api/createUserConf', data);
}

//转正审批意见
function confReviewComments(data) {
    return http("post", '/api/confReviewComments', data);
}

//员工转正列表
function userConfList(data) {
    return http("get", '/api/userConfList', data);
}

//转正审批详情
function userConfDetail(data) {
    return http("get", '/api/userConfDetail', data);
}

/* 员工离职 */

//添加编辑员工离职办理
function createUserQuit(data) {
    return http("post", '/api/createUserQuit', data);
}

//离职辅助数据
function quitAuxiliary(data) {
    return http("get", '/api/quitAuxiliary', data);
}

//添加编辑批准离职时间
function agreeQuitTime(data) {
    return http("post", '/api/agreeQuitTime', data);
}

//员工离职列表
function userQuitList(data) {
    return http("get", '/api/userQuitList', data);
}

//员工离职详情
function userQuitDetail(data) {
    return http("get", '/api/userQuitDetail', data);
}

/* 用车申请 */

//用车申请列表
function useVehicleList(data) {
    return http("get", '/api/useVehicleList', data);
}

//用车申请详情
function useVehicleDetail(data) {
    return http("get", '/api/useVehicleDetail', data);
}

//用车申请详情
function vehicleAuxiliary(data) {
    return http("get", '/api/vehicleAuxiliary', data);
}

//添加编辑用车申请
function createUseVehicle(data) {
    return http("post", '/api/createUseVehicle', data);
}

//用车申请详情
function useVehicleLog(data) {
    return http("post", '/api/useVehicleLog', data);
}

/* 车辆派遣 */

//车辆派遣列表
function vehicleDispatchList(data) {
    return http("get", '/api/vehicleDispatchList', data);
}

//用车申请详情
function vehicleDispatchDetail(data) {
    return http("get", '/api/vehicleDispatchDetail', data);
}

//添加编辑车辆派遣
function createVehicleDispatch(data) {
    return http("post", '/api/createVehicleDispatch', data);
}

/* 业务招待申请 */

//业务招待申请列表
function workEatList(data) {
    return http("get", '/api/workEatList', data);
}

//业务招待申请详情
function workEatDetail(data) {
    return http("get", '/api/workEatDetail', data);
}

//业务招待申请辅助数据
function workEatAuxiliary(data) {
    return http("get", '/api/workEatAuxiliary', data);
}

//添加编辑业务招待申请
function createWorkEat(data) {
    return http("post", '/api/createWorkEat', data);
}

/* 办公用品申请 */

//添加编辑办公用品申请
function createOfficeSupplies(data) {
    return http("post", '/api/createOfficeSupplies', data);
}

//办公用品申请列表
function officeSuppliesList(data) {
    return http("get", '/api/officeSuppliesList', data);
}

//办公用品申请详情
function officeSuppliesDetail(data) {
    return http("get", '/api/officeSuppliesDetail', data);
}

//添加编辑施工组织设计审批
function createConstructionOrganization(data) {
    return http("post", '/api/createConstructionOrganization', data);
}

//施工组织设计审批列表
function ConstructionOrganizationList(data) {
    return http("get", '/api/ConstructionOrganizationList', data);
}

//施工组织设计审批详情
function ConstructionOrganizationDetail(data) {
    return http("get", '/api/ConstructionOrganizationDetail', data);
}

//编辑施工组织设计审批文件附件
function EditConstructionOrganizationEnclosure(data) {
    return http("post", '/api/EditConstructionOrganizationEnclosure', data);
}

//获取离职员工拥有功能
function getUserHaveFunction(data) {
    return http("get", '/api/getUserHaveFunction', data);
}

//离职交接详情
function HandoverWorkDetail(data) {
    return http("get", '/api/HandoverWorkDetail', data);
}

//离职交接列表
function HandoverWorkList(data) {
    return http("get", '/api/HandoverWorkList', data);
}

//添加编辑离职交接申请
function createHandoverWork(data) {
    return http("post", '/api/createHandoverWork', data);
}

//专利续费付款审批列表
function PatentRenewalList(data) {
    return http("get", '/api/PatentRenewalList', data);
}

//专利续费付款审批详情
function PatentRenewalDetail(data) {
    return http("get", '/api/PatentRenewalDetail', data);
}


//专利续费专利列表查询
function KnowledgeSelectList(data) {
    return http("get", '/api/KnowledgeSelectList', data);
}

//添加编辑专利续费付款审批
function createPatentRenewal(data) {
    return http("post", '/api/createPatentRenewal', data);
}

//工作暂管通知详情
function WorkNoticeDetail(data) {
    return http("get", '/api/WorkNoticeDetail', data);
}


//通知暂管申请列表
function WorkNoticeList(data) {
    return http("get", '/api/WorkNoticeList', data);
}

//启用禁用通知暂管
function updateNoticeStatus(data) {
    return http("post", '/api/updateNoticeStatus', data);
}

//添加编辑工作暂管
function createWorkNotice(data) {
    return http("post", '/api/createWorkNotice', data);
}

export {
    userManagerList,
    updateUserPassword,
    EditUserPosition,
    updateUserDe,
    jobBindPermission,
    JobRolePermission,
    editDepartment,
    departmentList,
    deleteDepartment,
    createCompanyJob,
    deIdGetCompanyJob,
    contractAuxiliaryData,
    createContractApproval,
    contractApprovalList,
    selectNature,
    selectAvailableSeal,
    contractApprovalDetail,
    createUserLoan,
    userLoanList,
    userLoanDetail,
    reimbursementAuxiliary,
    createUserReimbursement,
    userReimbursementList,
    userReimbursementDetail,
    createComResearch,
    comResearchList,
    comResearchDetail,
    createProjectPayment,
    projectPaymentList,
    projectPaymentDetail,
    createUserConf,
    confReviewComments,
    userConfList,
    userConfDetail,
    createUserQuit,
    quitAuxiliary,
    agreeQuitTime,
    userQuitList,
    userQuitDetail,
    useVehicleList,
    useVehicleDetail,
    vehicleAuxiliary,
    createUseVehicle,
    useVehicleLog,
    vehicleDispatchList,
    vehicleDispatchDetail,
    createVehicleDispatch,
    workEatList,
    workEatDetail,
    workEatAuxiliary,
    createWorkEat,
    createOfficeSupplies,
    officeSuppliesList,
    officeSuppliesDetail,
    createConstructionOrganization,
    ConstructionOrganizationList,
    ConstructionOrganizationDetail,
    EditConstructionOrganizationEnclosure,
    getUserHaveFunction,
    HandoverWorkDetail,
    HandoverWorkList,
    createHandoverWork,
    PatentRenewalList,
    PatentRenewalDetail,
    KnowledgeSelectList,
    createPatentRenewal,
    WorkNoticeDetail,
    WorkNoticeList,
    updateNoticeStatus,
    createWorkNotice
}
