import React, { Component } from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { FunctionNoticeList, enginNoticeConfig } from "../../../utils/dispostion"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '功能名称',
                    dataIndex: 'functionName',
                    key: 'functionName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '接收人',
                    dataIndex: 'receive_user',
                    key: 'receive_user',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }

                },
                {
                    title: '时间基准',
                    dataIndex: 'time_att',
                    key: 'time_att',
                },

                {
                    title: '通知类型',
                    dataIndex: 'notice_type',
                    key: 'notice_type',
                },
                {
                    title: '天数',
                    dataIndex: 'days',
                    key: 'days',
                },

                {
                    title: '是否循环',
                    dataIndex: 'is_loop',
                    key: 'is_loop',
                },
                {
                    title: '循环类型',
                    dataIndex: 'loop_unit',
                    key: 'loop_unit',
                },

                {
                    title: '通知标题',
                    dataIndex: 'title',
                    key: 'title',
                },

                {
                    title: '状态',
                    dataIndex: 'use_status',
                    key: 'use_status',
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div>启用中</div>
                            )
                        } else {
                            return (
                                <div style={{ color: '#EE5655' }}> 禁用中</div >
                            )
                        }

                    }
                },
                {
                    title: '操作',
                    dataIndex: 'controls',
                    key: 'controls',
                    render: (text, record, index) => {
                        if (record.use_status === 0) {
                            return (
                                <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => {
                                    this.set_enginNoticeConfig(record)
                                }}>启用</div>
                            )
                        } else if (record.use_status === 1) {
                            return (
                                <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => {
                                    this.set_enginNoticeConfig(record)
                                }}>禁用</div>
                            )
                        }
                    }
                },
            ],
            title: '',
            TableInfo: [
                {
                    FieldTitle: '标题', //字段标题
                    fieldName: 'title', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            selectValue: []
        }
    }

    componentDidMount() {
        this.get_FunctionNoticeList()
    }

    get_FunctionNoticeList = () => {
        let { dataSource, pages, process_state, title, } = this.state
        let data = {
            title: title,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        FunctionNoticeList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.notice_config.length) {
                        res.data.notice_config.forEach((item, index) => {
                            let user_name = ''
                            let receive_user = JSON.parse(item.receive_user)
                            receive_user.forEach((em, ex) => {
                                user_name = user_name + ' ' + em.user_name
                            })
                            let rowValue = {
                                key: index + 1,
                                table_id: item.id,
                                functionName: item.functionName,
                                receive_user: user_name,
                                time_att: item.time_att,
                                notice_type: item.notice_type === 1 ? '预通知' : item.notice_type === 2 ? '即时通知' : '延迟通知',
                                days: item.days || '/',
                                is_loop: item.is_loop === 1 ? '是' : '否',
                                loop_unit: item.loop_unit === 'year' ? '年' : item.loop_unit === 'month' ? '月' : item.loop_unit === 'day' ? '日' : '/',
                                title: item.title,
                                use_status: item.use_status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    set_enginNoticeConfig = (item) => {
        let data = {
            id: item.table_id
        }
        enginNoticeConfig(data).then(
            (res) => {
                if (!res.code) {
                    message.success('修改成功')
                    this.get_FunctionNoticeList()
                } else {
                    message.error(res.data[0] || '修改失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/disposition/notification/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/disposition/notification/NewItem',
            query: {
                'tableId': tableId,
                'status': '详情'
            }
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.props.history.push({
                    pathname: '/disposition/notification/NewItem',
                    query: {
                        'tableId': selectValue[0].table_id,
                        'status': '编辑'
                    }
                })
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            title: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_FunctionNoticeList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            title: value.pro_name,
        }, () => {
            this.get_FunctionNoticeList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_FunctionNoticeList()
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
            </div>
        )
    }
}

export default withRouter(List)
