import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { historyLogDetail } from "../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'userName',
                    key: 'userName',
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'jobName',
                    key: 'jobName',
                },
                {
                    title: '操作时间',
                    dataIndex: 'get_time',
                    key: 'get_time',
                },
            ],
            type: '',
            table_name: '',
            username: '',
            classType: 'look',
            TableInfo: [
                {
                    FieldTitle: 'ID', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            this.setState(preState => ({
                type: this.props.location.query.type,
                table_name: this.props.location.query.table_name
            }), () => {
                this.get_historyLogDetail()
            })

        }
    }

    get_historyLogDetail = () => {
        let { dataSource, pages, username, classType, type, table_name } = this.state
        let data = {
            type: type,
            table: table_name,
            class: classType,
            username: username,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        historyLogDetail(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.data_logs.length) {
                        res.data.data_logs.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                deName: item.deName,
                                userName: item.userName,
                                jobName: item.jobName,
                                get_time: item.created_at,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            username: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_historyLogDetail()
        })
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
        }, () => {
            this.get_historyLogDetail()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_historyLogDetail()
        })
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_historyLogDetail()
        })
    }

    render() {
        let { searchShow, classType, columns, dataSource, pages, TableInfo } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate dontAdd={true} dontImport={true} notPrint={true} educe={true} searchShow={searchShow} searchEvent={this.handleSearch} />
                    </div>

                    <div className='supplier_right employeesType'>
                        <div className={classType === 'look' ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex('look')
                        }}>浏览</div>
                        <div className={classType === 'commit' ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex('commit')
                        }}>提交</div>
                    </div>
                </div>
                {
                    searchShow ? <div className='searchForm'><Search TableInfo={TableInfo} type="close" searchEvent={this.searchQuery} cancelEvent={this.handleCancel} /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
