import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../../components/AddConfig'
import InitialValue from '../../../../components/InitialValue'
import { createSeedlingInventory, SeedlingInventoryDetail, selectNurseryList } from "../../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: false,
            template: '/deliveryStorage.docx',
            templateName: '四川天艺优境环境科技有限公司出库管理(358工程)',
            tempData: {
                apply_date:'',
                nur_name:'',
                nur_number: '',
                total_money: '',
                total_mou_toney: '',
                total_payed_money: '',
                total_period_money: '',             
                results: []
            },
            FlowModelName: "出库管理",
            TableInfo: [
                {
                    FieldTitle: '出库时间', //字段标题
                    fieldName: 'warehousing', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择出库时间', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '是否成品苗生产出库', //字段标题
                    fieldName: 'is_product', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否成品苗生产出库', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            value: 1,
                            label: '否'
                        },
                        {
                            value: 2,
                            label: '是'
                        }
                    ]
                },
                {
                    FieldTitle: '选择出库苗圃', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                },
                {
                    FieldTitle: '出库苗圃', //字段标题
                    fieldName: 'nur_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '苗圃编号', //字段标题
                    fieldName: 'nur_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
            ],
            FirstInfo: [
                {
                    plateName: '清单明细',
                    list: [],
                    imptable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '',
                                dataIndex: 'key',
                                key: 'key',
                            },
                            {
                                title: '植物编号',
                                dataIndex: 'plant_code',
                                key: 'plant_code',
                            }, {
                                title: '采购单位',
                                dataIndex: 'supply_unit',
                                key: 'supply_unit',
                            },
                            {
                                title: '植物名称',
                                dataIndex: 'plant_name',
                                key: 'plant_name',
                            },
                            {
                                title: '型号规格',
                                dataIndex: 'model',
                                key: 'model',
                            },
                            {
                                title: '出库数量',
                                dataIndex: 'see_number',
                                key: 'see_number',
                            },
                            {
                                title: '出库单价',
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: '出库总额',
                                dataIndex: 'all_price',
                                key: 'all_price',
                            },
                            {
                                title: '入库时间',
                                dataIndex: 'godown',
                                key: 'godown',
                            },
                            {
                                title: '负责人/签收人',
                                dataIndex: 'signatory',
                                key: 'signatory',
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/importInventory',
                            name: 'seedling',
                            data: {
                                is_product: 1,
                                operate :'out'
                            }
                        },
                        summary: true,
                        summaryValue: {
                            colspan: 11,
                            name: '植物出库总额(元)',
                            value: 0
                        }
                    },
                    isShow: true
                },
                {
                    plateName: '清单明细',
                    list: [],
                    imptable: {
                        scroll:true,
                        dataSource: [],
                        columns: [
                            {
                                title: '',
                                dataIndex: 'key',
                                key: 'key',
                            },
                            {
                                title: '植物编号',
                                dataIndex: 'plant_code',
                                key: 'plant_code',
                            }, {
                                title: '采购单位',
                                dataIndex: 'supply_unit',
                                key: 'supply_unit',
                            },
                            {
                                title: '成品苗名称',
                                dataIndex: 'plant_name',
                                key: 'plant_name',
                            },
                            {
                                title: '型号规格',
                                dataIndex: 'model',
                                key: 'model',
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '出库数量',
                                dataIndex: 'see_number',
                                key: 'see_number',
                            },
                            {
                                title: '生产单价(元)',
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: '生产结算合价',
                                dataIndex: 'all_price',
                                key: 'all_price',
                            },
                            {
                                title: '出库单价(元)',
                                dataIndex: 'out_price',
                                key: 'out_price',
                            },
                            {
                                title: '出库结算合价',
                                dataIndex: 'out_all_price',
                                key: 'out_all_price',
                            },
                            {
                                title: '累计已付金额',
                                dataIndex: 'payed_money',
                                key: 'payed_money',
                            },
                            {
                                title: '本期支付金额',
                                dataIndex: 'period_money',
                                key: 'period_money',
                            },
                            {
                                title: '入库时间',
                                dataIndex: 'godown',
                                key: 'godown',
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/importInventory',
                            name: 'seedling',
                            data: {
                                is_product: 2,
                                operate :'out'
                            }
                        },
                        summary: true,
                        summaryArray:true,
                        summaryValue: {
                            colspan: 15,
                            list:[
                                {
                                    name: '生产结算合价总额(元)',
                                    value: 0
                                },
                                {
                                    name: '出库结算合价总额(元)',
                                    value: 0
                                },
                                {
                                    name: '累计已付金额总额(元)',
                                    value: 0
                                },
                                {
                                    name: '本期支付金额总额(元)',
                                    value: 0
                                }
                            ]
                        }
                    },
                    isShow: true
                },
                {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '验收单', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '苗圃编号',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: '苗圃名称',
                dataIndex: 'name',
                key: 'name',
            },
        ],
        TableInfo: [],
        pages: {
            page: 1,
            total: 0,
            limit: 50,
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SeedlingInventoryDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_selectNurseryList = () => {
        let { dataSource } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                key: item.id,
                                code: item.code,
                                name: item.name
                            }
                            dataSource = [...dataSource, value]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_SeedlingInventoryDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        SeedlingInventoryDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.is_product === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '出库苗圃') {
                                item.value = res.data.Detail.nur_name
                            } else if (item.FieldTitle === '苗圃编号') {
                                item.value = res.data.Detail.nur_code
                            } else if (item.FieldTitle === '选择出库苗圃') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[2].list.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '验收单') {
                                if (res.data.Detail.enclosure.length) {
                                    item.value = res.data.Detail.enclosure
                                } else {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.FirstInfo[0].isShow = false
                        Info.FirstInfo[1].isShow = true
                        if (res.data.Detail.details) {
                            let total_money = 0
                            Info.FirstInfo[0].imptable.dataSource = []
                            res.data.Detail.details.forEach((item, index) => {
                                item.key = index + 1
                                Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                                total_money = total_money + Number(item.all_price)
                            })
                            Info.FirstInfo[0].imptable.summaryValue.value = total_money
                        }

                        Info.state = res.data.Detail.status
                    } else {
                        if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                            form.table_id = tableId
                            form.nur_id = res.data.Detail.nur_id

                            Info.process.edit = res.data.Detail.editPermission

                            Info.TableInfo.forEach((item, index) => {
                                item.disabled = false

                                if (item.FieldTitle === '选择出库苗圃') {
                                    item.isShow = false
                                }
                            })

                            Info.FirstInfo[2].list.forEach((item, index) => {
                                item.disabled = false

                                if (item.FieldTitle === '验收单') {
                                    item.isShow = false
                                    if (res.data.Detail.enclosure.length) {
                                        item.value = res.data.Detail.enclosure
                                    }
                                }
                            })
                        } else {
                            Info.TableInfo.forEach((item, index) => {
                                item.disabled = true

                                if (item.FieldTitle === '选择出库苗圃') {
                                    item.isShow = true
                                }
                            })

                            Info.FirstInfo[2].list.forEach((item, index) => {
                                item.disabled = true

                                if (item.FieldTitle === '验收单') {
                                    if (res.data.Detail.enclosure.length) {
                                        item.value = res.data.Detail.enclosure
                                    } else {
                                        item.isShow = true
                                    }
                                }
                            })

                            if (res.data.Detail.status === 4) {
                                Info.isExport=true
                                Info.tempData.apply_date = res.data.Detail.warehousing 
                                Info.tempData.nur_name = res.data.Detail.nur_name
                                Info.tempData.nur_number = res.data.Detail.nur_code
                                // Info.tempData.total_money = res.data.Detail.total_money
                                // Info.tempData.total_mou_toney = res.data.Detail.total_mou_toney
                                // Info.tempData.total_payed_money = res.data.Detail.total_payed_money 
                                // Info.tempData.total_period_money = res.data.Detail.total_period_money    
                                Info.tempData.results = []
    
                                res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                    let value = {
                                        username: item.username,
                                        opinion: item.opinion,
                                        reviewStatus: item.review_status
                                    }
                                    Info.tempData.results = [...Info.tempData.results, value]
                                })
                            }

                        }

                        Info.state = res.data.Detail.status
                        Info.process.data = res.data.Detail.reviewAudit
                        Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                        Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                        Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                        Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                        Info.FirstInfo[0].isShow = true
                        Info.FirstInfo[1].isShow = false

                        if (res.data.Detail.details) {
                            let total_money = 0
                            let total_mou_toney = 0
                            let total_payed_money = 0
                            let total_period_money = 0
                            Info.FirstInfo[1].imptable.dataSource = []
                            res.data.Detail.details.forEach((item, index) => {
                                item.key = index + 1
                                Info.FirstInfo[1].imptable.dataSource = [...Info.FirstInfo[1].imptable.dataSource, item]
                                total_money = total_money + Number(item.all_price)
                                total_mou_toney= total_mou_toney + Number(item.out_all_price)
                                total_payed_money = total_payed_money + Number(item.payed_money)
                                total_period_money = total_period_money + Number(item.period_money)
                            })
                            Info.FirstInfo[1].imptable.summaryValue.list[0].value = total_money
                            Info.FirstInfo[1].imptable.summaryValue.list[1].value = total_mou_toney
                            Info.FirstInfo[1].imptable.summaryValue.list[2].value = total_payed_money
                            Info.FirstInfo[1].imptable.summaryValue.list[3].value = total_period_money
                            Info.tempData.total_money = total_money
                            Info.tempData.total_mou_toney = total_mou_toney
                            Info.tempData.total_payed_money = total_payed_money 
                            Info.tempData.total_period_money = total_period_money    
                            
                        }

                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '出库苗圃') {
                                item.value = res.data.Detail.nur_name
                            } else if (item.FieldTitle === '苗圃编号') {
                                item.value = res.data.Detail.nur_code
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        warehousing: res.data.Detail.warehousing ? moment(res.data.Detail.warehousing, 'YYYY-MM-DD') : '',
                        is_product: res.data.Detail.is_product,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.operate = 'out'
        form.warehousing = data.warehousing ? data.warehousing.format('YYYY-MM-DD') : ''
        form.is_product = data.is_product
        if (form.is_product === 1) {
            form.nur_table = JSON.stringify(Info.FirstInfo[0].imptable.dataSource)
        } else {
            form.nur_table = JSON.stringify(Info.FirstInfo[1].imptable.dataSource)
        }

        createSeedlingInventory(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // 文件上传改变事件
    updateChange = (info) => {
        let { Info, form } = this.state
        if (info.file.status === 'done') {
            if (!info.file.response.code) {
                message.success('导入成功')

                if (form.is_product === 1) {
                    info.file.response.data.data.forEach((item, index) => {
                        item.key = index + 1
                        Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                    })

                    Info.FirstInfo[0].imptable.summaryValue.value = info.file.response.data.total_money
                } else {
                    let total_money = 0
                    let total_mou_toney = 0
                    let total_payed_money = 0
                    let total_period_money = 0
                    info.file.response.data.data.forEach((item, index) => {
                        item.key = index + 1
                        Info.FirstInfo[1].imptable.dataSource = [...Info.FirstInfo[1].imptable.dataSource, item]
                        total_money = total_money + Number(item.all_price)
                        total_mou_toney = total_mou_toney + Number(item.out_all_price)
                        total_payed_money = total_payed_money + Number(item.payed_money)
                        total_period_money = total_period_money + Number(item.period_money)
                    })

                    Info.FirstInfo[1].imptable.summaryValue.list[0].value = total_money
                    Info.FirstInfo[1].imptable.summaryValue.list[1].value = total_mou_toney
                    Info.FirstInfo[1].imptable.summaryValue.list[2].value = total_payed_money
                    Info.FirstInfo[1].imptable.summaryValue.list[3].value = total_period_money
                }


                this.setState(preState => ({
                    Info
                })
                )
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    handleClear = () => {
        let { Info, form } = this.state
        if (form.is_product === 1) {
            Info.FirstInfo[0].imptable.dataSource = []
        } else {
            Info.FirstInfo[1].imptable.dataSource = []
        }

        this.setState(preState => ({
            Info,
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handButton = (title, state) => {
        if (state) {
            this.get_selectNurseryList()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handleRadio = (value, name) => {
        let { Info, form } = this.state
        form.is_product = value
        if (value === 1) {
            Info.FirstInfo[0].isShow = false
            Info.FirstInfo[1].isShow = true
        } else {
            Info.FirstInfo[0].isShow = true
            Info.FirstInfo[1].isShow = false
        }
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.nur_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '出库苗圃') {
                item.value = value[0].name
            } else if (item.FieldTitle === '苗圃编号') {
                item.value = value[0].code
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_selectNurseryList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.selectNurseryList()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} uploadEvent={this.updateChange} ClearEvent={this.handleClear} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
