import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree, message, Button } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../../../components/Operate'
import Search from '../../../../components/Search'
import Table from '../../../../components/Table'
import { SeedlingsBatchStatistics, selectNurseryList, ShipmentStatusEditing } from "../../../../../utils/scientific_research"

import Folders from "../../../../../accets/img/folders.png"
import File from "../../../../../accets/img/file.png"

const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '入库时间',
                    dataIndex: 'godown',
                    key: 'godown',
                },
                {
                    title: '植物编号',
                    dataIndex: 'plant_code',
                    key: 'plant_code',
                },
                {
                    title: '植物名称',
                    dataIndex: 'plant_name',
                    key: 'plant_name',
                },
                {
                    title: '入库数量',
                    dataIndex: 'see_number',
                    key: 'see_number',
                },
                {
                    title: '出库数量',
                    dataIndex: 'out_number',
                    key: 'out_number',
                },
                {
                    title: '库存/耗损数量',
                    dataIndex: 'surplus_number',
                    key: 'surplus_number',
                },
                {
                    title: '操作',
                    dataIndex: 'is_over',
                    key: 'is_over',
                    render: (text, record, index) => {
                        if (text === 1) {
                            return (
                                <div className='download' onClick={() => { this.handleStatus(record) }}>
                                    清库
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    已清库
                                </div>
                            )
                        }
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '植物编号', //字段标题
                    fieldName: 'plant_code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '植物名称', //字段标题
                    fieldName: 'plant_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            nur_id: '',
            plant_code: '',
            plant_name: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    componentDidMount() {
        this.get_selectNurseryList()
    }

    get_selectNurseryList = () => {
        let { treeData, nur_id, selectedKeys, expandedKeys } = this.state
        selectNurseryList().then(
            (res) => {
                if (!res.code) {
                    treeData = [
                        {
                            title: '苗圃名称',
                            key: '0',
                            icon: <FoldersIcon />,
                            children: []
                        }
                    ]
                    if (res.data.nursery.length) {
                        res.data.nursery.forEach((item, index) => {
                            let value = {
                                title: item.name,
                                key: item.id,
                                icon: <FileIcon />,
                            }
                            treeData[0].children = [...treeData[0].children, value]
                        })
                    }

                    if (!nur_id) {
                        selectedKeys = []
                        expandedKeys = ['0']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        nur_id = em.key
                                        selectedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        nur_id,
                        selectedKeys,
                        expandedKeys,
                    }, () => {
                        this.get_SeedlingsBatchStatistics()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SeedlingsBatchStatistics = () => {
        let { dataSource, pages, nur_id, plant_code, plant_name, } = this.state
        let data = {
            nur_id: nur_id,
            plant_code: plant_code,
            plant_name: plant_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SeedlingsBatchStatistics(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.details.length) {
                        res.data.details.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.sheet_id,
                                godown: item.godown,
                                plant_code: item.plant_code,
                                plant_name: item.plant_name,
                                see_number: item.see_number,
                                out_number: item.out_number,
                                surplus_number: item.surplus_number,
                                is_over: item.is_over,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        templateList: res.data.template
                    })
                    )
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleStatus = (item) => {
        let data = {
            plant_code: item.plant_code,
            plant_name: item.plant_name,
            godown: item.godown
        }
        ShipmentStatusEditing(data).then(
            (res) => {
                if (!res.code) {
                    message.success('清库成功!')
                    setTimeout(() => {
                        this.get_SeedlingsBatchStatistics()
                    })
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );

    }

    handleNew = () => { }

    handleSummary = () => {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/inventoryStatistics/SummaryList'
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            plant_code: '',
            plant_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_SeedlingsBatchStatistics()
        })
    }

    searchQuery = (value) => {
        this.setState({
            plant_code: value.plant_code,
            plant_name: value.plant_name,
        }, () => {
            this.get_SeedlingsBatchStatistics()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_SeedlingsBatchStatistics()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            nur_id: value[0],
        }), () => {
            this.get_SeedlingsBatchStatistics()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }


    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys, } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <Operate dontAdd={true} notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                        <div className='history ListOperate' style={{ width: 'auto' }}>
                            <Button onClick={() => {
                                this.handleSummary()
                            }}>
                                <div>库存总表</div>
                            </Button>
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
