import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createNotice, noticeDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "通知公告",
            TableInfo: [{
                FieldTitle: '标题', //字段标题
                fieldName: 'noticeName', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '关键字', //字段标题
                fieldName: 'key_str', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '发布时间', //字段标题
                fieldName: 'public_day', //字段名称
                fieldType: '日期时间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '文件编号', //字段标题
                fieldName: 'file_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '发文类型', //字段标题
                fieldName: 'type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '公示'
                    },
                    {
                        value: 2,
                        label: '函件'
                    },
                    {
                        value: 3,
                        label: '会议纪要'
                    },
                    {
                        value: 4,
                        label: '决议'
                    },
                    {
                        value: 5,
                        label: '任免决定'
                    },
                    {
                        value: 6,
                        label: '通知'
                    },
                    {
                        value: 7,
                        label: '新闻报道'
                    },
                    {
                        value: 8,
                        label: '其他'
                    },
                ]
            }, {
                FieldTitle: '发文子类型', //字段标题
                fieldName: 'sub_type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '党群工作类'
                    },
                    {
                        value: 2,
                        label: '企业经营类'
                    },
                ]
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'notice', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_noticeDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_noticeDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        noticeDetail(data).then(
            (res) => {
                if (!res.code) {
                    // if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                    //     form.table_id = tableId
                    //     Info.process.edit = res.data.Detail.editPermission
                    // } else {
                    //     Info.TableInfo.forEach((item, index) => {
                    //         item.disabled = true
                    //     })
                    // }

                    if (this.props.location.query.status === '编辑') {
                        Info.state = 1
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.state = res.data.Detail.status
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        noticeName: res.data.Detail.notice_name,
                        key_str: res.data.Detail.key_str,
                        public_day: res.data.Detail.public_day ? moment(res.data.Detail.public_day, 'YYYY-MM-DD HH:mm:ss') : '',
                        file_code: res.data.Detail.file_code,
                        type: res.data.Detail.type,
                        sub_type: res.data.Detail.sub_type,
                        notice: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.noticeName = data.noticeName
        form.key_str = data.key_str
        form.public_day = data.public_day ? data.public_day.format('YYYY-MM-DD HH:mm:ss') : ''
        form.file_code = data.file_code
        form.type = data.type
        form.sub_type = data.sub_type
        form.status = status

        createNotice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
