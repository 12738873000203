import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createUseVehicle, useVehicleDetail } from "../../../../utils/public"
import { getCompanyDeName, selectUserName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "用车申请",
            TableInfo: [{
                FieldTitle: '车辆类型', //字段标题
                fieldName: 've_type', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择车辆类型', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "商务用车" },
                    { value: 2, label: "项目用车" },
                ]
            },],
            FirstInfo: [{
                plateName: '用车登记',
                list: [
                    {
                        FieldTitle: '用车部门', //字段标题
                        fieldName: 'de_id', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择用车部门', //必填要求
                        value: '',
                        width: '50%',
                        selectList: []
                    }, {
                        FieldTitle: '联系电话', //字段标题
                        fieldName: 'phone', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入联系电话', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '用车时间', //字段标题
                        fieldName: 'time', //字段名称
                        fieldType: '时间区间',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '2023-01-01',
                        width: '50%',
                        child: [
                            {
                                FieldTitle: '开始时间', //字段标题
                                fieldName: 'start', //字段名称
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '结束时间', //字段标题
                                fieldName: 'end', //字段名称
                                value: '',
                                width: '50%',
                            },
                        ]
                    }, {
                        FieldTitle: '用途', //字段标题
                        fieldName: 'purpose', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            { value: 1, label: "市内" },
                            { value: 2, label: "长途" },
                            { value: 3, label: "市内借车" },
                            { value: 4, label: "长途借车" },
                        ]
                    },
                    {
                        FieldTitle: '选择驾驶员', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '驾驶员', //字段标题
                        fieldName: 'driver', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '选择跟车人员', //字段标题
                        fieldType: '按钮',  //字段类型
                    },
                    {
                        FieldTitle: '跟车人员', //字段标题
                        fieldName: 'people', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '用车事由', //字段标题
                        fieldName: 'reason', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入用车事由', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
            },
            {
                plateName: '收车登记',
                isShow: true,
                list: [
                    {
                        FieldTitle: '车辆号牌', //字段标题
                        fieldName: 'driver', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '收车时间', //字段标题
                        fieldName: 'back_time', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '过路费(元)', //字段标题
                        fieldName: 'toll', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '里程数(里)', //字段标题
                        fieldName: 'mileage', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '油耗', //字段标题
                        fieldName: 'oil', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                ],
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        selectTitle: '',
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_CompanyDeName()
    }

    get_CompanyDeName = () => {
        let { Info } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '用车部门') {
                            res.data.departments.forEach((em, ex) => {
                                let value = {
                                    value: Number(em.id),
                                    label: em.name
                                }
                                item.selectList = [...item.selectList, value]
                            })
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_useVehicleDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_useVehicleDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        useVehicleDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '选择驾驶员') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择跟车人员') {
                                item.isShow = true
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission


                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '驾驶员') {
                            item.value = res.data.Detail.driverName
                            form.driver = res.data.Detail.driver
                        } else if (item.FieldTitle === '跟车人员') {
                            item.value = res.data.Detail.peopleNames
                            form.people = res.data.Detail.peoples
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        de_id: res.data.Detail.de_id,
                        phone: res.data.Detail.phone,
                        start: res.data.Detail.start ? moment(res.data.Detail.start, 'YYYY-MM-DD') : '',
                        end: res.data.Detail.end ? moment(res.data.Detail.end, 'YYYY-MM-DD') : '',
                        purpose: res.data.Detail.purpose,
                        reason: res.data.Detail.reason,
                        ve_type: res.data.Detail.ve_type,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.ve_type = data.ve_type
        form.de_id = data.de_id
        form.phone = data.phone
        form.start = data.start ? data.start.format('YYYY-MM-DD HH:mm:ss') : ''
        form.end = data.end ? data.end.format('YYYY-MM-DD HH:mm:ss') : ''
        form.purpose = data.purpose
        form.reason = data.reason

        createUseVehicle(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
            this.setState(preState => ({
                selectTitle: title
            }))
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handSelect = (value) => {
        let { form, Info, selectTitle } = this.state
        if (selectTitle === '选择驾驶员') {
            form.driver = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '驾驶员') {
                    item.value = value[0].username
                }
            })
        } else {
            let people = []
            let peopleName = []
            value.forEach((item, index) => {
                people.push(item.key)
                peopleName.push(item.username + ' ')
            })
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '跟车人员') {
                    item.value = peopleName
                }
            })
            form.people = JSON.stringify(people)
        }
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd' >
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
