import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { nurserySettlementList } from "../../../../utils/scientific_research"




class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '合同名称',
                    dataIndex: 'con_name',
                    key: 'con_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '合同金额',
                    dataIndex: 'con_money',
                    key: 'con_money',
                },
                {
                    title: '结算金额',
                    dataIndex: 'con_set_money',
                    key: 'con_set_money',
                },
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                },
                {
                    title: '供货单位',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            con_name: '',
            pro_name: '',
            TableInfo: [
                {
                    FieldTitle: '合同名称', //字段标题
                    fieldName: 'con_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }
    }

    componentDidMount() {
        this.get_nurserySettlementList()
    }

    get_nurserySettlementList = () => {
        let { dataSource, pages, process_state, con_name, pro_name } = this.state
        let data = {
            con_name: con_name,
            pro_name: pro_name,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        nurserySettlementList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                con_set_money: item.con_set_money,
                                pro_name: item.pro_name,
                                sup_name: item.sup_name,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            total: res.data.pages.total,
                            limit: res.data.pages.limit
                        }
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/nurserySettlement/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/nurserySettlement/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            con_name: '',
            pro_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_nurserySettlementList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            con_name: value.con_name,
            pro_name: value.pro_name,
        }, () => {
            this.get_nurserySettlementList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_nurserySettlementList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_nurserySettlementList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
