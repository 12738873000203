import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '部门名称',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '用户登录次数',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '用户使用时长',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '最近登录时间',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '使用功能点击量排行',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, index) => {
                        return (
                            <div style={{ color: '#339EF9', cursor: 'pointer'}} onClick={() => [
                                this.handleRanking()
                            ]}>查看</div>
                        )
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '部门名称', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    handleNew = () => { }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false
        })
    }

    searchQuery = () => {
        console.log('筛选')
    }

    handleRanking = () => {
        this.props.history.push('/public/system/Ranking')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {})
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo } = this.state
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit}/>
                }
            </div>
        )
    }
}

export default withRouter(List)
