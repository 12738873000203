import React, { Component } from 'react'
import { message, Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createConstructionLog, ConstructionLogDetail, BlankingSelectProject } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "施工日志",
            TableInfo: [
                {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '填报人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '填报日期', //字段标题
                    fieldName: 'apply_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择填报日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '上午天气', //字段标题
                    fieldName: 'am', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择上午天气', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            value: 1,
                            label: '阴天'
                        },
                        {
                            value: 2,
                            label: '晴天'
                        },
                        {
                            value: 3,
                            label: '雨天'
                        }
                    ]
                },
                {
                    FieldTitle: '下午天气', //字段标题
                    fieldName: 'pm', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择下午天气', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        {
                            value: 1,
                            label: '阴天'
                        },
                        {
                            value: 2,
                            label: '晴天'
                        },
                        {
                            value: 3,
                            label: '雨天'
                        }
                    ]
                }, {
                    FieldTitle: '最高气温(℃)', //字段标题
                    fieldName: 'high', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '最低气温(℃)', //字段标题
                    fieldName: 'low', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '选择接收人', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '接收人', //字段标题
                    fieldName: 'read_user', //字段名称
                    fieldType: '默认', //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
            ],
            FirstInfo: [
                {
                    plateName: '材料日志',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 0, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '收货品类',
                                dataIndex: 'class',
                                key: 'class',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'class' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'class')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'class' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'class')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'model')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'model')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'unit')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'unit')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '实收数量',
                                dataIndex: 'number',
                                key: 'number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }, {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]
                    },
                },
                {
                    plateName: '劳务日志',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 1, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '施工班组',
                                dataIndex: 'team',
                                key: 'team',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'team' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'team')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'team' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'team')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '工作人数',
                                dataIndex: 'number',
                                key: 'number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'work_number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'work_number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '施工内容',
                                dataIndex: 'content',
                                key: 'content',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'work_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'content')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'work_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'content')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            // {
                            //     title: '施工内容',
                            //     dataIndex: 'content',
                            //     key: 'content',
                            //     width: '24%',
                            //     render: (text, record, index) => {
                            //         if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                            //             return (
                            //                 <Form.Item name={'content' + record.key} key={index} initialValue={text}>
                            //                     <Select
                            //                         options={[
                            //                             {
                            //                                 value: 1,
                            //                                 label: '土建'
                            //                             },
                            //                             {
                            //                                 value: 2,
                            //                                 label: '主体'
                            //                             }
                            //                         ]}
                            //                         onChange={(e) => this.SelectChange(e, record.key, 'content')}
                            //                         suffixIcon={<CaretDownOutlined />}
                            //                     >
                            //                     </Select>
                            //                 </Form.Item>
                            //             )
                            //         } else {
                            //             return (
                            //                 <Form.Item name={'content' + record.key} key={index} initialValue={text}>
                            //                     <Select
                            //                         options={[
                            //                             {
                            //                                 value: 1,
                            //                                 label: '土建'
                            //                             },
                            //                             {
                            //                                 value: 2,
                            //                                 label: '主体'
                            //                             }
                            //                         ]}
                            //                         disabled
                            //                         onChange={(e) => this.SelectChange(e, record.key, 'content')}
                            //                         suffixIcon={<CaretDownOutlined />}
                            //                     >
                            //                     </Select>
                            //                 </Form.Item>
                            //             )
                            //         }
                            //     }

                            // }, 
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'labou_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'labou_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]
                    },
                },
                {
                    plateName: '机械日志',
                    list: [],
                    editable: {
                        dataSource: [],
                        columns: [
                            // {
                            //     title: '',
                            //     dataIndex: 'key',
                            //     key: 'key',
                            //     width: '80px'
                            // },
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'controls',
                                key: 'controls',
                                width: '100px',
                                render: (text, record, index) => {
                                    return (
                                        <div className='download' onClick={() => {
                                            this.handleDel(index, 2, record.key)
                                        }}>
                                            删除
                                        </div>
                                    )
                                }
                            },
                            {
                                title: '机械类型',
                                dataIndex: 'type',
                                key: 'type',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'type' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'type')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'type' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'type')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '台班用时',
                                dataIndex: 'time',
                                key: 'time',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'time')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'time')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '工作内容',
                                dataIndex: 'content',
                                key: 'content',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'machinery_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'content')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'machinery_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'content')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }, {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'machinery_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'machinery_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]
                    },
                },
                {
                    plateName: '',
                    list: [
                        {
                            FieldTitle: '当日施工进展', //字段标题
                            fieldName: 'progress', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '需要协调问题', //字段标题
                            fieldName: 'problem', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '现场图片', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        }
                    ]
                }
            ]
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        pro_name: '',
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
        read_userName: [],
        FlowchartKey: 1,
        formKey: 2
    }

    NewForm = React.createRef();

    componentDidMount() {
        let { form } = this.state
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ConstructionLogDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { form, Info } = this.state;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                }
            })
        }
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '填报人') {
                item.value = userInfo.name
            }
        })
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    get_ConstructionLogDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        ConstructionLogDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择接收人') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '收货品类',
                                dataIndex: 'class',
                                key: 'class',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'class' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'class')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'class' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'class')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'model')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'model')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'unit')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'unit')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '实收数量',
                                dataIndex: 'number',
                                key: 'number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }, {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_01(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo[1].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '施工班组',
                                dataIndex: 'team',
                                key: 'team',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'team' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'team')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'team' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'team')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '工作人数',
                                dataIndex: 'number',
                                key: 'number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'work_number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'number')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'work_number' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'number')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '施工内容',
                                dataIndex: 'content',
                                key: 'content',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'work_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'content')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'work_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'content')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'labou_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'labou_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_02(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo[2].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '机械类型',
                                dataIndex: 'type',
                                key: 'type',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'type' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'type')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'type' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'type')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '台班用时',
                                dataIndex: 'time',
                                key: 'time',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'time')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'time')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '工作内容',
                                dataIndex: 'content',
                                key: 'content',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'machinery_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'content')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'machinery_content' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'content')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            }, {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'machinery_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'machinery_info' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange_03(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                        ]

                        Info.FirstInfo[3].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '现场图片') {
                                    item.isShow = true
                                }
                            }
                        })

                    }

                    Info.state = res.data.Detail.status

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.FieldTitle === '填报人') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '填报日期') {
                            item.value = res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : ''
                        } else if (item.FieldTitle === '上午天气') {
                            item.value = res.data.Detail.am
                        } else if (item.FieldTitle === '下午天气') {
                            item.value = res.data.Detail.pm
                        } else if (item.FieldTitle === '最高气温(℃)') {
                            item.value = res.data.Detail.high
                        } else if (item.FieldTitle === '最低气温(℃)') {
                            item.value = res.data.Detail.low
                        }

                    })

                    if (res.data.Detail.readUser.length) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '接收人') {
                                item.value = []
                                res.data.Detail.readUser.forEach((em, ex) => {
                                    item.value.push(em.userName + ' , ')
                                    em.key = em.user_id
                                    em.username = em.userName
                                })
                            }
                        })
                    }

                    if (res.data.Detail.mac_log) {
                        let dataSource = JSON.parse(res.data.Detail.mac_log)
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'class' + key)()]: item.class,
                                [(() => 'model' + key)()]: item.model,
                                [(() => 'unit' + key)()]: item.unit,
                                [(() => 'number' + key)()]: item.number,
                                [(() => 'info' + key)()]: item.info,
                            })
                        })
                        Info.FirstInfo[0].editable.dataSource = dataSource
                    } else {
                        Info.FirstInfo[0].editable.dataSource = []
                    }

                    if (res.data.Detail.art_log) {
                        let dataSource = JSON.parse(res.data.Detail.art_log)
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'team' + key)()]: item.team,
                                [(() => 'work_number' + key)()]: item.work_number,
                                [(() => 'work_content' + key)()]: item.work_content,
                                [(() => 'labou_info' + key)()]: item.labou_info,
                            })
                        })
                        Info.FirstInfo[1].editable.dataSource = dataSource
                    }else {
                        Info.FirstInfo[1].editable.dataSource = []
                    }

                    if (res.data.Detail.mec_log) {
                        let dataSource = JSON.parse(res.data.Detail.mec_log)
                        dataSource.forEach((item, index) => {
                            let key = index
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'type' + key)()]: item.type,
                                [(() => 'time' + key)()]: item.time,
                                [(() => 'machinery_content' + key)()]: item.machinery_content,
                                [(() => 'machinery_info' + key)()]: item.machinery_info,
                            })
                        })
                        Info.FirstInfo[2].editable.dataSource = dataSource
                    }else {
                        Info.FirstInfo[2].editable.dataSource = []
                    }

                    Info.FirstInfo[3].list.forEach((item, index) => {
                        if (item.FieldTitle === '现场图片') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        } if (item.FieldTitle === '当日施工进展') {
                            item.value = res.data.Detail.progress
                        } else if (item.FieldTitle === '需要协调问题') {
                            item.value = res.data.Detail.problem
                        }
                    })

                    // this.NewForm.current.formRef.current.setFieldsValue({
                    //     apply_date: moment(res.data.Detail.apply_date, 'YYYY-MM-DD'),
                    //     am: Number(res.data.Detail.am),
                    //     pm: Number(res.data.Detail.pm),
                    //     high: res.data.Detail.high,
                    //     low: res.data.Detail.low,
                    //     progress: res.data.Detail.progress,
                    //     problem: res.data.Detail.problem,
                    //     enclosure: res.data.Detail.enclosure
                    // })

                    this.setState(preState => ({
                        Info,
                        form,
                        read_userName: res.data.Detail.readUser || [],
                        formKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info, read_userName } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.am = data.am
        form.pm = data.pm
        form.high = data.high
        form.low = data.low
        form.progress = data.progress
        form.problem = data.problem
        form.readUser = []
        read_userName.forEach((item, index) => {
            form.readUser.push(item.key)
        })
        form.readUser = JSON.stringify(form.readUser)
        form.mac_log = JSON.stringify(Info.FirstInfo[0].editable.dataSource)
        form.art_log = JSON.stringify(Info.FirstInfo[1].editable.dataSource)
        form.mec_log = JSON.stringify(Info.FirstInfo[2].editable.dataSource)

        createConstructionLog(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '选择接收人') {
                columns = [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ]
                TableInfo = [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }]
                radio = false
                this.get_InitialValue()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                radio = true
                this.get_BlankingSelectProject()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio,
                FlowchartKey: Math.random(),
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle, read_userName } = this.state
        if (buttonTitle === '选择接收人') {
            if (value.length) {
                value.forEach((item, index) => {
                    read_userName.forEach((em, ex) => {
                        if (item.username === em.username) {
                            value.splice(index, 1)
                        }
                    })
                })

                read_userName = [...read_userName, ...value]
            }
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '接收人') {
                    let userName = []
                    read_userName.forEach((em, ex) => {
                        userName.push(em.username + ' , ')
                    })

                    item.value = userName
                }
            })
        } else {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            read_userName,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择接收人') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        } else {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[3].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择接收人') {
                this.get_InitialValue()
            } else {
                this.get_BlankingSelectProject()
            }
        })
    }

    handleWorkAdd = (name) => {
        let { Info } = this.state;
        if (name === '材料日志') {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                let key = Info.FirstInfo[0].editable.dataSource.length - 1
                let value = {
                    key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                    class: '',
                    model: '',
                    unit: '',
                    number: '',
                    info: ''
                }
                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, value]
            } else {
                let value = {
                    key: Info.FirstInfo[0].editable.dataSource.length + 1,
                    class: '',
                    model: '',
                    unit: '',
                    number: '',
                    info: ''
                }
                Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, value]
            }
        } else if (name === '劳务日志') {
            if (Info.FirstInfo[1].editable.dataSource.length) {
                let key = Info.FirstInfo[1].editable.dataSource.length - 1
                let value = {
                    key: Info.FirstInfo[1].editable.dataSource[key].key + 1,
                    team: '',
                    number: '',
                    content: '',
                    info: ''
                }
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, value]
            } else {
                let value = {
                    key: Info.FirstInfo[1].editable.dataSource.length + 1,
                    team: '',
                    number: '',
                    content: '',
                    info: ''
                }
                Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, value]
            }
        } else if (name === '机械日志') {
            if (Info.FirstInfo[2].editable.dataSource.length) {
                let key = Info.FirstInfo[2].editable.dataSource.length - 1
                let value = {
                    key: Info.FirstInfo[2].editable.dataSource[key].key + 1,
                    type: '',
                    time: '',
                    content: '',
                    info: ''
                }
                Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, value]
            } else {
                let value = {
                    key: Info.FirstInfo[2].editable.dataSource.length + 1,
                    type: '',
                    time: '',
                    content: '',
                    info: ''
                }
                Info.FirstInfo[2].editable.dataSource = [...Info.FirstInfo[2].editable.dataSource, value]
            }
        }

        this.setState(preState => ({
            Info
        }))
    }

    InputChange_01 = (e, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }


    InputChange_02 = (e, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }


    InputChange_03 = (e, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[2].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    SelectChange = (value, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    handleDel = (key, state, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'class' + e)()]: '',
            [(() => 'model' + e)()]: '',
            [(() => 'unit' + e)()]: '',
            [(() => 'number' + e)()]: '',
            [(() => 'info' + e)()]: '',
            [(() => 'team' + e)()]: '',
            [(() => 'work_number' + e)()]: '',
            [(() => 'work_content' + e)()]: '',
            [(() => 'labou_info' + e)()]: '',
            [(() => 'type' + e)()]: '',
            [(() => 'time' + e)()]: '',
            [(() => 'machinery_content' + e)()]: '',
            [(() => 'machinery_info' + e)()]: '',
        })
        Info.FirstInfo[state].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[state].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, formKey, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={formKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} rowAddEvent={this.handleWorkAdd} auditEvent={this.handleButton} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
