import React, { Component } from 'react'
import { message } from 'antd';
import 'moment/locale/zh-cn';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createDevelopmentExtension, DevelopmentExtensionDetail, getDevelopmentInfo } from "../../../utils/scientific_research"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "项目延期结题申请",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '项目基本信息',
                    list: [
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目编号', //字段标题
                            fieldName: 'pro_code', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '部门名称', //字段标题
                            fieldName: 'de_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目经费(元)', //字段标题
                            fieldName: 'pro_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '项目负责人', //字段标题
                            fieldName: 'pro_manager', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '合作单位', //字段标题
                            fieldName: 'coo_unit', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'phone', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_time', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_time', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申请延期时间', //字段标题
                            fieldName: 'ext_time', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '经费使用情况', //字段标题
                            fieldName: 'use_info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '延期结题原因', //字段标题
                            fieldName: 'ext_info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '下一步研究计划', //字段标题
                            fieldName: 'last_plan', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                },
            ]
        },
        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DevelopmentExtensionDetail(tableId)
        } else {
            let devel_id = sessionStorage.getItem('development_id')
            this.get_DevelopmentInfo(devel_id)
        }
    }

    get_DevelopmentInfo = (devel_id) => {
        let { Info, form } = this.state
        let data = {
            devel_id: devel_id
        }
        getDevelopmentInfo(data).then(
            (res) => {
                if (!res.code) {
                    Info.state = 1
                    form.devel_id = devel_id
                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.devel.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.devel.pro_code
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.devel.deName
                        } else if (item.FieldTitle === '项目经费(元)') {
                            item.value = res.data.devel.pro_money
                        } else if (item.FieldTitle === '项目负责人') {
                            item.value = res.data.devel.pro_manager
                        } else if (item.FieldTitle === '联系电话') {
                            item.value = res.data.devel.phone
                        } else if (item.FieldTitle === '合作单位') {
                            item.value = res.data.devel.coo_unit
                        } else if (item.FieldTitle === '开始时间') {
                            item.value = res.data.devel.start_time
                        } else if (item.FieldTitle === '结束时间') {
                            item.value = res.data.devel.end_time
                        }
                    })
                    this.setState(preState => ({
                        Info,
                        form
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DevelopmentExtensionDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentExtensionDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '附件') {
                                    if (!res.data.Detail.enclosure.length) {
                                        em.isShow = true
                                    }
                                }
                            })
                        })
                    }

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.devel.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.devel.pro_code
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.devel.deName
                        } else if (item.FieldTitle === '项目经费(元)') {
                            item.value = res.data.Detail.devel.pro_money
                        } else if (item.FieldTitle === '项目负责人') {
                            item.value = res.data.Detail.devel.pro_manager
                        } else if (item.FieldTitle === '联系电话') {
                            item.value = res.data.Detail.devel.phone
                        } else if (item.FieldTitle === '合作单位') {
                            item.value = res.data.Detail.devel.coo_unit
                        } else if (item.FieldTitle === '开始时间') {
                            item.value = res.data.Detail.devel.start_time
                        } else if (item.FieldTitle === '结束时间') {
                            item.value = res.data.Detail.devel.end_time
                        }
                    })

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        use_info: res.data.Detail.use_info,
                        ext_info: res.data.Detail.ext_info,
                        ext_time:res.data.Detail.ext_time ? moment(res.data.Detail.ext_time, 'YYYY-MM-DD') : '',
                        last_plan: res.data.Detail.last_plan,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.use_info = data.use_info
        form.ext_info = data.ext_info
        form.ext_time = data.ext_time ? data.ext_time.format('YYYY-MM-DD') : ''
        form.last_plan = data.last_plan

        createDevelopmentExtension(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
					    setTimeout(() => {
					        window.history.back(-1)
					    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
