import React, { Component } from 'react'
import moment from 'moment';
import { Input, Form, message } from 'antd';
import AddConfig from '../../../components/AddConfig'
import { createOfficeSupplies, officeSuppliesDetail } from "../../../../utils/public"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "办公用品申请",
            TableInfo: [{
                FieldTitle: '申请人', //字段标题
                fieldName: 'user_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '申请部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '申请日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择申请日期', //必填要求
                value: '',
                width: '50%'
            },],
            FirstInfo: [{
                plateName: '领用明细',
                list: [],
                editable: {
                    dataSource: [],
                    columns: [
                        // {
                        //     title: '',
                        //     dataIndex: 'key',
                        //     key: 'key',
                        //     width:'80px'
                        // },
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '用品名称',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '领用数量',
                            dataIndex: 'number',
                            key: 'number',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                    ]
                }
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_equipmentDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }
    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '申请部门') {
                item.value = userInfo.jobName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_equipmentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            id: tableId
        }
        officeSuppliesDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo[0].editable.columns=[
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '用品名称',
                                dataIndex: 'name',
                                key: 'name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'name' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '领用数量',
                                dataIndex: 'number',
                                key: 'number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'number' + record.key} key={index} initialValue={text}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '申请部门') {
                            item.value = res.data.Detail.deName
                        }
                    })

                    let content = JSON.parse(res.data.Detail.content)
                    if (content.length) {
                        content.forEach((item, index) => {
                            let key = Info.FirstInfo[0].editable.dataSource.length + 1
                            const newData = {
                                key: key,
                                name: item.name,
                                number: item.number,
                            };
                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'name' + key)()]: item.name,
                                [(() => 'number' + key)()]: item.number,
                            })
                            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                        })
                    } else {
                        Info.FirstInfo[0].editable.dataSource = []
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handPresent = (data, status) => {
        const { Info, form } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.content = Info.FirstInfo[0].editable.dataSource

        form.content.forEach((item, index) => {
            item.name = data['name' + item.key] ? data['name' + item.key] : ''
            item.number = data['number' + item.key] ? data['number' + item.key] : ''
        })

        form.content = JSON.stringify(form.content)

        createOfficeSupplies(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWorkAdd = (name) => {
        const { Info } = this.state;
        if (Info.FirstInfo[0].editable.dataSource.length) {
            let key = Info.FirstInfo[0].editable.dataSource.length - 1
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                name: '',
                number: '',
            };
            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]

        } else {
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                name: '',
                number: '',
            };
            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        }
        this.setState({
            Info
        });
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'name' + e)()]: '',
            [(() => 'number' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} auditEvent={this.handleButton} />
            </div>
        )
    }
}
