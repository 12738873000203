import React, { Component } from 'react'
import { Input, Form, message, DatePicker, ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import axios from 'axios'
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { saveMaterialBill, materialDetail, BlankingSelectProject, projectMaterial, blankingMaterialSelect, addMaterialPrice } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"
import excess from "../../../accets/img/excess.png"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "人材机下料单",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'pro_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '项目工长', //字段标题
                fieldName: 'goreman', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '选择集采专员', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            },
            {
                FieldTitle: '集采专员', //字段标题
                fieldName: 'cc_user', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '下料类别', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择下料类别', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "材料" },
                    { value: 2, label: "劳务" },
                    { value: 3, label: "机械" },
                ],
            },
            {
                FieldTitle: '下料单主要内容', //字段标题
                fieldName: 'team_com', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入下料单主要内容', //必填要求
                value: '',
                width: '100%',
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [
                {
                    plateName: '下料信息',
                    list: [],
                    isShow: true,
                    export: true,
                    editable: {
                        dataSource: [],
                        columns: []
                    },
                }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        material: '',
        pro_name: '',
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
        FlowchartKey: 2,
        formKey: 1,
        isEdit: false,
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_materialDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.pro_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = project.pro_manager
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_materialDetail = (tableId) => {
        let { Info, form, isEdit } = this.state;
        let permission = JSON.parse(localStorage.getItem("permission"))
        let data = {
            table_id: tableId
        }
        materialDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.pro_id = res.data.pro_id
                        form.cc_user = res.data.cc_user
                        form.class = res.data.class
                        Info.process.edit = res.data.editPermission

                        if (res.data.class === 1) {
                            Info.FirstInfo[0].editable = {
                                scroll: true,
                                scrollWidth: 2500,
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '操 作',
                                        dataIndex: 'controls',
                                        key: 'controls',
                                        width: '100px',
                                        render: (text, record, index) => {
                                            return (
                                                <div className='download' onClick={() => {
                                                    this.handleDel(index, record.key)
                                                }}>
                                                    删除
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                        width: '120px'
                                    },
                                    {
                                        title: '清单总量',
                                        dataIndex: 'number',
                                        key: 'number',
                                        width: '120px'
                                    },
                                    {
                                        title: '本次提料量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        width: '150px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input />
                                                    </Form.Item>
                                                )

                                            } else {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '累计提料量',
                                        dataIndex: 'material',
                                        key: 'material',
                                        width: '150px',
                                        render: (text, record, index) => {
                                            if (record.mat_status === 0) {
                                                return (
                                                    <div>{text}</div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ marginRight: '20px' }}>{text}</div>
                                                        <img src={excess} alt=""></img>
                                                    </div>
                                                )
                                            }

                                        }
                                    },
                                    {
                                        title: '累计合格到货量',
                                        dataIndex: 'qualified',
                                        key: 'qualified',
                                        width: '180px',

                                    },
                                    {
                                        title: '本次提料时间',
                                        dataIndex: 'qua_time',
                                        key: 'qua_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'qua_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'qua_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'qua_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'qua_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '预留加工周期(天)',
                                        dataIndex: 'machining_time',
                                        key: 'machining_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'machining_time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'machining_time')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'machining_time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'machining_time')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '计划到货时间',
                                        dataIndex: 'arrival_time',
                                        key: 'arrival_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '成本备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '120px'
                                    },
                                    {
                                        title: '下料备注',
                                        dataIndex: 'pic_info',
                                        key: 'pic_info',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'pic_info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pic_info')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'pic_info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pic_info')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                ]
                            }
                        } else if (res.data.class === 2) {
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '操 作',
                                        dataIndex: 'controls',
                                        key: 'controls',
                                        width: '100px',
                                        render: (text, record, index) => {
                                            return (
                                                <div className='download' onClick={() => {
                                                    this.handleDel(index, record.key)
                                                }}>
                                                    删除
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '数 量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        render: (text, record, index) =>
                                            <div>{record.number}</div>

                                    },
                                    {
                                        title: '单 价',
                                        dataIndex: 'unit_price',
                                        key: 'unit_price',
                                    },
                                    {
                                        title: '备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                ]
                            }
                        } else if (res.data.class === 3) {
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '操 作',
                                        dataIndex: 'controls',
                                        key: 'controls',
                                        width: '100px',
                                        render: (text, record, index) => {
                                            return (
                                                <div className='download' onClick={() => {
                                                    this.handleDel(index, record.key)
                                                }}>
                                                    删除
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '数量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '到场时间',
                                        dataIndex: 'arrival_time',
                                        key: 'arrival_time',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                ]
                            }
                        } else if (res.data.class === 7) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '下料类别') {
                                    let value = { value: 7, label: "零星材料" }
                                    item.radioList = [...item.radioList, value]
                                }
                            })
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '操 作',
                                        dataIndex: 'controls',
                                        key: 'controls',
                                        width: '100px',
                                        render: (text, record, index) => {
                                            return (
                                                <div className='download' onClick={() => {
                                                    this.handleDel(index, record.key)
                                                }}>
                                                    删除
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '单 位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '工程量',
                                        dataIndex: 'number',
                                        key: 'number',
                                    },
                                    {
                                        title: '单价(元)',
                                        dataIndex: 'price',
                                        key: 'price',
                                        render: (text, record, index) => {
                                            if (this.state.isEdit) {
                                                return (
                                                    <Form.Item name={'price' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputBlur(e, record, 'price')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else if (text) {
                                                return (
                                                    <Form.Item name={'price' + record.key} key={index} initialValue={text}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <div>/</div>
                                                )
                                            }
                                        }
                                    },
                                    {
                                        title: '合价(元)',
                                        dataIndex: 'money',
                                        key: 'money',
                                        render: (text, record, index) => {
                                            if (text) {
                                                return (
                                                    <Form.Item name={'money' + record.key} key={index} initialValue={text}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <div>/</div>
                                                )
                                            }
                                        }
                                    },
                                    {
                                        title: '备 注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    }
                                ]
                            }
                        }

                    } else {
                        form.table_id = tableId
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择集采专员') {
                                item.isShow = true
                            }

                            if (!res.data.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.class === 1) {
                            Info.FirstInfo[0].editable = {
                                scroll: true,
                                scrollWidth: 2500,
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                        width: '120px'
                                    },
                                    {
                                        title: '清单总量',
                                        dataIndex: 'number',
                                        key: 'number',
                                        width: '120px'
                                    },
                                    {
                                        title: '本次提料量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        width: '150px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input />
                                                    </Form.Item>
                                                )

                                            } else {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '累计提料量',
                                        dataIndex: 'material',
                                        key: 'material',
                                        width: '150px',
                                        render: (text, record, index) => {
                                            if (record.mat_status === 0) {
                                                return (
                                                    <div>{text}</div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ marginRight: '20px' }}>{text}</div>
                                                        <img src={excess} alt=""></img>
                                                    </div>
                                                )
                                            }

                                        }
                                    },
                                    {
                                        title: '累计合格到货量',
                                        dataIndex: 'qualified',
                                        key: 'qualified',
                                        width: '180px',

                                    },
                                    {
                                        title: '本次提料时间',
                                        dataIndex: 'qua_time',
                                        key: 'qua_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'qua_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'qua_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'qua_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'qua_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '预留加工周期(天)',
                                        dataIndex: 'machining_time',
                                        key: 'machining_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'machining_time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'machining_time')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'machining_time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'machining_time')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '计划到货时间',
                                        dataIndex: 'arrival_time',
                                        key: 'arrival_time',
                                        width: '180px',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '成本备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '120px'
                                    },
                                    {
                                        title: '下料备注',
                                        dataIndex: 'pic_info',
                                        key: 'pic_info',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'pic_info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pic_info')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'pic_info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pic_info')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                ]
                            }
                        } else if (res.data.class === 2) {
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '数 量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        render: (text, record, index) =>
                                            <div>{record.number}</div>

                                    },
                                    {
                                        title: '单 价',
                                        dataIndex: 'unit_price',
                                        key: 'unit_price',
                                    },
                                    {
                                        title: '备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                    },
                                ]
                            }
                        } else if (res.data.class === 3) {
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '计量单位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '数量',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'quantity')}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '到场时间',
                                        dataIndex: 'arrival_time',
                                        key: 'arrival_time',
                                        render: (text, record, index) => {
                                            if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            } else {
                                                return (
                                                    <ConfigProvider locale={zhCN}>
                                                        <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                format='YYYY-MM-DD'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                                            />
                                                        </Form.Item>
                                                    </ConfigProvider>
                                                )
                                            }
                                        }

                                    },
                                    {
                                        title: '备注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '220px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                ]
                            }
                        } else if (res.data.class === 7) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '下料类别') {
                                    let value = { value: 7, label: "零星材料" }
                                    item.radioList = [...item.radioList, value]
                                }
                            })
                            Info.FirstInfo[0].editable = {
                                dataSource: [],
                                columns: [
                                    {
                                        title: '序号',
                                        dataIndex: 'id',
                                        key: 'id',
                                        width: '80px',
                                        render: (text, record, index) => {
                                            return (
                                                <div>{index + 1}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '名称/工种',
                                        dataIndex: 'name',
                                        key: 'name',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '规格型号',
                                        dataIndex: 'model',
                                        key: 'model',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    },
                                    {
                                        title: '单 位',
                                        dataIndex: 'unit',
                                        key: 'unit',
                                    },
                                    {
                                        title: '工程量',
                                        dataIndex: 'number',
                                        key: 'number',
                                    },
                                    {
                                        title: '单价(元)',
                                        dataIndex: 'price',
                                        key: 'price',
                                        render: (text, record, index) => {
                                            if (this.state.isEdit) {
                                                return (
                                                    <Form.Item name={'price' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputBlur(e, record, 'price')}>
                                                        <Input />
                                                    </Form.Item>
                                                )
                                            } else if (text) {
                                                return (
                                                    <Form.Item name={'price' + record.key} key={index} initialValue={text}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <div>/</div>
                                                )
                                            }
                                        }
                                    },
                                    {
                                        title: '合价(元)',
                                        dataIndex: 'money',
                                        key: 'money',
                                        render: (text, record, index) => {
                                            if (text) {
                                                return (
                                                    <Form.Item name={'money' + record.key} key={index} initialValue={text}>
                                                        <Input disabled />
                                                    </Form.Item>
                                                )
                                            } else {
                                                return (
                                                    <div>/</div>
                                                )
                                            }
                                        }
                                    },
                                    {
                                        title: '备 注',
                                        dataIndex: 'info',
                                        key: 'info',
                                        width: '200px',
                                        render: (text, record, index) => {
                                            return (
                                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                                            )
                                        }
                                    }
                                ]
                            }
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    permission.forEach((item, index) => {
                        if (item === 'addMaterialPrice') {
                            isEdit = true
                        }
                    })

                    Info.FirstInfo[0].isShow = false

                    res.data.MaterialDetail.forEach((item, index) => {
                        let key = index + 1
                        item.key = key
                        item.material = item.cumulative
                        item.arrival_time = item.arrival_time ? moment(item.arrival_time, 'YYYY-MM-DD') : ''
                        item.qua_time = item.qua_time ? moment(item.qua_time, 'YYYY-MM-DD') : ''

                        this.NewForm.current.formRef.current.setFieldsValue({
                            [(() => 'quantity' + key)()]: item.quantity,
                            [(() => 'qua_time' + key)()]: item.qua_time,
                            [(() => 'machining_time' + key)()]: item.machining_time,
                            [(() => 'arrival_time' + key)()]: item.arrival_time,
                            [(() => 'pic_info' + key)()]: item.pic_info,
                        })
                    })

                    Info.FirstInfo[0].editable.dataSource = res.data.MaterialDetail

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.proManagerName
                        } else if (item.FieldTitle === '项目工长') {
                            item.value = res.data.goreman
                        } else if (item.FieldTitle === '集采专员') {
                            item.value = res.data.ccUserName
                        } else if (item.FieldTitle === '下料类别') {
                            item.value = res.data.class
                        } else if (item.FieldTitle === '下料单主要内容') {
                            item.value = res.data.team_com
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    // this.NewForm.current.formRef.current.setFieldsValue({
                    //     goreman: res.data.goreman,
                    //     class: res.data.class,
                    //     team_com: res.team_com,
                    //     enclosure: res.data.enclosure
                    // })

                    this.setState(preState => ({
                        Info,
                        form,
                        isEdit,
                        formKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectMaterial = () => {
        let { form, dataSource, pages, material } = this.state
        if (!form.pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            pro_id: form.pro_id,
            class: form.class,
            material: material,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        projectMaterial(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.materialData.length) {
                        res.data.materialData.forEach((item, index) => {
                            let user = {
                                key: item.id,
                                name: item.name,
                                model: item.model,
                                unit: item.unit,
                                number: item.number,
                                cumulative: item.cumulative,
                                qualified: item.qualified,
                                unit_price: item.unit_price,
                                info: item.info,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_blankingMaterialSelect = (id, number) => {
        let { Info } = this.state;
        let data = {
            mac_id: id,
            mat_number: number,
        }
        blankingMaterialSelect(data).then(
            (res) => {
                if (!res.code) {
                    let dataSource = []
                    Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
                        if (em.id === id) {
                            const newData = {
                                key: em.key,
                                id: em.id,
                                name: em.name,
                                unit: em.unit,
                                model: em.model,
                                number: em.number,
                                quantity: em.quantity,
                                material: res.data.material,
                                mat_status: res.data.mat_status,
                                qualified: res.data.qualified,
                                unit_price: em.unit_price,
                                qua_time: em.qua_time,
                                machining_time: em.machining_time,
                                arrival_time: em.arrival_time,
                                info: em.info,
                                pic_info: em.pic_info,
                            }
                            dataSource = [...dataSource, newData]

                        } else {
                            dataSource = [...dataSource, em]
                        }
                    })

                    Info.FirstInfo[0].editable.dataSource = dataSource

                    this.setState(preState => ({
                        Info,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.class = data.class
        form.team_com = data.team_com
        form.goreman = data.goreman
        form.is_excess = 2

        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (item.mat_status) {
                form.is_excess = 1
            }

            if (form.table_id) {
                if (typeof item.arrival_time !== 'string') {
                    item.arrival_time = item.arrival_time.format('YYYY-MM-DD')
                }
                if (typeof item.qua_time !== 'string') {
                    item.qua_time = item.qua_time.format('YYYY-MM-DD')
                }
            }

            // item.pic_info=item.pic_info
        })


        form.lists = Info.FirstInfo[0].editable.dataSource

        form.lists = JSON.stringify(form.lists)

        saveMaterialBill(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择集采专员') {
                columns = [
                    {
                        title: '姓名',
                        dataIndex: 'username',
                        key: 'username',
                    },
                    {
                        title: '性别',
                        dataIndex: 'sex',
                        key: 'sex',
                        render: (text, index) => {
                            if (text === '1') {
                                return (
                                    <span> 男 </span>
                                )
                            } else {
                                return (<span> 女 </span>
                                )
                            }
                        }
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'deName',
                        key: 'deName',
                    },
                    {
                        title: '职位',
                        dataIndex: 'company_job',
                        key: 'company_job',
                    },
                    {
                        title: '手机',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'username', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }, {
                        FieldTitle: '部门', //字段标题
                        fieldName: 'de_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_InitialValue()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                this.get_BlankingSelectProject()
            }


            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio: true
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handleWorkAdd = (name) => {
        let { columns, TableInfo } = this.state
        columns = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '规格型号',
                dataIndex: 'model',
                key: 'model',
                width: '300px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '计量单位',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: '清单总量',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '备注',
                dataIndex: 'info',
                key: 'info',
            }
        ]
        TableInfo = [
            {
                FieldTitle: '名称', //字段标题
                fieldName: 'material', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            }
        ]
        this.setState(preState => ({
            buttonTitle: '下料信息',
            columns,
            TableInfo,
            radio: false,
            FlowchartKey: Math.random(),
        }), () => {
            this.get_projectMaterial()
        })
    }

    handleRadio = (value) => {
        let { Info, form } = this.state;
        form.class = value
        Info.FirstInfo[0].isShow = false

        if (value === 1) {
            Info.FirstInfo[0].editable = {
                scroll: true,
                scrollWidth: 2500,
                dataSource: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        key: 'id',
                        width: '80px',
                        render: (text, record, index) => {
                            return (
                                <div>{index + 1}</div>
                            )
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'controls',
                        key: 'controls',
                        width: '100px',
                        render: (text, record, index) => {
                            return (
                                <div className='download' onClick={() => {
                                    this.handleDel(index, record.key)
                                }}>
                                    删除
                                </div>
                            )
                        }
                    },
                    {
                        title: '名称/工种',
                        dataIndex: 'name',
                        key: 'name',
                        width: '200px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '规格型号',
                        dataIndex: 'model',
                        key: 'model',
                        width: '200px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '计量单位',
                        dataIndex: 'unit',
                        key: 'unit',
                        width: '120px'
                    },
                    {
                        title: '清单总量',
                        dataIndex: 'number',
                        key: 'number',
                        width: '120px'
                    },
                    {
                        title: '本次提料量',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        width: '150px',
                        render: (text, record, index) =>
                            <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'quantity')}>
                                <Input />
                            </Form.Item>
                    },
                    {
                        title: '累计提料量',
                        dataIndex: 'material',
                        key: 'material',
                        width: '150px',
                        render: (text, record, index) => {
                            if (record.mat_status === 0) {
                                return (
                                    <div>{text}</div>
                                )
                            } else {
                                return (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '20px' }}>{text}</div>
                                        <img src={excess} alt=""></img>
                                    </div>
                                )
                            }

                        }
                    },
                    {
                        title: '累计合格到货量',
                        dataIndex: 'qualified',
                        key: 'qualified',
                        width: '180px',

                    },
                    {
                        title: '本次提料时间',
                        dataIndex: 'qua_time',
                        key: 'qua_time',
                        width: '150px',
                        render: (text, record, index) =>
                            <ConfigProvider locale={zhCN}>
                                <Form.Item name={'qua_time' + record.key} initialValue={text}>
                                    <DatePicker
                                        allowClear={false}
                                        format='YYYY-MM-DD'
                                        onChange={(e) => this.PickerChange(e, record.key, 'qua_time')}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                    },
                    {
                        title: '预留加工周期(天)',
                        dataIndex: 'machining_time',
                        key: 'machining_time',
                        width: '150px',
                        render: (text, record, index) =>
                            <Form.Item name={'machining_time' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'machining_time')}>
                                <Input />
                            </Form.Item>
                    },
                    {
                        title: '计划到货时间',
                        dataIndex: 'arrival_time',
                        key: 'arrival_time',
                        width: '150px',
                        render: (text, record, index) =>
                            <ConfigProvider locale={zhCN}>
                                <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                    <DatePicker
                                        allowClear={false}
                                        format='YYYY-MM-DD'
                                        onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                    },
                    {
                        title: '成本备注',
                        dataIndex: 'info',
                        key: 'info',
                        width: '120px'
                    },
                    {
                        title: '下料备注',
                        dataIndex: 'pic_info',
                        key: 'pic_info',
                        width: '500px',
                        render: (text, record, index) =>
                            <Form.Item name={'pic_info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.id, 'pic_info')}>
                                <Input />
                            </Form.Item>
                    },
                ]
            }
        } else if (value === 2) {
            Info.FirstInfo[0].editable = {
                dataSource: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        key: 'id',
                        width: '80px',
                        render: (text, record, index) => {
                            return (
                                <div>{index + 1}</div>
                            )
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'controls',
                        key: 'controls',
                        width: '100px',
                        render: (text, record, index) => {
                            return (
                                <div className='download' onClick={() => {
                                    this.handleDel(index, record.key)
                                }}>
                                    删除
                                </div>
                            )
                        }
                    },
                    {
                        title: '名称/工种',
                        dataIndex: 'name',
                        key: 'name',
                        width: '220px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '规格型号',
                        dataIndex: 'model',
                        key: 'model',
                        width: '220px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '计量单位',
                        dataIndex: 'unit',
                        key: 'unit',
                    },
                    {
                        title: '数量',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text, record, index) =>
                            <div>{record.number}</div>
                    },
                    {
                        title: '单价',
                        dataIndex: 'unit_price',
                        key: 'unit_price',
                    },
                    {
                        title: '备注',
                        dataIndex: 'info',
                        key: 'info',
                        width: '220px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                ]
            }
        } else if (value === 3) {
            Info.FirstInfo[0].editable = {
                dataSource: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        key: 'id',
                        width: '80px',
                        render: (text, record, index) => {
                            return (
                                <div>{index + 1}</div>
                            )
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'controls',
                        key: 'controls',
                        width: '100px',
                        render: (text, record, index) => {
                            return (
                                <div className='download' onClick={() => {
                                    this.handleDel(index, record.key)
                                }}>
                                    删除
                                </div>
                            )
                        }
                    },
                    {
                        title: '名称/工种',
                        dataIndex: 'name',
                        key: 'name',
                        width: '200px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '规格型号',
                        dataIndex: 'model',
                        key: 'model',
                        width: '200px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                    {
                        title: '计量单位',
                        dataIndex: 'unit',
                        key: 'unit',
                    },
                    {
                        title: '清单总量',
                        dataIndex: 'number',
                        key: 'number',
                    },
                    {
                        title: '数量',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text, record, index) =>
                            <Form.Item name={'quantity' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'quantity')}>
                                <Input />
                            </Form.Item>
                    },
                    {
                        title: '到场时间',
                        dataIndex: 'arrival_time',
                        key: 'arrival_time',
                        render: (text, record, index) =>
                            <ConfigProvider locale={zhCN}>
                                <Form.Item name={'arrival_time' + record.key} initialValue={text}>
                                    <DatePicker
                                        allowClear={false}
                                        format='YYYY-MM-DD'
                                        onChange={(e) => this.PickerChange(e, record.key, 'arrival_time')}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                    },
                    {
                        title: '备注',
                        dataIndex: 'info',
                        key: 'info',
                        width: '200px',
                        render: (text, record, index) => {
                            return (
                                <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            )
                        }
                    },
                ]
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    InputChange = (e, id, title) => {
        let { Info } = this.state;
        console.log(title)
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.id === id) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }), () => {
            let classValue = ''
            classValue = this.NewForm.current.formRef.current.getFieldsValue().class
            if (classValue === 1 && title === 'quantity') {
                this.get_blankingMaterialSelect(id, e.target.value)
            }
        })
    }

    PickerChange = (e, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = e.format('YYYY-MM-DD')
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.pro_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '选择集采专员') {
            form.cc_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '集采专员') {
                    item.value = value[0].username
                }
            })
        } else {
            if (Info.FirstInfo[0].editable.dataSource.length) {
                Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
                    value.forEach((em, ex) => {
                        if (em.key === item.id) {
                            value.splice(ex, 1)
                        }
                    })
                })

                value.forEach((item, index) => {
                    let key = Info.FirstInfo[0].editable.dataSource.length - 1
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                        id: item.key,
                        name: item.name,
                        unit: item.unit,
                        model: item.model,
                        number: item.number,
                        quantity: form.class === 2 ? item.number : '',
                        mat_status: 0,
                        material: item.cumulative,
                        qualified: item.qualified,
                        unit_price: item.unit_price,
                        qua_time: '',
                        machining_time: '',
                        arrival_time: '',
                        info: item.info,
                        pic_info: ''
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            } else {
                value.forEach((item, index) => {
                    const newData = {
                        key: Info.FirstInfo[0].editable.dataSource.length + 1,
                        id: item.key,
                        name: item.name,
                        unit: item.unit,
                        model: item.model,
                        number: item.number,
                        quantity: form.class === 2 ? item.number : '',
                        mat_status: 0,
                        material: item.cumulative,
                        qualified: item.qualified,
                        unit_price: item.unit_price,
                        qua_time: '',
                        machining_time: '',
                        arrival_time: '',
                        info: item.info,
                        pic_info: ''
                    };

                    Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
                })
            }
        }

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            material: '',
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择集采专员') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        } else {
            this.setState({
                material: value.material,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_projectMaterial()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            material: '',
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择集采专员') {
                this.get_InitialValue()
            } else {
                this.get_projectMaterial()
            }
        })
    }

    handleExport = () => {
        let { form } = this.state
        let data = {
            pick_id: form.table_id
        }
        axios.get('/api//blankingMaterialExport', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                })

                const link = document.createElement('a');
                link.download = '人才及下料单下料信息.xlsx'
                link.href = URL.createObjectURL(blob)
                link.target = "_blank"
                document.body.appendChild(link)
                link.click();
                //释放URL对象
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            } else {
                message.error('数据参数错误!')
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    InputBlur = (e, record, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.id === record.id) {
                em.price = e.target.value
                em.money = record.number * Number(e.target.value)
            }
        })
        this.setState(preState => ({
            Info,
            formKey: Math.random(),
        }), () => {
            this.get_addMaterialPrice(record.id, e.target.value)
        })
    }

    get_addMaterialPrice = (id, price) => {
        let { Info } = this.state;
        let data = {
            mac_id: id,
            price: price
        }

        addMaterialPrice(data).then(
            (res) => {
                if (!res.code) {
                    message.success('修改成功')
                } else {
                    message.error(res.data[0] || '价格添加失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'quantity' + e)()]: '',
            [(() => 'qua_time' + e)()]: '',
            [(() => 'machining_time' + e)()]: '',
            [(() => 'arrival_time' + e)()]: '',
            [(() => 'pic_info' + e)()]: '',
        })

        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                // if (index > key) {
                //     item.key = item.key - 1
                // }
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, formKey, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={formKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} exportEvent={this.handleExport} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
