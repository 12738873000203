import React, { Component } from 'react'
import axios from 'axios'
import { Button, Form, DatePicker, ConfigProvider, message } from 'antd';
import { withRouter } from "react-router-dom";
import AMapLoader from '@amap/amap-jsapi-loader';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import { SearchOutlined } from '@ant-design/icons';
import InitialValue from '../../components/InitialValue'
import Table from '../../components/Table'
import { allUserSignList } from "../../../utils/project"
import { selectUserName, getCompanyDeName } from "../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            icon: {
                type: "image",
                image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                size: [10, 15],
                anchor: "center",
            },
            markerList: [],
            modal_Visible: false,
            tableColumns: [
                {
                    title: '签到人',
                    dataIndex: 'apply_user',
                    key: 'apply_user',
                },
                {
                    title: '签到时间',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
                {
                    title: '签到地点',
                    dataIndex: 'address',
                    key: 'address',
                },
            ],
            dataSource: [],
            columns: [{
                title: '姓名',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, index) => {
                    if (text === '1') {
                        return (
                            <span> 男 </span>
                        )
                    } else {
                        return (<span> 女 </span>
                        )
                    }
                }
            },
            {
                title: '部门名称',
                dataIndex: 'deName',
                key: 'deName',
            },
            {
                title: '职位',
                dataIndex: 'company_job',
                key: 'company_job',
            },
            {
                title: '手机',
                dataIndex: 'phone',
                key: 'phone',
            },
            ],
            TableInfo: [{
                FieldTitle: '姓名', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '部门', //字段标题
                fieldName: 'de_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }],
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            FlowchartKey: 1,
            searchData: {
                user_id: '',
                userName: '',
                year: '',
                month: ''
            },
            isExport: false,
            isExportUser: false
        }
        this.map = null
    }

    formRef = React.createRef();

    componentDidMount() {
        let { isExportUser } = true
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            permission.forEach((item, index) => {
                if (item === 'exportUserSign') {
                    isExportUser = true
                }
            })
        }
        this.setState(preState => ({
            isExportUser
        }), () => {
            this.get_container()
        })
    }

    get_container = () => {
        AMapLoader.load({
            key: "e091f26fd34d16a2e7d93f6779b4b838",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
            //创建地图
            this.map = new AMap.Map("container", { //设置地图容器id
                viewMode: "2D",         //是否为3D地图模式
                zoom: 10,                //初始化地图级别       
            });

        }).catch(e => {
            console.log(e);
        })
    }

    get_labelMarker = () => {
        let { markerList } = this.state
        let _this = this
        AMapLoader.load({
            key: "e091f26fd34d16a2e7d93f6779b4b838",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
            //创建地图
            _this.map = new AMap.Map("container", { //设置地图容器id
                viewMode: "2D",         //是否为3D地图模式
                zoom: 10,                //初始化地图级别     
                center: [markerList[0].lng, markerList[0].lat], //初始化地图中心点位置      
            });

            //创建 LabelsLayer 图层
            const labelsLayer = new AMap.LabelsLayer({
                zooms: [3, 20],
                zIndex: 1000,
            });

            //设置一个图标对象
            const icon = {
                type: "image", //图标类型，现阶段只支持 image 类型
                image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                size: [10, 15],
                anchor: "center", //图片相对 position 的锚点，默认为 bottom-center
            };

            var labelMarkerList = []
            var marker = new AMap.Marker({
                anchor: 'bottom-center',
                offset: [0, -15],
            });

            markerList.forEach((item, index) => {
                var labelMarker = new AMap.LabelMarker({
                    position: [item.lng, item.lat],
                    icon: icon,
                });

                labelMarkerList.push(labelMarker)

                // 给labelMarker绑定事件
                labelMarker.on('mouseover', function (e) {
                    var position = e.data.data && e.data.data.position;
                    if (position) {
                        marker.setContent(
                            '<div class="pop_up">' +
                            '<div>' + item.apply_user + '</div>' +
                            '<div>' + '打卡时间:' + item.apply_date + '</div>' +
                            '<div>' + '打卡地点:' + item.address + '</div>' +
                            '</div>')
                        marker.setPosition(position);
                        _this.map.add(marker);
                    }
                });

                labelMarker.on('mouseout', function () {
                    _this.map.remove(marker);
                });
            })

            //添加一个 labelMarker
            labelsLayer.add(labelMarkerList);

            //将 LabelsLayer 添加到地图实例
            _this.map.add(labelsLayer);
        }).catch(e => {
            console.log(e);
        })
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.setState(preState => ({
                columns: [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ],
                TableInfo: [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }],
                FlowchartKey: Math.random(),
                isExport: false
            }), () => {
                this.get_InitialValue()
            })

        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { searchData, isExport } = this.state
        if (isExport) {
            if (value.length) {
                this.handleExportUser(value[0].key)
            }
        } else {
            if (value.length) {
                value.forEach((item, index) => {
                    searchData.userName = item.username
                    searchData.user_id = item.key
                })
            }
        }

        this.setState(preState => ({
            username: '',
            de_name: '',
            searchData,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    handleInquire = (data) => {
        let { searchData } = this.state
        let apply_date = data.apply_date ? data.apply_date.format('YYYY-MM') : ''
        if (!apply_date || !searchData.userName) {
            message.error('请选择签到人和签到时间!')
            return
        }
        let date = apply_date.split('-')
        searchData.year = date[0]
        searchData.month = date[1]
        this.setState(preState => ({
            searchData,
        }), () => {
            this.get_allUserSignList()
        })
    }

    get_clear = () => {
        this.formRef.current.resetFields(['apply_date'])
        this.map.destroy();
        this.map = null
        this.setState(preState => ({
            searchData: {
                user_id: '',
                userName: '',
                year: '',
                month: ''
            },
            markerList: []
        }), () => {
            this.get_container()
        })
    }

    get_allUserSignList = () => {
        let { searchData, markerList, map } = this.state
        let data = {
            user_id: searchData.user_id,
            year: searchData.year,
            month: searchData.month
        }
        allUserSignList(data).then(
            (res) => {
                if (!res.code) {
                    markerList = []
                    if (res.data.signs.length) {
                        res.data.signs.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                lng: item.long,
                                lat: item.lat,
                                apply_user: item.applyUsername,
                                apply_date: item.created_at,
                                address: item.address
                            }
                            markerList = [...markerList, value]
                        })
                    }
                    this.map.destroy();
                    this.map = null
                    this.setState(preState => ({
                        markerList,
                    }), () => {
                        if (markerList.length) {
                            this.get_labelMarker()
                        } else {
                            this.get_container()
                        }

                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_CompanyDeName = () => {
        let { dataSource } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    if (!res.code) {
                        dataSource = []
                        if (res.data.departments.length) {
                            res.data.departments.forEach((item, index) => {
                                let user = {
                                    key: item.id,
                                    deName: item.name,
                                }
                                dataSource = [...dataSource, user]
                            })
                        }
                        this.setState(preState => ({
                            dataSource,
                            pages: false,
                            modal_Visible: true,
                        }))
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleExport = () => {
        this.setState(preState => ({
            columns: [
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                }
            ],
            TableInfo: [],
            FlowchartKey: Math.random(),
            isExport: true
        }), () => {
            this.get_CompanyDeName()
        })
    }

    handleExportUser = (key) => {
        let FieldsValue = this.formRef.current.getFieldsValue()
        let apply_date = FieldsValue.apply_date ? FieldsValue.apply_date.format('YYYY-MM') : ''
        if (!apply_date) {
            message.error('请选择月份')
            return
        }
        let date = apply_date.split('-')
        let data = {
            year: date[0],
            month: date[1],
            de_id: key,
        }
        axios.get('/api/exportUserSign', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '出勤汇总.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            message.error('导出失败')
            console.log(error);
        })
    }

    render() {
        let { searchData, FlowchartKey, modal_Visible, dataSource, pages, columns, TableInfo, markerList, tableColumns, isExportUser } = this.state
        return (
            <div>
                <div className='searchForm'>
                    <Form ref={this.formRef} onFinish={this.handleInquire}>
                        <Form.Item
                            style={{ width: '48%' }}
                            label='签到人'
                            name="username"
                        >
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '70%' }}>
                                    <div className='defaultValue' style={{ backgroundColor: '#FFFFFF' }}>{searchData.userName}</div>
                                </div>
                                <div className='searchOutlined' style={{ backgroundColor: '#FFFFFF' }} onClick={() => {
                                    this.handButton('', true)
                                }}>
                                    <SearchOutlined style={{ color: '#339EF9' }} />
                                    <div>选择</div>
                                </div>
                            </div>
                        </Form.Item>
                        <ConfigProvider locale={zhCN}>
                            <Form.Item
                                style={{ width: '48%' }}
                                label="签到时间"
                                name="apply_date"
                            >
                                <DatePicker picker="month" />
                            </Form.Item>
                        </ConfigProvider>
                        <Form.Item className='ListSearch BasicData'>
                            <Button htmlType="submit">
                                <div>确定</div>
                            </Button>
                            <Button onClick={() => {
                                this.get_clear()
                            }}>
                                <div>清空</div>
                            </Button>

                            {
                                isExportUser ? <Button onClick={() => {
                                    this.handleExport()
                                }}>
                                    <div>导出</div>
                                </Button> : ''
                            }

                        </Form.Item>
                    </Form>
                </div>

                <div style={{ padding: '20px' }}>
                    <div id="container" className="container" style={{ height: '640px' }} >
                    </div>
                </div>



                <Table columns={tableColumns} dataSource={markerList} selection={true} />

                <div style={{ padding: '10px' }}></div>

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        );
    }
}

export default withRouter(List)
