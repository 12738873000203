import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Operate from '../../../../../components/Operate'
import Search from '../../../../../components/Search'
import Table from '../../../../../components/Table'
import Append from '../../../../../components/addAppend'
import { operatorList, getOperatorDetail, createOperator } from "../../../../../../utils/purchase"


export default class List extends Component {
    state = {
        searchShow: false,
        modal_Visible: false,
        dataSource: [],
        selectValue: [],
        columns: [
            {
                title: '机操手',
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_getOperatorDetail(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '操作类型',
                dataIndex: 'class',
                key: 'class',
            },
            {
                title: '所在项目',
                dataIndex: 'proName',
                key: 'proName',
            },
            {
                title: '所用机械',
                dataIndex: 'plantName',
                key: 'plantName',
            },
            {
                title: '油卡编号',
                dataIndex: 'oilCode',
                key: 'oilCode',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '机 操 手', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
        ],
        name: '',
        Info: {
            state: '新增',
            FlowModelName: "机操手信息",
            TableInfo: [{
                FieldTitle: '机操手', //字段标题
                fieldName: 'name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入机操手姓名', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '联系电话', //字段标题
                fieldName: 'phone', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '操作类型', //字段标题
                fieldName: 'class', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入操作类型', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }
            ],
            FirstInfo: []
        },
        pages: {
            limit: 8,
            page: 1,
            total: 0
        },
        form: {},
        FlowchartKey: 1
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_operatorList()
    }

    get_operatorList = () => {
        let { dataSource, pages, name } = this.state
        let data = {
            name: name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        operatorList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.operators.length) {
                        res.data.operators.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                name: item.operName,
                                phone: item.phone,
                                class: item.oper_class,
                                proName: item.proName || '/',
                                plantName: item.plantName || '/',
                                oilCode: item.oilCode || '/'
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_getOperatorDetail = (tableId, status) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        getOperatorDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    form.oper_user = res.data.oper_user
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }

                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        const { Info } = this.state;
        Info.state = '新增'

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_getOperatorDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true

                if (!value.enclosure.length) {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = value.enclosure.length ? value.enclosure : ''
            }
        })

        this.NewForm.current.formRef.current.setFieldsValue({
            name: value.name,
            phone: value.phone,
            class: value.oper_class,
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            item.isShow = false
            item.disabled = false
            item.value = ''
        })

        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                name: '',
                phone: '',
                class: '',
                enclosure: '',
            })
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_operatorList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
        }, () => {
            this.get_operatorList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_operatorList()
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.name = data.name
        form.phone = data.phone
        form.class = data.class

        createOperator(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.modalCancel()
                    this.get_operatorList()
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { searchShow, TableInfo, dataSource, columns, pages, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table key={FlowchartKey} columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />

                <Append ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} fileEvent={this.handFile} buttonEvent={this.handButton} />
            </div>
        )
    }
}
