import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createCardReplacement, lookCardRellacDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "补卡申请",
            TableInfo: [],
            FirstInfo: [{
                plateName: '基本信息',
                list: [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '申请日期', //字段标题
                        fieldName: 'apply_time', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择申请时间', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '部门名称', //字段标题
                        fieldName: 'deName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '职位名称', //字段标题
                        fieldName: 'jobName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '补卡时间', //字段标题
                        fieldName: 'time', //字段名称
                        fieldType: '时间区间',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '2023-01-01',
                        width: '50%',
                        child: [
                            {
                                FieldTitle: '开始时间', //字段标题
                                fieldName: 'start', //字段名称
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '结束时间', //字段标题
                                fieldName: 'end', //字段名称
                                value: '',
                                width: '50%',
                            },
                        ]
                    }, {
                        FieldTitle: '补卡原因', //字段标题
                        fieldName: 'reason', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择补卡原因', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            { value: 1, label: "忘刷卡", disabled: true },
                            { value: 2, label: "外出公干"},
                            { value: 3, label: "刷卡机故障" },
                        ]
                    },
                    {
                        FieldTitle: '位置信息', //字段标题
                        fieldName: 'address', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入位置信息', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true,
                    },
                    {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入备注信息', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true,
                    },
                ],
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_lookCardRellacDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '姓名') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门名称') {
                item.value = userInfo.deName
            } else if (item.FieldTitle === '职位名称') {
                item.value = userInfo.jobName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_lookCardRellacDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        lookCardRellacDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '姓名') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '职位名称') {
                            item.value = res.data.Detail.jobName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_time: res.data.Detail.apply_time ? moment(res.data.Detail.apply_time, 'YYYY-MM-DD') : '',
                        start: res.data.Detail.start ? moment(res.data.Detail.start, 'YYYY-MM-DD HH:mm:ss') : '',
                        end: res.data.Detail.end ? moment(res.data.Detail.end, 'YYYY-MM-DD HH:mm:ss') : '',
                        reason: res.data.Detail.reason,
                        info: res.data.Detail.info,
                        address: res.data.Detail.address,
                        enclosure: res.data.Detail.enclosure
                    })

                    if (res.data.Detail.reason === 2) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '位置信息' || item.FieldTitle === '附件') {
                                item.isShow = false
                            }
                        })
                    }

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_time = data.apply_time ? data.apply_time.format('YYYY-MM-DD') : ''
        form.start = data.start ? data.start.format('YYYY-MM-DD HH:mm:ss') : ''
        form.end = data.end ? data.end.format('YYYY-MM-DD HH:mm:ss') : ''
        form.reason = data.reason
        form.info = data.info
        form.address = data.address

        console.log(form)

        createCardReplacement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handSelector = (value) => {
        const { Info } = this.state;
        if (value === 2) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '位置信息' || item.FieldTitle === '附件') {
                    item.isShow = false
                }
            })
        } else {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '位置信息' || item.FieldTitle === '附件') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }


    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} fileEvent={this.handFile} selectorEvent={this.handSelector} auditEvent={this.handleButton} />
            </div>
        )
    }
}
