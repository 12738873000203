import http from '../utils/http';

//添加编辑设计文件移交及进度汇报
function createDesignHandover(data) {
    return http("post", '/api/createDesignHandover', data);
}

//设计文件移交及进度汇报列表
function DesignHandoverList(data) {
    return http("get", '/api/DesignHandoverList', data);
}

//设计文件移交及进度汇报详情
function DesignHandoverDetail(data) {
    return http("get", '/api/DesignHandoverDetail', data);
}

//获取设计进度汇总
function getDesignSummary(data) {
    return http("get", '/api/getDesignSummary', data);
}

//添加编辑设计进度汇总
function createDesignSummary(data) {
    return http("post", '/api/createDesignSummary', data);
}

export {
    createDesignHandover,
    DesignHandoverList,
    DesignHandoverDetail,
    getDesignSummary,
    createDesignSummary,
}
