import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { useVehicleList } from "../../../../utils/public"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '用车部门',
                    dataIndex: 'deName',
                    key: 'deName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '联系电话',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '用车时间',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text, index) => {
                        return (
                            <div className='timeStyle'>
                                <div>
                                    <div></div>
                                    <div>{text[0]}</div>
                                </div>
                                <div>
                                    <div></div>
                                    <div>{text[1]}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '驾驶员',
                    dataIndex: 'driverName',
                    key: 'driverName',
                },
                {
                    title: '跟车人员',
                    dataIndex: 'peopleNames',
                    key: 'peopleNames',
                },
                {
                    title: '用车事由',
                    dataIndex: 'reason',
                    key: 'reason',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            de_name: '',
            driver:'',
            start: '',
            end: '',
            TableInfo: [
                {
                    FieldTitle: '驾驶员', //字段标题
                    fieldName: 'driver', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '用车部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '用车时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_useVehicleList()
    }

    get_useVehicleList = () => {
        let { dataSource, pages, de_name,driver, start, end, process_state } = this.state
        let data = {
            de_name: de_name,
            driver:driver,
            start: start,
            end: end,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        useVehicleList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.vehicles.length) {
                        res.data.vehicles.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                deName: item.deName,
                                phone: item.phone,
                                time: [item.start,item.end],
                                driverName:item.driverName,  
                                peopleNames:item.peopleNames, 
                                reason:item.reason,                               
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/office/car/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            de_name: '',
            driver:'',
            start: '',
            end: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_useVehicleList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            de_name: value.de_name,
            driver: value.driver,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : ''
        }, () => {
            this.get_useVehicleList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_useVehicleList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_useVehicleList()
        })
    }

    render() {
        return (
            <div>
                <Operate dontAdd={true} notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
