import React, { Component } from 'react'
import { Input, Button, message } from 'antd';
import axios from 'axios'
import { userInfo } from "../../utils/api"
import logo_01 from "../../accets/img/logo_01.png"
import logo_02 from "../../accets/img/logo_02.png"
import logo_03 from "../../accets/img/logo_03.png"
import logo_04 from "../../accets/img/logo_04.png"
import logo_06 from "../../accets/img/logo_06.png"
import logo_07 from "../../accets/img/logo_07.png"




export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            password: ""
        }
    }

    userChange = (value) => {
        this.setState({
            name: value
        })
    }

    passwordChange = (value) => {
        this.setState({
            password: value
        })
    }

    get_Login = () => {
        axios({
            method: 'post',
            url: '/api/login',
            data: {
                name: this.state.name,
                password: this.state.password
            }
        }).then((res) => {
            if (!res.data.code) {
                localStorage.setItem('Token', res.data.data.Token)
                localStorage.setItem('permission', JSON.stringify(res.data.data.userInfo.permission))
                userInfo().then(
                    (response) => {
                        if (!response.code) {
                            let userInfo = {
                                name: response.data.name,
                                deName: response.data.deName,
                                deId:response.data.department_id,
                                jobName: response.data.jobName,
                                userId: response.data.table_id,
                                job_day: response.data.job_day,
                                education: response.data.education,
                                school: response.data.school,
                                major: response.data.major,
                            }
                            localStorage.setItem('userInfo', JSON.stringify(userInfo))
                            this.props.history.push('/index')
                        }
                    },
                    (error) => {
                        console.log("get response failed!");
                    }
                );
            } else {
                message.error(res.data.data[0] || res.data.msg || '登录失败!')
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        return (
            <div className='login'>
                <div>
                    <div>
                        <div>
                            <div>
                                <img src={logo_01} alt="" style={{ width: '54%' }}></img>
                                <img src={logo_02} alt=""></img>
                                <img src={logo_03} alt=""></img>
                            </div>
                            <img src={logo_04} alt=""></img>
                        </div>
                        <div>
                            <div>
                                <img src={logo_06} alt=""></img>
                                <img src={logo_07} alt=""></img>
                            </div>
                            <div>
                                <div>
                                    <div>账号:</div>
                                    <Input size="large" placeholder="请输入账号" onChange={(e) => this.userChange(e.target.value)} />
                                </div>
                                <div>
                                    <div>密码:</div>
                                    <Input.Password size="large" placeholder="请输入密码" onChange={(e) => this.passwordChange(e.target.value)} onPressEnter={() => {
                                        this.get_Login()
                                    }} />
                                </div>
                            </div>
                            <Button size="large" type="primary" onClick={() => {
                                this.get_Login()
                            }}>登录</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
