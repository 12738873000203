import React, { Component } from 'react'
// import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
//import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import Home from './views/home/Home'
import Login from './views/login/Login'

export default class App extends Component {

    render() {
        return (
            // <HashRouter>
            //     <Switch>
            //         <Route path="/login" component={Login}></Route>
            //         <Route path="/" render={() => {
            //             if (localStorage.getItem('Token')) {
            //                 return <Home />
            //             } else {
            //                 return <Redirect to="/login" />
            //             }
            //         }}></Route>
            //     </Switch>
            // </HashRouter >
            <Router>
                <Route path="/login" component={Login} />
                <Route path="/" render={() => {
                    if (localStorage.getItem('Token')) {
                        return <Home />
                    } else {
                        return <Redirect to="/login" />
                    }
                }} />
            </Router>
        )
    }
}
