import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { message } from 'antd';
import moment from 'moment';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import { researchProjectList, EditProjectOver } from "../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ cursor: 'pointer', width: '190px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '开工时间',
                    dataIndex: 'plant_open_day',
                    key: 'plant_open_day',
                },
                {
                    title: '竣工时间',
                    dataIndex: 'over_day',
                    key: 'over_day',
                },
                {
                    title: '工  期',
                    dataIndex: 'duration',
                    key: 'duration',
                },
                {
                    title: '中标金额',
                    dataIndex: 'select_money',
                    key: 'select_money',
                },
                {
                    title: '立项时间',
                    dataIndex: 'cre_day',
                    key: 'cre_day',
                },
                {
                    title: '项目进展',
                    dataIndex: 'is_over',
                    key: 'is_over',
                    render: (text, record, index) => {
                        if (record.status === 2) {
                            return (
                                <div>
                                    立项中
                                </div>
                            )
                        } else if (record.status === 4) {
                            if (text === 0) {
                                return (
                                    <div style={{ display: 'flex' }}>
                                        <div>施工中</div>
                                        <div style={{ color: '#339EF9', cursor: 'pointer', marginLeft: '15px' }} onClick={() => { this.get_projectOver(record, '添加') }}>竣工</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div style={{ display: 'flex' }}>
                                        <div>已竣工</div>
                                        <div style={{ color: '#339EF9', cursor: 'pointer', marginLeft: '15px' }} onClick={() => { this.get_projectOver(record, '编辑') }}>编辑</div>
                                    </div>
                                )
                            }

                        } else {
                            return (
                                <div>
                                    /
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pro_name: '',
            start_money: '',
            end_money: '',
            start_time: '',
            end_time: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            modal_Visible: false,
            Info: {
                state: '新增',
                FlowModelName: "竣工信息",
                TableInfo: [{
                    FieldTitle: '竣工日期', //字段标题
                    fieldName: 'over_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择竣工日期', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '人工工日', //字段标题
                    fieldName: 'peo_date', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入人工工日', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '人工成本(元)', //字段标题
                    fieldName: 'peo_cost', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入人工成本', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '结算金额(元)', //字段标题
                    fieldName: 'set_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入结算金额', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '结算面积(㎡)', //字段标题
                    fieldName: 'set_area', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入结算面积', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '其他费用', //字段标题
                    fieldName: 'other_cost', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '请输入其他费用', //必填要求
                    value: '',
                    width: '50%'
                },
                ],
                FirstInfo: []
            },
            form: {},
            modalKey: 1,
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_ProjectList()
    }

    get_ProjectList = () => {
        let { dataSource, pages, process_state, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        researchProjectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.researches.length) {
                        res.data.researches.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_name: item.pro_name,
                                cre_day: item.cre_day,
                                over_day: item.over_day,
                                plant_open_day: item.plant_open_day,
                                select_money: item.select_money,
                                duration: item.duration,
                                is_over: item.is_over,
                                over_time: item.over_time || '',
                                peo_date: item.peo_date || '',
                                peo_cost: item.peo_cost || '',
                                set_money: item.set_money || '',
                                set_area: item.set_area || '',
                                other_cost: item.other_cost || '',
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            total: res.data.pages.total,
                            limit: res.data.pages.limit
                        }
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/projectInitiation/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/projectInitiation/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            pro_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_ProjectList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
        }, () => {
            this.get_ProjectList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_ProjectList()
        })
    }

    get_projectOver = (event, status) => {
        let { Info } = this.state
        if (status === '编辑') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '竣工日期') {
                    item.value = moment(event.over_time, 'YYYY-MM-DD')
                } else if (item.FieldTitle === '人工工日') {
                    item.value = event.peo_date
                } else if (item.FieldTitle === '人工成本(元)') {
                    item.value = event.peo_cost
                } else if (item.FieldTitle === '结算金额(元)') {
                    item.value = event.set_money
                } else if (item.FieldTitle === '结算面积(㎡)') {
                    item.value = event.set_area
                }else if (item.FieldTitle === '其他费用') {
                    item.value = event.other_cost
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '竣工日期') {
                    item.value = ''
                } else if (item.FieldTitle === '人工工日') {
                    item.value = ''
                } else if (item.FieldTitle === '人工成本(元)') {
                    item.value = ''
                } else if (item.FieldTitle === '结算金额(元)') {
                    item.value = ''
                } else if (item.FieldTitle === '结算面积(㎡)') {
                    item.value = ''
                }else if (item.FieldTitle === '其他费用') {
                    item.value = ''
                }
            })
        }

        this.setState({
            modal_Visible: true,
            Info,
            form: {
                table_id: event.table_id
            }
        })

    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.over_time = data.over_time ? data.over_time.format('YYYY-MM-DD') : ''
        form.peo_date = data.peo_date
        form.peo_cost = data.peo_cost
        form.set_money = data.set_money
        form.set_area = data.set_area
        form.other_cost=data.other_cost

        EditProjectOver(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.modalCancel()
                    this.get_ProjectList()
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false,
            form: {},
            modalKey: Math.random(),
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Append key={this.state.modalKey} ref={this.NewForm} Info={this.state.Info} modal_Visible={this.state.modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} />
            </div>
        )
    }
}

export default withRouter(List)
