import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createWorkEat, workEatDetail } from "../../../../utils/public"
import { selectProjectName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/businessEntertainment.docx',
            templateName: '四川天艺生态园林集团股份有限公司业务招待申请',
            tempData: {
                user_name: '',
                deName: '',
                proName: '',
                meal_cost: '',
                is_tr: '',
                proName: '',
                pro_number: '',
                info: '',
                results: []
            },
            FlowModelName: "业务招待申请",
            TableInfo: [{
                FieldTitle: '申请人', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '所属部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            //{
            //     FieldTitle: '职务级别', //字段标题
            //     fieldName: 'job_level', //字段名称
            //     fieldType: '选择器',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%',
            //     selectList: [
            //         { value: '1', label: "普通员工" },
            //         { value: '2', label: "部门经理" },
            //         { value: '3', label: "副总经理(含总监)" },
            //         { value: '4', label: "总经理" },
            //     ]
            // }, {
            //     FieldTitle: '客户名称', //字段标题
            //     fieldName: 'customer', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '客户人数', //字段标题
            //     fieldName: 'cus_num', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '客户性质', //字段标题
            //     fieldName: 'cus_nature', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '就餐时间', //字段标题
            //     fieldName: 'eat_time', //字段名称
            //     fieldType: '日期',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '所在区域', //字段标题
            //     fieldName: 'region', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '就餐地点', //字段标题
            //     fieldName: 'eat_address', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, 
            {
                FieldTitle: '费用预估(元)', //字段标题
                fieldName: 'meal_cost', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入费用预估(元)', //必填要求
                value: '',
                width: '50%'
            },
            // {
            //     FieldTitle: '酒水费预估(元)', //字段标题
            //     fieldName: 'wine_cost', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, {
            //     FieldTitle: '其他费用预估(元)', //字段标题
            //     fieldName: 'other_cost', //字段名称
            //     fieldType: '文本',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: true, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%'
            // }, 
            {
                FieldTitle: '费用是否超限', //字段标题
                fieldName: 'is_tr', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择费用是否超限', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ]
            }, {
                FieldTitle: '关联项目', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'project_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'project_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '费用超限标准', //字段标题
                fieldName: 'standard', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '副总经理（含总监）级：单次消费合计1500元以内且人均不超过300元；\n部门经理级：单次消费合计600元以内且人均不超过200元；\n普通员工级：单次消费合计300元以内且人均不超过100元；',
                width: '100%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'managerName',
                key: 'managerName',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '95%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_workEatDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '所属部门') {
                item.value = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_workEatDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        workEatDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        if (res.data.Detail.status === 4) {
                            Info.tempData.user_name = res.data.Detail.apply_userName
                            Info.tempData.deName = res.data.Detail.deName
                            Info.tempData.meal_cost = res.data.Detail.meal_cost
                            Info.tempData.is_tr = res.data.Detail.is_tr === 1 ? '是' : '否'
                            Info.tempData.proName = res.data.Detail.projectName || '/'
                            Info.tempData.pro_number = res.data.Detail.projectNumber || '/'
                            Info.info = res.data.Detail.info
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '关联项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.apply_userName
                        } else if (item.FieldTitle === '所属部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.projectName
                            form.project_id = res.data.Detail.project_id
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.projectNumber
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        // job_level: res.data.Detail.job_level,
                        // customer: res.data.Detail.customer,
                        // cus_num: res.data.Detail.cus_num,
                        // cus_nature: res.data.Detail.cus_nature,
                        // eat_time: moment(res.data.Detail.eat_time, 'YYYY-MM-DD'),
                        // region: res.data.Detail.region,
                        // eat_address: res.data.Detail.eat_address,
                        meal_cost: res.data.Detail.meal_cost,
                        // wine_cost: res.data.Detail.wine_cost,
                        // other_cost: res.data.Detail.other_cost,
                        is_tr: res.data.Detail.is_tr,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        //form.job_level = data.job_level
        //form.customer = data.customer
        // form.cus_num = data.cus_num
        // form.cus_nature = data.cus_nature
        // form.eat_time = data.eat_time.format('YYYY-MM-DD HH:mm:ss')
        // form.region = data.region
        // form.eat_address = data.eat_address
        form.meal_cost = Number(data.meal_cost)
        // form.wine_cost = Number(data.wine_cost)
        // form.other_cost = Number(data.other_cost)
        form.is_tr = data.is_tr
        form.info = data.info

        createWorkEat(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
