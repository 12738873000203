import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { approvalProcess } from "../../utils/index"
import Table from '../components/Table'

class Process extends Component {
    state = {
        columns: [
            {
                title: '申请类型',
                dataIndex: 'functionName',
                key: 'functionName',
                render: (text, record, index) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_details(record)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '申请日期',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '流程状态',
                dataIndex: 'review_status',
                key: 'review_status',
                width: '218px',
                render: (text, index) => {
                    if (text === 2) {
                        return (
                            <div className='process-state'>
                                <div className='audit'>
                                    审核中
                                </div>
                            </div>
                        )
                    } else if (text === 3) {
                        return (
                            <div className='process-state'>
                                <div className='rejected'>
                                    已驳回
                                </div>
                            </div>
                        )
                    } else if (text === 4) {
                        return (
                            <div className='process-state'>
                                <div className='complete'>
                                    已完成
                                </div>
                            </div>
                        )
                    } else if (text === 5) {
                        return (
                            <div className='process-state'>
                                <div className='termination'>
                                    已终止
                                </div>
                            </div>
                        )
                    }
                }
            },
        ],
        dataSource: [],
        pages: {
            limit: 8,
            page: 1,
            total: 0
        },
    }

    componentDidMount() {
        this.get_approvalProcess()
    }

    get_details(item) {
        this.props.history.push({
            pathname: item.deteilUrl,
            query: {
                'tableId': item.table_id,
            }
        })   
    }

    get_approvalProcess = () => {
        let { pages, dataSource } = this.state
        let data = {
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        approvalProcess(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.flows.length) {
                        res.data.flows.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                deteilUrl: item.deteilUrl,
                                created_at: item.created_at,
                                functionName: item.functionName,
                                review_status: item.review_status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_approvalProcess()
        })
    }

    render() {
        return (
            <div>
                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(Process)
