import React, { Component } from 'react'
import { Tree, Dropdown, Menu, Input, Button, Modal, message } from 'antd';
import Icon from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { editDepartment, departmentList, deIdGetCompanyJob, createCompanyJob } from "../../../utils/public"
import group from "../../../accets/img/group.png"
import company from "../../../accets/img/company.png"

const groupImage = () => <img src={group} alt="" style={{ width: '24px' }}></img>
const companyImage = () => <img src={company} alt=""></img>
const GroupIcon = (props) => <Icon component={groupImage} {...props} />
const CompanyIcon = (props) => <Icon component={companyImage} {...props} />

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            modalShow: false,
            modalTitle: '用户组管理',
            de_id: '',
            groupForm: {},
            jobForm: {},
            groupList: [],
            dataSource: [],
            columns: [
                {
                    title: '职位名称',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '职位所含人员',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '权限',
                    dataIndex: 'authority',
                    key: 'authority',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#339EF9', cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.job_id)
                            }}>设置</div>
                        )
                    }
                }
            ],
            pages: {
                limit: 20,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            inputValue: '',
        }
    }

    componentDidMount() {
        this.get_departmentList()
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/administrative/userGroup/Authority',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_departmentList = () => {
        let { treeData, expandedKeys, selectedKeys } = this.state
        let data = {
            is_job: 0
        }
        treeData = []
        expandedKeys = []
        selectedKeys = []
        departmentList(data).then(
            (res) => {
                if (!res.code) {
                    res.data.departments.forEach((item, index) => {
                        let value = {
                            title: item.name,
                            key: item.id,
                            level: item.level,
                            children: [],
                            icon: <CompanyIcon />,
                        }
                        if (index === 0) {
                            expandedKeys.push(item.id)
                        }
                        if (item.parents) {
                            item.parents.forEach((tem, dex) => {
                                let children_01 = {
                                    title: tem.name,
                                    key: tem.id,
                                    level: tem.level,
                                    children: [],
                                    icon: <CompanyIcon />,
                                }
                                if (dex === 0) {
                                    children_01.icon = <GroupIcon />
                                    expandedKeys.push(tem.id)
                                }
                                if (tem.parents) {
                                    tem.parents.forEach((em, ex) => {
                                        let children_02 = {
                                            title: em.name,
                                            key: em.id,
                                            level: em.level,
                                            children: [],
                                            icon: <GroupIcon />,
                                        }
                                        if (ex === 0) {
                                            expandedKeys.push(em.id)
                                        }
                                        if (em.parents) {
                                            em.parents.forEach((m, x) => {
                                                let children_03 = {
                                                    title: m.name,
                                                    key: m.id,
                                                    level: m.level,
                                                    icon: <GroupIcon />,
                                                }
                                                if (x === 0) {
                                                    selectedKeys.push(m.id)
                                                    this.get_CompanyJob(selectedKeys)
                                                }
                                                children_02.children = [...children_02.children, children_03]
                                            })
                                        }
                                        children_01.children = [...children_01.children, children_02]
                                    })
                                }
                                value.children = [...value.children, children_01]
                            })
                        }
                        treeData = [...treeData, value]
                    })

                    this.setState(preState => ({
                        treeData,
                        expandedKeys,
                        selectedKeys,
                        groupList: res.data.departments,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_CompanyJob = (value) => {
        let { dataSource } = this.state
        let data = {
            de_id: value[0]
        }
        deIdGetCompanyJob(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.company_jobs.length) {
                        res.data.company_jobs.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                job_id: item.job_id,
                                company_job: item.company_job,
                                name: [],
                                authority: '',
                                copy: ''
                            }
                            if (item.users) {
                                item.users.forEach((em, ex) => {
                                    if (ex === 0) {
                                        rowValue.name = em.name
                                    } else {
                                        rowValue.name = rowValue.name + ' ' + em.name
                                    }
                                })
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onRightClick = (value) => {
        console.log(value)
        let groupForm = {
            level: value.node.level,
        }
        this.setState(preState => ({
            modalTitle: '用户组管理',
            de_id: value.node.key,
            groupForm
        })
        )
    }

    onSelect = (value) => {
        this.get_CompanyJob(value)
        this.setState(preState => ({
            selectedKeys: value
        })
        )
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    menu = () => (
        <Menu
            items={[
                {
                    key: 'add',
                    label: <span onClick={() => this.handleMenu('add')}>新建</span>,
                },
                {
                    key: 'update',
                    label: <span onClick={() => this.handleMenu('update')}>编辑</span>,
                },
            ]}
        />
    )

    titleRender = (nodeData) => {
        return (
            <Dropdown overlay={this.menu} trigger={['contextMenu']}>
                <div>{nodeData.title}</div>
            </Dropdown>
        );
    };

    handleModal = () => {
        this.setState(preState => ({
            modalShow: false
        })
        )
    }

    handleMenu = (type) => {
        let { groupList, groupForm, de_id } = this.state
        if (type === 'add') {
            groupForm.parent_id = de_id
            groupForm.deid = ''
            groupForm.level = Number(groupForm.level) + 1
        } else {
            if (groupForm.level === 1) {
                groupForm.parent_id = 0
            } else if (groupForm.level === 2) {
                groupList.forEach((item, index) => {
                    if (item.parents) {
                        item.parents.forEach((tem, dex) => {
                            if (tem.id === de_id) {
                                groupForm.parent_id = item.id
                            }
                        })
                    }
                })
            } else if (groupForm.level === 3) {
                groupList.forEach((item, index) => {
                    if (item.parents) {
                        item.parents.forEach((tem, dex) => {
                            if (tem.parents) {
                                tem.parents.forEach((em, ex) => {
                                    if (em.id === de_id) {
                                        groupForm.parent_id = tem.id
                                    }
                                })
                            }
                        })
                    }
                })
            } else if (groupForm.level === 4) {
                groupList.forEach((item, index) => {
                    if (item.parents) {
                        item.parents.forEach((tem, dex) => {
                            if (tem.parents) {
                                tem.parents.forEach((em, ex) => {
                                    if (em.parents) {
                                        em.parents.forEach((m, x) => {
                                            if (m.id === de_id) {
                                                groupForm.parent_id = em.id
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
            groupForm.deid = de_id
        }

        this.setState(preState => ({
            groupForm,
            modalShow: true
        })
        )
    }

    saveModal = () => {
        let { modalTitle, groupForm, jobForm, selectedKeys } = this.state
        if (modalTitle === '用户组管理') {
            console.log(groupForm)
            editDepartment(groupForm).then(
                (res) => {
                    if (!res.code) {
                        message.success('保存成功')
                        this.setState(preState => ({
                            modalShow: false,
                            inputValue: ''
                        }), () => {
                            this.get_departmentList()
                        })
                    } else {
                        message.error('保存失败')
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            createCompanyJob(jobForm).then(
                (res) => {
                    if (!res.code) {
                        message.success('保存成功')
                        this.setState(preState => ({
                            modalShow: false,
                            inputValue: ''
                        }), () => {
                            this.get_CompanyJob(selectedKeys)
                        })
                    } else {
                        message.error('保存失败')
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

    }

    InputChange = (value) => {
        let { modalTitle, groupForm, jobForm } = this.state
        if (modalTitle === '用户组管理') {
            groupForm.deName = value.target.value
        } else {
            jobForm.jobName = value.target.value
        }
        this.setState(preState => ({
            groupForm,
            jobForm,
            inputValue: value.target.value
        })
        )
    }

    handSelect = (value) => {
        console.log(value)
        let { jobForm } = this.state
        jobForm.job_id = value[0].job_id
    }

    handleNew = () => {
        let { selectedKeys } = this.state
        this.setState(preState => ({
            modalShow: true,
            modalTitle: '职位管理',
            jobForm: {
                de_id: selectedKeys[0],
                jobName: '',
                job_id: ''
            }
        })
        )
    }

    handleEdit = () => {
        let { selectedKeys, jobForm } = this.state
        if (selectedKeys.length) {
            jobForm.de_id = selectedKeys[0]
            jobForm.jobName = ''
            this.setState(preState => ({
                modalShow: true,
                modalTitle: '职位管理',
                jobForm
            })
            )
        }else{
            message.error('请选择职位!')
        }
    }

    handleSearch = () => {
        console.log('搜索')
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    searchQuery = () => {
        console.log('筛选')
    }

    render() {
        let { dataSource, columns, treeData, expandedKeys, selectedKeys, modalShow, modalTitle, inputValue } = this.state

        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        titleRender={this.titleRender}
                        treeData={treeData}
                        onRightClick={(value) => {
                            this.onRightClick(value)
                        }}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate seek={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} selectEvent={this.handSelect} />
                </div>

                <Modal
                    title='基础资料'
                    centered
                    visible={modalShow}
                    onCancel={() => this.handleModal()}
                    wrapClassName='weekly'
                    footer={[
                        <Button type="primary" onClick={() => this.saveModal()}>确认</Button>
                    ]}
                >
                    <div className='parameter'>
                        <div>{modalTitle}</div>

                        <div className='option'>
                            <div style={{ width: '92px' }}>{modalTitle === '用户组管理' ? '用户组名称' : '职位名称'}</div>
                            <Input value={inputValue} onChange={(e) => this.InputChange(e)} style={{ width: '420px' }} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(List)
