import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createTechnologyApplication, TechnologyApplicationDetail } from "../../../utils/scientific_research"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "科技项目申报",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '材料日志',
                    list: [
                        {
                            FieldTitle: '项目名称', //字段标题
                            fieldName: 'pro_name', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '项目类别', //字段标题
                            fieldName: 'pro_class', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                {
                                    value: 1,
                                    label: '课题项目'
                                },
                                {
                                    value: 2,
                                    label: '企业资质'
                                },
                                {
                                    value: 3,
                                    label: '情况汇报'
                                },
                                {
                                    value: 4,
                                    label: '荣誉奖项'
                                },
                                {
                                    value: 5,
                                    label: '奖励补贴'
                                }
                            ]
                        },
                        {
                            FieldTitle: '申报开始时间', //字段标题
                            fieldName: 'apply_date', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申报结束时间', //字段标题
                            fieldName: 'end_date', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申报方式', //字段标题
                            fieldName: 'apply_type', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            selectList: [
                                {
                                    value: 1,
                                    label: '自主申报'
                                },
                                {
                                    value: 2,
                                    label: '中介参与'
                                }
                            ]
                        },
                        {
                            FieldTitle: '有效期限', //字段标题
                            fieldName: 'valid_period', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申报方向', //字段标题
                            fieldName: 'dec_direction', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申报资金额度', //字段标题
                            fieldName: 'dec_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '补贴形式', //字段标题
                            fieldName: 'subsidy_form', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '主管机构', //字段标题
                            fieldName: 'authority', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '网址', //字段标题
                            fieldName: 'int_url', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '联系人', //字段标题
                            fieldName: 'contacts', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '职务', //字段标题
                            fieldName: 'job', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '固定电话', //字段标题
                            fieldName: 'tel_phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '电子邮箱', //字段标题
                            fieldName: 'email', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '传真', //字段标题
                            fieldName: 'fax', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '手机', //字段标题
                            fieldName: 'mobile_phone', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '申报要求', //字段标题
                            fieldName: 'apply_ask', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                        {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                        },
                    ],
                },
                {
                    plateName: '启动审批',
                    list: [
                        {
                            FieldTitle: '项目负责人', //字段标题
                            fieldName: 'pro_apply_user', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '申报单位', //字段标题
                            fieldName: 'apply_unit', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '代理单位', //字段标题
                            fieldName: 'agent_unit', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '代理费用(元)', //字段标题
                            fieldName: 'agent_money', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '付款方式', //字段标题
                            fieldName: 'pay_model', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        // {
                        //     FieldTitle: '申报条件', //字段标题
                        //     fieldName: 'apply_condition', //字段名称
                        //     fieldType: '文本域',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '100%'
                        // },
                    ]
                },
                {
                    plateName: '结果确认',
                    isShow: true,
                    list: [
                        {
                            FieldTitle: '申报结果', //字段标题
                            fieldName: 'result', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        }, {
                            FieldTitle: '认定有效期', //字段标题
                            fieldName: 'validity', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        }, {
                            FieldTitle: '资金额度(元)', //字段标题
                            fieldName: 'get_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        }, {
                            FieldTitle: '资金申请时间', //字段标题
                            fieldName: 'apply_time', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        }, {
                            FieldTitle: '获证清单', //字段标题
                            fieldName: 'cer_list', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                            isShow: true,
                        }, {
                            FieldTitle: '原因', //字段标题
                            fieldName: 'cer_list', //字段名称
                            fieldType: '文本',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            isShow: true,
                        }, {
                            FieldTitle: '附件', //字段标题
                            fieldName: 'confirm_result_enclosure', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ]
                }
            ]
        },

        form: {},
    }

    NewForm = React.createRef();

    componentDidMount() {
        let { form } = this.state
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_TechnologyApplicationDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_TechnologyApplicationDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        TechnologyApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true

                                if (em.FieldTitle === '附件') {
                                    if (!res.data.Detail.enclosure.length) {
                                        em.isShow = true
                                    }
                                }
                            })
                        })
                    }

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.confirm_result) {
                        Info.FirstInfo[2].isShow = false
                        if (res.data.Detail.confirm_result.result === '通过') {
                            Info.FirstInfo[2].list.forEach((item, index) => {
                                if (item.FieldTitle === '申报结果') {
                                    item.value = res.data.Detail.confirm_result.result
                                } else if (item.FieldTitle === '认定有效期') {
                                    item.isShow = false
                                    item.value = res.data.Detail.confirm_result.validity
                                } else if (item.FieldTitle === '资金额度(元)') {
                                    item.isShow = false
                                    item.value = res.data.Detail.confirm_result.get_money
                                } else if (item.FieldTitle === '资金申请时间') {
                                    item.isShow = false
                                    item.value = res.data.Detail.confirm_result.apply_time
                                } else if (item.FieldTitle === '获证清单') {
                                    item.isShow = false
                                    item.value = res.data.Detail.confirm_result.cer_list
                                } else if (item.FieldTitle === '附件') {
                                    item.value = res.data.Detail.confirm_result_enclosure
                                    if (!res.data.Detail.confirm_result_enclosure.length) {
                                        item.isShow = true
                                    }
                                }
                            })
                        } else {
                            Info.FirstInfo[2].list.forEach((item, index) => {
                                if (item.FieldTitle === '申报结果') {
                                    item.value = res.data.Detail.confirm_result.result
                                } else if (item.FieldTitle === '原因') {
                                    item.value = res.data.Detail.confirm_result.cer_list
                                    item.isShow = false
                                } else if (item.FieldTitle === '附件') {
                                    item.value = res.data.Detail.confirm_result_enclosure
                                    if (!res.data.Detail.confirm_result_enclosure.length) {
                                        item.isShow = true
                                    }
                                }
                            })
                        }
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        pro_class: res.data.Detail.pro_class,
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        end_date: res.data.Detail.end_date ? moment(res.data.Detail.end_date, 'YYYY-MM-DD') : '',
                        apply_type: res.data.Detail.apply_type,
                        valid_period: res.data.Detail.valid_period,
                        dec_direction: res.data.Detail.dec_direction,
                        dec_money: res.data.Detail.dec_money,
                        subsidy_form: res.data.Detail.subsidy_form,
                        int_url: res.data.Detail.int_url,
                        authority: res.data.Detail.authority,
                        contacts: res.data.Detail.contacts,
                        job: res.data.Detail.job,
                        tel_phone: res.data.Detail.tel_phone,
                        email: res.data.Detail.email,
                        fax: res.data.Detail.fax,
                        mobile_phone: res.data.Detail.mobile_phone,
                        apply_ask: res.data.Detail.apply_ask,
                        pro_apply_user: res.data.Detail.pro_apply_user,
                        apply_unit: res.data.Detail.apply_unit,
                        //apply_condition: res.data.Detail.apply_condition,
                        agent_unit: res.data.Detail.agent_unit,
                        agent_money: res.data.Detail.agent_money,
                        pay_model: res.data.Detail.pay_model,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.pro_class = data.pro_class
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.end_date = data.end_date ? data.end_date.format('YYYY-MM-DD') : ''
        form.apply_type = data.apply_type
        form.valid_period = data.valid_period
        form.dec_direction = data.dec_direction
        form.dec_money = data.dec_money
        form.subsidy_form = data.subsidy_form
        form.authority = data.authority
        form.int_url = data.int_url
        form.contacts = data.contacts
        form.job = data.job
        form.tel_phone = data.tel_phone
        form.email = data.email
        form.fax = data.fax
        form.mobile_phone = data.mobile_phone
        form.apply_ask = data.apply_ask
        form.pro_apply_user = data.pro_apply_user
        form.apply_unit = data.apply_unit
        //form.apply_condition = data.apply_condition
        form.agent_unit = data.agent_unit
        form.agent_money = data.agent_money
        form.pay_model = data.pay_model

        createTechnologyApplication(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
