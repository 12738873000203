import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import EchartsPie from './pie';
import EchartsChina from './china';
import InitialValue from '../../components/InitialValue'
import { selectProjectName, projectStatisticsInfo } from "../../../utils/api"
import { Value } from 'sass';



class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: true,
            dataSource: [],
            columns: [{
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
                width: '64%'
            },
            {
                title: '项目经理',
                dataIndex: 'managerName',
                key: 'managerName',
            },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }
            ],
            pro_id: '',
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            pro_item: {},
            FlowchartKey: 1,
            shinaData: [
                {
                    name: "北京",
                    value: 0
                },
                {
                    value: 0,
                    name: "广东"
                },
                {
                    value: 0,
                    name: "台湾"
                },
                {
                    value: 0,
                    name: "香港"
                },
                {
                    value: 0,
                    name: "山东"
                },
                {
                    value: 0,
                    name: "江苏"
                },
                {
                    value: 0,
                    name: "浙江"
                },
                {
                    value: 0,
                    name: "上海"
                },
                {
                    value: 0,
                    name: "河北"
                },
                {
                    value: 0,
                    name: "河南"
                },
                {
                    value: 0,
                    name: "辽宁"
                },
                {
                    value: 0,
                    name: "四川"
                },
                {
                    value: 0,
                    name: "湖北"
                },
                {
                    value: 0,
                    name: "湖南"
                },
                {
                    value: 0,
                    name: "安徽"
                },
                {
                    value: 0,
                    name: "吉林"
                },
                {
                    value: 0,
                    name: "江西"
                },
                {
                    value: 0,
                    name: "新疆"
                },
                {
                    value: 0,
                    name: "重庆"
                },
                {
                    value: 0,
                    name: "福建"
                },
                {
                    value: 0,
                    name: "广西"
                },
                {
                    value: 0,
                    name: "山西"
                },
                {
                    value: 0,
                    name: "云南"
                },
                {
                    value: 0,
                    name: "内蒙古"
                },
                {
                    value: 0,
                    name: "黑龙江"
                },
                {
                    value: 0,
                    name: "陕西"
                },
                {
                    value: 0,
                    name: "天津"
                },
                {
                    value: 0,
                    name: "宁夏"
                },
                {
                    value: 0,
                    name: "甘肃"
                },
                {
                    value: 0,
                    name: "贵州"
                },
                {
                    value: 0,
                    name: "西藏"
                },
                {
                    value: 0,
                    name: "青海"
                },
                {
                    value: 0,
                    name: "海南"
                }
            ]
        }
    }

    componentDidMount() {
        this.get_ProjectName()
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_projectStatisticsInfo = () => {
        let { pro_id, pro_item, shinaData } = this.state
        let data = {
            project_id: pro_id,
        }
        projectStatisticsInfo(data).then(
            (res) => {
                if (!res.code) {
                    pro_item = res.data
                    shinaData.forEach((item, index) => {
                        if (res.data.projectData.length) {
                            res.data.projectData.forEach((em, ex) => {
                                if (em.name.includes(item.name)) {
                                    item.value = em.number
                                }
                            })
                        }
                    })

                    this.setState(preState => ({
                        pro_item,
                        shinaData,
                        FlowchartKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectName()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handSelect = (value) => {
        let { pro_id } = this.state
        pro_id = value[0].key

        this.setState(preState => ({
            pro_id,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }), () => {
            this.get_projectStatisticsInfo()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectName()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectName()
        })
    }

    get_itemList(url) {
        let { pro_id } = this.state
        this.props.history.push({
            pathname: url,
            query: {
                'tableId': pro_id,
            }
        })
    }

    get_itemLists(url) {
        let { pro_id, pro_item } = this.state
        this.props.history.push({
            pathname: url,
            query: {
                'proName': pro_item.pro_name,
            }
        })
    }

    render() {
        let { modal_Visible, dataSource, columns, TableInfo, pages, pro_item, FlowchartKey, shinaData } = this.state
        return (
            <div className='control_table' key={FlowchartKey}>
                <div className='table-top'>
                    <div className='table-top-left'>
                        <div className="table-top-left-top">项目动态总控表</div>
                        <div className='table-top-left-buttom'>
                            <div className='pro_overview'>项目概况</div>
                            <div className='pro_introduce'>
                                <div>
                                    <div>工程名称:</div>
                                    <div>{pro_item.pro_name}</div>
                                </div>
                                <div>
                                    <div>工程类型:</div>
                                    <div>{pro_item.industry}</div>
                                </div>
                                <div>
                                    <div>工程状态:</div>
                                    <div>/</div>
                                </div>
                                <div>
                                    <div>业主单位:</div>
                                    <div>{pro_item.owner_unit}</div>
                                </div>
                                <div>
                                    <div>施工单位:</div>
                                    <div>{pro_item.award_unit}</div>
                                </div>
                                <div>
                                    <div>建管单位:</div>
                                    <div>/</div>
                                </div>
                                <div>
                                    <div>监理单位:</div>
                                    <div>/</div>
                                </div>
                                <div>
                                    <div>合同总金额:</div>
                                    <div>{pro_item.firstCon_money}</div>
                                </div>
                                <div>
                                    <div>合同日期:</div>

                                    {
                                        pro_item.plan_time ? <div className='contract_date'>
                                            <div>{pro_item.plan_time}</div>
                                            <div>{pro_item.complete_time}</div>
                                        </div> : ''
                                    }

                                </div>
                                {/* <div>
                                    <div>实际工期:</div>
                                    <div className='actual_date'>
                                        <div>2022-03-14</div>
                                        <div>2022-03-14</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='table-top-right'>
                        <div className='table-top-right-top'>
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.handButton('', true)
                            }}>选择项目</div>
                            <div className='con_name'>
                                <div>{pro_item.pro_name}</div>
                            </div>
                        </div>
                        <div className='table-top-right-buttom'>
                            <div className='pro_number'>
                                <div>
                                    <div>项目(在建)</div>
                                    <div>{pro_item.proBuildingNumber}</div>
                                </div>
                                <div>
                                    <div>项目(完工)</div>
                                    <div>{pro_item.proTotalNumber ? pro_item.proTotalNumber - pro_item.proBuildingNumber : ''}</div>
                                </div>
                                <div>
                                    <div>项目总数</div>
                                    <div>{pro_item.proTotalNumber}</div>
                                </div>
                            </div>

                            <div className='map'>
                                <EchartsChina data={shinaData} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-buttom'>
                    <div className='table-buttom-left'>
                        <div className='pro_overview'>项目成本/支出看板</div>
                        <div className='pro_cost'>
                            <div>
                                <div>合同金额(元)</div>
                                <div>预算金额(元)</div>
                                <div>支出总金额(元)</div>
                                <div>开票总金额(元)</div>
                                <div>实收总金额(元)</div>
                                <div>超支额(元)</div>
                                <div>超支率(%)</div>
                            </div>
                            <div>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.get_itemList('/project/contract/List')}>{pro_item.Con_money}</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.get_itemList('/cost/costbudget/List')}>{pro_item.budget_money}</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.get_itemLists('/project/settlements/List')}>{pro_item.pay_money}</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.get_itemList('/project/invoice/List')}>{pro_item.invo_money}</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => this.get_itemList('/cost/progressPayment/SummaryList')}>{pro_item.collection}</div>
                                <div>{pro_item.overexpenditure}</div>
                                <div>{pro_item.overexpenditure_rate}</div>
                            </div>
                        </div>
                    </div>
                    <div className='table-buttom-right'>
                        <div className='pro_overview'>材料超量预警</div>
                        <div className='mat_excess'>
                            <div>
                                <div>
                                    <EchartsPie project={pro_item} />
                                </div>
                                <div>
                                    <div>
                                        <div></div>
                                        <div>材料总量</div>
                                    </div>
                                    <div>
                                        <div></div>
                                        <div>材料超量</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>超量占比</div>
                                <div>{pro_item.material_excess || 0}%</div>
                            </div>
                        </div>
                    </div>
                </div>


                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
