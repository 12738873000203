import React from 'react';
import App from './App'
import { createRoot } from 'react-dom/client';
import './accets/css/style.css'
import 'antd/dist/antd.css';
import "@ant-design/flowchart/dist/index.css";


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <App />
);





