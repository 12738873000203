import http from '../utils/http';

//获取投标决策辅助选项
function auxiliary_options(data) {
    return http("get", '/api/auxiliary_options', data);
}

//新建/保存投标决策表
function createDecisionApproval(data) {
    return http("post", '/api/createDecisionApproval', data);
}

//决策审批列表
function decisionApprovalList(data) {
    return http("get", '/api/decisionApprovalList', data);
}

//获取投标决策详情
function getDecisionDetail(data) {
    return http("get", '/api/getDecisionDetail', data);
}

//获取保证金支付辅助项
function getMarginAuxiliary(data) {
    return http("get", '/api/getMarginAuxiliary', data);
}


//获取关联审批表
function getBiddingTable(data) {
    return http("get", '/api/getBiddingTable', data);
}

//获取关联投标决策审批表详情
function getAboutBiddingDetail(data) {
    return http("get", '/api/getAboutBiddingDetail', data);
}

//新建编辑保证金支付申请
function saveMarginApplication(data) {
    return http("post", '/api/saveMarginApplication', data);
}

//投标保证金支付申请列表
function biddingMarginList(data) {
    return http("get", '/api/biddingMarginList', data);
}

//投标保证金支付申请详情
function biddingMarginDetail(data) {
    return http("get", '/api/biddingMarginDetail', data);
}

//查询投标决策审批表
function selectBidding(data) {
    return http("get", '/api/selectBidding', data);
}

//保存中标通知书
function saveAwardBook(data) {
    return http("post", '/api/saveAwardBook', data);
}

//获取中标通知列表
function getAwardList(data) {
    return http("get", '/api/getAwardList', data);
}

//获取中标通知书详情
function getAwardDetail(data) {
    return http("get", '/api/getAwardDetail', data);
}

//获取项目立项辅助选项
function projectAuxiliary(data) {
    return http("get", '/api/projectAuxiliary', data);
}

//查询关联的项目中标通知书
function getProjectAwardList(data) {
    return http("get", '/api/getProjectAwardList', data);
}

//保存编辑中标立项
function saveCreateProject(data) {
    return http("post", '/api/saveCreateProject', data);
}

//获取立项项目列表
function getProjectList(data) {
    return http("get", '/api/getProjectList', data);
}

//获取立项项目详情
function getProjectDetail(data) {
    return http("get", '/api/getProjectDetail', data);
}

//获取收入合同审批辅助项
function getRevenueAuxiliary(data) {
    return http("get", '/api/getRevenueAuxiliary', data);
}

//获取印章
function getCompanySeal(data) {
    return http("get", '/api/getCompanySeal', data);
}

//查询中标立项
function getProjectBidding(data) {
    return http("get", '/api/getProjectBidding', data);
}

//保存编辑收入合同审批
function saveRevenue(data) {
    return http("post", '/api/saveRevenue', data);
}

//主合同查询
function parentRevenue(data) {
    return http("get", '/api/parentRevenue', data);
}

//公司项目查询
function revenueMain(data) {
    return http("get", '/api/revenueMain', data);
}

//合同列表查询
function RevenueSelect(data) {
    return http("get", '/api/RevenueSelect', data);
}


//查看收入合同审批详情
function RevenueDetail(data) {
    return http("get", '/api/RevenueDetail', data);
}

//添加编辑市场部设计项目申请
function createDesignMarkProject(data) {
    return http("post", '/api/createDesignMarkProject', data);
}

//市场部 设计项目申请列表
function DesignMarkProjectList(data) {
    return http("get", '/api/DesignMarkProjectList', data);
}

//市场部 设计项目申请详情
function DesignMarkProjectDetail(data) {
    return http("get", '/api/DesignMarkProjectDetail', data);
}

//市场部 设计项目列表查询(已审批通过按年分类)
function designPublicTaskList(data) {
    return http("get", '/api/designPublicTaskList', data);
}

//添加编辑市场部概算项目申请
function createEstimatesMarkProject(data) {
    return http("post", '/api/createEstimatesMarkProject', data);
}

//市场部 概算项目申请列表
function estimatesMarkProjectList(data) {
    return http("get", '/api/estimatesMarkProjectList', data);
}

//市场部 概算项目申请详情
function estimatesMarkProjectDetail(data) {
    return http("get", '/api/estimatesMarkProjectDetail', data);
}



export {
    auxiliary_options,
    createDecisionApproval,
    decisionApprovalList,
    getDecisionDetail,
    getMarginAuxiliary,
    getBiddingTable,
    getAboutBiddingDetail,
    saveMarginApplication,
    biddingMarginList,
    biddingMarginDetail,
    selectBidding,
    saveAwardBook,
    getAwardList,
    getAwardDetail,
    projectAuxiliary,
    getProjectAwardList,
    saveCreateProject,
    getProjectList,
    getProjectDetail,
    getRevenueAuxiliary,
    getCompanySeal,
    getProjectBidding,
    saveRevenue,
    parentRevenue,
    revenueMain,
    RevenueSelect,
    RevenueDetail,
    createDesignMarkProject,
    DesignMarkProjectList,
    DesignMarkProjectDetail,
    designPublicTaskList,
    createEstimatesMarkProject,
    estimatesMarkProjectList,
    estimatesMarkProjectDetail
}
