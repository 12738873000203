import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { auxiliary_options, createDecisionApproval, getDecisionDetail } from "../../../utils/market"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "投标决策审批表",
            TableInfo: [
                {
                    FieldTitle: '联营自营', //字段标题
                    fieldName: 'operation', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择联营自营', //必填要求
                    value: '',
                    width: '50%',
                    radioList: []
                }, {
                    FieldTitle: '申请编号', //字段标题
                    fieldName: 'number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择申请日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '工程编号', //字段标题
                    fieldName: 'eng_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入项目名称', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '项目地址', //字段标题
                    fieldName: 'pro_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入项目地址', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '建设单位(招标人)', //字段标题
                    fieldName: 'con_unit', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入建设单位(招标人)', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '集团名称', //字段标题
                    fieldName: 'group_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '招标代理名称', //字段标题
                    fieldName: 'bidding_agency', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '开标时间', //字段标题
                    fieldName: 'opening_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择开标时间', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '资质要求', //字段标题
                    fieldName: 'qua_require', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '工期(天)', //字段标题
                    fieldName: 'con_period', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入工期(天)', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '项目性质', //字段标题
                    fieldName: 'pro_nature', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择项目性质', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '项目经理资质要求', //字段标题
                    fieldName: 'manager_qua', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入项目经理资质要求', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '技术负责人资质要求', //字段标题
                    fieldName: 'technical_qua', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入技术负责人资质要求', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '项目管理人员资质要求', //字段标题
                    fieldName: 'management_qua', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '答辩团队要求', //字段标题
                    fieldName: 'defense_team', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '业绩要求', //字段标题
                    fieldName: 'per_requirements', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入业绩要求', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '样品要求', //字段标题
                    fieldName: 'sample_request', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入样品要求', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '投标保证金金额', //字段标题
                    fieldName: 'tender_bond', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入投标保证金金额', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '投标保证金退还时间', //字段标题
                    fieldName: 'tender_refund_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '履约保证金金额', //字段标题
                    fieldName: 'bid_bond_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入履约保证金金额', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '履约保证金退还时间', //字段标题
                    fieldName: 'bid_refund_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }
            ],
            FirstInfo: [{
                plateName: '付款条件',
                list: [
                    {
                        FieldTitle: '预付款', //字段标题
                        fieldName: 'advance_charge', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入预付款', //必填要求
                        value: '',
                        width: '100%'
                    }, {
                        FieldTitle: '进度款', //字段标题
                        fieldName: 'progress_payment', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入进度款', //必填要求
                        value: '',
                        width: '100%'
                    }, {
                        FieldTitle: '质保期', //字段标题
                        fieldName: 'guarantee_period', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入质保期', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '养护期', //字段标题
                        fieldName: 'curing_period', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入养护期', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '质保金(合同总价的x%)', //字段标题
                        fieldName: 'guarantee_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入质保金(合同总价的x%)', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '踏勘要求', //字段标题
                        fieldName: 'survey_requirements', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '合同履约性质', //字段标题
                        fieldName: 'performance_nature', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        selectList: []
                    }, {
                        FieldTitle: '其他隐性要求', //字段标题
                        fieldName: 'hidden_conditions', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '开标要求', //字段标题
                        fieldName: 'bid_opening_requirements', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'remarks', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ]
            }],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_auxiliary()
    }

    get_auxiliary = () => {
        const { Info } = this.state;
        auxiliary_options().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '联营自营') {
                            item.radioList = []
                            for (let key in res.data.operationMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operationMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.FieldTitle === '项目性质') {
                            item.selectList = []
                            for (let key in res.data.projectNature) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectNature[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '合同履约性质') {
                            item.selectList = []
                            for (let key in res.data.naturePerformance) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.naturePerformance[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_DecisionDetail(tableId)
                        } else {
                            this.get_userInfo()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_DecisionDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        getDecisionDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.number = res.data.Detail.number
                        form.eng_number = res.data.Detail.eng_number
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })                     
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.editPermission) {
                        Info.state = 10

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = false
                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = false

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = false
                                }
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '申请编号') {
                            item.value = res.data.Detail.number
                        } else if (item.FieldTitle === '工程编号') {
                            item.value = res.data.Detail.eng_number
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        operation: res.data.Detail.operation,
                        apply_day: res.data.Detail.apply_day ? moment(res.data.Detail.apply_day, 'YYYY-MM-DD') : '',
                        pro_name: res.data.Detail.pro_name,
                        pro_address: res.data.Detail.pro_address,
                        con_unit: res.data.Detail.con_unit,
                        group_name: res.data.Detail.group_name,
                        bidding_agency: res.data.Detail.bidding_agency,
                        opening_time: res.data.Detail.opening_time ? moment(res.data.Detail.opening_time, 'YYYY-MM-DD') : '',
                        qua_require: res.data.Detail.qua_require,
                        con_period: res.data.Detail.con_period,
                        pro_nature: res.data.Detail.pro_nature,
                        manager_qua: res.data.Detail.manager_qua,
                        technical_qua: res.data.Detail.technical_qua,
                        management_qua: res.data.Detail.management_qua,
                        defense_team: res.data.Detail.defense_team,
                        per_requirements: res.data.Detail.per_requirements,
                        sample_request: res.data.Detail.sample_request,
                        tender_bond: res.data.Detail.tender_bond,
                        tender_refund_time: res.data.Detail.tender_refund_time ? moment(res.data.Detail.tender_refund_time, 'YYYY-MM-DD') : '',
                        bid_bond_money: res.data.Detail.bid_bond_money,
                        bid_refund_time: res.data.Detail.bid_refund_time ? moment(res.data.Detail.bid_refund_time, 'YYYY-MM-DD') : '',
                        advance_charge: res.data.Detail.advance_charge,
                        progress_payment: res.data.Detail.progress_payment,
                        guarantee_period: res.data.Detail.guarantee_period,
                        curing_period: res.data.Detail.curing_period,
                        guarantee_money: res.data.Detail.guarantee_money,
                        survey_requirements: res.data.Detail.survey_requirements,
                        performance_nature: res.data.Detail.performance_nature,
                        hidden_conditions: res.data.Detail.hidden_conditions,
                        bid_opening_requirements: res.data.Detail.bid_opening_requirements,
                        remarks: res.data.Detail.remarks,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.operation = data.operation
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.pro_name = data.pro_name
        form.pro_address = data.pro_address
        form.con_unit = data.con_unit
        form.group_name = data.group_name
        form.bidding_agency = data.bidding_agency
        form.opening_time = data.opening_time ? data.opening_time.format('YYYY-MM-DD') : ''
        form.qua_require = data.qua_require
        form.con_period = data.con_period
        form.pro_nature = data.pro_nature
        form.manager_qua = data.manager_qua
        form.technical_qua = data.technical_qua
        form.management_qua = data.management_qua
        form.defense_team = data.defense_team
        form.per_requirements = data.per_requirements
        form.sample_request = data.sample_request
        form.tender_bond = data.tender_bond
        form.tender_refund_time = data.tender_refund_time ? data.tender_refund_time.format('YYYY-MM-DD') : ''
        form.bid_bond_money = data.bid_bond_money
        form.bid_refund_time = data.bid_refund_time ? data.bid_refund_time.format('YYYY-MM-DD') : ''
        form.advance_charge = data.advance_charge
        form.progress_payment = data.progress_payment
        form.guarantee_period = data.guarantee_period
        form.curing_period = data.curing_period
        form.guarantee_money = data.guarantee_money
        form.survey_requirements = data.survey_requirements
        form.performance_nature = data.performance_nature
        form.hidden_conditions = data.hidden_conditions
        form.bid_opening_requirements = data.bid_opening_requirements
        form.remarks = data.remarks

        createDecisionApproval(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName,fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
