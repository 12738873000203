import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Table from '../../components/Table'
import { previewNotice, IndexNoticeList } from "../../../utils/index"


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '标题',
                    dataIndex: 'notice_name',
                    key: 'notice_name',
                    width:'35%',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_previewNotice(record)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '发布时间',
                    dataIndex: 'public_day',
                    key: 'public_day',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_IndexNoticeList()
    }

    get_IndexNoticeList = () => {
        let { dataSource, pages, } = this.state
        let data = {
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        IndexNoticeList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.notices.length) {
                        res.data.notices.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                notice_name: item.notice_name,
                                public_day: item.public_day,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_previewNotice = (file) => {
        let data = {
            table_id: file.table_id
        }
        previewNotice(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_IndexNoticeList()
        })
    }

    render() {
        return (
            <div>
                <Table selection={true} columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
