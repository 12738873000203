import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import { createStandard, assessmentDetail } from "../../../../utils/oa"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
	state = {
		Info: {
			state: '',
			FlowModelName: "考核标准",
			TableInfo: [{
				FieldTitle: '标准名称', //字段标题
				fieldName: 'filename', //字段名称
				fieldType: '文本', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请输入标准名称', //必填要求
				value: '',
				width: '50%'
			}, {
				FieldTitle: '发布日期', //字段标题
				fieldName: 'public_date', //字段名称
				fieldType: '日期', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请选择发布日期', //必填要求
				value: '',
				width: '50%'
			}, {
				FieldTitle: '生效日期', //字段标题
				fieldName: 'use_date', //字段名称
				fieldType: '日期', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请选择生效日期', //必填要求
				value: '',
				width: '50%'
			}, {
				FieldTitle: '附件', //字段标题
				fieldName: 'enclosure', //字段名称
				fieldType: '附件', //字段类型
				disabled: false, //字段是否禁用
				mandatory: false, //字段是否必填
				required: '', //必填要求
				value: '',
				width: '100%'
			},],
			FirstInfo: [],
			process: {
				reviewAudit: [],
				ccReading: [],
				audit: false,
				edit: false
			}
		},
		form: {}
	}

	NewForm = React.createRef();

	componentDidMount() {
		if (this.props.location.query) {
			let tableId = this.props.location.query.tableId
			this.get_assessmentDetail(tableId)
		} else {
			this.get_FormState()
		}
	}

	get_FormState = () => {
		const { Info } = this.state;
		Info.state = 1
		this.setState(preState => ({
			Info
		}))
	}

	get_assessmentDetail = (tableId) => {
		const { Info, form } = this.state;
		let data = {
			table_id: tableId
		}
		assessmentDetail(data).then(
			(res) => {
				if (!res.code) {
					if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
						form.table_id = tableId
						Info.process.edit = res.data.Detail.editPermission
					} else {
						Info.TableInfo.forEach((item, index) => {
							item.disabled = true
						})
					}

					Info.state = res.data.Detail.status
					Info.process.data = res.data.Detail.reviewAudit
					Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
					Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
					Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

					Info.TableInfo.forEach((item, index) => {
						if (item.FieldTitle === '附件') {
							item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure :
								''
						}
					})

					this.NewForm.current.formRef.current.setFieldsValue({
						public_date: res.data.Detail.public_date ? moment(res.data.Detail.public_date, 'YYYY-MM-DD') : '',
						use_date: res.data.Detail.use_date ? moment(res.data.Detail.use_date, 'YYYY-MM-DD HH:mm:ss') : '',
						filename: res.data.Detail.filename,
						enclosure: res.data.Detail.enclosure
					})

					this.setState(preState => ({
						Info,
						form
					}))
				} else {
					message.error(res.data[0] || '数据获取失败!')
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handPresent = (data, status) => {
		const { form, Info } = this.state;
		form.status = status
		form.filename = data.filename
		form.public_date = data.public_date ? data.public_date.format('YYYY-MM-DD HH:mm:ss') : ''
		form.use_date = data.use_date ? data.use_date.format('YYYY-MM-DD HH:mm:ss') : ''

		createStandard(form).then(
			(res) => {
				if (!res.code) {
					message.success(form.status === 1 ? '暂存成功' : '提交成功')
					// if (form.status === 2) {
					// 	setTimeout(() => {
					// 		window.history.back(-1)
					// 	})
					// }
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '提交失败,请检查表单!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handleButton = (type, value) => {
		let { Info } = this.state
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		let data = {
			tableName: '',
			table_id: '',
			reviewResult: type,
			opinion: value,
			review_id: '',
		}

		Info.process.reviewAudit.forEach((item, index) => {
			if (item.user_id === userInfo.userId) {
				data.tableName = item.table_name
				data.table_id = item.table_id
				data.review_id = item.id
			}
		})
		reviewApplyResult(data).then(
			(res) => {
				if (!res.code) {
					message.success('审核成功!')
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '审核失败!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handFile = (storeList, fileName, fileValue) => {
		let { form, Info } = this.state
		let fileList = []
		if (form.table_id) {
			Info.TableInfo.forEach((item, index) => {
				if (item.fieldName === fileName) {
					if (item.value && item.value.length) {
						item.value.forEach((em, ex) => {
							storeList.forEach((m, x) => {
								if (em.table_id === m) {
									fileList.push(em)
								}
							})
						})
					}
					if (fileValue) {
						fileList.push(fileValue)
					}
					item.value = fileList
				}
			})
		}
		form[fileName] = JSON.stringify(storeList)
		this.setState(preState => ({
			form,
			Info
		}))
	}

	render() {
		return (
			<div className='configAdd' >
				<AddConfig ref={this.NewForm}
					Info={this.state.Info}
					presentEvent={
						this.handPresent
					}
					fileEvent={
						this.handFile
					}
					auditEvent={
						this.handleButton
					}
				/>
			</div>
		)
	}
}
