import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import moment from 'moment';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import Append from '../../components/addAppend'

import { MaintenanceLogList, createMaintenanceLog, MaintenanceLogDetail, MaintenanceTallInspectionList } from "../../../utils/scientific_research"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: ' 巡查人员',
                    dataIndex: 'main_users',
                    key: 'main_users',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_MaintenanceLogDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '巡查日期',
                    dataIndex: 'main_day',
                    key: 'main_day',
                },
                {
                    title: '问题总结',
                    dataIndex: 'summarize',
                    key: 'summarize',
                },
                {
                    title: '解决措施',
                    dataIndex: 'answer',
                    key: 'answer',
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            pro_id: '',
            proList: [],
            treeData: [
                {
                    title: '四川天艺生态园林集团股份有限公司',
                    key: 0,
                    icon: <FolderIcon />,
                    children: []
                }
            ],
            expandedKeys: [0],
            selectedKeys: [],
            Info: {
                state: '新增',
                FlowModelName: "立体绿化项目养护巡查表",
                TableInfo: [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '项目地址', //字段标题
                    fieldName: 'pro_address', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '巡查人员', //字段标题
                    fieldName: 'main_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '巡查日期', //字段标题
                    fieldName: 'main_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }
                ],
                FirstInfo: [
                    {
                        plateName: '巡查情况',
                        list: [
                            // {
                            //     FieldTitle: '水箱水量是否正常', //字段标题
                            //     fieldName: 'patrol_01', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '水箱过滤器是否清晰', //字段标题
                            //     fieldName: 'patrol_02', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '水泵是否正常', //字段标题
                            //     fieldName: 'patrol_03', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '控制器是否正常', //字段标题
                            //     fieldName: 'patrol_04', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '电磁阀是否正常', //字段标题
                            //     fieldName: 'patrol_05', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '传感器是否正常', //字段标题
                            //     fieldName: 'patrol_06', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '水压是否正常', //字段标题
                            //     fieldName: 'patrol_07', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '滴头滴箭是否正常', //字段标题
                            //     fieldName: 'patrol_08', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '土壤温湿度是否正常', //字段标题
                            //     fieldName: 'patrol_09', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '植物是否正常生长', //字段标题
                            //     fieldName: 'patrol_10', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '监控探头是否正常', //字段标题
                            //     fieldName: 'patrol_11', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '排水槽是否通畅', //字段标题
                            //     fieldName: 'patrol_12', //字段名称
                            //     fieldType: '选择器',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '50%',
                            //     selectList: [
                            //         {
                            //             value: 1,
                            //             label: '是'
                            //         },
                            //         {
                            //             value: 2,
                            //             label: '否'
                            //         },
                            //     ]
                            // },
                            // {
                            //     FieldTitle: '其他情况', //字段标题
                            //     fieldName: 'other_info', //字段名称
                            //     fieldType: '文本',  //字段类型
                            //     disabled: false,  //字段是否禁用
                            //     mandatory: false, //字段是否必填
                            //     required: '', //必填要求
                            //     value: '',
                            //     width: '100%'
                            // },
                            {
                                FieldTitle: '问题总结', //字段标题
                                fieldName: 'summarize', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '解决措施', //字段标题
                                fieldName: 'answer', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '备注说明', //字段标题
                                fieldName: 'info', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'enclosure', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                        ]
                    },
                ]
            },
            selectValue: '',
            form: {},
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_MaintenanceTallInspectionList()
    }

    get_MaintenanceTallInspectionList = () => {
        let { treeData, pro_id, selectedKeys } = this.state
        MaintenanceTallInspectionList().then(
            (res) => {
                if (!res.code) {
                    treeData = [
                        {
                            title: '四川天艺生态园林集团股份有限公司',
                            key: 0,
                            icon: <FolderIcon />,
                            children: []
                        }
                    ]
                    selectedKeys = []
                    res.data.proList.forEach((item, index) => {
                        let value = {
                            title: item.pro_name,
                            key: item.table_id,
                            icon: <FoldersIcon />,
                        }
                        if (index === 0) {
                            pro_id = item.table_id
                            selectedKeys = [...selectedKeys, item.table_id]
                        }
                        treeData[0].children = [...treeData[0].children, value]
                    })

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        proList: res.data.proList
                    }, () => {
                        this.get_MaintenanceLogList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_MaintenanceLogList = () => {
        let { pro_id, dataSource, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            main_id: pro_id,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        MaintenanceLogList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.logs.length) {
                        res.data.logs.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                main_day: item.main_day,
                                main_users: item.main_users,
                                summarize: item.summarize,
                                answer: item.answer,
                                info: item.info,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info, pro_id, proList } = this.state

        proList.forEach((item, index) => {
            if (item.table_id === pro_id) {
                item.disabled = false
                Info.TableInfo.forEach((em, ex) => {
                    if (em.FieldTitle === '项目名称') {
                        em.value = item.pro_name
                    } else if (em.FieldTitle === '项目地址') {
                        em.value = item.pro_address
                    } else if (em.FieldTitle === '项目经理') {
                        em.value = item.pro_manager
                    }
                })
            }
        })

        Info.FirstInfo[0].list.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_MaintenanceLogDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_MaintenanceLogDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        MaintenanceLogDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true
            })

            Info.FirstInfo[0].list.forEach((item, index) => {
                item.disabled = true

                if (!value.enclosure.length) {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })

            Info.FirstInfo[0].list.forEach((item, index) => {
                item.disabled = false
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value.inspection.pro_name
            } else if (item.FieldTitle === '项目地址') {
                item.value = value.inspection.pro_address
            } else if (item.FieldTitle === '项目经理') {
                item.value = value.inspection.pro_manager
            }
        })

        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = value.enclosure
            }
        })

        value.information = JSON.parse(value.information)

        this.NewForm.current.formRef.current.setFieldsValue({
            main_user: value.main_users,
            main_day: value.main_day ? moment(value.main_day, 'YYYY-MM-DD') : '',
            patrol_01: value.information[0].patrol_01,
            patrol_02: value.information[0].patrol_02,
            patrol_03: value.information[0].patrol_03,
            patrol_04: value.information[0].patrol_04,
            patrol_05: value.information[0].patrol_05,
            patrol_06: value.information[0].patrol_06,
            patrol_07: value.information[0].patrol_07,
            patrol_08: value.information[0].patrol_08,
            patrol_09: value.information[0].patrol_09,
            patrol_10: value.information[0].patrol_10,
            patrol_11: value.information[0].patrol_11,
            patrol_12: value.information[0].patrol_12,
            other_info: value.other_info,
            summarize: value.summarize,
            answer: value.answer,
            info: value.info,
        })

        this.setState({
            Info
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_MaintenanceLogList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_MaintenanceLogList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_MaintenanceLogList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handPresent = (data, status) => {
        const { form, pro_id } = this.state;
        form.main_id = pro_id
        form.main_user = data.main_user
        form.main_day = data.main_day ? data.main_day.format('YYYY-MM-DD') : ''
        form.information = []
        let value = {
            patrol_01: data.patrol_01,
            patrol_02: data.patrol_02,
            patrol_03: data.patrol_03,
            patrol_04: data.patrol_04,
            patrol_05: data.patrol_05,
            patrol_06: data.patrol_06,
            patrol_07: data.patrol_07,
            patrol_08: data.patrol_08,
            patrol_09: data.patrol_09,
            patrol_10: data.patrol_10,
            patrol_11: data.patrol_11,
            patrol_12: data.patrol_12,
        }
        form.information = [...form.information, value]
        form.information = JSON.stringify(form.information)
        form.other_info = data.other_info
        form.summarize = data.summarize
        form.answer = data.answer
        form.info = data.info

        createMaintenanceLog(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_MaintenanceInspectionList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                main_user: '',
                main_day: '',
                patrol_01: '',
                patrol_02: '',
                patrol_03: '',
                patrol_04: '',
                patrol_05: '',
                patrol_06: '',
                patrol_07: '',
                patrol_08: '',
                patrol_09: '',
                patrol_10: '',
                patrol_11: '',
                patrol_12: '',
                other_info: '',
                summarize: '',
                answer: '',
                info: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    render() {
        let { dataSource, columns, treeData, expandedKeys, selectedKeys, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} educe={true} dontImport={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} scroll={false} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                    }

                    <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
