import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import { CertificateLogList, CertificateLogDetail, createCertificateLog } from "../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '证书名称',
                    dataIndex: 'cer_name',
                    key: 'cer_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_CertificateLogDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '证书编码',
                    dataIndex: 'cer_code',
                    key: 'cer_code',
                },
                {
                    title: '获奖人',
                    dataIndex: 'get_user',
                    key: 'get_user',
                },
                {
                    title: '日期',
                    dataIndex: 'get_day',
                    key: 'get_day',
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                },
                // {
                //     title: '附件',
                //     dataIndex: 'enclosure',
                //     key: 'enclosure',
                //     render: (text, record, index) => {
                //         return (
                //             <div className='download' onClick={() => {
                //                 this.get_download(record)
                //             }}>
                //                 下载
                //             </div>
                //         )
                //     }
                // },
            ],
            cer_name: '',
            cer_code: '',
            TableInfo: [
                {
                    FieldTitle: '证书名称', //字段标题
                    fieldName: 'cer_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '证书编号', //字段标题
                    fieldName: 'cer_code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            Info: {
                state: '新增',
                FlowModelName: "资质荣誉证书信息登记表",
                TableInfo: [{
                    FieldTitle: '证书名称', //字段标题
                    fieldName: 'cer_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '证书编码', //字段标题
                    fieldName: 'cer_code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '获奖人', //字段标题
                    fieldName: 'get_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '等级', //字段标题
                    fieldName: 'grade', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '日期', //字段标题
                    fieldName: 'get_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            },
            selectValue: '',
            form: {},
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_CertificateLogList()
    }

    get_CertificateLogList = () => {
        let { cer_name, cer_code, dataSource, pages } = this.state
        let data = {
            cer_name: cer_name,
            cer_code: cer_code,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        CertificateLogList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.logs.length) {
                        res.data.logs.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                cer_name: item.cer_name,
                                cer_code: item.cer_code,
                                get_user: item.get_user,
                                get_day: item.get_day,
                                info: item.info,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_CertificateLogDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_CertificateLogDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        CertificateLogDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info, classType } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true

                if (!value.enclosure.length) {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                } else {
                    if (item.FieldTitle === '附件') {
                        item.value = value.enclosure
                    }
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false

                if (item.FieldTitle === '附件') {
                    item.value = value.enclosure
                }
            })
        }

        this.NewForm.current.formRef.current.setFieldsValue({
            cer_name: value.cer_name,
            cer_code: value.cer_code,
            get_user: value.get_user,
            grade: value.grade,
            get_day: value.get_day ? moment(value.get_day, 'YYYY-MM-DD HH:mm:ss') : '',
            info: value.info,
            enclosure: value.enclosure,
        })

        this.setState({
            Info
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            cer_name: '',
            cer_code: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_CertificateLogList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            cer_name: value.cer_name,
            cer_code: value.cer_code,
        }, () => {
            this.get_CertificateLogList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_CertificateLogList()
        })
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.cer_name = data.cer_name
        form.cer_code = data.cer_code
        form.get_user = data.get_user
        form.grade = data.grade
        form.get_day = data.get_day ? data.get_day.format('YYYY-MM-DD') : ''
        form.info = data.info

        createCertificateLog(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_CertificateLogList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                cer_name: '',
                cer_code: '',
                get_user: '',
                grade: '',
                get_day: '',
                info: '',
                enclosure: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                }

                <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} selectorEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(List)
