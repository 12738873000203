import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { projectAuxiliary, getProjectAwardList, saveCreateProject, getProjectDetail } from "../../../utils/market"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "项目中标立项",
                TableInfo: [{
                    FieldTitle: '立项类别', //字段标题
                    fieldName: 'operation', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择立项类别', //必填要求
                    value: '',
                    width: '50%',
                    radioList: []
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '立项时间', //字段标题
                    fieldName: 'pro_app_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择立项时间', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '关联信息采集', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '信息采集单据', //字段标题
                    fieldName: 'award_id', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    newline: true,
                    search: true
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '省份', //字段标题
                    fieldName: 'province', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择省份', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '城市', //字段标题
                    fieldName: 'city', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择城市', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '中标单位', //字段标题
                    fieldName: 'award_unit', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择中标单位', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '中标金额(元)', //字段标题
                    fieldName: 'award_money', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '计划开工时间', //字段标题
                    fieldName: 'plan_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '要求竣工时间', //字段标题
                    fieldName: 'complete_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '工期(天)', //字段标题
                    fieldName: 'con_period', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入工期(天)', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '所属行业', //字段标题
                    fieldName: 'industry', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入所属行业', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '经营方式', //字段标题
                    fieldName: 'operation_mode', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                },
                ],
                FirstInfo: [
                    {
                        plateName: '甲方单位信息',
                        list: [
                            {
                                FieldTitle: '业主单位', //字段标题
                                fieldName: 'owner_unit', //字段名称
                                fieldType: '默认',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主地址', //字段标题
                                fieldName: 'owner_address', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入业主地址', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主代表', //字段标题
                                fieldName: 'owner_deputy', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请把输入业主代表', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '业主联系方式', //字段标题
                                fieldName: 'owner_contact', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入业主联系方式', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '项目资金来源', //字段标题
                                fieldName: 'money_source', //字段名称
                                fieldType: '选择器',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请选择项目资金来源', //必填要求
                                value: '',
                                width: '50%',
                                selectList: []
                            }, {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'enclosure', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            }
                        ]
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            provinces: [],
            modal_Visible: false,
            dataSource: [],
            columns: [{
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            },
            {
                title: '建设单位(招标人)',
                dataIndex: 'con_unit',
                key: 'con_unit',
            },
            {
                title: '中标金额(元)',
                dataIndex: 'company_job',
                key: 'company_job',
            },
            {
                title: '中标时间',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '中标通知书领取',
                dataIndex: 'phone',
                key: 'phone',
            },
            ],
            TableInfo: [{
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }, {
                FieldTitle: '建设单位', //字段标题
                fieldName: 'con_unit', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
            }],
            pro_name: '',
            con_unit: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_projectAuxiliary()
    }

    get_projectAuxiliary = () => {
        let { Info } = this.state
        projectAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '立项类别') {
                            item.radioList = []
                            for (let key in res.data.operationMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operationMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.FieldTitle === '省份') {
                            item.selectList = []
                            res.data.provinces.forEach((tem, dex) => {
                                let value = {
                                    value: tem.value,
                                    label: tem.label
                                }
                                item.selectList = [...item.selectList, value]
                            })
                        } else if (item.FieldTitle === '中标单位') {
                            item.selectList = []
                            for (let key in res.data.projectAwardUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectAwardUnit[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '经营方式') {
                            item.selectList = []
                            for (let key in res.data.operation) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operation[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '项目资金来源') {
                            item.selectList = []
                            for (let key in res.data.moneySource) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.moneySource[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        provinces: res.data.provinces
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_ProjectDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_ProjectDetail = (tableId) => {
        const { Info, form, provinces } = this.state;
        let data = {
            table_id: tableId
        }
        getProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.pro_number = res.data.pro_number
                        form.owner_unit = res.data.owner_unit
                        Info.process.edit = res.data.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '关联信息采集') {
                                item.isShow = true
                            }
                        })
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true
                        })                       
                    }

                    Info.state = res.data.status
                    form.award_id = res.data.award_id
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    provinces.forEach((item, index) => {
                        if (item.value === res.data.province) {
                            Info.TableInfo.forEach((tem, dex) => {
                                if (tem.FieldTitle === '城市') {
                                    tem.selectList = []
                                    item.children.forEach((em, ex) => {
                                        let value = {
                                            value: em.value,
                                            label: em.label
                                        }
                                        tem.selectList = [...tem.selectList, value]
                                    })
                                }
                            })
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.FieldTitle === '信息采集单据') {
                            item.value = res.data.award_id
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.FieldTitle === '中标金额(元)') {
                            item.value = res.data.award_money
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '业主单位') {
                            item.value = res.data.owner_unit
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        operation: res.data.operation,
                        pro_app_time: res.data.pro_app_time ? moment(res.data.pro_app_time, 'YYYY-MM-DD') : '',
                        province: res.data.province,
                        city: res.data.city,
                        award_unit: res.data.award_unit,
                        plan_time: res.data.plan_time ? moment(res.data.plan_time, 'YYYY-MM-DD') : '',
                        complete_time: res.data.complete_time ? moment(res.data.complete_time, 'YYYY-MM-DD') : '',
                        con_period: res.data.con_period,
                        industry: res.data.industry,
                        operation_mode: res.data.operation_mode,
                        owner_address: res.data.owner_address,
                        owner_deputy: res.data.owner_deputy,
                        owner_contact: res.data.owner_contact,
                        money_source: res.data.money_source,
                        enclosure: res.data.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectAwardList = () => {
        let { dataSource, pages, pro_name, con_unit } = this.state
        let data = {
            pro_name: pro_name,
            con_unit: con_unit,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        getProjectAwardList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.awards.length) {
                        res.data.awards.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                con_unit: item.con_unit,
                                award_money: item.award_money,
                                award_time: item.award_time,
                                book_receive: item.book_receive === 1 ? '有' : '无'
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.operation = data.operation
        form.province = data.province
        form.city = data.city
        form.award_unit = data.award_unit
        form.con_period = data.con_period
        form.industry = data.industry
        form.operation_mode = data.operation_mode
        form.owner_address = data.owner_address
        form.owner_deputy = data.owner_deputy
        form.owner_contact = data.owner_contact
        form.money_source = data.money_source
        form.pro_app_time = data.pro_app_time ? data.pro_app_time.format('YYYY-MM-DD') : ''
        form.plan_time = data.plan_time ? data.plan_time.format('YYYY-MM-DD') : ''
        form.complete_time = data.complete_time ? data.complete_time.format('YYYY-MM-DD') : ''

        saveCreateProject(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ProjectAwardList()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '信息采集单据') {
                item.value = value[0].key
                form.award_id = value[0].key
            } else if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else {
                if (item.FieldTitle === '中标金额(元)') {
                    item.value = value[0].award_money
                }
            }
        })

        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '业主单位') {
                item.value = value[0].con_unit
                form.owner_unit = value[0].con_unit
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            con_unit: value.con_unit,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ProjectAwardList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            con_unit: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_ProjectAwardList()
        })
    }

    handSelector = (value) => {
        const { Info, provinces } = this.state;
        provinces.forEach((item, index) => {
            if (item.value === value) {
                Info.TableInfo.forEach((tem, dex) => {
                    if (tem.FieldTitle === '城市') {
                        tem.selectList = []
                        item.children.forEach((em, ex) => {
                            let value = {
                                value: Number(em.value),
                                label: em.label
                            }
                            tem.selectList = [...tem.selectList, value]
                        })
                    }
                })
            }
        })

        this.setState(preState => ({
            Info
        })
        )
    }

    handFile = (storeList, fileName,fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleSearch = () => {
        let { form } = this.state
        if (!form.award_id) {
            message.error('未关联信息采集单据!')
            return
        }
        this.props.history.push({
            pathname: '/market/notice/List',
            query: {
                'tableId': form.award_id,
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} selectorEvent={this.handSelector} auditEvent={this.handleButton} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
