import React, { Component } from 'react'
import { Radio } from 'antd';

export default class UserRadio extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { disabled, radioList, onChange, value, fileName, postEvent } = this.props;
        return (
            <Radio.Group
                disabled={disabled}
                options={radioList}
                value={value}
                onChange={
                    (e) => {
                        onChange(e.target.value)
                        if (fileName === '是否需要票务订购' || fileName === '是否涉及工程资料' || fileName === '是否盖章' || fileName === '是否为项目费用' || fileName === '进度结算信息' || fileName === '下料类别' || fileName === '收货类别' || fileName === '认价类别' || fileName === '合同类别' || fileName === '是否为内部' || fileName === '付款类别' || fileName === '进度计量类别' || fileName === '结算类别' || fileName === '供应商类别' || fileName === '采购部门' || fileName === '是否欠票' || fileName === '资料类型' || fileName === '移交部门' || fileName === '合同类型' || fileName === '项目付款' || fileName === '项目类型' || fileName === '用工类别'|| fileName === '是否循环'||fileName==='是否成品苗生产出库'||fileName==='借款类型') {
                            postEvent(e.target.value, fileName)
                        }
                    }
                }
            />
        )
    }
}
