import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { aboutMachineryContract, aboutProgressSettlements, aboutSettlementApproval, MaterialPaymentDetail, createMaterialPayment } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/manMachinePayment.docx',
                templateName: '四川天艺生态园林集团股份有限公司人材机付款审批',
                tempData: {
                    con_number: '',
                    apply_date: '',
                    user_name: '',
                    con_name: '',
                    con_money: '',
                    con_type: '',
                    total_pay_money: '',
                    acc_money: '',
                    pay_money: '',
                    invo_money: '',
                    acc_invoice: '',
                    pay_class: '',
                    pro_money: '',
                    set_money: '',
                    unit_name: '',
                    invo_type: '',
                    money: '',
                    replenish_invo: '',
                    info: '',
                    accout_name: '',
                    bank_name: '',
                    bank_number: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "人材机付款审批",
                TableInfo: [
                    {
                        FieldTitle: '日期', //字段标题
                        fieldName: 'apply_date', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择日期', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '选择合同', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    }, {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '合同类别', //字段标题
                        fieldName: 'macType', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'proName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'managerName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '合同总金额(元)', //字段标题
                        fieldName: 'con_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '已累计付款(元)', //字段标题
                        fieldName: 'total_pay_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '剩余未付款(元)', //字段标题
                        fieldName: 'acc_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入剩余未付款(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '本次付款(元)', //字段标题
                        fieldName: 'pay_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本次付款(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '付款说明', //字段标题
                        fieldName: 'pay_info', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '本次开票金额(元)', //字段标题
                        fieldName: 'invo_money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本次开票金额(元)', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '前期已开票金额(元)', //字段标题
                        fieldName: 'acc_invoice', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '分类', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择分类', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "合作" },
                            { value: 2, label: "自营" },
                        ]
                    },
                    {
                        FieldTitle: '是否欠票', //字段标题
                        fieldName: 'has_owing', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择是否欠票', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "是" },
                            { value: 2, label: "否" },
                        ]
                    },
                    {
                        FieldTitle: '欠票单位', //字段标题
                        fieldName: 'unit_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票类型', //字段标题
                        fieldName: 'invo_type', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票金额', //字段标题
                        fieldName: 'money', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '欠票原因', //字段标题
                        fieldName: 'cause', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '补票时间', //字段标题
                        fieldName: 'replenish_invo', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        isShow: true
                    },
                    {
                        FieldTitle: '付款类别', //字段标题
                        fieldName: 'pay_class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择付款类别', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            { value: 1, label: "预付款" },
                            { value: 2, label: "进度计量" },
                            { value: 3, label: "结算审批" },
                        ]
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'advance', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        isShow: true
                    },
                ],
                FirstInfo: [{
                    plateName: '进度付款',
                    isShow: true,
                    list: [
                        // {
                        //     FieldTitle: '是否有进度结算单据', //字段标题
                        //     fieldName: 'is_progress', //字段名称
                        //     fieldType: '单选',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '50%',
                        //     radioList: [
                        //         { value: 1, label: "是" },
                        //         { value: 2, label: "否" },
                        //     ]
                        // },
                        // {
                        //     FieldTitle: '结算类型', //字段标题
                        //     fieldName: 'progress_class', //字段名称
                        //     fieldType: '默认',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '50%',
                        // },
                        {
                            FieldTitle: '关联进度计量单据', //字段标题
                            fieldType: '按钮',  //字段类型
                        },
                        {
                            FieldTitle: '进度计量单据', //字段标题
                            fieldName: 'progress_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        },
                        {
                            FieldTitle: '累计进度计量金额(元)', //字段标题
                            fieldName: 'pro_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                    ],
                },
                {
                    plateName: '结算',
                    isShow: true,
                    list: [
                        // {
                        //     FieldTitle: '是否有结算单据', //字段标题
                        //     fieldName: 'is_settlement', //字段名称
                        //     fieldType: '单选',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '50%',
                        //     radioList: [
                        //         { value: 1, label: "是" },
                        //         { value: 2, label: "否" },
                        //     ]
                        // },
                        // {
                        //     FieldTitle: '结算类型', //字段标题
                        //     fieldName: 'settlement_class', //字段名称
                        //     fieldType: '默认',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '50%',
                        // },
                        {
                            FieldTitle: '关联结算单据', //字段标题
                            fieldType: '按钮',  //字段类型
                        },
                        {
                            FieldTitle: '结算单据', //字段标题
                            fieldName: 'settlement_id', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                            search: true
                        },
                        {
                            FieldTitle: '结算金额(元)', //字段标题
                            fieldName: 'set_money', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%',
                        },
                        // {
                        //     FieldTitle: '合同金额(元)', //字段标题
                        //     fieldName: 'con_money', //字段名称
                        //     fieldType: '默认',  //字段类型
                        //     disabled: false,  //字段是否禁用
                        //     mandatory: false, //字段是否必填
                        //     required: '', //必填要求
                        //     value: '',
                        //     width: '50%',
                        //     isShow: true
                        // },
                    ],
                },
                {
                    plateName: '收款单位信息',
                    list: [
                        {
                            FieldTitle: '收款账号', //字段标题
                            fieldName: 'bank_number', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '收款户名', //字段标题
                            fieldName: 'accout_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '收款银行', //字段标题
                            fieldName: 'bank_name', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '联系人', //字段标题
                            fieldName: 'contact', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '联系电话', //字段标题
                            fieldName: 'phone', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '是否为内部', //字段标题
                            fieldName: 'is_inside', //字段名称
                            fieldType: '单选',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择是否为内部', //必填要求
                            value: '',
                            width: '50%',
                            radioList: [
                                {
                                    value: 1,
                                    label: '是'
                                },
                                {
                                    value: 2,
                                    label: '否'
                                }
                            ]
                        },
                        {
                            FieldTitle: '外部意见', //字段标题
                            fieldName: 'review_comments', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%',
                            isShow: true,
                        },
                    ]
                }
                    // {
                    //     plateName: '财务填写数据',
                    //     list: [
                    //         {
                    //             FieldTitle: '发票类型', //字段标题
                    //             fieldName: 'performance_nature', //字段名称
                    //             fieldType: '选择器',  //字段类型
                    //             disabled: false,  //字段是否禁用
                    //             mandatory: false, //字段是否必填
                    //             required: '', //必填要求
                    //             value: '',
                    //             width: '50%',
                    //             selectList: [
                    //                 { value: 1, label: "增值税专用发票" },
                    //                 { value: 2, label: "增值税普通发票" },
                    //             ]
                    //         },
                    //         {
                    //             FieldTitle: '增值税税率', //字段标题
                    //             fieldName: 'performance_nature', //字段名称
                    //             fieldType: '选择器',  //字段类型
                    //             disabled: false,  //字段是否禁用
                    //             mandatory: false, //字段是否必填
                    //             required: '', //必填要求
                    //             value: '',
                    //             width: '50%',
                    //             selectList: [
                    //                 { value: 1, label: "1%" },
                    //                 { value: 2, label: "3%" },
                    //                 { value: 3, label: "6%" },
                    //                 { value: 4, label: "9%" },
                    //                 { value: 5, label: "13%" },
                    //             ]
                    //         },
                    //         {
                    //             FieldTitle: '合同金额(元)', //字段标题
                    //             fieldName: 'about_project', //字段名称
                    //             fieldType: '文本',  //字段类型
                    //             disabled: false,  //字段是否禁用
                    //             mandatory: false, //字段是否必填
                    //             required: '', //必填要求
                    //             value: '',
                    //             width: '50%'
                    //         },
                    //     ],
                    // },
                ]
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            con_number: '',
            con_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        let { form, Info } = this.state
        if (this.props.location.query) {
            if (this.props.location.query.projectId) {
                form.project_id = this.props.location.query.projectId
                Info.state = 1
                this.setState(preState => ({
                    form
                })
                )
            } else {
                let tableId = this.props.location.query.tableId
                this.get_MaterialPaymentDetail(tableId)
            }

        }
    }

    get_MaterialPaymentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        MaterialPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    let macType = res.data.Detail.mac_type === 1 ? '材料' : res.data.Detail.mac_type === 2 ? '劳务' : res.data.Detail.mac_type === 3 ? '机械' : res.data.Detail.mac_type === 4 ? '设计' : '零星材料'
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.machinery_id = res.data.Detail.machinery_id
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_inside === 2) {
                            Info.FirstInfo[2].list.forEach((item, index) => {
                                if (item.FieldTitle === '外部意见') {
                                    item.isShow = false
                                    item.value = res.data.Detail.review_comments.length ? res.data.Detail.review_comments : ''
                                }
                            })
                        }

                        if (res.data.Detail.pay_class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = false
                                    item.value = res.data.Detail.advance.length ? res.data.Detail.advance : ''
                                }
                            })
                        } else if (res.data.Detail.pay_class === 2) {
                            Info.FirstInfo[0].isShow = false
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '进度计量单据') {
                                    item.value = res.data.Detail.progress_id
                                    form.progress_id = res.data.Detail.progress_id
                                } else if (item.FieldTitle === '累计进度计量金额(元)') {
                                    item.value = res.data.Detail.pro_money
                                    form.pro_money = res.data.Detail.pro_money
                                }
                            })
                        } else if (res.data.Detail.pay_class === 3) {
                            Info.FirstInfo[1].isShow = false
                            Info.FirstInfo[1].list.forEach((item, index) => {
                                if (item.FieldTitle === '结算单据') {
                                    item.value = res.data.Detail.settlement_id
                                    form.settlement_id = res.data.Detail.settlement_id
                                } else if (item.FieldTitle === '结算金额(元)') {
                                    item.value = res.data.Detail.set_money
                                    form.set_money = res.data.Detail.set_money
                                }
                            })
                        }

                    } else {

                        if (res.data.Detail.status === 4) {
                            Info.tempData.con_number = res.data.Detail.con_number
                            Info.tempData.apply_date = res.data.Detail.apply_date
                            Info.tempData.user_name = res.data.Detail.applyUsername
                            Info.tempData.con_name = res.data.Detail.con_name
                            Info.tempData.con_money = res.data.Detail.con_money
                            Info.tempData.con_type = res.data.Detail.class === 1 ? '合作' : '自营'
                            Info.tempData.total_pay_money = res.data.Detail.total_pay_money
                            Info.tempData.acc_money = res.data.Detail.acc_money
                            Info.tempData.pay_money = res.data.Detail.pay_money
                            Info.tempData.invo_money = res.data.Detail.invo_money
                            Info.tempData.acc_invoice = res.data.Detail.acc_invoice
                            Info.tempData.class = res.data.Detail.class
                            Info.tempData.pay_class = res.data.Detail.pay_class === 1 ? '预付款' : res.data.Detail.pay_class === 2 ? '进度计量' : '结算审批'
                            Info.tempData.pro_money = res.data.Detail.pay_class === 2 ? res.data.Detail.pro_money : '/'
                            Info.tempData.set_money = res.data.Detail.pay_class === 3 ? res.data.Detail.set_money : '/'
                            Info.tempData.unit_name = res.data.Detail.has_owing === 1 ? res.data.Detail.unit_name : '/'
                            Info.tempData.invo_type = res.data.Detail.has_owing === 1 ? res.data.Detail.invo_type : '/'
                            Info.tempData.money = res.data.Detail.has_owing === 1 ? res.data.Detail.money : '/'
                            Info.tempData.cause = res.data.Detail.has_owing === 1 ? res.data.Detail.cause : '/'
                            Info.tempData.replenish_invo = res.data.Detail.has_owing === 1 ? res.data.Detail.replenish_invo : '/'
                            Info.tempData.info = res.data.Detail.info
                            Info.tempData.accout_name = res.data.Detail.accout_name
                            Info.tempData.bank_name = res.data.Detail.bank_name
                            Info.tempData.bank_number = res.data.Detail.bank_number
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            }
                        })

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                            })
                        })

                        if (res.data.Detail.is_inside === 2) {
                            Info.FirstInfo[2].list.forEach((item, index) => {
                                if (item.FieldTitle === '外部意见') {
                                    item.value = res.data.Detail.review_comments.length ? res.data.Detail.review_comments : ''
                                }
                            })
                        }

                        if (res.data.Detail.pay_class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (res.data.Detail.advance.length) {
                                    if (item.FieldTitle === '附件') {
                                        item.isShow = false
                                        item.value = res.data.Detail.advance
                                    }
                                }
                            })
                        } else if (res.data.Detail.pay_class === 2) {
                            Info.FirstInfo[0].isShow = false
                            Info.FirstInfo[0].list.forEach((item, index) => {
                                if (item.FieldTitle === '关联进度计量单据') {
                                    item.isShow = true
                                } else if (item.FieldTitle === '进度计量单据') {
                                    item.value = res.data.Detail.progress_id
                                    form.progress_id = res.data.Detail.progress_id
                                } else if (item.FieldTitle === '累积进度计量金额(元)') {
                                    item.value = res.data.Detail.pro_money
                                }
                            })
                        } else if (res.data.Detail.pay_class === 3) {
                            Info.FirstInfo[1].isShow = false
                            Info.FirstInfo[1].list.forEach((item, index) => {
                                if (item.FieldTitle === '关联结算单据') {
                                    item.isShow = true
                                } else if (item.FieldTitle === '结算单据') {
                                    item.value = res.data.Detail.settlement_id
                                    form.settlement_id = res.data.Detail.settlement_id
                                } else if (item.FieldTitle === '结算金额(元)') {
                                    item.value = res.data.Detail.set_money
                                }
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.FieldTitle === '合同编号') {
                            item.value = res.data.Detail.con_number
                        } else if (item.FieldTitle === '合同类别') {
                            form.mac_type = res.data.Detail.mac_type
                            item.value = macType
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.managerUsername
                        } else if (item.FieldTitle === '合同总金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.FieldTitle === '已累计付款(元)') {
                            item.value = res.data.Detail.total_pay_money
                            form.total_pay_money = res.data.Detail.total_pay_money
                        } else if (item.FieldTitle === '前期已开票金额(元)') {
                            item.value = res.data.Detail.acc_invoice
                            form.acc_invoice = res.data.Detail.acc_invoice
                        }
                    })

                    Info.FirstInfo[2].list.forEach((item, index) => {
                        if (item.FieldTitle === '收款账号') {
                            item.value = res.data.Detail.bank_number
                            form.bank_number = res.data.Detail.bank_number
                        } else if (item.FieldTitle === '收款户名') {
                            item.value = res.data.Detail.accout_name
                            form.accout_name = res.data.Detail.accout_name
                        } else if (item.FieldTitle === '收款银行') {
                            item.value = res.data.Detail.bank_name
                            form.bank_name = res.data.Detail.bank_name
                        } else if (item.FieldTitle === '联系人') {
                            item.value = res.data.Detail.contact
                            form.contact = res.data.Detail.contact
                        } else if (item.FieldTitle === '联系电话') {
                            item.value = res.data.Detail.phone
                            form.phone = res.data.Detail.phone
                        }
                    })

                    if (res.data.Detail.has_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '欠票单位') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '欠票原因') {
                                item.isShow = false
                            } else if (item.FieldTitle === '补票时间') {
                                item.isShow = false
                            }
                        })
                    }

                    // if (res.data.Detail.is_progress === 1) {
                    //     form.progress_id = res.data.Detail.progress_id
                    //     Info.FirstInfo[0].list.forEach((item, index) => {
                    //         if (item.FieldTitle === '进度结算单据') {
                    //             item.isShow = false
                    //             item.value = res.data.Detail.progress_id
                    //         } else if (item.FieldTitle === '结算类型') {
                    //             item.isShow = false
                    //             item.value = macType + '进度结算'
                    //         }
                    //     })
                    // }

                    // if (res.data.Detail.is_settlement === 1) {
                    //     form.settlement_id = res.data.Detail.settlement_id
                    //     Info.FirstInfo[1].list.forEach((item, index) => {
                    //         if (item.FieldTitle === '结算单据') {
                    //             item.isShow = false
                    //             item.value = res.data.Detail.settlement_id
                    //         } else if (item.FieldTitle === '结算金额(元)') {
                    //             item.isShow = false
                    //             item.value = res.data.Detail.set_con_money
                    //         } else if (item.FieldTitle === '合同金额(元)') {
                    //             item.isShow = false
                    //             item.value = res.data.Detail.set_money
                    //         } else if (item.FieldTitle === '结算类型') {
                    //             item.isShow = false
                    //             item.value = macType + '进度结算'
                    //         }
                    //     })
                    // }


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        // total_pay_money: res.data.Detail.total_pay_money,
                        acc_money: res.data.Detail.acc_money,
                        pay_money: res.data.Detail.pay_money,
                        pay_info: res.data.Detail.pay_info,
                        invo_money: res.data.Detail.invo_money,
                        class: res.data.Detail.class,
                        // is_progress: res.data.Detail.is_progress,
                        // is_settlement: res.data.Detail.is_settlement,
                        pay_class: res.data.Detail.pay_class,
                        // bank_number: res.data.Detail.bank_number,
                        // accout_name: res.data.Detail.accout_name,
                        // bank_name: res.data.Detail.bank_name,
                        // contact: res.data.Detail.contact,
                        // phone: res.data.Detail.phone,
                        is_inside: res.data.Detail.is_inside,
                        review_comments: res.data.Detail.review_comments,
                        has_owing: res.data.Detail.has_owing,
                        unit_name: res.data.Detail.unit_name,
                        invo_type: res.data.Detail.invo_type,
                        money: res.data.Detail.money,
                        cause: res.data.Detail.cause,
                        replenish_invo: res.data.Detail.replenish_invo ? moment(res.data.Detail.replenish_invo, 'YYYY-MM-DD') : '',
                        advance: res.data.Detail.advance,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutMachineryContract = () => {
        let { dataSource, pages, con_number, con_name, form } = this.state
        let data = {
            project_id: form.project_id,
            con_number: con_number,
            con_name: con_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutMachineryContract(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.macs.length) {
                        res.data.macs.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_number: item.con_number,
                                macType: item.macType,
                                mac_type: item.mac_type,
                                proName: item.proName,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                con_money: item.con_money,
                                totalPayMoney: item.totalPayMoney,
                                acc_invoice: item.acc_invoice,
                                bank_number: item.bank_number,
                                accout_name: item.accounts,
                                bank_name: item.bank_name,
                                contacts: item.contacts,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutProgressSettlements = () => {
        let { dataSource, pages, form } = this.state
        let data = {
            project_id: form.project_id,
            class: form.mac_type,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutProgressSettlements(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.progress.length) {
                        res.data.progress.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                proName: item.proName,
                                projectName: item.projectName,
                                sup_name: item.sup_name,
                                month_pay: item.month_pay,
                                pro_set_money: item.pro_set_money,
                                acc_money: item.acc_money
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutSettlementApproval = () => {
        let { dataSource, pages, sup_name, form } = this.state
        let data = {
            project_id: form.project_id,
            sup_name: sup_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutSettlementApproval(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.sets.length) {
                        res.data.sets.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_money: item.con_set_money,
                                projectName: item.projectName,
                                proName: item.proName,
                                proManager: item.proManager,
                                supplier: item.supplier,
                                acc_pay: item.acc_pay,
                                set_money: item.con_money,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        //form.total_pay_money = data.total_pay_money
        form.acc_money = data.acc_money
        form.pay_money = data.pay_money
        form.pay_info = data.pay_info
        form.invo_money = data.invo_money
        form.class = data.class
        form.pay_class = data.pay_class
        form.is_progress = data.is_progress
        form.is_settlement = data.is_settlement
        // form.bank_number = data.bank_number
        // form.accout_name = data.accout_name
        // form.bank_name = data.bank_name
        // form.contact = data.contact
        // form.phone = data.phone
        form.is_inside = data.is_inside
        form.has_owing = data.has_owing
        form.unit_name = data.unit_name || ''
        form.invo_type = data.invo_type || ''
        form.money = data.money || ''
        form.cause = data.cause || ''
        form.replenish_invo = data.replenish_invo ? data.replenish_invo.format('YYYY-MM-DD') : ''


        createMaterialPayment(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择合同') {
                columns = [
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同编号',
                        dataIndex: 'con_number',
                        key: 'con_number',
                    },
                    {
                        title: '合同类别',
                        dataIndex: 'macType',
                        key: 'macType',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'proName',
                        key: 'proName',
                    },
                    {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'managerName',
                        key: 'managerName',
                    },
                    {
                        title: '合同总金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_number', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_aboutMachineryContract()
            } else if (title === '关联进度计量单据') {
                columns = [
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同总金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'projectName',
                        key: 'projectName',
                    },
                    {
                        title: '供货单位',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '进度款支付金额',
                        dataIndex: 'month_pay',
                        key: 'month_pay',
                    },
                    {
                        title: '进度款结算金额',
                        dataIndex: 'pro_set_money',
                        key: 'pro_set_money',
                    },
                    {
                        title: '累积进度计量金额',
                        dataIndex: 'acc_money',
                        key: 'acc_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供货单位', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_aboutProgressSettlements()
            } else if (title === '关联结算单据') {
                columns = [
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同总金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'projectName',
                        key: 'projectName',
                    },
                    {
                        title: '供货单位',
                        dataIndex: 'supplier',
                        key: 'supplier',
                    },
                    {
                        title: '前期累计进度计量金额',
                        dataIndex: 'acc_pay',
                        key: 'acc_pay',
                    },
                    {
                        title: '合同结算金额',
                        dataIndex: 'set_money',
                        key: 'set_money',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供货单位', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }
                ]
                this.get_aboutSettlementApproval()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择合同') {
            form.machinery_id = value[0].key
            form.mac_type = value[0].mac_type
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '合同名称') {
                    item.value = value[0].con_name
                } else if (item.FieldTitle === '合同编号') {
                    item.value = value[0].con_number
                } else if (item.FieldTitle === '合同类别') {
                    item.value = value[0].macType
                } else if (item.FieldTitle === '项目名称') {
                    item.value = value[0].proName
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].managerName
                } else if (item.FieldTitle === '合同总金额(元)') {
                    item.value = value[0].con_money
                } else if (item.FieldTitle === '已累计付款(元)') {
                    item.value = value[0].totalPayMoney
                    form.total_pay_money = value[0].totalPayMoney
                } else if (item.FieldTitle === '前期已开票金额(元)') {
                    item.value = value[0].acc_invoice
                    form.acc_invoice = value[0].acc_invoice
                }
            })

            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.FieldTitle === '收款账号') {
                    item.value = value[0].bank_number
                    form.bank_number = value[0].bank_number
                } else if (item.FieldTitle === '收款户名') {
                    item.value = value[0].accout_name
                    form.accout_name = value[0].accout_name
                } else if (item.FieldTitle === '收款银行') {
                    item.value = value[0].bank_name
                    form.bank_name = value[0].bank_name
                } else if (item.FieldTitle === '联系人') {
                    item.value = value[0].contacts
                    form.contact = value[0].contacts
                } else if (item.FieldTitle === '联系电话') {
                    item.value = value[0].phone
                    form.phone = value[0].phone
                }
            })

        } else if (buttonTitle === '关联进度计量单据') {
            form.progress_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '进度计量单据') {
                    item.value = value[0].key
                } else if (item.FieldTitle === '累积进度计量金额(元)') {
                    item.value = value[0].acc_money
                    form.pro_money = value[0].acc_money
                }
            })
        } else if (buttonTitle === '关联结算单据') {
            form.settlement_id = value[0].key
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.FieldTitle === '结算单据') {
                    item.value = value[0].key
                } else if (item.FieldTitle === '结算金额(元)') {
                    item.value = value[0].set_money
                    form.set_money = value[0].set_money
                }
                // else if (item.FieldTitle === '合同金额(元)') {
                //     item.value = value[0].con_money
                // }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleRadio = (value, title) => {
        let { Info } = this.state;

        if (title === '是否为内部') {
            if (value === 2) {
                Info.FirstInfo[2].list.forEach((item, index) => {
                    if (item.FieldTitle === '外部意见') {
                        item.isShow = false
                    }
                })
            } else {
                Info.FirstInfo[2].list.forEach((item, index) => {
                    if (item.FieldTitle === '外部意见') {
                        item.isShow = true
                    }
                })
            }
        }
        // else if (title === '是否有进度结算单据') {
        //     if (value === 1) {
        //         Info.FirstInfo[0].list.forEach((item, index) => {
        //             if (item.FieldTitle === '进度结算单据') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '关联进度结算单据') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '结算类型') {
        //                 item.isShow = false
        //             }
        //         })
        //     } else if (value === 2) {
        //         Info.FirstInfo[0].list.forEach((item, index) => {
        //             if (item.FieldTitle === '进度结算单据') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '关联进度结算单据') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '结算类型') {
        //                 item.isShow = true
        //             }
        //         })
        //     }
        // } else if (title === '是否有结算单据') {
        //     if (value === 1) {
        //         Info.FirstInfo[1].list.forEach((item, index) => {
        //             if (item.FieldTitle === '结算单据') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '关联结算单据') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '结算金额(元)') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '合同金额(元)') {
        //                 item.isShow = false
        //             } else if (item.FieldTitle === '结算类型') {
        //                 item.isShow = false
        //             }
        //         })
        //     } else if (value === 2) {
        //         Info.FirstInfo[1].list.forEach((item, index) => {
        //             if (item.FieldTitle === '结算单据') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '关联结算单据') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '结算金额(元)') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '合同金额(元)') {
        //                 item.isShow = true
        //             } else if (item.FieldTitle === '结算类型') {
        //                 item.isShow = true
        //             }
        //         })
        //     }
        // } 
        else if (title === '是否欠票') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = false
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '欠票单位') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '欠票原因') {
                        item.isShow = true
                    } else if (item.FieldTitle === '补票时间') {
                        item.isShow = true
                    }
                })
            }
        } else if (title === '付款类别') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '附件') {
                        item.isShow = false
                    }
                })

                Info.FirstInfo[0].isShow = true

                Info.FirstInfo[1].isShow = true
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                })

                Info.FirstInfo[0].isShow = false

                Info.FirstInfo[1].isShow = true
            } else if (value === 3) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '附件') {
                        item.isShow = true
                    }
                })

                Info.FirstInfo[0].isShow = true

                Info.FirstInfo[1].isShow = false
            }
        }

        this.setState(preState => ({
            Info
        }))
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择合同') {
            this.setState({
                con_number: value.con_number,
                con_name: value.con_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_aboutMachineryContract()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            con_number: '',
            con_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[2].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择合同') {
                this.get_aboutMachineryContract()
            } else if (buttonTitle === '关联进度计量单据') {
                this.get_aboutProgressSettlements()
            } else if (buttonTitle === '关联结算单据') {
                this.get_aboutSettlementApproval()
            }
        })
    }

    handleSearch = (name) => {
        let { form } = this.state
        console.log(name,form)
        if (name === '进度计量单据') {
            if (!form.progress_id) {
                message.error('未关联进度计量单据!')
                return
            }
            this.props.history.push({
                pathname: '/project/settlement/NewItem',
                query: {
                    'tableId': form.progress_id,
                }
            })
        } else {
            if (!form.settlement_id) {
                message.error('未关联结算单据!')
                return
            }
            this.props.history.push({
                pathname: '/project/settlements/NewItem',
                query: {
                    'tableId': form.settlement_id,
                }
            })
        }

    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} radioEvent={this.handleRadio} auditEvent={this.handleButton} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}