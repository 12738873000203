import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { DataTransferList } from "../../../utils/project"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: ' 项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    width: '300px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ cursor: 'pointer', width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '移交日期',
                    dataIndex: 'tran_day',
                    key: 'tran_day',
                },
                {
                    title: '发 起 人',
                    dataIndex: 'applyUsername',
                    key: 'applyUsername',
                },
                // {
                //     title: '接 收 人',
                //     dataIndex: 'receiveUsername',
                //     key: 'receiveUsername',
                // },
                // {
                //     title: '接收部门',
                //     dataIndex: 'receiveUserDename',
                //     key: 'receiveUserDename',
                // },
                {
                    title: '供应商名称',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                    width: '200px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '180px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                }, {
                    title: '文件类型',
                    dataIndex: 'invo_class',
                    key: 'invo_class',
                }, {
                    title: '计量月份',
                    dataIndex: 'number',
                    key: 'number',
                }, {
                    title: '本月完成产值(元)',
                    dataIndex: 'adm_price',
                    key: 'adm_price',
                    width: '200px',
                }, {
                    title: '至本月累计完成产值(元)',
                    dataIndex: 'invoice_code',
                    key: 'invoice_code',
                    width: '250px',
                }, {
                    title: '签收日期',
                    dataIndex: 'receive_day',
                    key: 'receive_day',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '移交日期', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_day', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_day', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
                {
                    FieldTitle: '发 起 人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '接 收 人', //字段标题
                    fieldName: 'receive_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pro_name: '',
            start_day: '',
            end_day: '',
            apply_user: '',
            receive_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            classType: 1,
            scrollWidth: 2500
        }
    }

    componentDidMount() {
        this.get_DataTransferList()
    }


    get_DataTransferList = () => {
        let { pro_name, start_day, end_day, apply_user, receive_user, classType, process_state, dataSource, pages } = this.state

        let data = {
            data_class: classType,
            pro_name: pro_name,
            start_day: start_day,
            end_day: end_day,
            apply_user: apply_user,
            receive_user: receive_user,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        DataTransferList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.transfers.length) {
                        res.data.transfers.forEach((item, index) => {
                            let adm_price = ''
                            let tax_invo = ''
                            let invo_class = ''
                            if (classType === 3) {
                                Number(item.adm_price) === 1 ? adm_price = '0%' : Number(item.adm_price) === 2 ? adm_price = '1%' : Number(item.adm_price) === 3 ? adm_price = '3%' : Number(item.adm_price) === 4 ? adm_price = '6%' : Number(item.adm_price) ? adm_price = '9%' : adm_price = '12%'
                                Number(item.tax_invo) === 1 ? tax_invo = '0%' : Number(item.tax_invo) === 2 ? tax_invo = '1%' : Number(item.tax_invo) === 3 ? tax_invo = '3%' : Number(item.tax_invo) === 4 ? tax_invo = '6%' : Number(item.tax_invo) === 5 ? tax_invo = '9%' : tax_invo = '12%'
                                invo_class = item.invo_class === 1 ? '增值税专用发票' : item.invo_class === 2 ? '增值税普通发票' : '免税发票'
                            } else {
                                adm_price = item.adm_price
                                tax_invo = item.tax_invo
                                invo_class = item.invo_class === 1 ? '材料' : item.invo_class === 2 ? '劳务' : '机械'
                            }
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                proName: item.proName,
                                tran_day: item.tran_day,
                                applyUsername: item.applyUsername,
                                // receiveUsername: item.receiveUsername,
                                // receiveUserDename: item.receiveUserDename,
                                supplierName: item.supplierName || '/',
                                contractName: item.contractName || '/',
                                invo_class: invo_class,
                                adm_price: adm_price,
                                tax_invo: tax_invo,
                                invoice_code: item.invoice_code,
                                number: item.number,
                                money: item.money,
                                ofd: item.ofd,
                                accInvoiceMoney: item.accInvoiceMoney,
                                info: item.info,
                                receive_day: item.receive_day,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/project/handoverOrder/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/handoverOrder/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            pro_name: '',
            start_day: '',
            end_day: '',
            apply_user: '',
            receive_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_DataTransferList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            start_day: value.start_day ? value.start_day.format('YYYY-MM-DD') : '',
            end_day: value.end_day ? value.end_day.format('YYYY-MM-DD') : '',
            apply_user: value.apply_user,
            receive_user: value.receive_user,
        }, () => {
            this.get_DataTransferList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_DataTransferList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_DataTransferList()
        })
    }

    get_tabIndex = (index) => {
        let { columns, scrollWidth } = this.state
        if (index === 1) {
            columns = [
                {
                    title: ' 项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '移交日期',
                    dataIndex: 'tran_day',
                    key: 'tran_day',
                },
                {
                    title: '发起人',
                    dataIndex: 'applyUsername',
                    key: 'applyUsername',
                },
                // {
                //     title: '接收人',
                //     dataIndex: 'receiveUsername',
                //     key: 'receiveUsername',
                // },
                // {
                //     title: '接收部门',
                //     dataIndex: 'receiveUserDename',
                //     key: 'receiveUserDename',
                // },
                {
                    title: '供应商名称',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                }, {
                    title: '文件类型',
                    dataIndex: 'invo_class',
                    key: 'invo_class',
                }, {
                    title: '本月完成产值(元)',
                    dataIndex: 'adm_price',
                    key: 'adm_price',
                }, {
                    title: '至本月累计完成产值(元)',
                    dataIndex: 'invoice_code',
                    key: 'invoice_code',
                }, {
                    title: '计量月份',
                    dataIndex: 'number',
                    key: 'number',
                }, {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                }, {
                    title: '签收日期',
                    dataIndex: 'receive_day',
                    key: 'receive_day',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
            scrollWidth = 2200
        } else if (index === 2) {
            columns = [
                {
                    title: ' 项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '移交日期',
                    dataIndex: 'tran_day',
                    key: 'tran_day',
                },
                {
                    title: '发起人',
                    dataIndex: 'applyUsername',
                    key: 'applyUsername',
                },
                // {
                //     title: '接收人',
                //     dataIndex: 'receiveUsername',
                //     key: 'receiveUsername',
                // },
                // {
                //     title: '接收部门',
                //     dataIndex: 'receiveUserDename',
                //     key: 'receiveUserDename',
                // },
                {
                    title: '供应商名称',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                }, {
                    title: '文件类型',
                    dataIndex: 'invo_class',
                    key: 'invo_class',
                }, {
                    title: '至本月累计完成产值(元)',
                    dataIndex: 'invoice_code',
                    key: 'invoice_code',
                }, {
                    title: '结算金额(元)',
                    dataIndex: 'adm_price',
                    key: 'adm_price',
                }, {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                }, {
                    title: '签收日期',
                    dataIndex: 'receive_day',
                    key: 'receive_day',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
            scrollWidth = 2200
        } else if (index === 3) {
            columns = [
                {
                    title: ' 项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '移交日期',
                    dataIndex: 'tran_day',
                    key: 'tran_day',
                },
                {
                    title: '发起人',
                    dataIndex: 'applyUsername',
                    key: 'applyUsername',
                },
                // {
                //     title: '接收人',
                //     dataIndex: 'receiveUsername',
                //     key: 'receiveUsername',
                // },
                // {
                //     title: '接收部门',
                //     dataIndex: 'receiveUserDename',
                //     key: 'receiveUserDename',
                // },
                {
                    title: '供应商名称',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                }, {
                    title: '发票类型',
                    dataIndex: 'invo_class',
                    key: 'invo_class',
                }, {
                    title: '税率(认价)',
                    dataIndex: 'adm_price',
                    key: 'adm_price',
                }, {
                    title: '税率(开票)',
                    dataIndex: 'tax_invo',
                    key: 'tax_invo',
                }, {
                    title: '发票编号',
                    dataIndex: 'invoice_code',
                    key: 'invoice_code',
                }, {
                    title: '发票份数',
                    dataIndex: 'number',
                    key: 'number',
                }, {
                    title: '本期金额',
                    dataIndex: 'money',
                    key: 'money',
                }, {
                    title: '有无OFD',
                    dataIndex: 'ofd',
                    key: 'ofd',
                }, {
                    title: '累计开票金额(元)',
                    dataIndex: 'accInvoiceMoney',
                    key: 'accInvoiceMoney',
                }, {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                }, {
                    title: '签收日期',
                    dataIndex: 'receive_day',
                    key: 'receive_day',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
            scrollWidth = 3200
        } else if (index === 4) {
            columns = [
                {
                    title: ' 项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '移交日期',
                    dataIndex: 'tran_day',
                    key: 'tran_day',
                },
                {
                    title: '发起人',
                    dataIndex: 'applyUsername',
                    key: 'applyUsername',
                },
                // {
                //     title: '接收人',
                //     dataIndex: 'receiveUsername',
                //     key: 'receiveUsername',
                // },
                // {
                //     title: '接收部门',
                //     dataIndex: 'receiveUserDename',
                //     key: 'receiveUserDename',
                // },
                {
                    title: '合同名称',
                    dataIndex: 'contractName',
                    key: 'contractName',
                },
                {
                    title: '合同编号',
                    dataIndex: 'invoice_code',
                    key: 'invoice_code',
                }, {
                    title: '合同金额(元)',
                    dataIndex: 'money',
                    key: 'money',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                },
                {
                    title: '文件类型',
                    dataIndex: 'invo_class',
                    key: 'invo_class',
                }, {
                    title: '文件份数',
                    dataIndex: 'number',
                    key: 'number',
                }, {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                }, {
                    title: '签收日期',
                    dataIndex: 'receive_day',
                    key: 'receive_day',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ]
            scrollWidth = 3000
        }
        this.setState(preState => ({
            classType: index,
            columns,
            scrollWidth,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_DataTransferList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, classType, scrollWidth } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                    </div>

                    <div className='supplier_right designes'>
                        <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>进度</div>
                        <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>结算</div>
                        <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(3)
                        }}>发票</div>
                        <div className={classType === 4 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(4)
                        }}>合同</div>
                    </div>
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} scroll={true} scrollWidth={scrollWidth} dataLimit={this.handleLimit} />
                }
            </div>
        )
    }
}

export default withRouter(List)
