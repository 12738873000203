import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import { revenueMain } from "../../../utils/market"
import { receiptManagementList } from "../../../utils/project"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;


class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: '提交日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '操作人',
                    dataIndex: 'operation_user',
                    key: 'operation_user',
                },
                {
                    title: '记录人',
                    dataIndex: 'log_user',
                    key: 'log_user',
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            proList: []
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys, proList } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    proList=[]
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            proList = [...proList, ...item.project[key]]
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                        proList
                    }, () => {
                        this.get_receiptManagementList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/project/machinery/NewItem'
        let CachingKeys = getCachingKeys()
        let { pro_id, proList } = this.state
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
		localStorage.setItem('itemForm', '')
        setTimeout(() => {
            proList.forEach((item, index) => {
                if (item.project_id === pro_id) {
                    localStorage.setItem('itemForm', JSON.stringify(item))
                }
            })
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/machinery/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_receiptManagementList = () => {
        let { pro_id, dataSource, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            class: 3,
            project_id: pro_id,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        receiptManagementList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.receipts.length) {
                        res.data.receipts.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                created_at: item.created_at,
                                operation_user: item.operation_user,
                                log_user: item.log_user,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_receiptManagementList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_receiptManagementList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }


    render() {
        let { searchShow, dataSource, columns, treeData, expandedKeys, selectedKeys } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} educe={true} seek={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
