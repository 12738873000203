import React, { Component } from 'react'
import InitialValue from '../../components/InitialValue'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createWorkNotice, WorkNoticeDetail } from "../../../utils/public"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "工作通知暂管申请",
            TableInfo: [
                {
                    FieldTitle: '发起人', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '部门名称', //字段标题
                    fieldName: 'deName', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '选择通知人', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '通知人', //字段标题
                    fieldName: 'con_period', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        read_userName: [],
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_WorkNoticeDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '发起人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门名称') {
                item.value = userInfo.jobName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_WorkNoticeDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        WorkNoticeDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '发起人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.applyUserDeName
                        } else if (item.FieldTitle === '通知人') {
                            item.value = []
                            res.data.Detail.usersData.forEach((em, ex) => {
                                item.value.push(em.receiveUsersName + ' , ')
                                em.key = em.table_id
                                em.username = em.receiveUsersName
                            })
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        info: res.data.Detail.info,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        read_userName: res.data.Detail.usersData
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info, read_userName } = this.state;
        form.status = status
        form.info = data.info
        form.users = []
        read_userName.forEach((item, index) => {
            form.users.push(item.key)
        })
        form.users = JSON.stringify(form.users)

        createWorkNotice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, read_userName } = this.state
        if (value.length) {
            read_userName=[]
            read_userName = [...read_userName, ...value]
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '通知人') {
                    let userName = []
                    read_userName.forEach((em, ex) => {
                        userName.push(em.username + ' , ')
                    })
    
                    item.value = userName
                }
            })
        }else{
            read_userName=[]
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '通知人') {
                    item.value = []
                }
            })
        }


        this.setState(preState => ({
            Info,
            form,
            read_userName,
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }


    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
