import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [
                {
                    key: '1',
                    title: 'BIM中心',
                    time: '2022-03-01',
                    name: '胡彦斌',
                    state: '已驳回'
                },
            ],
            columns: [
                {
                    title: '序号',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: '功能',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '点击量排行',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, index) => {
                        return (
                            <div></div>
                        )
                    }
                },               
            ],
            TableInfo: [
                {
                    FieldTitle: '功能', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
        }
    }

    handleNew = () => { }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false
        })
    }

    searchQuery = () => {
        console.log('筛选')
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo } = this.state
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                {
                    <Table columns={columns} dataSource={dataSource} />
                }
            </div>
        )
    }
}

export default withRouter(List)
