import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { reviewApplyResult } from "../../../utils/api"
import { ProjectResponsibilitiesDetail, createdProjectResponsibilities, BlankingSelectProject } from "../../../utils/project"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "获取项目责任书信息",
                TableInfo: [                    
                    // {
                    //     FieldTitle: '选择项目', //字段标题
                    //     fieldType: '按钮',  //字段类型
                    //     mandatory: true
                    // },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    }, {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_code', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    }, {
                        FieldTitle: '项目造价(元)', //字段标题
                        fieldName: 'cost', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                ],
                FirstInfo: [{
                    plateName: '项目责任人',
                    list: [{
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'res_user', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '身份证号码', //字段标题
                        fieldName: 'card', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '是否购买社保', //字段标题
                        fieldName: 'is_social', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            }
                        ]
                    },
                    {
                        FieldTitle: '是否签劳动合同', //字段标题
                        fieldName: 'is_con', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            }
                        ]
                    },
                    {
                        FieldTitle: '担保形式', //字段标题
                        fieldName: 'security', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            {
                                value: 1,
                                label: '公司'
                            },
                            {
                                value: 2,
                                label: '房产'
                            },
                            {
                                value: 3,
                                label: '现金担保'
                            },
                            {
                                value: 4,
                                label: '无'
                            }
                        ]
                    }, {
                        FieldTitle: '项目利润(%)', //字段标题
                        fieldName: 'profit', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '风险金(%)', //字段标题
                        fieldName: 'risk_fund', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '附加条款', //字段标题
                        fieldName: 'term', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                    ]
                }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                }, {
                    title: '项目编号',
                    dataIndex: 'pro_number',
                    key: 'pro_number',
                },
                {
                    title: '项目经理',
                    dataIndex: 'pro_manager',
                    key: 'pro_manager',
                },
                {
                    title: '项目地址',
                    dataIndex: 'pro_address',
                    key: 'pro_address',
                },
            ],
            TableInfo: [{
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本', //字段类型
                disabled: false, //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '95%',
            }],
            modal_Visible: false,
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ProjectResponsibilitiesDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_ProjectResponsibilitiesDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        ProjectResponsibilitiesDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        //form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            // if (item.FieldTitle === '选择项目') {
                            //     item.isShow = true
                            // }

                             if (!res.data.Detail.enclosure) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                            })
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_code
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        pro_code: res.data.Detail.pro_code,
                        cost: res.data.Detail.cost,
                        res_user: res.data.Detail.res_user,
                        card: res.data.Detail.card,
                        is_social: res.data.Detail.is_social,
                        is_con: res.data.Detail.is_con,
                        security: res.data.Detail.security,
                        profit: res.data.Detail.profit,
                        risk_fund: res.data.Detail.risk_fund,
                        term: res.data.Detail.term,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.pro_code = data.pro_code
        form.cost = data.cost
        form.res_user = data.res_user
        form.card = data.card
        form.is_social = data.is_social
        form.is_con = data.is_con
        form.security = data.security
        form.profit = data.profit
        form.risk_fund = data.risk_fund
        form.term = data.term

        createdProjectResponsibilities(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_BlankingSelectProject()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form } = this.state;

        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}