import React, { Component } from 'react'
import axios from 'axios'
import { Select, Button, Modal, message } from 'antd';
import { CaretDownOutlined, BarChartOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Table from '../../../components/Table'
import calendarImg from "../../../../accets/img/calendar.png"
import { weeklyListStatistics } from "../../../../utils/oa"
import { getCompanyDeName } from "../../../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            weekList: [],
            dataSource: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '部门',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '应提交次数',
                    dataIndex: 'total',
                    key: 'total',
                },
                {
                    title: '已提交次数',
                    dataIndex: 'Submitted',
                    key: 'Submitted',
                },
                {
                    title: '未提交次数',
                    dataIndex: 'notSubmitted',
                    key: 'notSubmitted',
                },
                {
                    title: '未提交日期',
                    dataIndex: 'detail',
                    key: 'detail',
                    render: (text, index) => {
                        return (
                            <div style={{
                                fontSize: '16px',
                                color: '#339EF9',
                                cursor: 'pointer'
                            }} onClick={() => { this.modalVisible(text) }}>
                                点击查看
                            </div>
                        )
                    }
                },
            ],
            yearList: [
                { value: 2022, label: "2022年" },
                { value: 2023, label: "2023年" },
                { value: 2024, label: "2024年" },
                { value: 2025, label: "2025年" },
                { value: 2026, label: "2026年" },
                { value: 2027, label: "2027年" },
                { value: 2028, label: "2028年" },
                { value: 2029, label: "2029年" },
                { value: 2030, label: "2030年" },
            ],
            yearValue: '',
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
            teamList: [],
            teamValue: '',
            weeklyModal: false,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_CompanyDeName()
        this.get_weeklyListStatistics()
    }

    get_CompanyDeName = () => {
        let { teamList } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    res.data.departments.forEach((item, index) => {
                        let value = {
                            value: item.id,
                            label: item.name
                        }
                        teamList = [...teamList, value]
                    })
                    this.setState(preState => ({
                        teamList
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_weeklyListStatistics = () => {
        let { dataSource, pages, yearValue, monthValue, teamValue } = this.state
        let data = {
            year: yearValue,
            month: monthValue,
            de_id: teamValue,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        weeklyListStatistics(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                name: item.name,
                                deName: item.deName,
                                total: item.total,
                                Submitted: item.Submitted,
                                notSubmitted: item.notSubmitted,
                                detail: item.detail,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleExport = () => {
        let { yearValue, monthValue, teamValue } = this.state
        let data = {
            year: yearValue,
            month: monthValue,
            de_id: teamValue,
        }
        // localStorage.setItem('Blob', true)
        // weeklyLeadingOut(data).then(
        //     (res) => {
        //         const blob = new Blob([res], {
        //             type: 'application/vnd.ms-excel;charset=utf-8'
        //         })

        //         const link = document.createElement('a');
        //         link.download = '周报统计.xlsx'
        //         link.href = URL.createObjectURL(blob)
        //         link.target = "_blank"
        //         document.body.appendChild(link)
        //         link.click();
        //         //释放URL对象
        //         URL.revokeObjectURL(link.href);
        //         document.body.removeChild(link);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );

        axios.get('/api/weeklyLeadingOut', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params:data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '周报统计.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    modalVisible = (value) => {
        let { weekList } = this.state
        weekList = []
        if (value.length) {
            value.forEach((item, index) => {
                if (!item.apply) {
                    let label = {
                        year: item.e ? item.e.slice(0, 4) : '',
                        month: item.e ? item.e.slice(5, 10) : '',
                    }
                    weekList = [...weekList, label]
                }
            })
            this.setState({
                weekList,
                weeklyModal: true
            })
        } else {
            message.error('暂无未提交周报')
        }
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => { this.get_weeklyListStatistics() })
    }

    monthChange = (value) => {
        this.setState({
            monthValue: value
        }, () => { this.get_weeklyListStatistics() })
    }

    teamChange = (value) => {
        this.setState({
            teamValue: value
        }, () => { this.get_weeklyListStatistics() })
    }

    modalCancel = () => {
        this.setState({
            weeklyModal: false
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => { this.get_weeklyListStatistics() })
    }

    render() {
        let { dataSource, columns, weeklyModal, yearValue, yearList, monthList, monthValue, teamList, teamValue, weekList } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='history ListOperate' style={{ width: 'auto', marginRight: '15px' }}>
                        <Button onClick={() => {
                            this.handleExport()
                        }}>
                            <BarChartOutlined />
                            <div>周报导出</div>
                        </Button>
                    </div>

                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    this.yearChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择月份 :</div>
                            <Select
                                options={monthList}
                                value={monthValue}
                                onChange={(e) => {
                                    this.monthChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择部门 :</div>
                            <Select
                                options={teamList}
                                value={teamValue}
                                onChange={(e) => {
                                    this.teamChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />

                <Modal
                    title='未提交周报日期'
                    centered
                    visible={weeklyModal}
                    onCancel={() => { this.modalCancel() }}
                    wrapClassName='weekly'
                    footer={[
                        <Button type="primary" onClick={() => { this.modalCancel() }}>确认</Button>
                    ]}
                >
                    <div className='weekly_time'>

                        {
                            weekList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={calendarImg} alt=""></img>
                                        <div>
                                            <div>{item.month}</div>
                                            <div>{item.year}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }



                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(List)
