import React, { Component } from 'react'
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { DevelopmentApplicationList, getDevelopmentTechYear } from "../../../utils/scientific_research"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                },
                {
                    title: '项目时间',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text, index) => {
                        return (
                            <div className='timeStyle'>
                                <div>
                                    <div></div>
                                    <div>{text[0]}</div>
                                </div>
                                <div>
                                    <div></div>
                                    <div>{text[1]}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目经费(元)',
                    dataIndex: 'pro_money',
                    key: 'pro_money',
                }, {
                    title: '合同审批',
                    dataIndex: 'con_approve',
                    key: 'con_approve',
                    render: (text, record, index) => {
                        if (record.status === 4) {
                            if (record.contract_status) {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_ConApprovalList(record.table_id)
                                    }}>合同审批记录</div>
                                )
                            } else {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_ConApprovalNewItem(record.table_id)
                                    }}>合同审批</div>
                                )
                            }
                        }
                    }
                }, {
                    title: '中期检查',
                    dataIndex: 'Inspect',
                    key: 'Inspect',
                    render: (text, record, index) => {
                        if (record.status === 4) {
                            if (record.inspects_status) {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_InspectNewItems(record.inspects_content.table_id)
                                    }}>{record.inspects_content.status === 2 ? '中期检查中...' : record.inspects_content.status === 3 ? '被驳回' : record.inspects_content.status === 4 ? '已通过' : record.inspects_content.status === 5 ? '已终止' : '中期检查记录'}</div>
                                )
                            } else {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_InspectNewItem(record.table_id)
                                    }}>中期检查</div>
                                )
                            }
                        }
                    }
                }, {
                    title: '结题相关',
                    dataIndex: 'conclude',
                    key: 'conclude',
                    render: (text, record, index) => {
                        if (record.status === 4) {
                            if (!record.acceptances_status && !record.extensions_status) {
                                return (
                                    <div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_ConcludeNewItem(record.table_id)
                                        }}>结题申请</div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_PostponeNewItem(record.table_id)
                                        }}>延期申请</div>
                                    </div>
                                )
                            } else if (record.acceptances_status && record.extensions_status) {
                                return (
                                    <div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_ConcludeNewItems(record.acceptances_taleId)
                                        }}>结题申请记录</div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_PostponeList(record.table_id)
                                        }}>延期结题记录</div>
                                    </div>
                                )
                            } else if (!record.acceptances_status && record.extensions_status) {
                                return (
                                    <div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_ConcludeNewItem(record.table_id)
                                        }}>结题申请</div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_PostponeList(record.table_id)
                                        }}>延期结题记录</div>
                                    </div>
                                )
                            } else if (record.acceptances_status && !record.extensions_status) {
                                return (
                                    <div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_ConcludeNewItems(record.acceptances_taleId)
                                        }}>结题申请记录</div>
                                        <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                            this.get_PostponeNewItem(record.table_id)
                                        }}>延期申请</div>
                                    </div>
                                )
                            }
                        }
                    }
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '200px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },

            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            year: '',
            pro_name: '',
            pro_class: '',
            apply_date: '',
            end_date: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '立项时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_TechYear()
    }

    get_TechYear = () => {
        let { treeData, selectedKeys, expandedKeys, year } = this.state
        getDevelopmentTechYear().then(
            (res) => {
                if (!res.code) {
                    treeData = []
                    let item = {
                        title: '四川天艺生态园林集团股份有限公司',
                        key: 0,
                        icon: <FolderIcon />,
                        children: []
                    }

                    for (let key in res.data) {
                        let value = {
                            title: res.data[key] + '年',
                            key: res.data[key],
                            icon: <FoldersIcon />,
                        }
                        item.children = [...item.children, value]
                    }

                    treeData = [...treeData, item]

                    if (!year) {
                        selectedKeys = []
                        expandedKeys = [0]
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        year = em.key
                                        selectedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        year,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_DevelopmentApplicationList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DevelopmentApplicationList = () => {
        let { year, pro_name, start_time, end_time, dataSource, pages, process_state } = this.state
        if (!year) {
            message.error('请选择年份')
            return
        }

        let data = {
            year: year,
            pro_name: pro_name,
            de_name: '',
            start_time: start_time,
            end_time: end_time,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        DevelopmentApplicationList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.development.length) {
                        res.data.development.forEach((item, index) => {
                            let start_time=item.start_time.slice(0,10)
                            let end_time=item.end_time.slice(0,10)
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                deName: item.deName,
                                pro_name: item.pro_name,
                                time: [start_time, end_time],
                                pro_money: item.pro_money,
                                contract_status: item.con_status,
                                inspects_status: item.inspects_status,
                                inspects_content: item.inspects_content,
                                acceptances_status: item.acceptances_status,
                                acceptances_taleId: item.acceptances_taleId,
                                extensions_status: item.extensions_status,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: res.data.pages.page,
                            limit: res.data.pages.limit,
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/project/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/project/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_ConApprovalList(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/ConApprovalList')
    }

    get_ConApprovalNewItem(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/ConApprovalNewItem')
    }

    get_InspectNewItem(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/InspectNewItem')
    }

    get_InspectNewItems(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/project/InspectNewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_ConcludeNewItem(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/ConcludeNewItem')
    }

    get_ConcludeNewItems(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/project/ConcludeNewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_PostponeList(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/PostponeList')
    }

    get_PostponeNewItem(tableId) {
        sessionStorage.setItem('development_id', tableId)
        this.props.history.push('/scientific_research/project/PostponeNewItem')
    }

    handleStatistical = () => {
        this.props.history.push('/project/build/Summary')
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            start_time: '',
            end_time: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_DevelopmentApplicationList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            start_time: value.start_time ? value.start_time.format('YYYY-MM-DD') : '',
            end_time: value.end_time ? value.end_time.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_DevelopmentApplicationList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_DevelopmentApplicationList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            year: value[0],
        }), () => {
            this.get_DevelopmentApplicationList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_DevelopmentApplicationList()
        })
    }

    get_result = (tableId) => {
        let { form } = this.state
        form.tech_id = tableId
        this.setState({
            modal_Visible: true,
            form,
            FlowchartKey: Math.random(),
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} scroll={true} scrollWidth={1500} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
