import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { stayReadConstructionLog, readConstructionLog } from "../../utils/index"
import Operate from '../components/Operate'
import Search from '../components/Search';
import Table from '../components/Table'

class weekly extends Component {
    state = {
        searchShow: false,
        columns: [
            {
                title: '填报人',
                dataIndex: 'title',
                key: 'title',
                render: (text, record, index) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                             
                            this.get_details(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '填报人',
                dataIndex: 'apply_name',
                key: 'apply_name',
            },
            {
                title: '填报日期',
                dataIndex: 'apply_date',
                key: 'apply_date',
            },
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '当日施工进展',
                dataIndex: 'progress',
                key: 'progress',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '需要协调问题',
                dataIndex: 'problem',
                key: 'problem',
                width: '200px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '已读/未读',
                dataIndex: 'is_read',
                key: 'is_read',
                render: (text, index) => {
                    if (text === 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ backgroundColor: '#F53500', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></div>
                                <div>未读</div>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ backgroundColor: '#00DC9E', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></span>
                                <span>已读</span>
                            </div>
                        )
                    }
                }
            }
        ],
        dataSource: [],
        TableInfo: [
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '24%',
            },
        ],
        pro_name: '',
        pages: {
            limit: 8,
            page: 1,
            total: 0
        }
    }

    componentDidMount() {
        this.get_stayReadConstructionLog()
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/project/build/NewItem',
            query: {
                'tableId': tableId,
            }
        })
        this.handleLog(tableId)
    }

    get_stayReadConstructionLog = () => {
        let { pro_name, pages, dataSource } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        stayReadConstructionLog(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.cons.length) {
                        res.data.cons.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                title: item.applyUsername + '的施工日志',
                                apply_name: item.applyUsername,
                                apply_date: item.apply_date,
                                pro_name: item.proName,
                                progress: item.progress,
                                problem: item.problem,
                                is_read: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLog = (tableId) => {
        let data = {
            table_id: tableId,
            range: 'single'
        }
        readConstructionLog(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_stayReadConstructionLog()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
        }, () => {
            this.get_stayReadConstructionLog()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_stayReadConstructionLog()
        })
    }

    render() {
        return (
            <div>
                <Operate dontAdd={true} dontImport={true} notPrint={true} educe={true} searchEvent={this.handleSearch} searchShow={this.state.searchShow} />

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(weekly)
