import React, { Component } from 'react'
import { Button } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { initiationProjectList } from "../../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目编号',
                    dataIndex: 'pro_number',
                    key: 'pro_number',
                },
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    render: (text, record, index) => {
                        if (!text.is_manager && !text.proTeamAuth) {
                            return (
                                <div className='appoint_operate'>
                                    <div onClick={() => {
                                        this.handleAppointNewitem(record)
                                    }}>任命</div>
                                    <div onClick={() => {
                                        this.handleAccredit(record, 'add')
                                    }}>授权项目团队</div>
                                </div>
                            )
                        } else if (text.is_manager && !text.proTeamAuth) {
                            return (
                                <div className='appoint_operate'>
                                    <div onClick={() => {
                                        this.editAppointNewitem(record.proUserTable_id)
                                    }}>调整任命</div>
                                    <div onClick={() => {
                                        this.handleAccredit(record, 'add')
                                    }}>授权项目团队</div>
                                </div>
                            )
                        } else if (!text.is_manager && text.proTeamAuth) {
                            return (
                                <div className='appoint_operate'>
                                    <div onClick={() => {
                                        this.handleAppointNewitem(record)
                                    }}>任命</div>
                                    <div onClick={() => {
                                        this.handleAccredit(record, 'edit')
                                    }}>修改团队授权</div>
                                </div>
                            )
                        } else if (text.is_manager && text.proTeamAuth) {
                            return (
                                <div className='appoint_operate'>
                                    <div onClick={() => {
                                        this.editAppointNewitem(record.proUserTable_id)
                                    }}>调整任命</div>
                                    <div onClick={() => {
                                        this.handleAccredit(record, 'edit')
                                    }}>修改团队授权</div>
                                </div>
                            )
                        }
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'code', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            code: '',
            pro_name: ''
        }
    }

    componentDidMount() {
        this.get_initiationProjectList()
    }

    get_initiationProjectList = () => {
        let { dataSource, pages, code, pro_name } = this.state
        let data = {
            code: code,
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        initiationProjectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                pro_id: item.table_id,
                                proUserTable_id: item.proUserTable_id,
                                pro_number: item.pro_number,
                                pro_name: item.pro_name,
                                manager: item.managerName,
                                operation: {
                                    is_manager: item.managerName,
                                    proTeamAuth: item.proTeamAuth
                                }
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAppoint = () => {
        this.props.history.push('/administrative/human/projectTeam/AppointList')
    }

    handleAccredit = (project, status) => {
        project = JSON.stringify(project)
        this.props.history.push({
            pathname: '/administrative/human/projectTeam/AccreditNewItem',
            query: {
                'project': project,
                'status': status
            }
        })
    }

    handleAppointNewitem = (project) => {
        project = JSON.stringify(project)
        this.props.history.push({
            pathname: '/administrative/human/projectTeam/AppointNewItem',
            query: {
                'project': project
            }
        })
    }

    editAppointNewitem = (tableId) => {
        this.props.history.push({
            pathname: '/administrative/human/projectTeam/AppointNewItem',
            query: {
                'tableId': tableId,
                'handle':'调整任命'
            }
        })
    }

    handleNew = () => {

    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            code: '',
            pro_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_initiationProjectList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            code: value.code,
            pro_name: value.pro_name
        }, () => {
            this.get_initiationProjectList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_initiationProjectList()
        })
    }

    render() {
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate dontAdd={true} searchShow={this.state.searchShow} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport}/>

                        <div className='history ListOperate'>
                            <Button onClick={() => {
                                this.handleAppoint()
                            }} style={{ marginLeft: 0 }}>
                                <FileDoneOutlined />
                                <div>团队任命</div>
                            </Button>
                        </div>
                    </div>
                </div>

                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
