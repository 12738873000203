import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import { useSealApplyAuxiliaryData, createUseApply, useSealApplyDetail } from "../../../../utils/oa"
import { reviewApplyResult, selectProjectName } from "../../../../utils/api"
import { selectAvailableSeal } from "../../../../utils/public"
import InitialValue from '../../../components/InitialValue'

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            isExport: true,
            template: '/sealApplication.docx',
            templateName: '四川天艺生态园林集团股份有限公司用章申请',
            tempData: {
                number: '',
                user_name: '',
                deName: '',
                use_time: '',
                is_eng: '',
                eng_class: '',
                useNum: '',
                proName: '',
                fileNames: '',
                yes_by: '',
                info: '',
                results: []
            },
            FlowModelName: "用章申请",
            TableInfo: [{
                FieldTitle: '编号', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '(自动编号)',
                width: '50%'
            }, {
                FieldTitle: '申请人', //字段标题
                fieldName: 'userName',  //字段类型
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '申请部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: true,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            },
            {
                FieldTitle: '是否涉及工程资料', //字段标题
                fieldName: 'is_eng', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择是否涉及工程资料', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ],
            },
            {
                FieldTitle: '工程资料具体分类', //字段标题
                fieldName: 'eng_class', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择工程资料具体分类', //必填要求
                value: '',
                width: '100%',
                radioList: [
                    { value: 1, label: "不涉及金额" },
                    { value: 2, label: "签证变更" },
                    { value: 3, label: "收款等涉及金额" },
                    { value: 4, label: "结算" },
                    { value: 5, label: "授权及法人签字" },
                ],
                isShow: true,
            },
            {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                isShow: false,
                mandatory: true, //字段是否必填
                isShow: true,
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true,
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true,
            },
            {
                FieldTitle: '是否为358工程用章', //字段标题
                fieldName: 'is_res_pro', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择是否为358工程用章', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    { value: 1, label: "是" },
                    { value: 2, label: "否" },
                ],
            }, {
                FieldTitle: '用印时间', //字段标题
                fieldName: 'use_time', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择用印时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '用印份数', //字段标题
                fieldName: 'useNum', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入用印份数', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '用印文件名称', //字段标题
                fieldName: 'fileNames', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入用印文件名称', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '选择印章', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '印章名称', //字段标题
                fieldName: 'seal_id', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
            // {
            //     FieldTitle: '印章名称', //字段标题
            //     fieldName: 'seal_id', //字段名称
            //     fieldType: '单选',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '100%',
            //     radioList: []
            // }, 
            {
                FieldTitle: '用印事由', //字段标题
                fieldName: 'yes_by', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'remarks', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {},
        modal_Visible: false,
        buttonTitle: '',
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        seal_name: '',
        pro_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 8,
        },
        radio:false,
        FlowchartKey: 1,
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_useSealApplyAuxiliaryData()
    }

    get_useSealApplyAuxiliaryData = () => {
        const { Info, form } = this.state
        useSealApplyAuxiliaryData().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        // if (item.FieldTitle === '印章名称') {
                        //     res.data.sealData.forEach((em, ex) => {
                        //         let value = {
                        //             value: em.table_id,
                        //             label: em.name
                        //         }
                        //         item.radioList = [...item.radioList, value]
                        //     })
                        // } else 
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.apply_user.username
                        }
                        else if (item.FieldTitle === '申请部门') {
                            item.value = res.data.apply_user.de_name
                        }
                    })

                    form.apply_user = res.data.apply_user.user_id
                    form.de_id = res.data.apply_user.de_id

                    this.setState(preState => ({
                        Info,
                        form
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_useSealApplyDetail(tableId)
                        } else {
                            this.get_FormState()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_useSealApplyDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        useSealApplyDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.apply_user = res.data.Detail.apply_user
                        form.de_id = res.data.Detail.de_id
                        form.seal_id = res.data.Detail.seal_id
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission

                        if (res.data.Detail.is_eng === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '工程资料具体分类') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '选择项目') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '项目名称') {
                                    item.isShow = false
                                    item.value = res.data.Detail.projectName
                                } else if (item.FieldTitle === '项目编号') {
                                    item.isShow = false
                                    item.value = res.data.Detail.projectNumber
                                }
                            })
                        }
                    } else {
                        if (res.data.Detail.status === 4) {
                            Info.tempData.number = res.data.Detail.code
                            Info.tempData.user_name = res.data.Detail.username
                            Info.tempData.deName = res.data.Detail.deName
                            Info.tempData.use_time = res.data.Detail.use_time
                            Info.tempData.is_eng = res.data.Detail.is_eng === 1 ? '是' : '否'
                            Info.tempData.eng_class = res.data.Detail.eng_class === 1 ? '不涉及金额' : res.data.Detail.eng_class === 2 ? '签证变更' : res.data.Detail.eng_class === 3 ? '收款等涉及金额' : res.data.Detail.eng_class === 4 ? '结算' : res.data.Detail.eng_class === 5 ? '授权及法人签字' : '/'
                            Info.tempData.useNum = res.data.Detail.use_num
                            Info.tempData.proName = res.data.Detail.is_eng === 1 ? res.data.Detail.projectName : '/'
                            Info.tempData.fileNames = res.data.Detail.usefiles
                            Info.tempData.sealnames = res.data.Detail.sealnames
                            Info.tempData.yes_by = res.data.Detail.yes_by
                            Info.tempData.info = res.data.Detail.remarks || '/'
                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }

                            if (item.FieldTitle === '选择印章') {
                                item.isShow = true
                            }

                            if (res.data.Detail.is_eng === 1) {
                                if (item.FieldTitle === '工程资料具体分类') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '项目名称') {
                                    item.isShow = false
                                    item.value = res.data.Detail.projectName
                                } else if (item.FieldTitle === '项目编号') {
                                    item.isShow = false
                                    item.value = res.data.Detail.projectNumber
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission


                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '编号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '申请部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '印章名称') {
                            item.value = res.data.Detail.sealnames
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                            if (res.data.Detail.enclosure.length) {
                                let enclosure = []
                                res.data.Detail.enclosure.forEach((em, ex) => {
                                    enclosure = [...enclosure, em.table_id]
                                })
                                form.enclosure = JSON.stringify(enclosure)
                                item.value = res.data.Detail.enclosure
                            }
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        use_time: res.data.Detail.use_time ? moment(res.data.Detail.use_time, 'YYYY-MM-DD') : '',
                        useNum: res.data.Detail.use_num,
                        fileNames: res.data.Detail.usefiles,
                        seal_id: res.data.Detail.seal_id,
                        yes_by: res.data.Detail.yes_by,
                        remarks: res.data.Detail.remarks,
                        is_eng: res.data.Detail.is_eng || '',
                        is_res_pro: res.data.Detail.is_res_pro || '',
                        eng_class: res.data.Detail.eng_class,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleRadio = (value) => {
        const { Info } = this.state
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '工程资料具体分类') {
                    item.isShow = false
                } else if (item.FieldTitle === '选择项目') {
                    item.isShow = false
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = false
                } else if (item.FieldTitle === '项目编号') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '工程资料具体分类') {
                    item.isShow = true
                } else if (item.FieldTitle === '选择项目') {
                    item.isShow = true
                } else if (item.FieldTitle === '项目名称') {
                    item.isShow = true
                } else if (item.FieldTitle === '项目编号') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.is_eng = data.is_eng
        form.is_res_pro = data.is_res_pro
        form.eng_class = data.eng_class || ''
        form.use_time = data.use_time ? data.use_time.format('YYYY-MM-DD') : ''
        form.useNum = data.useNum
        form.fileNames = data.fileNames
        form.yes_by = data.yes_by
        form.remarks = data.remarks

        createUseApply(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                        pages: {
                            page: 1,
                            total: 0,
                            limit: 50,
                        },
                        radio:false,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: {
                                page: Number(res.data.pages.page),
                                limit:Number(res.data.pages.limit),
                                total: res.data.pages.total
                            },
                            modal_Visible: true,
                            radio:true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择印章') {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_seal()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'managerName',
                        key: 'managerName',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_ProjectName()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                FlowchartKey: Math.random(),
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                workAdd: false
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章名称') {
                    item.value = ''
                    form.seal_id = []
                    console.log(value)
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })

                    console.log(item.value)
                    form.seal_id = JSON.stringify(form.seal_id)
                }
            })
        } else {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                }
            })
        }
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择印章') {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        } else {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_ProjectName()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            seal_name: '',
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else {
                this.get_ProjectName()
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages,radio,FlowchartKey } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} radioEvent={this.handleRadio} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
