import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import { createUserLeave, lookLeaveDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "请假申请",
            TableInfo: [],
            FirstInfo: [{
                plateName: '基本信息',
                list: [
                    {
                        FieldTitle: '姓名', //字段标题
                        fieldName: 'name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '申请日期', //字段标题
                        fieldName: 'apply_date', //字段名称
                        fieldType: '日期',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择申请时间', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '部门名称', //字段标题
                        fieldName: 'deName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '职位名称', //字段标题
                        fieldName: 'jobName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: true,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%'
                    }, {
                        FieldTitle: '请假类型', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '选择器',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择请假类型', //必填要求
                        value: '',
                        width: '50%',
                        selectList: [
                            { value: 1, label: "病假" },
                            { value: 2, label: "事假" },
                            { value: 3, label: "丧假" },
                            { value: 4, label: "婚假" },
                            { value: 5, label: "产假" },
                            { value: 6, label: "产检假" },
                            { value: 7, label: "陪产假" },
                            { value: 8, label: "哺乳假" },

                        ]
                    }, {
                        FieldTitle: '请假时间', //字段标题
                        fieldName: 'time', //字段名称
                        fieldType: '时间区间',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '2023-01-01',
                        width: '50%',
                        child: [
                            {
                                FieldTitle: '开始时间', //字段标题
                                fieldName: 'start', //字段名称
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '结束时间', //字段标题
                                fieldName: 'end', //字段名称
                                value: '',
                                width: '50%',
                            },
                        ]
                    }, {
                        FieldTitle: '请假天数', //字段标题
                        fieldName: 'leave_day', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入请假天数', //必填要求
                        value: '',
                        width: '50%'
                    },
                    {
                        FieldTitle: '请假原因', //字段标题
                        fieldName: 'matter', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入请假原因', //必填要求
                        value: '',
                        width: '100%'
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_lookLeaveDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '姓名') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门名称') {
                item.value = userInfo.deName
            } else if (item.FieldTitle === '职位名称') {
                item.value = userInfo.jobName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_lookLeaveDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        lookLeaveDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission
                    

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '姓名') {
                            item.value = res.data.Detail.applyUserName
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '职位名称') {
                            item.value = res.data.Detail.jobName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        start: res.data.Detail.start ? moment(res.data.Detail.start, 'YYYY-MM-DD HH:mm:ss') : '',
                        end: res.data.Detail.end ? moment(res.data.Detail.end, 'YYYY-MM-DD HH:mm:ss') : '',
                        class: res.data.Detail.class,
                        leave_day: res.data.Detail.leave_day,
                        matter: res.data.Detail.matter,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
        form.start = data.start ? data.start.format('YYYY-MM-DD HH:mm:ss') : ''
        form.end = data.end ? data.end.format('YYYY-MM-DD HH:mm:ss') : ''
        form.class = data.class
        form.leave_day = data.leave_day
        form.matter = data.matter


        createUserLeave(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    render() {
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={this.state.Info} presentEvent={this.handPresent} fileEvent={this.handFile} auditEvent={this.handleButton} />
            </div>
        )
    }
}
