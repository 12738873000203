import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createDataTransfer, DataTransferDetail, BlankingSelectProject, aboutMachineryContract, TransferAccThisMonthOutput } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"
import { supplierSelect } from "../../../utils/purchase"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "资料移交单",
            TableInfo: [{
                FieldTitle: '资料类型', //字段标题
                fieldName: 'data_class', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    {
                        value: 1,
                        label: '进度'
                    },
                    {
                        value: 2,
                        label: '结算'
                    },
                    {
                        value: 3,
                        label: '发票'
                    },
                    {
                        value: 4,
                        label: '合同'
                    }
                ]
            }, {
                FieldTitle: '移交部门', //字段标题
                fieldName: 'tax_invo', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    {
                        value: 1,
                        label: '集采中心'
                    },
                    {
                        value: 2,
                        label: '资料中心'
                    },
                ],
                isShow: true
            },
            {
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '移交日期', //字段标题
                fieldName: 'tran_day', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '发起人', //字段标题
                fieldName: 'userName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '发起人部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '文件类型', //字段标题
                fieldName: 'invo_class', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '材料'
                    },
                    {
                        value: 2,
                        label: '机械'
                    },
                    {
                        value: 3,
                        label: '劳务'
                    },
                ]
            },
            // {
            //     FieldTitle: '选择接收人', //字段标题
            //     fieldType: '按钮',  //字段类型
            // }, {
            //     FieldTitle: '接收人', //字段标题
            //     fieldName: 'receive_user', //字段名称
            //     fieldType: '默认',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%',
            // },
            //  {
            //     FieldTitle: '接收部门', //字段标题
            //     fieldName: 'recipient_deName', //字段名称
            //     fieldType: '默认',  //字段类型
            //     disabled: false,  //字段是否禁用
            //     mandatory: false, //字段是否必填
            //     required: '', //必填要求
            //     value: '',
            //     width: '50%',
            // },

            {
                FieldTitle: '选择合同', //字段标题
                fieldType: '按钮',  //字段类型
                isShow: true
            }, {
                FieldTitle: '合同名称', //字段标题
                fieldName: 'con_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '合同编号', //字段标题
                fieldName: 'invoice_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '合同金额', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '选择供应商', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            },
            {
                FieldTitle: '供应商名称', //字段标题
                fieldName: 'supplier', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '文件份数', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '本月完成产值(元)', //字段标题
                fieldName: 'adm_price', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            }, {
                FieldTitle: '计量月份', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "1月" },
                    { value: 2, label: "2月" },
                    { value: 3, label: "3月" },
                    { value: 4, label: "4月" },
                    { value: 5, label: "5月" },
                    { value: 6, label: "6月" },
                    { value: 7, label: "7月" },
                    { value: 8, label: "8月" },
                    { value: 9, label: "9月" },
                    { value: 10, label: "10月" },
                    { value: 11, label: "11月" },
                    { value: 12, label: "12月" },
                ],
            }, {
                FieldTitle: '至本月累计完成产值(元)', //字段标题
                fieldName: 'invoice_code', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '结算金额(元)', //字段标题
                fieldName: 'adm_price', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            },
            {
                FieldTitle: '发票类型', //字段标题
                fieldName: 'invo_class', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '增值税专用发票'
                    },
                    {
                        value: 2,
                        label: '增值税普通发票'
                    },
                    {
                        value: 3,
                        label: '免税发票'
                    },
                ],
                isShow: true
            }, {
                FieldTitle: '税率(认价)', //字段标题
                fieldName: 'adm_price', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '0%'
                    },
                    {
                        value: 2,
                        label: '1%'
                    },
                    {
                        value: 3,
                        label: '3%'
                    },
                    {
                        value: 4,
                        label: '6%'
                    },
                    {
                        value: 5,
                        label: '9%'
                    },
                    {
                        value: 6,
                        label: '13%'
                    },
                ],
                isShow: true
            }, {
                FieldTitle: '税率(开票)', //字段标题
                fieldName: 'tax_invo', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    {
                        value: 1,
                        label: '0%'
                    },
                    {
                        value: 2,
                        label: '1%'
                    },
                    {
                        value: 3,
                        label: '3%'
                    },
                    {
                        value: 4,
                        label: '6%'
                    },
                    {
                        value: 5,
                        label: '9%'
                    },
                    {
                        value: 6,
                        label: '13%'
                    },
                ],
                isShow: true
            }, {
                FieldTitle: '发票编号', //字段标题
                fieldName: 'invoice_code', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '发票份数', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '本期金额(元)', //字段标题
                fieldName: 'money', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                isShow: true
            }, {
                FieldTitle: '有无OFD', //字段标题
                fieldName: 'ofd', //字段名称
                fieldType: '单选',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
                radioList: [
                    {
                        value: 1,
                        label: '有'
                    },
                    {
                        value: 2,
                        label: '无'
                    },
                ],
                isShow: true
            }, {
                FieldTitle: '累计已开票金额(元)', //字段标题
                fieldName: 'cumulative_billing', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
                isShow: true
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            },
            ],
            FirstInfo: [],
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        username: '',
        de_name: '',
        con_number: '',
        sup_name: '',
        con_name: '',
        pro_name: '',
        sup_class: '',
        sup_name: '',
        mat_class: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        radio: true,
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DataTransferDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '发起人') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '发起人部门') {
                item.value = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_DataTransferDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        DataTransferDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.invoice_unit = res.data.Detail.invoice_unit
                        form.receive_user = res.data.Detail.receive_user
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }
                            // else if (item.FieldTitle === '选择接收人') {
                            //     item.isShow = true
                            // } 
                            else if (item.FieldTitle === '选择供应商') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.data_class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '文件类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '本月完成产值(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '计量月份') {
                                item.isShow = false
                            } else if (item.FieldTitle === '结算金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票类型') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(认价)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(开票)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票份数') {
                                item.isShow = true
                            } else if (item.FieldTitle === '本期金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '有无OFD') {
                                item.isShow = true
                            } else if (item.FieldTitle === '累计已开票金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '移交部门') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择供应商') {
                                item.isShow = false
                            } else if (item.FieldTitle === '供应商名称') {
                                item.isShow = false
                            } else if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同金额') {
                                item.isShow = true
                            } else if (item.FieldTitle === '文件份数') {
                                item.isShow = true
                            }
                        })
                    } else if (res.data.Detail.data_class === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '文件类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '本月完成产值(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '计量月份') {
                                item.isShow = true
                            } else if (item.FieldTitle === '结算金额(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '发票类型') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(认价)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(开票)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票份数') {
                                item.isShow = true
                            } else if (item.FieldTitle === '本期金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '有无OFD') {
                                item.isShow = true
                            } else if (item.FieldTitle === '累计已开票金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '移交部门') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择供应商') {
                                item.isShow = false
                            } else if (item.FieldTitle === '供应商名称') {
                                item.isShow = false
                            } else if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同金额') {
                                item.isShow = true
                            } else if (item.FieldTitle === '文件份数') {
                                item.isShow = true
                            }
                        })
                    } else if (res.data.Detail.data_class === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '文件类型') {
                                item.isShow = true
                            } else if (item.FieldTitle === '本月完成产值(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '计量月份') {
                                item.isShow = true
                            } else if (item.FieldTitle === '结算金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '税率(认价)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '税率(开票)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '发票编号') {
                                item.isShow = false
                            } else if (item.FieldTitle === '发票份数') {
                                item.isShow = false
                            } else if (item.FieldTitle === '本期金额(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '有无OFD') {
                                item.isShow = false
                            } else if (item.FieldTitle === '累计已开票金额(元)') {
                                item.isShow = false
                            } else if (item.FieldTitle === '移交部门') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择供应商') {
                                item.isShow = false
                            } else if (item.FieldTitle === '供应商名称') {
                                item.isShow = false
                            } else if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '合同金额') {
                                item.isShow = true
                            } else if (item.FieldTitle === '文件份数') {
                                item.isShow = true
                            }
                        })
                    } else if (res.data.Detail.data_class === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '文件类型') {
                                item.isShow = false
                            } else if (item.FieldTitle === '本月完成产值(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '计量月份') {
                                item.isShow = true
                            } else if (item.FieldTitle === '结算金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票类型') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(认价)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '税率(开票)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票编号') {
                                item.isShow = true
                            } else if (item.FieldTitle === '发票份数') {
                                item.isShow = true
                            } else if (item.FieldTitle === '本期金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '有无OFD') {
                                item.isShow = true
                            } else if (item.FieldTitle === '累计已开票金额(元)') {
                                item.isShow = true
                            } else if (item.FieldTitle === '移交部门') {
                                item.isShow = false
                            } else if (item.FieldTitle === '合同编号') {
                                item.isShow = false
                            } else if (item.FieldTitle === '合同金额') {
                                item.isShow = false
                            } else if (item.FieldTitle === '文件份数') {
                                item.isShow = false
                            } else if (item.FieldTitle === '供应商名称') {
                                item.isShow = false
                            }
                        })

                        if (Number(res.data.Detail.tax_invo) === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择合同') {
                                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                                        item.isShow = true
                                    }
                                } else if (item.FieldTitle === '合同名称') {
                                    item.isShow = true
                                } else if (item.FieldTitle === '选择供应商') {
                                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                                        item.isShow = false
                                    }
                                }
                            })
                        } else if (Number(res.data.Detail.tax_invo) === 2) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择合同') {
                                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                                        item.isShow = false
                                    }
                                } else if (item.FieldTitle === '合同名称') {
                                    item.isShow = false
                                } else if (item.FieldTitle === '选择供应商') {
                                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                                        item.isShow = true
                                    }
                                }
                            })
                        }
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '发起人') {
                            item.value = res.data.Detail.applyUsername
                        } else if (item.FieldTitle === '发起人部门') {
                            item.value = res.data.Detail.applyUserDename
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.proName
                        }
                        // else if (item.FieldTitle === '接收人') {
                        //     item.value = res.data.Detail.receiveUsername
                        // } else if (item.FieldTitle === '接收部门') {
                        //     item.value = res.data.Detail.receiveUserDename
                        // } 
                        else if (item.FieldTitle === '供应商名称') {
                            item.value = res.data.Detail.supplierName
                        } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                            item.value = res.data.Detail.invoice_code
                            form.invoice_code = res.data.Detail.invoice_code
                            form.adm_price = res.data.Detail.adm_price
                        } else if (item.FieldTitle === '累计已开票金额(元)') {
                            item.value = res.data.Detail.accInvoiceMoney
                        } else if (item.FieldTitle === '合同名称') {
                            item.value = res.data.Detail.contractName
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        data_class: res.data.Detail.data_class,
                        tran_day: res.data.Detail.tran_day ? moment(res.data.Detail.tran_day, 'YYYY-MM-DD') : '',
                        invo_class: res.data.Detail.invo_class,
                        adm_price: Number(res.data.Detail.adm_price),
                        tax_invo: Number(res.data.Detail.tax_invo),
                        invoice_code: res.data.Detail.invoice_code,
                        number: res.data.Detail.number,
                        money: res.data.Detail.money,
                        ofd: Number(res.data.Detail.ofd),
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_aboutMachineryContract = () => {
        let { dataSource, pages, con_number, sup_name, con_name, form } = this.state
        let data = {
            project_id: form.project_id,
            con_number: con_number,
            sup_name: sup_name,
            con_name: con_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        aboutMachineryContract(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.macs.length) {
                        res.data.macs.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                con_name: item.con_name,
                                con_number: item.con_number,
                                macType: item.macType,
                                mac_type: item.mac_type,
                                proName: item.proName,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                con_money: item.con_money,
                                totalPayMoney: item.totalPayMoney,
                                acc_invoice: item.acc_invoice,
                                bank_number: item.bank_number,
                                accout_name: item.accounts,
                                bank_name: item.bank_name,
                                contacts: item.contacts,
                                phone: item.phone,
                                sup_name: item.sup_name
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    } else {
                        message.error('当前项目无合同选择！')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierSelect = () => {
        let { dataSource, pages, sup_class, sup_name, mat_class, form } = this.state
        let data = {
            class: sup_class,
            sup_name: sup_name,
            mat_class: mat_class,
            invo_class: form.invo_class,
            project_id: form.project_id,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierSelect(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                class: item.class === 1 ? '材料' : item.class === 2 ? '劳务' : '机械',
                                mat_class: item.matClass,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                accOutput: item.accOutput
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_TransferAccThisMonthOutput = () => {
        let { Info, form } = this.state
        let data = {
            project_id: form.project_id,
            sup_id: form.invoice_unit,
        }
        TransferAccThisMonthOutput(data).then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '累计已开票金额(元)') {
                            item.value = res.data.totalMoney
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleInput = (value, title) => {
        let { form, Info } = this.state
        if (title === '本月完成产值(元)') {
            form.adm_price = value

            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '至本月累计完成产值(元)') {
                    item.value = Number(form.last_month_output) + Number(form.adm_price)
                    form.invoice_code = Number(form.last_month_output) + Number(form.adm_price)
                }
            })

        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.data_class = data.data_class
        form.tran_day = data.tran_day ? data.tran_day.format('YYYY-MM-DD') : ''
        form.invo_class = data.invo_class
        form.adm_price = data.adm_price
        form.tax_invo = data.tax_invo
        if (form.data_class === 3 || form.data_class === 4) {
            form.invoice_code = data.invoice_code
        }
        form.number = data.number
        form.money = data.money
        form.ofd = data.ofd
        form.info = data.info

        createDataTransfer(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                this.get_BlankingSelectProject()
            } else if (title === '选择供应商') {
                columns = [
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                    {
                        title: '供应商类型',
                        dataIndex: 'class',
                        key: 'class',
                    },
                    {
                        title: '所属分类',
                        dataIndex: 'mat_class',
                        key: 'mat_class',
                    },
                    {
                        title: '电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '联系人',
                        dataIndex: 'contacts',
                        key: 'contacts',
                    },
                    {
                        title: '至上月累计完成产值(元)',
                        dataIndex: 'accOutput',
                        key: 'accOutput',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }, {
                        FieldTitle: '供应商类型', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                        selectList: [
                            {
                                label: '材料',
                                value: 1
                            },
                            {
                                label: '劳务',
                                value: 2
                            },
                            {
                                label: '机械',
                                value: 3
                            }
                        ]
                    }, {
                        FieldTitle: '所属分类', //字段标题
                        fieldName: 'mat_class', //字段名称
                        fieldType: '文本', //字段类型
                        disabled: false, //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }
                ]
                this.get_supplierSelect()
            }
            // else if (title === '选择接收人') {
            //     columns = [{
            //         title: '姓名',
            //         dataIndex: 'username',
            //         key: 'username',
            //     },
            //     {
            //         title: '性别',
            //         dataIndex: 'sex',
            //         key: 'sex',
            //         render: (text, index) => {
            //             if (text === '1') {
            //                 return (
            //                     <span> 男 </span>
            //                 )
            //             } else {
            //                 return (<span> 女 </span>
            //                 )
            //             }
            //         }
            //     },
            //     {
            //         title: '部门名称',
            //         dataIndex: 'deName',
            //         key: 'deName',
            //     },
            //     {
            //         title: '职位',
            //         dataIndex: 'company_job',
            //         key: 'company_job',
            //     },
            //     {
            //         title: '手机',
            //         dataIndex: 'phone',
            //         key: 'phone',
            //     },
            //     ]
            //     TableInfo = [{
            //         FieldTitle: '姓名', //字段标题
            //         fieldName: 'username', //字段名称
            //         fieldType: '文本', //字段类型
            //         disabled: false, //字段是否禁用
            //         mandatory: false, //字段是否必填
            //         required: '', //必填要求
            //         value: '',
            //         width: '48%',
            //     }, {
            //         FieldTitle: '部门', //字段标题
            //         fieldName: 'de_name', //字段名称
            //         fieldType: '文本', //字段类型
            //         disabled: false, //字段是否禁用
            //         mandatory: false, //字段是否必填
            //         required: '', //必填要求
            //         value: '',
            //         width: '48%',
            //     }]
            //     this.get_InitialValue()
            // } 
            else if (title === '选择合同') {
                columns = [
                    {
                        title: '合同名称',
                        dataIndex: 'con_name',
                        key: 'con_name',
                    },
                    {
                        title: '合同编号',
                        dataIndex: 'con_number',
                        key: 'con_number',
                    },
                    {
                        title: '合同类别',
                        dataIndex: 'macType',
                        key: 'macType',
                    },
                    {
                        title: '合同总金额',
                        dataIndex: 'con_money',
                        key: 'con_money',
                    },
                    {
                        title: '供应商名称',
                        dataIndex: 'sup_name',
                        key: 'sup_name',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_number', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    },
                    {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    },
                    {
                        FieldTitle: '供应商名称', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '31%',
                    }
                ]
                this.get_aboutMachineryContract()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio: true,
            })
            )

        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                }
            })
        } else if (buttonTitle === '选择供应商') {
            form.invoice_unit = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '供应商名称') {
                    item.value = value[0].sup_name
                } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                    item.value = value[0].accOutput
                    form.last_month_output = value[0].accOutput

                    if (form.data_class === 2) {
                        form.invoice_code = value[0].accOutput
                    }
                }
            })

            if (form.data_class === 3) {
                this.get_TransferAccThisMonthOutput()
            }
        }
        // else if (buttonTitle === '选择接收人') {
        //     form.receive_user = value[0].key
        //     Info.TableInfo.forEach((item, index) => {
        //         if (item.FieldTitle === '接收人') {
        //             item.value = value[0].username
        //         } else if (item.FieldTitle === '接收部门') {
        //             item.value = value[0].deName
        //         }
        //     })
        // }
        else if (buttonTitle === '选择合同') {
            form.invoice_unit = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '合同名称') {
                    item.value = value[0].con_name
                } else if (item.FieldTitle === '供应商名称') {
                    item.value = value[0].sup_name
                }
            })

            this.NewForm.current.formRef.current.setFieldsValue({
                invoice_code: value[0].con_number,
                money: value[0].con_money,
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    handleRadio = (value, title) => {
        let { form, Info } = this.state;
        if (title === '资料类型') {
            form.data_class = value
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '文件类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '本月完成产值(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '计量月份') {
                        item.isShow = false
                    } else if (item.FieldTitle === '结算金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(认价)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(开票)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票份数') {
                        item.isShow = true
                    } else if (item.FieldTitle === '本期金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '有无OFD') {
                        item.isShow = true
                    } else if (item.FieldTitle === '累计已开票金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '移交部门') {
                        item.isShow = true
                    } else if (item.FieldTitle === '选择供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '文件份数') {
                        item.isShow = true
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '文件类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '本月完成产值(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '计量月份') {
                        item.isShow = true
                    } else if (item.FieldTitle === '结算金额(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '发票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(认价)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(开票)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票份数') {
                        item.isShow = true
                    } else if (item.FieldTitle === '本期金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '有无OFD') {
                        item.isShow = true
                    } else if (item.FieldTitle === '累计已开票金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '移交部门') {
                        item.isShow = true
                    } else if (item.FieldTitle === '选择供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '文件份数') {
                        item.isShow = true
                    }
                })
            } else if (value === 3) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '文件类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '本月完成产值(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '计量月份') {
                        item.isShow = true
                    } else if (item.FieldTitle === '结算金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '税率(认价)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '税率(开票)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '发票编号') {
                        item.isShow = false
                    } else if (item.FieldTitle === '发票份数') {
                        item.isShow = false
                    } else if (item.FieldTitle === '本期金额(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '有无OFD') {
                        item.isShow = false
                    } else if (item.FieldTitle === '累计已开票金额(元)') {
                        item.isShow = false
                    } else if (item.FieldTitle === '移交部门') {
                        item.isShow = true
                    } else if (item.FieldTitle === '选择供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同金额') {
                        item.isShow = true
                    } else if (item.FieldTitle === '文件份数') {
                        item.isShow = true
                    }
                })
            } else if (value === 4) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '文件类型') {
                        item.isShow = false
                    } else if (item.FieldTitle === '本月完成产值(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '计量月份') {
                        item.isShow = true
                    } else if (item.FieldTitle === '结算金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票类型') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(认价)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '税率(开票)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票编号') {
                        item.isShow = true
                    } else if (item.FieldTitle === '发票份数') {
                        item.isShow = true
                    } else if (item.FieldTitle === '本期金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '有无OFD') {
                        item.isShow = true
                    } else if (item.FieldTitle === '累计已开票金额(元)') {
                        item.isShow = true
                    } else if (item.FieldTitle === '移交部门') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择供应商') {
                        item.isShow = true
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同编号') {
                        item.isShow = false
                    } else if (item.FieldTitle === '合同金额') {
                        item.isShow = false
                    } else if (item.FieldTitle === '文件份数') {
                        item.isShow = false
                    }
                })
            }
        } else if (title === '移交部门') {
            form.tax_invo = value
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择供应商') {
                        item.isShow = false
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = true
                    } else if (item.FieldTitle === '合同编号') {
                        item.disabled = false
                    } else if (item.FieldTitle === '合同金额') {
                        item.disabled = false
                    }
                })
            } else if (value === 2) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择供应商') {
                        item.isShow = true
                    } else if (item.FieldTitle === '供应商名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '选择合同') {
                        item.isShow = false
                    } else if (item.FieldTitle === '合同名称') {
                        item.isShow = false
                    } else if (item.FieldTitle === '合同编号') {
                        item.disabled = true
                    } else if (item.FieldTitle === '合同金额') {
                        item.disabled = true
                    }
                })
            }
        }

        this.setState(preState => ({
            form,
            Info
        }))
    }

    handSelector = (value) => {
        const { Info, form } = this.state;
        form.invo_class = value
        this.setState(preState => ({
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择供应商') {
            this.setState({
                sup_class: value.sup_class,
                sup_name: value.sup_name,
                mat_class: value.mat_class,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_supplierSelect()
            })
        }
        // else if (buttonTitle === '选择接收人') {
        //     this.setState({
        //         username: value.username,
        //         de_name: value.de_name,
        //         pages: {
        //             page: 1,
        //             total: 0,
        //             limit: 10,
        //         },
        //     }, () => {
        //         this.get_InitialValue()
        //     })
        // }
        else if (buttonTitle === '选择合同') {
            this.setState({
                con_number: value.con_number,
                con_name: value.con_name,
                sup_name: value.sup_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_aboutMachineryContract()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            username: '',
            de_name: '',
            con_number: '',
            sup_name: '',
            con_name: '',
            sup_class: '',
            sup_name: '',
            mat_class: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择供应商') {
                this.get_supplierSelect()
            }
            // else if (buttonTitle === '选择接收人') {
            //     this.get_InitialValue()
            // } 
            else if (buttonTitle === '选择合同') {
                this.get_aboutMachineryContract()
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} radioEvent={this.handleRadio} selectorEvent={this.handSelector} InputEvent={this.handleInput} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={radio} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
