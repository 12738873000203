import React, { Component } from 'react'
import axios from 'axios'
import md5 from 'md5'
import { Button, Upload, message, Modal, Progress } from 'antd';
import { VerticalAlignTopOutlined, FormOutlined, SearchOutlined, PlusOutlined, PrinterOutlined, DownloadOutlined, ReconciliationOutlined, DeleteOutlined } from '@ant-design/icons';


export default class Operate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: {},
            fileChunkList: [],
            isProgress: false,
            percent: 0,
            fileMd5: ''
        }
    }

    updateChange = ({ file, fileList }) => {
        this.setState(preState => ({
            file: file
        }), () => {
            this.createFileChunk()
        })
    }

    createFileChunk = () => {
        let { file, fileChunkList, isProgress } = this.state
        let chunkSize = 1024 * 1024  // 切片每次1M
        let filecount = Math.ceil(file.size / chunkSize)
        let currentChunk = 0 // 当前上传的chunk
        let index = 0
        fileChunkList = []
        while (currentChunk < file.size) {
            const chunk = file.slice(currentChunk, currentChunk + chunkSize);
            fileChunkList.push({ file: chunk, index, filecount }); // 可以加入很多信息。比如MD5
            currentChunk += chunkSize;
            index += 1;
        }

        if (filecount > 5) {
            isProgress = true
        }

        fileChunkList.forEach((item, index) => {
            if (index === 0) {
                let reader = new FileReader()
                let fileDate = item.file
                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    this.setState(preState => ({
                        fileChunkList,
                        isProgress,
                        fileMd5: md5(e.target.result)
                    }), () => {
                        this.fileUpload(0)
                    })
                }
            }
        })
    }

    fileUpload = (uploadIndex) => {
        let { file, fileChunkList, fileList, storeList, fileMd5 } = this.state
        fileChunkList.forEach((item, index) => {//分片传输
            if (index === uploadIndex) {
                let reader = new FileReader()
                let fileDate = item.file
                let data = {
                    file: '',
                    name: file.name,
                    index: item.index,
                    filecount: item.filecount,
                    MD5: fileMd5,
                    is_download: 1
                }

                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    data.file = e.target.result
                    axios({
                        method: 'post',
                        url: '/api/fileUpload',
                        data: data,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer " + localStorage.getItem("Token")
                        }
                    }).then((res) => {
                        if (!res.data.code) {
                            if (res.data.data.result) {
                                let index = res.data.data.index
                                this.fileUpload(index)

                                this.setState(preState => ({
                                    percent: Math.ceil((100 / item.filecount) * index)
                                })
                                )
                            } else {
                                this.props.importEvent(res.data.data.att_id)
                                this.setState(preState => ({
                                    fileList,
                                    storeList,
                                    percent: 0,
                                    isProgress: false,
                                    fileMd5: ''
                                })
                                )
                            }
                        }
                    }).catch((err) => {
                        message.error("上传失败，请稍后重试！");
                        return;
                    })
                }
            }
        })
    }

    handleModal = () => {
        this.setState(preState => ({
            isProgress: false
        }))
    }

    render() {
        let { searchShow, dontAdd, edit, del, newFolder, notPrint, dontLead, dontImport, seek, educe, uploadUrl, template } = this.props;
        let { isProgress, percent } = this.state
        return (
            <div className='ListOperate'>
                <Button onClick={() => {
                    this.props.newEvent()
                }} style={{ display: dontAdd ? 'none' : '', marginLeft: 0 }}>
                    <PlusOutlined />
                    <div>新增</div>
                </Button>

                <Upload name='file'
                    action='/api/fileUpload' //上传文件地址
                    headers={{
                        "Authorization": "Bearer " + localStorage.getItem("Token")
                    }}
                    showUploadList={false}
                    beforeUpload={(file, fileList) => {
                        return false
                    }}
                    onChange={this.updateChange}
                >
                    <Button style={{ display: dontLead ? '' : 'none', marginLeft: dontAdd ? 0 : '15px' }}>
                        <VerticalAlignTopOutlined />
                        <div>导入</div>
                    </Button>
                </Upload>

                <Upload name='tableFile'
                    action={uploadUrl ? uploadUrl.url : ''} //上传文件地址
                    headers={{
                        "Authorization": "Bearer " + localStorage.getItem("Token")
                    }}
                    data={uploadUrl ? uploadUrl.data : ''}
                    showUploadList={false}
                    onChange={(e) => {
                        this.props.uploadEvent(e)
                    }}   //上传状态改变事件

                >
                    <Button style={{ display: dontImport ? 'none' : '', marginLeft: dontAdd ? 0 : '15px' }}>
                        <VerticalAlignTopOutlined />
                        <div>导入</div>
                    </Button>
                </Upload>

                <Button onClick={() => {
                    this.props.templateEvent()
                }} style={{ display: template ? '' : 'none' }}>
                    <ReconciliationOutlined />
                    <div>模板</div>
                </Button>

                <Button onClick={() => {
                    this.props.editEvent()
                }} style={{ display: edit ? '' : 'none' }}>
                    <FormOutlined />
                    <div>编辑</div>
                </Button>

                <Button onClick={() => {
                    this.props.delEvent()
                }} style={{ display: del ? '' : 'none' }}>
                    <DeleteOutlined />
                    <div>删除</div>
                </Button>

                <Button onClick={() => {
                    this.props.newEvent()
                }} style={{ width: 128, display: newFolder ? '' : 'none', marginLeft: dontAdd && dontImport && !dontLead ? 0 : '15px', }}>
                    <PlusOutlined />
                    <div>新建文件夹</div>
                </Button>

                {
                    searchShow ? <Button type="primary" style={{ marginLeft: dontAdd && dontImport && !dontLead ? 0 : '15px', color: '#FFFFFF', display: seek ? 'none' : '' }}>
                        <SearchOutlined />
                        <div>搜索</div>
                    </Button> : <Button style={{ marginLeft: dontAdd && dontImport && !dontLead ? 0 : '15px', display: seek ? 'none' : '' }} onClick={() => {
                        this.props.searchEvent()
                    }} >
                        <SearchOutlined />
                        <div>搜索</div>
                    </Button>
                }

                <Button onClick={() => {
                    this.props.printEvent()
                }} style={{ display: notPrint ? 'none' : '' }}>
                    <PrinterOutlined />
                    <div>打印</div>
                </Button>

                <Button onClick={() => {
                    this.props.exportEvent()
                }} style={{ display: educe ? 'none' : '' }}>
                    <DownloadOutlined />
                    <div>导出</div>
                </Button>

                <Modal
                    title='上传进度'
                    centered
                    visible={isProgress}
                    onCancel={() => this.handleModal()}
                    footer={[]}
                >
                    <Progress percent={percent} />
                </Modal>
            </div>
        )
    }
}
