import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../components/AddConfig'
import { JointPaymentDetail, paymentInformation } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import amountInWords from '../../../components/amountInWords'

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/projectPayment.docx',
                templateName: '四川天艺生态园林集团股份有限公司合作项目付款审批',
                tempData: {
                    pro_name: '',
                    username: '',
                    apply_date: '',
                    user_name: '',
                    pay_unit: '',
                    pay_money: '',
                    money_use: '',
                    bank_name: '',
                    bank_number: '',
                    info: '',
                    balance: '',
                    this_contract: '',
                    cumulative_contract: '',
                    type: '',
                    accumulated_output: '',
                    previously: '',
                    payment: '',
                    acc_payment: '',
                    invoicing: '',
                    acc_invoicing: '',
                    images: [],
                    results: []
                },
                FlowModelName: "合作项目付款申请",
                TableInfo: [{
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '项目责任人', //字段标题
                    fieldName: 'res_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '付款单位', //字段标题
                    fieldName: 'pay_unit', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '付款金额(元)', //字段标题
                    fieldName: 'pay_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '款项用途', //字段标题
                    fieldName: 'money_use', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '开户银行', //字段标题
                    fieldName: 'bank_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '银行账号', //字段标题
                    fieldName: 'bank_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: [],
                    width: '100%',
                },
                ],
                FirstInfo: [
                    {
                        plateName: '',
                        list: [
                            {
                                FieldTitle: '项目核算户资金余额', //字段标题
                                fieldName: 'balance', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '本次合同', //字段标题
                                fieldName: 'this_contract', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '累计合同', //字段标题
                                fieldName: 'cumulative_contract', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '结算类型', //字段标题
                                fieldName: 'type', //字段名称
                                fieldType: '单选',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                                radioList: [
                                    {
                                        value: 1,
                                        label: '结算'
                                    },
                                    {
                                        value: 2,
                                        label: '进度'
                                    }
                                ]
                            },
                            {
                                FieldTitle: '累计产值', //字段标题
                                fieldName: 'accumulated_output', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '前期已付', //字段标题
                                fieldName: 'previously_paid', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '本次支付', //字段标题
                                fieldName: 'this_payment', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '累计支付', //字段标题
                                fieldName: 'accumulated_payment', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '本次开票', //字段标题
                                fieldName: 'this_invoicing', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '累计开票', //字段标题
                                fieldName: 'accumulated_invoicing', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: true,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%',
                            },
                        ],
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_JointPaymentDetail(tableId)
        }
    }

    get_JointPaymentDetail = (tableId) => {
        const { Info, form } = this.state;
        let permission = JSON.parse(localStorage.getItem("permission"))
        let data = {
            table_id: tableId
        }
        JointPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2) {
                        form.table_id = tableId
                        permission.forEach((item, index) => {
                            if (item === 'paymentInformation') {
                                Info.process.redact = true

                                if (res.data.Detail.reviewAudit.reviewPermission) {
                                    Info.FirstInfo[0].list.forEach((item, index) => {
                                        item.disabled = false
                                    })
                                }
                            }
                        })
                    } else if (res.data.Detail.status === 4) {
                        Info.tempData.pro_name = res.data.Detail.pro_name
                        Info.tempData.username = res.data.Detail.applyUsername
                        Info.tempData.apply_date = res.data.Detail.created_at.slice(0, 10)
                        Info.tempData.user_name = res.data.Detail.applyUsername
                        Info.tempData.pay_unit = res.data.Detail.pay_unit
                        Info.tempData.pay_money = res.data.Detail.pay_money
                        Info.tempData.money_uppercase = amountInWords(Number(res.data.Detail.pay_money))
                        Info.tempData.money_use = res.data.Detail.money_use
                        Info.tempData.bank_name = res.data.Detail.bank_name
                        Info.tempData.bank_number = res.data.Detail.bank_number
                        Info.tempData.image = res.data.Detail.signature
                        Info.tempData.info = res.data.Detail.info || ''
                        Info.tempData.balance = res.data.Detail.balance || '/'
                        Info.tempData.this_contract = res.data.Detail.this_contract || '/'
                        Info.tempData.cumulative_contract = res.data.Detail.cumulative_contract || '/'
                        Info.tempData.type = res.data.Detail.type === 1 ? '结算' : res.data.Detail.type === 2 ? '进度' : '/'
                        Info.tempData.accumulated_output = res.data.Detail.accumulated_output || '/'
                        Info.tempData.previously = res.data.Detail.previously_paid || '/'
                        Info.tempData.payment = res.data.Detail.this_payment || '/'
                        Info.tempData.acc_payment = res.data.Detail.accumulated_payment || '/'
                        Info.tempData.invoicing = res.data.Detail.this_invoicing || '/'
                        Info.tempData.acc_invoicing = res.data.Detail.accumulated_invoicing || '/'
                        Info.tempData.images = []
                        Info.tempData.results = [
                            {
                                username: res.data.Detail.res_user,
                                opinion: '',
                                reviewStatus: '已同意'
                            }
                        ]
                        let value = {
                            src: res.data.Detail.signature
                        }
                        Info.tempData.images = [...Info.tempData.images, value]
                        res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                            let value = {
                                username: item.username,
                                opinion: item.opinion,
                                reviewStatus: item.review_status
                            }
                            Info.tempData.results = [...Info.tempData.results, value]
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure
                            if (!res.data.Detail.enclosure.length) {
                                item.isShow = true
                            }
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        username: res.data.Detail.applyUsername,
                        res_user: res.data.Detail.res_user,
                        pro_name: res.data.Detail.pro_name,
                        pay_unit: res.data.Detail.pay_unit,
                        pay_money: res.data.Detail.pay_money,
                        money_use: res.data.Detail.money_use,
                        receive_number: res.data.Detail.receive_number,
                        bank_name: res.data.Detail.bank_name,
                        bank_number: res.data.Detail.bank_number,
                        info: res.data.Detail.info,
                        balance: res.data.Detail.balance,
                        this_contract: res.data.Detail.this_contract,
                        cumulative_contract: res.data.Detail.cumulative_contract,
                        type: res.data.Detail.type,
                        accumulated_output: res.data.Detail.accumulated_output,
                        previously_paid: res.data.Detail.previously_paid,
                        this_payment: res.data.Detail.this_payment,
                        accumulated_payment: res.data.Detail.accumulated_payment,
                        this_invoicing: res.data.Detail.this_invoicing,
                        accumulated_invoicing: res.data.Detail.accumulated_invoicing,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.balance = data.balance
        form.this_contract = data.this_contract
        form.cumulative_contract = data.cumulative_contract
        form.type = data.type
        form.accumulated_output = data.accumulated_output
        form.previously_paid = data.previously_paid
        form.this_payment = data.this_payment
        form.accumulated_payment = data.accumulated_payment
        form.this_invoicing = data.this_invoicing
        form.accumulated_invoicing = data.accumulated_invoicing

        paymentInformation(form).then(
            (res) => {
                if (!res.code) {
                    message.success('提交成功')
                    this.get_JointPaymentDetail(form.table_id)
                } else {
                    message.error(res.data[0] || '提交失败,请检查表单!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} auditEvent={this.handleButton} presentEvent={this.handPresent} />
            </div>
        )
    }
}
