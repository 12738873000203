import React, { Component } from 'react'
import { message } from 'antd';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { reviewApplyResult } from "../../../utils/api"
import { BlankingSelectProject, processDocumentUpload, processDocumentDetail, processRecallOperation } from "../../../utils/project"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                FlowModelName: "工程过程资料上传",
                TableInfo: [
                    {
                        FieldTitle: '选择项目', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'pro_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'pro_manager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '资料类型', //字段标题
                        fieldName: 'class', //字段名称
                        fieldType: '单选',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        radioList: [
                            {
                                label: '巡视照片',
                                value: 10
                            },
                            {
                                label: '材质报审资料',
                                value: 11
                            },
                            {
                                label: '施工图资料',
                                value: 12
                            },
                            {
                                label: '过程验收资料',
                                value: 13
                            },
                            {
                                label: '其他',
                                value: 14
                            },
                        ]
                    },
                    {
                        FieldTitle: '文件说明', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },
                ],
                FirstInfo: [],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
            },
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    width: '280px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                }, {
                    title: '项目编号',
                    dataIndex: 'pro_number',
                    key: 'pro_number',
                },
                {
                    title: '项目经理',
                    dataIndex: 'pro_manager',
                    key: 'pro_manager',
                },
                {
                    title: '项目地址',
                    dataIndex: 'pro_address',
                    key: 'pro_address',
                    width: '280px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '260px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {},
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_processDocumentDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = project.pro_manager
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_processDocumentDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        processDocumentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission

                    } else {

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                    }

                    form.table_id = tableId
                    Info.state = res.data.Detail.status

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.projectCode
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.projectManager
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure && res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        info: res.data.Detail.info,
                        class: res.data.Detail.class,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.info = data.info
        form.class = data.class

        processDocumentUpload(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_BlankingSelectProject()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handSelect = (value) => {
        const { Info, form } = this.state;
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            } else if (item.FieldTitle === '项目经理') {
                item.value = value[0].pro_manager
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleRevocation = () => {
        let { form } = this.state
        let data = {
            table_id: form.table_id
        }
        processRecallOperation(data).then(
            (res) => {
                if (!res.code) {
                    message.success('撤回成功')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    message.error(res.data[0] || '撤回失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleRadio=()=>{}

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })

                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} revocationEvent={this.handleRevocation} radioEvent={this.handleRadio}/>

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}