import React, { Component } from 'react'
import { Form, Modal, Button, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { SearchOutlined } from '@ant-design/icons';
import UserInput from '../../components/userInput'
import UserTextArea from '../../components/userTextArea'
import UserRadio from '../../components/userRadio'
import UserSelect from '../../components/userSelect'
import UserDatePicker from '../../components/userDatePicker'
import UserRangePicker from '../../components/userRangePicker'
import UserTimePicker from '../../components/userTimePicker'
import UserAttach from '../../components/userAttach'
import FileList from '../../components/fileList'
import Icon_01 from "../../accets/img/icon_01.png"

export default class Append extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    formRef = React.createRef();

    initNode = (item) => {
        var node = null
        switch (item.fieldType) {
            case '文本':
                node = <UserInput disabled={item.disabled} fieldType='text' />
                break;
            case '文本域':
                node = <UserTextArea disabled={item.disabled} />
                break;
            case '单选':
                node = <UserRadio disabled={item.disabled} radioList={item.radioList} fileName={item.FieldTitle} postEvent={this.handRadio} />
                break;
            case '选择器':
                node = <UserSelect disabled={item.disabled} selectList={item.selectList} fileName={item.FieldTitle} postEvent={this.handSelect} multiple={item.multiple} />
                break;
            case '日期':
                node = <UserDatePicker disabled={item.disabled} />
                break;
            case '日期区间':
                node = <UserRangePicker child={item.child} disabled={item.disabled} />
                break;
            case '日期时间':
                node = <UserDatePicker disabled={item.disabled} showTime="true" />
                break;
            case '时间区间':
                node = <UserRangePicker child={item.child} showTime="true" disabled={item.disabled} />
                break;
            case '时间':
                node = <UserTimePicker disabled={item.disabled} />
                break;
            case '附件':
                if (item.disabled) {
                    node = <FileList fileValue={item.value} />
                } else {
                    node = <UserAttach disabled={item.disabled} fileEvent={this.handFile} fileName={item.fieldName} fileValue={item.value} />
                }
                break;
            default:
                node = null
                break;
        }

        return node
    }

    modalCancel = () => {
        this.props.cancelEvent()
    }

    onFinish = (values) => {
        this.props.presentEvent(values)
    };

    handFile = (storeList, fileName, value) => {
        this.props.fileEvent(storeList, fileName, value)
    }

    handSelect = (id) => {
        this.props.selectorEvent(id)
    }

    handRadio = (id) => {
        this.props.radioEvent(id)
    }

    render() {
        let { modal_Visible, Info } = this.props;
        return (
            <Modal
                title={Info.state}
                centered
                visible={modal_Visible}
                onCancel={() => this.modalCancel()}
                wrapClassName='searchModal'
                maskClosable={false}
                footer={[]}
            >
                <Form ref={this.formRef}
                    onFinish={this.onFinish}
                >
                    <div className='formTitle'>{Info.FlowModelName}</div>

                    {
                        Info.TableInfo.length ? Info.TableInfo.map((item, index) => {
                            if (!item.isShow) {
                                if (item.fieldType === '按钮') {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: '80%' }}
                                            key={index}
                                        >
                                            <div className='select_button' onClick={() => {
                                                this.props.buttonEvent(item.FieldTitle, true)
                                            }}>
                                                <div>
                                                    {item.FieldTitle}
                                                    <span>*</span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    )
                                } else if (item.fieldType === '默认') {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                            className={item.width === '100%' ? 'standard' : 'moiety'}
                                            label={item.FieldTitle}
                                            key={index}
                                        >
                                            <div className='defaultValue'>{item.value}</div>
                                        </Form.Item>
                                    )
                                } else if (item.search) {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                            className={item.width === '100%' ? 'standard' : 'moiety'}
                                            label={item.FieldTitle}
                                            name={item.fieldName}
                                            key={index}
                                            initialValue={item.value}
                                        >
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '70%' }}>
                                                    {
                                                        this.initNode(item)
                                                    }
                                                </div>
                                                <div className='searchOutlined'>
                                                    <SearchOutlined style={{ color: '#339EF9' }} />
                                                    <div>查看单据</div>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item style={{ width: item.width, marginRight: item.newline ? '5%' : '' }}
                                            className={item.width === '100%' ? 'standard' : 'moiety'}
                                            label={item.FieldTitle}
                                            name={item.fieldName}
                                            key={index}
                                            initialValue={item.value}
                                            rules={[
                                                {
                                                    required: item.mandatory,
                                                    message: item.required
                                                },
                                            ]}
                                        >
                                            {
                                                this.initNode(item)
                                            }
                                        </Form.Item>
                                    )
                                }
                            }
                        }) : ''
                    }
                    {
                        Info.FirstInfo.length ? Info.FirstInfo.map((item, index) => {
                            if (!item.isShow) {
                                return (
                                    <div key={index} style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            item.plateName ? <div className='FirstTitle'>
                                                <div>
                                                    <div>{item.plateName}</div>
                                                    <div><img src={Icon_01} alt=""></img></div>
                                                </div>
                                            </div> : ''
                                        }
                                        {
                                            item.list.length ? item.list.map((em, ex) => {
                                                if (!em.isShow) {
                                                    if (em.fieldType === '按钮') {
                                                        return (
                                                            <Form.Item style={{ width: em.width, marginRight: '80%' }}
                                                                key={ex}
                                                            >
                                                                <div className='select_button' onClick={() => {
                                                                    this.props.buttonEvent(em.FieldTitle, true)
                                                                }}>
                                                                    <div>
                                                                        {em.FieldTitle}
                                                                        <span>*</span>
                                                                    </div>
                                                                </div>
                                                            </Form.Item>
                                                        )
                                                    } else if (em.fieldType === '默认') {
                                                        return (
                                                            <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                                                className={em.width === '100%' ? 'standard' : 'moiety'}
                                                                label={em.FieldTitle}
                                                                key={ex}
                                                            >
                                                                <div className='defaultValue'>{em.value}</div>
                                                            </Form.Item>
                                                        )
                                                    } else if (em.search) {
                                                        return (
                                                            <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                                                className={em.width === '100%' ? 'standard' : 'moiety'}
                                                                label={em.FieldTitle}
                                                                name={em.fieldName}
                                                                key={index}
                                                                initialValue={em.value}
                                                            >
                                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div style={{ width: '70%' }}>
                                                                        {
                                                                            this.initNode(em)
                                                                        }
                                                                    </div>
                                                                    <div className='searchOutlined'>
                                                                        <SearchOutlined style={{ color: '#339EF9' }} />
                                                                        <div>查看单据</div>
                                                                    </div>
                                                                </div>
                                                            </Form.Item>
                                                        )
                                                    } else if (em.fieldType === '列表') {
                                                        if (em.value.length) {
                                                            return (
                                                                <Form.Item label={em.FieldTitle} key={ex} style={{ width: '100%' }}>
                                                                    <div className='nameList'>
                                                                        {
                                                                            em.value.map((m, x) => {
                                                                                return (
                                                                                    <div key={x}>{m}</div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Form.Item>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <Form.Item style={{ width: em.width, marginRight: em.newline ? '5%' : '' }}
                                                                className={em.width === '100%' ? 'standard' : 'moiety'}
                                                                label={em.FieldTitle}
                                                                name={em.fieldName}
                                                                key={ex}
                                                                initialValue={em.value}
                                                                rules={[
                                                                    {
                                                                        required: em.mandatory,
                                                                        message: em.required
                                                                    },
                                                                ]}
                                                            >
                                                                {
                                                                    this.initNode(em)
                                                                }
                                                            </Form.Item>
                                                        )
                                                    }
                                                }
                                            }) : ''
                                        }

                                        {
                                            !item.table ? '' : item.table.summary ? <ConfigProvider locale={zhCN}>
                                                <Table pagination={false} rowClassName={(record, index) => {
                                                    let className = 'light-row';
                                                    if (index % 2 === 1) className = 'dark-row';
                                                    return className;
                                                }}
                                                    style={{ width: '100%', marginBottom: '40px' }}
                                                    dataSource={item.table.dataSource}
                                                    columns={item.table.columns}
                                                    summary={() => (
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0}>总计</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}>1</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>2</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={3}>3</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={4}>4</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5}>5</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={6}>6</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7}>7</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>8</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>8</Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    )}
                                                />
                                            </ConfigProvider> : <ConfigProvider locale={zhCN}>
                                                <Table pagination={false} rowClassName={(record, index) => {
                                                    let className = 'light-row';
                                                    if (index % 2 === 1) className = 'dark-row';
                                                    return className;
                                                }}
                                                    style={{ width: '100%', marginBottom: '40px' }}
                                                    dataSource={item.table.dataSource}
                                                    columns={item.table.columns}
                                                />
                                            </ConfigProvider>
                                        }

                                        {
                                            !item.editable ? '' : item.editable.scroll ? <div style={{ width: '100%' }} className="form_table">
                                                <div style={{
                                                    width: '82px',
                                                    backgroundColor: '#D4EBFF',
                                                    border: '#339EF9 solid 1px',
                                                    borderRadius: '8px',
                                                    padding: '5px 0px',
                                                    fontSize: '16px',
                                                    color: ' #339EF9',
                                                    fontWeight: 'bold',
                                                    marginBottom: '20px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }} onClick={() => { this.props.rowAddEvent(item.plateName) }}>添加</div>
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                            scroll={{
                                                                x: 1800,
                                                            }}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div> : <div style={{ width: '100%' }} className="form_table">
                                                <div style={{
                                                    width: '82px',
                                                    backgroundColor: '#D4EBFF',
                                                    border: '#339EF9 solid 1px',
                                                    borderRadius: '8px',
                                                    padding: '5px 0px',
                                                    fontSize: '16px',
                                                    color: ' #339EF9',
                                                    fontWeight: 'bold',
                                                    marginBottom: '20px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }} onClick={() => { this.props.rowAddEvent(item.plateName) }}>添加</div>
                                                <Form.Item>
                                                    <ConfigProvider locale={zhCN}>
                                                        <Table pagination={false} rowClassName={(record, index) => {
                                                            let className = 'light-row';
                                                            if (index % 2 === 1) className = 'dark-row';
                                                            return className;
                                                        }}
                                                            style={{ width: '100%' }}
                                                            dataSource={item.editable.dataSource}
                                                            columns={item.editable.columns}
                                                        />
                                                    </ConfigProvider>
                                                </Form.Item>
                                            </div>
                                        }


                                    </div>
                                )
                            }
                        }) : ''
                    }

                    {
                        Info.state !== '详情' ? <div className='handleAppend'>
                            <Button type="primary" htmlType="submit">确认</Button>
                        </div> : ''
                    }
                </Form >
            </Modal>
        )
    }
}
