import React, { Component } from 'react'
import { TimePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';

export default class UserTimePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let timeFormat = 'HH:mm:ss'
        let { disabled, onChange, value } = this.props;
        return (
            <div>
                <ConfigProvider locale={zhCN}>
                    <TimePicker
                        allowClear={false}
                        disabled={disabled}
                        value={value}
                        onChange={(time, timeString) => {
                            onChange(moment(timeString, timeFormat))
                        }}
                        format={timeFormat}
                        hourStep={1}
                        minuteStep={10} 
                        secondStep={60} 
                    />
                </ConfigProvider>
            </div>
        )
    }
}
