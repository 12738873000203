import React, { Component } from 'react'
import { Modal, Tree, message, Button, Select, Cascader, Input } from 'antd';
import { withRouter } from "react-router-dom";
import { Flowchart } from '@ant-design/flowchart';
import { ExceptionOutlined, CaretDownOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import startImg from "../../../accets/img/start.png"
import endImg from "../../../accets/img/end.png"
import InitialValue from '../../components/InitialValue'
import { DeFunctionList, getFunctionTable, FunctionTableAttribute, getFlowImage, createProcessStartEnd, createProcessNode, editProcessNode, saveFlowImage, auditCopy, deleteProcessNode, clearFunctionTable } from "../../../utils/oa"
import { selectUserName, getCompanyDeName } from "../../../utils/api"

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      att_Modal: false,
      par_Modal: false,
      tableName: '',
      FlowchartKey: 1,
      modalKey: 1,
      InitialKey: 1,
      conType: '选项',
      optionList: [],
      numberList: [],
      teamList: [],
      positionList: [],
      treeData: [],
      Datum: {
        edges: [],
        nodes: []
      },
      auditId: '',
      nodeType: '节点',
      deType: '',
      Initia_Modal: false,
      dataSource: [],
      columns: [{
        title: '姓名',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex',
        render: (text, index) => {
          if (text === '1') {
            return (
              <span> 男 </span>
            )
          } else {
            return (<span> 女 </span>
            )
          }
        }
      },
      {
        title: '部门名称',
        dataIndex: 'deName',
        key: 'deName',
      },
      {
        title: '职位',
        dataIndex: 'company_job',
        key: 'company_job',
      },
      {
        title: '手机',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '微信通知',
        dataIndex: 'isWechat',
        key: 'isWechat',
        render: (text, record, index) => {
          return (
            <Select
              defaultValue={text}
              style={{
                width: 120,
              }}
              options={[
                {
                  value: 0,
                  label: '否',
                },
                {
                  value: 1,
                  label: '是',
                },
              ]}
              onChange={(e) => this.handleChange(e, index)}
            />
          )

        }
      }
      ],
      TableInfo: [{
        FieldTitle: '姓名', //字段标题
        fieldName: 'username', //字段名称
        fieldType: '文本', //字段类型
        disabled: false, //字段是否禁用
        mandatory: false, //字段是否必填
        required: '', //必填要求
        value: '',
        width: '48%',
      }, {
        FieldTitle: '部门', //字段标题
        fieldName: 'de_name', //字段名称
        fieldType: '文本', //字段类型
        disabled: false, //字段是否禁用
        mandatory: false, //字段是否必填
        required: '', //必填要求
        value: '',
        width: '48%',
      }],
      username: '',
      de_name: '',
      pages: {
        page: 1,
        total: 0,
        limit: 10,
      },
    }
  }

  componentDidMount() {
    this.get_FunctionTable()
  }

  get_DeFunctionList = () => {
    let { treeData } = this.state
    DeFunctionList().then(
      (res) => {
        if (!res.code) {
          res.data.des.forEach((item, index) => {
            let value = {
              title: item.name,
              key: item.id,
              children: [],
            }
            if (item.functions.length) {
              item.functions.forEach((em, ex) => {
                let children = {
                  title: em.tableComment,
                  key: item.id + '-' + em.id,
                  name: em.name,
                  id: em.id
                }
                value.children = [...value.children, children]
              })
            }
            treeData = [...treeData, value]
          })

          setTimeout(() => {
            this.setState(preState => ({
              treeData
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_FunctionTable = () => {
    let { treeData } = this.state
    getFunctionTable().then(
      (res) => {
        if (!res.code) {
          treeData = []
          res.data.tables.forEach((item, index) => {
            let value = {
              title: item.tableComment,
              key: item.tableName,
            }
            treeData = [...treeData, value]
          })

          setTimeout(() => {
            this.setState(preState => ({
              treeData
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_FunctionTableAttribute = (type) => {
    let { tableName, optionList, numberList, positionList, Datum, auditId } = this.state
    if (!tableName) {
      message.error('请选择功能表!')
      return
    }
    let data = {
      name: type === '职位' ? '职位' : tableName
    }

    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.conType = type
      }
    })

    FunctionTableAttribute(data).then(
      (res) => {
        if (!res.code) {
          if (res.data.tableColumns.length) {
            optionList = []
            numberList = []
            positionList = []
            res.data.tableColumns.forEach((item, index) => {
              if (type === '职位') {
                for (let key in item.selectData) {
                  let value = {
                    value: key,
                    label: item.selectData[key],
                  }
                  positionList = [...positionList, value]
                }
              } else {
                if (item.columnType === '选项') {
                  let value = {
                    value: item.columnName,
                    label: item.columnComment,
                    children: []
                  }
                  for (let key in item.selectData) {
                    let children = {
                      value: key,
                      label: item.selectData[key],
                    }
                    value.children = [...value.children, children]
                  }
                  optionList = [...optionList, value]
                  console.log(optionList)
                } else if (item.columnType === '数值') {
                  let value = {
                    label: item.columnComment,
                    value: item.columnName
                  }
                  numberList = [...numberList, value]
                }
              }
            })
            setTimeout(() => {
              this.setState(preState => ({
                optionList,
                numberList,
                positionList,
                par_Modal: true,
                att_Modal: false,
                conType: type,
                Datum,
              }))
            })
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleAttModal = (state, value) => {
    console.log('节点/连线参数', value)
    let { auditId, nodeType, Datum } = this.state
    if (value) {
      auditId = value.target.data.id
      if (value.target.type === 'node') {
        nodeType = '节点'
        Datum.nodes.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            item.nodeType = '节点'
          }
        })
      } else if (value.target.type === 'edge') {
        nodeType = '连线'
        Datum.edges.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            item.nodeType = '连线'
          }
        })
      }
    }
    setTimeout(() => {
      this.setState(preState => ({
        att_Modal: state,
        auditId,
        nodeType,
        Datum
      }))
    })
  }

  handleDel = (value) => {
    let { Datum } = this.state
    if (value) {
      if (value.target.type === 'node') {
        Datum.nodes.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            let cancel = true
            Datum.edges.forEach((em, ex) => {
              if (em.source.cell === item.id) {
                cancel = false
              } else if (em.target.cell === item.id) {
                cancel = false
              }
            })

            if (cancel) {
              this.deleteProcessNode(value, item.audit_id)
            } else {
              message.error('请确认已删除节点连线')
            }
          }
        })
      } else if (value.target.type === 'edge') {
        Datum.edges.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            console.log('连线', item)
            this.deleteProcessNode(value, item.audit_id)
          }
        })
      }

    }
  }

  deleteProcessNode = (value, audit_id) => {
    let { Datum, tableName } = this.state
    let data = {
      table_name: tableName,
      node_id: audit_id,
    }
    deleteProcessNode(data).then(
      (res) => {
        if (!res.code) {
          if (value.target.type === 'node') {
            Datum.nodes.forEach((item, index) => {
              if (item.id === value.target.data.id) {
                Datum.nodes.splice(index, 1)
              }
            })
          } else if (value.target.type === 'edge') {
            Datum.edges.forEach((item, index) => {
              if (item.id === value.target.data.id) {
                Datum.edges.splice(index, 1)
              }
            })
          }
          setTimeout(() => {
            this.setState(preState => ({
              Datum,
              FlowchartKey: Math.random(),
            }))
          })
        } else {
          message.error(res.data[0] || '删除失败')
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleParModal = (value) => {
    setTimeout(() => {
      this.setState(preState => ({
        par_Modal: value,
        deType: ''
      }))
    })
  }

  IndicatorNode = (props) => {
    const { data } = props;

    if (data.label === '开始') {
      return (
        <div style={{
          position: 'relative',
          display: 'block',
          width: '52px',
          textAlign: 'center'
        }}>
          <img src={startImg} alt="" style={{
            width: '52px',
            height: '52px',
            marginBottom: '5px'
          }}></img>
          <div>开始</div>
        </div>
      )
    } else if (data.label === '结束') {
      return (
        <div style={{
          position: 'relative',
          display: 'block',
          width: '52px',
          textAlign: 'center'
        }}>
          <img src={endImg} alt="" style={{
            width: '52px',
            height: '52px',
            marginBottom: '5px'
          }}></img>
          <div>结束</div>
        </div>
      )
    }
  };

  handleProcessStartEnd = (type, value) => {
    let { tableName, Datum } = this.state
    if (!tableName) {
      message.error('请选择功能表!')
      return
    }
    if (type === '开始') {
      Datum = {
        nodes: [],
        edges: []
      }
    }
    let data = {
      name: tableName,
      type: type
    }
    createProcessStartEnd(data).then(
      (res) => {
        if (!res.code) {
          value.audit_id = res.data.audit_id
          Datum.nodes = [...Datum.nodes, value]
          setTimeout(() => {
            this.setState(preState => ({
              Datum
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleProcessNode = (type, value) => {
    console.log(value)
    let { tableName, Datum } = this.state
    if (!tableName) {
      message.error('请选择功能表!')
      return
    }
    let prent_id = ''
    let sub_id = ''
    if (type === '连线') {
      Datum.nodes.forEach((item, index) => {
        if (item.id === value.source.cell) {
          prent_id = item.audit_id
        } else if (item.id === value.target.cell) {
          sub_id = item.audit_id
        }
      })
    }
    let data = {
      name: tableName,
      nodeType: type,
      prent_id: prent_id,
      sub_id: sub_id
    }
    createProcessNode(data).then(
      (res) => {
        if (!res.code) {
          value.audit_id = res.data.audit_id
          if (type === '节点') {
            Datum.nodes = [...Datum.nodes, value]
          } else if (type === '连线') {
            Datum.edges = [...Datum.edges, value]
          }

          setTimeout(() => {
            this.setState(preState => ({
              Datum,
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saveProcessNode = (value) => {
    let { Datum, tableName, auditId, nodeType } = this.state
    let data = {
      name: tableName
    }
    if (value) {
      if (value.target.type === 'node') {
        Datum.nodes.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            data.audit_id = item.audit_id
            data.nodeType = item.nodeType || ''
            data.type = item.conType || ''
            data.node_name = item.label || ''
            data.attr = item.attr || ''
            data.condition = item.condition || ''
            data.allowed_value = item.allowed_value || ''
          }
        })
      } else if (value.target.type === 'edge') {
        Datum.edges.forEach((item, index) => {
          if (item.id === value.target.data.id) {
            data.audit_id = item.audit_id
            data.nodeType = item.nodeType || ''
            data.type = item.conType || ''
            data.node_name = item.label || ''
            data.attr = item.attr || ''
            data.condition = item.condition || ''
            data.allowed_value = item.allowed_value || ''
          }
        })
      }
    } else {
      if (nodeType === '节点') {
        Datum.nodes.forEach((item, index) => {
          if (item.id === auditId) {
            data.audit_id = item.audit_id
            data.nodeType = item.nodeType
            data.type = item.conType
            data.node_name = item.label || ''
            data.attr = item.attr || ''
            data.condition = item.condition || ''
            data.allowed_value = item.allowed_value || ''
          }
        })
      } else if (nodeType === '连线') {
        Datum.edges.forEach((item, index) => {
          if (item.id === auditId) {
            data.audit_id = item.audit_id
            data.nodeType = item.nodeType
            data.type = item.conType
            data.node_name = item.label || ''
            data.attr = item.attr || ''
            data.condition = item.condition || ''
            data.allowed_value = item.allowed_value || ''
          }
        })
      }
    }

    if (!data.node_name || !data.allowed_value) {
      message.error('请确认参数配置完整')
      setTimeout(() => {
        this.setState(preState => ({
          par_Modal: value,
          deType: ''
        }))
      })
      return
    }

    editProcessNode(data).then(
      (res) => {
        if (!res.code) {
          message.success('保存成功')
        } else {
          message.error('保存失败')
        }
        setTimeout(() => {
          this.setState(preState => ({
            par_Modal: value,
            deType: ''
            // modalKey: Math.random(),
          }))
        })
      },
      (error) => {
        console.log(error);
      }
    )
  }

  handlesaveFlowImage = (value) => {
    let { tableName, Datum } = this.state

    let data = {
      table_name: tableName,
      content: {
        nodes: [],
        edges: []
      }
    }

    value.nodes.forEach((item, index) => {
      Datum.nodes.forEach((em, ex) => {
        if (item.id === em.id) {
          item.audit_id = em.audit_id
          data.content.nodes = [...data.content.nodes, item]
        }
      })
    })

    value.edges.forEach((item, index) => {
      Datum.edges.forEach((em, ex) => {
        if (item.id === em.id) {
          item.audit_id = em.audit_id
          data.content.edges = [...data.content.edges, item]
        }
      })
    })

    console.log(data.content)

    data.content = JSON.stringify(data.content)
    saveFlowImage(data).then(
      (res) => {
        if (!res.code) {
          message.success('保存成功')
        } else {
          message.error('保存失败')
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  get_InitialValue = () => {
    let { dataSource, pages, username, de_name } = this.state
    let data = {
      username: username,
      de_name: de_name,
      page: Number(pages.page),
      limit: Number(pages.limit)
    }
    selectUserName(data).then(
      (res) => {
        if (!res.code) {
          dataSource = []
          if (res.data.users.length) {
            res.data.users.forEach((item, index) => {
              let user = {
                key: item.table_id,
                username: item.username,
                sex: item.sex,
                deName: item.deName,
                company_job: item.jobName,
                phone: item.phone,
                isWechat: 0
              }
              dataSource = [...dataSource, user]
            })
          }
          setTimeout(() => {
            this.setState(preState => ({
              dataSource,
              pages: res.data.pages,
              Initia_Modal: true,
              att_Modal: false
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleChange = (value, key) => {
    let { dataSource, pages, username, de_name } = this.state
    dataSource.forEach((item, index) => {
      if (index === key) {
        item.isWechat = value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        dataSource,
      }))
    })
  }

  get_CompanyDeName = () => {
    let { teamList } = this.state
    getCompanyDeName().then(
      (res) => {
        if (!res.code) {
          res.data.departments.forEach((item, index) => {
            let value = {
              value: item.id,
              label: item.name
            }
            teamList = [...teamList, value]
          })
          console.log(teamList)
          setTimeout(() => {
            this.setState(preState => ({
              teamList,
              deType: '人员'
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handButton = (title, state) => {
    let { tableName, auditId, Datum, columns, InitialKey, conType } = this.state
    if (!tableName) {
      message.error('请选择功能表!')
      return
    }
    if (state) {
      if (title === '人员') {
        columns = [{
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key: 'sex',
          render: (text, index) => {
            if (text === '1') {
              return (
                <span> 男 </span>
              )
            } else {
              return (<span> 女 </span>
              )
            }
          }
        },
        {
          title: '部门名称',
          dataIndex: 'deName',
          key: 'deName',
        },
        {
          title: '职位',
          dataIndex: 'company_job',
          key: 'company_job',
        },
        {
          title: '手机',
          dataIndex: 'phone',
          key: 'phone',
        }
        ]
      } else {
        columns = [{
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key: 'sex',
          render: (text, index) => {
            if (text === '1') {
              return (
                <span> 男 </span>
              )
            } else {
              return (<span> 女 </span>
              )
            }
          }
        },
        {
          title: '部门名称',
          dataIndex: 'deName',
          key: 'deName',
        },
        {
          title: '职位',
          dataIndex: 'company_job',
          key: 'company_job',
        },
        {
          title: '手机',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '微信通知',
          dataIndex: 'isWechat',
          key: 'isWechat',
          render: (text, record, index) => {
            return (
              <Select
                defaultValue={text}
                style={{
                  width: 120,
                }}
                options={[
                  {
                    value: 0,
                    label: '否',
                  },
                  {
                    value: 1,
                    label: '是',
                  },
                ]}
                onChange={(e) => this.handleChange(e, index)}
              />
            )

          }
        }
        ]
      }
      Datum.nodes.forEach((item, index) => {
        if (item.id === auditId) {
          item.conType = title
        }
      })
      Datum.edges.forEach((item, index) => {
        if (item.id === auditId) {
          item.conType = title
        }
      })
      if (conType !== title) {
        InitialKey = Math.random()
      }
      this.get_InitialValue()
      setTimeout(() => {
        this.setState(preState => ({
          conType: title,
          Datum,
          columns,
          InitialKey
        }))
      })
    } else {
      setTimeout(() => {
        this.setState(preState => ({
          dataSource: [],
          pages: {
            page: 1,
            total: 1,
            limit: 10,
          },
          Initia_Modal: false,
        }))
      })
    }
  }

  handleDem = (type) => {
    let { tableName, auditId, Datum } = this.state
    if (!tableName) {
      message.error('请选择功能表!')
      return
    }
    Datum.nodes.forEach((item, index) => {
      if (item.id === auditId) {
        item.conType = type
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        att_Modal: false,
        par_Modal: true,
        conType: type,
        Datum,
      }))
    })

  }

  handleCopy = () => {
    let { tableName, Datum, auditId } = this.state
    let data = {
      table_name: tableName,
    }
    Datum.nodes.forEach((item, index) => {
      if (item.id === auditId) {
        data.cc = JSON.stringify(item.copy)
        data.audit_id = item.audit_id
      }
    })
    auditCopy(data).then(
      (res) => {
        if (!res.code) {
          message.success('保存成功')
        } else {
          message.error(res.data[0] || '保存失败')
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  handSelect = (value) => {
    let { Datum, auditId, nodeType, conType } = this.state
    let label = '人员：'
    if (nodeType === '节点') {
      Datum.nodes.forEach((item, index) => {
        if (item.id === auditId) {
          item.attr = ''
          item.copy = []
          item.allowed_value = []
          if (value.length) {
            value.forEach((em, ex) => {
              if (conType === '人员') {
                if (ex === 0) {
                  item.label = label + em.username
                  item.allowed_value = [...item.allowed_value, em.key]
                } else {
                  item.label = item.label + ' 、 ' + em.username
                  item.allowed_value = [...item.allowed_value, em.key]
                }
              } else {
                if (ex === 0) {
                  if (em.isWechat === 0) {
                    item.label = label + em.username
                  } else {
                    item.label = label + em.username + '(微)'
                  }
                  let copyValue = {
                    table_id: em.key,
                    wechat: em.isWechat
                  }
                  item.copy = [...item.copy, copyValue]
                } else {
                  if (em.isWechat === 0) {
                    item.label = item.label + ' 、 ' + em.username
                  } else {
                    item.label = item.label + ' 、 ' + em.username + '(微)'
                  }
                  let copyValue = {
                    table_id: em.key,
                    wechat: em.isWechat
                  }
                  item.copy = [...item.copy, copyValue]
                }
              }
            })
            item.allowed_value = JSON.stringify(item.allowed_value)
          }

        }
      })
    } else {
      Datum.edges.forEach((item, index) => {
        if (item.id === auditId) {
          item.allowed_value = []
          if (value.length) {
            value.forEach((em, ex) => {
              if (ex === 0) {
                item.label = label + em.username
                item.allowed_value = [...item.allowed_value, em.key]
              } else {
                item.label = item.label + ' 、 ' + em.username
                item.allowed_value = [...item.allowed_value, em.key]
              }
            })
            item.allowed_value = JSON.stringify(item.allowed_value)
          }
        }
      })
    }

    setTimeout(() => {
      this.setState(preState => ({
        Datum,
        FlowchartKey: Math.random(),
        Initia_Modal: false
      }), () => {
        if (conType === '抄送') {
          this.handleCopy()
        } else {
          this.saveProcessNode()
        }
      })
    })
  }

  handleLimit = (page, limit) => {
    let { pages } = this.state
    pages.page = page
    pages.limit = limit

    setTimeout(() => {
      this.setState({
        pages
      }, () => {
        this.get_InitialValue()
      })
    })
  }

  onSelect = (selectedKeys, info) => {
    let { Datum } = this.state
    let data = {
      table_name: info.node.key
    }
    getFlowImage(data).then(
      (res) => {
        if (!res.code) {
          Datum = JSON.parse(res.data.content)
          console.log(Datum)
          setTimeout(() => {
            this.setState(preState => ({
              tableName: info.node.key,
              Datum,
              FlowchartKey: Math.random(),
            }))
          })
        } else {
          setTimeout(() => {
            this.setState(preState => ({
              tableName: info.node.key,
              FlowchartKey: Math.random(),
              Datum: {
                nodes: [],
                edges: []
              }
            }))
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  caderChange = (value) => {
    let { Datum, auditId } = this.state
    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = value[0]
        if (value.length > 1) {
          item.allowed_value = value[1]
        }
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  selectChange = (value) => {
    let { Datum, auditId } = this.state
    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  condChange = (value) => {
    let { Datum, auditId } = this.state
    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.condition = value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  searchQuery = (value) => {
    setTimeout(() => {
      this.setState({
        username: value.username,
        de_name: value.de_name,
        pages: {
          page: 1,
          total: 0,
          limit: 10,
        },
      }, () => {
        this.get_InitialValue()
      })
    })
  }

  handleCancel = () => {
    setTimeout(() => {
      this.setState({
        Initia_Modal: false,
        username: '',
        de_name: '',
        pages: {
          limit: 10,
          page: 1,
          total: 0
        },
      })
    })
  }

  InputChange = (value) => {
    let { Datum, auditId } = this.state
    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.allowed_value = value.target.value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  allowedChange = (value) => {
    let { Datum, auditId } = this.state

    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.label = value.target.value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum,
        FlowchartKey: Math.random(),
      }))
    })
  }

  deNameChange = (value) => {
    let { Datum, auditId, teamList } = this.state
    Datum.nodes.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = ''
        item.allowed_value = value
        teamList.forEach((em, ex) => {
          if (em.value === value) {
            item.label = '部门：' + em.label
          }
        })
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum,
        FlowchartKey: Math.random(),
      }))
    })
  }

  selectDeType = () => {
    let { Datum, auditId } = this.state
    Datum.nodes.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = ''
        item.allowed_value = '经理'
        item.label = '部门：' + '部门经理'
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum,
        FlowchartKey: Math.random(),
      }))
    }, () => {
      this.saveProcessNode()
    })
  }

  get_president = () => {
    let { Datum, auditId } = this.state
    Datum.nodes.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = ''
        item.allowed_value = '主管副总'
        item.label = '主管副总'
        item.conType = '主管副总'
        item.nodeType = '节点'
      }
    })

    setTimeout(() => {
      this.setState(preState => ({
        Datum,
        conType: '主管副总',
        FlowchartKey: Math.random(),
      }), this.saveProcessNode())
    })
  }

  jobNameChange = (value) => {
    let { Datum, auditId } = this.state
    Datum.edges.forEach((item, index) => {
      if (item.id === auditId) {
        item.attr = '职位'
        item.allowed_value = value
      }
    })
    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  ConfigChange = (value) => {
    let { Datum } = this.state
    if (value.type === 'update:node') {
      Datum.nodes.forEach((item, index) => {
        if (item.id === value.config.id) {
          item.label = value.config.label
        }
      })
    } else if (value.type === 'update:edge') {
      Datum.edges.forEach((item, index) => {
        if (item.id === value.config.id) {
          item.label = value.config.label
        }
      })
    }

    setTimeout(() => {
      this.setState(preState => ({
        Datum
      }))
    })
  }

  handleCache = () => {
    clearFunctionTable().then(
      (res) => {
        if (!res.code) {
          message.success('缓存清理成功')
          this.get_FunctionTable()
        } else {
          message.error(res.data[0] || '缓存清理失败')
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  render() {
    let { FlowchartKey, modalKey, InitialKey, Datum, att_Modal, treeData, par_Modal, optionList, numberList, teamList, positionList, conType, nodeType, deType, Initia_Modal, dataSource, columns, TableInfo, pages } = this.state

    return (
      <div className='contract_List cost_list'>
        <div className='menuList'>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '15px'
          }}>
            <div style={{
              fontSize: '18px',
              fontWeight: 'bold',
            }}>功能列表：</div>
            <div style={{
              padding: '3px 15px',
              backgroundColor: '#00D0CC',
              color: '#FFFFFF',
              borderRadius: '24px',
              cursor: 'pointer'
            }} onClick={() => {
              this.handleCache()
            }}>清除缓存</div>
          </div>
          <Tree
          
            showLine={{ showLeafIcon: false }}
            showIcon={true}
            onSelect={this.onSelect}
            treeData={treeData}
          />
        </div>

        <div style={{ backgroundColor: '#FFFFFF' }}>
          <Flowchart
            key={FlowchartKey}
            data={Datum}

            onSave={(e) => {
              this.handlesaveFlowImage(e)
            }}

            onAddNode={(e) => {
              if (e.name === 'start') {
                this.handleProcessStartEnd('开始', e)
              } else if (e.name === 'end') {
                this.handleProcessStartEnd('结束', e)
              } else {
                this.handleProcessNode('节点', e)
              }
            }}

            onAddEdge={(e) => {
              this.handleProcessNode('连线', e)
            }}

            toolbarPanelProps={{
              position: {
                top: 0,
                left: 0,
                right: 0,
              },
            }}
            scaleToolbarPanelProps={{
              layout: 'horizontal',
              position: {
                right: 0,
                top: -40,
              },
              style: {
                width: 150,
                height: 39,
                left: 'auto',
                background: 'transparent',
              },
            }}
            canvasProps={{
              position: {
                top: 40,
                left: 0,
                right: 0,
                bottom: 0,
              },
            }}
            nodePanelProps={{
              position: { width: 160, top: 40, bottom: 0, left: 0 },
              defaultActiveKey: ['custom'],
              showHeader: false,
              registerNode: {
                title: '常用节点',
                nodes: [
                  {
                    component: this.IndicatorNode,
                    popover: () => <div>开始节点</div>,
                    name: 'start',
                    width: 52,
                    height: 80,
                    label: '开始'
                  },
                  {
                    component: this.IndicatorNode,
                    popover: () => <div>结束节点</div>,
                    name: 'end',
                    width: 52,
                    height: 80,
                    label: '结束'
                  },
                ],
              },

            }}
            detailPanelProps={{
              position: { width: 200, top: 40, bottom: 0, right: 0 },
            }}
            contextMenuPanelProps={{
              showOfficial: false,
              submenu: () => {
                return [{
                  id: 'attribute',
                  label: '属性',
                  render: (text, record, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', color: '#595959', padding: '5px 12px', cursor: 'pointer' }} onClick={() => {
                        this.handleAttModal(true, text)
                      }}>
                        <ExceptionOutlined style={{ fontSize: '13px', marginRight: '8px' }} />
                        <div style={{ fontSize: '14px' }}>属性</div>
                      </div>
                    )
                  }
                }, {
                  id: 'delete',
                  label: '删除',
                  render: (text, record, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', color: '#595959', padding: '5px 12px', cursor: 'pointer' }} onClick={() => {
                        this.handleDel(text)
                      }}>
                        <DeleteOutlined style={{ fontSize: '13px', marginRight: '8px' }} />
                        <div style={{ fontSize: '14px' }}>删除</div>
                      </div>
                    )
                  }
                }, {
                  id: 'save',
                  label: '保存',
                  render: (text, record, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', color: '#595959', padding: '5px 12px', cursor: 'pointer' }} onClick={() => { this.saveProcessNode(text) }}>
                        <CopyOutlined style={{ fontSize: '13px', marginRight: '8px' }} />
                        <div style={{ fontSize: '14px' }}>保存</div>
                      </div>
                    )
                  }
                }]
              }
            }}
          />
        </div>

        <Modal
          title="基础资料"
          centered
          visible={att_Modal}
          onCancel={() => this.handleAttModal(false, '')}
          wrapClassName='weekly'
          footer={[]}
        >
          <div className='att_value'>
            <div>选择属性</div>
            <div>
              <div onClick={() => {
                this.handButton('人员', true)
              }}>人员</div>
              <div style={{ display: nodeType === '连线' ? "block" : 'none' }} onClick={() => {
                this.get_FunctionTableAttribute('选项')
              }} >选项</div>
              <div style={{ display: nodeType === '连线' ? "block" : 'none' }} onClick={() => {
                this.get_FunctionTableAttribute('数值')
              }} >数值</div>
              <div style={{ display: nodeType === '节点' ? "block" : 'none' }} onClick={() => {
                this.handleDem('部门')
              }}>部门</div>
              <div style={{ display: nodeType === '连线' ? "block" : 'none' }} onClick={() => {
                this.get_FunctionTableAttribute('职位')
              }}>职位</div>
              <div style={{ display: nodeType === '节点' ? "block" : 'none' }} onClick={() => {
                this.get_president()
              }}>主管副总</div>
              <div style={{ display: nodeType === '节点' ? "block" : 'none' }} onClick={() => {
                this.handButton('抄送', true)
              }}>抄送</div>
            </div>
          </div>
        </Modal>

        <Modal
          title='基础资料'
          key={modalKey}
          centered
          visible={par_Modal}
          onCancel={() => this.handleParModal(false)}
          wrapClassName='weekly'
          footer={[
            <Button type="primary" onClick={() => this.saveProcessNode()}>确认</Button>
          ]}
        >
          <div className='parameter'>
            <div>{conType}</div>

            <div className='option' style={{ display: nodeType === '连线' ? 'flex' : 'none' }}>
              <div>节点名称</div>
              <Input onChange={(e) => this.allowedChange(e)} />
            </div>

            <div className='option' style={{ display: conType === '选项' ? 'flex' : 'none' }}>
              <div>选项内容</div>
              <Cascader options={optionList}
                onChange={(e) => this.caderChange(e)}
                suffixIcon={<CaretDownOutlined />}
                changeOnSelect />
            </div>

            <div className='numberValue' style={{ display: conType === '数值' ? 'block' : 'none' }}>
              <div>
                <div>字段</div>
                <Select
                  options={numberList}
                  onChange={(e) => this.selectChange(e)}
                  suffixIcon={<CaretDownOutlined />}
                >
                </Select>
              </div>
              <div>
                <div>条件</div>
                <Select
                  options={[
                    { value: '>=', label: '大于等于' },
                    { value: '<=', label: '小于等于' },
                    { value: '=', label: '等于' },
                    { value: '>', label: '大于' },
                    { value: '<', label: '小于' },
                  ]}
                  onChange={(e) => this.condChange(e)}
                  suffixIcon={<CaretDownOutlined />}
                >
                </Select>
              </div>
              <div>
                <div>数值</div>
                <Input onChange={(e) => this.InputChange(e)} />
              </div>
            </div>

            <div className='option' style={{ display: conType === '部门' && deType === '人员' ? 'flex' : 'none' }}>
              <div>部门名称</div>
              <Select
                options={teamList}
                onChange={(e) => this.deNameChange(e)}
                suffixIcon={<CaretDownOutlined />}
              >
              </Select>
            </div>

            <div className='option' style={{ display: conType === '职位' ? 'flex' : 'none' }}>
              <div>职位级别</div>
              <Select
                options={positionList}
                onChange={(e) => this.jobNameChange(e)}
                suffixIcon={<CaretDownOutlined />}
              >
              </Select>
            </div>

            <div className='deType' style={{ display: conType === '部门' && deType !== '人员' ? 'flex' : 'none' }}>
              <div onClick={() => this.get_CompanyDeName()}>部门人员</div>
              <div onClick={() => this.selectDeType()}>部门经理</div>
            </div>

          </div>
        </Modal>

        <InitialValue key={InitialKey} modal_Visible={Initia_Modal} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
      </div>
    )
  }
}

export default withRouter(List)
