import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { RecognitionPriceDetail, researchProjectList, createRecognitionPrice } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/identityAndPricing.docx',
                templateName: '四川天艺生态园林集团股份有限公司358工程认质核价审批表',
                tempData: {
                    username: '',
                    type: '',
                    apply_date: '',
                    pro_name: '',
                    party_a: '',
                    party_b: '',
                    inv_type: '',
                    tax: '',
                    pay_model: '',
                    is_fare: '',
                    all_price: '',
                    info: '',
                    details: [],
                    results: []
                },
                FlowModelName: "认质核价审批",
                flowTips: '(358工程)',
                TableInfo: [{
                    FieldTitle: '起草人', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择申请日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '认价类别', //字段标题
                    fieldName: 'class', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择认质核价类别', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        {
                            value: 1,
                            label: '项目类'
                        },
                        {
                            value: 2,
                            label: '科研类'
                        }
                    ]
                }, {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '合同甲方', //字段标题
                    fieldName: 'party_a', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同甲方', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '合同乙方', //字段标题
                    fieldName: 'party_b', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入合同乙方', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '开票类型', //字段标题
                    fieldName: 'inv_type', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '税率(%)', //字段标题
                    fieldName: 'tax', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入税率', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '付款方式', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '是否含运费', //字段标题
                    fieldName: 'is_fare', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: [{
                    plateName: '清单明细',
                    list: [],
                    isShow: true,
                    imptable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '序号',
                                dataIndex: 'index',
                                key: 'index',
                            },
                            {
                                title: '材料设备名称',
                                dataIndex: 'name',
                                key: 'name',
                            }, {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                            },
                            {
                                title: '高度、冠幅',
                                dataIndex: 'height',
                                key: 'height',
                            },
                            {
                                title: '密度(株/㎡)',
                                dataIndex: 'density',
                                key: 'density',
                            },
                            {
                                title: '技术要求/质量等级',
                                dataIndex: 'ask',
                                key: 'ask',
                            },
                            {
                                title: '单位',
                                dataIndex: 'unit',
                                key: 'unit',
                            },
                            {
                                title: '暂定数量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '报审单价(元)',
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: '暂定总价(元)',
                                dataIndex: 'all_price',
                                key: 'all_price',
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/ImportRecognitionPrice',
                            name: 'Recognition',
                            data: {
                                class: 1
                            }
                        }
                    }
                },
                {
                    plateName: '清单明细',
                    list: [],
                    isShow: true,
                    imptable: {
                        dataSource: [],
                        columns: [
                            {
                                title: '序号',
                                dataIndex: 'index',
                                key: 'index',
                            },
                            {
                                title: '植物',
                                dataIndex: 'name',
                                key: 'name',
                            }, {
                                title: '数量',
                                dataIndex: 'number',
                                key: 'number',
                            },
                            {
                                title: '入库规格',
                                dataIndex: 'import_model',
                                key: 'import_model',
                            },
                            {
                                title: '出库规格',
                                dataIndex: 'out_model',
                                key: 'out_model',
                            },
                            {
                                title: '成品苗生产单价',
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: '合计',
                                dataIndex: 'all_price',
                                key: 'all_price',
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                            }
                        ],
                        uploadUrl: {
                            url: '/api/ImportRecognitionPrice',
                            name: 'Recognition',
                            data: {
                                class: 2
                            }
                        }
                    }
                }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                },
                {
                    title: '中标金额',
                    dataIndex: 'select_money',
                    key: 'select_money',
                },
                {
                    title: '开工时间',
                    dataIndex: 'plant_open_day',
                    key: 'plant_open_day',
                },
                {
                    title: '竣工时间',
                    dataIndex: 'over_day',
                    key: 'over_day',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_RecognitionPriceDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.table_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    if (project) {
                        item.value = project.pro_name
                    }
                }
            })
        }
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '起草人') {
                item.value = userInfo.name
            }
        })
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_RecognitionPriceDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        RecognitionPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.project_id
                        Info.process.edit = res.data.editPermission

                        if (res.data.class === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.FieldTitle === '选择项目') {
                                    item.isShow = false
                                }
                            })
                        }

                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.status === 4) {
                            Info.tempData.type = res.data.class === 1 ? '项目类' : '科研类'
                            Info.tempData.apply_date = res.data.apply_day
                            Info.tempData.username = res.data.applyUsername
                            Info.tempData.pro_name = res.data.pro_name || '/'
                            Info.tempData.party_a = res.data.party_a
                            Info.tempData.party_b = res.data.party_b
                            Info.tempData.inv_type = res.data.inv_type || '/'
                            Info.tempData.tax = res.data.tax || '/'
                            Info.tempData.pay_model = res.data.pay_model || '/'
                            Info.tempData.is_fare = res.data.is_fare || '/'
                            Info.tempData.all_price = res.data.all_price || '/'
                            Info.tempData.info = res.data.info || '/'
                            Info.tempData.results = []

                            res.data.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission

                    if (res.data.class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '项目名称') {
                                item.isShow = false
                            }
                        })
                        Info.FirstInfo[0].isShow = false
                        Info.FirstInfo[0].imptable.dataSource = res.data.details
                        Info.FirstInfo[1].isShow = true
                        Info.FirstInfo[1].imptable.dataSource = []
                    } else if (res.data.class === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            } else if (item.FieldTitle === '项目名称') {
                                item.isShow = true
                            }
                        })
                        Info.FirstInfo[0].isShow = true
                        Info.FirstInfo[0].imptable.dataSource = []
                        Info.FirstInfo[1].isShow = false
                        Info.FirstInfo[1].imptable.dataSource = res.data.details
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '起草人') {
                            item.value = res.data.applyUsername
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.apply_day ? moment(res.data.apply_day, 'YYYY-MM-DD') : '',
                        class: res.data.class,
                        party_a: res.data.party_a,
                        party_b: res.data.party_b,
                        inv_type: res.data.inv_type,
                        tax: res.data.tax,
                        pay_model: res.data.pay_model,
                        is_fare: res.data.is_fare,
                        info: res.data.info,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchProjectList = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            status: 4,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        researchProjectList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.researches.length) {
                        res.data.researches.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                cre_day: item.cre_day,
                                over_day: item.over_day,
                                plant_open_day: item.plant_open_day,
                                select_money: item.select_money,
                                duration: item.duration,
                                is_over: item.is_over,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.class = data.class
        form.party_a = data.party_a
        form.party_b = data.party_b
        form.inv_type = data.inv_type
        form.tax = data.tax
        form.pay_model = data.pay_model
        form.is_fare = data.is_fare
        form.info = data.info

        if (form.class === 1) {
            form.details = Info.FirstInfo[0].imptable.dataSource
        } else {
            form.details = Info.FirstInfo[1].imptable.dataSource
        }
        form.details = JSON.stringify(form.details)

        createRecognitionPrice(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_researchProjectList()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handleRadio = (value, name) => {
        let { form, Info } = this.state;
        form.class = value
        if (name === '认价类别') {
            if (value === 1) {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择项目') {
                        item.isShow = false
                    } else if (item.FieldTitle === '项目名称') {
                        item.isShow = false
                    }
                })
                Info.FirstInfo[0].isShow = false
                Info.FirstInfo[1].isShow = true
            } else {
                Info.TableInfo.forEach((item, index) => {
                    if (item.FieldTitle === '选择项目') {
                        item.isShow = true
                    } else if (item.FieldTitle === '项目名称') {
                        item.isShow = true
                    }
                })

                Info.FirstInfo[0].isShow = true
                Info.FirstInfo[1].isShow = false
            }
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        const { Info, form } = this.state;
        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }


    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_researchProjectList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_researchProjectList()
        })
    }

    // 文件上传改变事件
    updateChange = (info) => {
        let { Info, form } = this.state
        if (info.file.status === 'done') {
            if (!info.file.response.code) {
                message.success('导入成功')
                Info.FirstInfo[0].imptable.dataSource = []
                Info.FirstInfo[1].imptable.dataSource = []
                if (form.class === 1) {
                    info.file.response.data.data.forEach((item, index) => {
                        item.key = index + 1
                        Info.FirstInfo[0].imptable.dataSource = [...Info.FirstInfo[0].imptable.dataSource, item]
                    })
                } else {
                    info.file.response.data.data.forEach((item, index) => {
                        item.key = index + 1
                        Info.FirstInfo[1].imptable.dataSource = [...Info.FirstInfo[1].imptable.dataSource, item]
                    })
                }
                this.setState(preState => ({
                    Info
                })
                )
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    handleClear = () => {
        let { Info } = this.state
        Info.FirstInfo[0].imptable.dataSource = []
        Info.FirstInfo[1].imptable.dataSource = []
        this.setState(preState => ({
            Info,
        })
        )
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} selectorEvent={this.handSelector} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} uploadEvent={this.updateChange} ClearEvent={this.handleClear} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}