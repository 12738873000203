import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createDispatchApply, dispatchDetail } from "../../../../utils/oa"
import { selectUserName, selectProjectName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "派遣申请",
            TableInfo: [],
            FirstInfo: [{
                plateName: '基本信息',
                list: [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '部门名称', //字段标题
                    fieldName: 'deName', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: true,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '选择项目名称', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: false,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '选择项目经理', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: false,
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '项目地点', //字段标题
                    fieldName: 'pro_address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请填写项目地点', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '派遣时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '', //必填要求
                    value: '2023-01-01',
                    width: '50%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                }
                ],
            }
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        username: '',
        de_name: '',
        pro_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_dispatchDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.FirstInfo[0].list.forEach((item, index) => {
            if (item.FieldTitle === '姓名') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门名称') {
                item.value = userInfo.deName
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_dispatchDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        dispatchDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        form.pro_manager = res.data.Detail.pro_manager
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目名称') {
                                item.isShow = true
                            } else if (item.FieldTitle === '选择项目经理') {
                                item.isShow = true
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '姓名') {
                            item.value = res.data.Detail.applyUserName
                        } else if (item.FieldTitle === '部门名称') {
                            item.value = res.data.Detail.deName
                        }
                        else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.projectName
                        }
                        else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        }
                        else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManager
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        start: res.data.Detail.start ? moment(res.data.Detail.start, 'YYYY-MM-DD HH:mm:ss') : '',
                        end: res.data.Detail.end ? moment(res.data.Detail.end, 'YYYY-MM-DD HH:mm:ss') : '',
                        pro_address: res.data.Detail.pro_address,
                    })

                    if (res.data.Detail.reason === 2) {
                        Info.FirstInfo[0].list.forEach((item, index) => {
                            if (item.FieldTitle === '位置信息' || item.FieldTitle === '附件') {
                                item.isShow = false
                            }
                        })
                    }

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectName = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_number: item.pro_number,
                                managerName: item.managerName,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                        this.setState(preState => ({
                            dataSource,
                            pages: res.data.pages,
                            modal_Visible: true,
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.pro_address = data.pro_address
        form.start = data.start ? data.start.format('YYYY-MM-DD') : ''
        form.end = data.end ? data.end.format('YYYY-MM-DD') : ''

        createDispatchApply(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择项目经理') {
                columns = [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ]
                TableInfo = [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }]
                this.get_InitialValue()
            } else {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                    {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'managerName',
                        key: 'managerName',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_ProjectName()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目经理') {
            form.pro_manager = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '项目经理') {
                    item.value = value[0].username
                }
            })
        } else {
            form.project_id = value[0].key
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目经理') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        } else {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_ProjectName()
            })
        }

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目经理') {
                this.get_InitialValue()
            } else {
                this.get_ProjectName()
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
