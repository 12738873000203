import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { Select } from 'antd';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../../components/Operate'
import Table from '../../../components/Table'
import { plantList } from "../../../../utils/scientific_research"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '植物名称',
                    dataIndex: 'plant_name',
                    key: 'plant_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                 
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '植物编号',
                    dataIndex: 'plant_code',
                    key: 'plant_code',
                },
                {
                    title: '系列编号',
                    dataIndex: 'list_code',
                    key: 'list_code',
                },
                {
                    title: '引种数量',
                    dataIndex: 'plant_number',
                    key: 'plant_number',
                },
                {
                    title: '引种地',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: '所属种类',
                    dataIndex: 'plant_type',
                    key: 'plant_type',
                },
                {
                    title: '开始日期',
                    dataIndex: 'start_time',
                    key: 'start_time',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            yearList: [
                { value: 2022, label: "2022年" },
                { value: 2023, label: "2023年" },
                { value: 2024, label: "2024年" },
                { value: 2025, label: "2025年" },
                { value: 2026, label: "2026年" },
                { value: 2027, label: "2027年" },
                { value: 2028, label: "2028年" },
                { value: 2029, label: "2029年" },
                { value: 2030, label: "2030年" },
            ],
            yearValue: '',
            monthList: [
                { value: 1, label: "1月" },
                { value: 2, label: "2月" },
                { value: 3, label: "3月" },
                { value: 4, label: "4月" },
                { value: 5, label: "5月" },
                { value: 6, label: "6月" },
                { value: 7, label: "7月" },
                { value: 8, label: "8月" },
                { value: 9, label: "9月" },
                { value: 10, label: "10月" },
                { value: 11, label: "11月" },
                { value: 12, label: "12月" },
            ],
            monthValue: '',
        }
    }

    componentDidMount() {
        this.get_plantList()
    }

    get_plantList = () => {
        let { dataSource, pages, yearValue, monthValue } = this.state
        let data = {
            queryCriteria: 'personal',
            year: yearValue,
            month: monthValue,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        plantList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.plants.length) {
                        res.data.plants.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.id,
                                plant_name: item.plant_name,
                                plant_code: item.plant_code,
                                list_code: item.list_code,
                                plant_number: item.plant_number,
                                address: item.address,
                                plant_type: item.plant_type,
                                start_time: item.start_time,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/achievement_transformation/plantIntroduction/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/plantIntroduction/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            con_name: '',
            con_code: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_plantList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            con_name: value.con_name,
            con_code: value.con_code
        }, () => {
            this.get_plantList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_plantList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_plantList()
        })
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => { this.get_plantList() })
    }

    monthChange = (value) => {
        this.setState({
            monthValue: value
        }, () => { this.get_plantList() })
    }

    render() {
        let { dataSource, columns, pages, yearValue, yearList, monthList, monthValue } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate notPrint={true} educe={true} dontImport={true} seek={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />
                    </div>

                    <div className='screen_right'>
                        <div>
                            <div>选择年份 :</div>
                            <Select
                                options={yearList}
                                value={yearValue}
                                onChange={(e) => {
                                    this.yearChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>

                        <div>
                            <div>选择月份 :</div>
                            <Select
                                options={monthList}
                                value={monthValue}
                                onChange={(e) => {
                                    this.monthChange(e)
                                }}
                                suffixIcon={<CaretDownOutlined />}
                            >
                            </Select>
                        </div>
                    </div>
                </div>
                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
