import React, { Component } from 'react'
import { Input, Form, message, DatePicker, ConfigProvider, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createReceiptManagement, receiptManagementDetail, BlankingSelectProject } from "../../../utils/project"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "机械台班记录表",
            TableInfo: [
                {
                    FieldTitle: '选择项目', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '提交日期', //字段标题
                    fieldName: 'delivery_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择提交日期', //必填要求
                    value: '',
                    width: '50%',
                },
                // {
                //     FieldTitle: '选择操作人', //字段标题
                //     fieldType: '按钮',  //字段类型
                //     mandatory: true, //字段是否必填
                // },
                {
                    FieldTitle: '操作人', //字段标题
                    fieldName: 'operation_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                // {
                //     FieldTitle: '选择记录人', //字段标题
                //     fieldType: '按钮',  //字段类型
                //     mandatory: true, //字段是否必填
                // },
                {
                    FieldTitle: '记录人', //字段标题
                    fieldName: 'log_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
            ],
            FirstInfo: [{
                plateName: '台班信息',
                list: [],
                editable: {
                    scroll: true,
                    scrollWidth: 2400,
                    dataSource: [],
                    columns: [
                        // {
                        //     title: '',
                        //     dataIndex: 'key',
                        //     key: 'key',
                        //     width: '80px'
                        // },
                        {
                            title: '序号',
                            dataIndex: 'id',
                            key: 'id',
                            width: '80px',
                            render: (text, record, index) => {
                                return (
                                    <div>{index + 1}</div>
                                )
                            }
                        },
                        {
                            title: '操作',
                            dataIndex: 'controls',
                            key: 'controls',
                            width: '100px',
                            render: (text, record, index) => {
                                return (
                                    <div className='download' onClick={() => {
                                        this.handleDel(index, record.key)
                                    }}>
                                        删除
                                    </div>
                                )
                            }
                        },
                        {
                            title: '机械性质',
                            dataIndex: 'mec_pro',
                            key: 'mec_pro',
                            width: '168px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'mec_pro' + record.key} key={index} initialValue={text}>
                                            <Select
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: '租赁'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: '自有'
                                                    }
                                                ]}
                                                onChange={(e) => this.SelectChange(e, record.key, 'mec_pro')}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'mec_pro' + record.key} key={index} initialValue={text}>
                                            <Select
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: '租赁'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: '自有'
                                                    }
                                                ]}
                                                disabled
                                                onChange={(e) => this.SelectChange(e, record.key, 'mec_pro')}
                                                suffixIcon={<CaretDownOutlined />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            }

                        },
                        {
                            title: '机械名称',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'name' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'name')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'name' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'name')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '规格型号',
                            dataIndex: 'model',
                            key: 'model',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'model' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'model')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'model' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'model')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '单位',
                            dataIndex: 'unit',
                            key: 'unit',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'unit' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'unit')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'unit' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'unit')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '工作内容',
                            dataIndex: 'result',
                            key: 'result',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'result')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'result')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '上午时间',
                            dataIndex: 'give_number',
                            key: 'give_number',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'morning_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'morning_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'give_number', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'morning_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'morning_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'give_number', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'morning_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'morning_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'give_number', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'morning_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'morning_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'give_number', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                }
                            }
                        },
                        {
                            title: '下午时间',
                            dataIndex: 'pua_number',
                            key: 'pua_number',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'afternoon_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'afternoon_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'afternoon_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'afternoon_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'afternoon_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'afternoon_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'afternoon_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'afternoon_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                }
                            }
                        },
                        {
                            title: '晚上时间',
                            dataIndex: 'keep_alive',
                            key: 'keep_alive',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'evening_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'evening_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'evening_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'evening_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>
                                            <ConfigProvider locale={zhCN}>
                                                <div key={'evening_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                    <Form.Item name={'evening_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 0)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div key={'evening_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                    <Form.Item name={'evening_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                        <DatePicker
                                                            allowClear={false}
                                                            showTime={true}
                                                            format='YYYY-MM-DD HH:mm:ss'
                                                            disabled
                                                            onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 1)}
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </ConfigProvider>
                                        </div>
                                    )
                                }
                            }

                        },
                        {
                            title: '合计工作时间',
                            dataIndex: 'total_time',
                            key: 'total_time',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'total_time' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'total_time')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'total_time' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'total_time')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '油耗',
                            dataIndex: 'oil_con',
                            key: 'oil_con',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'oil_con' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'oil_con')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'oil_con' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'oil_con')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '备注',
                            dataIndex: 'info',
                            key: 'info',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name={'info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'info')}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name={'info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'info')}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                    ]
                }
            }]
        },
        modal_Visible: false,
        dataSource: [],
        columns: [],
        TableInfo: [],
        buttonTitle: '',
        username: '',
        de_name: '',
        pro_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        formKey: 1
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_receiptManagementDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
        let project = ''
        Info.state = 1
        if (localStorage.getItem('itemForm')) {
            project = JSON.parse(localStorage.getItem('itemForm'))
            form.project_id = project.project_id
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = project.pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = project.pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = project.pro_manager
                }
            })
        }
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_receiptManagementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        receiptManagementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }
                            // else if (item.FieldTitle === '选择操作人') {
                            //     item.isShow = true
                            // } else if (item.FieldTitle === '选择记录人') {
                            //     item.isShow = true
                            // }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.FirstInfo[0].editable.columns = [
                            {
                                title: '序号',
                                dataIndex: 'id',
                                key: 'id',
                                width: '80px',
                                render: (text, record, index) => {
                                    return (
                                        <div>{index + 1}</div>
                                    )
                                }
                            },
                            {
                                title: '机械性质',
                                dataIndex: 'mec_pro',
                                key: 'mec_pro',
                                width: '168px',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'mec_pro' + record.key} key={index} initialValue={text}>
                                                <Select
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: '租赁'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: '自有'
                                                        }
                                                    ]}
                                                    onChange={(e) => this.SelectChange(e, record.key, 'mec_pro')}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'mec_pro' + record.key} key={index} initialValue={text}>
                                                <Select
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: '租赁'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: '自有'
                                                        }
                                                    ]}
                                                    disabled
                                                    onChange={(e) => this.SelectChange(e, record.key, 'mec_pro')}
                                                    suffixIcon={<CaretDownOutlined />}
                                                >
                                                </Select>
                                            </Form.Item>
                                        )
                                    }
                                }

                            },
                            {
                                title: '机械名称',
                                dataIndex: 'name',
                                key: 'name',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'name' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'name')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'name' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'name')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '规格型号',
                                dataIndex: 'model',
                                key: 'model',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'model')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'model' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'model')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'unit')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'unit' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'unit')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '工作内容',
                                dataIndex: 'result',
                                key: 'result',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'result')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'result' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'result')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '上午时间',
                                dataIndex: 'give_number',
                                key: 'give_number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'morning_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'morning_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'give_number', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'morning_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'morning_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'give_number', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'morning_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'morning_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'give_number', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'morning_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'morning_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'give_number', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: '下午时间',
                                dataIndex: 'pua_number',
                                key: 'pua_number',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'afternoon_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'afternoon_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'afternoon_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'afternoon_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'afternoon_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'afternoon_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'afternoon_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'afternoon_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'pua_number', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: '晚上时间',
                                dataIndex: 'keep_alive',
                                key: 'keep_alive',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'evening_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'evening_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'evening_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'evening_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <ConfigProvider locale={zhCN}>
                                                    <div key={'evening_start_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#00DC9E', marginRight: '5px' }}></div>
                                                        <Form.Item name={'evening_start_time' + record.key} initialValue={text[0]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 0)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div key={'evening_end_time' + index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#F53500', marginRight: '5px' }}></div>
                                                        <Form.Item name={'evening_end_time' + record.key} initialValue={text[1]} style={{ width: '90%' }}>
                                                            <DatePicker
                                                                allowClear={false}
                                                                showTime={true}
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                disabled
                                                                onChange={(e) => this.PickerChange(e, record.key, 'keep_alive', 1)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                </ConfigProvider>
                                            </div>
                                        )
                                    }
                                }

                            },
                            {
                                title: '合计工作时间',
                                dataIndex: 'total_time',
                                key: 'total_time',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'total_time' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'total_time')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'total_time' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'total_time')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '油耗',
                                dataIndex: 'oil_con',
                                key: 'oil_con',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'oil_con' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'oil_con')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'oil_con' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'oil_con')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'info',
                                key: 'info',
                                render: (text, record, index) => {
                                    if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'info')}>
                                                <Input />
                                            </Form.Item>
                                        )
                                    } else {
                                        return (
                                            <Form.Item name={'info' + record.key} key={index} initialValue={text} onChange={(e) => this.InputChange(e, record.key, 'info')}>
                                                <Input disabled />
                                            </Form.Item>
                                        )
                                    }
                                }
                            },
                        ]

                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.content.length) {
                        res.data.Detail.content.forEach((em, ex) => {
                            let key = ex + 1
                            em.key = key
                            em.give_number = JSON.parse(em.give_number)
                            em.give_number[0] = em.give_number[0] ? moment(em.give_number[0], 'YYYY-MM-DD HH:mm:ss') : ""
                            em.give_number[1] = em.give_number[1] ? moment(em.give_number[1], 'YYYY-MM-DD HH:mm:ss') : ''
                            em.pua_number = JSON.parse(em.pua_number)
                            em.pua_number[0] = em.pua_number[0] ? moment(em.pua_number[0], 'YYYY-MM-DD HH:mm:ss') : ''
                            em.pua_number[1] = em.pua_number[1] ? moment(em.pua_number[1], 'YYYY-MM-DD HH:mm:ss') : ''
                            em.keep_alive = JSON.parse(em.keep_alive)
                            em.keep_alive[0] = em.keep_alive[0] ? moment(em.keep_alive[0], 'YYYY-MM-DD HH:mm:ss') : ''
                            em.keep_alive[1] = em.keep_alive[1] ? moment(em.keep_alive[1], 'YYYY-MM-DD HH:mm:ss') : ''

                            this.NewForm.current.formRef.current.setFieldsValue({
                                [(() => 'mec_pro' + key)()]: em.mec_pro,
                                [(() => 'name' + key)()]: em.name,
                                [(() => 'model' + key)()]: em.model,
                                [(() => 'unit' + key)()]: em.unit,
                                [(() => 'result' + key)()]: em.result,
                                [(() => 'morning_start_time' + key)()]: em.give_number[0],
                                [(() => 'morning_end_time' + key)()]: em.give_number[1],
                                [(() => 'afternoon_start_time' + key)()]: em.pua_number[0],
                                [(() => 'afternoon_end_time' + key)()]: em.pua_number[1],
                                [(() => 'evening_start_time' + key)()]: em.keep_alive[0],
                                [(() => 'evening_end_time' + key)()]: em.keep_alive[1],
                                [(() => 'total_time' + key)()]: em.total_time,
                                [(() => 'oil_con' + key)()]: em.oil_con,
                                [(() => 'info' + key)()]: em.info,
                            })
                        })
                    }

                    Info.FirstInfo[0].editable.dataSource = res.data.Detail.content



                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.pro_manager
                        } else if (item.FieldTitle === '提交日期') {
                            item.value = res.data.Detail.delivery_date ? moment(res.data.Detail.delivery_date, 'YYYY-MM-DD') : ''
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        } else if (item.FieldTitle === '操作人') {
                            item.value = res.data.Detail.operationUser
                            form.operation_user = res.data.Detail.operation_user
                        } else if (item.FieldTitle === '记录人') {
                            item.value = res.data.Detail.logUser
                            form.log_user = res.data.Detail.log_user
                        }
                    })



                    // this.NewForm.current.formRef.current.setFieldsValue({
                    //     delivery_date: moment(res.data.Detail.delivery_date, 'YYYY-MM-DD'),
                    //     operation_user: res.data.Detail.operation_user,
                    //     log_user: res.data.Detail.log_user,
                    //     enclosure: res.data.Detail.enclosure
                    // })

                    this.setState(preState => ({
                        Info,
                        form,
                        formKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.class = 3
        form.operation_user = data.operation_user
        form.log_user = data.log_user
        form.delivery_date = data.delivery_date ? data.delivery_date.format('YYYY-MM-DD') : ''

        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            item.give_number = JSON.stringify(item.give_number)
            item.pua_number = JSON.stringify(item.pua_number)
            item.keep_alive = JSON.stringify(item.keep_alive)
        })

        form.content = JSON.stringify(Info.FirstInfo[0].editable.dataSource)

        createReceiptManagement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo } = this.state
        if (state) {
            if (title === '选择项目') {
                columns = [
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    }, {
                        title: '项目编号',
                        dataIndex: 'pro_number',
                        key: 'pro_number',
                    },
                    {
                        title: '项目经理',
                        dataIndex: 'pro_manager',
                        key: 'pro_manager',
                    },
                    {
                        title: '项目地址',
                        dataIndex: 'pro_address',
                        key: 'pro_address',
                    },
                ]
                TableInfo = [{
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                }]
                this.get_BlankingSelectProject()
            } else {
                columns = [{
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <span> 男 </span>
                            )
                        } else {
                            return (<span> 女 </span>
                            )
                        }
                    }
                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',
                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                ]
                TableInfo = [{
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'username', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }, {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'de_name', //字段名称
                    fieldType: '文本', //字段类型
                    disabled: false, //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                }]
                this.get_InitialValue()
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
            })
            )

        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: ''
            })
            )
        }
    }

    handleWorkAdd = (name) => {
        let { Info } = this.state;
        if (Info.FirstInfo[0].editable.dataSource.length) {
            let key = Info.FirstInfo[0].editable.dataSource.length - 1
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource[key].key + 1,
                mec_pro: '',
                name: '',
                unit: '',
                model: '',
                pua_number: [],
                give_number: [],
                keep_alive: [],
                result: '',
                total_time: '',
                oil_con: '',
                info: ''
            };
            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        } else {
            const newData = {
                key: Info.FirstInfo[0].editable.dataSource.length + 1,
                mec_pro: '',
                name: '',
                unit: '',
                model: '',
                pua_number: [],
                give_number: [],
                keep_alive: [],
                result: '',
                total_time: '',
                oil_con: '',
                info: ''
            };

            Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
        }

        this.setState(preState => ({
            Info
        }))
    }

    InputChange = (e, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = e.target.value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    PickerChange = (e, key, title, index) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title].splice(index, 1, e.format('YYYY-MM-DD HH:mm:ss'))
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    SelectChange = (value, key, title) => {
        let { Info } = this.state;
        Info.FirstInfo[0].editable.dataSource.forEach((em, ex) => {
            if (em.key === key) {
                em[title] = value
            }
        })
        this.setState(preState => ({
            Info
        }))
    }

    handSelect = (value) => {
        let { Info, form, buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            form.project_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目编号') {
                    item.value = value[0].pro_number
                } else if (item.FieldTitle === '项目经理') {
                    item.value = value[0].pro_manager
                }
            })
        } else if (buttonTitle === '选择操作人') {
            form.operation_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '操作人') {
                    item.value = value[0].username
                }
            })
        } else if (buttonTitle === '选择记录人') {
            form.log_user = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '记录人') {
                    item.value = value[0].username
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '选择项目') {
            this.setState({
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BlankingSelectProject()
            })
        } else if (buttonTitle === '选择操作人' || buttonTitle === '选择记录人') {
            this.setState({
                username: value.username,
                de_name: value.de_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_InitialValue()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择项目') {
                this.get_BlankingSelectProject()
            } else if (buttonTitle === '选择操作人' || buttonTitle === '选择记录人') {
                this.get_InitialValue()
            }
        })
    }

    handleDel = (key, e) => {
        let { Info } = this.state
        let dataSource = []
        this.NewForm.current.formRef.current.setFieldsValue({
            [(() => 'mec_pro' + e)()]: '',
            [(() => 'name' + e)()]: '',
            [(() => 'model' + e)()]: '',
            [(() => 'unit' + e)()]: '',
            [(() => 'result' + e)()]: '',
            [(() => 'morning_start_time' + e)()]: '',
            [(() => 'morning_end_time' + e)()]: '',
            [(() => 'afternoon_start_time' + e)()]: '',
            [(() => 'afternoon_end_time' + e)()]: '',
            [(() => 'evening_start_time' + e)()]: '',
            [(() => 'evening_end_time' + e)()]: '',
            [(() => 'total_time' + e)()]: '',
            [(() => 'oil_con' + e)()]: '',
            [(() => 'info' + e)()]: '',
        })
        Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
            if (index !== key) {
                dataSource = [...dataSource, item]
            }
        })
        Info.FirstInfo[0].editable.dataSource = dataSource
        this.setState(preState => ({
            Info,
        })
        )
    }

    render() {
        let { Info, formKey, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={formKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
