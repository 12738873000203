import React, { Component } from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import Append from '../../../components/addAppend'
import Icon from '@ant-design/icons';
import { Tree } from 'antd';
import Folder from "../../../../accets/img/folder.png"
import File from "../../../../accets/img/file.png"

import { supplierAuxiliary, supplierList, createSupplier, supplierDetail } from "../../../../utils/purchase"

const FolderImage = () => (
    <img src={Folder} alt=""></img>
);
const FileImage = () => (
    <img src={File} alt=""></img>
);


const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: false,
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_supplierDetail(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '电话',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '联系人',
                    dataIndex: 'contacts',
                    key: 'contacts',
                },
                {
                    title: '付款方式',
                    dataIndex: 'pay_model',
                    key: 'pay_model',
                },
                {
                    title: '建档人',
                    dataIndex: 'apply_user',
                    key: 'apply_user',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ backgroundColor: '#00DC9E', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></span>
                                    <span>使用中</span>
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ backgroundColor: '#F53500', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></div>
                                    <div>已禁用</div>
                                </div>
                            )
                        }
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '供应商', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            Info: {
                state: '新增',
                FlowModelName: "供应商入库",
                TableInfo: [{
                    FieldTitle: '供应商名称', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '材料类别', //字段标题
                    fieldName: 'mat_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    selectList: []
                }, {
                    FieldTitle: '供应商资质', //字段标题
                    fieldName: 'sup_pua', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ]
                }, {
                    FieldTitle: '联系电话', //字段标题
                    fieldName: 'phone', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '供应范围', //字段标题
                    fieldName: 'range', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '采购方式', //字段标题
                    fieldName: 'purchase', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '联系人', //字段标题
                    fieldName: 'contacts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '传真', //字段标题
                    fieldName: 'fax', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '地址', //字段标题
                    fieldName: 'address', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '付款方式', //字段标题
                    fieldName: 'pay_model', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '户名', //字段标题
                    fieldName: 'accounts', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '开户行', //字段标题
                    fieldName: 'bank_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '账号', //字段标题
                    fieldName: 'bank_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                {
                    FieldTitle: '状态', //字段标题
                    fieldName: 'use_status', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    radioList: [
                        {
                            label: '使用',
                            value: 1
                        },
                        {
                            label: '禁用',
                            value: 0
                        }
                    ]
                }
                ],
                FirstInfo: []
            },
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            classType: 1,
            sup_name: '',
            mat_class: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            form: {},
            auxiliary: {},
            selectValue: {},
            FlowchartKey: 1,
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_supplierAuxiliary()
    }

    get_supplierAuxiliary = () => {
        let { Info, treeData, auxiliary } = this.state
        supplierAuxiliary().then(
            (res) => {
                if (!res.code) {
                    treeData = [
                        {
                            title: '材料供应商类别',
                            key: 0,
                            icon: <FolderIcon />,
                            children: []
                        }
                    ]
                    auxiliary = res.data

                    for (let key in auxiliary.mat_type) {
                        let value = {
                            title: auxiliary.mat_type[key],
                            key: Number(key),
                            icon: <FileIcon />
                        }

                        treeData[0].children = [...treeData[0].children, value]
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '采购方式') {
                            item.selectList = []
                            for (let key in auxiliary.purchase_type) {
                                let value = {
                                    label: auxiliary.purchase_type[key],
                                    value: Number(key),
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        } else if (item.FieldTitle === '付款方式') {
                            item.selectList = []
                            for (let key in auxiliary.pay_model) {
                                let value = {
                                    label: auxiliary.pay_model[key],
                                    value: Number(key),
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })


                    this.setState(preState => ({
                        Info,
                        treeData,
                        expandedKeys: [0],
                        selectedKeys: [1],
                        auxiliary,
                        mat_class: 1
                    }), () => {
                        this.get_supplierList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_supplierList = () => {
        let { classType, sup_name, mat_class, dataSource, pages, process_state } = this.state

        let data = {
            class: classType,
            sup_name: sup_name,
            mat_class: mat_class,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        supplierList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.suppliers.length) {
                        res.data.suppliers.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                apply_user: item.username,
                                sup_name: item.sup_name,
                                phone: item.phone,
                                contacts: item.contacts,
                                pay_model: item.payModel,
                                status: item.use_status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: res.data.pages
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let { Info, classType, auxiliary } = this.state
        Info.state = '新增'
        Info.TableInfo.forEach((item, index) => {
            item.disabled = false
            if (classType === 1) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '材料类别'
                    item.selectList = []
                    for (let key in auxiliary.mat_type) {
                        let value = {
                            label: auxiliary.mat_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            } else if (classType === 2) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '班组类别'
                    item.selectList = []
                    for (let key in auxiliary.team_type) {
                        let value = {
                            label: auxiliary.team_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            } else if (classType === 3) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '机械类别'
                    item.selectList = []
                    for (let key in auxiliary.mac_type) {
                        let value = {
                            label: auxiliary.mac_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            }
        })

        this.setState({
            modal_Visible: true,
            Info,
            FlowchartKey: Math.random(),
        })
    }

    handleEdit = () => {
        let { selectValue } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                this.get_supplierDetail(selectValue[0].table_id, '编辑')
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    get_supplierDetail = (tableId, status) => {
        let { form, Info } = this.state
        let data = {
            table_id: tableId
        }
        supplierDetail(data).then(
            (res) => {
                if (!res.code) {
                    form.table_id = tableId
                    if (status) {
                        Info.state = '编辑'
                    } else {
                        Info.state = '详情'
                    }
                    this.setState({
                        modal_Visible: true,
                        form,
                        Info
                    }, () => {
                        this.get_Detail(res.data.Detail)
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_Detail = (value) => {
        let { Info, classType, auxiliary } = this.state
        if (Info.state === '详情') {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = true
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                item.disabled = false
            })
        }
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = value.enclosure.length ? value.enclosure : ''
            }
        })

        Info.TableInfo.forEach((item, index) => {
            if (classType === 1) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '材料类别'
                    item.selectList = []
                    for (let key in auxiliary.mat_type) {
                        let value = {
                            label: auxiliary.mat_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            } else if (classType === 2) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '班组类别'
                    item.selectList = []
                    for (let key in auxiliary.team_type) {
                        let value = {
                            label: auxiliary.team_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            } else if (classType === 3) {
                if (item.fieldName === 'mat_class') {
                    item.FieldTitle = '机械类别'
                    item.selectList = []
                    for (let key in auxiliary.mac_type) {
                        let value = {
                            label: auxiliary.mac_type[key],
                            value: Number(key),
                        }
                        item.selectList = [...item.selectList, value]
                    }
                }
            }
        })

        this.NewForm.current.formRef.current.setFieldsValue({
            mat_class: value.mat_class,
            sup_name: value.sup_name,
            sup_pua: value.sup_pua,
            phone: value.phone,
            range: value.range,
            purchase: value.purchase,
            contacts: value.contacts,
            fax: value.fax,
            address: value.address,
            pay_model: value.pay_model,
            accounts: value.accounts,
            bank_name: value.bank_name,
            bank_number: value.bank_number,
            info: value.info,
            enclosure: value.enclosure,
            use_status: value.use_status
        })

        this.setState({
            Info
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true,
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            start: '',
            end: '',
            name: '',
            apply_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_supplierList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
            sup_name: value.sup_name,
        }, () => {
            this.get_supplierList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_supplierList()
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handPresent = (data, status) => {
        const { form, classType } = this.state;
        form.class = classType
        form.mat_class = data.mat_class
        form.sup_name = data.sup_name
        form.sup_pua = data.sup_pua
        form.phone = data.phone
        form.range = data.range
        form.purchase = data.purchase
        form.contacts = data.contacts
        form.fax = data.fax
        form.address = data.address
        form.pay_model = data.pay_model
        form.accounts = data.accounts
        form.bank_name = data.bank_name
        form.bank_number = data.bank_number
        form.info = data.info
        form.use_status = data.use_status

        createSupplier(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_supplierList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '附件') {
                item.value = ''
            }
        })
        this.setState({
            modal_Visible: false,
            Info,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                mat_class: '',
                sup_name: '',
                phone: '',
                range: '',
                purchase: '',
                contacts: '',
                fax: '',
                address: '',
                pay_model: '',
                accounts: '',
                bank_name: '',
                bank_number: '',
                info: '',
                enclosure: '',
            })
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    get_tabIndex = (index) => {
        let { auxiliary, treeData } = this.state
        if (index === 1) {
            treeData = [
                {
                    title: '材料供应商类别',
                    key: 0,
                    icon: <FolderIcon />,
                    children: []
                }
            ]
            for (let key in auxiliary.mat_type) {
                let value = {
                    title: auxiliary.mat_type[key],
                    key: Number(key),
                    icon: <FileIcon />
                }

                treeData[0].children = [...treeData[0].children, value]
            }
        } else if (index === 2) {
            treeData = [
                {
                    title: '劳务班组',
                    key: 0,
                    icon: <FolderIcon />,
                    children: []
                }
            ]
            for (let key in auxiliary.team_type) {
                let value = {
                    title: auxiliary.team_type[key],
                    key: Number(key),
                    icon: <FileIcon />
                }

                treeData[0].children = [...treeData[0].children, value]
            }
        } else if (index === 3) {
            treeData = [
                {
                    title: '机械供应商类别',
                    key: 0,
                    icon: <FolderIcon />,
                    children: []
                }
            ]
            for (let key in auxiliary.mac_type) {
                let value = {
                    title: auxiliary.mac_type[key],
                    key: Number(key),
                    icon: <FileIcon />
                }

                treeData[0].children = [...treeData[0].children, value]
            }
        }

        this.setState(preState => ({
            classType: index,
            treeData,
            expandedKeys: [0],
            selectedKeys: [1],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_supplierList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            mat_class: value[0],
        }), () => {
            this.get_supplierList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, pages, TableInfo, classType, treeData, expandedKeys, selectedKeys, modal_Visible, Info, FlowchartKey } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <div className='cost_budgeting'>
                            <Operate notPrint={true} educe={true} dontImport={true} edit={true} editEvent={this.handleEdit} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                        </div>

                        <div className='supplier_right'>
                            <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(1)
                            }}>材料</div>
                            <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(2)
                            }}>劳务</div>
                            <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(3)
                            }}>机械</div>
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                    }

                    <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
