import React, { Component } from 'react'
import Icon, { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Tree, message } from 'antd';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import { revenueMain } from "../../../utils/market"
import { MasterPlanList, createMasterPlan } from "../../../utils/project"
import { fileDownload, filePreview } from "../../../utils/api"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"


const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pro_id: '',
            classType: 1,
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            planList: []
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_MasterPlanList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_MasterPlanList = () => {
        let { pro_id, classType, planList } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            class: classType === 1 ? 'material' : classType === 2 ? 'work' : 'mechanics',
            project_id: pro_id,
            queryCriteria: 'personal',
        }
        MasterPlanList(data).then(
            (res) => {
                if (!res.code) {
                    if (classType === 1) {
                        planList = res.data.material
                    } else if (classType === 2) {
                        planList = res.data.work
                    } else {
                        planList = res.data.mechanics
                    }
                    this.setState({
                        planList
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_createMasterPlan = () => {
        let { pro_id, classType, file_id } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }

        let data = {
            class: classType === 1 ? 'material' : classType === 2 ? 'work' : 'mechanics',
            project_id: pro_id,
            file_id: file_id
        }
        createMasterPlan(data).then(
            (res) => {
                if (!res.code) {
                    message.success('导入成功')
                    this.get_MasterPlanList()
                } else {
                    message.error('导入失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleImport = (id) => {
        this.setState(preState => ({
            file_id: id
        }), () => {
            this.get_createMasterPlan()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_MasterPlanList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
			pages: {
			    limit: 8,
			    page: 1,
			    total: 0
			},
        }), () => {
            this.get_MasterPlanList()
        })
    }

    get_download = (file) => {
        let data = {
            file_table_id: file.table_id
        }
        fileDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.click()
                } else {
                    message.error(res.data.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { treeData, expandedKeys, selectedKeys, classType } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <div className='cost_budgeting'>
                            <Operate dontAdd={true} notPrint={true} seek={true} educe={true} dontLead={true} dontImport={true} importEvent={this.handleImport} />
                        </div>

                        <div className='supplier_right'>
                            <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(1)
                            }}>材料</div>
                            <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(2)
                            }}>劳务</div>
                            <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(3)
                            }}>机械</div>
                        </div>
                    </div>

                    <div className='uploadList'>
                        <div>
                            {
                                this.state.planList.length ? this.state.planList.map((item, index) => {
                                    return (
                                        <div style={{ width: '49%' }} key={index}>
                                            <div></div>
                                            <div style={{ width: '92%' }}>
                                                <div>{item.filename}</div>
                                                <div>
                                                    <div>{item.name}于{item.created_at}上传</div>
                                                    <div>
                                                        <EyeOutlined style={{ color: "#339EF9", marginRight: '10px' }} onClick={() => {
                                                            this.get_filePreview(item)
                                                        }} />
                                                        <DownloadOutlined style={{ color: "#339EF9" }} onClick={() => {
                                                            this.get_download(item)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(List)
