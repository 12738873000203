import React, { Component } from 'react'
import InitialValue from '../../components/InitialValue'
import { message } from 'antd';
import AddConfig from '../../components/AddConfig'
import { createWeekly, weeklyDetail } from "../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "工作周报",
            TableInfo: [{
                FieldTitle: '姓名', //字段标题
                fieldName: 'user_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '日期', //字段标题
                fieldName: 'time', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择接收人', //字段标题
                fieldType: '按钮',  //字段类型
                mandatory: true, //字段是否必填
            }, {
                FieldTitle: '接收人', //字段标题
                fieldName: 'con_period', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入项目名称', //必填要求
                value: '',
                newline: true,
                width: '100%'
            },
            {
                FieldTitle: '本周完成工作', //字段标题
                fieldName: 'over_content', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入本周完成工作', //必填要求
                value: '',
                newline: true,
                width: '100%'
            },
            {
                FieldTitle: '下周工作计划', //字段标题
                fieldName: 'next_week', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                newline: true,
                width: '100%'
            },
            {
                FieldTitle: '备注', //字段标题
                fieldName: 'info', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                newline: true,
                width: '100%'
            },
            {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                newline: true,
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        workAdd: false,
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        read_userName: [],
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_weeklyDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '姓名') {
                item.value = userInfo.name
            } else if (item.FieldTitle === '部门') {
                item.value = userInfo.jobName
            } else if (item.FieldTitle === '日期') {
                item.value = new Date().toLocaleDateString()
            }
        })
        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_weeklyDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        weeklyDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择接收人') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    }

                    Info.state = res.data.Detail.status

                    if (res.data.Detail.readUserName) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '接收人') {
                                item.value = []
                                res.data.Detail.readUserName.forEach((em, ex) => {
                                    item.value.push(em.username + ' , ')
                                    em.key = em.table_id
                                })
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '姓名') {
                            item.value = res.data.Detail.username
                        } else if (item.FieldTitle === '部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '日期') {
                            item.value = res.data.Detail.created_at
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        pro_name: res.data.Detail.pro_name,
                        over_content: res.data.Detail.over_content,
                        next_week: res.data.Detail.next_week,
                        info: res.data.Detail.info,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        read_userName: res.data.Detail.readUserName || []
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info, read_userName } = this.state;
        form.status = status
        form.pro_name = data.pro_name
        form.over_content = data.over_content
        form.next_week = data.next_week
        form.info = data.info
        form.read_users = []
        read_userName.forEach((item, index) => {
            form.read_users.push(item.key)
        })
        form.read_users = JSON.stringify(form.read_users)

        createWeekly(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form, read_userName } = this.state
        if (value.length) {
            read_userName = []
            read_userName = [...read_userName, ...value]
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '接收人') {
                    let userName = []
                    read_userName.forEach((em, ex) => {
                        userName.push(em.username + ' , ')
                    })

                    item.value = userName
                }
            })
        } else {
            read_userName = []
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '接收人') {
                    item.value = []
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            read_userName,
            username: '',
            de_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div >
        )
    }
}
