import React, { Component } from 'react'
import { Tree, Input, Button, message, Modal } from 'antd';
import Icon, { RotateLeftOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../components/Operate'
import Table from '../../components/Table'
import Search from '../../components/Search'
import { folderBuildingContentGet, createBuildingDocument, documentUploadFile } from "../../../utils/project"
import { revenueMain } from "../../../utils/market"
import { fileDownload, filePreview, BulkDownload } from "../../../utils/api"
import foldeImg from "../../../accets/img/folde.png"
import fileImg from "../../../accets/img/files.png"
import wordImg from "../../../accets/img/word.png"
import xlsImg from "../../../accets/img/xls.png"
import pptImg from "../../../accets/img/ppt.png"
import pdfImg from "../../../accets/img/pdf.png"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdm: false,
            isUser: false,
            parentId: '',
            folderId: '',
            folder_name: '',
            enclosure: [],
            inputModal: false,
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '文 件 名',
                    dataIndex: 'name',
                    key: 'name',
                    width: '250px',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', }} onClick={() => {
                                if (record.file_type === '文件夹') {
                                    this.get_NextLevel(record)
                                } else {
                                    this.get_filePreview(record)
                                }
                            }}>

                                {
                                    record.file_type === '文件夹' ? <img src={foldeImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'docx' ? <img src={wordImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'xls' ? <img src={xlsImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'ppt' ? <img src={pptImg} alt="" style={{ width: '30px', height: '30px' }}></img> : record.file_type === 'pdf' ? <img src={pdfImg} alt="" style={{ width: '30px', height: '30px' }}></img> : <img src={fileImg} alt="" style={{ width: '30px', height: '30px' }}></img>
                                }

                                <div title={text} style={{ marginLeft: '20px', width: '240px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '上 传 人',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '上传时间',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '大  小',
                    dataIndex: 'file_size',
                    key: 'file_size',
                },
                {
                    title: '类  型',
                    dataIndex: 'file_type',
                    key: 'file_type',
                },
                // {
                //     title: '操  作',
                //     dataIndex: 'operation',
                //     key: 'operation',
                //     render: (text, record, index) => {
                //         if (record.file_type === '文件夹') {
                //             return (
                //                 <div>/</div>
                //             )
                //         } else {
                //             return (
                //                 <div className='download' onClick={() => {
                //                     this.get_download(record)
                //                 }}>
                //                     下载
                //                 </div>
                //             )
                //         }
                //     }
                // },
            ],
            TableInfo: [
                {
                    FieldTitle: '文件名称', //字段标题
                    fieldName: 'file_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '上 传 人', //字段标题
                    fieldName: 'up_user', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 15,
                page: 1,
                total: 0
            },
            pro_id: '',
            file_name: '',
            up_user: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            selectValue: [],
            fileList: [],
            FlowchartKey: 1
        }
    }

    componentDidMount() {
        let { isAdm } = this.state
        isAdm = false
        let permission = JSON.parse(localStorage.getItem("permission"))
        if (permission) {
            permission.forEach((item, index) => {
                if (item === 'createBuildingDocument') {
                    isAdm = true
                }
            })
        }
        this.setState(preState => ({
            isAdm
        }), () => {
            this.get_revenueMain()
        })
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (this.props.location.query) {
                        pro_id = this.props.location.query.tableId
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            item.children.forEach((em, ex) => {
                                em.children.forEach((m, x) => {
                                    if (m.key === pro_id) {
                                        selectedKeys.push(m.key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            })
                        })
                    } else {
                        if (!pro_id) {
                            selectedKeys = []
                            expandedKeys = ['1']
                            treeData.forEach((item, index) => {
                                if (index === 0) {
                                    item.children.forEach((em, ex) => {
                                        if (ex === 0) {
                                            pro_id = em.children[0].key
                                            selectedKeys.push(em.children[0].key)
                                            expandedKeys.push(em.key)
                                        }
                                    })
                                }
                            })
                        }
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                    }, () => {
                        this.get_folderBuildingContentGet()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_folderBuildingContentGet = () => {
        let { dataSource, pro_id, parentId, file_name, up_user, isUser, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            file_name: file_name,
            up_user: up_user,
            table_id: parentId == 1 ? '' : parentId,
            project_id: pro_id,
            page:Number(pages.page),
            limit:Number(pages.limit)
        }
        folderBuildingContentGet(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.type === 'files') {
                        if (res.data.files.length) {
                            res.data.files.forEach((item, index) => {
                                let rowValue = {
                                    key: index + 1,
                                    table_id: item.table_id,
                                    name: item.filename,
                                    file_size: item.fileSize,
                                    file_type: item.ext,
                                    username: item.applyUsername,
                                    created_at: item.created_at
                                }
                                dataSource = [...dataSource, rowValue]
                            })
                        }
                        parentId = res.data.parent_id
                        isUser = true
                    } else if (res.data.type === 'folder') {
                        if (res.data.files.length) {
                            res.data.files.forEach((item, index) => {
                                let rowValue = {
                                    key: index + 1,
                                    table_id: item.table_id,
                                    name: item.folder_name,
                                    created_at: item.created_at,
                                    file_size: item.fileSize || '/',
                                    file_type: '文件夹',
                                }
                                dataSource = [...dataSource, rowValue]
                            })
                        }
                        if (res.data.parent_id === 1) {
                            parentId = ''
                        } else {
                            parentId = res.data.parent_id
                        }
                        isUser = false
                    }

                    this.setState(preState => ({
                        dataSource,
                        parentId: parentId,
                        isUser,
                        pages: {
                            limit: Number(res.data.pages.limit),
                            page: Number(res.data.pages.page),
                            total: Number(res.data.pages.total),
                        },
                        FlowchartKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    updateFolder = () => {
        let { folder_name, parentId, pro_id } = this.state
        let data = {
            folder_name: folder_name,
            table_id: parentId,
            project_id: pro_id
        }
        createBuildingDocument(data).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.setState(preState => ({
                        inputModal: false,
                    }), () => {
                        this.get_folderBuildingContentGet()
                    })
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    set_documentUploadFile = () => {
        let { folderId, enclosure } = this.state
        let data = {
            folder_id: folderId,
            enclosure: JSON.stringify(enclosure)
        }
        documentUploadFile(data).then(
            (res) => {
                if (!res.code) {
                    message.success('导入成功')
                    this.setState(preState => ({
                        parentId: folderId,
                    }), () => {
                        this.get_folderBuildingContentGet()
                    })
                } else {
                    message.error('导入失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_NextLevel = (record) => {
        this.setState(preState => ({
            parentId: record.table_id,
            folderId: record.table_id,
            pages: {
                limit: 15,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_folderBuildingContentGet()
        })
    }

    Upper_level = () => {
        let { parentId } = this.state
        this.setState(preState => ({
            parentId: parentId,
            folderId: '',
            pages: {
                limit: 15,
                page: 1,
                total: 0
            },
        }), () => {
            this.get_folderBuildingContentGet()
        })
    }

    handleImport = (id) => {
        let { enclosure } = this.state
        enclosure = []
        enclosure = [...enclosure, id]
        this.setState(preState => ({
            enclosure
        }), () => {
            this.set_documentUploadFile()
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handlePrint = () => { }

    handleExport = () => {
        let { selectValue, isUser, pro_id, fileList } = this.state
        if (!isUser) {
            if (selectValue.length > 0) {
                if (selectValue.length === 1) {
                    // this.batchDownload(selectValue[0].table_id)

                    let data = {
                        table_id: selectValue[0].table_id,
                        project_id: pro_id,
                    }
                    folderBuildingContentGet(data).then(
                        (res) => {
                            if (!res.code) {
                                if (res.data.type === 'files') {
                                    if (res.data.files.length) {
                                        this.batchDownload(selectValue[0].table_id, res.data.pages.total)
                                    } else {
                                        message.error('文件夹内文件为空，无法正常导出')
                                    }
                                } else if (res.data.type === 'folder') {
                                    message.error('内含二级文件夹，无法正常导出')
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (selectValue.length > 1) {
                    message.error('无法批量下载文件夹,请选择单项')
                } else {
                    message.error('请选择导出项')
                }
            } else {
                message.error('请选择导出项')
            }
        } else {
            if (selectValue.length > 0) {
                if (selectValue.length > 1) {
                    fileList = []
                    selectValue.forEach((item, index) => {
                        fileList = [...fileList, item.table_id]
                    })
                    this.setState(preState => ({
                        fileList
                    }), () => {
                        this.batch_download()
                    })
                } else {
                    this.get_download(selectValue[0])
                }
            } else {
                message.error('请选择导出项')
            }
        }
    }

    batchDownload = (tableId, Total) => {
        let { pro_id, fileList } = this.state

        let data = {
            table_id: tableId,
            project_id: pro_id,
            page: 1,
            limit: Total
        }
        folderBuildingContentGet(data).then(
            (res) => {
                if (!res.code) {
                    fileList = []
                    res.data.files.forEach((item, index) => {
                        fileList = [...fileList, item.table_id]
                    })
                }

                this.setState(preState => ({
                    fileList
                }), () => {
                    this.batch_download()
                })
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // get_download = (file) => {
    //     let data = {
    //         file_table_id: file.table_id
    //     }
    //     axios.get('/api/fileDownload', {
    //         headers: {
    //             "Authorization": "Bearer " + localStorage.getItem("Token")
    //         },
    //         responseType: 'blob',
    //         params: data,
    //     }).then((res) => {
    //         const blob = new Blob([res.data], {
    //             type: 'application/vnd.ms-excel;charset=utf-8'
    //         })

    //         const link = document.createElement('a');
    //         link.download = file.filename
    //         link.href = URL.createObjectURL(blob)
    //         link.target = "_blank"
    //         document.body.appendChild(link)
    //         link.click();
    //         //释放URL对象
    //         URL.revokeObjectURL(link.href);
    //         document.body.removeChild(link);
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    batch_download = () => {
        let { fileList } = this.state
        let data = {
            file_ids: JSON.stringify(fileList)
        }
        BulkDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.href = res.data.path
                    link.download = res.data.fileName; // 设置新的文件名
                    console.log(res.data.fileName)
                    document.body.appendChild(link);
                    link.click()
                } else {
                    message.error(res.data[0] || '导出失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleCancel = () => {
        let { folderId } = this.state
        this.setState({
            searchShow: false,
            file_name: '',
            up_user: '',
            parentId: folderId,
            pages: {
                limit: 15,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_folderBuildingContentGet()
        })
    }

    searchQuery = (value) => {
        let { folderId } = this.state
        this.setState({
            file_name: value.file_name,
            up_user: value.up_user,
            parentId: folderId,
        }, () => {
            this.get_folderBuildingContentGet()
        })
    }

    handleLimit = (page, limit) => {
        let { pages, folderId } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages,
            parentId: folderId,
        }, () => {
            this.get_folderBuildingContentGet()
        })
    }

    handleNew = () => {
        this.setState({
            inputModal: true,
        })
    }

    handleModal = () => {
        this.setState({
            inputModal: false,
        })
    }

    InputChange = (value) => {
        let { folder_name } = this.state
        folder_name = value.target.value
        this.setState({
            folder_name
        })
    }

    get_download = (file) => {

        console.log(file)
        let data = {
            file_table_id: file.table_id
        }
        fileDownload(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.click()
                } else {
                    message.error(res.data.data[0] || '预览失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
            parentId: '',
        }), () => {
            this.get_folderBuildingContentGet()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { isAdm, isUser, dataSource, columns, treeData, expandedKeys, selectedKeys, inputModal, pages, searchShow, TableInfo, FlowchartKey } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <div className='cost_budgeting' style={{ width: 'auto' }}>
                            <Operate educe={false} dontAdd={true} dontLead={isUser} dontImport={true} notPrint={true} newFolder={isAdm && !isUser} seek={!isUser} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} importEvent={this.handleImport} />

                            {
                                this.state.parentId ? <div className='ListOperate'>
                                    <Button onClick={() => {
                                        this.Upper_level()
                                    }}>
                                        <RotateLeftOutlined />
                                        <div>返回</div>
                                    </Button>
                                </div> : ''
                            }
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    <Table key={FlowchartKey} columns={
                        !isUser ? columns.filter((item) => item.dataIndex !== 'username') : columns
                    } dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                    <Modal
                        title="文件夹名称"
                        centered
                        visible={inputModal}
                        onOk={() => this.updateFolder()}
                        onCancel={() => this.handleModal()}
                        okText="确认"
                        cancelText="取消"
                        wrapClassName='userModal'
                    >
                        <Input onChange={(e) => this.InputChange(e)} />
                    </Modal>
                </div>
            </div>
        )
    }
}


export default withRouter(List)



