import React, { Component } from 'react'
import SideMenu from '../../components/SideMenu'
import TopHeader from '../../components/TopHeader'
import { withRouter } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom'
import CacheRoute, { CacheSwitch, dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import router from '../../router/Index'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: {},
        }
    }

    static getDerivedStateFromProps(props, state) {
        return { path: props.location }
    }

    handleDelete = (value) => {
        let CachingKeys = getCachingKeys()
        CachingKeys.forEach((item, index) => {
            if (item === value) {
                dropByCacheKey(item)
            }
        })
    }

    render() {
        let { path } = this.state
        return (
            <div className='home_plate'>
                <SideMenu />
                <div className='sandbox'>
                    <div className='home_plate_right'>
                        <TopHeader path={path} deleteEvent={this.handleDelete} />
                        <div className='home_plate_content'>
                            <CacheSwitch>
                                {router.map((item, index) => {
                                    return !item.cache ? (
                                        <CacheRoute
                                            exact
                                            key={index}
                                            cacheKey={item.path}
                                            path={item.path}
                                            component={item.component}
                                        />
                                    ) : (
                                        <Route
                                            exact
                                            key={index}
                                            path={item.path}
                                            component={item.component}
                                        />
                                    );
                                    // return (
                                    //     <CacheRoute
                                    //         exact
                                    //         key={index}
                                    //         cacheKey={item.path}
                                    //         path={item.path}
                                    //         component={item.component}
                                    //     />
                                    // )
                                })}
                                <Redirect from="/" to="/index" />
                            </CacheSwitch>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default withRouter(Home)
