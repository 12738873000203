import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import Icon from '@ant-design/icons';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import amountInWords from '../../../../components/amountInWords'
import { nurserySettlementDetail, NurserySettlementContract, createNurserySettlement, researchProjectMain } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"
import Folder from "../../../../accets/img/min_company.png"
import Folders from "../../../../accets/img/min_folder.png"
import File from "../../../../accets/img/min_file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/scientificResearchSettlement.docx',
                templateName: '四川天艺优境环境科技有限公司358结算审批单',
                tempData: {
                    apply_date: '',
                    project_name: '',
                    con_name: '',
                    sup_name: '',
                    con_money: '',
                    acc_pay: '',
                    set_money: '',
                    ch_con_money: '',
                    ch_acc_pay: '',
                    ch_set_money: '',
                    results: []
                },
                FlowModelName: "成果转化项目结算审批",
                flowTips: '(358工程)',
                TableInfo: [{
                    FieldTitle: '选择合同', //字段标题
                    fieldType: '按钮',  //字段类型
                    mandatory: true, //字段是否必填
                }, {
                    FieldTitle: '合同名称', //字段标题
                    fieldName: 'con_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'con_num', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '合同金额', //字段标题
                    fieldName: 'con_money', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '项目编号', //字段标题
                    fieldName: 'pro_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '项目经理', //字段标题
                    fieldName: 'pro_manager', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '供货单位', //字段标题
                    fieldName: 'con_party', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '结算金额(元)', //字段标题
                    fieldName: 'con_set_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入结算金额', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '前期进度计量金额(元)', //字段标题
                    fieldName: 'progress_payment', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请输入结算金额', //必填要求
                    value: '',
                    width: '50%',
                },
                {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本域',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: [],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            treeData: [],
            pro_id: '',
            selectedKeys: [],
            expandedKeys: [],
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '合同名称',
                    dataIndex: 'con_name',
                    key: 'con_name',
                },
                {
                    title: '合同编号',
                    dataIndex: 'con_number',
                    key: 'con_number',
                },
                {
                    title: '合同金额',
                    dataIndex: 'con_money',
                    key: 'con_money',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '申请日期',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '合同名称', //字段标题
                    fieldName: 'con_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '合同编号', //字段标题
                    fieldName: 'con_number', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            con_name: '',
            con_number: '',
            pages: {
                page: 1,
                total: 0,
                limit: 50,
            },
            form: {}
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_nurserySettlementDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info,
        }))
    }

    get_nurserySettlementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        nurserySettlementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 1 || res.data.status === 3) {
                        form.table_id = tableId
                        form.con_id = res.data.con_id
                        Info.process.edit = res.data.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                            if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            } else if (item.FieldTitle === '附件') {
                                if (!res.data.enclosure.length) {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.status === 4) {
                            Info.tempData.apply_date = res.data.created_at.slice(0, 10)
                            Info.tempData.project_name = res.data.pro_name
                            Info.tempData.con_name = res.data.con_name
                            Info.tempData.sup_name = res.data.sup_name
                            Info.tempData.con_money = res.data.con_money
                            Info.tempData.acc_pay = res.data.progress_payment || 0
                            Info.tempData.set_money = res.data.con_set_money
                            Info.tempData.ch_con_money = amountInWords(Number(res.data.con_money))
                            Info.tempData.ch_acc_pay = res.data.progress_payment ? amountInWords(Number(res.data.progress_payment)) : '零'
                            Info.tempData.ch_set_money = amountInWords(Number(res.data.con_set_money))
                            Info.tempData.results = []

                            res.data.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                    }

                    Info.state = res.data.status
                    Info.process.data = res.data.reviewAudit
                    Info.process.reviewAudit = res.data.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.reviewAudit.ccReading
                    Info.process.audit = res.data.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.reviewAudit.selfRejectPermission



                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同名称') {
                            item.value = res.data.con_name
                        } else if (item.FieldTitle === '合同编号') {
                            item.value = res.data.con_num
                        } else if (item.FieldTitle === '合同金额') {
                            item.value = res.data.con_money
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.proManager
                        } else if (item.FieldTitle === '供货单位') {
                            item.value = res.data.sup_name
                        } else if (item.FieldTitle === '前期进度计量金额(元)') {
                            item.value = res.data.progress_payment || 0
                            form.progress_payment = res.data.progress_payment || 0
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.enclosure.length ? res.data.enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        info: res.data.info,
                        con_set_money: res.data.con_set_money,
                        enclosure: res.data.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchProjectMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        researchProjectMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    treeData.forEach((item, index) => {
                        if (index === 0) {
                            item.children.forEach((em, ex) => {
                                if (ex === 0) {
                                    pro_id = em.children[0].key
                                    selectedKeys.push(em.children[0].key)
                                    expandedKeys.push(em.key)
                                }
                            })
                        }
                    })

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                        modal_Visible: true,
                    }, () => {
                        this.get_NurserySettlementContract()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_NurserySettlementContract = () => {
        let { dataSource, pages, pro_id, con_name, con_number } = this.state
        let data = {
            con_id: pro_id,
            con_name: con_name,
            con_number: con_number,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        NurserySettlementContract(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                apply_date: item.apply_day,
                                con_name: item.con_name,
                                con_number: item.con_num,
                                con_money: item.con_money,
                                sup_name: item.party_b,
                                pro_name: item.proName,
                                pro_number: item.proNumber,
                                pro_manager: item.proManager,
                                progress_payment: item.progress_payment,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.con_set_money = data.con_set_money
        form.info = data.info

        createNurserySettlement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_researchProjectMain()
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }


    handSelect = (value) => {
        const { Info, form } = this.state;

        form.con_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '合同名称') {
                item.value = value[0].con_name
            } else if (item.FieldTitle === '合同编号') {
                item.value = value[0].con_number
            } else if (item.FieldTitle === '合同金额') {
                item.value = value[0].con_money
            } else if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            } else if (item.FieldTitle === '项目经理') {
                item.value = value[0].pro_manager
            } else if (item.FieldTitle === '供货单位') {
                item.value = value[0].sup_name
            } else if (item.FieldTitle === '前期进度计量金额(元)') {
                item.value = value[0].progress_payment
                form.progress_payment = value[0].progress_payment
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    searchQuery = (value) => {
        this.setState({
            con_name: value.con_name,
            con_number: value.con_number,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_NurserySettlementContract()
        })

    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            con_name: '',
            con_number: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_NurserySettlementContract()
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_NurserySettlementContract()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, treeData, selectedKeys, expandedKeys } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} isTree={treeData.length ? true : false} treeData={treeData} selectedKeys={selectedKeys} expandedKeys={expandedKeys} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} onSelectEvent={this.onSelect} onExpandEvent={this.onExpand} />
            </div>
        )
    }
}