import React, { Component } from 'react'
import { message, Modal } from 'antd';
import moment from 'moment';
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Append from '../../components/addAppend'
import { GeneralInventoryList, EditGeneralInventory } from "../../../utils/purchase"

export default class Tree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: ' 时间',
                    dataIndex: 'apply_date',
                    key: 'apply_date',
                },
                {
                    title: '材料名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '规格型号',
                    dataIndex: 'unit',
                    key: 'unit',
                },
                {
                    title: '品牌1',
                    dataIndex: 'brand_1',
                    key: 'brand_1',
                    render: (text, record, index) => {
                        return (
                            <div >
                                <div>品牌: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.brand}</span></div>
                                <div>单位: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.unit}</span></div>
                                <div>单价(元): <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.price}</span></div>
                            </div>
                        )
                    }
                },
                {
                    title: '品牌2',
                    dataIndex: 'brand_2',
                    key: 'brand_2',
                    render: (text, record, index) => {
                        return (
                            <div >
                                <div>品牌: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.brand}</span></div>
                                <div>单位: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.unit}</span></div>
                                <div>单价(元): <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.price}</span></div>
                            </div>
                        )
                    }
                },
                {
                    title: '品牌3',
                    dataIndex: 'brand_3',
                    key: 'brand_3',
                    render: (text, record, index) => {
                        return (
                            <div >
                                <div>品牌: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.brand}</span></div>
                                <div>单位: <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.unit}</span></div>
                                <div>单价(元): <span style={{ color: '#339EF9', fontSize: '18px', marginLeft: '5px' }}>{text.price}</span></div>
                            </div>
                        )
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'info',
                    key: 'info',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '材料名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '95%',
                },
            ],
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            uploadUrl: {
                url: '/api/ImportGeneralInventory',
                data: {
                    project_id: '',
                    class: '管材'
                }
            },
            modal_Visible: false,
            Info: {
                state: '编辑',
                FlowModelName: "人材机总库",
                TableInfo: [{
                    FieldTitle: '名称', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '单位', //字段标题
                    fieldName: 'unit', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '时间', //字段标题
                    fieldName: 'apply_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '备注', //字段标题
                    fieldName: 'info', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }
                ],
                FirstInfo: [
                    {
                        plateName: '品牌1',
                        list: [
                            {
                                FieldTitle: '品牌', //字段标题
                                fieldName: 'brand_1_brand', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单位', //字段标题
                                fieldName: 'brand_1_unit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单价', //字段标题
                                fieldName: 'brand_1_price', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }
                        ]
                    },
                    {
                        plateName: '品牌2',
                        list: [
                            {
                                FieldTitle: '品牌', //字段标题
                                fieldName: 'brand_2_brand', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单位', //字段标题
                                fieldName: 'brand_2_unit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单价', //字段标题
                                fieldName: 'brand_2_price', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }
                        ]
                    },
                    {
                        plateName: '品牌3',
                        list: [
                            {
                                FieldTitle: '品牌', //字段标题
                                fieldName: 'brand_3_brand', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单位', //字段标题
                                fieldName: 'brand_3_unit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '单价', //字段标题
                                fieldName: 'brand_3_price', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '50%'
                            }
                        ]
                    }
                ]
            },
            selectValue: [],
            form: {},
            templateShow: false,
            templateList:[]
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_GeneralInventoryList()
    }

    get_GeneralInventoryList = () => {
        let { dataSource, pages, name } = this.state
        let data = {
            class: '管材',
            name: name,
            queryCriteria: 'personal',
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        GeneralInventoryList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.general.length) {
                        res.data.general.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.id,
                                apply_date: item.apply_date,
                                brand_1: JSON.parse(item.brand_1),
                                brand_2: JSON.parse(item.brand_2),
                                brand_3: JSON.parse(item.brand_3),
                                name: item.name,
                                unit: item.unit,
                                info: item.info,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        templateList: res.data.template
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    get_Detail = (value) => {
        this.NewForm.current.formRef.current.setFieldsValue({
            name: value[0].name,
            unit: value[0].unit,
            apply_date: moment(value[0].apply_date, 'YYYY-MM-DD'),
            info: value[0].info,
            brand_1_brand: value[0].brand_1.brand,
            brand_2_brand: value[0].brand_2.brand,
            brand_3_brand: value[0].brand_3.brand,
            brand_1_unit: value[0].brand_1.unit,
            brand_2_unit: value[0].brand_2.unit,
            brand_3_unit: value[0].brand_3.unit,
            brand_1_price: value[0].brand_1.price,
            brand_2_price: value[0].brand_2.price,
            brand_3_price: value[0].brand_3.price,
        })
    }


    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_GeneralInventoryList()
        })
    }

    updateChange = (info) => {
        if (info.file.status === 'done') {
            if (!info.file.response.code) {
                message.success('导入成功')
                this.get_GeneralInventoryList()
            } else if (info.file.response.code === 1004) {
                message.error('权限受限，请联系管理员!')
            } else {
                message.error(info.file.response.data[0] || '导入失败')
            }
        } else if (info.file.status === 'error') {
            message.error('导入失败')
        }
    }

    handleEdit = () => {
        let { selectValue, form } = this.state
        if (selectValue.length > 0) {
            if (selectValue.length > 1) {
                message.error('无法编辑多项')
            } else {
                form.id = selectValue[0].table_id
                this.setState({
                    modal_Visible: true,
                    form
                }, () => {
                    this.get_Detail(selectValue)
                })
            }
        } else {
            message.error('请选择编辑项')
        }
    }

    handSelect = (value) => {
        this.setState({
            selectValue: value
        })
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.class = '管材'
        form.name = data.name
        form.unit = data.unit
        form.apply_date = data.apply_date.format('YYYY-MM-DD')
        form.info = data.info
        form.brand_1 = {
            brand: data.brand_1_brand,
            unit: data.brand_1_unit,
            price: data.brand_1_price,
        }
        form.brand_1 = JSON.stringify(form.brand_1)
        form.brand_2 = {
            brand: data.brand_2_brand,
            unit: data.brand_2_unit,
            price: data.brand_2_price,
        }
        form.brand_2 = JSON.stringify(form.brand_2)
        form.brand_3 = {
            brand: data.brand_3_brand,
            unit: data.brand_3_unit,
            price: data.brand_3_price,
        }
        form.brand_3 = JSON.stringify(form.brand_3)
        EditGeneralInventory(form).then(
            (res) => {
                if (!res.code) {
                    message.success('编辑成功')
                    this.modalCancel()
                    this.get_GeneralInventoryList()
                } else {
                    message.error('编辑失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false,
            form: {}
        }, () => {
            this.NewForm.current.formRef.current.setFieldsValue({
                name: '',
                unit: '',
                apply_date: '',
                info: '',
                brand_1_brand: '',
                brand_2_brand: '',
                brand_3_brand: '',
                brand_1_unit: '',
                brand_2_unit: '',
                brand_3_unit: '',
                brand_1_price: '',
                brand_2_price: '',
                brand_3_price: '',
            })
        })
    }

    handleTemplate = () => {
        this.setState({
            templateShow: true,
        })
    }

    cancelTemplate = () => {
        this.setState({
            templateShow: false,
        })
    }

    handlePath = (path) => {
        let link = document.createElement("a")
        link.href = path
        link.click()
    }

    render() {
        let { searchShow, dataSource, columns, pages, TableInfo, uploadUrl, Info, modal_Visible, templateShow, templateList } = this.state
        return (
            <div>
                <Operate notPrint={true} educe={true} dontAdd={true} edit={true} template={true} uploadEvent={this.updateChange} editEvent={this.handleEdit} searchEvent={this.handleSearch} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} uploadUrl={uploadUrl} templateEvent={this.handleTemplate}/>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource} pages={pages} dataLimit={this.handleLimit} selectEvent={this.handSelect} />

                <Append ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} presentEvent={this.handPresent} cancelEvent={this.modalCancel} />

                <Modal
                    title="下载模板"
                    centered
                    visible={templateShow}
                    onCancel={() => this.cancelTemplate()}
                    wrapClassName='userModal template'
                    maskClosable={false}
                    footer={[]}
                >
                    {
                        templateList.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    this.handlePath(item.filePath)
                                }}>
                                    {item.file_name}
                                </div>
                            )
                        })
                    }
                </Modal>
            </div>
        )
    }
}
