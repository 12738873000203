import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { message } from 'antd';
import InitialValue from '../../components/InitialValue'
import { ResearchProjectDataInformation, ResearchProjectDataList } from "../../../utils/scientific_research"
import Icon01 from "../../../accets/img/kanban_icon_01.png"
import Icon02 from "../../../accets/img/kanban_icon_02.png"
import Icon03 from "../../../accets/img/kanban_icon_03.png"
import Icon04 from "../../../accets/img/kanban_icon_04.png"
import Icon05 from "../../../accets/img/kanban_icon_05.png"
import Icon06 from "../../../accets/img/kanban_icon_06.png"
import Icon07 from "../../../accets/img/kanban_icon_07.png"
import Icon08 from "../../../accets/img/kanban_icon_08.png"
import Icon09 from "../../../accets/img/kanban_icon_09.png"
import Icon10 from "../../../accets/img/kanban_icon_10.png"
import Icon11 from "../../../accets/img/kanban_icon_11.png"
import Icon12 from "../../../accets/img/kanban_icon_12.png"




class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    width: '240px',
                    render: (text, record, index) => {
                        return (
                            <div title={text} style={{ width: '235px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '开工时间',
                    dataIndex: 'plant_open_day',
                    key: 'plant_open_day',
                },
                {
                    title: '项目性质',
                    dataIndex: 'pro_class',
                    key: 'pro_class',
                },
                {
                    title: '项目负责人',
                    dataIndex: 'pro_manager',
                    key: 'con_monepro_managery',
                },
                {
                    title: '材料总价(元)',
                    dataIndex: 'mac_money',
                    key: 'mac_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '人工总价(元)',
                    dataIndex: 'peo_cost',
                    key: 'peo_cost',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '结算面积(㎡)',
                    dataIndex: 'set_area',
                    key: 'set_area',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#07D7F3' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '结算金额(元)',
                    dataIndex: 'set_money',
                    key: 'set_money',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#E4DD40' }}>{text}</div>
                        )
                    }
                },
                {
                    title: '利润率(%)',
                    dataIndex: 'profit_rate',
                    key: 'profit_rate',
                    render: (text, record, index) => {
                        return (
                            <div style={{ color: '#EC5355' }}>{text}</div>
                        )
                    }
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            pro_item: {},
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ResearchProjectDataInformation(tableId)
        }
    }

    get_ResearchProjectDataInformation = (tableId) => {
        let { pro_item } = this.state
        let data = {
            table_id: tableId,
        }
        ResearchProjectDataInformation(data).then(
            (res) => {
                if (!res.code) {
                    pro_item = res.data
                    this.setState(preState => ({
                        pro_item
                    })
                    )
                }else{
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ResearchProjectDataList = () => {
        let { dataSource, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            pro_class: '',
            start: '',
            end: '',
        }
        ResearchProjectDataList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let rowValue = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                plant_open_day: item.plant_open_day,
                                pro_class: item.proClassName,
                                pro_manager: item.pro_manager,
                                mac_money: item.mac_money,
                                peo_cost: item.peo_cost,
                                set_area: item.set_area,
                                set_money: item.set_money,
                                profit_rate: item.profit_rate
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_ResearchProjectDataList()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        this.setState(preState => ({
            modal_Visible: false,
        }), () => {
            this.get_ResearchProjectDataInformation(value[0].key)
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ResearchProjectDataList()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    render() {
        let { modal_Visible, dataSource, columns, TableInfo, pages, pro_item } = this.state
        return (
            <div className='control_table project_kanban_NewItem'>
                <div>
                    <div className='project_kanban_name' style={{ justifyContent: 'flex-start' }}>
                        <div></div>
                        <div>358项目总看板</div>
                    </div>
                    <div className='table-top-right-top' style={{ height: 'auto' }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.handButton('', true)
                        }}>切换项目</div>
                        <div className='con_name' >
                            <div>{pro_item.pro_name}</div>
                        </div>
                    </div>
                    <div></div>
                </div>

                <div>
                    <div className='table-top-left-buttom'>
                        <div className='pro_overview'>基本信息</div>
                        <div className='pro_introduce'>
                            <div>
                                <img src={Icon01} alt=""></img>
                                <div>项目名称:</div>
                                <div>{pro_item.pro_name}</div>
                            </div>
                            <div>
                                <img src={Icon02} alt=""></img>
                                <div>开工时间:</div>
                                <div>{pro_item.plant_open_day}</div>
                            </div>
                            <div>
                                <img src={Icon03} alt=""></img>
                                <div>甲方:</div>
                                <div>{pro_item.gen_unit}</div>
                            </div>
                            <div>
                                <img src={Icon04} alt=""></img>
                                <div>甲方负责人:</div>
                                <div>{pro_item.gen_proxy}</div>
                            </div>
                            <div>
                                <img src={Icon06} alt=""></img>
                                <div>项目性质:</div>
                                <div>{pro_item.proClassName}</div>
                            </div>
                            <div>
                                <img src={Icon07} alt=""></img>
                                <div>施工内容:</div>
                                <div>{pro_item.twoLevleName}</div>
                            </div>
                            <div>
                                <img src={Icon08} alt=""></img>
                                <div>施工面积(㎡):</div>
                                <div>{pro_item.build_area}</div>
                            </div>
                            <div>
                                <img src={Icon05} alt=""></img>
                                <div>项目负责人:</div>
                                <div>{pro_item.pro_manager}</div>
                            </div>
                        </div>
                    </div>
                    <div className='table-buttom-right'>
                        <div className='pro_overview'>工程信息</div>
                        <div className='eng_Information'>
                            <div>
                                <img src={Icon09} alt=""></img>
                                <div>
                                    <div>
                                        <div>材料到场单价(元/㎡)：</div>
                                        <div>{pro_item.site_price}</div>
                                    </div>
                                    <div></div>
                                    <div>
                                        <div>材料总价(元)：</div>
                                        <div style={{ color: '#07D7F3' }}>{pro_item.macAllMoney}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src={Icon10} alt=""></img>
                                <div>
                                    <div>
                                        <div>人工单价(元/天)：</div>
                                        <div>{pro_item.peo_price}</div>
                                    </div>
                                    <div>
                                        <div>人工工日(天)：</div>
                                        <div>{pro_item.peo_date}</div>
                                    </div>
                                    <div>
                                        <div>人工总价(元)：</div>
                                        <div style={{ color: '#07D7F3' }}>{pro_item.peo_cost}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src={Icon11} alt=""></img>
                                <div>
                                    <div>
                                        <div>其他费用(元)：</div>
                                        <div style={{ color: '#07D7F3' }}>{pro_item.other_cost}</div>
                                    </div>
                                    <div>
                                        <div>利润率(%)：</div>
                                        <div style={{ color: '#EC5355' }}>{pro_item.profit_rate}</div>
                                    </div>
                                    <div>
                                        <div>成本总额(元)：</div>
                                        <div style={{ color: '#E4DD40' }}>{pro_item.costAllMoney}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src={Icon12} alt=""></img>
                                <div>
                                    <div>
                                        <div>结算单价(元/㎡)：</div>
                                        <div style={{ color: '#07D7F3' }}>{pro_item.set_price}</div>
                                    </div>
                                    <div>
                                        <div>结算面积(㎡)：</div>
                                        <div style={{ color: '#07D7F3' }}>{pro_item.set_area}</div>
                                    </div>
                                    <div>
                                        <div>结算总额(元)：</div>
                                        <div style={{ color: '#E4DD40' }}>{pro_item.set_money}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={false} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} />
            </div>
        )
    }
}

export default withRouter(List)
