import React, { Component } from 'react'
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { Tree, message, Form } from 'antd';
import { withRouter } from "react-router-dom";
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import Operate from '../../components/Operate'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { TechnologyApplicationList, technologyConfirms, getTechYear } from "../../../utils/scientific_research"
import Append from '../../components/addAppend'

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'pro_name',
                    key: 'pro_name',
                    width: '150px',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer',width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '项目类别',
                    dataIndex: 'pro_class',
                    key: 'pro_class',
                },
                {
                    title: '申报时间',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text, index) => {
                        return (
                            <div className='timeStyle'>
                                <div>
                                    <div></div>
                                    <div>{text[0]}</div>
                                </div>
                                <div>
                                    <div></div>
                                    <div>{text[1]}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '申报额度',
                    dataIndex: 'dec_money',
                    key: 'dec_money',
                }, {
                    title: '合同审批',
                    dataIndex: 'con_approve',
                    key: 'con_approve approve',
                    render: (text, record, index) => {
                        if (record.status === 4) {
                            if (record.contract_status) {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_ConApprovalList(record.table_id)
                                    }}>合同审批记录</div>
                                )
                            } else {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_ConApprovalNewItem(record.table_id)
                                    }}>合同审批</div>
                                )
                            }
                        }
                    }
                }, {
                    title: '结果确认',
                    dataIndex: 'res_verification',
                    key: 'res_verification',
                    render: (text, record, index) => {
                        if (record.status === 4) {
                            if (record.confirm_status) {
                                return (
                                    <div>{record.confirm_result}</div>
                                )
                            } else {
                                return (
                                    <div style={{ cursor: 'pointer', color: '#339EF9' }} onClick={() => {
                                        this.get_result(record.table_id)
                                    }}>结果确认</div>
                                )
                            }
                        }
                    }
                }, {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '200px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },

            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            year: '',
            pro_name: '',
            pro_class: '',
            apply_date: '',
            end_date: '',
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                },
                {
                    FieldTitle: '项目类别', //字段标题
                    fieldName: 'pro_class', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    selectList: [
                        {
                            value: 1,
                            label: '课题项目'
                        },
                        {
                            value: 2,
                            label: '企业资质'
                        },
                        {
                            value: 3,
                            label: '情况汇报'
                        },
                        {
                            value: 4,
                            label: '荣誉奖项'
                        },
                        {
                            value: 5,
                            label: '奖励补贴'
                        }
                    ]
                },
                {
                    FieldTitle: '立项时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '31%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'apply_date', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_date', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
            Info: {
                state: '结果确认',
                FlowModelName: "项目申报结果确认",
                TableInfo: [{
                    FieldTitle: '申报结果', //字段标题
                    fieldName: 'result', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: [
                        { value: 1, label: "通过" },
                        { value: 2, label: "未通过" },
                    ]
                }, {
                    FieldTitle: '认定有效期', //字段标题
                    fieldName: 'validity', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '资金额度(元)', //字段标题
                    fieldName: 'get_money', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '资金申请时间', //字段标题
                    fieldName: 'apply_time', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '获证清单', //字段标题
                    fieldName: 'cer_list', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }, {
                    FieldTitle: '原因', //字段标题
                    fieldName: 'cer_list', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '附件', //字段标题
                    fieldName: 'enclosure', //字段名称
                    fieldType: '附件',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                },
                ],
                FirstInfo: []
            },
            form: {},
            FlowchartKey: 1,
            modal_Visible: false
        }
    }

    NewForm = React.createRef();

    // componentDidMount() {
    //     this.get_TechnologyApplicationList()
    // }

    componentDidMount() {
        this.get_TechYear()
    }

    get_TechYear = () => {
        let { treeData, selectedKeys, expandedKeys, year } = this.state
        getTechYear().then(
            (res) => {
                if (!res.code) {
                    treeData = []
                    let item = {
                        title: '四川天艺生态园林集团股份有限公司',
                        key: 0,
                        icon: <FolderIcon />,
                        children: []
                    }

                    for (let key in res.data) {
                        let value = {
                            title: res.data[key] + '年',
                            key: res.data[key],
                            icon: <FoldersIcon />,
                        }
                        item.children = [...item.children, value]
                    }

                    treeData = [...treeData, item]

                    if (!year) {
                        selectedKeys = []
                        expandedKeys = [0]
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        year = em.key
                                        selectedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        year,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_TechnologyApplicationList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_TechnologyApplicationList = () => {
        let { year, pro_name, pro_class, apply_date, end_date, dataSource, pages, process_state } = this.state
        if (!year) {
            message.error('请选择年份')
            return
        }

        let data = {
            year: year,
            pro_name: pro_name,
            pro_class: pro_class,
            apply_date: apply_date,
            end_date: end_date,
            queryCriteria: 'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        TechnologyApplicationList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.technology.length) {
                        res.data.technology.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                pro_name: item.pro_name,
                                pro_class: item.pro_class === 1 ? '课题项目' : item.pro_class === 2 ? '企业资质' : item.pro_class === 3 ? '情况汇报' : item.pro_class === 4 ? '荣誉奖项' : '奖励补贴',
                                time: [item.apply_date, item.end_date],
                                dec_money: item.dec_money,
                                contract_status: item.contract_status,
                                confirm_status: item.confirm_status,
                                confirm_result: item.confirm_result,
                                status: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        dataSource,
                        pages: {
                            page: Number(res.data.pages.page),
                            limit: Number(res.data.pages.limit),
                            total: res.data.pages.total,
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        let value = '/scientific_research/project_application/NewItem'
        let CachingKeys = getCachingKeys()
        if (CachingKeys.length) {
            CachingKeys.forEach((item, index) => {
                if (item === value) {
                    dropByCacheKey(item)
                }
            })
        }
        setTimeout(() => {
            this.props.history.push(value)
        });
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/scientific_research/project_application/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    get_ConApprovalList(tableId) {
        sessionStorage.setItem('technologyId', tableId)
        this.props.history.push('/scientific_research/project_application/ConApprovalList')
        // this.props.history.push({
        //     pathname: '/scientific_research/project_application/ConApprovalList',
        //     query: {
        //         'technology_id': tableId,
        //     }
        // })
    }

    get_ConApprovalNewItem(tableId) {
        sessionStorage.setItem('technologyId', tableId)
        this.props.history.push('/scientific_research/project_application/ConApprovalNewItem')
        // this.props.history.push({
        //     pathname: '/scientific_research/project_application/ConApprovalNewItem',
        //     query: {
        //         'technology_id': tableId,
        //     }
        // })
    }

    handleStatistical = () => {
        this.props.history.push('/project/build/Summary')
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            pro_name: '',
            pro_class: '',
            apply_date: '',
            end_date: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_TechnologyApplicationList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pro_class: value.pro_class,
            apply_date: value.apply_date ? value.apply_date.format('YYYY-MM-DD') : '',
            end_date: value.end_date ? value.end_date.format('YYYY-MM-DD') : '',
        }, () => {
            this.get_TechnologyApplicationList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_TechnologyApplicationList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            year: value[0],
        }), () => {
            this.get_TechnologyApplicationList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_TechnologyApplicationList()
        })
    }

    get_result = (tableId) => {
        let { form } = this.state
        form.tech_id = tableId
        this.setState({
            modal_Visible: true,
            form,
            FlowchartKey: Math.random(),
        })
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        }))
    }

    handPresent = (data, status) => {
        const { form } = this.state;
        form.result = data.result === 1 ? '通过' : '未通过'
        form.validity = data.validity
        form.get_money = data.get_money
        form.apply_time = data.apply_time ? data.apply_time.format('YYYY-MM-DD') : ''
        form.cer_list = data.cer_list

        technologyConfirms(form).then(
            (res) => {
                if (!res.code) {
                    message.success('保存成功')
                    this.modalCancel()
                    this.get_TechnologyApplicationList()
                } else {
                    message.error('保存失败')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false,
            form: {}
        })
    }

    handleSelect = (value) => {
        const { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                item.isShow = false
                if (item.FieldTitle === '原因') {
                    item.isShow = true
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '申报结果' || item.FieldTitle === '原因' || item.FieldTitle === '附件') {
                    item.isShow = false
                } else {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, treeData, expandedKeys, selectedKeys, Info, FlowchartKey, modal_Visible } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <Operate notPrint={true} educe={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
                    }

                    <Append key={FlowchartKey} ref={this.NewForm} Info={Info} modal_Visible={modal_Visible} fileEvent={this.handFile} presentEvent={this.handPresent} cancelEvent={this.modalCancel} selectorEvent={this.handleSelect} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
