import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { selectAvailableSeal } from "../../../utils/public"
import { getMarginAuxiliary, saveMarginApplication, biddingMarginDetail, getBiddingTable } from "../../../utils/market"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/marginPayment.docx',
                templateName: '四川天艺生态园林集团股份有限公司投标保证金支付申请',
                tempData: {
                    apply_number: '',
                    apply_date: '',
                    apply_user: '',
                    is_seal: '',
                    pro_name: '',
                    seal_name: '',
                    margin_amount: '',
                    commission:'',
                    account_name: '',
                    bank_deposit: '',
                    bank_account: '',
                    info: '',
                    results: []
                },
                FlowModelName: "投标保证金支付申请",
                TableInfo: [{
                    FieldTitle: '申请编号', //字段标题
                    fieldName: 'apply_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '申请日期', //字段标题
                    fieldName: 'apply_day', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择申请日期', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'apply_user', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                }, {
                    FieldTitle: '是否盖章', //字段标题
                    fieldName: 'is_seal', //字段名称
                    fieldType: '单选',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: true, //字段是否必填
                    required: '请选择是否盖章', //必填要求
                    value: '',
                    width: '50%',
                    radioList: [
                        { value: 1, label: "是" },
                        { value: 2, label: "否" },
                    ],
                }, {
                    FieldTitle: '选择印章', //字段标题
                    fieldType: '按钮',  //字段类型
                    isShow: true
                }, {
                    FieldTitle: '印章', //字段标题
                    fieldName: 'seal_ids', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                    isShow: true
                }, {
                    FieldTitle: '投标保证金台账状态', //字段标题
                    fieldName: 'book_status', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '投标申请编号', //字段标题
                    fieldName: 'bidding_apply_number', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '(自动编号)',
                    width: '50%'
                }, {
                    FieldTitle: '关联申请审批单', //字段标题
                    fieldType: '按钮',  //字段类型
                }, {
                    FieldTitle: '申请审批单据', //字段标题
                    fieldName: 'bidd_id', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    search: true,
                }, {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'pro_name', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '项目地址', //字段标题
                    fieldName: 'pro_address', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '建设单位(招标人)', //字段标题
                    fieldName: 'con_unit', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%'
                }, {
                    FieldTitle: '招标代理', //字段标题
                    fieldName: 'bidding_agency', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '开标时间', //字段标题
                    fieldName: 'opening_time', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '保证金到账截止时间', //字段标题
                    fieldName: 'payment_date', //字段名称
                    fieldType: '日期',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                }, {
                    FieldTitle: '缴纳方式', //字段标题
                    fieldName: 'payment_method', //字段名称
                    fieldType: '选择器',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%',
                    selectList: []
                }, {
                    FieldTitle: '缴费金额(元)', //字段标题
                    fieldName: 'margin_amount', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '50%'
                },
                {
                    FieldTitle: '保函手续费(元)', //字段标题
                    fieldName: 'commission', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    placeholder: '500元-1000元',
                    value: '',
                    width: '50%',
                    isShow: true
                },
                {
                    FieldTitle: '资质要求', //字段标题
                    fieldName: 'qua_require', //字段名称
                    fieldType: '默认',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                },
                ],
                FirstInfo: [
                    {
                        plateName: '需转出到账户信息',
                        list: [
                            {
                                FieldTitle: '账户名', //字段标题
                                fieldName: 'account_name', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入账户名', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '账户开户行', //字段标题
                                fieldName: 'bank_deposit', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入账户开户行', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '账号', //字段标题
                                fieldName: 'bank_account', //字段名称
                                fieldType: '文本',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: true, //字段是否必填
                                required: '请输入账号', //必填要求
                                value: '',
                                width: '50%'
                            }, {
                                FieldTitle: '备注', //字段标题
                                fieldName: 'remarks', //字段名称
                                fieldType: '文本域',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                            {
                                FieldTitle: '附件', //字段标题
                                fieldName: 'attachment', //字段名称
                                fieldType: '附件',  //字段类型
                                disabled: false,  //字段是否禁用
                                mandatory: false, //字段是否必填
                                required: '', //必填要求
                                value: '',
                                width: '100%'
                            },
                        ]
                    }
                ],
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                }
            },
            modal_Visible: false,
            dataSource: [],
            columns: [],
            TableInfo: [],
            buttonTitle: '',
            apply_user: '',
            pro_name: '',
            seal_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: false
        }

    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_MarginAuxiliary()
    }

    get_MarginAuxiliary = () => {
        const { Info } = this.state;
        getMarginAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '缴纳方式') {
                            item.selectList = []
                            for (let key in res.data.payment_method) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.payment_method[key]
                                }
                                item.selectList = [...item.selectList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_MarginDetail(tableId)
                        } else {
                            this.get_userInfo()
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const { Info } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '申请人') {
                item.value = userInfo.name
            }
        })

        Info.state = 1
        this.setState(preState => ({
            Info
        })
        )
    }

    get_MarginDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        biddingMarginDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.seal_ids = JSON.parse(res.data.Detail.seal_ids)
                        form.apply_number = res.data.Detail.apply_number
                        form.bidding_apply_number = res.data.Detail.bidding_apply_number
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        if (res.data.Detail.status === 4) {
                            Info.tempData.apply_number = res.data.Detail.apply_number
                            Info.tempData.apply_date = res.data.Detail.apply_day
                            Info.tempData.apply_user = res.data.Detail.apply_username
                            Info.tempData.is_seal = res.data.Detail.is_seal === 1 ? '是' : '否'
                            Info.tempData.pro_name = res.data.Detail.bidds.pro_name
                            Info.tempData.seal_name = res.data.Detail.sealNames || '/'
                            Info.tempData.margin_amount = res.data.Detail.margin_amount
                            Info.tempData.commission = res.data.Detail.commission
                            Info.tempData.account_name = res.data.Detail.account_name
                            Info.tempData.bank_deposit = res.data.Detail.bank_deposit
                            Info.tempData.bank_account = res.data.Detail.bank_account
                            Info.tempData.info = res.data.Detail.remarks

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })

                        }

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '关联申请审批单') {
                                item.isShow = true
                            }else if (item.FieldTitle === '选择印章') {
                                item.isShow = true
                            }

                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    if (res.data.Detail.payment_method === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '保函手续费(元)') {
                                item.isShow = false
                            }
                        })
                    }

                    if (res.data.Detail.editPermission) {
                        Info.state = 10

                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = false

                            if (item.FieldTitle === '关联申请审批单') {
                                item.isShow = false
                            }else if (item.FieldTitle === '选择印章') {
                                item.isShow = false
                            }

                        })

                        Info.FirstInfo[0].list.forEach((item, index) => {
                            item.disabled = false

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = false
                                }
                            }
                        })
                    }

                    form.bidd_id = res.data.Detail.bidd_id

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '申请人') {
                            item.value = res.data.Detail.apply_username
                        } else if (item.FieldTitle === '申请编号') {
                            item.value = res.data.Detail.apply_number
                        } else if (item.FieldTitle === '投标申请编号') {
                            item.value = res.data.Detail.bidding_apply_number
                        } else if (item.FieldTitle === '申请审批单据') {
                            item.value = res.data.Detail.bidd_id
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.bidds.pro_name
                        } else if (item.FieldTitle === '项目地址') {
                            item.value = res.data.Detail.bidds.pro_address
                        } else if (item.FieldTitle === '建设单位(招标人)') {
                            item.value = res.data.Detail.bidds.con_unit
                        } else if (item.FieldTitle === '招标代理') {
                            item.value = res.data.Detail.bidds.bidding_agency
                        } else if (item.FieldTitle === '开标时间') {
                            item.value = res.data.Detail.bidds.opening_time
                        } else if (item.FieldTitle === '资质要求') {
                            item.value = res.data.Detail.bidds.qua_require
                        }
                    })

                    Info.FirstInfo[0].list.forEach((item, index) => {
                        if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.FieldTitle === '印章') {
                                item.isShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    this.NewForm.current.formRef.current.setFieldsValue({
                        apply_day: res.data.Detail.apply_day ? moment(res.data.Detail.apply_day, 'YYYY-MM-DD') : '',
                        is_seal: res.data.Detail.is_seal,
                        book_status: res.data.Detail.book_status,
                        payment_date: res.data.Detail.payment_date ? moment(res.data.Detail.payment_date, 'YYYY-MM-DD') : '',
                        payment_method: res.data.Detail.payment_method,
                        margin_amount: res.data.Detail.margin_amount,
                        commission: res.data.Detail.commission,
                        account_name: res.data.Detail.account_name,
                        bank_deposit: res.data.Detail.bank_deposit,
                        bank_account: res.data.Detail.bank_account,
                        remarks: res.data.Detail.remarks,
                        attachment: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_day = data.apply_day ? data.apply_day.format('YYYY-MM-DD') : ''
        form.is_seal = data.is_seal
        // form.seal_ids = data.is_seal === 1 ? JSON.stringify(form.seal_ids) : ''
        form.book_status = data.book_status
        form.payment_date = data.payment_date ? data.payment_date.format('YYYY-MM-DD') : ''
        form.payment_method = data.payment_method
        form.margin_amount = data.margin_amount
        form.commission = data.commission
        form.account_name = data.account_name
        form.bank_deposit = data.bank_deposit
        form.bank_account = data.bank_account
        form.remarks = data.remarks

        saveMarginApplication(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BiddingTable = () => {
        let { dataSource, pages, apply_user, pro_name } = this.state
        let data = {
            apply_day: '',
            apply_user: apply_user,
            eng_number: '',
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        getBiddingTable(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.bidding.length) {
                        res.data.bidding.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                apply_day: item.apply_day,
                                apply_user: item.username,
                                eng_number: item.eng_number,
                                pro_name: item.pro_name,
                                pro_address: item.pro_address,
                                con_unit: item.con_unit,
                                opening_time: item.opening_time,
                                bidding_agency: item.bidding_agency,
                                qua_require: item.qua_require,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { dataSource, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                key: item.table_id,
                                name: item.name,
                            }
                            dataSource = [...dataSource, seal]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        let { columns, TableInfo, radio } = this.state
        if (state) {
            if (title === '关联申请审批单') {
                columns = [
                    {
                        title: '申请日期',
                        dataIndex: 'apply_day',
                        key: 'apply_day',
                    },
                    {
                        title: '申请人',
                        dataIndex: 'apply_user',
                        key: 'apply_user',
                    },
                    {
                        title: '工程编号',
                        dataIndex: 'eng_number',
                        key: 'eng_number',
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'pro_name',
                        key: 'pro_name',
                    },
                ]
                TableInfo = [
                    {
                        FieldTitle: '申请人', //字段标题
                        fieldName: 'apply_user', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'pro_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '48%',
                    }
                ]
                this.get_BiddingTable()
                radio = true
            } else {
                columns = [
                    {
                        title: '印章名称',
                        dataIndex: 'name',
                        key: 'name',
                    }
                ]
                TableInfo = [
                    {
                        FieldTitle: '印章名称', //字段标题
                        fieldName: 'seal_name', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '95%',
                    }
                ]
                this.get_seal()
                radio = false
            }
            this.setState(preState => ({
                buttonTitle: title,
                columns,
                TableInfo,
                radio
            })
            )
        } else {
            this.setState(preState => ({
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
            }))
        }
    }

    handleRadio = (value) => {
        let { Info } = this.state;
        if (value === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = false
                } else if (item.FieldTitle === '印章') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '选择印章') {
                    item.isShow = true
                } else if (item.FieldTitle === '印章') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        }))
    }

    handleSelect = (value) => {
        console.log(value)
        const { Info } = this.state;
        if (value === 3) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '保函手续费(元)') {
                    item.isShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '保函手续费(元)') {
                    item.isShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handSelect = (value) => {
        const { Info, form, buttonTitle } = this.state;
        if (buttonTitle === '关联申请审批单') {
            form.bidd_id = value[0].key
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '申请审批单据') {
                    item.value = value[0].key
                } else if (item.FieldTitle === '项目名称') {
                    item.value = value[0].pro_name
                } else if (item.FieldTitle === '项目地址') {
                    item.value = value[0].pro_address
                } else if (item.FieldTitle === '建设单位(招标人)') {
                    item.value = value[0].con_unit
                } else if (item.FieldTitle === '招标代理') {
                    item.value = value[0].bidding_agency
                } else if (item.FieldTitle === '开标时间') {
                    item.value = value[0].opening_time
                } else if (item.FieldTitle === '资质要求') {
                    item.value = value[0].qua_require
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '印章') {
                    item.value = ''
                    form.seal_ids = []
                    value.forEach((em, ex) => {
                        item.value = item.value + ' ' + em.name
                        form.seal_id = [...form.seal_id, em.key]
                    })
                    form.seal_ids = JSON.stringify(form.seal_id)
                }
            })
        }

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )

    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[0].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    searchQuery = (value) => {
        let { buttonTitle } = this.state
        if (buttonTitle === '关联申请审批单') {
            this.setState({
                apply_user: value.apply_user,
                pro_name: value.pro_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_BiddingTable()
            })
        } else {
            this.setState({
                seal_name: value.seal_name,
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
            }, () => {
                this.get_seal()
            })
        }
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            apply_user: '',
            pro_name: '',
            seal_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '关联申请审批单') {
                this.get_BiddingTable()
            } else {
                this.get_seal()
            }
        })
    }

    handleSearch = () => {
        let { form } = this.state
        if (!form.bidd_id) {
            message.error('未关联申请审批单!')
            return
        }
        this.props.history.push({
            pathname: '/market/application/NewItem',
            query: {
                'tableId': form.bidd_id,
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} radioEvent={this.handleRadio} selectorEvent={this.handleSelect} auditEvent={this.handleButton} searchEvent={this.handleSearch} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} radio={radio} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
