import React, { Component } from 'react'
import { Input, Form, message } from 'antd';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createAssessment, assessmentDetailDetail } from "../../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
	state = {
		Info: {
			state: '',
			FlowModelName: "考核记录",
			TableInfo: [{
				FieldTitle: '年度', //字段标题
				fieldName: 'year', //字段名称
				fieldType: '选择器', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请选择年度', //必填要求
				value: '',
				width: '50%',
				selectList: [{
					value: 2022,
					label: "2022年"
				},
				{
					value: 2023,
					label: "2023年"
				},
				{
					value: 2024,
					label: "2024年"
				},
				{
					value: 2025,
					label: "2025年"
				},
				{
					value: 2026,
					label: "2026年"
				},
				{
					value: 2027,
					label: "2027年"
				},
				{
					value: 2028,
					label: "2028年"
				},
				{
					value: 2029,
					label: "2029年"
				},
				{
					value: 2030,
					label: "2030年"
				},
				]
			}, {
				FieldTitle: '月份', //字段标题
				fieldName: 'month', //字段名称
				fieldType: '选择器', //字段类型
				disabled: false, //字段是否禁用
				mandatory: true, //字段是否必填
				required: '请选择月份', //必填要求
				value: '',
				width: '50%',
				selectList: [{
					value: 1,
					label: "1月"
				},
				{
					value: 2,
					label: "2月"
				},
				{
					value: 3,
					label: "3月"
				},
				{
					value: 4,
					label: "4月"
				},
				{
					value: 5,
					label: "5月"
				},
				{
					value: 6,
					label: "6月"
				},
				{
					value: 7,
					label: "7月"
				},
				{
					value: 8,
					label: "8月"
				},
				{
					value: 9,
					label: "9月"
				},
				{
					value: 10,
					label: "10月"
				},
				{
					value: 11,
					label: "11月"
				},
				{
					value: 12,
					label: "12月"
				},
				]
			},],
			FirstInfo: [{
				plateName: '考核记录',
				list: [],
				editable: {
					dataSource: [],
					columns: [
						{
							title: '序号',
							dataIndex: 'id',
							key: 'id',
							width: '80px',
							render: (text, record, index) => {
								return (
									<div>{index + 1}</div>
								)
							}
						},
						{
							title: '操作',
							dataIndex: 'controls',
							key: 'controls',
							width: '100px',
							render: (text, record, index) => {
								return (
									<div className='download' onClick={() => {
										this.handleDel(index, record.key)
									}}>
										删除
									</div>
								)
							}
						},
						{
							title: '姓名',
							dataIndex: 'name',
							key: 'name',
							width: '120px'
						},
						{
							title: '部门',
							dataIndex: 'deName',
							key: 'deName',
							width: '120px'
						},
						{
							title: '一',
							dataIndex: 'one',
							key: 'one',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'one' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'one' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '二',
							dataIndex: 'two',
							key: 'two',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'two' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'two' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '三',
							dataIndex: 'three',
							key: 'three',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'three' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'three' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '四',
							dataIndex: 'four',
							key: 'four',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'four' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'four' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
						{
							title: '考核说明',
							dataIndex: 'matter',
							key: 'matter',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'matter' + record.key} key={index} initialValue={text}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'matter' + record.key} key={index} initialValue={text}>
											<Input disabled />
										</Form.Item>
									)
								}
							}
						},
					]
				},
			}],
			process: {
				reviewAudit: [],
				ccReading: [],
				audit: false,
				edit: false
			}
		},
		modal_Visible: false,
		dataSource: [],
		columns: [{
			title: '姓名',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: '性别',
			dataIndex: 'sex',
			key: 'sex',
			render: (text, index) => {
				if (text === '1') {
					return (<span > 男 </span>
					)
				} else {
					return (<span > 女 </span>)
				}
			}
		},
		{
			title: '部门名称',
			dataIndex: 'deName',
			key: 'deName',
		},
		{
			title: '职位',
			dataIndex: 'company_job',
			key: 'company_job',
		},
		{
			title: '手机',
			dataIndex: 'phone',
			key: 'phone',
		},
		],
		TableInfo: [{
			FieldTitle: '姓名', //字段标题
			fieldName: 'username', //字段名称
			fieldType: '文本', //字段类型
			disabled: false, //字段是否禁用
			mandatory: false, //字段是否必填
			required: '', //必填要求
			value: '',
			width: '48%',
		}, {
			FieldTitle: '部门', //字段标题
			fieldName: 'de_name', //字段名称
			fieldType: '文本', //字段类型
			disabled: false, //字段是否禁用
			mandatory: false, //字段是否必填
			required: '', //必填要求
			value: '',
			width: '48%',
		}],
		username: '',
		de_name: '',
		pages: {
			page: 1,
			total: 0,
			limit: 10,
		},
		form: {}
	}

	NewForm = React.createRef();

	componentDidMount() {
		if (this.props.location.query) {
			let tableId = this.props.location.query.tableId
			this.get_assessmentDetail(tableId)
		} else {
			this.get_FormState()
		}
	}

	get_FormState = () => {
		const { Info } = this.state;
		Info.state = 1
		this.setState(preState => ({
			Info
		}))
	}

	get_assessmentDetail = (tableId) => {
		const { Info, form } = this.state;
		let data = {
			table_id: tableId
		}
		assessmentDetailDetail(data).then(
			(res) => {
				if (!res.code) {
					if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
						form.table_id = tableId
						Info.process.edit = res.data.Detail.editPermission
					} else {
						Info.TableInfo.forEach((item, index) => {
							item.disabled = true
						})

						Info.FirstInfo[0].editable.columns = [
							{
								title: '序号',
								dataIndex: 'id',
								key: 'id',
								width: '80px',
								render: (text, record, index) => {
									return (
										<div>{index + 1}</div>
									)
								}
							},
							{
								title: '姓名',
								dataIndex: 'name',
								key: 'name',
								width: '120px'
							},
							{
								title: '部门',
								dataIndex: 'deName',
								key: 'deName',
								width: '120px'
							},
							{
								title: '一',
								dataIndex: 'one',
								key: 'one',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'one' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'one' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '二',
								dataIndex: 'two',
								key: 'two',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'two' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'two' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '三',
								dataIndex: 'three',
								key: 'three',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'three' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'three' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '四',
								dataIndex: 'four',
								key: 'four',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'four' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'four' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
							{
								title: '考核说明',
								dataIndex: 'matter',
								key: 'matter',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'matter' + record.key} key={index} initialValue={text}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'matter' + record.key} key={index} initialValue={text}>
												<Input disabled />
											</Form.Item>
										)
									}
								}
							},
						]
					}

					Info.state = res.data.Detail.status
					Info.process.data = res.data.Detail.reviewAudit
					Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
					Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
					Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

					this.NewForm.current.formRef.current.setFieldsValue({
						year: res.data.Detail.details[0].year,
						month: res.data.Detail.details[0].month,
					})

					form.content = []

					res.data.Detail.details.forEach((item, index) => {
						item.content = JSON.parse(item.content)
						let key = Info.FirstInfo[0].editable.dataSource.length + 1
						const newData = {
							key: key,
							user_id: item.user_id,
							name: item.username,
							deName: item.deName,
							one: item.content.one,
							two: item.content.two,
							three: item.content.three,
							four: item.content.four,
							matter: item.matter,
						};
						this.NewForm.current.formRef.current.setFieldsValue({
							[(() => 'one' + key)()]: item.content.one,
							[(() => 'two' + key)()]: item.content.two,
							[(() => 'three' + key)()]: item.content.three,
							[(() => 'four' + key)()]: item.content.four,
							[(() => 'matter' + key)()]: item.matter,
						})
						form.content = [...form.content, newData]
						Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
					})

					this.setState(preState => ({
						Info,
						form
					}))
				} else {
					message.error(res.data[0] || '数据获取失败!')
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handPresent = (data, status) => {
		const { form, Info } = this.state;
		form.status = status
		form.year = data.year
		form.month = data.month
		form.content.forEach((item, index) => {
			index++
			if (!item.matter) {
				item.matter = data['matter' + index] ? data['matter' + index] : ''
				item.one = data['one' + index] ? data['one' + index] : ''
				item.two = data['two' + index] ? data['two' + index] : ''
				item.three = data['three' + index] ? data['three' + index] : ''
				item.four = data['four' + index] ? data['four' + index] : ''
			}
		})

		form.content = JSON.stringify(form.content)

		createAssessment(form).then(
			(res) => {
				if (!res.code) {
					message.success(form.status === 1 ? '暂存成功' : '提交成功')
					// if (form.status === 2) {
					// 	setTimeout(() => {
					// 		window.history.back(-1)
					// 	})
					// }
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '提交失败,请检查表单!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handleWorkAdd = (name) => {
		this.get_InitialValue()
	}

	get_InitialValue = () => {
		let {
			dataSource,
			pages,
			username,
			de_name
		} = this.state
		let data = {
			username: username,
			de_name: de_name,
			page: Number(pages.page),
			limit: Number(pages.limit)
		}
		selectUserName(data).then(
			(res) => {
				if (!res.code) {
					dataSource = []
					if (res.data.users.length) {
						res.data.users.forEach((item, index) => {
							let user = {
								key: item.table_id,
								username: item.username,
								sex: item.sex,
								deName: item.deName,
								company_job: item.jobName,
								phone: item.phone
							}
							dataSource = [...dataSource, user]
						})
					}
					this.setState(preState => ({
						dataSource,
						pages: res.data.pages,
						modal_Visible: true,
					}))
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handButton = (title, state) => {
		if (state) {
			this.get_InitialValue()
		} else {
			this.setState(preState => ({
				dataSource: [],
				pages: {
					page: 1,
					total: 0,
					limit: 10,
				},
				modal_Visible: false,
				workAdd: false
			}))
		}
	}

	handSelect = (value) => {
		const {
			Info,
			form
		} = this.state;

		if (Info.FirstInfo[0].editable.dataSource.length) {

			Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
				value.forEach((em, ex) => {
					if (em.key === item.user_id) {
						value.splice(ex, 1)
					}
				})
			})

			value.forEach((item, index) => {
				const newData = {
					key: Info.FirstInfo[0].editable.dataSource.length + 1,
					user_id: item.key,
					name: item.username,
					deName: item.deName,
					one: '',
					two: '',
					three: '',
					four: '',
					matter: '',
				};
				Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
			})

		} else {
			value.forEach((item, index) => {
				const newData = {
					key: Info.FirstInfo[0].editable.dataSource.length + 1,
					user_id: item.key,
					name: item.username,
					deName: item.deName,
					one: '',
					two: '',
					three: '',
					four: '',
					matter: '',
				};
				Info.FirstInfo[0].editable.dataSource = [...Info.FirstInfo[0].editable.dataSource, newData]
			})
		}

		form.content = []

		Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
			let value = {
				user_id: item.user_id
			}
			form.content.push(value)
		})

		this.setState(preState => ({
			Info,
			form,
			dataSource: [],
			pages: {
				page: 1,
				total: 0,
				limit: 10,
			},
			modal_Visible: false,
		}))
	}

	searchQuery = (value) => {
		this.setState({
			username: value.username,
			de_name: value.de_name,
			pages: {
				page: 1,
				total: 0,
				limit: 10,
			},
		}, () => {
			this.get_InitialValue()
		})
	}

	handleCancel = () => {
		this.setState({
			modal_Visible: false,
			username: '',
			de_name: '',
			pages: {
				limit: 10,
				page: 1,
				total: 0
			},
		})
	}

	handleButton = (type, value) => {
		let {
			Info
		} = this.state
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		let data = {
			tableName: '',
			table_id: '',
			reviewResult: type,
			opinion: value,
			review_id: '',
		}

		Info.process.reviewAudit.forEach((item, index) => {
			if (item.user_id === userInfo.userId) {
				data.tableName = item.table_name
				data.table_id = item.table_id
				data.review_id = item.id
			}
		})
		reviewApplyResult(data).then(
			(res) => {
				if (!res.code) {
					message.success('审核成功!')
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '审核失败!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handleLimit = (page, limit) => {
		let {
			pages
		} = this.state
		pages.page = page
		pages.limit = limit
		this.setState({
			pages
		}, () => {
			this.get_InitialValue()
		})
	}

	handleDel = (key, e) => {
		let { Info } = this.state
		let dataSource = []
		this.NewForm.current.formRef.current.setFieldsValue({
			[(() => 'one' + e)()]: '',
			[(() => 'two' + e)()]: '',
			[(() => 'three' + e)()]: '',
			[(() => 'four' + e)()]: '',
			[(() => 'matter' + e)()]: '',
		})
		Info.FirstInfo[0].editable.dataSource.forEach((item, index) => {
			if (index !== key) {
				if (index > key) {
					item.key = item.key - 1
				}
				dataSource = [...dataSource, item]
			}
		})
		Info.FirstInfo[0].editable.dataSource = dataSource
		this.setState(preState => ({
			Info,
		})
		)
	}

	render() {
		let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
		return (
			<div className='configAdd' >
				<AddConfig ref={this.NewForm} Info={Info}
					presentEvent={
						this.handPresent
					}
					rowAddEvent={
						this.handleWorkAdd
					}
					auditEvent={
						this.handleButton
					}
				/>

				<InitialValue modal_Visible={modal_Visible}
					dataSource={dataSource}
					pages={pages}
					columns={columns}
					TableInfo={TableInfo}
					buttonEvent={
						this.handButton
					}
					searchEvent={
						this.searchQuery
					}
					cancelEvent={
						this.handleCancel
					}
					selectEvent={
						this.handSelect
					}
					limitEvent={
						this.handleLimit
					}
				/>
			</div>
		)
	}
}
