import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { userWeeklyLookList, userReadWeekly } from "../../utils/index"
import Operate from '../components/Operate'
import Search from '../components/Search';
import Table from '../components/Table'

class weekly extends Component {
    state = {
        searchShow: false,
        classType: 1,
        columns: [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                render: (text, record, index) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            this.get_details(record.table_id)
                        }}>{text}</div>
                    )
                }
            },
            {
                title: '申请日期',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '姓名',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: '部门名称',
                dataIndex: 'deName',
                key: 'deName',
            },
            {
                title: '本周完成工作',
                dataIndex: 'over_content',
                key: 'over_content',
                width: '300px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '下周工作计划',
                dataIndex: 'next_week',
                key: 'next_week',
                width: '300px',
                render: (text, record, index) => {
                    return (
                        <div title={text} style={{ width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
                    )
                }
            },
            {
                title: '已读/未读',
                dataIndex: 'is_read',
                key: 'is_read',
                render: (text, index) => {
                    if (text === 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ backgroundColor: '#F53500', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></div>
                                <div>未读</div>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ backgroundColor: '#00DC9E', width: '16px', height: '16px', borderRadius: '50%', marginRight: '16px' }}></span>
                                <span>已读</span>
                            </div>
                        )
                    }
                }
            }
        ],
        dataSource: [],
        TableInfo: [
            {
                FieldTitle: '姓名', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '24%',
            },
            {
                FieldTitle: '部门', //字段标题
                fieldName: 'de_name', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '24%',
            },
            {
                FieldTitle: '日期', //字段标题
                fieldName: 'time', //字段名称
                fieldType: '日期区间',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '48%',
                child: [
                    {
                        FieldTitle: '开始时间', //字段标题
                        fieldName: 'start', //字段名称
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '结束时间', //字段标题
                        fieldName: 'end', //字段名称
                        value: '',
                        width: '50%',
                    },
                ]
            },
        ],
        start: '',
        end: '',
        username: '',
        de_name: '',
        pages: {
            limit: 8,
            page: 1,
            total: 0
        }
    }

    componentDidMount() {
        this.get_userWeeklyLookList()
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/human/weekly/NewItem',
            query: {
                'tableId': tableId,
            }
        })
        this.handleWeekly(tableId)
    }

    get_userWeeklyLookList = () => {
        let { start, end, username, de_name, pages, dataSource, classType } = this.state
        let data = {
            start: start,
            end: end,
            username: username,
            de_name: de_name,
            type: classType,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        userWeeklyLookList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.weekly.length) {
                        res.data.weekly.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                title: item.applyUserName + '的周报',
                                created_at: item.created_at,
                                username: item.applyUserName,
                                deName: item.deName,
                                pro_name: item.pro_name,
                                over_content: item.over_content,
                                next_week: item.next_week,
                                is_read: item.status,
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWeekly = (tableId) => {
        let data = {
            table_id: tableId
        }
        userReadWeekly(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            start: '',
            end: '',
            username: '',
            de_name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_userWeeklyLookList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : '',
            username: value.username,
            de_name: value.de_name,
        }, () => {
            this.get_userWeeklyLookList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_userWeeklyLookList()
        })
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
			pages: {
			    limit: 8,
			    page: 1,
			    total: 0
			}
        }), () => {
            this.get_userWeeklyLookList()
        })
    }

    render() {
        return (
            <div>
                <div className='labor_search'>
                    <div className='cost_budgeting'>
                        <Operate dontAdd={true} dontImport={true} notPrint={true} educe={true} searchEvent={this.handleSearch} searchShow={this.state.searchShow} />
                    </div>

                    <div className='supplier_right employeesType'>
                        <div className={this.state.classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>已读</div>
                        <div className={this.state.classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>未读</div>
                    </div>
                </div>


                {
                    this.state.searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={this.state.TableInfo} type="close" /></div> : ''
                }

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(weekly)
