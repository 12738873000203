import http from '../utils/http';

//添加编辑文件夹
function createFileManager(data) {
    return http("post", '/api/createFileManager', data);
}

//添加文件夹文件
function addFileUpload(data) {
    return http("post", '/api/addFileUpload', data);
}

//集采文件管理列表
function fileList(data) {
    return http("get", '/api/fileList', data);
}

//删除文件
function deleteFile(data) {
    return http("post", '/api/deleteFile', data);
}

//删除文件夹
function deleteFolder(data) {
    return http("post", '/api/deleteFolder', data);
}

//导入人材机总库数据表
function ImportGeneralInventory(data) {
    return http("post", '/api/ImportGeneralInventory', data);
}

//人材机总库列表
function GeneralInventoryList(data) {
    return http("get", '/api/GeneralInventoryList', data);
}

//编辑人材机总库材料
function EditGeneralInventory(data) {
    return http("get", '/api/EditGeneralInventory', data);
}

//供应商入库辅助数据
function supplierAuxiliary(data) {
    return http("get", '/api/supplierAuxiliary', data);
}


//供应商入库列表
function supplierList(data) {
    return http("get", '/api/supplierList', data);
}


//供应商查询
function supplierSelect(data) {
    return http("get", '/api/supplierSelect', data);
}


//供应商入库详情
function supplierDetail(data) {
    return http("get", '/api/supplierDetail', data);
}


//添加编辑供应商入库
function createSupplier(data) {
    return http("post", '/api/createSupplier', data);
}

//添加编辑优质供应商入库
function createExcellentSupplier(data) {
    return http("post", '/api/createExcellentSupplier', data);
}

//优质供应商入库列表
function excellentSupplierList(data) {
    return http("get", '/api/excellentSupplierList', data);
}

//优质供应商入库详情
function excellentSupplierDetail(data) {
    return http("get", '/api/excellentSupplierDetail', data);
}

//添加编辑供应商认价审批
function createSupConfirmPrice(data) {
    return http("post", '/api/createSupConfirmPrice', data);
}

//供应商认价审批列表
function SupConfirmPriceList(data) {
    return http("get", '/api/SupConfirmPriceList', data);
}

//供应商认价审批详情
function SupConfirmPriceDetail(data) {
    return http("get", '/api/SupConfirmPriceDetail', data);
}

//添加材料新价格
function addSupUpdatePrice(data) {
    return http("post", '/api/addSupUpdatePrice', data);
}

//查询关联材料
function aboutCostBudget(data) {
    return http("get", '/api/aboutCostBudget', data);
}

//项目下料单查询
function selectProjectPickList(data) {
    return http("get", '/api/selectProjectPickList', data);
}

//项目材料查询
function projectMacSelect(data) {
    return http("get", '/api/projectMacSelect', data);
}

//查询关联材料
function confPriceComparison(data) {
    return http("post", '/api/confPriceComparison', data);
}

//材料调价审批列表
function updateMacPriceList(data) {
    return http("get", '/api/updateMacPriceList', data);
}

//获取认价审批单材料详情
function getConfirmMacDetail(data) {
    return http("get", '/api/getConfirmMacDetail', data);
}

//查询材料调整价格
function selectMacChangePrice(data) {
    return http("get", '/api/selectMacChangePrice', data);
}

//材料调价审批详情
function updateMacPriceDetail(data) {
    return http("get", '/api/updateMacPriceDetail', data);
}

//添加材料调价审批
function updateMacPrice(data) {
    return http("post", '/api/updateMacPrice', data);
}

//添加编辑机操手
function createOperator(data) {
    return http("post", '/api/createOperator', data);
}


//获取机操手信息
function getOperatorDetail(data) {
    return http("get", '/api/getOperatorDetail', data);
}


//机操手列表
function operatorList(data) {
    return http("get", '/api/operatorList', data);
}

//添加编辑油卡信息
function createOilCard(data) {
    return http("post", '/api/createOilCard', data);
}

//油卡列表
function OilCardList(data) {
    return http("get", '/api/OilCardList', data);
}

//获取油卡信息
function getOilCardDetail(data) {
    return http("get", '/api/getOilCardDetail', data);
}

//添加编辑机械信息
function createCenPlant(data) {
    return http("post", '/api/createCenPlant', data);
}

//机械列表
function CenPlantList(data) {
    return http("get", '/api/CenPlantList', data);
}

//机械详情
function CenPlantDetail(data) {
    return http("get", '/api/CenPlantDetail', data);
}

//添加编辑机械调拨申请
function createCenRestructure(data) {
    return http("post", '/api/createCenRestructure', data);
}

//设备调拨列表
function CenRestructureList(data) {
    return http("get", '/api/CenRestructureList', data);
}

//设备调拨详情
function CenRestructureDetail(data) {
    return http("get", '/api/CenRestructureDetail', data);
}

//获取省市县区信息
function provinceCityCare(data) {
    return http("get", '/api/provinceCityCare', data);
}




export {
    createFileManager,
    addFileUpload,
    fileList,
    deleteFile,
    deleteFolder,
    ImportGeneralInventory,
    GeneralInventoryList,
    EditGeneralInventory,
    supplierAuxiliary,
    supplierList,
    supplierSelect,
    supplierDetail,
    createSupplier,
    createExcellentSupplier,
    excellentSupplierList,
    excellentSupplierDetail,
    createSupConfirmPrice,
    SupConfirmPriceList,
    SupConfirmPriceDetail,
    addSupUpdatePrice,
    aboutCostBudget,
    selectProjectPickList,
    projectMacSelect,
    confPriceComparison,
    updateMacPriceList,
    getConfirmMacDetail,
    selectMacChangePrice,
    updateMacPriceDetail,
    updateMacPrice,
    createOperator,
    getOperatorDetail,
    operatorList,
    createOilCard,
    OilCardList,
    getOilCardDetail,
    createCenPlant,
    CenPlantList,
    CenPlantDetail,
    createCenRestructure,
    CenRestructureList,
    CenRestructureDetail,
    provinceCityCare
}
