import React, { Component } from 'react'
import { Modal, Button, Input, Table, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { withRouter } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons';
import Operate from '../../../components/Operate'
import Tables from '../../../components/Table'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_Visible: false,
            dataSource: [
                {
                    key: '1',
                    title: '朗基香颂天府项目一期(1、3块地)景观绿化工程',
                    time: '2022-03-01',
                    name: '胡彦斌',
                    state: '已驳回'
                },
                {
                    key: '2',
                    title: '朗基香颂天府项目一期(1、3块地)景观绿化工程',
                    time: '2022-03-01',
                    name: '胡彦斌',
                    state: '审批中'
                },
                {
                    key: '3',
                    title: '朗基香颂天府项目一期(1、3块地)景观绿化工程',
                    time: '2022-03-01',
                    name: '胡彦斌',
                    state: '已完成'
                },
                {
                    key: '4',
                    title: '朗基香颂天府项目一期(1、3块地)景观绿化工程',
                    time: '2022-03-01',
                    name: '胡彦斌',
                    state: '已终止'
                },
            ],
            columns: [
                {
                    title: '材料名称',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '项目名称 & 采购单价（元）',
                    dataIndex: 'time',
                    key: 'time',
                },
            ],
            TableInfo: {
                dataSource: [
                    {
                        key: '1',
                        name: '朗基香颂天府项目一期(1、3块地)景观绿化工程',
                    },
                ],
                columns: [
                    {
                        title: '材料名称(可多选)',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ],
            }
        }
    }

    handleNew = () => {
        this.setState({
            modal_Visible: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    searchQuery = () => {
        console.log('筛选')
    }

    modalCancel = () => {
        this.setState({
            modal_Visible: false
        })
    }

    // selectedRowKeys表示所选择列的key是一个字符串,selectedRows表示所选列的信息，是一个数组
    rowSelectionChange = (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.rowSelectionChange(selectedRowKeys, selectedRows)
            },
        };
        return (
            <div>
                <div className='cost_budgeting'>
                    <div className='history ListOperate' style={{ marginRight: '15px', width: 'auto' }} >
                        <Button>
                            <div>认价对比筛选</div>
                        </Button>
                    </div>
                    <Operate dontImport={true} seek={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />
                </div>

                <Tables columns={this.state.columns} dataSource={this.state.dataSource} />

                <Modal
                    title='新增'
                    centered
                    visible={this.state.modal_Visible}
                    onCancel={() => this.modalCancel()}
                    wrapClassName='searchModal contrastNew'
                    footer={[]}
                >
                    <div className='formTitle' style={{ fontWeight: 'initial' }}>认价对比筛选</div>

                    <div>
                        <div>
                            <div>材料名称</div>
                            <Input type='text' />
                        </div>
                        <div className='handleAppend'>
                            <Button type="primary">搜索</Button>
                        </div>
                    </div>

                    <div>
                        <div>
                            <ConfigProvider locale={zhCN}>
                                <Table pagination={{
                                    total: 10, //数据的总条数
                                    defaultCurrent: 1,//默认当前的页数
                                    defaultPageSize: 8,//默认每页的条数,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["8", "10", "12", "15"],
                                    onChange: (page, pageSize) => {
                                        console.log(page, pageSize)
                                    }
                                }}
                                    rowClassName={(record, index) => {
                                        let className = 'light-row';
                                        if (index % 2 === 1) className = 'dark-row';
                                        return className;
                                    }}
                                    rowSelection={rowSelection}
                                    style={{ width: '100%' }}
                                    dataSource={this.state.TableInfo.dataSource}
                                    columns={this.state.TableInfo.columns}
                                />
                            </ConfigProvider>
                        </div>
                        <div className='handleAppend'>
                            <Button type="primary">添加</Button>
                        </div>
                    </div>

                    <div className='formTitle' style={{ fontWeight: 'initial' }}>认价对比筛选</div>

                    <div>
                        <div>
                            <div>
                                <div>休闲桌（E）（甲方选型）</div>
                                <CloseOutlined />
                            </div>

                            <div>
                                <div>定制椅子（F）（甲方选型）</div>
                                <CloseOutlined />
                            </div>

                            <div>
                                <div>P180红叶石楠球</div>
                                <CloseOutlined />
                            </div>

                            <div>
                                <div>P120滴水观音</div>
                                <CloseOutlined />
                            </div>

                            <div>
                                <div>满天星</div>
                                <CloseOutlined />
                            </div>
                        </div>
                        <div className='handleAppend'>
                            <Button type="primary">确定</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(List)
