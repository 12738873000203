import React, { Component } from 'react'
import axios from 'axios'
import { Select, Button, message } from 'antd';
import { CaretDownOutlined, BarChartOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Table from '../../components/Table'
import { ProjectCreateYear, SummaryProgressPayment, ExportSummaryProgress } from "../../../utils/cost"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            weekList: [],
            dataSource: [],
            columns: [
                {
                    title: ' 项目时间',
                    dataIndex: 'pro_create_day',
                    key: 'pro_create_day',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    dataIndex: 'proName',
                    key: 'proName',
                },
                {
                    title: '申报产值(元)',
                    dataIndex: 'mea_dec',
                    key: 'mea_dec',
                },
                {
                    title: '审定产值(元)',
                    dataIndex: 'metering',
                    key: 'metering',
                },
                {
                    title: '应付款(元)',
                    dataIndex: 'pay_money',
                    key: 'pay_money',
                },
            ],
            yearList: [],
            yearValue: [],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_ProjectCreateYear()
    }

    get_ProjectCreateYear = () => {
        let { yearList, yearValue } = this.state
        ProjectCreateYear().then(
            (res) => {
                if (!res.code) {
                    yearList = []
                    yearValue = []
                    res.data.forEach((item, index) => {
                        let value = {
                            value: item,
                            label: item + '年'
                        }
                        yearList = [...yearList, value]
                        yearValue = [...yearValue, item]
                    })
                    this.setState(preState => ({
                        yearList,
                        yearValue
                    }),()=>{
                        this.get_SummaryProgressPayment()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SummaryProgressPayment = () => {
        let { dataSource, pages, yearValue, } = this.state
        let data = {
            years: JSON.stringify(yearValue),
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        SummaryProgressPayment(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                proName: item.proName,
                                pro_create_day: item.pro_create_day,
                                pay_money: item.pay_money,
                                metering: item.metering,
                                mea_dec: item.mea_dec,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleExport = () => {
        let { yearValue } = this.state
        if (!yearValue.length) {
            message.error('请选择项目时间段!')
            return
        }
        let data = {
            years: JSON.stringify(yearValue),
        }
        axios.get('/api/ExportSummaryProgress', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("Token")
            },
            responseType: 'blob',
            params: data,
        }).then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            })

            const link = document.createElement('a');
            link.download = '工程进度款汇总数据.xlsx'
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            document.body.appendChild(link)
            link.click();
            //释放URL对象
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        })
    }

    yearChange = (value) => {
        this.setState({
            yearValue: value
        }, () => {
            this.get_SummaryProgressPayment()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => { this.get_SummaryProgressPayment() })
    }

    render() {
        let { dataSource, columns, yearValue, yearList, } = this.state
        return (
            <div>
                <div className='labor_search'>
                    <div className='history ListOperate' style={{ width: 'auto', marginRight: '15px' }}>
                        <Button onClick={() => {
                            this.handleExport()
                        }}>
                            <BarChartOutlined />
                            <div>导出</div>
                        </Button>
                    </div>

                    <div className='summarySearch'>
                        <div>项目时间 :</div>
                        <Select
                            mode='tags'
                            options={yearList}
                            value={yearValue}
                            onChange={(e) => {
                                this.yearChange(e)
                            }}
                            suffixIcon={<CaretDownOutlined />}
                        >
                        </Select>
                    </div>
                </div>

                <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)

