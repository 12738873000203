import React, { Component } from 'react'
import { message } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createResProgressSettlement, ResProgressSettlementDetail, ResProgressSelectCon, researchProjectMain } from "../../../../utils/scientific_research"
import { reviewApplyResult } from "../../../../utils/api"
import { revenueMain } from "../../../../utils/market"
import Folder from "../../../../accets/img/min_company.png"
import Folders from "../../../../accets/img/min_folder.png"
import File from "../../../../accets/img/min_file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

export default class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                state: '',
                isExport: true,
                template: '/scientificResearchProgressMeasurement.docx',
                templateName: '四川天艺优境环境科技有限公司358进度计量审批单',
                tempData: {
                    apply_date: '',
                    project_name: '',
                    con_name: '',
                    sup_name: '',
                    con_money: '',
                    now_month_output: '',
                    month_over_output: '',
                    pay_tax: '',
                    month_pay: '',
                    stage:'',
                    progress_month: '',
                    last_month_output: '',
                    results: []
                },
                process: {
                    reviewAudit: [],
                    ccReading: [],
                    audit: false,
                    edit: false
                },
                FlowModelName: "成果转化项目进度计量审批",
                flowTips: '(358工程)',
                TableInfo: [
                    {
                        FieldTitle: '选择合同', //字段标题
                        fieldType: '按钮',  //字段类型
                        mandatory: true, //字段是否必填
                    },
                    {
                        FieldTitle: '合同名称', //字段标题
                        fieldName: 'con_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                        search: true,
                    },
                    {
                        FieldTitle: '合同编号', //字段标题
                        fieldName: 'con_number', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '合同总金额', //字段标题
                        fieldName: 'con_money', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目名称', //字段标题
                        fieldName: 'proName', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '项目编号', //字段标题
                        fieldName: 'proNumber', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '项目经理', //字段标题
                        fieldName: 'proManager', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '供货单位', //字段标题
                        fieldName: 'sup_name', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '本月完成产值(元)', //字段标题
                        fieldName: 'month_over_output', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入本月完成产值(元)', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '至本月累计完成产值(元)', //字段标题
                        fieldName: 'now_month_output', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '进度款支付比例(%)', //字段标题
                        fieldName: 'pay_tax', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请输入进度款支付比例(%)', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '本月进度款支付金额(元)', //字段标题
                        fieldName: 'month_pay', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '进度月份', //字段标题
                        fieldName: 'progress_month', //字段名称
                        fieldType: '月份',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '请选择进度月份', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '进度月份范围', //字段标题
                        fieldName: 'progress_ranges', //字段名称
                        fieldType: '日期区间',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                        child: [
                            {
                                FieldTitle: '开始时间', //字段标题
                                fieldName: 'start', //字段名称
                                value: '',
                                width: '50%',
                            },
                            {
                                FieldTitle: '结束时间', //字段标题
                                fieldName: 'end', //字段名称
                                value: '',
                                width: '50%',
                            },
                        ]
                    },
                    {
                        FieldTitle: '进度期数', //字段标题
                        fieldName: 'stage', //字段名称
                        fieldType: '文本',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '请输入进度期数', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '至上月累计完成产值(元)', //字段标题
                        fieldName: 'last_month_output', //字段名称
                        fieldType: '默认',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: true, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '50%',
                    },
                    {
                        FieldTitle: '备注', //字段标题
                        fieldName: 'info', //字段名称
                        fieldType: '文本域',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%',
                    },
                    {
                        FieldTitle: '附件', //字段标题
                        fieldName: 'enclosure', //字段名称
                        fieldType: '附件',  //字段类型
                        disabled: false,  //字段是否禁用
                        mandatory: false, //字段是否必填
                        required: '', //必填要求
                        value: '',
                        width: '100%'
                    },

                ],
                FirstInfo: []
            },
            treeData: [],
            pro_id: '',
            selectedKeys: [],
            expandedKeys: [],
            modal_Visible: false,
            dataSource: [],
            columns: [
                {
                    title: '合同名称',
                    dataIndex: 'con_name',
                    key: 'con_name',
                },
                {
                    title: '合同总金额(元)',
                    dataIndex: 'con_money',
                    key: 'con_money',
                },
                {
                    title: '供应商名称',
                    dataIndex: 'sup_name',
                    key: 'sup_name',
                },
                {
                    title: '至上月累计完成产值(元)',
                    dataIndex: 'lastMonthMoney',
                    key: 'lastMonthMoney',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '供应商名称', //字段标题
                    fieldName: 'sup_name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            sup_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            form: {},
            radio: true,
            FlowchartKey: 1,
        }
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ResProgressSettlementDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_researchProjectMain = () => {
        let { treeData, pro_id, con_type, proList, selectedKeys, expandedKeys } = this.state
        researchProjectMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    proList = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            proList = [...proList, ...item.project[key]]
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 1) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys,
                    }, () => {
                        this.get_ResProgressSelectCon()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ResProgressSettlementDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        ResProgressSettlementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission

                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择合同') {
                                item.isShow = true
                            }

                            if (!res.data.Detail.enclosure.length) {
                                if (item.FieldTitle === '附件') {
                                    item.isShow = true
                                }
                            }
                        })

                        if (res.data.Detail.status === 4) {
                            Info.tempData.apply_date = res.data.Detail.created_at.slice(0, 10)
                            Info.tempData.project_name = res.data.Detail.pro_name
                            Info.tempData.con_name = res.data.Detail.con_name
                            Info.tempData.sup_name = res.data.Detail.supplier
                            Info.tempData.con_money = res.data.Detail.con_money
                            Info.tempData.month_over_output = res.data.Detail.month_over_output
                            Info.tempData.now_month_output = res.data.Detail.now_month_output
                            Info.tempData.pay_tax = res.data.Detail.pay_tax
                            Info.tempData.month_pay = res.data.Detail.month_pay
                            Info.tempData.progress_month = res.data.Detail.progress_month
                            Info.tempData.last_month_output = res.data.Detail.last_month_output

                            if (res.data.Detail.stage) {
                                Info.tempData.stage = res.data.Detail.stage
                                Info.template='/scientificResearchProgressMeasurementStage.docx'
                            }

                            Info.tempData.results = []

                            res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
                                let value = {
                                    username: item.username,
                                    opinion: item.opinion,
                                    reviewStatus: item.review_status
                                }
                                Info.tempData.results = [...Info.tempData.results, value]
                            })
                        }
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
                    Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    form.con_id = res.data.Detail.con_id

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.FieldTitle === '合同编号') {
                            item.value = res.data.Detail.con_num
                        } else if (item.FieldTitle === '合同总金额') {
                            item.value = res.data.Detail.con_money
                        } else if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_num
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.pro_manager
                        } else if (item.FieldTitle === '供货单位') {
                            item.value = res.data.Detail.supplier_name
                        } else if (item.FieldTitle === '至本月累计完成产值(元)') {
                            item.value = res.data.Detail.now_month_output
                            form.now_month_output = res.data.Detail.now_month_output
                            form.month_over_output = res.data.Detail.month_over_output
                        } else if (item.FieldTitle === '本月进度款支付金额(元)') {
                            item.value = res.data.Detail.month_pay
                            form.month_pay = res.data.Detail.month_pay
                            form.pay_tax = res.data.Detail.pay_tax
                        } else if (item.FieldTitle === '至上月累计完成产值(元)') {
                            item.value = res.data.Detail.last_month_output
                            form.last_month_output = res.data.Detail.last_month_output
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    let progress_ranges = JSON.parse(res.data.Detail.progress_ranges)

                    this.NewForm.current.formRef.current.setFieldsValue({
                        month_over_output: res.data.Detail.month_over_output,
                        pay_tax: res.data.Detail.pay_tax,
                        progress_month: res.data.Detail.progress_month ? moment(res.data.Detail.progress_month, 'YYYY-MM') : '',
                        start: progress_ranges[0] ? moment(progress_ranges[0], 'YYYY-MM-DD') : '',
                        end: progress_ranges[1] ? moment(progress_ranges[1], 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        stage: res.data.Detail.stage,
                        enclosure: res.data.Detail.enclosure,
                    })


                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ResProgressSelectCon = () => {
        let { dataSource, pages, sup_name, pro_id } = this.state
        let data = {
            project_id: pro_id,
            sup_name: sup_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        ResProgressSelectCon(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.contracts.length) {
                        res.data.contracts.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                apply_date: item.apply_date,
                                proNumber: item.pro_num,
                                proName: item.pro_name,
                                proManager: item.pro_manager,
                                con_number: item.con_num,
                                con_name: item.con_name,
                                con_money: item.con_money,
                                sup_name: item.party_b,
                                lastMonthMoney: item.lastMonthMoney
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    })
                    )
                } else {
                    message.error(res.data[0])
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.month_over_output = data.month_over_output
        form.pay_tax = data.pay_tax
        form.progress_month = data.progress_month ? data.progress_month.format('YYYY-MM') : ''
        form.progress_ranges = data.start ? [data.start.format('YYYY-MM-DD'), data.end.format('YYYY-MM-DD')] : []
        form.progress_ranges = JSON.stringify(form.progress_ranges)
        form.info = data.info
        form.stage = data.stage

        createResProgressSettlement(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_researchProjectMain()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
                buttonTitle: '',
                treeData: [],
                pro_id: '',
                selectedKeys: [],
                expandedKeys: [],
            })
            )
        }
    }

    handleInput = (value, title) => {
        let { form, Info } = this.state
        if (title === '本月完成产值(元)') {
            form.month_over_output = value

            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '至本月累计完成产值(元)') {
                    item.value = Number(form.last_month_output) + Number(form.month_over_output)
                    form.now_month_output = Number(form.last_month_output) + Number(form.month_over_output)
                }
            })

        } else if ('进度款支付比例(%)') {
            form.pay_tax = value
        }

        if (form.month_over_output && form.pay_tax) {
            Info.TableInfo.forEach((item, index) => {
                if (item.FieldTitle === '本月进度款支付金额(元)') {
                    item.value = Number(form.month_over_output) * Number(form.pay_tax) / 100
                    form.month_pay = Number(form.month_over_output) * Number(form.pay_tax) / 100
                }
            })
        }

        this.setState(preState => ({
            Info,
            form
        }))
    }

    handSelect = (value) => {
        let { Info, form } = this.state
        form.con_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].proName
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].proNumber
            } else if (item.FieldTitle === '项目经理') {
                item.value = value[0].proManager
            } else if (item.FieldTitle === '合同编号') {
                item.value = value[0].con_number
            } else if (item.FieldTitle === '合同名称') {
                item.value = value[0].con_name
            } else if (item.FieldTitle === '合同总金额') {
                item.value = value[0].con_money
            } else if (item.FieldTitle === '供货单位') {
                item.value = value[0].sup_name
            } else if (item.FieldTitle === '至上月累计完成产值(元)') {
                item.value = value[0].lastMonthMoney
                form.last_month_output = value[0].lastMonthMoney
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            sup_name: value.sup_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_ResProgressSelectCon()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            sup_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages, buttonTitle } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            if (buttonTitle === '选择印章') {
                this.get_seal()
            } else if (buttonTitle === '关联材料购买清单') {
                this.get_aboutSupConfirmSelect()
            } else if (buttonTitle === '选择合同') {
                this.get_ResProgressSelectCon()
            }
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_ResProgressSelectCon()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    handleSearch = (title) => {
        let { form } = this.state
        if (!form.con_id) {
            message.error('未关联合同!')
            return
        }
        this.props.history.push({
            pathname: '/scientific_research/achievement_transformation/proContractApproval/NewItem',
            query: {
                'tableId': form.con_id,
            }
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages, radio, FlowchartKey, treeData, selectedKeys, expandedKeys } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} InputEvent={this.handleInput} searchEvent={this.handleSearch} />

                <InitialValue key={FlowchartKey} modal_Visible={modal_Visible} isTree={treeData.length ? true : false} treeData={treeData} selectedKeys={selectedKeys} expandedKeys={expandedKeys} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} onSelectEvent={this.onSelect} onExpandEvent={this.onExpand} />
            </div>
        )
    }
}
