import React, { Component } from 'react'
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import { Button } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [ ],
            columns: [
                {
                    title: '申请人',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '部门名称',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '用章数量',
                    dataIndex: 'name',
                    key: 'name',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '申请人', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '部门', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    handleNew = () => {
		let value = '/administrative/performance/record/NewItem'
		let CachingKeys = getCachingKeys()
		if (CachingKeys.length) {
			CachingKeys.forEach((item, index) => {
				if (item === value) {
					dropByCacheKey(item)
				}
			})
		}
		setTimeout(() => {
			this.props.history.push(value)
		});
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false
        })
    }

    searchQuery = () => {
        console.log('筛选')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_useSealApplyList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo } = this.state
        return (
            <div>
                <div className='cost_budgeting'>
                    <div className='history ListOperate' style={{ width: 'auto', marginRight: '15px' }}>
                        <Button>
                            <AuditOutlined />
                            <div>用章统计</div>
                        </Button>
                    </div>
                    <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} />
                </div>

                {
                    searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                }

                <Table columns={columns} dataSource={dataSource}  pages={this.state.pages} dataLimit={this.handleLimit}/>

            </div>
        )
    }
}

export default withRouter(List)
