import React, { Component } from 'react'
import { Input, Form, message, DatePicker, ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createAwayApply, AwayDetail } from "../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../utils/api"


export default class NewItem extends Component {
	state = {
		Info: {
			state: '',
			//模板导出
			isExport: true,
            template: '/chuchaishenqing.docx',
            templateName: '四川天艺生态园林集团股份有限公司出差申请',
            tempData: {
                apply_date: '',//申请日期
				address:'',//出差地址
                user_name: '',//申请人
				start_date:'',//预计出差时间
				end_date:'',//预计出差时间
				date_num:'',//出差天数
				tool:'',//交通工具
				is_booking:'',//是否需要票务订购
				jobName:'',//申请人职位
				peerUserName:'',//同行人员
                details: [],
                results: []
            },

			FlowModelName: "出差申请",
			TableInfo: [],
			FirstInfo: [{
				plateName: '基本信息',
				list: [{
					FieldTitle: '姓名', //字段标题
					fieldName: 'name', //字段名称
					fieldType: '默认', //字段类型
					disabled: true, //字段是否禁用
					mandatory: false, //字段是否必填
					required: '', //必填要求
					value: '',
					width: '50%'
				}, {
					FieldTitle: '申请日期', //字段标题
					fieldName: 'apply_date', //字段名称
					fieldType: '日期', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请选择申请日期', //必填要求
					value: '',
					width: '50%'
				}, {
					FieldTitle: '部门名称', //字段标题
					fieldName: 'deName', //字段名称
					fieldType: '默认', //字段类型
					disabled: true, //字段是否禁用
					mandatory: false, //字段是否必填
					required: '', //必填要求
					value: '',
					width: '50%'
				}, {
					FieldTitle: '职位名称', //字段标题
					fieldName: 'jobName', //字段名称
					fieldType: '默认', //字段类型
					disabled: true, //字段是否禁用
					mandatory: false, //字段是否必填
					required: '', //必填要求
					value: '',
					width: '50%'
				}, {
					FieldTitle: '预计出差时间', //字段标题
					fieldName: 'time', //字段名称
					fieldType: '时间区间', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '', //必填要求
					value: '2023-01-01',
					width: '50%',
					child: [{
						FieldTitle: '开始时间', //字段标题
						fieldName: 'start_date', //字段名称
						value: '',
						width: '50%',
					},
					{
						FieldTitle: '结束时间', //字段标题
						fieldName: 'end_date', //字段名称
						value: '',
						width: '50%',
					},
					]
				}, {
					FieldTitle: '出差天数', //字段标题
					fieldName: 'date_num', //字段名称
					fieldType: '文本', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请输入出差天数', //必填要求
					value: '',
					width: '50%'
				}, {
					FieldTitle: '交通工具', //字段标题
					fieldName: 'tool', //字段名称
					fieldType: '选择器', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请选择交通工具', //必填要求
					value: '',
					width: '50%',
					selectList: [{
						value: 1,
						label: "火车"
					},
					{
						value: 2,
						label: "飞机"
					},
					{
						value: 3,
						label: "飞机+火车"
					},
					{
						value: 4,
						label: "高铁(动车)"
					},
					{
						value: 5,
						label: "飞机+高铁(动车)"
					},
					{
						value: 6,
						label: "其他"
					},
					]
				}, {
					FieldTitle: '是否需要票务订购', //字段标题
					fieldName: 'is_booking', //字段名称
					fieldType: '单选', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请选择是否需要票务订购', //必填要求
					value: '',
					width: '50%',
					radioList: [{
						value: 1,
						label: "是"
					},
					{
						value: 2,
						label: "否"
					},
					]
				},
				{
					FieldTitle: '申请人职位', //字段标题
					fieldName: 'apply_job', //字段名称
					fieldType: '单选', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请选择申请人职位', //必填要求
					value: '',
					width: '50%',
					radioList: [{
						value: 1,
						label: "普通职员"
					},
					{
						value: 2,
						label: "部门经理"
					},
					{
						value: 3,
						label: "副总经理"
					},
					]
				},
				{
					FieldTitle: '选择同行人员', //字段标题
					fieldType: '按钮', //字段类型
				},
				// {
				// 	FieldTitle: '同行人员', //字段标题
				// 	fieldType: '列表', //字段类型                       
				// 	value: [],
				// },
				{
					FieldTitle: '同行人员', //字段标题
					fieldName: 'peer', //字段名称
					fieldType: '默认', //字段类型
					disabled: false, //字段是否禁用
					mandatory: false, //字段是否必填
					required: '', //必填要求
					value: '',
					width: '100%'
				},
				{
					FieldTitle: '出差地点', //字段标题
					fieldName: 'address', //字段名称
					fieldType: '文本域', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请输入出差地点', //必填要求
					value: '',
					width: '100%'
				},
				{
					FieldTitle: '出差事由', //字段标题
					fieldName: 'matter', //字段名称
					fieldType: '文本域', //字段类型
					disabled: false, //字段是否禁用
					mandatory: true, //字段是否必填
					required: '请输入出差事由', //必填要求
					value: '',
					width: '100%'
				},
				],
			},
			{
				plateName: '票务订购信息',
				isShow: true,
				list: [],
				editable: {
					dataSource: [],
					columns: [
						// {
						// 	title: '',
						// 	dataIndex: 'key',
						// 	key: 'key',
						// 	width: '80px'
						// },
						{
							title: '序号',
							dataIndex: 'id',
							key: 'id',
							width: '80px',
							render: (text, record, index) => {
								return (
									<div>{index + 1}</div>
								)
							}
						},
						{
							title: '操作',
							dataIndex: 'controls',
							key: 'controls',
							width: '100px',
							render: (text, record, index) => {
								return (
									<div className='download' onClick={() => {
										this.handleDel(index, record.key)
									}}>
										删除
									</div>
								)
							}
						},
						{
							title: '姓名',
							dataIndex: 'name',
							key: 'name',
							width: '120px'
						},
						{
							title: '部门',
							dataIndex: 'deName',
							key: 'deName',
							width: '120px'
						},
						{
							title: '事由',
							dataIndex: 'matter',
							key: 'matter',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'depart_matter' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'matter')}>
											<Input />
										</Form.Item>

									)

								} else {
									return (
										<Form.Item name={'depart_matter' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'matter')}>
											<Input disabled />
										</Form.Item>
									)
								}
							}

						},
						{
							title: '票务类型',
							dataIndex: 'tool',
							key: 'tool',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'depart_tool' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tool')}>
											<Input />
										</Form.Item>
									)
								} else {
									return (
										<Form.Item name={'depart_tool' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tool')}>
											<Input disabled />
										</Form.Item>
									)
								}
							}

						},
						{
							title: '出发地',
							dataIndex: 'start_address',
							key: 'start_address',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'start_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'start_address')}>
											<Input />
										</Form.Item>
									)
								} else {
									return (
										<Form.Item name={'start_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'start_address')}>
											<Input disabled />
										</Form.Item>
									)
								}
							}

						},
						{
							title: '目的地',
							dataIndex: 'end_address',
							key: 'end_address',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<Form.Item name={'end_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'end_address')}>
											<Input />
										</Form.Item>
									)
								} else {
									return (
										<Form.Item name={'end_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'end_address')}>
											<Input disabled />
										</Form.Item>
									)
								}
							}

						},
						// {
						// 	title: '出发日期',
						// 	dataIndex: 'start_date',
						// 	key: 'start_date',
						// 	render: (text, record, index) => {
						// 		if (this.state.Info.state === 1 || this.state.Info.state === 3) {
						// 			return (
						// 				<ConfigProvider locale={zhCN}>
						// 					<Form.Item name={'depart_start_date' + record.key} initialValue={text}>
						// 						<DatePicker
						// 							allowClear={false}
						// 							disabled
						// 							format='YYYY-MM-DD'
						// 							onChange={(e) => this.PickerChange(e, record.key, 'start_date')}
						// 						/>
						// 					</Form.Item>
						// 				</ConfigProvider>
						// 			)
						// 		} else {
						// 			return (
						// 				<ConfigProvider locale={zhCN}>
						// 					<Form.Item name={'depart_start_date' + record.key} initialValue={text}>
						// 						<DatePicker
						// 							allowClear={false}
						// 							format='YYYY-MM-DD'
						// 							onChange={(e) => this.PickerChange(e, record.key, 'start_date')}
						// 						/>
						// 					</Form.Item>
						// 				</ConfigProvider>
						// 			)
						// 		}
						// 	}

						// },
						{
							title: '出发时间',
							dataIndex: 'date_detail',
							key: 'date_detail',
							render: (text, record, index) => {
								if (this.state.Info.state === 1 || this.state.Info.state === 3) {
									return (
										<ConfigProvider locale={zhCN}>
											<Form.Item name={'date_detail' + record.key} initialValue={text}>
												<DatePicker
													allowClear={false}
													showTime={true}
													format='YYYY-MM-DD HH:mm:ss'
													onChange={(e) => this.PickerChange(e, record.key, 'date_detail')}
												/>
											</Form.Item>
										</ConfigProvider>
									)
								} else {
									return (
										<ConfigProvider locale={zhCN}>
											<Form.Item name={'date_detail' + record.key} initialValue={text}>
												<DatePicker
													allowClear={false}
													showTime={true}
													format='YYYY-MM-DD HH:mm:ss'
													disabled
													onChange={(e) => this.PickerChange(e, record.key, 'date_detail')}
												/>
											</Form.Item>
										</ConfigProvider>
									)
								}
							}

						},
					]
				},
			}
			],
			process: {
				reviewAudit: [],
				ccReading: [],
				audit: false,
				edit: false
			}
		},
		modal_Visible: false,
		workAdd: false,
		dataSource: [],
		columns: [{
			title: '姓名',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: '性别',
			dataIndex: 'sex',
			key: 'sex',
			render: (text, index) => {
				if (text === '1') {
					return (
						<span> 男 </span>
					)
				} else {
					return (<span> 女 </span>
					)
				}
			}
		},
		{
			title: '部门名称',
			dataIndex: 'deName',
			key: 'deName',
		},
		{
			title: '职位',
			dataIndex: 'company_job',
			key: 'company_job',
		},
		{
			title: '手机',
			dataIndex: 'phone',
			key: 'phone',
		},
		],
		TableInfo: [{
			FieldTitle: '姓名', //字段标题
			fieldName: 'username', //字段名称
			fieldType: '文本', //字段类型
			disabled: false, //字段是否禁用
			mandatory: false, //字段是否必填
			required: '', //必填要求
			value: '',
			width: '48%',
		}, {
			FieldTitle: '部门', //字段标题
			fieldName: 'de_name', //字段名称
			fieldType: '文本', //字段类型
			disabled: false, //字段是否禁用
			mandatory: false, //字段是否必填
			required: '', //必填要求
			value: '',
			width: '48%',
		}],
		username: '',
		de_name: '',
		pages: {
			page: 1,
			total: 0,
			limit: 10,
		},
		form: {},
		FlowchartKey: 1
	}

	NewForm = React.createRef();

	componentDidMount() {
		if (this.props.location.query) {
			let tableId = this.props.location.query.tableId
			this.get_AwayDetail(tableId)
		} else {
			this.get_userInfo()
		}
	}

	get_userInfo = () => {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		const {
			Info
		} = this.state;
		Info.FirstInfo[0].list.forEach((item, index) => {
			if (item.FieldTitle === '姓名') {
				item.value = userInfo.name
			} else if (item.FieldTitle === '部门名称') {
				item.value = userInfo.deName
			} else if (item.FieldTitle === '职位名称') {
				item.value = userInfo.jobName
			}
		})
		Info.state = 1
		this.setState(preState => ({
			Info
		}))
	}

	get_AwayDetail = (tableId) => {
		const { Info, form } = this.state;
		let data = {
			table_id: tableId
		}
		let peer_userName = []
		AwayDetail(data).then(
			(res) => {
				if (!res.code) {
					if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
						form.table_id = tableId
						Info.process.edit = res.data.Detail.editPermission
					} else {
						Info.FirstInfo[0].list.forEach((item, index) => {
							item.disabled = true
							if (item.FieldTitle === '选择同行人员') {
								item.isShow = true
							}
						})

						Info.FirstInfo[1].editable.columns = [
							{
								title: '序号',
								dataIndex: 'id',
								key: 'id',
								width: '80px',
								render: (text, record, index) => {
									return (
										<div>{index + 1}</div>
									)
								}
							},
							{
								title: '姓名',
								dataIndex: 'name',
								key: 'name',
								width: '120px'
							},
							{
								title: '部门',
								dataIndex: 'deName',
								key: 'deName',
								width: '120px'
							},
							{
								title: '事由',
								dataIndex: 'matter',
								key: 'matter',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'depart_matter' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'matter')}>
												<Input />
											</Form.Item>

										)

									} else {
										return (
											<Form.Item name={'depart_matter' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'matter')}>
												<Input disabled />
											</Form.Item>
										)
									}
								}

							},
							{
								title: '票务类型',
								dataIndex: 'tool',
								key: 'tool',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'depart_tool' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tool')}>
												<Input />
											</Form.Item>
										)
									} else {
										return (
											<Form.Item name={'depart_tool' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'tool')}>
												<Input disabled />
											</Form.Item>
										)
									}
								}

							},
							{
								title: '出发地',
								dataIndex: 'start_address',
								key: 'start_address',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'start_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'start_address')}>
												<Input />
											</Form.Item>
										)
									} else {
										return (
											<Form.Item name={'start_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'start_address')}>
												<Input disabled />
											</Form.Item>
										)
									}
								}

							},
							{
								title: '目的地',
								dataIndex: 'end_address',
								key: 'end_address',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<Form.Item name={'end_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'end_address')}>
												<Input />
											</Form.Item>
										)
									} else {
										return (
											<Form.Item name={'end_address' + record.key} key={index} initialValue={text} onBlur={(e) => this.InputChange(e, record.id, 'end_address')}>
												<Input disabled />
											</Form.Item>
										)
									}
								}

							},
							{
								title: '出发时间',
								dataIndex: 'date_detail',
								key: 'date_detail',
								render: (text, record, index) => {
									if (this.state.Info.state === 1 || this.state.Info.state === 3) {
										return (
											<ConfigProvider locale={zhCN}>
												<Form.Item name={'date_detail' + record.key} initialValue={text}>
													<DatePicker
														allowClear={false}
														showTime={true}
														format='YYYY-MM-DD HH:mm:ss'
														onChange={(e) => this.PickerChange(e, record.key, 'date_detail')}
													/>
												</Form.Item>
											</ConfigProvider>
										)
									} else {
										return (
											<ConfigProvider locale={zhCN}>
												<Form.Item name={'date_detail' + record.key} initialValue={text}>
													<DatePicker
														allowClear={false}
														showTime={true}
														format='YYYY-MM-DD HH:mm:ss'
														disabled
														onChange={(e) => this.PickerChange(e, record.key, 'date_detail')}
													/>
												</Form.Item>
											</ConfigProvider>
										)
									}
								}

							},
						]
					}

					Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
					Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
					Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
					Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

					if (res.data.Detail.peerUserName) {
						res.data.Detail.peerUserName.forEach((item, index) => {
							peer_userName.push(item.userName)
						})
					}

					Info.FirstInfo[0].list.forEach((item, index) => {
						if (item.FieldTitle === '姓名') {
							item.value = res.data.Detail.applyUserName
						} else if (item.FieldTitle === '部门名称') {
							item.value = res.data.Detail.deName
						} else if (item.FieldTitle === '职位名称') {
							item.value = res.data.Detail.jobName
						} else if (item.FieldTitle === '同行人员') {
							item.value = peer_userName
						}
					})
					let toolArray = ['火车','飞机','火车+飞机','高铁(动车)','飞机+高铁(动车)','其他']
					if (res.data.Detail.status === 4) {

						// tempData: {
						// 	apply_date: '',//申请日期
						// 	user_name: '',//申请人
						// 	start_date:'',//预计出差时间
						// 	date_num:'',//出差天数
						// 	tool:'',//交通工具
						// 	is_booking:'',//是否需要票务订购
						// 	jobName:'',//申请人职位
						// 	peerUserName:[],//同行人员
						// 	details: [],
						// 	results: []
						// },
						Info.tempData.apply_date = res.data.Detail.apply_date
						Info.tempData.address = res.data.Detail.address
						Info.tempData.user_name = res.data.Detail.applyUserName
						Info.tempData.start_date = res.data.Detail.start_date
						Info.tempData.end_date = res.data.Detail.end_date
						Info.tempData.date_num = res.data.Detail.date_num 
						Info.tempData.tool = toolArray[res.data.Detail.tool-1]
						Info.tempData.is_booking = res.data.Detail.is_booking===1?'是':'否'
						Info.tempData.jobName = res.data.Detail.jobName
						Info.tempData.matter = res.data.Detail.matter
						Info.tempData.results = []
						//随行人员
						for(let key in res.data.Detail.peerUserName){
							if(Info.tempData.peerUserName){
								Info.tempData.peerUserName = Info.tempData.peerUserName + ' '+ res.data.Detail.peerUserName[key].userName
							}else{
								Info.tempData.peerUserName = Info.tempData.peerUserName + res.data.Detail.peerUserName[key].userName
							}
						}
						
						
						//审批流程
						res.data.Detail.reviewAudit.reviewAudit.forEach((item, index) => {
							let value = {
								username: item.username,
								opinion: item.opinion,
								reviewStatus: item.review_status
							}
							Info.tempData.results = [...Info.tempData.results, value]
						})
					}

					if (res.data.Detail.bookings && res.data.Detail.bookings.length) {
						res.data.Detail.bookings.forEach((item, index) => {
							let key = index + 1
							item.key = key
							item.name = item.applyUserName
							let date_detail = item.date_detail
							// item.start_date = moment(item.start_date, 'YYYY-MM-DD')
							item.date_detail = item.date_detail ? moment(item.date_detail, 'YYYY-MM-DD HH:mm:ss') : ''
							Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, item]
							this.NewForm.current.formRef.current.setFieldsValue({
								[(() => 'depart_matter' + key)()]: item.depart_matter,
								[(() => 'depart_tool' + key)()]: item.depart_tool,
								[(() => 'start_address' + key)()]: item.start_address,
								[(() => 'end_address' + key)()]: item.end_address,
								[(() => 'date_detail' + key)()]: item.date_detail,
							})
							//导出模板中购票详情
							let value = {
								index:key,
								applyUserName: item.applyUserName,
								deName: item.deName,
								matter: item.matter,
								tool: item.tool,
								start_address: item.start_address,
								end_address: item.end_address,
								date_detail: date_detail
							};
							Info.tempData.details = [...Info.tempData.details, value]
						})
					} else {
						Info.FirstInfo[1].editable.dataSource = []
					}
					console.log(Info.tempData)


					this.NewForm.current.formRef.current.setFieldsValue({
						apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
						start_date: res.data.Detail.start_date ? moment(res.data.Detail.start_date, 'YYYY-MM-DD HH:mm:ss') : '',
						end_date: res.data.Detail.end_date ? moment(res.data.Detail.end_date, 'YYYY-MM-DD HH:mm:ss') : '',
						date_num: res.data.Detail.date_num,
						tool: res.data.Detail.tool,
						is_booking: res.data.Detail.is_booking,
						apply_job: res.data.Detail.apply_job,
						peer_user: res.data.Detail.peer_user,
						address: res.data.Detail.address,
						matter: res.data.Detail.matter,
					})

					if (res.data.Detail.is_booking === 1) {
						Info.FirstInfo[1].isShow = false
					}

					this.setState(preState => ({
						Info,
						form
					})
					)
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handPresent = (data, status) => {
		const { form, Info } = this.state;
		form.status = status
		form.apply_date = data.apply_date ? data.apply_date.format('YYYY-MM-DD') : ''
		form.start_date = data.start_date ? data.start_date.format('YYYY-MM-DD HH:mm:ss') : ''
		form.end_date = data.end_date ? data.end_date.format('YYYY-MM-DD HH:mm:ss') : ''
		form.date_num = data.date_num
		form.tool = data.tool
		form.is_booking = data.is_booking
		form.apply_job = data.apply_job
		form.address = data.address
		form.matter = data.matter
		form.bookings = Info.FirstInfo[1].editable.dataSource
		form.bookings = JSON.stringify(form.bookings)

		createAwayApply(form).then(
			(res) => {
				if (!res.code) {
					message.success(form.status === 1 ? '暂存成功' : '提交成功')
					// if (form.status === 2) {
					// 	setTimeout(() => {
					// 		window.history.back(-1)
					// 	})
					// }
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '提交失败,请检查表单!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	InputChange = (e, id, title) => {
		let { Info } = this.state;

		Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
			if (em.id === id) {
				em[title] = e.target.value
			}
		})

		this.setState(preState => ({
			Info
		}))
	}

	PickerChange = (e, key, title) => {
		let { Info } = this.state;

		if (title === 'date_detail') {
			Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
				if (em.key === key) {
					em[title] = e.format('YYYY-MM-DD HH:mm:ss')
				}
			})
		} else {
			Info.FirstInfo[1].editable.dataSource.forEach((em, ex) => {
				if (em.key === key) {
					em[title] = e.format('YYYY-MM-DD')
				}
			})
		}

		this.setState(preState => ({
			Info
		}))
	}

	handleWorkAdd = (name) => {
		this.setState(preState => ({
			workAdd: true,
			FlowchartKey: Math.random(),
		}), () => {
			this.get_InitialValue()
		})
	}

	get_InitialValue = () => {
		let { dataSource, pages, username, de_name } = this.state
		let data = {
			username: username,
			de_name: de_name,
			page: Number(pages.page),
			limit: Number(pages.limit)
		}
		selectUserName(data).then(
			(res) => {
				if (!res.code) {
					dataSource = []
					if (res.data.users.length) {
						res.data.users.forEach((item, index) => {
							let user = {
								key: item.table_id,
								username: item.username,
								sex: item.sex,
								deName: item.deName,
								company_job: item.jobName,
								phone: item.phone
							}
							dataSource = [...dataSource, user]
						})
					}
					this.setState(preState => ({
						dataSource,
						pages: res.data.pages,
						modal_Visible: true,
					}))
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handButton = (title, state) => {
		if (state) {
			this.setState(preState => ({
				FlowchartKey: Math.random(),
			}), () => {
				this.get_InitialValue()
			})
		} else {
			this.setState(preState => ({
				pages: {
					page: 1,
					total: 0,
					limit: 10,
				},
				modal_Visible: false,
				workAdd: false
			}))
		}
	}

	handleRadio = (value) => {
		let { Info } = this.state;
		if (value === 1) {
			Info.FirstInfo[1].isShow = false
		} else {
			Info.FirstInfo[1].isShow = true
		}
		this.setState(preState => ({
			Info
		}))
	}

	handlesButton = (type, value) => {
		let { Info } = this.state
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		let data = {
			tableName: '',
			table_id: '',
			reviewResult: type,
			opinion: value,
			review_id: '',
		}

		Info.process.reviewAudit.forEach((item, index) => {
			if (item.user_id === userInfo.userId) {
				data.tableName = item.table_name
				data.table_id = item.table_id
				data.review_id = item.id
			}
		})
		reviewApplyResult(data).then(
			(res) => {
				if (!res.code) {
					message.success('审核成功!')
					setTimeout(() => {
						window.history.back(-1)
					})
				} else {
					if (res.code === 1010) {
						let select = {
							audit_id: res.audit_id,
							tableName: res.tableName,
							table_id: res.table_id,
							data: res.data,
						}

						Info.select = select

						this.setState({
							Info
						})
					} else {
						message.error(res.data[0] || '审核失败!')
					}
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	handSelect = (value) => {
		const { Info, form } = this.state;
		if (this.state.workAdd) {
			if (Info.FirstInfo[1].editable.dataSource.length) {
				Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
					value.forEach((em, ex) => {
						if (em.key === item.user_id) {
							value.splice(ex, 1)
						}
					})
				})

				value.forEach((item, index) => {
					let key = Info.FirstInfo[1].editable.dataSource.length - 1
					const newData = {
						key: Info.FirstInfo[1].editable.dataSource[key].key + 1,
						user_id: item.key,
						name: item.username,
						deName: item.deName,
						matter: '',
						tool: '',
						start_address: '',
						end_address: '',
						// depart_start: '',
						date_detail: ''
					};

					Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
				})
			} else {
				value.forEach((item, index) => {
					const newData = {
						key: Info.FirstInfo[1].editable.dataSource.length + 1,
						user_id: item.key,
						name: item.username,
						deName: item.deName,
						matter: '',
						tool: '',
						start_address: '',
						end_address: '',
						// depart_start: '',
						date_detail: ''
					};

					Info.FirstInfo[1].editable.dataSource = [...Info.FirstInfo[1].editable.dataSource, newData]
				})
			}

			this.setState(preState => ({
				Info,
				form,
				dataSource: [],
				pages: {
					page: 1,
					total: 0,
					limit: 10,
				},
				modal_Visible: false,
			}))
		} else {
			let peer_user = []
			let peer_userName = []
			value.forEach((item, index) => {
				peer_user.push(item.key)
				peer_userName.push(item.username + ' , ')
			})
			Info.FirstInfo[0].list.forEach((item, index) => {
				if (item.FieldTitle === '同行人员') {
					item.value = peer_userName
				}
			})
			form.peer_user = JSON.stringify(peer_user)

			this.setState(preState => ({
				Info,
				form,
				pages: {
					page: 1,
					total: 0,
					limit: 10,
				},
				modal_Visible: false,
			}))
		}

	}

	searchQuery = (value) => {
		this.setState({
			username: value.username,
			de_name: value.de_name,
			pages: {
				page: 1,
				total: 0,
				limit: 10,
			}
		}, () => {
			this.get_InitialValue()
		})
	}

	handleCancel = () => {
		this.setState({
			modal_Visible: false,
			username: '',
			de_name: '',
			pages: {
				limit: 10,
				page: 1,
				total: 0
			},
		})
	}

	handleLimit = (page, limit) => {
		let { pages } = this.state
		pages.page = page
		pages.limit = limit
		this.setState({
			pages
		}, () => {
			this.get_InitialValue()
		})
	}

	handleDel = (key, e) => {
		let { Info } = this.state
		let dataSource = []
		this.NewForm.current.formRef.current.setFieldsValue({
			[(() => 'depart_matter' + e)()]: '',
			[(() => 'depart_tool' + e)()]: '',
			[(() => 'start_address' + e)()]: '',
			[(() => 'end_address' + e)()]: '',
			[(() => 'date_detail' + e)()]: '',
		})
		Info.FirstInfo[1].editable.dataSource.forEach((item, index) => {
			if (index !== key) {
				dataSource = [...dataSource, item]
			}
		})
		Info.FirstInfo[1].editable.dataSource = dataSource
		this.setState(preState => ({
			Info,
		})
		)
	}

	render() {
		let { Info, modal_Visible, dataSource, columns, TableInfo, pages, FlowchartKey } = this.state
		return (
			<div className='configAdd' >
				<AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} rowAddEvent={this.handleWorkAdd} buttonEvent={this.handButton} radioEvent={this.handleRadio} auditEvent={this.handlesButton} />

				<InitialValue key={FlowchartKey} modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
			</div>
		)
	}
}
