import React, { Component } from 'react'
import { Select, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import MenuList from '../../../components/MenuList'
import { staffList } from "../../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '项目名称',
                    dataIndex: 'title',
                    key: 'title',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '操作时间',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '操作类型',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '工作类型',
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: '里程表数据',
                    dataIndex: 'time',
                    key: 'time',
                },
            ],
            TableInfo: [
                {
                    FieldTitle: '项目名称', //字段标题
                    fieldName: 'applicant', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                },
                {
                    FieldTitle: '操作时间', //字段标题
                    fieldName: 'time', //字段名称
                    fieldType: '日期区间',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '48%',
                    child: [
                        {
                            FieldTitle: '开始时间', //字段标题
                            fieldName: 'start_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                        {
                            FieldTitle: '结束时间', //字段标题
                            fieldName: 'end_time', //字段名称
                            value: '',
                            width: '50%',
                        },
                    ]
                },
            ],
            teamList: [
                { value: 1, label: "外请班组-老张" },
                { value: 2, label: "外请班组-老刘" },
                { value: 3, label: "外请班组-老梁" },
                { value: 4, label: "外请班组-老李" },
                { value: 5, label: "外请班组-老王" },
            ],
            teamValue: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    // componentDidMount() {
    //     this.get_staffList()
    // }

    get_staffList = () => {
        let { dataSource, pages, start, end, name, apply_user, process_state } = this.state
        let data = {
            start: start,
            end: end,
            name: name,
            apply_user: apply_user,
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        staffList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                created_at: item.created_at,
                                apply_user: item.apply_user,
                                name: item.name,
                                job_day: item.job_day,
                                department: item.department,
                                position: item.position,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        this.props.history.push('/purchase/registration/NewItem')
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/purchase/registration/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            process_state: '',
            start: '',
            end: '',
            name: '',
            apply_user: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_staffList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
            apply_user: value.apply_user,
            start: value.start ? value.start.format('YYYY-MM-DD') : '',
            end: value.end ? value.end.format('YYYY-MM-DD') : ''
        }, () => {
            this.get_staffList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_staffList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, teamList, teamValue } = this.state
        return (
            <div className='contract_List'>
                <div className='menuList'>
                    <MenuList />
                </div>
                <div>
                    <div className='labor_search'>
                        {/* <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={this.state.searchShow} /> */}

                        <div className='cost_budgeting'>
                            <div className='history ListOperate' style={{ marginRight: '15px', width: 'auto' }}  onClick={()=>{
                                this.handleNew()
                            }}>
                                <Button style={{ width: '180px' }}>
                                    <div>工程机械操作记录</div>
                                </Button>
                            </div>
                            <Operate dontAdd={true} dontImport={true} searchEvent={this.handleSearch} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />
                        </div>


                        <div className='screen_right'>
                            <div>
                                <div>操作人员 :</div>
                                <Select
                                    options={teamList}
                                    value={teamValue}
                                    onChange={(e) => {
                                        console.log(e)
                                    }}
                                    suffixIcon={<CaretDownOutlined />}
                                >
                                </Select>
                            </div>
                        </div>
                    </div>



                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    <Table columns={columns} dataSource={dataSource} pages={this.state.pages} dataLimit={this.handleLimit}/>
                </div>
            </div>
        )
    }
}

export default withRouter(List)
