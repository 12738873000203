import http from '../utils/http';


/* 用工申请 */

//添加编辑用工申请
function createEmploymentApplication(data) {
    return http("post", '/api/createEmploymentApplication', data);
}

//用工申请列表
function useEmploymentList(data) {
    return http("get", '/api/useEmploymentList', data);
}

//用工申请详情
function employmentApplicationDetail(data) {
    return http("get", '/api/employmentApplicationDetail', data);
}

//添加编辑临时用工申请
function createTemporaryEmployment(data) {
    return http("post", '/api/createTemporaryEmployment', data);
}

//临时用工申请列表
function temporaryEmploymentList(data) {
    return http("get", '/api/temporaryEmploymentList', data);
}

//临时用工申请审批详情
function temporaryEmploymentDetail(data) {
    return http("get", '/api/temporaryEmploymentDetail', data);
}

/* 临时用工工资表 */

//添加编辑非全日制临时用工工资表
function createPartTime(data) {
    return http("post", '/api/createPartTime', data);
}

//非全日制临时用工工资表详情
function partTimeWagesDetails(data) {
    return http("get", '/api/partTimeWagesDetails', data);
}

//非全日制临时用工工资表列表
function partTimeWagersList(data) {
    return http("get", '/api/partTimeWagersList', data);
}

/* 组建项目团队*/

//项目团队组建列表
function projectUserList(data) {
    return http("get", '/api/projectUserList', data);
}

//获取项目列表
function initiationProjectList(data) {
    return http("get", '/api/initiationProjectList', data);
}

//调整项目人员详情
function projectUserDetail(data) {
    return http("get", '/api/projectUserDetail', data);
}

//任命团队人员
function createProjectUser(data) {
    return http("post", '/api/createProjectUser', data);
}

//添加编辑项目授权团队
function createAutoProjectDuty(data) {
    return http("post", '/api/createAutoProjectDuty', data);
}

//获取项目授权团队详细信息
function autoProjectTeamDetail(data) {
    return http("get", '/api/autoProjectTeamDetail', data);
}

//删除项目授权团队人员
function deleteProjectTeam(data) {
    return http("post", '/api/deleteProjectTeam', data);
}

//删除项目授权团队人员
function projectTeamAuxiliary(data) {
    return http("get", '/api/projectTeamAuxiliary', data);
}


//添加编辑公司
function createCompany(data) {
    return http("post", '/api/createCompany', data);
}

//添加编辑公章
function officialSeal(data) {
    return http("post", '/api/officialSeal', data);
}

//获取部门职位
function getDepartmentJob(data) {
    return http("get", '/api/getDepartmentJob', data);
}

//获取功能表单
function getFunctionTable(data) {
    return http("get", '/api/getFunctionTable', data);
}

//部门绑定功能
function deBindFunction(data) {
    return http("post", '/api/deBindFunction', data);
}

//员工入职-编辑
function staffMeans(data) {
    return http("post", '/api/OA_EditStaffMeans', data);
}

//员工入职列表
function staffList(data) {
    return http("get", '/api/OA_staffList', data);
}

//查看员工信息详情
function userInformation(data) {
    return http("get", '/api/userInformation', data);
}

//编辑员工档案
function editUserArchives(data) {
    return http("post", '/api/editUserArchives', data);
}

//员工档案列表
function userArchivesList(data) {
    return http("get", '/api/userArchivesList', data);
}

//编辑员工职位
function EditUserPosition(data) {
    return http("get", '/api/EditUserPosition', data);
}

//查看员工档案详情
function userArchivesInformation(data) {
    return http("get", '/api/userArchivesInformation', data);
}

/* 考勤管理 */

//添加编辑补卡申请
function createCardReplacement(data) {
    return http("post", '/api/createCardReplacement', data);
}

//补卡申请列表
function cardReplacementList(data) {
    return http("get", '/api/cardReplacementList', data);
}

//查看补卡申请详情
function lookCardRellacDetail(data) {
    return http("get", '/api/lookCardRellacDetail', data);
}

//添加编辑出差申请
function createAwayApply(data) {
    return http("post", '/api/createAwayApply', data);
}

//出差申请列表
function awayApplyList(data) {
    return http("get", '/api/awayApplyList', data);
}

//出差申请列表
function AwayDetail(data) {
    return http("get", '/api/AwayDetail', data);
}

//添加编辑请假申请
function createUserLeave(data) {
    return http("post", '/api/createUserLeave', data);
}

//请假申请列表
function userLeaveList(data) {
    return http("get", '/api/userLeaveList', data);
}

//查看请假申请详情
function lookLeaveDetail(data) {
    return http("get", '/api/lookLeaveDetail', data);
}

//添加编辑派遣申请
function createDispatchApply(data) {
    return http("post", '/api/createDispatchApply', data);
}

//派遣申请列表
function userDispatchList(data) {
    return http("get", '/api/userDispatchList', data);
}

//派遣申请详情
function dispatchDetail(data) {
    return http("get", '/api/dispatchDetail', data);
}

//考勤汇总
function AttendanceSummary(data) {
    return http("get", '/api/AttendanceSummary', data);
}

/* 设备购置 */

//申请设备购置
function createEquipmentPurchase(data) {
    return http("post", '/api/createEquipmentPurchase', data);
}

//设备购置列表
function equipmentPurchaseList(data) {
    return http("get", '/api/equipmentPurchaseList', data);
}

//设备购置申请详情
function equipmentDetail(data) {
    return http("get", '/api/equipmentDetail', data);
}

/* 印章管理 */

//添加印章
function createSealManager(data) {
    return http("post", '/api/createSealManager', data);
}

//印章列表
function sealManagerList(data) {
    return http("get", '/api/sealManagerList', data);
}

//添加编辑用章申请
function createUseApply(data) {
    return http("post", '/api/createUseApply', data);
}

//用章申请列表
function useSealApplyList(data) {
    return http("get", '/api/useSealApplyList', data);
}

//用章申请辅助数据
function useSealApplyAuxiliaryData(data) {
    return http("get", '/api/useSealApplyAuxiliaryData', data);
}

//用章申请详情
function useSealApplyDetail(data) {
    return http("get", '/api/useSealApplyDetail', data);
}

//刻章申请
function applySeal(data) {
    return http("post", '/api/applySeal', data);
}

//刻章申请列表
function sealApplyList(data) {
    return http("get", '/api/sealApplyList', data);
}

//查看刻章申请内容
function lookApplyContent(data) {
    return http("get", '/api/lookApplyContent', data);
}

//获取刻章申请辅助数据
function getSealAuxiliary(data) {
    return http("get", '/api/getSealAuxiliary', data);
}

//添加编辑印章注销
function applySealCan(data) {
    return http("post", '/api/applySealCan', data);
}

//添加编辑印章注销
function isCancellationSeal(data) {
    return http("get", '/api/isCancellationSeal', data);
}

//注销印章列表
function canSealList(data) {
    return http("get", '/api/canSealList', data);
}

//注销印章详情
function canSealDetail(data) {
    return http("get", '/api/canSealDetail', data);
}

/*工作总结 */

//添加编辑工作周报
function createWeekly(data) {
    return http("post", '/api/createWeekly', data);
}

//周报详情
function weeklyDetail(data) {
    return http("get", '/api/weeklyDetail', data);
}

//周报列表
function weeklyList(data) {
    return http("get", '/api/weeklyList', data);
}

//周报统计
function weeklyListStatistics(data) {
    return http("get", '/api/weeklyListStatistics', data);
}

//周报导出
function weeklyLeadingOut(data) {
    return http("get", '/api/weeklyLeadingOut', data);
}

//添加编辑年度总结
function createAnnualSummary(data) {
    return http("post", '/api/createAnnualSummary', data);
}

//年度总结列表
function annualSummaryList(data) {
    return http("get", '/api/annualSummaryList', data);
}

//年度总结详情
function annualSummaryDetail(data) {
    return http("get", '/api/annualSummaryDetail', data);
}

/* 绩效考核 */

//添加编辑考核标准
function createStandard(data) {
    return http("post", '/api/createStandard', data);
}

//考核标准列表
function standardList(data) {
    return http("get", '/api/standardList', data);
}

//考核标准详情
function assessmentDetail(data) {
    return http("get", '/api/assessmentDetail', data);
}

//编辑添加考核记录
function createAssessment(data) {
    return http("post", '/api/createAssessment', data);
}

//考核记录列表
function assessmentDetailList(data) {
    return http("get", '/api/assessmentDetailList', data);
}

//考核标准详情
function assessmentDetailDetail(data) {
    return http("get", '/api/assessmentDetailDetail', data);
}


/* 流程设计 */

//获取部门功能列表
function DeFunctionList(data) {
    return http("get", '/api/getDeFunctionList', data);
}


//获取功能表属性
function FunctionTableAttribute(data) {
    return http("get", '/api/getFunctionTableAttribute', data);
}

//根据表名 获取流程数据
function tableNameGetAudit(data) {
    return http("get", '/api/tableNameGetAudit', data);
}

//新增流程开始-结束
function createProcessStartEnd(data) {
    return http("post", '/api/createProcessStartEnd', data);
}

//绑定流程抄送人员
function auditCopy(data) {
    return http("post", '/api/audit_Cc', data);
}

//对流程进行审核
function reviewApplyResult(data) {
    return http("post", '/api/reviewApplyResult', data);
}

//新增节点连线
function createProcessNode(data) {
    return http("post", '/api/createProcessNode', data);
}

//编辑节点连线
function editProcessNode(data) {
    return http("post", '/api/editProcessNode', data);
}

//查询流程设计图谱
function getFlowImage(data) {
    return http("get", '/api/getFlowImage', data);
}

//保存编辑流程设计图谱
function saveFlowImage(data) {
    return http("post", '/api/saveFlowImage', data);
}

//删除节点连线
function deleteProcessNode(data) {
    return http("post", '/api/deleteProcessNode', data);
}

//添加发布公告
function createNotice(data) {
    return http("post", '/api/createNotice', data);
}

//通知公告列表
function noticeList(data) {
    return http("get", '/api/noticeList', data);
}

//通知公告预览
function previewNotice(data) {
    return http("get", '/api/previewNotice', data);
}

//通知公告详情
function noticeDetail(data) {
    return http("get", '/api/noticeDetail', data);
}

//删除通知公告
function deleteNotice(data) {
    return http("post", '/api/deleteNotice', data);
}

//清理流程设计功能缓存
function clearFunctionTable(data) {
    return http("get", '/api/clearFunctionTable', data);
}

export {
    createEmploymentApplication,
    useEmploymentList,
    employmentApplicationDetail,
    createTemporaryEmployment,
    temporaryEmploymentList,
    temporaryEmploymentDetail,
    createPartTime,
    partTimeWagesDetails,
    partTimeWagersList,
    createCompany,
    officialSeal,
    getDepartmentJob,
    getFunctionTable,
    deBindFunction,
    staffMeans,
    staffList,
    userInformation,
    editUserArchives,
    userArchivesList,
    EditUserPosition,
    userArchivesInformation,
    createCardReplacement,
    cardReplacementList,
    lookCardRellacDetail,
    createAwayApply,
    awayApplyList,
    AwayDetail,
    createUserLeave,
    userLeaveList,
    lookLeaveDetail,
    createDispatchApply,
    userDispatchList,
    AttendanceSummary,
    dispatchDetail,
    createEquipmentPurchase,
    equipmentPurchaseList,
    equipmentDetail,
    createSealManager,
    sealManagerList,
    createUseApply,
    useSealApplyList,
    useSealApplyAuxiliaryData,
    useSealApplyDetail,
    applySeal,
    sealApplyList,
    lookApplyContent,
    getSealAuxiliary,
    applySealCan,
    isCancellationSeal,
    canSealList,
    canSealDetail,
    createWeekly,
    weeklyDetail,
    weeklyList,
    weeklyListStatistics,
    weeklyLeadingOut,
    createAnnualSummary,
    annualSummaryList,
    annualSummaryDetail,
    initiationProjectList,
    createProjectUser,
    createAutoProjectDuty,
    autoProjectTeamDetail,
    deleteProjectTeam,
    projectTeamAuxiliary,
    projectUserDetail,
    projectUserList,
    createStandard,
    standardList,
    assessmentDetail,
    createAssessment,
    assessmentDetailList,
    assessmentDetailDetail,
    DeFunctionList,
    FunctionTableAttribute,
    tableNameGetAudit,
    createProcessStartEnd,
    auditCopy,
    reviewApplyResult,
    createProcessNode,
    editProcessNode,
    getFlowImage,
    saveFlowImage,
    deleteProcessNode,
    createNotice,
    noticeList,
    previewNotice,
    noticeDetail,
    deleteNotice,
    clearFunctionTable
}
