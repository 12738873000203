import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { applySealCan, isCancellationSeal, canSealDetail } from "../../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "印章注销",
            TableInfo: [],
            FirstInfo: [
                {
                    plateName: '申请',
                    list: [
                        {
                            FieldTitle: '印章名称', //字段标题
                            fieldName: 'seal_id', //字段名称
                            fieldType: '选择器',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择印章名称', //必填要求
                            value: '',
                            selectList: [],
                            width: '50%'
                        }
                    ],
                },
                {
                    plateName: '注销',
                    list: [
                        {
                            FieldTitle: '选择负责人', //字段标题
                            fieldType: '按钮',  //字段类型
                            mandatory: true, //字段是否必填
                        },
                        {
                            FieldTitle: '负责人', //字段标题
                            fieldName: 'res_user', //字段名称
                            fieldType: '默认',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '注销日期', //字段标题
                            fieldName: 'can_day', //字段名称
                            fieldType: '日期',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请选择注销日期', //必填要求
                            value: '',
                            width: '50%'
                        },
                        {
                            FieldTitle: '注销原因', //字段标题
                            fieldName: 'info', //字段名称
                            fieldType: '文本域',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: true, //字段是否必填
                            required: '请输入注销原因', //必填要求
                            value: '',
                            width: '100%'
                        },
                        {
                            FieldTitle: '回执', //字段标题
                            fieldName: 'receipt', //字段名称
                            fieldType: '附件',  //字段类型
                            disabled: false,  //字段是否禁用
                            mandatory: false, //字段是否必填
                            required: '', //必填要求
                            value: '',
                            width: '100%'
                        },
                    ],
                },
            ],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '姓名',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, index) => {
                    if (text === '1') {
                        return (
                            <span>男</span>
                        )
                    } else {
                        return (
                            <span>女</span>
                        )
                    }
                }
            },
            {
                title: '部门名称',
                dataIndex: 'deName',
                key: 'deName',
            },
            {
                title: '职位',
                dataIndex: 'company_job',
                key: 'company_job',
            },
            {
                title: '手机',
                dataIndex: 'phone',
                key: 'phone',
            },
        ],
        TableInfo: [
            {
                FieldTitle: '姓名', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }
        ],
        pages: {
            page: 1,
            dataNumber: 1,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        this.get_isCancellationSeal()
    }

    get_isCancellationSeal = () => {
        let { Info } = this.state
        isCancellationSeal().then(
            (res) => {
                res.data.seals.forEach((item, index) => {
                    let value = {
                        value: item.table_id,
                        label: item.name
                    }
                    Info.FirstInfo[0].list[0].selectList = [...Info.FirstInfo[0].list[0].selectList, value]
                })
                this.setState(preState => ({
                    Info
                }), () => {
                    if (this.props.location.query) {
                        let tableId = this.props.location.query.tableId
                        this.get_canSealDetail(tableId)
                    } else {
                        this.get_FormState()
                    }
                })
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_canSealDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        canSealDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.FirstInfo.forEach((item, index) => {
                            item.list.forEach((em, ex) => {
                                em.disabled = true
                                if (em.FieldTitle === '选择负责人') {
                                    em.isShow = true
                                }
                            })
                        })

                        Info.FirstInfo[1].list.forEach((item, index) => {
                            if (!res.data.Detail.receipt.length) {
                                if (item.FieldTitle === '回执') {
                                    item.isShow = true
                                }
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    let value = {
                        value: res.data.Detail.seal_id,
                        label: res.data.Detail.sealName
                    }
                    Info.FirstInfo[0].list[0].selectList = [...Info.FirstInfo[0].list[0].selectList, value]

                    Info.FirstInfo[1].list.forEach((item, index) => {
                        if (item.FieldTitle === '负责人') {
                            form.res_user = res.data.Detail.res_user
                            item.value = res.data.Detail.resUsername
                        } else if (item.FieldTitle === '回执') {
                            item.value = res.data.Detail.receipt.length ? res.data.Detail.receipt : ''
                        }
                    })


                    this.NewForm.current.formRef.current.setFieldsValue({
                        seal_id: res.data.Detail.seal_id,
                        can_day: res.data.Detail.can_day ? moment(res.data.Detail.can_day, 'YYYY-MM-DD') : '',
                        info: res.data.Detail.info,
                        receipt: res.data.Detail.receipt
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                    })
                    )
                } else {
                    message.error(res.data[0] || '数据获取失败!')
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { form, Info } = this.state
        let fileList = []
        if (form.table_id) {
            Info.FirstInfo[1].list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            form,
            Info
        })
        )
    }

    handSelect = (value) => {
        console.log(value)
        let { Info, form } = this.state
        form.res_user = value[0].key
        Info.FirstInfo[1].list.forEach((item, index) => {
            if (item.FieldTitle === '负责人') {
                item.value = value[0].username
            }
        })
        this.setState(preState => ({
            Info,
            form,
            modal_Visible: false,
        })
        )
    }

    handPresent = (data, status) => {

        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.seal_id = data.seal_id
        form.can_day = data.can_day ? data.can_day.format('YYYY-MM-DD') : ''
        form.info = data.info

        applySealCan(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages } = this.state
        let data = {
            username: '',
            page: Number(pages.page)
        }
        selectUserName(data).then(
            (res) => {
                res.data.users.forEach((item, index) => {
                    let user = {
                        key: item.table_id,
                        username: item.username,
                        sex: item.sex,
                        deName: item.deName,
                        company_job: item.jobName,
                        phone: item.phone
                    }
                    dataSource = [...dataSource, user]
                })
                this.setState(preState => ({
                    dataSource,
                    pages: res.data.pages,
                    modal_Visible: true,
                })
                )
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    dataNumber: 1,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} fileEvent={this.handFile} buttonEvent={this.handButton} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
