import React, { Component } from 'react'
import Icon from '@ant-design/icons';
import { Tree } from 'antd';
import Folder from "../../accets/img/folder.png"
import Folders from "../../accets/img/folders.png"
import File from "../../accets/img/file.png"

const FolderImage = () => (
    <img src={Folder} alt=""></img>
);

const FoldersImage = () => (
    <img src={Folders} alt=""></img>
);

const FileImage = () => (
    <img src={File} alt=""></img>
);


const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

const treeData = [
    {
        title: '四川天艺生态园林集团股份有限公司',
        key: '0-0',
        icon: <FolderIcon />,
        children: [
            {
                title: '2022年',
                key: '0-0-0',
                icon: <FoldersIcon />,
                children: [
                    {
                        title: '朗基香颂天府项目—期(1、3',
                        key: '0-0-0-0',
                        icon: <FileIcon />,
                    },

                ],
            },
            {
                title: '2021年',
                key: '0-0-1',
                icon: <FoldersIcon />,
                children: [
                    {
                        title: '中关村贵阳科技园观山湖大数',
                        key: '0-0-1-0',
                        icon: <FileIcon />,
                    },
                ],
            },
            {
                title: '2020年',
                key: '0-0-2',
                icon: <FoldersIcon />,
                children: [
                    {
                        title: '中关村贵阳科技园观山湖大数',
                        key: '0-0-2-0',
                        icon: <FileIcon />,
                    },
                    {
                        title: '朗基香颂天府项目—期(1、3',
                        key: '0-0-2-1',
                        icon: <FileIcon />,
                    },
                ],
            },
        ],
    }
];

export default class MenuList extends Component {
    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };

    render() {

        return (
            <Tree
                showLine={{ showLeafIcon: false }}
                showIcon={true}
                defaultExpandedKeys={['0-0']}
                onSelect={this.onSelect}
                treeData={treeData}
            />
        )
    }
}
