import React, { Component } from 'react'
import { message } from 'antd';
import moment from 'moment';
import AddConfig from '../../../components/AddConfig'
import InitialValue from '../../../components/InitialValue'
import { createUserQuit, userQuitDetail } from "../../../../utils/public"
import { selectUserName, reviewApplyResult } from "../../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            FlowModelName: "离职办理",
            TableInfo: [{
                FieldTitle: '单据编号', //字段标题
                fieldName: 'number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '(自动编号)',
                width: '50%'
            }, {
                FieldTitle: '申请日期', //字段标题
                fieldName: 'apply_date', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择申请时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '选择离职人', //字段标题
                fieldType: '按钮',  //字段类型               
            }, {
                FieldTitle: '离职人', //字段标题
                fieldName: 'username', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '部门', //字段标题
                fieldName: 'deName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '职位', //字段标题
                fieldName: 'jobName', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '离职时间', //字段标题
                fieldName: 'quit_time', //字段名称
                fieldType: '日期',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择离职时间', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '离职类型', //字段标题
                fieldName: 'quit_class', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择离职类型', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "辞职" },
                    { value: 2, label: "辞退" },
                    { value: 3, label: "开除" },
                    { value: 4, label: "退休" },
                    { value: 5, label: "合同到期" },
                    { value: 6, label: "其他" },
                ]
            }, {
                FieldTitle: '员工离职附件', //字段标题
                fieldName: 'user_enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '离职原因', //字段标题
                fieldName: 'quit_reason', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择离职原因', //必填要求
                value: [],
                width: '100%',
                selectList: [
                    { value: '1', label: "家庭因素" },
                    { value: '2', label: "合同到期" },
                    { value: '3', label: "晋升机会" },
                    { value: '4', label: "工作时间长" },
                    { value: '5', label: "上学进修" },
                    { value: '6', label: "福利不佳" },
                    { value: '7', label: "健康因素" },
                    { value: '8', label: "人际关系" },
                    { value: '9', label: "交通不便" },
                    { value: '10', label: "无法适应倒班" },
                    { value: '11', label: "无法调转人事关系" },
                    { value: '12', label: "工作环境" },
                    { value: '13', label: "其他" },

                ],
                multiple: true
            }, {
                FieldTitle: '行政面谈记录', //字段标题
                fieldName: 'admin_log', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '行政面谈记录附件', //字段标题
                fieldName: 'admin_enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '主管面谈记录', //字段标题
                fieldName: 'head_log', //字段名称
                fieldType: '文本域',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            {
                FieldTitle: '主管面谈记录附件', //字段标题
                fieldName: 'head_enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [],
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            }
        },
        modal_Visible: false,
        workAdd: false,
        dataSource: [],
        columns: [{
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text, index) => {
                if (text === '1') {
                    return (
                        <span> 男 </span>
                    )
                } else {
                    return (<span> 女 </span>
                    )
                }
            }
        },
        {
            title: '部门名称',
            dataIndex: 'deName',
            key: 'deName',
        },
        {
            title: '职位',
            dataIndex: 'company_job',
            key: 'company_job',
        },
        {
            title: '手机',
            dataIndex: 'phone',
            key: 'phone',
        },
        ],
        TableInfo: [{
            FieldTitle: '姓名', //字段标题
            fieldName: 'username', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }, {
            FieldTitle: '部门', //字段标题
            fieldName: 'de_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '48%',
        }],
        username: '',
        de_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {}
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_userQuitDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { Info } = this.state;
        Info.state = 1
        this.setState(preState => ({
            Info
        }))
    }

    get_userQuitDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        userQuitDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true



                            if (item.FieldTitle === '员工离职附件') {
                                if (!res.data.Detail.user_enclosure.length) {
                                    item.isShow = true
                                }
                            } else if (item.FieldTitle === '行政面谈记录附件') {
                                if (!res.data.Detail.admin_enclosure.length) {
                                    item.isShow = true
                                }
                            } else if (item.FieldTitle === '主管面谈记录附件') {
                                if (!res.data.Detail.head_enclosure.length) {
                                    item.isShow = true
                                }
                            } else if (item.FieldTitle === '选择离职人') {
                                item.isShow = true
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '单据编号') {
                            item.value = res.data.Detail.code
                        } else if (item.FieldTitle === '离职人') {
                            form.quit_user = res.data.Detail.apply_user
                            item.value = res.data.Detail.quitName
                        } else if (item.FieldTitle === '部门') {
                            item.value = res.data.Detail.deName
                        } else if (item.FieldTitle === '职位') {
                            item.value = res.data.Detail.jobName
                        } else if (item.FieldTitle === '员工离职附件') {
                            item.value = res.data.Detail.user_enclosure.length ? res.data.Detail.user_enclosure : ''
                        } else if (item.FieldTitle === '行政面谈记录附件') {
                            item.value = res.data.Detail.admin_enclosure.length ? res.data.Detail.admin_enclosure : ''
                        } else if (item.FieldTitle === '主管面谈记录附件') {
                            item.value = res.data.Detail.head_enclosure ? res.data.Detail.head_enclosure : ''
                        }
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        code: res.data.Detail.code,
                        apply_date: res.data.Detail.apply_date ? moment(res.data.Detail.apply_date, 'YYYY-MM-DD') : '',
                        quit_time: res.data.Detail.quit_time ? moment(res.data.Detail.quit_time, 'YYYY-MM-DD') : '',
                        quit_class: res.data.Detail.quit_class,
                        quit_reason: JSON.parse(res.data.Detail.quit_reason),
                        admin_log: res.data.Detail.admin_log,
                        head_log: res.data.Detail.head_log,
                        user_enclosure: res.data.Detail.user_enclosure,
                        admin_enclosure: res.data.Detail.admin_enclosure,
                        head_enclosure: res.data.Detail.head_enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        const { form, Info } = this.state;
        form.status = status
        form.apply_date = data.apply_date?data.apply_date.format('YYYY-MM-DD'):''
        form.quit_time = data.quit_time?data.quit_time.format('YYYY-MM-DD'):''
        form.quit_class = data.quit_class
        form.quit_reason = JSON.stringify(data.quit_reason)
        form.admin_log = data.admin_log
        form.head_log = data.head_log

        createUserQuit(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { dataSource, pages, username, de_name } = this.state
        let data = {
            username: username,
            de_name: de_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                username: item.username,
                                sex: item.sex,
                                deName: item.deName,
                                company_job: item.jobName,
                                phone: item.phone
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_InitialValue()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            }))
        }
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.list.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handSelect = (value) => {
        const { Info, form } = this.state;
        form.quit_user = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '离职人') {
                item.value = value[0].username
            } else if (item.FieldTitle === '部门') {
                item.value = value[0].deName
            } else if (item.FieldTitle === '职位') {
                item.value = value[0].company_job
            }
        })
        this.setState(preState => ({
            Info,
            form,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        }))
    }

    searchQuery = (value) => {
        this.setState({
            username: value.username,
            de_name: value.de_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_InitialValue()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            username: '',
            de_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_InitialValue()
        })
    }

    render() {
        let { Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd' >
                <AddConfig ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
