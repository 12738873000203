import React, { Component } from 'react'
import { Checkbox } from 'antd';

export default class UserCheckbox extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { disabled, checkboxList, onChange, value, fileName, postEvent } = this.props;
        return (
            <Checkbox.Group
                disabled={disabled}
                options={checkboxList}
                value={value}
                onChange={(e) => {
                    onChange(e)
                    if (fileName === '调拨类型') {
                        postEvent(e)
                    }
                }}
            />
        )
    }
}
