import React, { Component } from 'react'
import { Tree, message } from 'antd';
import Icon from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Search from '../../../components/Search'
import Table from '../../../components/Table'
import { userArchivesList } from "../../../../utils/oa"
import { departmentList } from "../../../../utils/public"
import group from "../../../../accets/img/group.png"
import company from "../../../../accets/img/company.png"

const groupImage = () => <img src={group} alt="" style={{ width: '24px' }}></img>
const companyImage = () => <img src={company} alt=""></img>
const GroupIcon = (props) => <Icon component={groupImage} {...props} />
const CompanyIcon = (props) => <Icon component={companyImage} {...props} />

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            classType: 0,
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '入职日期',
                    dataIndex: 'job_day',
                    key: 'job_day',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '姓名',
                    dataIndex: 'username',
                    key: 'username',

                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <div>男</div>
                            )
                        } else if (text === '2') {
                            return (
                                <div>女</div>
                            )
                        }
                    }
                },
                {
                    title: '民族',
                    dataIndex: 'nation',
                    key: 'nation',

                },
                {
                    title: '部门名称',
                    dataIndex: 'deName',
                    key: 'deName',

                },
                {
                    title: '职位',
                    dataIndex: 'company_job',
                    key: 'company_job',

                },
                {
                    title: '专业',
                    dataIndex: 'major',
                    key: 'major',

                },
                {
                    title: '学历',
                    dataIndex: 'education',
                    key: 'education',
                    render: (text, index) => {
                        if (text === '1') {
                            return (
                                <div>本科</div>
                            )
                        } else if (text === '2') {
                            return (
                                <div>专科</div>
                            )
                        } else if (text === '3') {
                            return (
                                <div>高中</div>
                            )
                        } else if (text === '4') {
                            return (
                                <div>初中</div>
                            )
                        } else if (text === '5') {
                            return (
                                <div>硕士</div>
                            )
                        } else if (text === '6') {
                            return (
                                <div>博士</div>
                            )
                        }
                    }

                },
                {
                    title: '联系住址',
                    dataIndex: 'place_residence',
                    key: 'place_residence',

                },
                {
                    title: '籍贯',
                    dataIndex: 'native_place',
                    key: 'native_place',

                },
                {
                    title: '身份证号码',
                    dataIndex: 'id_cart',
                    key: 'id_cart',
                },
                {
                    title: '身份证地址',
                    dataIndex: 'cart_address',
                    key: 'cart_address',

                },
                {
                    title: '手机号码',
                    dataIndex: 'phone',
                    key: 'phone',

                },
            ],
            TableInfo: [
                {
                    FieldTitle: '姓名', //字段标题
                    fieldName: 'name', //字段名称
                    fieldType: '文本',  //字段类型
                    disabled: false,  //字段是否禁用
                    mandatory: false, //字段是否必填
                    required: '', //必填要求
                    value: '',
                    width: '100%',
                }
            ],
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            name: '',
            de_id: '',
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    componentDidMount() {
        this.get_departmentList()
    }

    get_userArchivesList = () => {
        let { dataSource, pages, name, de_id,classType } = this.state
        let data = {
            name: name,
            de_id: de_id,
            member_status:classType,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        userArchivesList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                job_day: item.job_day,
                                username: item.username,
                                sex: item.sex,
                                nation: item.nation,
                                deName: item.deName,
                                company_job: item.company_job,
                                major: item.major,
                                education: item.education,
                                place_residence: item.place_residence,
                                native_place: item.native_place,
                                id_cart: item.id_cart,
                                cart_address: item.cart_address,
                                phone: item.phone,
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    pages = {
                        limit: res.data.pages.limit,
                        page: res.data.pages.page,
                        total: res.data.pages.dataNumber,
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_departmentList = () => {
        let { treeData, expandedKeys, selectedKeys } = this.state
        let data = {
            is_job: 0
        }
        treeData = []
        expandedKeys = []
        selectedKeys = []
        departmentList(data).then(
            (res) => {
                if (!res.code) {
                    res.data.departments.forEach((item, index) => {
                        let value = {
                            title: item.name,
                            key: item.id,
                            level: item.level,
                            children: [],
                            icon: <CompanyIcon />,
                        }
                        if (index === 0) {
                            expandedKeys.push(item.id)
                            selectedKeys.push(item.id)
                        }
                        if (item.parents) {
                            item.parents.forEach((tem, dex) => {
                                let children_01 = {
                                    title: tem.name,
                                    key: tem.id,
                                    level: tem.level,
                                    children: [],
                                    icon: <CompanyIcon />,
                                }
                                if (dex === 0) {
                                    children_01.icon = <GroupIcon />
                                }
                                if (tem.parents) {
                                    tem.parents.forEach((em, ex) => {
                                        let children_02 = {
                                            title: em.name,
                                            key: em.id,
                                            level: em.level,
                                            children: [],
                                            icon: <GroupIcon />,
                                        }
                                        if (em.parents) {
                                            em.parents.forEach((m, x) => {
                                                let children_03 = {
                                                    title: m.name,
                                                    key: m.id,
                                                    level: m.level,
                                                    icon: <GroupIcon />,
                                                }
                                                children_02.children = [...children_02.children, children_03]
                                            })
                                        }
                                        children_01.children = [...children_01.children, children_02]
                                    })
                                }
                                value.children = [...value.children, children_01]
                            })
                        }
                        treeData = [...treeData, value]
                    })

                    this.setState(preState => ({
                        treeData,
                        expandedKeys,
                        selectedKeys,
                        groupList: res.data.departments,
                    }), () => {
                        this.get_userArchivesList()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleEdit = () => {
        let { tableId } = this.state
        if (tableId) {
            this.props.history.push({
                pathname: '/administrative/human/archives/NewItem',
                query: {
                    'tableId': tableId,
                    isEdit: true
                }
            })
        } else {
            message.error('请选择需要编辑的员工!')
        }
    }

    get_details = (tableId) => {
        this.props.history.push({
            pathname: '/administrative/human/archives/NewItem',
            query: {
                'tableId': tableId,
                isEdit: false
            }
        })
    }

    handSelect = (value) => {
        this.setState({
            tableId: value.length ? value[0].table_id : ''
        })
    }


    handleSearch = () => {
        this.setState({
            searchShow: true
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleCancel = () => {
        this.setState({
            searchShow: false,
            name: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
        }, () => {
            this.get_userArchivesList()
        })
    }

    searchQuery = (value) => {
        this.setState({
            name: value.name,
            pages: {
                limit: 8,
                page: 1,
                total: 0
            },
            expandedKeys: ['1']
        }, () => {
            this.get_userArchivesList()
        })
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_userArchivesList()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            de_id: value[0]
        }), () => {
            this.get_userArchivesList()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
			pages: {
			    limit: 8,
			    page: 1,
			    total: 0
			},
        }), () => {
            this.get_userArchivesList()
        })
    }

    render() {
        let { searchShow, dataSource, columns, TableInfo, pages, treeData, expandedKeys, selectedKeys, classType } = this.state
        return (
            <div className='contract_List cost_list'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='labor_search'>
                        <div className='cost_budgeting'>
                            <Operate notPrint={true} educe={true} dontAdd={true} dontImport={true} edit={true} searchEvent={this.handleSearch} editEvent={this.handleEdit} printEvent={this.handlePrint} exportEvent={this.handleExport} searchShow={searchShow} />
                        </div>

                        <div className='supplier_right employeesType'>
                            <div className={classType === 0 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(0)
                            }}>在职</div>
                            <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                                this.get_tabIndex(1)
                            }}>离职</div>
                        </div>
                    </div>

                    {
                        searchShow ? <div className='searchForm'><Search searchEvent={this.searchQuery} cancelEvent={this.handleCancel} TableInfo={TableInfo} type="close" /></div> : ''
                    }

                    {
                        <Table columns={columns} dataSource={dataSource} pages={pages} scroll={true} dataLimit={this.handleLimit} selectEvent={this.handSelect} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(List)
