import React, { Component } from 'react'
import { CaretDownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Operate from '../../../components/Operate'
import Table from '../../../components/Table'
import { useEmploymentList } from "../../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [
                {
                    title: '发起人',
                    dataIndex: 'launchUsername',
                    key: 'launchUsername',
                    render: (text, record, index) => {
                        return (
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                this.get_details(record.table_id)
                            }}>{text}</div>
                        )
                    }
                },
                {
                    title: '发起部门',
                    dataIndex: 'launchDeName',
                    key: 'launchDeName',
                },
                {
                    title: '职位名称',
                    dataIndex: 'job_name',
                    key: 'job_name',
                },
                {
                    title: '工作年限',
                    dataIndex: 'work_year',
                    key: 'work_year',
                },
                {
                    title: '学历',
                    dataIndex: 'education',
                    key: 'education',
                },
                {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                },
                {
                    title: '人数',
                    dataIndex: 'number',
                    key: 'number',
                },
                {
                    title: '流程状态',
                    dataIndex: 'status',
                    key: 'status',
                    width: '218px',
                    filterIcon: () => (
                        <div>
                            <span style={{ fontSize: '16px', marginRight: '8px', fontWeight: 'bold', color: '#333333' }}>
                                {
                                    this.state.process_state === '' ? '所有' : this.state.process_state === 2 ? '审批中' : this.state.process_state === 3 ? '已驳回' : this.state.process_state === 4 ? '已完成' : '已终止'
                                }
                            </span>
                            <CaretDownOutlined style={{ color: '#333333' }} />
                        </div>
                    ),
                    filterDropdown: () => (
                        <div className='filterDropdown'>
                            {this.state.filterChecked.map((item, index) =>
                                item.checked ? <div key={index} style={{ backgroundColor: '#F8F9FB' }}>
                                    {item.text}
                                </div> : <div key={index} onClick={() => {
                                    this.handleScreen(item.value)
                                }}>
                                    {item.text}
                                </div>
                            )}
                        </div>
                    ),
                    render: (text, index) => {
                        if (text === 1) {
                            return (
                                <div className='process-state'>
                                    <div className='green'>
                                        暂存中
                                    </div>
                                </div>
                            )
                        } else if (text === 2) {
                            return (
                                <div className='process-state'>
                                    <div className='audit'>
                                        审批中
                                    </div>
                                </div>
                            )
                        } else if (text === 3) {
                            return (
                                <div className='process-state'>
                                    <div className='rejected'>
                                        已驳回
                                    </div>
                                </div>
                            )
                        } else if (text === 4) {
                            return (
                                <div className='process-state'>
                                    <div className='complete'>
                                        已完成
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='process-state'>
                                    <div className='termination'>
                                        已终止
                                    </div>
                                </div>
                            )
                        }
                    }
                },
            ],
            filterChecked: [
                { text: '所有', value: '', checked: true },
                { text: '审批中', value: 2, checked: false },
                { text: '已驳回', value: 3, checked: false },
                { text: '已完成', value: 4, checked: false },
                { text: '已终止', value: 5, checked: false },
            ],
            process_state: '',
            pages: {
                limit: 8,
                page: 1,
                total: 0
            }
        }
    }

    componentDidMount() {
        this.get_useEmploymentList()
    }

    get_useEmploymentList = () => {
        let { dataSource, pages, process_state } = this.state
        let data = {
            queryCriteria:'personal',
            status: process_state,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        useEmploymentList(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.employments.length) {
                        res.data.employments.forEach((item, index) => {
                            let rowValue = {
                                key: index + 1,
                                table_id: item.table_id,
                                launchUsername: item.launchUsername,
                                launchDeName: item.launchDeName,
                                job_name: item.job_name,
                                work_year: item.work_year,
                                education: item.education,
                                age: item.age,
                                sex: item.sex,
                                number: item.number,
                                status: item.status
                            }
                            dataSource = [...dataSource, rowValue]
                        })
                    }

                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleNew = () => {
        this.props.history.push('/public/human/labor/NewItem')
    }

    get_details(tableId) {
        this.props.history.push({
            pathname: '/public/human/labor/NewItem',
            query: {
                'tableId': tableId,
            }
        })
    }

    handlePrint = () => {
        console.log('打印')
    }

    handleExport = () => {
        console.log('导出')
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_useEmploymentList()
        })
    }

    handleScreen = (value) => {
        this.state.filterChecked.forEach((item, index) => {
            item.checked = false
            if (item.value === value) {
                item.checked = true
                this.state.process_state = item.value
            }
        })
        this.setState({
            filterChecked: this.state.filterChecked,
            process_state: this.state.process_state
        }, () => {
            this.get_useEmploymentList()
        })
    }

    render() {
        return (
            <div>
                <Operate notPrint={true} educe={true} seek={true} dontImport={true} newEvent={this.handleNew} printEvent={this.handlePrint} exportEvent={this.handleExport} />

                <Table columns={this.state.columns} dataSource={this.state.dataSource} pages={this.state.pages} dataLimit={this.handleLimit} />
            </div>
        )
    }
}


export default withRouter(List)
