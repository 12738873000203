import React, { Component } from 'react'
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { Input, Form, message, DatePicker, ConfigProvider } from 'antd';
import AddConfig from '../../components/AddConfig'
import InitialValue from '../../components/InitialValue'
import { createSporadic, sporadicDetail, BlankingSelectProject } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"

export default class NewItem extends Component {
    state = {
        Info: {
            state: '',
            process: {
                reviewAudit: [],
                ccReading: [],
                audit: false,
                edit: false
            },
            FlowModelName: "零星用工",
            TableInfo: [{
                FieldTitle: '选择项目', //字段标题
                fieldType: '按钮',  //字段类型
            }, {
                FieldTitle: '项目名称', //字段标题
                fieldName: 'pro_name', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%',
            }, {
                FieldTitle: '项目编号', //字段标题
                fieldName: 'pro_number', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '项目经理', //字段标题
                fieldName: 'pro_manager', //字段名称
                fieldType: '默认',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '50%',
            },
            {
                FieldTitle: '统计月份', //字段标题
                fieldName: 'month', //字段名称
                fieldType: '选择器',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请选择统计月份', //必填要求
                value: '',
                width: '50%',
                selectList: [
                    { value: 1, label: "1月" },
                    { value: 2, label: "2月" },
                    { value: 3, label: "3月" },
                    { value: 4, label: "4月" },
                    { value: 5, label: "5月" },
                    { value: 6, label: "6月" },
                    { value: 7, label: "7月" },
                    { value: 8, label: "8月" },
                    { value: 9, label: "9月" },
                    { value: 10, label: "10月" },
                    { value: 11, label: "11月" },
                    { value: 12, label: "12月" }
                ]
            },
            {
                FieldTitle: '班组名称', //字段标题
                fieldName: 'team', //字段名称
                fieldType: '文本',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: true, //字段是否必填
                required: '请输入班组名称', //必填要求
                value: '',
                width: '50%'
            }, {
                FieldTitle: '计日工记录表', //字段标题
                fieldName: 'record_sheet', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            }, {
                FieldTitle: '附件', //字段标题
                fieldName: 'enclosure', //字段名称
                fieldType: '附件',  //字段类型
                disabled: false,  //字段是否禁用
                mandatory: false, //字段是否必填
                required: '', //必填要求
                value: '',
                width: '100%'
            },
            ],
            FirstInfo: [{
                plateName: '零星用工基本信息',
                list: [],
                table: {
                    scroll: true,
                    scrollWidth: 2800,
                    dataSource: [
                        {
                            key: 1,
                            start_time: '',
                            end_time: '',
                            pm_start_time: '',
                            pm_end_time: '',
                            ev_start_time: '',
                            ev_end_time: '',
                            content: '',
                            tome: '',
                            skill: '',
                            group_leader: '',
                            foreman: '',
                            info: ''
                        }
                    ],
                    columns: [
                        {
                            title: '上午工作开始时间',
                            dataIndex: 'start_time',
                            key: 'start_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '上午工作结束时间',
                            dataIndex: 'end_time',
                            key: 'end_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '下午工作开始时间',
                            dataIndex: 'pm_start_time',
                            key: 'pm_start_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='pm_start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='pm_start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '下午工作结束时间',
                            dataIndex: 'pm_end_time',
                            key: 'pm_end_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='pm_end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='pm_end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '晚上工作开始时间',
                            dataIndex: 'ev_start_time',
                            key: 'ev_start_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='ev_start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='ev_start_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '晚上工作结束时间',
                            dataIndex: 'ev_end_time',
                            key: 'ev_end_time',
                            width: '250px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='ev_end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                } else {
                                    return (
                                        <ConfigProvider locale={zhCN}>
                                            <Form.Item name='ev_end_time' initialValue={text}>
                                                <DatePicker
                                                    allowClear={false}
                                                    showTime={true}
                                                    format='YYYY-MM-DD HH:mm:ss'
                                                    disabled
                                                />
                                            </Form.Item>
                                        </ConfigProvider>
                                    )
                                }
                            }
                        },
                        {
                            title: '工作内容',
                            dataIndex: 'content',
                            key: 'content',
                            width: '400px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='content' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='content' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '普工(工日)',
                            dataIndex: 'tome',
                            key: 'tome',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='tome' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='tome' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '技工(工日)',
                            dataIndex: 'skill',
                            key: 'skill',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='skill' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='skill' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '班组长',
                            dataIndex: 'group_leader',
                            key: 'group_leader',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='group_leader' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='group_leader' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '工长',
                            dataIndex: 'foreman',
                            key: 'foreman',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='foreman' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='foreman' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                        {
                            title: '备注',
                            dataIndex: 'info',
                            key: 'info',
                            width: '400px',
                            render: (text, record, index) => {
                                if (this.state.Info.state === 1 || this.state.Info.state === 3) {
                                    return (
                                        <Form.Item name='info' key={index} initialValue={text}>
                                            <Input />
                                        </Form.Item>
                                    )
                                } else {
                                    return (
                                        <Form.Item name='info' key={index} initialValue={text}>
                                            <Input disabled />
                                        </Form.Item>
                                    )
                                }
                            }
                        },
                    ]
                }
            }
            ]
        },
        modal_Visible: false,
        dataSource: [],
        columns: [
            {
                title: '项目名称',
                dataIndex: 'pro_name',
                key: 'pro_name',
            }, {
                title: '项目编号',
                dataIndex: 'pro_number',
                key: 'pro_number',
            },
            {
                title: '项目经理',
                dataIndex: 'pro_manager',
                key: 'pro_manager',
            },
            {
                title: '项目地址',
                dataIndex: 'pro_address',
                key: 'pro_address',
            },
        ],
        TableInfo: [{
            FieldTitle: '项目名称', //字段标题
            fieldName: 'pro_name', //字段名称
            fieldType: '文本', //字段类型
            disabled: false, //字段是否禁用
            mandatory: false, //字段是否必填
            required: '', //必填要求
            value: '',
            width: '100%',
        }],
        pro_name: '',
        pages: {
            page: 1,
            total: 0,
            limit: 10,
        },
        form: {},
        FlowchartKey: 1
    }

    NewForm = React.createRef();

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_sporadicDetail(tableId)
        } else {
            this.get_FormState()
        }
    }

    get_FormState = () => {
        const { form, Info } = this.state;
		let project = ''
		Info.state = 1
		if (localStorage.getItem('itemForm')) {
		    project = JSON.parse(localStorage.getItem('itemForm'))
		    form.project_id = project.project_id
			Info.TableInfo.forEach((item, index) => {
			    if (item.FieldTitle === '项目名称') {
			        item.value = project.pro_name
			    } else if (item.FieldTitle === '项目编号') {
			        item.value = project.pro_number
			    } else if (item.FieldTitle === '项目经理') {
			        item.value = project.pro_manager
			    }
			})
		}
        this.setState(preState => ({
            Info,
            form,
            FlowchartKey: Math.random(),
        }))
    }

    get_sporadicDetail = (tableId) => {
        const { Info, form } = this.state;
        let data = {
            table_id: tableId
        }
        sporadicDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        form.table_id = tableId
                        form.project_id = res.data.Detail.project_id
                        Info.process.edit = res.data.Detail.editPermission
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.FieldTitle === '选择项目') {
                                item.isShow = true
                            }
                        })
                    }

                    Info.state = res.data.Detail.status
                    Info.process.data = res.data.Detail.reviewAudit
                    Info.process.reviewAudit = res.data.Detail.reviewAudit.reviewAudit
                    Info.process.ccReading = res.data.Detail.reviewAudit.ccReading
                    Info.process.audit = res.data.Detail.reviewAudit.reviewPermission
					Info.process.selfRejectPermission = res.data.Detail.reviewAudit.selfRejectPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.FieldTitle === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.FieldTitle === '项目经理') {
                            item.value = res.data.Detail.proManagerName
                        } else if (item.FieldTitle === '统计月份') {
                            item.value = res.data.Detail.month
                        } else if (item.FieldTitle === '班组名称') {
                            item.value = res.data.Detail.team
                        } else if (item.FieldTitle === '计日工记录表') {
                            item.value = res.data.Detail.record_sheet.length ? res.data.Detail.record_sheet : ''
                        } else if (item.FieldTitle === '附件') {
                            item.value = res.data.Detail.enclosure.length ? res.data.Detail.enclosure : ''
                        }
                    })

                    let content = JSON.parse(res.data.Detail.content)
                    Info.FirstInfo[0].table.dataSource = []
                    content.forEach((item, index) => {
                        item.start_time = item.start_time ? moment(item.start_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        item.end_time = item.end_time ? moment(item.end_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        item.pm_start_time = item.pm_start_time ? moment(item.pm_start_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        item.pm_end_time = item.pm_end_time ? moment(item.pm_end_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        item.ev_start_time = item.ev_start_time ? moment(item.ev_start_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        item.ev_end_time = item.ev_end_time ? moment(item.ev_end_time, 'YYYY-MM-DD HH:mm:ss') : ''
                        Info.FirstInfo[0].table.dataSource = [...Info.FirstInfo[0].table.dataSource, item]

                        this.NewForm.current.formRef.current.setFieldsValue({
                            start_time: item.start_time,
                            end_time: item.end_time,
                            pm_start_time: item.pm_start_time,
                            pm_end_time: item.pm_end_time,
                            ev_start_time: item.ev_start_time,
                            ev_end_time: item.ev_end_time,
                            content: item.content,
                            tome: item.tome,
                            skill: item.skill,
                            group_leader: item.group_leader,
                            foreman: item.foreman,
                            info: item.info,
                        })
                    })

                    this.NewForm.current.formRef.current.setFieldsValue({
                        month: res.data.Detail.month,
                        team: res.data.Detail.team,
                        record_sheet: res.data.Detail.record_sheet,
                        enclosure: res.data.Detail.enclosure,
                    })

                    this.setState(preState => ({
                        Info,
                        form,
                        //FlowchartKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { dataSource, pages, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let user = {
                                key: item.table_id,
                                pro_name: item.pro_name,
                                pro_manager: item.pro_manager,
                                pro_number: item.pro_number,
                                pro_address: item.pro_address,
                            }
                            dataSource = [...dataSource, user]
                        })
                    }
                    this.setState(preState => ({
                        dataSource,
                        pages: res.data.pages,
                        modal_Visible: true,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handPresent = (data, status) => {
        console.log(data)
        const { form, Info } = this.state;
        form.status = status
        form.month = data.month
        form.team = data.team
        form.content = []
        let value = {
            start_time: data.start_time ? data.start_time.format('YYYY-MM-DD HH:mm:ss') : '',
            end_time: data.end_time ? data.end_time.format('YYYY-MM-DD HH:mm:ss') : '',
            pm_start_time: data.pm_start_time ? data.pm_start_time.format('YYYY-MM-DD HH:mm:ss') : '',
            pm_end_time: data.pm_end_time ? data.pm_end_time.format('YYYY-MM-DD HH:mm:ss') : '',
            ev_start_time: data.ev_start_time ? data.ev_start_time.format('YYYY-MM-DD HH:mm:ss') : '',
            ev_end_time: data.ev_end_time ? data.ev_end_time.format('YYYY-MM-DD HH:mm:ss') : '',
            content: data.content,
            tome: data.tome,
            skill: data.skill,
            group_leader: data.group_leader,
            foreman: data.foreman,
            info: data.info
        }
        form.content.push(value)
        form.content = JSON.stringify(form.content)

        createSporadic(form).then(
            (res) => {
                if (!res.code) {
                    message.success(form.status === 1 ? '暂存成功' : '提交成功')
                    // if (form.status === 2) {
                    //     setTimeout(() => {
                    //         window.history.back(-1)
                    //     })
                    // }
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '提交失败,请检查表单!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handButton = (title, state) => {
        if (state) {
            this.get_BlankingSelectProject()
        } else {
            this.setState(preState => ({
                dataSource: [],
                pages: {
                    page: 1,
                    total: 0,
                    limit: 10,
                },
                modal_Visible: false,
            })
            )
        }
    }

    handSelect = (value) => {
        let { Info, form } = this.state

        form.project_id = value[0].key
        Info.TableInfo.forEach((item, index) => {
            if (item.FieldTitle === '项目名称') {
                item.value = value[0].pro_name
            } else if (item.FieldTitle === '项目编号') {
                item.value = value[0].pro_number
            } else if (item.FieldTitle === '项目经理') {
                item.value = value[0].pro_manager
            }
        })

        this.setState(preState => ({
            Info,
            form,
            pro_name: '',
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
            modal_Visible: false,
        })
        )
    }

    searchQuery = (value) => {
        this.setState({
            pro_name: value.pro_name,
            pages: {
                page: 1,
                total: 0,
                limit: 10,
            },
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    handleCancel = () => {
        this.setState({
            modal_Visible: false,
            pro_name: '',
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
        })
    }

    handleButton = (type, value) => {
        let { Info } = this.state
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    message.success('审核成功!')
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        message.error(res.data[0] || '审核失败!')
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handFile = (storeList, fileName, fileValue) => {
        let { Info, form } = this.state
        let fileList = []
        if (form.table_id) {
            Info.TableInfo.forEach((item, index) => {
                if (item.fieldName === fileName) {
                    if (item.value && item.value.length) {
                        item.value.forEach((em, ex) => {
                            storeList.forEach((m, x) => {
                                if (em.table_id === m) {
                                    fileList.push(em)
                                }
                            })
                        })
                    }
                    if (fileValue) {
                        fileList.push(fileValue)
                    }
                    item.value = fileList
                }
            })
        }
        form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Info,
            form
        })
        )
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_BlankingSelectProject()
        })
    }

    render() {
        let { FlowchartKey, Info, modal_Visible, dataSource, columns, TableInfo, pages } = this.state
        return (
            <div className='configAdd'>
                <AddConfig key={FlowchartKey} ref={this.NewForm} Info={Info} presentEvent={this.handPresent} buttonEvent={this.handButton} fileEvent={this.handFile} auditEvent={this.handleButton} />

                <InitialValue modal_Visible={modal_Visible} dataSource={dataSource} pages={pages} columns={columns} TableInfo={TableInfo} radio={true} buttonEvent={this.handButton} searchEvent={this.searchQuery} cancelEvent={this.handleCancel} selectEvent={this.handSelect} limitEvent={this.handleLimit} />
            </div>
        )
    }
}
