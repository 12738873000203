import React, { Component } from 'react'
import { Tree, message } from 'antd';
import Icon from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import Table from '../../components/Table'
import { revenueMain } from "../../../utils/market"
import { PriceComparison } from "../../../utils/cost"

import Folder from "../../../accets/img/folder.png"
import Folders from "../../../accets/img/folders.png"
import File from "../../../accets/img/file.png"

const FolderImage = () => <img src={Folder} alt=""></img>
const FoldersImage = () => <img src={Folders} alt=""></img>
const FileImage = () => <img src={File} alt=""></img>
const FolderIcon = (props) => <Icon component={FolderImage} {...props} />;
const FoldersIcon = (props) => <Icon component={FoldersImage} {...props} />;
const FileIcon = (props) => <Icon component={FileImage} {...props} />;

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchShow: false,
            dataSource: [],
            columns: [],
            pages: {
                limit: 10,
                page: 1,
                total: 0
            },
            pro_id: '',
            classType: 1,
            treeData: [],
            expandedKeys: [],
            selectedKeys: [],
        }
    }

    componentDidMount() {
        this.get_revenueMain()
    }

    get_revenueMain = () => {
        let { treeData, pro_id, selectedKeys, expandedKeys } = this.state
        revenueMain().then(
            (res) => {
                if (!res.code) {
                    let list = []
                    for (let key in res.data) {
                        let object = res.data[key]
                        let value = {
                            company: object[0],
                            project: object[1]
                        }
                        list = [...list, value]
                    }

                    treeData = []
                    list.forEach((item, index) => {
                        let value = {
                            title: '',
                            key: '',
                            icon: <FolderIcon />,
                            children: []
                        }
                        for (let key in item.company) {
                            value.title = item.company[key]
                            value.key = key
                        }

                        for (let key in item.project) {
                            let childValue = {
                                title: key,
                                key: value.key + '-' + key,
                                icon: <FoldersIcon />,
                                children: []
                            }

                            item.project[key].forEach((em, ex) => {
                                let proValue = {
                                    title: em.pro_name,
                                    key: em.project_id,
                                    icon: <FileIcon />,
                                }
                                childValue.children = [...childValue.children, proValue]
                            })

                            value.children = [childValue, ...value.children]
                        }

                        treeData = [...treeData, value]
                    })

                    if (!pro_id) {
                        selectedKeys = []
                        expandedKeys = ['1']
                        treeData.forEach((item, index) => {
                            if (index === 0) {
                                item.children.forEach((em, ex) => {
                                    if (ex === 0) {
                                        pro_id = em.children[0].key
                                        selectedKeys.push(em.children[0].key)
                                        expandedKeys.push(em.key)
                                    }
                                })
                            }
                        })
                    }

                    this.setState({
                        treeData,
                        pro_id,
                        selectedKeys,
                        expandedKeys
                    }, () => {
                        this.get_PriceComparison()
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_PriceComparison = () => {
        let { pro_id, name, classType, dataSource, columns, pages } = this.state
        if (!pro_id) {
            message.error('请选择项目')
            return
        }
        let data = {
            project_id: pro_id,
            class: classType,
            name: name,
            page: Number(pages.page),
            limit: Number(pages.limit)
        }
        PriceComparison(data).then(
            (res) => {
                if (!res.code) {
                    dataSource = []
                    columns = [
                        {
                            title: '序号',
                            dataIndex: 'key',
                            key: 'key',
                        },
                        {
                            title: '名称',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: '价格(元)',
                            dataIndex: 'unit_price',
                            key: 'unit_price',
                        },
                    ]
                    let number = 0
                    if (res.data.budget.length) {
                        res.data.budget.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                name: item.name,
                                number: item.number,
                                unit_price: item.unit_price,
                            }

                            if (item.updatePrice.length) {
                                item.updatePrice.forEach((em, ex) => {
                                    let dataIndex = 'sort' + em.sort
                                    if (item.updatePrice.length > number) {
                                        let title = {
                                            title: '第' + em.sort + '次修改',
                                            dataIndex: dataIndex,
                                            key: dataIndex,
                                        }
                                        columns = [...columns, title]

                                    }
                                    value[dataIndex] = em.price
                                })
                                number = item.updatePrice.length
                            }
                            dataSource = [...dataSource, value]
                        })
                    }
                    this.setState({
                        columns,
                        dataSource,
                        pages: res.data.pages
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLimit = (page, limit) => {
        let { pages } = this.state
        pages.page = page
        pages.limit = limit
        this.setState({
            pages
        }, () => {
            this.get_PriceComparison()
        })
    }

    onSelect = (value) => {
        this.setState(preState => ({
            selectedKeys: value,
            pro_id: value[0],
        }), () => {
            this.get_PriceComparison()
        })
    }

    onExpand = (value) => {
        this.setState(preState => ({
            expandedKeys: value
        })
        )
    }

    get_tabIndex = (index) => {
        this.setState(preState => ({
            classType: index,
			pages: {
			    limit: 10,
			    page: 1,
			    total: 0
			},
        }), () => {
            this.get_PriceComparison()
        })
    }


    render() {
        let { dataSource, columns, pages, treeData, expandedKeys, selectedKeys, classType } = this.state

        return (
            <div className='contract_List'>
                <div className='menuList'>
                    <Tree
                        showLine={{ showLeafIcon: false }}
                        showIcon={true}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                        defaultExpandParent={true}
                        treeData={treeData}
                        onSelect={(value) => {
                            this.onSelect(value)
                        }}
                        onExpand={(value) => {
                            this.onExpand(value)
                        }}
                    />
                </div>
                <div>
                    <div className='supplier_left'>
                        <div className={classType === 1 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(1)
                        }}>项目材料</div>
                        <div className={classType === 2 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(2)
                        }}>项目人工</div>
                        <div className={classType === 3 ? 'supplier_selection' : ''} onClick={() => {
                            this.get_tabIndex(3)
                        }}>项目机械</div>
                    </div>

                    <Table columns={columns} dataSource={dataSource} selection={true} pages={pages} dataLimit={this.handleLimit} />
                </div>
            </div>
        )
    }
}

export default withRouter(List)
